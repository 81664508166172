import React, {useState} from 'react';

const attendanceKeyboard = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', 'delete', '0', 'check' ];

const AttendanceNumPad = React.forwardRef(( { bookingAttend, bookingAttendFirstClick, checkAttendance, closeAttendanceNumpad }, ref)=>{
    const [attendance, setAttendance]= useState(bookingAttend)
    const [bookingAttendFirstClickState, setBookingAttendFirstClickState] = useState(bookingAttendFirstClick);

    const handleNumpadKeyin =(e) =>{
		let bookingAttendArray = attendance.toString().split('');

        if (e === 'delete') {
			bookingAttendArray.pop();
		} else if (e === 'clear') {
			bookingAttendArray = [];
		} else {
			if (!bookingAttendFirstClickState) {
				bookingAttendArray = [];
			}
			bookingAttendArray.push(e.toString());
		}

		const num = isNaN(parseInt(bookingAttendArray.join(''), 10)) ? '' : parseInt(bookingAttendArray.join(''), 10);

        setAttendance(num)
        setBookingAttendFirstClickState(true)
    }

	return (
        <div ref={ref} className="AttendanceNumPad">
            <div tabIndex="0" className="numPad">
                <div className="numpadClose" onClick={() => closeAttendanceNumpad()}>
                    <i className="fa fa-times" aria-hidden="true" />
                </div>
                <div className="numPad_display">
                    {attendance} <span>人</span>
                </div>
                <div className="numPadWrap">
                    {attendanceKeyboard.map((cell, index) => {
                        if (cell === 'delete') {
                            return (
                                <div
                                    key={index}
                                    className="numPadCell"
                                    onClick={() => handleNumpadKeyin('delete')}
                                >
                                    <img
                                        className="numPad_del"
                                        src={require('../../../images/delete_black_icon.svg')}
                                        alt="delete"
                                    />
                                </div>
                            );
                        } else if (cell === 'check') {
                            return (
                                <div key={index} className="numPadCell" onClick={() => checkAttendance(attendance)}>
                                    確認
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={index}
                                    className="numPadCell"
                                    onClick={() => handleNumpadKeyin(cell)}
                                >
                                    {cell}
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
})

export default AttendanceNumPad;