import React from 'react';
import Banner from '../../../images/newModule/newFeature.png';

export default ({ setStep }) => (
	<div className="page1">
		<h3>開始管理您的排隊</h3>
		<p className="lessWidth">現在，您可以透過暫停或結束排隊管理單日排隊狀態。</p>

		<div className="newFeatureBanner">
			<img src={Banner} alt="Haven Seen New Feature ?" />
		</div>

		<button onClick={() => setStep(2)}>下一頁</button>
	</div>
);
