import React from 'react'
import $ from 'jquery'
var createReactClass = require('create-react-class');
export default createReactClass({
  getInitialState() {
    var to = new Date();
    var from = new Date();
    from.setDate(from.getDate()-7);
    return {
      status: '', 
      state_code: '',
      from: this.getDateString(from), 
      to: this.getDateString(to)
    }
  },
  handleInputChange(e) {
    var new_state = $.extend({}, this.state);
    new_state[e.target.name] = e.target.value;
    // var f = new Date(new_state.from);
    // var t = new Date(new_state.to);
    this.setState(new_state, function() {
      this.props.onInputChange();
    });
  },
  leftPad(value) {
    value = (value<10) ? ('0'+value) : value;
    return value;
  },
  getDateString(date) {
    var y = date.getFullYear();
    var m = this.leftPad(date.getMonth()+1);
    var d = this.leftPad(date.getDate());
    return y+'-'+m+'-'+d;
  },
  componentDidMount() {
    $(this.refs.range).datepicker({
      language: "zh-TW",
      format: "yyyy-mm-dd",
      autoclose: true
    }).on('changeDate', function(e){
      this.handleInputChange(e);
    }.bind(this));
  },
	render() {
    return (
	    <div className="func-bar">
        <select className="form-control caller-type-select"
                name='state_code'
                value={this.state.state_code}
                onChange={this.handleInputChange}>
          <option value=''>全部</option>
          <option value='hang_up'>已接</option>
          <option value='ring_off'>未接</option>
        </select>
        <form className="form-inline date-range-form" id="datepicker-container">
          <span>查詢日期 </span>
          <div className="input-daterange input-group" id="datepicker" ref="range">
            <input type="text"
                   className="input-sm form-control" 
                   name="from"
                   value={this.state.from}
                   onChange={this.handleInputChange} />
            <span className="input-group-addon">至</span>
            <input type="text" 
                   className="input-sm form-control" 
                   name="to" 
                   value={this.state.to} 
                   onChange={this.handleInputChange} />
          </div>
        </form>
      </div>
	  );
	}
})
