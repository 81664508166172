import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import AnnouncementCard from '../AnnouncementCard';
import {
  announcementInit,
  showAnnouncementAddModal,
  closeAnnouncementListModal,
  searchAnnouncementListModal
} from '../../../actions/announcementAction';

const day_list = ['日', '一', '二', '三', '四', '五', '六'];

const AnnouncementListModal = ({ month = '' }) => {
  const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
    showListType,
    currentDate,
    dayAnnouncements,
    displayMonthAnnouncements,
    searchModalTxt,
    searchModeModal
	} = announcementListReducer;
  const dispatch = useDispatch();

  const cancelPopup = () => {
    dispatch(closeAnnouncementListModal());
    dispatch(searchAnnouncementListModal(''));
  };

  const showSearchInput = (type) => {
    const title = document.getElementsByClassName('title')[0];
    const searchInput = document.getElementsByClassName('searchInput')[0];

    if(type === 'open') {
      title.classList.add('open');
      searchInput.focus();
    } else {
      title.classList.remove('open');
      // 清除搜尋、恢復原始列表
      dispatch(searchAnnouncementListModal(''));
    }
  };

  const handleScrollTop = () => {
    const top = document.getElementsByClassName('announcementLisContent')[0];
    top.scrollIntoView({ behavior: "smooth" });
  };

  const showAddAnnouncementModal = () => {
    dispatch(announcementInit());
    dispatch(showAnnouncementAddModal());
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    dispatch(searchAnnouncementListModal(value));
  };

  const renderTitleAndAction = () => {
    if(showListType === 'month') {
      return (
        <div className='title'>
          <div className='month_text'>{`${month + 1}月份公告`}</div>
          <div className='announcementAction'>
            <div className='searchBlock'>
              <SearchOutlined
                className="search_icon"
                onClick={() => showSearchInput('open')}
              />
              <input
                type="text"
                className="searchInput"
                value={searchModalTxt}
                placeholder="請輸入關鍵字"
                onChange={(e) => handleSearch(e)}
              />
              <img
                className="closeSearch"
                src={require('../../../images/announcement/icon_close.svg')}
                onClick={() => showSearchInput('close')}
                alt=''
              />
            </div>
            <div className='add_icon' onClick={() => showAddAnnouncementModal()} />
          </div>
        </div>
      )
    } else {
      let date = moment(currentDate).format(('YYYY-MM-DD'));
      let day = new Date(currentDate).getDay();

      return <div className='title_day'>{ date + '（' + day_list[day]+ '）' }</div>;
    }
  };

  const renderList = () => {
    if(showListType === 'month') {
      let displayAnnouncementCount = []

      Object.keys(displayMonthAnnouncements).forEach((key) => {
        if(displayMonthAnnouncements[key].length > 0) {
          for(let i = 0; i < displayMonthAnnouncements[key].length; i++) {
            displayAnnouncementCount.push(key)
          }
        }
      });

      if(searchModeModal && displayAnnouncementCount.length === 0) {
        return <div className='list_empty'>沒有搜尋到相關公告，請重新輸入關鍵字。</div>
      }

      if(displayAnnouncementCount.length === 0) {
        return <div className='list_empty'>本月份暫無公告。</div>
      }

      return (
        Object.keys(displayMonthAnnouncements).map((item, idx) => {
          if(displayMonthAnnouncements[item].length > 0) {
            let day = new Date(item).getDay();

            displayMonthAnnouncements[item].sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

            return (
              <Fragment key={idx}>
                <h4>{ item + '（' + day_list[day] + '）' }</h4>
                {
                  displayMonthAnnouncements[item].map((i, idx) => {
                    return (
                      <AnnouncementCard
                        data={displayMonthAnnouncements}
                        item={i}
                        idx={idx}
                        key={i.id}
                        date={item}
                      />
                    )
                  })
                }
              </Fragment>
            )
          }
        })
      )
    } else {
      if(dayAnnouncements.length === 0) {
        return <div className='list_empty'>今日暫無公告。</div>
      }

      return (
        dayAnnouncements.map((item, idx) => {
          return (
            <AnnouncementCard
              data={dayAnnouncements}
              item={item}
              idx={idx}
              key={item.id}
              date={moment(currentDate).format(('YYYY-MM-DD'))}
            />
          )
        })
      )
    }
  }

  return (
    <Modal
      title={renderTitleAndAction()}
      visible
      centered
      className="announcementListModal"
      onCancel={cancelPopup}
      footer={
        <Fragment>
          <div className='toTop_icon' onClick={() => handleScrollTop()} />
          <button className='btn_save' onClick={() => cancelPopup()}>關閉</button>
        </Fragment>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className='announcementLisContent'>
        { renderList() }
      </div>
    </Modal>
  );
};

export default AnnouncementListModal;