import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import Konva from 'konva';
import { Stage, Layer, Rect, Circle, Group, Shape, Image } from 'react-konva';
// import Portal from './Portal';
import _ from 'lodash';
import BookingSystemApi from '../BookingSystemApi';
import useImage from 'use-image';
import '../../../stylesheets/tableMap.css';

import wall_1 from '../../../images/tablemap/wall-1.svg';
import wall_2 from '../../../images/tablemap/wall-2.svg';
import wall_3 from '../../../images/tablemap/wall-3.svg';
import wall_4 from '../../../images/tablemap/wall-4.svg';
import wall_5 from '../../../images/tablemap/wall-5.svg';
import wall_6 from '../../../images/tablemap/wall-6.svg';
import toilet from '../../../images/tablemap/toilet.svg';
import exit from '../../../images/tablemap/exit.svg';

/**
 * {number} w_w - canvas default width: 1024
 * {number} w_h - canvas default height: 700
 * {number} scaleRatio - scale canvas to fit in window, maximum: 1
 * {string} shapeType - record canvas item type: square, horizontalRect, verticalRect, circle, ele
 * {string} eleType - record ele type: walls, exit, toilet
 * {number} setting - display setting panel, 1: table panel, 2: ele panel
 * {string} selectedId - record selected table id in canvas
 * {number} imgSelectedId - record selected ele index in cavas
 * {object} tableGroup - add/remove table number to display or not display table number in table panel
 * {object} originTableGroup - record origin table groups contain table number
 * {array} tableRect - record table which has displayed in cavas
 * {array} eleImgs - record ele which has displayed in cavas
 * {object} tableGroupTab - record table group in which tab, same table group isn't allowed to be placed in different tabs
 */

const eleList = {
	wall_1,
	wall_2,
	wall_3,
	wall_4,
	wall_5,
	wall_6,
	toilet,
	exit
};

const $selectedColor = '#faa329';
const canvasTabWrapHeight = 70;
const editFormHeight = 85;
let addOffsetX = 0,
	addOffsetY = 0;

const GUIDELINE_OFFSET = 5;

export default class TableMap extends React.Component {
	
	state = {
		sending: false,
		loading: true,
		w_w: 1024,
		w_h: 700,
		scaleRatio: 1,
		tabsInfo: [],
		tables: [],
		setting: 1,
		activeTab: '',
		activeTableGroup: '',
		activeTable: '',
		selectedId: null,
		imgSelectedId: null,
		eleImgs: [],
		tableRect: [],
		shapeType: null,
		eleType: null,
		originTableGroup: {},
		tableGroup: {},
		showWarning: false,
		hasMoved: false,
		tableGroupTab: {},
		firstIn: false
	};

	componentDidMount() {
		if (!this.props.location.state) {
			this.props.router.push('/dashboard/setting/tablemap_setting');
			return;
		}
		let { originTableGroup, tableGroupTab } = this.state;
		let tableRect = [],
			eleImgs = [];
		let tabsInfo = [],
			activeTab = '';
		let scaleRatio =
			JSON.parse(((window.innerWidth - 350) / this.state.w_w).toFixed(2)) > 1
				? 1
				: JSON.parse(((window.innerWidth - 350) / this.state.w_w).toFixed(2));

		this.API = new BookingSystemApi();
		this.isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
		this.nav = document.getElementsByClassName('menushop-nav')[0];
		this.settings = document.getElementsByClassName('reservation-settings')[0];

		this.nav.classList.add('hide');
		this.settings.style.paddingTop = '0px';

		this.setState({ scaleRatio });

		this.API.getBookingSettingSeats().then((data) => {
			let sortTable = _.sortBy(data.results, [ 'group', 'max_seat' ]);

			sortTable.map((group) => {
				group.table_numbers.map((table) => {
					if (originTableGroup.hasOwnProperty(group.group)) {
						originTableGroup[group.group].push(table);
					} else {
						originTableGroup[group.group] = [];
						originTableGroup[group.group].push(table);
					}
					return null;
				});
				return null;
			});


			this.setState(
				{
					loading: false,
					tables: sortTable,
					activeTableGroup: data.results[0].group,
					originTableGroup,
					tableGroup: JSON.parse(JSON.stringify(originTableGroup))
				},
				() => {
					tabsInfo = this.props.location.state.tabsInfo;
					activeTab = this.props.location.state.activeTab;

					tabsInfo.map((tab) => {
						if (tab.name === activeTab) {
							tableRect = tab.table;
							eleImgs = tab.ele;
						}

						tab.table.map((table) => {
							if (!tableGroupTab.hasOwnProperty(table.group)) {
								tableGroupTab[table.group] = tab.name;
							}
							return null;
						});

						return null;
					});

					tableRect.map((table) => {
						let tableIndex = this.state.tableGroup[table.group].indexOf(table.name);

						this.state.tableGroup[table.group].splice(tableIndex, 1);
						return null;
					});

					this.setState({
						tabsInfo,
						tableRect,
						eleImgs,
						activeTab,
						activeTable: this.state.tableGroup[Object.keys(this.state.tableGroup)[0]][0],
						firstIn: true
					});
				}
			);
		});
	}

	componentWillUnmount() {
		if (this.nav && this.settings) {
			this.settings.removeAttribute('style');
			this.nav.classList.remove('hide');
		}
	}

	componentDidUpdate() {
		if (this.state.firstIn) {
			this.scrollToTab();
			this.setState({ firstIn: false });
		}
	}

	renderTabs = () => {
		let { activeTab, tabsInfo } = this.state;

		return tabsInfo.map((tab, index) => {
			let active = tab.name === activeTab ? 'active' : '';
			return (
				<span key={index} className={active} onClick={() => this.switchTab(tab.name)}>
					{tab.name}
				</span>
			);
		});
	};

	scrollToTab = () => {
		let $tabs = document.getElementsByClassName('tabs')[0];
		let $tabsContainer = document.getElementsByClassName('tabsContainer')[0];
		let $activeTab = $tabsContainer.getElementsByClassName('active')[0];

		if ($activeTab) {
			$tabs.scrollLeft = $activeTab.offsetLeft - 10;
		}
	};

	renderSettingEle = () => {
		let { setting } = this.state;
		let tableActive = '',
			eleActive = '';

		if (setting === 1) {
			tableActive = 'active';
			eleActive = '';
		} else {
			tableActive = '';
			eleActive = 'active';
		}

		return (
			<div className="settingEle">
				<div>
					<span className={tableActive} onClick={() => this.switchSetting(1)}>
						桌次
					</span>
				</div>
				<div>
					<span className={eleActive} onClick={() => this.switchSetting(2)}>
						元素
					</span>
				</div>
			</div>
		);
	};

	renderSetting = () => {
		let { setting } = this.state;

		if (setting === 1) {
			return this.table();
		} else {
			return this.ele();
		}
	};

	renderTables = () => {
		let { tables, activeTableGroup, activeTable, tabsInfo, originTableGroup } = this.state;
		let findTable = [];
		let activeTableGroupLength = originTableGroup[activeTableGroup] ? originTableGroup[activeTableGroup].length : 0;
		let tableGroup = [];
		let hasIn = false;

		tabsInfo.map((tab) => {
			tab.table.map((t) => {
				findTable.push(t);
				return null;
			});
			return null;
		});

		findTable.map((table) => {
			if (table.group === activeTableGroup) tableGroup.push(table);
			return null;
		});


		return (
			<div className="tableSettingWrap">
				{tables.map((table) => {
					if (table.group === activeTableGroup && activeTableGroupLength !== tableGroup.length) {
						return table.table_numbers.map((t, index) => {
							let active = activeTable === t ? 'active' : '';
							const tableIndex = findTable
								.map((t) => {
									return t.name;
								})
								.indexOf(t);

							if (tableIndex === -1) {
								return (
									<div key={index} className={`tableBlock ${active}`}>
										<p className="tableBlockTitle" onClick={() => this.handleChooseTable(t)}>
											{t} {`(${table.min_seat} - ${table.max_seat}人)`}
										</p>
										<div className="tableItemWrap">
											<div className="tableItem">
												<div
													className="tableItem-square"
													onClick={() => this.addToCanvas(t, table, 'square')}
												/>
											</div>
											<div className="tableItem">
												<div
													className="tableItem-circle"
													onClick={() => this.addToCanvas(t, table, 'circle')}
												/>
											</div>
											<div className="tableItem">
												<div
													className="tableItem-horizontalRect"
													onClick={() => this.addToCanvas(t, table, 'horizontalRect')}
												/>
											</div>
											<div className="tableItem">
												<div
													className="tableItem-verticalRect"
													onClick={() => this.addToCanvas(t, table, 'verticalRect')}
												/>
											</div>
										</div>
									</div>
								);
							} else {
								return null;
							}
						});
					} else if (
						table.group === activeTableGroup &&
						activeTableGroupLength === tableGroup.length &&
						!hasIn
					) {
						hasIn = true;

						return (
							<div key="done" className="seatDone">
								<p>此區已全數加入座位圖</p>
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>
		);
	};

	table = () => {
		let { tables, activeTableGroup, originTableGroup, tabsInfo } = this.state;
		let tableGroup = {};

		tabsInfo.map((tab) => {
			tab.table.map((t) => {
				if (tableGroup.hasOwnProperty(t.group)) {
					tableGroup[t.group] += 1;
				} else {
					tableGroup[t.group] = 1;
				}
				return null;
			});
			return null;
		});

		return (
			<div className="tableSetting">
				<div className="tablesWrap">
					{_.unionBy(tables, 'group').map((table) => {
						let active = activeTableGroup === table.group ? 'active' : '';
						let allClear = '';
						if (
							tableGroup[table.group] &&
							tableGroup[table.group] === originTableGroup[table.group].length
						) {
							allClear = 'allClear';
						} else {
							allClear = '';
						}
						return (
							<span
								key={table.group}
								className={`${active} ${allClear}`}
								onClick={() => this.handleChooseTableGroup(table.group)}
							>
								{table.group}
							</span>
						);
					})}
				</div>
				{this.renderTables()}
			</div>
		);
	};

	ele = () => {
		return (
			<div className="eleSetting">
				<div className="wallWrap">
					<div className="walls">
						<div className="wall_1" onClick={() => this.addEleToCanvas('wall_1')}>
							<i />
						</div>
						<div className="wall_2" onClick={() => this.addEleToCanvas('wall_2')}>
							<i />
						</div>
						<div className="wall_3" onClick={() => this.addEleToCanvas('wall_3')}>
							<i />
						</div>
						<div className="wall_4" onClick={() => this.addEleToCanvas('wall_4')}>
							<i />
						</div>
					</div>
					<div className="wallStick-1" onClick={() => this.addEleToCanvas('wall_5')}>
						<i />
					</div>
					<div className="wallStick-2" onClick={() => this.addEleToCanvas('wall_6')}>
						<i />
					</div>
					<div className="toilet" onClick={() => this.addEleToCanvas('toilet')}>
						<i />
					</div>
					<div className="exit" onClick={() => this.addEleToCanvas('exit')}>
						<i />
					</div>
				</div>
			</div>
		);
	};

	renderType = () => {
		let { shapeType, eleType } = this.state;

		if (shapeType !== 'ele') {
			let square_active = shapeType === 'square' ? 'active' : '',
				horizontalRect_active = shapeType === 'horizontalRect' ? 'active' : '',
				verticalRect_active = shapeType === 'verticalRect' ? 'active' : '',
				circle_active = shapeType === 'circle' ? 'active' : '';
			return (
				<React.Fragment>
					<div className="tableType">
						<div className={`tableItem ${square_active}`} onClick={() => this.changeTableType('square')}>
							<div className="tableItem-square" />
						</div>
						<div className={`tableItem ${circle_active}`} onClick={() => this.changeTableType('circle')}>
							<div className="tableItem-circle" />
						</div>
						<div
							className={`tableItem ${horizontalRect_active}`}
							onClick={() => this.changeTableType('horizontalRect')}
						>
							<div className="tableItem-horizontalRect" />
						</div>
						<div
							className={`tableItem ${verticalRect_active}`}
							onClick={() => this.changeTableType('verticalRect')}
						>
							<div className="tableItem-verticalRect" />
						</div>
					</div>
					<div className="editFormBtn-del" onClick={() => this.removeFromCanvas()}>
						<i className="fa fa-trash" aria-hidden="true" />
					</div>
				</React.Fragment>
			);
		} else {
			let wall_1_active = eleType === 'wall_1' ? 'active' : '',
				wall_2_active = eleType === 'wall_2' ? 'active' : '',
				wall_3_active = eleType === 'wall_3' ? 'active' : '',
				wall_4_active = eleType === 'wall_4' ? 'active' : '',
				wall_5_active = eleType === 'wall_5' ? 'active' : '',
				wall_6_active = eleType === 'wall_6' ? 'active' : '',
				toilet_active = eleType === 'toilet' ? 'active' : '',
				exit_active = eleType === 'exit' ? 'active' : '';

			return (
				<React.Fragment>
					<div className="wallWrap">
						<div className="walls">
							<div
								className={`item wall_1 ${wall_1_active}`}
								onClick={() => this.changeEleType('wall_1')}
							>
								<i />
							</div>
							<div
								className={`item wall_2 ${wall_2_active}`}
								onClick={() => this.changeEleType('wall_2')}
							>
								<i />
							</div>
							<div
								name="wall-3"
								className={`item wall_3 ${wall_3_active}`}
								onClick={() => this.changeEleType('wall_3')}
							>
								<i />
							</div>
							<div
								className={`item wall_4 ${wall_4_active}`}
								onClick={() => this.changeEleType('wall_4')}
							>
								<i />
							</div>
						</div>
						<div
							className={`wallStick-1 item ${wall_5_active}`}
							onClick={() => this.changeEleType('wall_5')}
						>
							<i />
						</div>
						<div
							className={`wallStick-2 item ${wall_6_active}`}
							onClick={() => this.changeEleType('wall_6')}
						>
							<i />
						</div>
						<div className={`toilet item ${toilet_active}`} onClick={() => this.changeEleType('toilet')}>
							<i />
						</div>
						<div className={`exit item ${exit_active}`} onClick={() => this.changeEleType('exit')}>
							<i />
						</div>
					</div>
					<div className="editFormBtn-del" onClick={() => this.removeEleFromCanvas()}>
						<i className="fa fa-trash" aria-hidden="true" />
					</div>
				</React.Fragment>
			);
		}
	};

	addToCanvas = (name, info, shape) => {
		let { tabsInfo, activeTab, tableGroup, activeTableGroup, hasMoved, tableGroupTab } = this.state;
		let newTable = { width: 100, height: 100 };
		let tableRect = [];
		let itemIndex = tableGroup[activeTableGroup].indexOf(name);
		let activeTable = '';
		let $canvasWrap = document.getElementsByClassName('canvasWrap')[0];

		if (tableGroupTab.hasOwnProperty(info.group) && tableGroupTab[info.group] !== activeTab) {
			window.app.alert.setMessage('同組別桌子不可加入不同區域', 'tip');
			return;
		} else {
			tableGroupTab[info.group] = activeTab;
		}

		tableGroup[activeTableGroup].splice(itemIndex, 1);

		activeTable = tableGroup[activeTableGroup][0];

		if (shape !== 'circle') {
			if (!hasMoved) {
				addOffsetX += 15;
				addOffsetY += 15;
				newTable.positionX = 20 + addOffsetX;
				newTable.positionY = 20 + addOffsetY;
			} else {
				newTable.positionX = 20;
				newTable.positionY = 20;
				addOffsetX = 0;
				addOffsetY = 0;
			}
			if (shape === 'horizontalRect') {
				newTable.width = 120;
				newTable.height = 90;
			} else if (shape === 'verticalRect') {
				newTable.height = 120;
				newTable.width = 90;
			}
		} else {
			if (!hasMoved) {
				addOffsetX += 15;
				addOffsetY += 15;
				newTable.positionX = 65 + addOffsetX;
				newTable.positionY = 65 + addOffsetY;
			} else {
				newTable.positionX = 65;
				newTable.positionY = 65;

				addOffsetX = 0;
				addOffsetY = 0;
			}
		}

		newTable.table_type = shape;
		newTable.name = name;
		newTable.table_setting_id = info.id;
		newTable.group = info.group;
		newTable.min_attendance = info.min_seat;
		newTable.max_attendance = info.max_seat;
		newTable.newItem = true;

		tabsInfo.map((t) => {
			if (t.name === activeTab) {
				t.table.push(newTable);
				tableRect = t.table;
			}
			return null;
		});

		$canvasWrap.scrollTop = 0;

		this.setState({
			tabsInfo,
			tableRect,
			tableGroup,
			activeTable,
			hasMoved: false,
			selectedId: name,
			imgSelectedId: null
		});
	};

	addEleToCanvas = (eleName) => {
		let { tabsInfo, eleImgs, activeTab, hasMoved } = this.state;
		let eleSetting = { table_type: 'ele', width: 50, height: 50, positionX: 35, positionY: 35 };
		let $canvasWrap = document.getElementsByClassName('canvasWrap')[0];

		if (!hasMoved) {
			addOffsetX += 15;
			addOffsetY += 15;

			eleSetting.positionX = 35 + addOffsetX;
			eleSetting.positionY = 35 + addOffsetY;
		} else {
			addOffsetX = 0;
			addOffsetY = 0;
		}

		if (eleName === 'wall_5') {
			eleSetting.width = 14;
			eleSetting.height = 110;
		} else if (eleName === 'wall_6') {
			eleSetting.width = 110;
			eleSetting.height = 14;
		} else if (eleName === 'exit') {
			eleSetting.width = 100;
			eleSetting.height = 50;
		}

		eleSetting.name = eleName;

		tabsInfo.map((t) => {
			if (t.name === activeTab) {
				eleImgs.push(eleSetting);
				t.ele = JSON.parse(JSON.stringify(eleImgs));
			}
			return null;
		});

		$canvasWrap.scrollTop = 0;

		this.setState({ eleImgs, tabsInfo, hasMoved: false, selectedId: null, imgSelectedId: eleImgs.length - 1 });
	};

	removeFromCanvas = () => {
		const {
			selectedId,
			tabsInfo,
			tableRect,
			activeTab,
			originTableGroup,
			tableGroup,
			activeTableGroup,
			activeTable,
			tableGroupTab
		} = this.state;
		let new_tableRect = tableRect.slice();
		let item = tableRect.find((t) => {
			return t.name === selectedId;
		});
		let itemIndex = new_tableRect.indexOf(item);
		let itemGroupIndex = null;
		let itemGroup = [];
		let tableIndex = originTableGroup[item.group].indexOf(item.name);
		let NewActiveTable = activeTable;

		tableGroup[item.group].splice(tableIndex, 0, selectedId);

		if (item.group === activeTableGroup) {
			NewActiveTable = tableGroup[item.group][0];
		}

		new_tableRect.splice(itemIndex, 1);

		itemGroup = new_tableRect.map((group) => {
			return group.group;
		});
		itemGroupIndex = itemGroup.indexOf(item.group);

		if (itemGroupIndex === -1) {
			delete tableGroupTab[item.group];
		}

		tabsInfo.map((tab) => {
			if (tab.name === activeTab) {
				tab.table = new_tableRect;
			}
			return null;
		});

		addOffsetX = 0;
		addOffsetY = 0;

		this.setState({ tableRect: new_tableRect, tabsInfo, tableGroup, activeTable: NewActiveTable }, () =>
			this.closeEditForm()
		);
	};

	removeEleFromCanvas = () => {
		let { tabsInfo, eleImgs, imgSelectedId, activeTab } = this.state;
		eleImgs.splice(imgSelectedId, 1);

		tabsInfo.map((t) => {
			if (t.name === activeTab) {
				t.ele = JSON.parse(JSON.stringify(eleImgs));
			}
			return null;
		});

		this.setState({ eleImgs, tabsInfo }, () => this.closeEditForm());
	};

	changeTableType = (type) => {
		this.setState({ shapeType: type, eleType: null }, () => this.checkChangeType());
	};

	changeEleType = (type) => {
		this.setState({ eleType: type, shapeType: null }, () => this.checkChangeType());
	};

	checkChangeType = () => {
		let { tabsInfo, activeTab, eleType, shapeType, tableRect, eleImgs, selectedId, imgSelectedId } = this.state;

		if (shapeType) {
			let new_tableRect = tableRect.slice();
			let findItem = new_tableRect.find((shape) => {
				return shape.name === selectedId;
			});
			let itemIndex = new_tableRect.indexOf(findItem);

			let positionX = 65,
				positionY = 65;

			if (shapeType === 'square') {
				if (findItem['table_type'] === 'circle') {
					positionX = new_tableRect[itemIndex].positionX - 50;
					positionY = new_tableRect[itemIndex].positionY - 50;
				} else {
					positionX = new_tableRect[itemIndex].positionX;
					positionY = new_tableRect[itemIndex].positionY;
				}

				new_tableRect[itemIndex] = {
					...new_tableRect[itemIndex],
					table_type: shapeType,
					width: 100,
					height: 100,
					positionX,
					positionY
				};
			} else if (shapeType === 'horizontalRect') {
				if (findItem['table_type'] === 'circle') {
					positionX = new_tableRect[itemIndex].positionX - 55;
					positionY = new_tableRect[itemIndex].positionY - 55;
				} else {
					positionX = new_tableRect[itemIndex].positionX;
					positionY = new_tableRect[itemIndex].positionY;
				}

				new_tableRect[itemIndex] = {
					...new_tableRect[itemIndex],
					table_type: shapeType,
					width: 120,
					height: 90,
					positionX,
					positionY
				};
			} else if (shapeType === 'verticalRect') {
				if (findItem['table_type'] === 'circle') {
					positionX = new_tableRect[itemIndex].positionX - 35;
					positionY = new_tableRect[itemIndex].positionY - 35;
				} else {
					positionX = new_tableRect[itemIndex].positionX;
					positionY = new_tableRect[itemIndex].positionY;
				}

				new_tableRect[itemIndex] = {
					...new_tableRect[itemIndex],
					table_type: shapeType,
					width: 90,
					height: 120,
					positionX,
					positionY
				};
			} else if (shapeType === 'circle') {
				positionX = new_tableRect[itemIndex].positionX + 35;
				positionY = new_tableRect[itemIndex].positionY + 35;

				new_tableRect[itemIndex] = {
					...new_tableRect[itemIndex],
					table_type: shapeType,
					width: 100,
					height: 100,
					positionX,
					positionY
				};
			}

			tabsInfo.map((t) => {
				if (t.name === activeTab) {
					t.table = JSON.parse(JSON.stringify(new_tableRect));
				}
				return null;
			});

			this.setState({ tabsInfo, tableRect: new_tableRect }, () => this.closeEditForm());
		} else if (eleType) {
			let new_imgs = eleImgs.slice();

			if (eleType === 'wall_5') {
				new_imgs[imgSelectedId] = {
					...new_imgs[imgSelectedId],
					type: 'ele',
					name: eleType,
					width: 14,
					height: 110,
					src: eleList[eleType]
				};
			} else if (eleType === 'wall_6') {
				new_imgs[imgSelectedId] = {
					...new_imgs[imgSelectedId],
					type: 'ele',
					name: eleType,
					width: 110,
					height: 14,
					src: eleList[eleType]
				};
			} else if (eleType === 'exit') {
				new_imgs[imgSelectedId] = {
					...new_imgs[imgSelectedId],
					type: 'ele',
					name: eleType,
					width: 100,
					height: 50,
					src: eleList[eleType]
				};
			} else {
				new_imgs[imgSelectedId] = {
					...new_imgs[imgSelectedId],
					type: 'ele',
					name: eleType,
					width: 50,
					height: 50,
					src: eleList[eleType]
				};
			}

			tabsInfo.map((t) => {
				if (t.name === activeTab) {
					t.ele = JSON.parse(JSON.stringify(new_imgs));
				}
				return null;
			});

			this.setState({ tabsInfo, eleImgs: new_imgs }, () => this.closeEditForm());
		}
	};

	switchTab = (newActiveTab) => {
		const { tabsInfo, eleImgs, tableRect, activeTab } = this.state;
		let newTableRect = [],
			newEle = [];

		tabsInfo.map((t) => {
			if (t.name === newActiveTab) {
				newTableRect = t.table;
				newEle = t.ele;
			} else if (t.name === activeTab) {
				t.table = tableRect;
				t.ele = eleImgs;
			}
			return null;
		});

		addOffsetX = 0;
		addOffsetY = 0;

		this.setState({ activeTab: newActiveTab, tableRect: newTableRect, eleImgs: newEle, firstIn: false }, () =>
			this.closeEditForm()
		);
	};

	switchSetting = (setting) => {
		this.setState({ setting });
	};

	handleChooseTable = (table) => {
		this.setState({ activeTable: table });
	};

	handleChooseTableGroup = (group) => {
		let { tableGroup } = this.state;
		let activeTable = tableGroup[group][0];

		this.setState({ activeTableGroup: group, activeTable });
	};

	selectShape = (shape) => {
		let { tableRect, tabsInfo, activeTab } = this.state;
		const items = tableRect.slice();
		let findItem, itemIndex;

		if (shape) {
			findItem = tableRect.find((i) => {
				return i.name === shape.name;
			});
			itemIndex = items.indexOf(findItem);

			items.splice(itemIndex, 1);
			items.push(findItem);

			tabsInfo.map((tab) => {
				if (tab.name === activeTab) {
					tab.table = JSON.parse(JSON.stringify(items));
				}

				return null;
			});

			this.setState({ selectedId: shape.name, tableRect: items, tabsInfo, imgSelectedId: null }, () =>
				this.showEdit(shape)
			);
		} else {
			this.setState({ selectedId: shape, imgSelectedId: null }, () => this.closeEditForm());
		}
	};

	selectImg = (ele, index) => {
		if (ele !== null) {
			const { tabsInfo, activeTab, eleImgs } = this.state;
			const new_eleImgs = eleImgs.slice();
			const ele = eleImgs[index];

			new_eleImgs.splice(index, 1);
			new_eleImgs.push(ele);

			tabsInfo.map((tab) => {
				if (tab.name === activeTab) {
					tab.ele = JSON.parse(JSON.stringify(new_eleImgs));
				}
				return null;
			});

			this.setState(
				{ tabsInfo, eleImgs: new_eleImgs, imgSelectedId: new_eleImgs.length - 1, selectedId: null },
				() => {
					this.showEdit(ele);
				}
			);
		} else {
			this.setState({ imgSelectedId: ele, selectedId: null }, () => this.closeEditForm());
		}
	};

	setRectangles = (rect) => {
		const { tableRect, tabsInfo, activeTab } = this.state;
		const index = tableRect
			.map((r) => {
				return r.name;
			})
			.indexOf(rect.name);

		const rects = tableRect.slice();

		rects[index] = rect;

		tabsInfo.map((tab) => {
			if (tab.name === activeTab) {
				tab.table = rects;
			}
			return null;
		});

		this.setState({ tableRect: rects, tabsInfo, hasMoved: true });
	};

	setImage = (index, img) => {
		const { eleImgs } = this.state;
		const newImgs = eleImgs.slice();

		newImgs[index] = img;

		this.setState({ eleImgs: newImgs, hasMoved: true });
	};

	showEdit = (shapeProps) => {
		let editForm = document.getElementById('editForm');

		editForm.classList.remove('hide');

		if (shapeProps.table_type !== 'ele') {
			this.setState({ shapeType: shapeProps.table_type });
		} else {
			this.setState({ shapeType: shapeProps.table_type, eleType: shapeProps.name });
		}
	};

	closeEditForm = () => {
		let editForm = document.getElementById('editForm');
		editForm.classList.add('hide');
		editForm.removeAttribute('style');

		this.setState({ selectedId: null, imgSelectedId: null });
	};

	toogleSetting = (e) => {
		let cavasSetting = document.getElementsByClassName('cavasSetting')[0];
		let toggleIcon = document.getElementById('toggleIcon');
		let open = cavasSetting.classList.contains('hideSetting');

		if (!open) {
			cavasSetting.classList.add('hideSetting');
			toggleIcon.classList.remove('fa-angle-double-left');
			toggleIcon.classList.add('fa-angle-double-right');
			this.resetCanvasWidth(0);
		} else {
			cavasSetting.classList.remove('hideSetting');
			toggleIcon.classList.add('fa-angle-double-left');
			toggleIcon.classList.remove('fa-angle-double-right');
			this.resetCanvasWidth(350);
		}
	};

	resetCanvasWidth = (spareWidth) => {
		let actual_w_w = window.innerWidth - spareWidth;

		let scaleRatio =
			JSON.parse((actual_w_w / this.state.w_w).toFixed(2)) > 1
				? 1
				: JSON.parse((actual_w_w / this.state.w_w).toFixed(2));

		this.setState(
			{ scaleRatio }
			// ()=>this.closeEditForm()
		);
	};

	goBackWarning = () => {
		this.setState({ showWarning: true });
	};

	leave = () => {
		browserHistory.push({
			pathname: '/dashboard/setting/tablemap_setting'
		});
	};

	stay = () => {
		this.setState({ showWarning: false });
	};

	setDragging = (setting) => {
		let konvajsContent = document.getElementsByClassName('konvajs-content')[0];
		if (setting) {
			konvajsContent.classList.add('draggingActive');
		} else {
			konvajsContent.classList.remove('draggingActive');
		}
	};

	submit = () => {
		this.setState({ sending: true });
		this.submitTableMap();
	};

	submitTableMap = async () => {
		let { tabsInfo, activeTab, tableRect, eleImgs } = this.state;
		let canvas_id = [];
		let bookingSetting = JSON.stringify({
			booking_settings: {
				canvas_enabled: this.props.location.state.tableMapSetting
			}
		});

		tabsInfo.map((tab) => {
			if (tab.name === activeTab) {
				tab.table = tableRect;
				tab.ele = eleImgs;
			}
			if (tab.id) canvas_id.push(tab.id);
			return null;
		});

		try {
			await this.API.updateTableMap(tabsInfo, canvas_id).then((data) => {
				tabsInfo = data.canvas;
			});

			await this.API.updateBookingSetting(bookingSetting);

			this.setState({ sending: false, tabsInfo }, () => {
				this.props.router.push('/dashboard/setting/tablemap_setting');
			});
		} catch (err) {
			window.app.alert.setMessage('請稍後再試', 'error');
			this.setState({ sending: false });
		}
	};

	renderLoading = () => {
		return (
			<div className="tableMapLoading">
				<div>
					<img src={require('../../../images/logo_140_50.png')} alt="logo" />
				</div>
			</div>
		);
	};

	getLineGuideStops = (skipShape) => {
		let stageRef = this.refs.stageRef;

		let vertical = [ 0, stageRef.width() / 2, stageRef.width() ];
		let horizontal = [ 0, stageRef.height() / 2, stageRef.height() ];

		stageRef.find('.object').forEach((guideItem) => {
			if (guideItem === skipShape) {
				return;
			}

			let box = guideItem.getClientRect({ skipTransform: true });
			let orignShape = guideItem.getAttrs();

			vertical.push([ orignShape.x, orignShape.x + box.width, orignShape.x + box.width / 2 ]);
			horizontal.push([ orignShape.y, orignShape.y + box.height, orignShape.y + box.height / 2 ]);
		});

		return {
			vertical: vertical.flat(),
			horizontal: horizontal.flat()
		};
	};

	getObjectSnappingEdges = (node) => {
		let box = node.getClientRect({ skipTransform: true });
		let orignShape = node.getAttrs();

		return {
			vertical: [
				{
					guide: Math.round(orignShape.x),
					offset: Math.round(node.x() - orignShape.x),
					snap: 'start'
				},
				{
					guide: Math.round(orignShape.x + box.width / 2),
					offset: Math.round(node.x() - orignShape.x - box.width / 2),
					snap: 'center'
				},
				{
					guide: Math.round(orignShape.x + box.width),
					offset: Math.round(node.x() - orignShape.x - box.width),
					snap: 'end'
				}
			],
			horizontal: [
				{
					guide: Math.round(orignShape.y),
					offset: Math.round(node.y() - orignShape.y),
					snap: 'start'
				},
				{
					guide: Math.round(orignShape.y + box.height / 2),
					offset: Math.round(node.y() - orignShape.y - box.height / 2),
					snap: 'center'
				},
				{
					guide: Math.round(orignShape.y + box.height),
					offset: Math.round(node.y() - orignShape.y - box.height + 1),
					snap: 'end'
				}
			]
		};
	};

	getGuides = (lineGuideStops, itemBounds) => {
		let resultV = [];
		let resultH = [];

		lineGuideStops.vertical.forEach((lineGuide) => {
			itemBounds.vertical.forEach((itemBound) => {
				var diff = Math.abs(lineGuide - itemBound.guide);
				if (diff < GUIDELINE_OFFSET) {
					resultV.push({
						lineGuide: lineGuide,
						diff: diff,
						snap: itemBound.snap,
						offset: itemBound.offset
					});
				}
			});
		});

		lineGuideStops.horizontal.forEach((lineGuide) => {
			itemBounds.horizontal.forEach((itemBound) => {
				var diff = Math.abs(lineGuide - itemBound.guide);
				if (diff < GUIDELINE_OFFSET) {
					resultH.push({
						lineGuide: lineGuide,
						diff: diff,
						snap: itemBound.snap,
						offset: itemBound.offset
					});
				}
			});
		});

		let guides = [];

		// find closest snap
		let minV = resultV.sort((a, b) => a.diff - b.diff)[0];
		let minH = resultH.sort((a, b) => a.diff - b.diff)[0];

		if (minV) {
			guides.push({
				lineGuide: minV.lineGuide,
				offset: minV.offset,
				orientation: 'V',
				snap: minV.snap
			});
		}

		if (minH) {
			guides.push({
				lineGuide: minH.lineGuide,
				offset: minH.offset,
				orientation: 'H',
				snap: minH.snap
			});
		}

		return guides;
	};

	drawGuides = (guides) => {
		let layer = this.refs.layerRef;

		guides.forEach((lg) => {
			if (lg.orientation === 'H') {
				let line = new Konva.Line({
					points: [ -6000, lg.lineGuide, 6000, lg.lineGuide ],
					stroke: 'rgb(0, 161, 255)',
					strokeWidth: 1,
					name: 'guid-line',
					dash: [ 4, 6 ]
				});

				layer.add(line);
				layer.batchDraw();
			} else if (lg.orientation === 'V') {
				let line = new Konva.Line({
					points: [ lg.lineGuide, -6000, lg.lineGuide, 6000 ],
					stroke: 'rgb(0, 161, 255)',
					strokeWidth: 1,
					name: 'guid-line',
					dash: [ 4, 6 ]
				});

				layer.add(line);
				layer.batchDraw();
			}
		});
	};

	layerMove = (e) => {
		let layer = this.refs.layerRef;

		layer.find('.guid-line').destroy();

		let lineGuideStops = this.getLineGuideStops(e.target);
		let itemBounds = this.getObjectSnappingEdges(e.target);
		let guides = this.getGuides(lineGuideStops, itemBounds);

		// do nothing of no snapping
		if (!guides.length) {
			return;
		}

		this.drawGuides(guides);

		guides.forEach((lg) => {
			switch (lg.snap) {
				case 'start': {
					switch (lg.orientation) {
						case 'V': {
							e.target.x(lg.lineGuide + lg.offset);
							break;
						}
						case 'H': {
							e.target.y(lg.lineGuide + lg.offset);
							break;
						}
						default:
							break;
					}
					break;
				}
				case 'center': {
					switch (lg.orientation) {
						case 'V': {
							e.target.x(lg.lineGuide + lg.offset);
							break;
						}
						case 'H': {
							e.target.y(lg.lineGuide + lg.offset);
							break;
						}
						default:
							break;
					}
					break;
				}
				case 'end': {
					switch (lg.orientation) {
						case 'V': {
							e.target.x(lg.lineGuide + lg.offset);
							break;
						}
						case 'H': {
							e.target.y(lg.lineGuide + lg.offset);
							break;
						}
						default:
							break;
					}
					break;
				}
				default:
					break;
			}
		});
	};

	layerMoveEnd = () => {
		let layer = this.refs.layerRef;
		layer.find('.guid-line').destroy();

		layer.batchDraw();
	};

	render() {
		let {
			loading,
			w_w,
			w_h,
			scaleRatio,
			tableRect,
			eleImgs,
			selectedId,
			imgSelectedId,
			showWarning,
			firstIn
		} = this.state;
		if (loading) return this.renderLoading();
		let actual_w_h = window.innerHeight - canvasTabWrapHeight - editFormHeight;
		// let canvasWrapHeight = actual_w_h >= w_h * scaleRatio ? w_h * scaleRatio : actual_w_h;
		let canvasMarginBottom = actual_w_h >= w_h * scaleRatio ? 0 : 20;

		return (
			<div className="tableMapCavas">
				{this.state.sending && (
					<div className="lightBoxLayer">
						<h4>儲存中</h4>
					</div>
				)}

				<div className="canvas">
					<div className="cavasSetting">
						<span className="toggleSetting" onClick={(e) => this.toogleSetting(e)}>
							<i id="toggleIcon" className="fa fa-angle-double-left" aria-hidden="true" />
						</span>
						<div className="settingWrap">
							{this.renderSettingEle()}
							{this.renderSetting()}
						</div>
						<p className="goBack">
							<span onClick={() => this.goBackWarning()}>
								<i className="fa fa-arrow-left" aria-hidden="true" />
								<span>回預約系統設定</span>
							</span>
						</p>
					</div>
					<div />
					<div className="canvasWrapOuter">
						<div className="tabWrap">
							<div className="tabs">
								<div className="tabsContainer">{this.renderTabs()}</div>
							</div>
							{firstIn && this.scrollToTab()}

							<div className="save">
								<button className="msBtn msBtnMain" onClick={() => this.submit()}>
									儲存
								</button>
							</div>
						</div>
						<div style={{ height: window.innerHeight - 70 }}>
							<div className="editFormWrap">
								<div id="editForm" className="editForm hide">
									<div className="editForm__body">{this.renderType()}</div>
								</div>
							</div>
							<div className={`canvasWrap`} style={{ height: actual_w_h }}>
								<Stage
									ref="stageRef"
									name={'container'}
									style={{ marginBottom: canvasMarginBottom, marginTop: canvasMarginBottom }}
									width={w_w * scaleRatio}
									height={w_h * scaleRatio}
									scaleX={scaleRatio}
									scaleY={scaleRatio}
									onMouseDown={(e) => {
										const clickedOnEmpty = e.target === e.target.getStage();

										if (clickedOnEmpty) {
											this.selectShape(null);
											this.selectImg(null);
										}
									}}
								>
									<Layer
										ref="layerRef"
										onDragMove={(e) => this.layerMove(e)}
										onDragEnd={(e) => this.layerMoveEnd(e)}
									>
										{eleImgs.map((img, i) => {
											return (
												<URLImage
													key={i}
													scaleRatio={scaleRatio}
													img={img}
													index={i}
													isMobile={this.isMobile}
													imgSelected={i === imgSelectedId}
													setDragging={this.setDragging}
													onChange={this.setImage}
													selectImg={this.selectImg}
													closeEditForm={this.closeEditForm}
												/>
											);
										})}

										{tableRect.map((rect, i) => {
											if (rect.table_type !== 'circle') {
												return (
													<Rectangle
														key={i}
														scaleRatio={scaleRatio}
														isMobile={this.isMobile}
														shapeProps={rect}
														isSelected={rect.name === selectedId}
														onChange={this.setRectangles}
														selectShape={this.selectShape}
														setDragging={this.setDragging}
														closeEditForm={this.closeEditForm}
													/>
												);
											} else if (rect.table_type === 'circle') {
												return (
													<CircleShap
														key={i}
														scaleRatio={scaleRatio}
														isMobile={this.isMobile}
														shapeProps={rect}
														isSelected={rect.name === selectedId}
														onChange={this.setRectangles}
														selectShape={this.selectShape}
														setDragging={this.setDragging}
														closeEditForm={this.closeEditForm}
													/>
												);
											} else {
												return null;
											}
										})}
									</Layer>
								</Stage>
							</div>
						</div>
					</div>
				</div>
				{showWarning && <GoBackWarning leave={this.leave} stay={this.stay} />}
			</div>
		);
	}
}

const GoBackWarning = ({ leave, stay }) => {
	return (
		<div className="warning_layout">
			<div className="warning">
				<div className="warning_body">
					<h3>確定要離開嗎？</h3>
					<p>若未儲存, 系統將不會儲存您所做的變更</p>
				</div>
				<div className="warning_footer">
					<button className="btn" onClick={() => stay()}>
						取消
					</button>
					<button className="btn" onClick={() => leave()}>
						離開
					</button>
				</div>
			</div>
		</div>
	);
};

const Rectangle = ({
	shapeProps,
	isSelected,
	onChange,
	isMobile,
	selectShape,
	closeEditForm,
	setDragging,
	scaleRatio
}) => {
	const RectangleRef = React.useRef();
	const [ visible, setVisible ] = useState(true);
	const [ selectedVisible, setSeletedVisible ] = useState(false);

	React.useEffect(
		() => {
			if (isSelected) {
				setSeletedVisible(true);
			} else {
				setSeletedVisible(false);
			}
		},
		[ isSelected ]
	);

	return (
		<Group
			x={shapeProps.positionX}
			y={shapeProps.positionY}
			name={'object'}
			draggable
			onDragStart={() => {
				setVisible(false);
				setDragging(true);
				closeEditForm();
			}}
			onClick={() => {
				if (!isMobile) selectShape({ ...shapeProps });
			}}
			onTap={() => {
				selectShape({ ...shapeProps });
			}}
			onDragEnd={(e) => {
				setVisible(true);
				setDragging(false);

				onChange({
					...shapeProps,
					positionX: e.target.x(),
					positionY: e.target.y()
				});
			}}
			dragBoundFunc={(pos) => {
				const boundX = 1024 * scaleRatio,
					boundY = 700 * scaleRatio;
				let posX = pos.x,
					posY = pos.y;

				if (pos.x < 10) {
					posX = 10;
				} else if (pos.x + shapeProps.width * scaleRatio > boundX - 10 * scaleRatio) {
					posX = boundX - shapeProps.width * scaleRatio - 10 * scaleRatio;
				}

				if (pos.y < 10) {
					posY = 10;
				} else if (pos.y + shapeProps.height * scaleRatio > boundY - 10 * scaleRatio) {
					posY = boundY - shapeProps.height * scaleRatio - 10 * scaleRatio;
				}

				return {
					x: posX,
					y: posY
				};
			}}
		>
			<Rect
				visible={selectedVisible}
				width={shapeProps.width + 16}
				height={shapeProps.height + 16}
				x={-8}
				y={-8}
				fill={'rgba(0,0,0,0)'}
				cornerRadius={8}
				stroke={$selectedColor}
				strokeWidth={3}
			/>
			<Rect
				ref={RectangleRef}
				{...shapeProps}
				fill={visible ? '#ddd' : '#aaa'}
				cornerRadius={8}
				stroke={'#aaa'}
				strokeWidth={2}
			/>

			<Shape
				visible={visible}
				sceneFunc={function sceneFunc(context) {
					let centerX = shapeProps.width / 2,
						centerY = shapeProps.height / 2;
					let group_text = shapeProps.group;
					let text_1 = shapeProps.name,
						text_2 = '(' + shapeProps.min_attendance + '-' + shapeProps.max_attendance + '人)';
					let group_text_w = context.measureText(group_text).width / 2;
					let text_w_1 = context.measureText(text_1).width / 2,
						text_w_2 = context.measureText(text_2).width / 2;

					context.fillText(group_text, centerX - group_text_w, centerY - 15);
					context.fillText(text_1, centerX - text_w_1, centerY);
					context.fillText(text_2, centerX - text_w_2, centerY + 15);
				}}
			/>
		</Group>
	);
};

const CircleShap = ({
	shapeProps,
	isSelected,
	onChange,
	isMobile,
	selectShape,
	closeEditForm,
	setDragging,
	scaleRatio
}) => {
	const shapeRef = React.useRef();
	const [ visible, setVisible ] = useState(true);
	const [ selectedVisible, setSeletedVisible ] = useState(false);

	React.useEffect(
		() => {
			if (isSelected) {
				setSeletedVisible(true);
			} else {
				setSeletedVisible(false);
			}
		},
		[ isSelected ]
	);

	return (
		<Group
			draggable
			x={shapeProps.positionX}
			y={shapeProps.positionY}
			name={'object'}
			onClick={() => {
				if (!isMobile) selectShape({ ...shapeProps });
			}}
			onTap={() => {
				selectShape({ ...shapeProps });
			}}
			onDragStart={() => {
				setVisible(false);
				setDragging(true);
				closeEditForm();
			}}
			onDragEnd={(e) => {
				setVisible(true);
				setDragging(false);

				onChange({
					...shapeProps,
					positionX: e.target.x(),
					positionY: e.target.y()
				});
			}}
			dragBoundFunc={(pos) => {
				const boundX = 1024 * scaleRatio,
					boundY = 700 * scaleRatio;
				let posX = pos.x,
					posY = pos.y;

				if (pos.x < shapeProps.width / 2 * scaleRatio + 10) {
					posX = shapeProps.width / 2 * scaleRatio + 10;
				} else if (pos.x + shapeProps.width / 2 * scaleRatio + 10 > boundX - 10 * scaleRatio) {
					posX = boundX - shapeProps.width / 2 * scaleRatio - 10 * scaleRatio;
				}

				if (pos.y < shapeProps.height / 2 * scaleRatio + 10) {
					posY = shapeProps.height / 2 * scaleRatio + 10;
				} else if (pos.y + shapeProps.height / 2 * scaleRatio + 10 > boundY - 10 * scaleRatio) {
					posY = boundY - shapeProps.height / 2 * scaleRatio - 10 * scaleRatio;
				}

				return {
					x: posX,
					y: posY
				};
			}}
		>
			<Circle
				visible={selectedVisible}
				width={shapeProps.width + 16}
				height={shapeProps.height + 16}
				// x={shapeProps.positionX}
				// y={shapeProps.positionY}
				fill={'rgba(0,0,0,0)'}
				cornerRadius={8}
				stroke={$selectedColor}
				strokeWidth={3}
			/>

			<Circle
				ref={shapeRef}
				{...shapeProps}
				cornerRadius={8}
				fill={visible ? '#ddd' : '#aaa'}
				stroke={'#aaa'}
				strokeWidth={2}
			/>

			<Shape
				visible={visible}
				sceneFunc={function sceneFunc(context, shape) {
					let group_text = shapeProps.group;
					let text_1 = shapeProps.name,
						text_2 = '(' + shapeProps.min_attendance + '-' + shapeProps.max_attendance + '人)';
					let group_text_w = context.measureText(group_text).width / 2;
					let text_w_1 = context.measureText(text_1).width / 2,
						text_w_2 = context.measureText(text_2).width / 2;

					context.fillText(group_text, 0 - group_text_w, -13);
					context.fillText(text_1, 0 - text_w_1, 2);
					context.fillText(text_2, 0 - text_w_2, 16);
				}}
			/>
		</Group>
	);
};

const URLImage = ({
	img,
	imgSelected,
	onChange,
	isMobile,
	index,
	selectImg,
	closeEditForm,
	setDragging,
	scaleRatio
}) => {
	const imgRef = React.useRef();
	const [ image ] = useImage(eleList[img.name]);
	const [ selectedVisible, setSeletedVisible ] = useState(false);

	React.useEffect(
		() => {
			if (imgSelected) {
				setSeletedVisible(true);
			} else {
				setSeletedVisible(false);
			}
		},
		[ imgSelected ]
	);

	return (
		<Group
			draggable
			x={img.positionX}
			y={img.positionY}
			onClick={() => {
				if (!isMobile) selectImg({ ...img }, index);
			}}
			onTap={() => {
				selectImg({ ...img }, index);
			}}
			onDragStart={() => {
				setDragging(true);
				closeEditForm();
			}}
			onDragEnd={(e) => {
				setDragging(false);
				onChange(index, {
					...img,
					positionX: e.target.x(),
					positionY: e.target.y()
				});
			}}
			dragBoundFunc={(pos) => {
				const boundX = 1024 * scaleRatio,
					boundY = 700 * scaleRatio;
				let posX = pos.x,
					posY = pos.y;

				if (pos.x < 10) {
					posX = 10;
				} else if (pos.x + img.width * scaleRatio > boundX - 10 * scaleRatio) {
					posX = boundX - img.width * scaleRatio - 10 * scaleRatio;
				}

				if (pos.y < 10) {
					posY = 10;
				} else if (pos.y + img.height * scaleRatio > boundY - 10 * scaleRatio) {
					posY = boundY - img.height * scaleRatio - 10 * scaleRatio;
				}

				return {
					x: posX,
					y: posY
				};
			}}
		>
			<Rect
				visible={selectedVisible}
				width={img.width + 16}
				height={img.height + 16}
				x={-8}
				y={-8}
				fill={'rgba(0,0,0,0)'}
				stroke={$selectedColor}
				strokeWidth={3}
			/>
			<Image ref={imgRef} width={img.width} height={img.height} image={image} />
		</Group>
	);
};
