import axios from 'axios';
import { config } from '../../../utils/config';


const BookingSearchResquest = axios.create({
	baseURL: config.domain,
    withCredentials: true,
});


export const getBookingSearchRes = (keyword) => BookingSearchResquest.get(`/dashboard/bookings/searching?keyword=${keyword}`)
export const getNewModuleSearchRes = (keyword) => BookingSearchResquest.get(`/dashboard/settings_and_data/searching?keyword=${keyword}`)
