import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { Table } from './Table';

const headerHeight = 50,
	footerHeight = 66,
	totalHeight = headerHeight + footerHeight;

export const TablePicker = ({ date, current, timelineTables, closeTimelineSeat, queueSeatedData, checkTable }) => {
	const [ tableGroup, setTableGroup ] = useState({});
	const [ activeGroup, setActiveGroup ] = useState('all');
	const [ choosenTable, setChoosenTable ] = useState([]);

	useEffect(
		() => {
			let groupTables = {};

			timelineTables.forEach((t) => {
				const group = t.group;
				if (!groupTables.hasOwnProperty(group)) {
					groupTables[group] = [];
					groupTables[group].push(t);
				} else {
					groupTables[group].push(t);
				}
			});

			setTableGroup(groupTables);
		},
		[ timelineTables ]
	);

	const changeGroup = (e) => {
		const tableGroupTxt = e.target.value;

		setActiveGroup(tableGroupTxt);
	};

	const selectTable = useCallback(
		(table) => {
			const newTable = _.cloneDeep(choosenTable);
			const findTableIndex = newTable.findIndex((t) => t.table_number === table.table_number);

			if (findTableIndex === -1) {
				newTable.push(table);
			} else {
				newTable.splice(findTableIndex, 1);
			}

			setChoosenTable(newTable);
		},
		[ choosenTable ]
	);

	const renderTableGroups = useCallback(
		() => {
			if (activeGroup === 'all') {
				return Object.keys(tableGroup).map((group) => {
					return (
						<section key={group}>
							<div className="groupName">{group}</div>

							<div className="groupTableWrap">
								{tableGroup[group].map((table) => {
									const findTable = choosenTable.findIndex(
										(t) => t.table_number === table.table_number
									);

									const newTimeline = _.sortBy(
										table.timeline,
										'service_start_time'
									).filter((booking) => {
										const bookingDate = moment(new Date(booking.service_start_time)).format('YYYY/MM/DD');
										const newDate = moment(new Date(date)).format('YYYY/MM/DD');
										return bookingDate === newDate && booking.status !== 'finish';
									});

									return (
										<Table
											key={table.table_number}
											table={table}
											timeline={newTimeline}
											findTable={findTable}
											current={current}
											selectTable={selectTable}
										/>
									);
								})}
							</div>
						</section>
					);
				});
			} else {
				return (
					<section>
						<div className="groupName">{activeGroup}</div>

						<div className="groupTableWrap">
							{tableGroup[activeGroup].map((table) => {
								const findTable = choosenTable.findIndex((t) => t.table_number === table.table_number);

								const newTimeline = _.sortBy(table.timeline, 'booking_datetime').filter((booking) => {
									const bookingDate = moment(new Date(booking.date)).format('YYYY/MM/DD');
									const newDate = moment(new Date(date)).format('YYYY/MM/DD');
									return bookingDate === newDate;
								});

								return (
									<Table
										key={table.table_number}
										table={table}
										timeline={newTimeline}
										findTable={findTable}
										current={current}
										selectTable={selectTable}
									/>
								);
							})}
						</div>
					</section>
				);
			}
		},
		[ tableGroup, activeGroup, choosenTable, current, date, selectTable ]
	);

	const renderGroupSelect = () => {
		return (
			<select onChange={(e) => changeGroup(e)}>
				<option key="all" value="all">
					全部
				</option>
				{Object.keys(tableGroup).map((group) => (
					<option key={group} value={group}>
						{group}
					</option>
				))}
			</select>
		);
	};

	const renderQueueAttendance = useCallback(
		() => {
			const seatMaxAttendace = choosenTable.reduce((prev, cur) =>  prev + cur.max_seat, 0);

			const chosenTableStyle = choosenTable.length > 0 ? 'chosenTableStyle' : '';
			return (
				<p>
					<span>入座人數</span>
					<span className="queueAttendance"> {queueSeatedData.people_count} </span>人，
					<span>
						已選 <span className={`attendanceStyle ${chosenTableStyle}`}>{choosenTable.length}</span> 張，可容納<span className={`attendanceStyle ${chosenTableStyle}`}> {seatMaxAttendace} </span>人
					</span>
				</p>
			);
		},
		[ choosenTable, queueSeatedData ]
	);

	return (
		<div className="TimelineSeatPanelWrap">
			<button className="closeTimelineSeatBtn" onClick={() => closeTimelineSeat()} />
			<section className="TimelineSeatPanel" style={{ height: window.innerHeight - 100 }}>
				<div className="TimelineSeatPanel__header text-center">
					{renderQueueAttendance()}
					<span className="seatedDateTime">預約日期：{current}</span>
				</div>
				<div className="TimelineSeatPanel__body" style={{ height: window.innerHeight - 100 - totalHeight }}>
					{renderGroupSelect()}
					{renderTableGroups()}
				</div>
				<div className="TimelineSeatPanel__footer">
					<div className="btnWrap text-center">
						<button className="btn-cancel" onClick={() => closeTimelineSeat()}>
							取消
						</button>
						<button
							className="btn-check"
							disabled={choosenTable.length === 0}
							onClick={() => checkTable(choosenTable)}
						>
							確定
						</button>
					</div>
				</div>
			</section>
		</div>
	);
};
