import React from 'react';

function UserProfile({
  userId,
  name,
  jobTitle,
  showError,
  handleInputChange,
  submit
}) {
  return (
    <div className='userBLock'>
      <div className='title'>帳號資訊</div>
      <div className='row__input'>
        <div className="subtitle">帳號</div>
        <input type="text" value={userId} disabled />
      </div>
      <div className='row__input'>
        <div className="subtitle">姓名*</div>
        <input
          type="text"
          name="name"
          className={`${showError ? 'input_error' : ''}`}
          placeholder='輸入姓名'
          value={name}
          onChange={handleInputChange}
        />
        { showError && <div className='text_error'>姓名不可為空白</div> }
      </div>
      <div className='row__input'>
        <div className="subtitle">職稱</div>
        <input
          type="text"
          name="job_title"
          placeholder='輸入職稱'
          value={jobTitle}
          onChange={handleInputChange}
        />
      </div>
      <hr />
      <div className='fix_btn'>
        <button className='btn_submit' onClick={submit}>儲存</button>
      </div>
    </div>
  )
}

export default UserProfile