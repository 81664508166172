import axios from 'axios';
import { config } from '../../../utils/config';

const menuUserAxios = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

/* 取得綁定狀態 */
export const getMenuUserInfo = () => menuUserAxios.get('/dashboard/menu_user');

/* 搜尋 menu user */
export const searchMenuUser = (menuUserId) => menuUserAxios.get(`/dashboard/menu_user/app/${menuUserId}`);

/* 確認綁定 menu user & 發送驗證碼 */
export const createMenuUser = (menuUserId) => menuUserAxios.post('/dashboard/menu_user', menuUserId);

/* 重新發送驗證碼 */
export const resendVerificationCode = () => menuUserAxios.post('/dashboard/menu_user/resend_verification_code');

/* 綁定 */
export const bindMenuUser = (menuUser) => menuUserAxios.post('/dashboard/menu_user/bind_menu', menuUser);

/* 解除綁定 */
export const unbindMenuUser = () => menuUserAxios.post('/dashboard/menu_user/unbind_menu');
