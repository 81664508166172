import { useCallback, useState } from 'react';
import { getBookingSearchRes } from '../api/getSearchRes';

export default (setInit) => {
	const [ BookingLoading, setBookingLoading ] = useState(false);
	const [ bookingSearchRes, setBookingSearchRes ] = useState({
		today: [],
		future: [],
		past: []
	});


	return {
		BookingLoading,
		bookingSearchRes,
		setBookingSearchRes,
		BookingSubmit: useCallback(
			async (searchInput) => {
				setBookingLoading(true);
				await getBookingSearchRes(searchInput)
					.then(({ data }) => {
						setInit(true);
						setBookingSearchRes(data);
						setBookingLoading(false);
					})
					.catch((err) => {
						console.log('search err---', err);
					});
			},
			[ setInit ]
		)
	};
};
