import React from 'react';
import '../../../stylesheets/new_module_warning.css';


export const WarningTemplate =({cancelWarning, confirmWarning, title, children, type=''})=>{
	return(
		<div className="waringWrap">
            <div className="waringLayout" />
			<div className="waring">
				<div className="waring__header">{title}</div>
				<div className="waring__body">
					{children}
				</div>
				<div className="waring__footer">
					<button className="waring-cancel" onClick={() => cancelWarning(type)}>
						取消
					</button>
					<button className="waring-check" onClick={() => confirmWarning(type)}>
						確定
					</button>
				</div>
			</div>
		</div>
	)
}


export const TimelineWarningTemplate =({cancelWarning=()=>{}, confirmWarning=()=>{}, title, children, conflict=false})=>{
	const renderFooter = ()=>{
		if(conflict){
			return(
				<React.Fragment>
					<button className="waring-cancel" onClick={() => confirmWarning('seated')}>
						入座
					</button>
					<button className="waring-check" onClick={() => confirmWarning('swap')}>
						交換
					</button>
				</React.Fragment>
			)
		}

		return(
			<button className="waring-check" onClick={() => confirmWarning('seated')}>
				入座
			</button>
		)
	}
	return(
		<div className="waringWrap">
            <div className="waringLayout" onClick={() => cancelWarning()}/>
			<div className="waring">
                <button className="waring__closeBtn" onClick={() => cancelWarning()}/>
				<div className="waring__header">
                    {title}
                </div>
				<div className="waring__body">
					{children}
				</div>
				<div className="waring__footer">
					{renderFooter()}
				</div>
			</div>
		</div>
	)
}



