import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import { handleWebsocketEventRegistry, cancelWebsocketSubscription } from '../../libs/handler';
import moment from 'moment';
import { Modal } from 'antd';
// import { Link } from 'react-router';
// import { config } from '../../utils/config';
// import { handleError } from '../../libs/handler';
// import $ from 'jquery';
// import ShopIcon from "../icons/ShopIcon";
// import UserIcon from "../icons/UserIcon";
import CustomerIcon from '../icons/CustomerIcon';
import CallIcon from '../icons/CallIcon';
import QueueIcon from '../icons/QueueIcon';
import BookingIcon from '../icons/BookingIcon';
import NewModuleIcon from '../icons/NewModuleIcon';
import CouponIcon from '../icons/Coupon';
import SettingIcon from '../icons/Setting';
import AnnouncementIcon from '../icons/AnnouncementIcon';

import OfficialAnnouncementModal from '../announcement/announcementPopup/AnnouncementOfficialModal';
import PreviewAnnouncementModal from '../announcement/announcementPopup/AnnouncementPreviewModal';

import PageApi from './pageApi';

import {
	announcementOfficialFetchToday,
	updateDayAnnouncements,
  announcementSetting,
  showAnnouncementPreviewModal
} from '../../actions/announcementAction';

import { getDisplayOfficialAnnouncements } from '../announcement/api/OfficialAnnouncementApi';

const page = new PageApi();

const day_list = ['日', '一', '二', '三', '四', '五', '六'];

const DashboardBox = () => {
	const auth = useSelector((state) => state.auth);
	const { shop_name } = auth.user;

	const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
		todayOfficialAnnouncements,
		dayAnnouncements
	} = announcementListReducer;

	const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    showPreviewAnnouncementModal
  } = announcementInfo;
  const dispatch = useDispatch();

	const [ showTodayAnnouncement, setShowTodayAnnouncement ] = useState(false);
	const [ displayOfficialAnnouncements, setDisplayOfficialAnnouncements ] = useState([]);
	const announcementCount = todayOfficialAnnouncements.length + dayAnnouncements.length;

	useEffect(() => {
		const announcementBlock = document.getElementsByClassName('announcementBlock')[0];
		const announcementBtn = document.getElementsByClassName('announcement_float_btn')[0];

		if(announcementBlock && !announcementBtn) {
			showTodayAnnouncement ? announcementBlock.style.bottom = '0' : announcementBlock.style.bottom = '-424px'
		}

		// 768px 以下
		if(announcementBtn && announcementBtn.classList.contains('show')) {
			showTodayAnnouncement ? announcementBlock.style.bottom = '0' : announcementBlock.style.bottom = '-619px'
		}

	}, [ showTodayAnnouncement ]);

	useEffect(
		() => {
			window.scrollTo(0, 0);
			sessionStorage.setItem('userId', shop_name);
		},
		[ shop_name ]
	);

	useEffect(() => {
		getOfficialAnnouncementsDisplay();
	}, []);
	
	useEffect(() => {
		dispatch(updateDayAnnouncements());
		dispatch(announcementOfficialFetchToday());
	}, [ dispatch ]);

	useEffect(() => {
		// 公告: 建立/更新/過期/刪除 -> update 今日公告
		handleWebsocketEventRegistry('sync_announcements', () => {
			setTimeout(() => {
				dispatch(updateDayAnnouncements());
			}, 3000);
		});

		return () => {
			cancelWebsocketSubscription([ 'sync_announcements' ]);
		}
	}, [ dispatch ])

	const getOfficialAnnouncementsDisplay = async() => {
		try {
			const { data } = await getDisplayOfficialAnnouncements();
			setDisplayOfficialAnnouncements(data);
		} catch(err) {
			console.log('get display official announcements err ----------', err);
		}
	};

	const previewAnnouncement = (item) => {
    dispatch(announcementSetting(item));
    dispatch(showAnnouncementPreviewModal());
  };

	const showAnnouncement = () => {
		setShowTodayAnnouncement(true);
	};

	const expandAnnouncement = () => {
		setShowTodayAnnouncement(!showTodayAnnouncement);
	};

	const renderAnnouncement = () => {
		const announcementLists = (type) => {
			let lists = [];
			let style = '';

			if(type === 'shop') {
				lists = dayAnnouncements;
				style = 'cell_shop';
			} else {
				lists = todayOfficialAnnouncements;
				style = 'cell_official';
			}

			return lists && lists.map((item, idx) => {
				let day_start = new Date(item.start_time).getDay();
				let day_end = new Date(item.end_time).getDay();
				let startTime = 
					moment(item.start_time).format('YYYY-MM-DD') + 
					'（' + day_list[day_start] + '）' +
					moment(item.start_time).format('HH:mm');
				let endTime = 
					moment(item.end_time).format('YYYY-MM-DD') +
					'（' + day_list[day_end] + '）' +
					moment(item.end_time).format('HH:mm');

				return(
					<div
						key={idx}
						className={`announcementBlock_list_cell ${style}`}
						onClick={() => previewAnnouncement(item)}
					>
						<h4>{item.title}</h4>
						<div>
							{startTime} - <br />
							{endTime}
						</div>
					</div>
				)
			})
		}

		if(announcementCount === 0) {
			return (
				<div className='announcement_box'>
					<div className='announcementBlock announcement_empty'>
						<button
							className='announcement_float_btn btn_disabled'
						>
							<img src={require('../../images/announcement/megaphone_btn_disabled.svg')} alt="announcement btn" />
						</button>
						<div
							className='announcementBlock_btn announcementBlock_btn_none'
						>
							今日暫無公告
						</div>
						<div className='announcementBlock_list'/>
					</div>
				</div>
			)
		} else {
			return (
				<div className='announcement_box'>
					<button
						className='announcement_float_btn'
						onClick={() => showAnnouncement()}
					>
						<img src={require('../../images/announcement/megaphone_btn.svg')} alt="announcement btn" />
					</button>
					<div className={showTodayAnnouncement ? 'announcementBlock announcementBlock_list_show' : 'announcementBlock'}>
						<div
							className='announcementBlock_btn announcementBlock_btn_have'
							onClick={() => expandAnnouncement()}
						>
							今日公告 ({ announcementCount })
							{ showTodayAnnouncement ? 
								<div className='announcementBlock_arrow_down' /> : 
								<div className='announcementBlock_arrow_up' />
							}
						</div>
						<div className='announcementBlock_list'>
							{ announcementLists('official') }
							{ todayOfficialAnnouncements.length > 0 && dayAnnouncements.length > 0 ? <div className='announcementBlock_list_hr' /> : null }
							{ announcementLists('shop') }
						</div	>
					</div>
				</div>
			)
		}
	};

	return (
		<div>
			<div className="bg-main">
				<div className="container">
					<div className="dashboardContainer">
						<BookingIcon key="booking" />
						<QueueIcon key="queue" />
						<NewModuleIcon key="New Module" />
						<CouponIcon key="Coupon" />
						<SettingIcon key="setting" />
						<CustomerIcon key="customer" />
						{/* <CallIcon key="call" /> */}
						<AnnouncementIcon key='announcement' />
					</div>
				</div>

				{ renderAnnouncement() }

				{
					displayOfficialAnnouncements.length > 0 &&
					<OfficialAnnouncementModal
						key={displayOfficialAnnouncements[0].id}
						item={displayOfficialAnnouncements[0]}
						displayOfficialAnnouncements={displayOfficialAnnouncements}
						setDisplayOfficialAnnouncements={setDisplayOfficialAnnouncements}
					/>
				}

				{ showPreviewAnnouncementModal &&
					<PreviewAnnouncementModal
						isDashboard={true}
					/>
				}
			</div>
		</div>
	);
};

export default DashboardBox;