import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import moment from 'moment';

import AnnouncementListModal from '../announcement/announcementPopup/AnnouncementListModal';
import AddAnnouncementModal from '../announcement/announcementPopup/AnnouncementAddModal';
import AnnouncementCancelModal from '../announcement/announcementPopup/AnnouncementCancelModal';
import AnnouncementCheckModal from '../announcement/announcementPopup/AnnouncementDeleteModal';

import {
	handleAnnouncementListModal,
	announcementFetchMonth,
	updateDayAnnouncements,
	resetAnnouncementList
} from '../../actions/announcementAction';

import '../../stylesheets/new_module_layout.css';
import '../../stylesheets/new_module.css';
import '../../stylesheets/new_module_mode.css';
import '../../stylesheets/new_module_queue.css';

const NewModuleEntry = ({ children }) => {
	const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
		dayAnnouncements,
		showAnnouncementListModal,
		showAnnouncementDeleteModal,
		showAnnouncementCancelModal
	} = announcementListReducer;
	const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    showAddAnnouncementModal
  } = announcementInfo;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(announcementFetchMonth());
		dispatch(updateDayAnnouncements());

		return () => dispatch(resetAnnouncementList());
	}, [ dispatch ]);

	const toSetting = () => {
		browserHistory.push({
			pathname: '/dashboard/setting/',
			state: {
				source: 'newModule',
				date: browserHistory.getCurrentLocation().query.date
			}
		});
	};

	const renderAnnouncementBtn = () => {
		let urlDate = browserHistory.getCurrentLocation().query.date;
		let queryDate = urlDate ? moment(new Date(urlDate)).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD');
	
		if(dayAnnouncements.length === 0) {
			return (
				<button
					className='announcement_btn disabled'
					style={{cursor: 'default'}}
				>
					<span className='announcement_day_none' />
					<div className='item'>公告</div>
				</button>
			)
		} else {
			return (
				<button
					className='announcement_btn'
					onClick={() => dispatch(handleAnnouncementListModal('day', queryDate))}
				>
					<span className='announcement_day_remind' />
					<div className='item'>公告</div>
				</button>
			)
		}
	};

	return (
		<div className="newModuleWrap" style={{ height: window.innerHeight }}>
			<div className="newModule">
				<aside className="newModule__aside">
					<button className="active">
						<i className="home" />
						<div className='item'>首頁</div>
					</button>
					{/* <button>
						<i className="chart" />
					</button> */}
					{ renderAnnouncementBtn() }
					<button onClick={() => toSetting()}>
						<i className="setting" />
						<div className='item'>設定</div>
					</button>
				</aside>
				<div className="newModule__main">
					<div className="newModule__main__radius">{children}</div>
				</div>
			</div>
			<div id="modalPortal"></div>

			{ showAnnouncementListModal && 
				<AnnouncementListModal/>
			}

			{
				showAddAnnouncementModal &&
				<AddAnnouncementModal />
			}

			{ showAnnouncementDeleteModal &&
				<AnnouncementCheckModal/>
			}

			{ showAnnouncementCancelModal &&
				<AnnouncementCancelModal/>
			}
		</div>
	);
};

export default NewModuleEntry;
