import React from 'react';

export default () => {
	return (
		<div className="week-names">
			<div className="day-container">
				<span className="day">日</span>
			</div>
			<div className="day-container">
				<span className="day">一</span>
			</div>
			<div className="day-container">
				<span className="day">二</span>
			</div>
			<div className="day-container">
				<span className="day">三</span>
			</div>
			<div className="day-container">
				<span className="day">四</span>
			</div>
			<div className="day-container">
				<span className="day">五</span>
			</div>
			<div className="day-container">
				<span className="day">六</span>
			</div>
		</div>
	);
};
