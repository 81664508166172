import axios from 'axios';
import { config } from '../../../utils/config';

const crmMessageMenuTalkAxios = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

/* 取得 menuTalk 訊息 */
export const getCrmMessagesMenuTalk = () => crmMessageMenuTalkAxios.get('/dashboard/crm/message/menu_app');

/* 新建 menuTalk 訊息 */
export const createCrmMenuTalk = (crmMenuTalkContent) => crmMessageMenuTalkAxios.post('/dashboard/crm/message/menu_app', crmMenuTalkContent);

/* 修改 menuTalk 訊息 */
export const updateCrmMenuTalk = (crmMenuTalkId, crmMenuTalkContent) => crmMessageMenuTalkAxios.patch(`/dashboard/crm/message/menu_app/${crmMenuTalkId}`, crmMenuTalkContent);

/* 修改排程 */
export const updateCrmMenuTalkSchedule = (crmMenuTalkId, scheduleTime) => crmMessageMenuTalkAxios.patch(`dashboard/crm/message/menu_app/${crmMenuTalkId}/scheduling`, scheduleTime);

/* 取消排程 */
export const cancelCrmMenuTalkSchedule = (crmMenuTalkId) => crmMessageMenuTalkAxios.delete(`dashboard/crm/message/menu_app/${crmMenuTalkId}/scheduling`);

/* 刪除 menuTalk 訊息 */
export const deleteCrmMenuTalk = (crmMenuTalkId) => crmMessageMenuTalkAxios.delete(`dashboard/crm/message/menu_app/${crmMenuTalkId}`);

/* 篩選粉絲 */
export const filterFollowers = (params) => crmMessageMenuTalkAxios.get('/dashboard/crm/message/menu_app/filter_followers', { params });