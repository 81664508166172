import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';


const NewModuleIcon = () => {
	return (
		<div className="main-item">
			<Link
					to={{
						pathname: '/dashboard/new_module',
						query:{date: moment().format('YYYY-MM-DD')}
					}}
					role="button"
				>
					<div className="function-thub">
						<img alt="booking" src={require('../../images/component/f_newModule_1.svg')} />
						<div className="caption function-title">
							<h3>排隊預約系統</h3> <p>管理顧客預約與現場排隊</p>
						</div>
					</div>
				</Link>
		</div>
	);
};

export default NewModuleIcon;
