import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { Stage, Layer } from 'react-konva';
import $ from 'jquery';
import moment from 'moment';

import Clocker from './NewModuleComponent/Clocker';
import SwapSeatPanel from './NewModuleComponent/SwithSeatPanel';
import SwapMoreSeatPanel from './NewModuleComponent/SwapMoreSeatPanel';
import CanvasTableBookingInfo from './BookingInfo/CanvasTableBookingInfo';

import { SeatedPanel } from './Queue/Queue';

import URLImage from './TableMap/URLImage';
import RectShape from './TableMap/RectShape';
import CircleShape from './TableMap/CircleShap';

import { SelectedTableContainer, SeatedSuccess } from './NewModuleComponent/SelectedTableContainer';
import { TableBookingPanel } from './TableBooking/TableBooking';
import { TableBookingNumPad } from './TableBooking/Numpad';
import { TableBookingDateTime } from './TableBooking/DateTime';
import DoubleBookingModal from './TableBooking/DoubleBooking';
import AttendanceOverMaxSeatsModal from './TableBooking/AttendanceOverMaxSeats';

import { moveCanvasClass, moveBackCanvasClass } from './NewModuleComponent/NewModuleTools';

import { Portal } from './utils/Portal';

//reducer
import { BaseSettingContext } from './NewModuleReducer/BaseSettingReducer';
import { CommomSettingContext } from './NewModuleReducer/CommomReuducer';

//hook
import useTableBookingSubmit from './hooks/useTableBookingSubmit';
import { swapConflictTable, seatedConflictTable } from './utils/conflictTable'

const bookingInfos = {
	currentBooking: [],
	nextBooking: []
};

const HeaderHeight = 56,
	CalendarHeight = 50,
	ClockerHeight = 30,
	CanvasTabHeight = 60;

const $totalHeight = HeaderHeight + CalendarHeight + ClockerHeight + CanvasTabHeight;

const NewModuleTableMap = ({
	scaleRatio,
	activeSystem,
	updateAfterSeated,
	updateBooking,
	setLoading,
	setActiveSystemToBooking,
	bookingListInfoVisible,
	setBookingListInfoVisible,
	smsNotificationSettings,
	emailNotificationSettings,
	spg_hash_iv,
	spg_hash_key,
	spg_merchant_id,
	credit_enabled
}) => {
	const { BaseSettingState } = useContext(BaseSettingContext);
	const { canvasWidth, canvasHeight } = BaseSettingState;

	const { CommomSettingState, CommomSettingDispatch } = useContext(CommomSettingContext);
	const {
		date,
		moduleSelectedBookingTime,
		canvas,
		activeCanvasTab,
		activeCanvasTables,
		canvasEleImgs,
		bookingCardSeats,
		queueSeatedData,
		selectedBookingTableData,
		swapOverlayTableData
	} = CommomSettingState;

	const [ bookingListInfoBookings, setBookingListInfoBookings ] = useState(bookingInfos); //current next booking資訊
	const [ showTablemapLayout, setShowTablemapLayout ] = useState(false); //選定入座後tablemap不能再點桌子
	const [ showSeatedSuccess, setSeatedSuccess ] = useState(false); //入座成功提醒
	const [ seatedSuccessData, setSeatedSuccessData ] = useState(''); //入座成功資料
	const [ tableBooking, setTableBooking ] = useState(false); //點桌子建立預約
	const [ tableBookingStep, setTableBookingStep ] = useState(1); //1輸入人數 2選預約時間 3選桌子
	const [ tableBookingAttendance, setTableBookingAttendance ] = useState(0); //點桌子建立預約輸入的人人數
	const [ tableBookingDateTime, setTableBookingDateTime ] = useState(null);

	const [ swapMode, setSwapMode ] = useState(false); // 一對一交換座位
	const [ swapMoreSeatMode, setSwapMoreSeatMode ] = useState(false); // 一對多交換座位
	const [ swapBookingInfo, setSwitchBookingInfo ] = useState({}); //欲換位的預約資訊(target)
	const [ target, setTarget ] = useState({}); //{current, pre, next}
	const [ swapBookingInfoSeat, setSwapBookingInfoSeat ] = useState({}); //欲換位的預約座位(target)
	const [ beSwapedBookingInfoSeat, setBeSwapedBookingInfoSeat ] = useState({}); //被換位的預約座位(be swapped)
	const [ swapped, setSwapped ] = useState({}); //{pre, current, next}

	const { tableBookingSubmit, conflictTables, setConflictTables, isBookingLoading, setIsBookingLoading } = useTableBookingSubmit(updateBooking);

	// 一對多交換座位重疊
	const [  showSwapOverlayTables ,setShowSwapOverlayTables ] = useState([]);
	// 顧客人數超過選擇桌位最高容納人數
	const [  showAttendanceOverMaxSeatsModal ,setShowAttendanceOverMaxSeatsModal ] = useState([]);

	useEffect(
		() => {
			setBookingListInfoVisible(false);
			CommomSettingDispatch({ type: 'setBookingCardSeats', seats: [] });
		},
		[ CommomSettingDispatch, date, moduleSelectedBookingTime, setBookingListInfoVisible ]
	);

	//開始點擊交換位置按鈕
	const startSwapSeat = (targetBooking) => {
		setActiveSystemToBooking();
		const $canvas = document.getElementsByClassName('tablemap')[0];
		const $newModule = document.getElementsByClassName('newModule')[0];
		const $newModuleMain = document.getElementsByClassName('newModule__main')[0];

		$newModuleMain.style.overflow = 'visible';
		$newModule.style.zIndex = 1032;

		$('#canvasSeated').show();
		$('#canvasSeated').append($canvas);

		const targetBookingObj = findTargetBooking(canvas, targetBooking);
		setTarget(targetBookingObj);

		CommomSettingDispatch({ type: 'setBookingCardSeats', seats: targetBooking.seats });

		setSwapMode(true);
		setSwitchBookingInfo(targetBooking);
		setTableBookingDateTime(targetBooking.booking_datetime);
		setBookingListInfoVisible(false);
	};

	//關閉交換模式
	const closeSwapSeate = useCallback(
		() => {
			const $canvas = document.getElementsByClassName('tablemap')[0];
			const $chart = document.getElementsByClassName('newModule__main__display__chart')[0];
			const $newModuleMain = document.getElementsByClassName('newModule__main')[0];
			const $newModule = document.getElementsByClassName('newModule')[0];

			$('#canvasSeated').hide();
			$($chart).append($canvas);

			$newModuleMain.removeAttribute('style');
			$newModule.removeAttribute('style');

			setSwapMode(false);
			setBookingListInfoVisible(false);
			setSwitchBookingInfo({});
			setTableBookingDateTime(null);

			CommomSettingDispatch({ type: 'setBookingCardSeats', seats: [] });
		},
		[ CommomSettingDispatch, setBookingListInfoVisible ]
	);

		//開始點擊換位位置按鈕（一可換多）
		const startSwapMoreSeat = (targetBooking) => {
			setActiveSystemToBooking();
			const $canvas = document.getElementsByClassName('tablemap')[0];
			const $newModule = document.getElementsByClassName('newModule')[0];
			const $newModuleMain = document.getElementsByClassName('newModule__main')[0];
			const swapOverlayTables = []
	
			$newModuleMain.style.overflow = 'visible';
			$newModule.style.zIndex = 1032;
	
			$('#canvasSeated').show();
			$('#canvasSeated').append($canvas);
			$('.tablemap').addClass('swapMoreSeatMode')
	
			const targetBookingObj = findTargetBooking(canvas, targetBooking);
			setTarget(targetBookingObj);

			const swapBookingCanvasTable = findSwapBookingCanvasTable(canvas, targetBooking)
	
			CommomSettingDispatch({ type: 'setBookingCardSeats', seats: targetBooking.seats });
			CommomSettingDispatch({ type: 'setSelectedBookingTableData', selectedBookingTableData: swapBookingCanvasTable });

			// 預設預約座位為重疊桌位
			for(let i=0; i<targetBooking.seats.length;i++) {
				swapOverlayTables.push(targetBooking.seats[i].name)
			}
			CommomSettingDispatch({ type: 'setSwapOverlayTableData', swapOverlayTableData: swapOverlayTables });
	
			setSwapMoreSeatMode(true);
			setSwitchBookingInfo(targetBooking);
			setTableBookingDateTime(targetBooking.booking_datetime);
			setBookingListInfoVisible(false);
		};
	
		//關閉點擊換位位置模式（一可換多）
		const closeSwapMoreSeat = useCallback(
			() => {
				const $canvas = document.getElementsByClassName('tablemap')[0];
				const $chart = document.getElementsByClassName('newModule__main__display__chart')[0];
				const $newModuleMain = document.getElementsByClassName('newModule__main')[0];
				const $newModule = document.getElementsByClassName('newModule')[0];
	
				$('#canvasSeated').hide();
				$($chart).append($canvas);
	
				$newModuleMain.removeAttribute('style');
				$newModule.removeAttribute('style');
				$('.swapMoreSeatMode').removeClass('swapMoreSeatMode')
	
				setSwapMoreSeatMode(false);
				setBookingListInfoVisible(false);
				setSwitchBookingInfo({});
				setTableBookingDateTime(null);
	
				CommomSettingDispatch({ type: 'setBookingCardSeats', seats: [] });
				CommomSettingDispatch({ type: 'setSelectedBookingTableData', selectedBookingTableData: [] });
				CommomSettingDispatch({ type: 'setSwapOverlayTableData', swapOverlayTableData: [] });
			},
			[ CommomSettingDispatch, setBookingListInfoVisible ]
		);

	//開始點擊桌子建立預約模式
	const startTableBooking = useCallback(
		() => {
			moveCanvasClass();

			setTableBooking(true);
			setBookingListInfoVisible(false);
			CommomSettingDispatch({ type: 'setBookingCardSeats', seats: [] });
		},
		[ CommomSettingDispatch, setBookingListInfoVisible ]
	);

	//關閉點擊桌子建立預約模式
	const closeTableBooking = () => {
		moveBackCanvasClass();

		setTableBooking(false);
		setTableBookingStep(1);
	};

	const switchTab = useCallback(
		(activeTab) => {
			setBookingListInfoVisible(false);
			CommomSettingDispatch({ type: 'changeCanvasView', activeTab });
		},
		[ CommomSettingDispatch, setBookingListInfoVisible ]
	);

	const renderCanvasTab = useCallback(
		(activeCanvasTab) => {
			return canvas.map((tab, index) => {
				const active = tab.name === activeCanvasTab ? 'active' : '';

				return (
					<li key={index} className={active} onClick={() => switchTab(tab.name)}>
						{tab.name}
					</li>
				);
			});
		},
		[ canvas, switchTab ]
	);

	//點選canvas上桌子
	const selectTable = useCallback(
		(shapeProps, currentBooking, nextBooking) => {
			let tableId = [];

			if (currentBooking.length !== 0) {
				tableId = currentBooking[0].seats;
			} else if (nextBooking.length !== 0) {
				tableId = nextBooking[0].seats;
			}

			CommomSettingDispatch({ type: 'setBookingCardSeats', seats: tableId });

			if (currentBooking.length === 0 && nextBooking.length === 0) {
				setBookingListInfoVisible(false);
				return;
			}

			const swapSeat = {
				idx: shapeProps.idx,
				table_setting_id: shapeProps.table_setting_id,
				max_seat: shapeProps.max_attendance,
				min_seat: shapeProps.min_attendance,
				table_number: shapeProps.name,
				group: shapeProps.group
			};
			setSwapBookingInfoSeat(swapSeat);
			setBookingListInfoVisible(true);
			setCanvasTableBookingInfoPositon(shapeProps, scaleRatio);
		},
		[ CommomSettingDispatch, scaleRatio, setBookingListInfoVisible ]
	);

	//交換模式下點擊桌子
	const selectSwapSeat = useCallback(
		(shapeProps, bookings, swappedBooking) => {
			const swappedSeat = {
				max_seat: shapeProps.max_attendance,
				min_seat: shapeProps.min_attendance,
				table_number: shapeProps.name,
				group: shapeProps.group,
				idx: shapeProps.idx,
				table_setting_id: shapeProps.table_setting_id
			};
			setSwapped(swappedBooking);
			setBeSwapedBookingInfoSeat(swappedSeat);
			setBookingListInfoVisible(true);
			setBookingListInfoBookings(bookings);
			setCanvasTableBookingInfoPositon(shapeProps, scaleRatio);
		},
		[ scaleRatio, setBookingListInfoVisible ]
	);

	// 檢查互換模式一換多座位有無重疊
	const swapMoreSeatCheckOverlayTable = useCallback(
		(target, swappedBooking, shapeProps) => {
			const swapOverlayTablesData = [ ...swapOverlayTableData ];
			const swapOverlayTableIndex = swapOverlayTablesData.findIndex((t) => t === shapeProps.name);
			let targetConflict = false;
			let swappedConflict = false;

				targetConflict = swapConflictTable({
					targetBooking: target.current,
					preBookinging: swappedBooking.seated.pre,
					nextBooking: swappedBooking.seated.next
				});
				swappedConflict = seatedConflictTable({
					targetBooking: target.current,
					seatedBooking: swappedBooking.seated.current
				});

				if ((targetConflict && swapOverlayTableIndex === -1) || (swappedConflict && swapOverlayTableIndex === -1)) {
					//其中一個是true就是有衝突
					swapOverlayTablesData.push(shapeProps.name)
					CommomSettingDispatch({ type: 'setSwapOverlayTableData', swapOverlayTableData: swapOverlayTablesData });
				} else if ((targetConflict && swapOverlayTableIndex !== -1) || (swappedConflict && swapOverlayTableIndex !== -1)) {
					swapOverlayTablesData.splice(swapOverlayTableIndex, 1);
					CommomSettingDispatch({ type: 'setSwapOverlayTableData', swapOverlayTableData: swapOverlayTablesData });
				}
		},
		[ CommomSettingDispatch, target, swapOverlayTableData ]
	)

	const closeTableBookingPanel = useCallback(
		() => {
			const $canvas = document.getElementsByClassName('tablemap')[0];
			const $chart = document.getElementsByClassName('newModule__main__display__chart')[0];
			const $newModuleMain = document.getElementsByClassName('newModule__main')[0];
			const $newModule = document.getElementsByClassName('newModule')[0];

			$('#canvasSeated').hide();
			$($chart).append($canvas);

			$newModuleMain.removeAttribute('style');
			$newModule.removeAttribute('style');

			CommomSettingDispatch({ type: 'setSelectedBookingTableData', selectedBookingTableData: [] });

			setShowTablemapLayout(false);

			setTableBooking(false);
			setTableBookingStep(1);
		},
		[ CommomSettingDispatch ]
	);

	const renderCanvasTableBookingInfo = () => {
		if (bookingListInfoVisible) {
			return (
				<CanvasTableBookingInfo
					target={target}
					swapped={swapped}
					swapMode={swapMode}
					swapMoreSeatMode={swapMoreSeatMode}
					swapBookingInfo={swapBookingInfo}
					swapBookingInfoSeat={swapBookingInfoSeat}
					beSwapedBookingInfoSeat={beSwapedBookingInfoSeat}
					bookingListInfoBookings={bookingListInfoBookings}
					updateBooking={updateBooking}
					setLoading={setLoading}
					startSwapSeat={startSwapSeat} // 交換：一換一
					startSwapMoreSeat={startSwapMoreSeat} // 換位：一可換多
					closeSwapSeate={closeSwapSeate} // 關閉交換
					setShowCanvasTableBookingInfo={setBookingListInfoVisible}
				/>
			);
		}
	};

	//入座模式點選桌子
	const selectedTableToSeated = useCallback(
		(table) => {
			const newTableData = [ ...selectedBookingTableData ];
			newTableData.push(table);
			CommomSettingDispatch({ type: 'setSelectedBookingTableData', selectedBookingTableData: newTableData });
		},
		[ CommomSettingDispatch, selectedBookingTableData ]
	);

	//入座模式移除桌子
	const removeSelectedTableToseated = useCallback(
		(table) => {
			if (table.nextBooking) delete table['nextBooking'];
			const newTableData = [ ...selectedBookingTableData ];
			const tableIndex = newTableData.findIndex((t) => t.id === table.id);
			if (tableIndex !== -1) {
				newTableData.splice(tableIndex, 1);
				// setSeatedTableData(newTableData);
				CommomSettingDispatch({ type: 'setSelectedBookingTableData', selectedBookingTableData: newTableData });
			}
		},
		[ CommomSettingDispatch, selectedBookingTableData ]
	);

	const renderTableCanvas = useCallback(
		() => {
			return activeCanvasTables.map((table) => {
				const findTableIndex = selectedBookingTableData.findIndex((t) => t.id === table.id);
				const findSelectedTableIndex = bookingCardSeats.indexOf(table.name);

				if (table.table_type !== 'circle') {
					const newTimeline = table.timeline.filter((booking) => {
						const bookingDate = moment(new Date(booking.date)).format('YYYY/MM/DD');
						const newDate = moment(new Date(date)).format('YYYY/MM/DD');
						return bookingDate === newDate;
					});

					return (
						<RectShape
							key={table.name}
							shapeProps={table}
							timeline={newTimeline}
							isSelected={findSelectedTableIndex !== -1}
							activeSystem={activeSystem}
							isSeatedTable={findTableIndex !== -1}
							tableBooking={tableBooking}
							tableBookingStep={tableBookingStep}
							tableBookingDateTime={tableBookingDateTime}
							swapMode={swapMode}
							swapMoreSeatMode={swapMoreSeatMode}
							swapBookingInfo={swapBookingInfo}
							isSwapSeat={table.name === swapBookingInfoSeat.table_number}
							selectTable={selectTable}
							selectSwapSeat={selectSwapSeat}
							selectedTableToSeated={selectedTableToSeated}
							setBookingListInfoBookings={setBookingListInfoBookings}
							removeSelectedTableToseated={removeSelectedTableToseated}
							swapMoreSeatCheckOverlayTable={swapMoreSeatCheckOverlayTable}
							target={target}
						/>
					);
				} else {
					const newTimeline = table.timeline.filter((booking) => {
						const bookingDate = moment(new Date(booking.date)).format('YYYY/MM/DD');
						const newDate = moment(new Date(date)).format('YYYY/MM/DD');
						return bookingDate === newDate;
					});

					return (
						<CircleShape
							key={table.name}
							shapeProps={table}
							timeline={newTimeline}
							isSelected={findSelectedTableIndex !== -1}
							activeSystem={activeSystem}
							isSeatedTable={findTableIndex !== -1}
							tableBooking={tableBooking}
							tableBookingStep={tableBookingStep}
							tableBookingDateTime={tableBookingDateTime}
							swapMode={swapMode}
							swapMoreSeatMode={swapMoreSeatMode}
							swapBookingInfo={swapBookingInfo}
							isSwapSeat={table.name === swapBookingInfoSeat.table_number}
							selectTable={selectTable}
							selectSwapSeat={selectSwapSeat}
							selectedTableToSeated={selectedTableToSeated}
							setBookingListInfoBookings={setBookingListInfoBookings}
							removeSelectedTableToseated={removeSelectedTableToseated}
							swapMoreSeatCheckOverlayTable={swapMoreSeatCheckOverlayTable}
							target={target}
						/>
					);
				}
			});
		},
		[
			activeSystem,
			activeCanvasTables,
			selectedBookingTableData,
			bookingCardSeats,
			tableBooking,
			tableBookingStep,
			tableBookingDateTime,
			swapMode,
			swapMoreSeatMode,
			swapBookingInfo,
			swapBookingInfoSeat.table_number,
			date,
			selectTable,
			selectSwapSeat,
			selectedTableToSeated,
			removeSelectedTableToseated
		]
	);

	const checkToSeated = () => {
		setShowTablemapLayout(true);
	};

	const backToChooseTable = () => {
		setShowTablemapLayout(false);
	};

	const setBookingTime = (attendance) => {
		setTableBookingAttendance(attendance);
		setTableBookingStep(2);
	};

	//點擊桌子建立預約輸入時間後
	const tableBookingStep3 = (time) => {
		const $tablemap = document.getElementsByClassName('tablemap')[0];
		const $newModule = document.getElementsByClassName('newModule')[0];
		const $newModuleMain = document.getElementsByClassName('newModule__main')[0];

		moveBackCanvasClass();

		$newModuleMain.style.overflow = 'visible';
		$newModule.style.zIndex = 1032;

		setTableBookingDateTime(time);

		$('#canvasSeated').show();
		$('#canvasSeated').append($tablemap);

		setTableBookingStep(3);
	};

	const closeQueueSelectedTable = useCallback(
		() => {
			const $canvas = document.getElementsByClassName('tablemap')[0];
			const $chart = document.getElementsByClassName('newModule__main__display__chart')[0];
			const $newModuleMain = document.getElementsByClassName('newModule__main')[0];
			const $newModule = document.getElementsByClassName('newModule')[0];

			$('#canvasSeated').hide();
			$($chart).append($canvas);

			$newModuleMain.removeAttribute('style');
			$newModule.removeAttribute('style');

			setShowTablemapLayout(false);
			CommomSettingDispatch({ type: 'closeQueueSelectedTable' });
		},
		[ CommomSettingDispatch ]
	);

	const renderCloseSeatedPanelMemo = useCallback(
		(queueSeatedData) => {
			if (Object.keys(queueSeatedData).length !== 0) {
				return <button className="closeSeatedPanel" onClick={() => closeQueueSelectedTable()} />;
			}
		},
		[ closeQueueSelectedTable ]
	);

	const renderTablemapLayout = useCallback((showTablemapLayout) => {
		if (showTablemapLayout) {
			return <div className="tablemapLayout" />;
		}
	}, []);

	const renderSeatedSuccess = useCallback((showSeatedSuccess, seatedSuccessData) => {
		if (showSeatedSuccess) {
			return <SeatedSuccess seatedSuccessData={seatedSuccessData} />;
		}
	}, []);

	const renderTableBookingAttendance = useCallback((tableBooking, tableBookingStep) => {
		if (tableBooking && tableBookingStep === 1) {
			return (
				<Portal>
					<TableBookingNumPad closeTableBooking={closeTableBooking} setBookingTime={setBookingTime} />
				</Portal>
			);
		} else if (tableBooking && tableBookingStep === 2) {
			return (
				<Portal>
					<TableBookingDateTime closeTableBooking={closeTableBooking} tableBookingStep3={tableBookingStep3} />
				</Portal>
			);
		}
	}, []);

	const renderTableBookingPanel = useCallback(
		(tableBooking, tableBookingStep) => {
			if (tableBooking && tableBookingStep === 3) {
				return (
					<TableBookingPanel
						tableBookingDateTime={tableBookingDateTime}
						tableBookingAttendance={tableBookingAttendance}
						checkToSeated={checkToSeated}
						backToChooseTable={backToChooseTable}
						closeTableBookingPanel={closeTableBookingPanel}
						setSeatedSuccessData={setSeatedSuccessData}
						setSeatedSuccess={setSeatedSuccess}
						setLoading={setLoading}
						isBookingLoading={isBookingLoading}
						setIsBookingLoading={setIsBookingLoading}
						tableBookingSubmit={({ customerInfoData, dataSubmit, btn }) =>
							tableBookingSubmit({ customerInfoData, dataSubmit, btn }, closeTableBookingPanel)}
						smsNotificationSettings={smsNotificationSettings}
						emailNotificationSettings={emailNotificationSettings}
						spg_hash_iv={spg_hash_iv}
						spg_hash_key={spg_hash_key}
						spg_merchant_id={spg_merchant_id}
						credit_enabled={credit_enabled}
					/>
				);
			}
		},
		[ tableBookingDateTime, tableBookingAttendance, closeTableBookingPanel, setLoading, tableBookingSubmit, setIsBookingLoading, isBookingLoading ]
	);

	const renderCloseTableBookingPanel = useCallback(
		(tableBooking, tableBookingStep) => {
			if (tableBooking && tableBookingStep === 3) {
				return <button className="closeSeatedPanel" onClick={() => closeTableBookingPanel()} />;
			}
		},
		[ closeTableBookingPanel ]
	);

	const renderTableBookingButton = useCallback(
		(activeSystem) => {
			if (activeSystem === 'booking') {
				return (
					<button className="tableBooking" onClick={() => startTableBooking()}>
						<i className="fa fa-plus" aria-hidden="true" />
						新增預約
					</button>
				);
			}
		},
		[ startTableBooking ]
	);

	const renderSwitchSeatPanel = useCallback(
		(swapMode) => {
			if (swapMode) {
				return <SwapSeatPanel swapBookingInfo={swapBookingInfo} closeSwapSeate={closeSwapSeate} />;
			}
		},
		[ closeSwapSeate, swapBookingInfo ]
	);

	const renderSwapMoreSeatPanel = useCallback(
		(swapMoreSeatMode) => {
			if (swapMoreSeatMode) {
				return <SwapMoreSeatPanel 
								swapBookingInfo={swapBookingInfo}
								closeSwapMoreSeat={closeSwapMoreSeat}
								updateBooking={updateBooking}
								setShowSwapOverlayTables={setShowSwapOverlayTables}
								setShowAttendanceOverMaxSeatsModal={setShowAttendanceOverMaxSeatsModal}
								/>;
			}
		},
		[ closeSwapMoreSeat, swapBookingInfo ]
	);

	const StageClick = useCallback(
		(e) => {
			const clickedOnEmpty = e.target === e.target.getStage();
			if (clickedOnEmpty) {
				setBookingListInfoVisible(false);
				if (!swapMode && !swapMoreSeatMode) {
					//若不是換位模式才取消選起的桌子
					CommomSettingDispatch({ type: 'setBookingCardSeats', seats: [] });

					setSwapBookingInfoSeat({});
					setBeSwapedBookingInfoSeat({});
				}
			}
		},
		[ CommomSettingDispatch, setBookingListInfoVisible, swapMode, swapMoreSeatMode ]
	);

	const canvasTabMemo = useMemo(() => renderCanvasTab(activeCanvasTab), [ renderCanvasTab, activeCanvasTab ]);

	const eleImgsCanvasMemo = useMemo(() => canvasEleImgs.map((ele, index) => <URLImage key={index} img={ele} />), [
		canvasEleImgs
	]);

	const SeatedPanelMemo = useMemo(
		() => {
			if (Object.keys(queueSeatedData).length !== 0) {
				return (
					<SeatedPanel
						checkToSeated={checkToSeated}
						backToChooseTable={backToChooseTable}
						updateAfterSeated={updateAfterSeated}
						setSeatedSuccess={setSeatedSuccess}
						setSeatedSuccessData={setSeatedSuccessData}
						closeQueueSelectedTable={closeQueueSelectedTable}
						setBookingListInfoVisible={setBookingListInfoVisible}
					/>
				);
			}
		},
		[ queueSeatedData, updateAfterSeated, closeQueueSelectedTable, setBookingListInfoVisible ]
	);

	const SelectedTableMemo = useMemo(
		() => {
			if (selectedBookingTableData.length !== 0) {
				return <SelectedTableContainer removeSelectedTableToseated={removeSelectedTableToseated} />;
			}
		},
		[ selectedBookingTableData, removeSelectedTableToseated ]
	);

	const CloseSeatedPanelMemo = useMemo(() => renderCloseSeatedPanelMemo(queueSeatedData), [
		renderCloseSeatedPanelMemo,
		queueSeatedData
	]);

	const TablemapLayoutMemo = useMemo(() => renderTablemapLayout(showTablemapLayout), [
		renderTablemapLayout,
		showTablemapLayout
	]);

	const SeatedSuccessedMemo = useMemo(() => renderSeatedSuccess(showSeatedSuccess, seatedSuccessData), [
		renderSeatedSuccess,
		showSeatedSuccess,
		seatedSuccessData
	]);

	const TableBookingAttendanceMemo = useMemo(() => renderTableBookingAttendance(tableBooking, tableBookingStep), [
		renderTableBookingAttendance,
		tableBooking,
		tableBookingStep
	]);

	const TableBookingPanelMemo = useMemo(() => renderTableBookingPanel(tableBooking, tableBookingStep), [
		renderTableBookingPanel,
		tableBooking,
		tableBookingStep
	]);

	const CloseTableBookingPanelMemo = useMemo(() => renderCloseTableBookingPanel(tableBooking, tableBookingStep), [
		renderCloseTableBookingPanel,
		tableBooking,
		tableBookingStep
	]);

	const tableBookingButtonMemo = useMemo(() => renderTableBookingButton(activeSystem), [
		renderTableBookingButton,
		activeSystem
	]);

	const SwitchSeatPanelMemo = useMemo(() => renderSwitchSeatPanel(swapMode), [ renderSwitchSeatPanel, swapMode ]);

	const SwapMoreSeatPanelMemo = useMemo(() => renderSwapMoreSeatPanel(swapMoreSeatMode), [ renderSwapMoreSeatPanel, swapMoreSeatMode ]);


	return (
		<div className="newModule__main__display__chart">
			<Clocker />
			<div className="tablemap">
				{/* 入座模式才會出現: TablemapLayoutMemo && CloseSeatedPanelMemo && SeatedPanelMemo*/}
				{TablemapLayoutMemo}
				{CloseSeatedPanelMemo}
				{SeatedPanelMemo}

				{/* 入座成功提示 */}
				{SeatedSuccessedMemo}

				{/* 點擊桌子建立預約 */}
				{TableBookingAttendanceMemo}
				{CloseTableBookingPanelMemo}
				{TableBookingPanelMemo}

				{/* 交換位子模式 */}
				{SwitchSeatPanelMemo}
				{SwapMoreSeatPanelMemo}

				<div className="tablemap__canvasTab">
					<ul>{canvasTabMemo}</ul>
				</div>
				<div className="tablemap__canvasTable" style={{ height: window.innerHeight - $totalHeight }}>
					<div
						className="canvasTableBookingInfoWrap"
						style={{ width: canvasWidth * scaleRatio, height: canvasHeight * scaleRatio }}
					>
						{renderCanvasTableBookingInfo()}
					</div>

					<CommomSettingContext.Consumer>
						{(value) => (
							<Stage
								className="canvasBackgound"
								width={canvasWidth * scaleRatio}
								height={canvasHeight * scaleRatio}
								scaleX={scaleRatio}
								scaleY={scaleRatio}
								onClick={(e) => StageClick(e)}
								onTouchStart={(e) => StageClick(e)}
							>
								<CommomSettingContext.Provider value={value}>
									<Layer>
										{eleImgsCanvasMemo}
										{renderTableCanvas()}
									</Layer>
								</CommomSettingContext.Provider>
							</Stage>
						)}
					</CommomSettingContext.Consumer>

					{/* 排隊入座&點選桌子建立預約所選的桌子 */}
					{SelectedTableMemo}
				</div>
			</div>

			{tableBookingButtonMemo}

			{!conflictTables.length ? null : (
				<DoubleBookingModal conflictTables={conflictTables} setConflictTables={setConflictTables} />
			)}

			{!showSwapOverlayTables.length ? null : (
				<DoubleBookingModal conflictTables={showSwapOverlayTables} setConflictTables={setShowSwapOverlayTables} />
			)}

			{!showAttendanceOverMaxSeatsModal.length ? null : (
				<AttendanceOverMaxSeatsModal conflictTables={showAttendanceOverMaxSeatsModal} setConflictTables={setShowAttendanceOverMaxSeatsModal} />
			)}
		</div>
	);
};

function setCanvasTableBookingInfoPositon(shapeProps, scaleRatio) {
	const $bookingListWrapWidth = document.getElementsByClassName('tablemap__canvasTable')[0].offsetWidth;
	const $canvasTableBookingInfoWrapWidth = document.getElementsByClassName('canvasTableBookingInfoWrap')[0]
		.offsetWidth;
	const $restGap = ($bookingListWrapWidth - $canvasTableBookingInfoWrapWidth) / 2;
	const $tablesBookingListCardWidth = 300;
	const $bookingList = document.getElementsByClassName('canvasTableBookingInfo')[0];
	let listX = 0,
		listY = 0;

	if (shapeProps.table_type === 'circle') {
		if (
			$bookingListWrapWidth - $restGap - (shapeProps.positionX + shapeProps.width / 2) * scaleRatio <
			$tablesBookingListCardWidth + 10
		) {
			$bookingList.classList.add('display_left');
			listX = (shapeProps.positionX - 15 - shapeProps.width / 2) * scaleRatio - $tablesBookingListCardWidth;
		} else {
			$bookingList.classList.remove('display_left');
			listX = (shapeProps.positionX + shapeProps.width / 2 + 5) * scaleRatio;
		}
		listY = (shapeProps.positionY - 90) * scaleRatio;
	} else {
		if (
			$bookingListWrapWidth - $restGap - (shapeProps.positionX + shapeProps.width) * scaleRatio <
			$tablesBookingListCardWidth + 10
		) {
			$bookingList.classList.add('display_left');
			listX = (shapeProps.positionX - 20) * scaleRatio - $tablesBookingListCardWidth;
		} else {
			$bookingList.classList.remove('display_left');
			listX = (shapeProps.positionX + shapeProps.width + 18) * scaleRatio;
		}
		listY = (shapeProps.positionY - 50) * scaleRatio;
	}

	$bookingList.style.left = listX + 'px';
	$bookingList.style.top = listY + 'px';
}

function findTargetBooking(canvas, targetBooking) {
	const targetBookingTable = targetBooking.target_seat.table_number;
	const targetBookingId = targetBooking.id;

	const findBooking = {
		current: targetBooking,
		next: {},
		pre: {}
	};

	let found = false;
	for (let i = 0; i < canvas.length; i++) {
		const canvasTable = canvas[i].table;

		for (let table = 0; table < canvasTable.length; table++) {
			if (canvasTable[table].name === targetBookingTable) {
				const canvasTimeline = canvasTable[table].timeline;
				for (let j = 0; j < canvasTimeline.length; j++) {
					if (canvasTimeline[j].id === targetBookingId) {
						findBooking.next = canvasTimeline[j + 1] ? canvasTimeline[j + 1] : {};
						findBooking.pre = canvasTimeline[j - 1] ? canvasTimeline[j - 1] : {};
						found = true;
						break;
					}
				}
			}
			if (found) break;
		}

		if (found) break;
	}

	return findBooking;
}

function findSwapBookingCanvasTable(canvas, targetBooking) {
	const targetBookingTable = targetBooking.seats
	
	for (let i = 0; i < canvas.length; i++) {
		const canvasTable = canvas[i].table;

		for (let table = 0; table < canvasTable.length; table++) {
			for(let seat = 0; seat < targetBookingTable.length; seat++) {
				if (canvasTable[table].name === targetBookingTable[seat].table_number) {
					targetBookingTable[seat].name = canvasTable[table].name
					targetBookingTable[seat].canvas_name = canvasTable[table].canvas_name
					targetBookingTable[seat].canvas_space_id = canvasTable[table].canvas_space_id
					targetBookingTable[seat].created_at = canvasTable[table].created_at
					targetBookingTable[seat].table_type = canvasTable[table].table_type
					targetBookingTable[seat].timeline = canvasTable[table].timeline
					targetBookingTable[seat].updated_at = canvasTable[table].updated_at
					targetBookingTable[seat].id = canvasTable[table].id
					targetBookingTable[seat].idx = canvasTable[table].idx
					targetBookingTable[seat].max_attendance = canvasTable[table].max_attendance
					targetBookingTable[seat].min_attendance = canvasTable[table].min_attendance
				}
			}
		}
	}

	return targetBookingTable
}

export default NewModuleTableMap;
