import React from 'react';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';

import findBookingInfo from '../utils/findBooking';

const TableCell = ({ groupTitle, tableInfo, tempSeating, availableTables, handleTableCellClick }) => {
	const renderCell = () => {
		// findIndex 已選擇的桌子
		// disableInddex 不能預約的桌子

		return tableInfo.map((item, i) => {
			const findIndex = tempSeating.findIndex((seat) => seat.table_number === item.table_number);
			const disableIndex = availableTables.findIndex((seat) => seat.table_number === item.table_number);
			let current = [];
			let next = [];

			if (disableIndex === -1) {
				const { timeline } = item;
				const { currentBooking, nextBooking } = findBookingInfo(timeline);

				current = currentBooking;
				next = nextBooking;
			}

			const active = findIndex > -1 ? 'active' : '';
			const disable = disableIndex > -1 ? '' : 'disable';

			item.disable = disable;

			return (
				<div
					key={i}
					className={`tableAreaFlex-cell ${active} ${disable}`}
					onClick={() => handleTableCellClick(item)}
				>
					{active === '' ? null : <CheckCircleOutlined style={{ color: '#3FBA88' }} theme="filled" />}

					<span>{item.table_number}</span>
					<p>
						<span>
							({item.min_seat}-{item.max_seat}人)
						</span>
						{current.length !== 0 ? <span>{current.length} 組顧客</span> : null}
						{current.length === 0 && next.length !== 0 ? (
							<span>{moment(next[0].service_start_time).format('HH:mm')}</span>
						) : null}
					</p>
				</div>
			);
		});
	};

	return (
		<section className="tableArea">
			<h3>{groupTitle}</h3>
			<div className="tableAreaFlex">{renderCell()}</div>
		</section>
	);
};

export default TableCell;
