import {useCallback} from 'react'
import moment from 'moment';
import _ from 'lodash';

import { bookingStatusObj } from '../utils/data';


export const useSetFakeTimelineStatus =  ()=>{
    return useCallback(({ state, booking, newStatus })=>{
        const newState = _.cloneDeep(state);
        const parseStatusTime = JSON.parse(booking.status_time);

        if (newStatus === 'seated') {
            const bookingTime_min = moment().minute();
            const diff = 5 - bookingTime_min % 5;
            const seatedTime = moment().add(diff, 'm').format('YYYY/MM/DD HH:mm');
            parseStatusTime[newStatus] = seatedTime;
        } else {
            parseStatusTime[newStatus] = moment().format('YYYY-MM-DD HH:mm:ss');
        }
    
        newState.forEach((table) => {
            table.timeline.forEach((findBooking) => {
                if (findBooking.id === booking.id) {
                    findBooking.status = newStatus;
                    findBooking.status_t = bookingStatusObj[newStatus];
                    findBooking.status_time = JSON.stringify(parseStatusTime);
                }
            });
        });
    
        return newState;

    }, [])
}