import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateDayAnnouncements } from '../../../actions/announcementAction';

const MonthTableRowCell = ({
	queryDate,
	date,
	vacantStyle,
	bookingCount,
	bookingAttendanceCount,
	isToday,
	unconfirmedCount,
	isHolidayStyle,
	changeUrlDate
}) => {
	const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
		monthAnnouncements
	} = announcementListReducer;
	const effectAnnouncement = monthAnnouncements[queryDate];
	const dispatch = useDispatch();

	const todayStyle = isToday ? 'isToday' : '';
	const selectedDate = () => {
		changeUrlDate(queryDate);
		dispatch(updateDayAnnouncements(queryDate));
	};

	return (
		<div className={`monthTableRow__cell ${vacantStyle} ${isToday}`} onClick={() => selectedDate()}>
			<span className="date">{date}</span>
			{ effectAnnouncement && effectAnnouncement.length > 0 ? <span className={`announcementIcon ${todayStyle}`} /> : null }
			<div className="totalAttendance">
				{bookingCount ? <span className="bookingSystem">預約{bookingCount}</span> : null}
				{bookingAttendanceCount ? <span className='bookingAttendance'>{bookingAttendanceCount}</span> : null}
			</div>
			{unconfirmedCount ? <span className="unconfiremedStyle" /> : null}

			{isHolidayStyle && isHolidayStyle.name !== '' ? (
				<span className="holiday">{isHolidayStyle.name}</span>
			) : null}
		</div>
	);
};

export default MonthTableRowCell;
