import _ from 'lodash';

export const swapReorder = ({
	tables,
	dragTableName,
	droppedTable,
	dragBookingIndex,
	swappedBooking,
	dropBookingIndex,
	dragBooking
}) => {
	const newTables = _.cloneDeep(tables);
	const newDragBooking = _.cloneDeep(dragBooking);
	const newSwappedBooking = _.cloneDeep(swappedBooking);

	const dragBookingTargetSeat = _.cloneDeep(dragBooking.target_seat);
	const dropBookingTargetSeat = _.cloneDeep(swappedBooking.target_seat);
	const oldSeatIndex = newDragBooking.seats.map((seat) => seat.table_number).indexOf(dragTableName);
	const oldSeat = _.cloneDeep(newDragBooking.seats[oldSeatIndex]);

	//被交換的桌子
	const newSeatIndex = newSwappedBooking.seats.map((seat) => seat.table_number).indexOf(droppedTable.table_number);
	const newSeat = _.cloneDeep(newSwappedBooking.seats[newSeatIndex]);

	newDragBooking.target_seat = dropBookingTargetSeat;
	newSwappedBooking.target_seat = dragBookingTargetSeat;

	newDragBooking.seats.splice(oldSeatIndex, 1, newSeat);
	newSwappedBooking.seats.splice(newSeatIndex, 1, oldSeat);

	newTables.forEach((table) => {
		if (table.table_number === dragTableName) {
			table.timeline.splice(dragBookingIndex, 1, newSwappedBooking);
		}

		if (table.table_number === droppedTable.table_number) {
			table.timeline.splice(dropBookingIndex, 1, newDragBooking);
		}
	});

	return { newTables, newDragBooking, newSwappedBooking };
};

export const seatedReorder = ({ tables, dragTableName, droppedTable, dragBookingIndex, dragBooking }) => {
	const newTables = _.cloneDeep(tables);
	const newDroppedTable = _.cloneDeep(droppedTable);
	const newBooking = _.cloneDeep(dragBooking);
	let dragTimeline = null;
	let dropTimeline = null;

	newTables.forEach((table) => {
		if (table.table_number === dragTableName) {
			dragTimeline = _.cloneDeep(table.timeline);
		}

		if (table.table_number === newDroppedTable.table_number) {
			dropTimeline = _.cloneDeep(table.timeline);
		}
	});

	dropTimeline.push(newBooking);
	dragTimeline.splice(dragBookingIndex, 1);

	const oldSeatIndex = newBooking.seats.map((seat) => seat.table_number).indexOf(dragTableName);

	delete newDroppedTable['timeline'];

	newBooking.target_seat = newDroppedTable;
	newBooking.seats.splice(oldSeatIndex, 1, newDroppedTable);

	return { newTables, newBooking };
};

