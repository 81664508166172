import React from 'react';
import { Modal } from 'antd';

import '../bookingSetting.scss';

export const LineNotifyRebindModal = ({ cancel, bindLineNotify }) => {
  return (
    <Modal
      title="確定要重新綁定嗎？"
      visible
      centered
      className="bookingSettingMsgLineNotifyModal"
      footer={
        <>
          <button className='btn_cancel' onClick={cancel}>取消</button>
          <button className='btn_save' onClick={bindLineNotify}>確認</button>
        </>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      重新綁定後原本連動的LINE帳號或群組將不會再收到預約回報。確定重新綁定嗎？
    </Modal>
  );
};

export const LineNotifyUnbindModal = ({ cancel, unbindLineNotify }) => {
  return (
    <Modal
      title="確定要關閉Line Notify通知嗎？"
      visible
      centered
      className="bookingSettingMsgLineNotifyModal"
      footer={
        <>
          <button className='btn_cancel' onClick={cancel}>取消</button>
          <button className='btn_save' onClick={unbindLineNotify}>確認</button>
        </>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      關閉後，如欲重啟需再重新綁定LINE個人帳號。確定關閉嗎？
    </Modal>
  );
};
