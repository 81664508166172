import {
  callApi
} from "../utils/apiUtils";

import { config } from '../utils/config'
import { setAlertMessage } from '../actions/flashMessage'

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const SWITCH_USER_SUCCESS = "SWITCH_USER_SUCCESS";
export const SWITCH_USER_FAILURE = "SWITCH_USER_FAILURE";

export const COUPON_BINDIND  = 'COUPON_BINDIND'

function loginRequest(user) {
  return setAlertMessage("登入中...")
  // return {
  //   type: LOGIN_REQUEST,
  //   user
  // };
}

function loginSuccess(payload) {
  return checkSession()
  // return {
  //   type: LOGIN_SUCCESS,
  //   user: payload.user,
  //   shop: payload.shop
  // };
}

function getUserSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    user: payload.user,
    shop: payload.shop,
    booking_setting: payload.booking_setting,
    switchable_users: payload.switchable_users
  };
}

function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error
  };
}


function loginFailure2(json) {
  let msg = "錯誤"
  if(json.error) { msg = json.error }
  return setAlertMessage(msg, 'error')
}

export function login(user_id, password, shop_name) {
  const post_config = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
        user: {
            user_id, password, shop_name
        }
    })
  };

  let url = config.domain + '/auth/sign_in.json';
  return callApi(
    url,
    post_config,
    loginRequest(user_id),
    loginSuccess,
    loginFailure2
  );
}



export function checkSession() {
  const fetchConfig = {
    method: "get",
  }
  const url = config.domain + '/auth/is_login';
  //const path = this.props.location.pathname;
  //const { router } = this.context;
  return callApi(
    url,
    fetchConfig,
    loginRequest, //onRequest
    getUserSuccess, //success
    loginFailure //fail
  );
}

function switchUserSuccess(){
  return {
    type: SWITCH_USER_SUCCESS
  }
}

function switchUserFailure(error) {
  setAlertMessage(error, 'error')
}

export function switchUser(userId) {
  const fetchConfig = {
    method: "post",
    body: JSON.stringify({
        id: userId
    })
  }
  const url = config.domain + '/auth/sign_in_as';
  //const path = this.props.location.pathname;
  //const { router } = this.context;
  return callApi(
    url,
    fetchConfig,
    loginRequest(), //onRequest
    switchUserSuccess, //success
    switchUserFailure //fail
  );
}