import React, { useEffect, useCallback, useContext } from 'react';
import moment from 'moment';

import { EmptyBookingList } from './Booking/booking';
import BookingListCard from './Booking/bookingListCard';

import { QueueStartNew, ToSetQueueGroup, QueueEmpty } from './Queue/Queue';
import QueueListCard from './Queue/QueueListCard';

import { CommomSettingContext } from './NewModuleReducer/CommomReuducer';
import { ListStateContext } from './NewModuleReducer/ListReducer';

const List = ({ activeSystem, updateQueueSystem, updateQueueRecord, startQueueTimeline, serviceTimeOptionEnabled }) => {
	const { CommomSettingState } = useContext(CommomSettingContext);
	const { todayDate, date, isToday } = CommomSettingState;

	const { ListReducerState, ListStateDispatch } = useContext(ListStateContext);
	const {
		bookings,
		queueState,
		queueGroup,
		activeQueueStatusTab,
		activeGroupTag,
		queueList,
		currentTag,
		search,
		searchBooking
	} = ListReducerState;

	useEffect(
		() => {
			if (search) {
				const { id } = searchBooking;
				const $booking = document.getElementById(id);

				if ($booking) {
					$booking.scrollIntoView();
					$booking.classList.add('active_scroll');

					setTimeout(() => {
						$booking.classList.remove('active_scroll');
					}, 1500);

					ListStateDispatch({ type: 'clearSearch' });
				}
			}
		},
		[ ListStateDispatch, search, searchBooking ]
	);

	const renderBookingList = useCallback(
		() => {
			if (bookings.length === 0) return <EmptyBookingList />;

			bookings.sort((a, b) => {
				if(bookings[0].status === 'confirmed' || bookings[0].status === 'finish' || bookings[0].status === 'unconfirmed' || bookings[0].status === 'no_show' || bookings[0].status === 'cancel') {
					return new Date(a.booking_datetime) - new Date(b.booking_datetime);
				} else if(bookings[0].status === 'show' || bookings[0].status === 'seated') {
					return new Date(a.updated_at) - new Date(b.updated_at);
				}
			})

			return (
				<div className="newModule__bookingList">
					{bookings.map((booking, index) => {
						return (
							<BookingListCard key={booking.id} booking={booking}>
								<BookingListCard.Header>
									<BookingListCard.Header.Time booking={booking} />
									<BookingListCard.Header.Status booking={booking} />
								</BookingListCard.Header>
								<BookingListCard.BookingListCardBody booking={booking}>
									<BookingListCard.BookingListCardBody.Info booking={booking} index={index} serviceTimeOptionEnabled={serviceTimeOptionEnabled} />
									<BookingListCard.BookingListCardBody.Seats booking={booking} />
								</BookingListCard.BookingListCardBody>
								<BookingListCard.BookingListCardFooter booking={booking} />
							</BookingListCard>
						);
					})}
				</div>
			);
		},
		[ bookings ]
	);

	const renderQueueList = useCallback(
		() => {
			if (queueGroup.length === 0) {
				//沒設定組別
				return (
					<div className="queueList">
						<ToSetQueueGroup />
					</div>
				);
			}

			const list = queueList[activeQueueStatusTab];
			const isPassedDay = moment(new Date(date)).isBefore(new Date(todayDate));

			if (isToday) {
				//當日

				if (queueState === 'end') {
					//結束時段
					return (
						<div className="queueList">
							<QueueStartNew openQueueSystem={updateQueueSystem} showQueueAddBtn={isToday} />
						</div>
					);
				} else if (queueState !== 'end' && list.length === 0) {
					//非結束時段且沒人排隊
					return (
						<div className="queueList">
							<QueueEmpty />
						</div>
					);
				} else if (queueState !== 'end' && list.length !== 0) {
					//非結束時段且正常排隊
					//比較group current tag是否與list tag相同
					//相同: 該時段的排隊列表
					//不相同: 非該時段的排隊，不顯示

					return (
						<div className="queueList">
							{list.map((queue) => {
								if (queue.tag === currentTag) {
									if (activeGroupTag === 'all') {
										return (
											<QueueListCard key={queue.id} queue={queue}>
												<QueueListCard.Header queue={queue} />
												<QueueListCard.Body>
													<QueueListCard.Body.Info queue={queue} />
													<QueueListCard.Body.Memo queue={queue} />
													<QueueListCard.Body.Button
														queue={queue}
														updateQueueRecord={updateQueueRecord}
														startQueueTimeline={startQueueTimeline}
													/>
												</QueueListCard.Body>
											</QueueListCard>
										);
									} else if (queue.group_name === activeGroupTag) {
										return (
											<QueueListCard key={queue.id}  queue={queue}>
												<QueueListCard.Header queue={queue} />
												<QueueListCard.Body>
													<QueueListCard.Body.Info queue={queue} />
													<QueueListCard.Body.Memo queue={queue} />
													<QueueListCard.Body.Button
														queue={queue}
														updateQueueRecord={updateQueueRecord}
														startQueueTimeline={startQueueTimeline}
													/>
												</QueueListCard.Body>
											</QueueListCard>
										);
									}
								}
								return null;
							})}
						</div>
					);
				}
			} else if (!isToday && isPassedDay) {
				//昨日
				if (list.length === 0) {
					//沒人排隊
					return (
						<div className="queueList">
							<QueueEmpty />
						</div>
					);
				} else if (list.length !== 0) {
					//有人排隊
					return (
						<div className="queueList">
							{list.map((queue) => {
								if (activeGroupTag === 'all') {
									return (
										<QueueListCard key={queue.id}  queue={queue}>
											<QueueListCard.Header queue={queue} />
											<QueueListCard.Body>
												<QueueListCard.Body.Info queue={queue} />
												<QueueListCard.Body.Memo queue={queue} />
												<QueueListCard.Body.Button
													queue={queue}
													updateQueueRecord={updateQueueRecord}
													startQueueTimeline={startQueueTimeline}
												/>
											</QueueListCard.Body>
										</QueueListCard>
									);
								} else if (queue.group_name === activeGroupTag) {
									return (
										<QueueListCard key={queue.id}  queue={queue}>
											<QueueListCard.Header queue={queue} />
											<QueueListCard.Body>
												<QueueListCard.Body.Info queue={queue} />
												<QueueListCard.Body.Memo queue={queue} />
												<QueueListCard.Body.Button
													queue={queue}
													updateQueueRecord={updateQueueRecord}
													startQueueTimeline={startQueueTimeline}
												/>
											</QueueListCard.Body>
										</QueueListCard>
									);
								}
								return null;
							})}
						</div>
					);
				}
			} else if (!isToday && !isPassedDay) {
				//明日
				return (
					<div className="queueList">
						<QueueEmpty />
					</div>
				);
			}
		},
		[
			activeGroupTag,
			activeQueueStatusTab,
			currentTag,
			date,
			isToday,
			queueGroup.length,
			queueList,
			queueState,
			startQueueTimeline,
			todayDate,
			updateQueueRecord,
			updateQueueSystem
		]
	);

	return (
		<React.Fragment>
			{activeSystem === 'booking' ? renderBookingList() : renderQueueList()}
		</React.Fragment>
	);
};

export default List;
