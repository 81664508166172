import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { config } from '../../utils/config';
import { handleError } from '../../libs/handler';
import { MenuOutlined } from '@ant-design/icons';

import UserNav from './UserNav';
import UserProfile from './UserProfile';
import UserPassword from './UserPassword';
import ConfirmPopup from '../popup/confirmPopup';
import './user.scss';

const confirmPopupText = {
	title: '密碼變更成功',
	content: '密碼變更成功，請以新密碼重新登入。'
};

const passwordErrorInit = {
  oldPasswordEmpty: false,
  oldPasswordWrong: false,
  newPasswordLength: false,
  confirmPasswordLength: false,
  passwordValidate: false,
};

const UserProfileBox = ({
  profile_url = '/dashboard/users/profile',
  url = '/dashboard/users/',
  upload_url = '/dashboard/upload',
  remove_url = '/dashboard/remove',
  reset_url = '/dashboard/users/update_password'
}) => {
  const [data, setData] = useState({
    avatar: '',
    name: '',
    job_title: '',
    user_id: ''
  });
  const [displayData, setDisplayData] = useState({
    avatar: '',
    name: '',
    job_title: '',
    user_id: ''
  });
  const [activeTab, setActiveTab] = useState('profile');
  const [showError, setShowError] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState(passwordErrorInit);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const { user_id } = user;
  const superAdminId = 'findlifeadmin';

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if(user_id === superAdminId) {
      browserHistory.push({
        pathname: '/dashboard'
      });
    }
  }, [user_id])

  const load = () => {
    window.app.alert.setMessage('載入中...', 'info');

    $.ajax({
      url: config.domain + profile_url,
      dataType: 'json',
      xhrFields: { withCredentials: true },
      success: (data) => {
        window.app.alert.setMessage('載入完成', 'done');
        setData(data.user);
        setDisplayData(data.user);
      },
      error: (xhr) => {
        window.app.alert.setMessage(xhr.responseJSON.error, 'error');
        handleError(xhr);
      }
    });
  }

  const handleSubmit = () => {
    let newData = {user: data};

    if(data.name.trim() === '') {
      setShowError(true);
      return window.app.alert.setMessage('請輸入姓名', 'error');
    }

    $.ajax({
      url: config.domain + url + '/' + data.id,
      dataType: 'json',
      data: newData,
      type: 'PUT',
      xhrFields: { withCredentials: true },
      success: function() {
        setDisplayData(data);
        setShowError(false);
        window.app.alert.setMessage('已儲存變更', 'done');
      },
      error: function(xhr, status, err) {
        window.app.alert.setMessage(data.message, 'error');
      }
    });
  }
  
  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleInputPassword = (e) => {
    const { name, value } = e.target;
    let copyPasswordError = Object.assign({}, passwordError);

    if(name === 'old_password') {
      setOldPassword(value);
      copyPasswordError.oldPasswordEmpty = false;
      copyPasswordError.oldPasswordWrong = false;
    }
    if(name === 'new_password') {
      setNewPassword(value);
      copyPasswordError.newPasswordLength = false;
    }
    if(name === 'new_password_confirm') {
      setNewPasswordConfirm(value);
      copyPasswordError.confirmPasswordLength = false;
    }

    copyPasswordError.passwordValidate = false;
    setPasswordError(copyPasswordError);
  };

  const handleResetPassword = () => {
    let copyPasswordError = Object.assign({}, passwordError);
    let isDisabled = false;
    let minLength = 4;

    if(oldPassword.length === 0) {
      copyPasswordError.oldPasswordEmpty = true;
      isDisabled = true;
    }
    if(newPassword.length < minLength) {
      copyPasswordError.newPasswordLength = true;
      isDisabled = true;
    }
    if(newPasswordConfirm.length < minLength) {
      copyPasswordError.confirmPasswordLength = true;
      isDisabled = true;
    }
    if(isDisabled) {
      return setPasswordError(copyPasswordError);
    };

    $.ajax({
      url: window.domain + reset_url,
      dataType: 'json',
      data: {
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirm: newPasswordConfirm
      },
      type: 'PUT',
      xhrFields: { withCredentials: true },
      success: function(data) {
        setPasswordError(passwordErrorInit);
        setOldPassword('');
        setNewPassword('');
        setNewPasswordConfirm('');
        setShowConfirmPopup(true);
      },
      error: function(xhr, status, err) {
        const oldPasswordError = '舊密碼錯誤';
        const confirmPasswordError = '確認碼與新密碼不相符';
        let copyPasswordError = Object.assign({}, passwordError);

        if (xhr.responseJSON.message === oldPasswordError) {
          copyPasswordError.oldPasswordWrong = true;
          setPasswordError(copyPasswordError);
        } else if (xhr.responseJSON.message === confirmPasswordError) {
          copyPasswordError.passwordValidate = true;
          setPasswordError(copyPasswordError);
          window.app.alert.setMessage('新密碼輸入不一致，請重新確認', 'error');
        } else {
          window.app.alert.setMessage(xhr.responseJSON.message, 'error');
        }
      }
    });
  };

  const handleConfirm = () => {
    setShowConfirmPopup(false);
    browserHistory.push({
      pathname: '/login'
    });
  };

  const handleTab = (value) => {
    setActiveTab(value);
    setData(displayData);
    setOldPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
    setShowError(false);
    setPasswordError(passwordErrorInit);
    closeMenu();
  };

  const toggleMenu = () => {
    const menu = document.getElementsByClassName('tab_menu')[0];
    menu.classList.toggle('show');
  };

  const closeMenu = () => {
    const menu = document.getElementsByClassName('tab_menu')[0];
    menu.classList.remove('show');
  };

  return (
    <div className='userProfileBox'>
      <h3>
        <MenuOutlined onClick={toggleMenu} />
        編輯個人資訊
      </h3>
      <UserNav
        isMobile={true}
        handleTab={handleTab}
        activeTab={activeTab}
      />
      <div className='block'>
        <UserNav
          isMobile={false}
          handleTab={handleTab}
          activeTab={activeTab}
        />
        {activeTab === 'profile' ? (
          <UserProfile
            userId={data.user_id}
            name={data.name}
            jobTitle={data.job_title}
            showError={showError}
            handleInputChange={handleInputChange}
            submit={handleSubmit}
          />
        ) : (
          <UserPassword
            oldPassword={oldPassword}
            newPassword={newPassword}
            newPasswordConfirm={newPasswordConfirm}
            handleInputChange={handleInputPassword}
            submit={handleResetPassword}
            passwordError={passwordError}
          />
        )}
        <div className='menu_mask' onClick={closeMenu} />
        {showConfirmPopup &&
          <ConfirmPopup
            title={confirmPopupText.title}
            content={confirmPopupText.content}
            submit={handleConfirm}
          />
        }
      </div>
    </div>
  );
}

export default UserProfileBox;