import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import {
  announcementSettingEnded,
  announcementChange,
  handleHeaderFilter,
  showAnnouncementAddModal,
  clearAnnouncement
} from '../../../actions/announcementAction';

import { updateOfficialAnnouncementDisplay } from '../api/OfficialAnnouncementApi';

const day_list = ['日', '一', '二', '三', '四', '五', '六'];
const typeTxt = {
	regular: '一般公告',
	long_term: '常態公告'
};
const announceEditList = [
  { title: '編輯', action: 'edit' },
  { title: '設為結束', action: 'finished' },
  { title: '刪除', action: 'deleted' },
];

const statusSelectList = [
  { title: '每日顯示一次', action: 'once' },
  { title: '不再顯示', action: 'never' }
];

const AnnouncementPreviewModal = ({ isDashboard, currentTab }) => {
  const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
		role,
    page,
    currentFilter
	} = announcementListReducer;
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    id,
    type,
    category,
    title,
    content,
    startDateTime,
    endDateTime,
    showEnabled,
    finished,
    officialShowEnabled,
    officialDisplayMode
  } = announcementInfo;
  const dispatch = useDispatch();

  const cancelPopup = () => {
    dispatch(clearAnnouncement());
  };

  const handleShowFrequency = async(value, id) => {
    let setting = {
      id: id,
      display_mode: value
    };

    try {
      await updateOfficialAnnouncementDisplay(setting);
      dispatch(handleHeaderFilter(currentFilter, page));
      dispatch(announcementChange('officialDisplayMode', value))
    } catch(err) {
      console.log('set official announcement frequency err ----------', err);
    }
  };

  const handleEditor = (item) => {
    if (item === 'edit') {
      dispatch(showAnnouncementAddModal());
		} else if(item === 'finished' || item === 'deleted') {
      dispatch(announcementSettingEnded(item));
    } else if(item === 'once' || item === 'never') {
      handleShowFrequency(item, id);
    }
  };

  const renderEditList = () => {
    if(currentTab === 'shop' || role === 'official') {
      const editList = _.cloneDeep(announceEditList);
      let newEditList = [];

      if(finished) {
        newEditList = editList.filter((i) => i.action !== 'finished');
      } else {
        newEditList = announceEditList;
      }

      return <Menu>
        { newEditList.map((item) => {
          return (
            <Menu.Item
              className={item.action === 'deleted' ? 'option_deleted' : ''}
              key={item.action}
              onClick={() => handleEditor(item.action)}
            >
              {item.title}
            </Menu.Item>
          );
        })
        }
      </Menu>
    } else {
      return <Menu>
        { statusSelectList.map((item) => {
        return (
            <Menu.Item key={item.action} onClick={() => handleEditor(item.action)}>
              {item.title}
            </Menu.Item>
          );
        })}
      </Menu>
    }
	};

  const renderTitleAndEdit = () => {
    if(!isDashboard) {
      return (
        <div className='title'>
          {title}
          <Dropdown
            className={`${type === 'official' && role === 'shop' && finished ? 'listCardDropDown_disabled' : ''} listCardDropDown`}
            placement="bottomRight"
            overlay={(
              renderEditList()
            )}
            trigger={['click']}
            overlayClassName='announcementPreviewModal_dropDown_menu'
            disabled={type === 'official' && role === 'shop' && finished}
          >
            <MoreOutlined />
          </Dropdown>
        </div>
      )
    } else {
      return (
        <div className='title'>
          {title}
        </div>
      )
    }
  };

  const renderStatus = () => {
    if(type === 'official') {
      if(role === 'official') {
        return (
          <Fragment>
            <span>{ finished ? '結束公告' : '找活公告' }</span>
            { !officialShowEnabled ? '・已關閉發布' : null }
          </Fragment>
        )
      }

      if(role === 'shop') {
        return (
          <Fragment>
            <span>找活公告</span>
            { finished ? '・已結束' : null }
            { !finished && officialDisplayMode === 'never' ? '・已關閉每日彈出提醒' : null }
          </Fragment>
        )
      }
    }

    return (
      <Fragment>
        <span>{ finished ? '結束公告' : typeTxt[category] }</span>
        { !finished && showEnabled && category !== 'long_term' ? '・已發布在系統首頁與預約系統頁面' : null }
      </Fragment>
    )
  };

  const renderStartTime = () => {
    let day_start = new Date(startDateTime).getDay();
    let dateTime = '';

    if(startDateTime) {
      dateTime = 
        moment(startDateTime).format('YYYY-MM-DD') + 
        '（' + day_list[day_start] + '）' +
        moment(startDateTime).format('HH:mm');
    }

    return <div className='dateTime'>開始時間：{dateTime}</div>
  };

  const renderEndTime = () => {
    let day_end = new Date(endDateTime).getDay();
    let dateTime = '';

    if(endDateTime) {
      dateTime = 
        moment(endDateTime).format('YYYY-MM-DD') +
        '（' + day_list[day_end] + '）' +
        moment(endDateTime).format('HH:mm');

      return <div className='dateTime'>結束時間：{dateTime}</div>
    }
  };

  return (
    <Modal
      visible
      centered
      className="announcementPreviewModal"
      onCancel={cancelPopup}
      footer={
        <button className='btn_save' onClick={cancelPopup}>關閉</button>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className='header'>
        { renderTitleAndEdit() }
        <div className='type'>
          { renderStatus() }
        </div>
        { renderStartTime() }
        { renderEndTime() }
      </div>
      <div className='content'>{content}</div>
    </Modal>
  );
};

export default AnnouncementPreviewModal;