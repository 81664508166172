import React from 'react';
import { Modal } from 'antd';

import './styles/doubleBooking.scss';

export default ({ conflictTables, setConflictTables }) => {
	return (
		<Modal
			destroyOnClose
			centered
			visible={conflictTables.length !== 0}
			wrapClassName="doubleBookingModal"
			footer={
				<button
					onClick={() => {
						setConflictTables([]);
					}}
				>
					確定
				</button>
			}
			title="預約重疊"
			onOk={() => setConflictTables([])}
			onCancel={() => setConflictTables([])}
		>
			<div className="doubleBookingModalBody">桌次 {conflictTables.join('、')} 有正在進行中的預約。</div>
		</Modal>
	);
};
