import React, { Fragment } from 'react';
import $ from 'jquery';

import BookingRow from './BookingRow';
import BookingSearch from '../../Search';
import { BookingListTab } from './BookingListTab'

export default class BookingListView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			bookings: this.props.bookings,
			// loading: this.props.loading,
			customQ: [],
			bookingTimeCount: {},
			serviceTimeOptionEnabled: this.props.serviceTimeOptionEnabled
			// bookingSearchVisible: false
		};

		this.renderConfirmedBookings = this.renderConfirmedBookings.bind(this);
		this.rendCancelBookings = this.rendCancelBookings.bind(this);
		this.renderNoShowBookings = this.renderNoShowBookings.bind(this);
		this.renderFinishBookings = this.renderFinishBookings.bind(this);
	}

	componentDidMount() {
		$('[data-toggle="popover"]').popover({
			html: true,
			trigger: 'click',
			container: '.container'
		});
		$('body').on('click', function(e) {
			if (
				$(e.target).data('toggle') !== 'popover' &&
				$(e.target).parents('[data-toggle="popover"]').length === 0 &&
				$(e.target).parents('.popover.in').length === 0
			) {
				$('[data-toggle="popover"]').popover('hide');
			}
		});

		this.countAttendancePerBookingTime();
	}

	countAttendancePerBookingTime = () => {
		const { bookings, bookingTimeCount } = this.state;
		let timeZone = null;

		bookings.map((booking) => {
			if (booking.status !== 'no_show' && booking.status !== 'cancel' && booking.status !== 'finish') {
				let hour = booking.time.slice(0, 2);
				let bookingTime = `${hour}:00`;

				if (timeZone === bookingTime) {
					bookingTimeCount[bookingTime] = bookingTimeCount[bookingTime] + booking.attendance;
				} else {
					timeZone = bookingTime;
					bookingTimeCount[bookingTime] = booking.attendance;
				}
			}

			return null;
		});

		this.setState({ bookingTimeCount });
	};

	componentDidUpdate(oldProps, oldState) {
		let { customQ, bookings } = this.props;
		$('[data-toggle="popover"]').popover({
			html: true,
			trigger: 'click',
			container: '.container'
		});

		if (oldState.bookings !== bookings) {
			this.setState(
				{
					bookings
					// loading: false
				},
				() => this.countAttendancePerBookingTime()
			);
		}

		if (oldState.customQ !== customQ) {
			this.setState({ customQ });
		}
	}

	handleScroll = () => {
		let { scrollToActiveCode } = this.props;

		if (scrollToActiveCode) {
			let scorllDiv = document.getElementById(scrollToActiveCode.activeCode);

			if (scorllDiv) {
				let scorllDiv_offsetTop = scorllDiv.offsetTop;
				window.scrollTo(0, scorllDiv_offsetTop);
				scorllDiv.classList.add('active_scroll');

				setTimeout(() => {
					scorllDiv.classList.remove('active_scroll');
					delete scrollToActiveCode['activeCode'];
				}, 1500);
			}
		}
	};

	renderConfirmedBookings() {
		let { bookings, customQ, bookingTimeCount, serviceTimeOptionEnabled } = this.state;
		let timeZone = null;

		let confirmedBookings = bookings.filter((booking) => booking.status === 'confirmed')
		let hiddenBookings = bookings.filter((booking) => booking.status === 'hidden')

		const displayBookings = confirmedBookings.concat(hiddenBookings);

		if (this.props.activeBookingListStatusTab === 'confirmed') {
			if (displayBookings.length === 0) {
				return (
					<div className="noBookingView">
						<img src={require('../../../images/empty_booking.png')} height={25} alt="" />
					</div>
				);
			} else if (bookings.length !== 0) {
				return bookings.map((booking) => {
					let hour = booking.time.slice(0, 2);
					if (
						timeZone === hour &&
						booking.status !== 'cancel' &&
						booking.status !== 'finish' &&
						booking.status !== 'no_show' &&
						booking.status !== 'show' &&
						booking.status !== 'unconfirmed' &&
						booking.status !== 'seated'
					) {
						return (
							<BookingRow
								customQ={customQ}
								booking={booking}
								bookings={this.props.bookings}
								status={booking.status}
								key={booking.id}
								setStatus={this.props.setStatus}
								showAddCustomerModal={this.props.showAddCustomerModal}
								showNewAddBookingModal={this.props.showNewAddBookingModal}
								serviceTimeOptionEnabled={serviceTimeOptionEnabled}
							/>
						);
					} else if (
						timeZone !== hour &&
						booking.status !== 'cancel' &&
						booking.status !== 'finish' &&
						booking.status !== 'no_show' &&
						booking.status !== 'show' &&
						booking.status !== 'unconfirmed' &&
						booking.status !== 'seated'
					) {
						timeZone = hour;
						return (
							<React.Fragment key={booking.id}>
								<p className="bookingSectionTitle">
									{timeZone} : 00
									<span className="bookingSectionTitle__attendance">
										共{bookingTimeCount[`${timeZone}:00`]}人
									</span>
								</p>
								<BookingRow
									customQ={customQ}
									booking={booking}
									bookings={this.props.bookings}
									status={booking.status}
									setStatus={this.props.setStatus}
									showAddCustomerModal={this.props.showAddCustomerModal}
									showNewAddBookingModal={this.props.showNewAddBookingModal}
									serviceTimeOptionEnabled={serviceTimeOptionEnabled}
								/>
							</React.Fragment>
						);
					}
				});
			}
		}
	}

	renderFinishBookings() {
		let { bookings, customQ, serviceTimeOptionEnabled } = this.state;
		let index = bookings
			.map((b) => {
				return b.status === 'finish';
			})
			.indexOf(true);

		if (this.props.activeBookingListStatusTab === 'finish') {
			if (index > -1) {
				return (
					<section>
						{bookings.map((booking) => {
							if (booking.status === 'finish') {
								return (
									<BookingRow
										customQ={customQ}
										booking={booking}
										bookings={this.props.bookings}
										status={booking.status}
										key={booking.id}
										setStatus={this.props.setStatus}
										showAddCustomerModal={this.props.showAddCustomerModal}
										showNewAddBookingModal={this.props.showNewAddBookingModal}
										serviceTimeOptionEnabled={serviceTimeOptionEnabled}
									/>
								);
							}
						})}
					</section>
				);
			} else {
				return (
					<div className="noBookingView">
						<img src={require('../../../images/empty_booking.png')} height={25} alt="" />
					</div>
				);
			}
		}
	}

	rendCancelBookings() {
		let { bookings, customQ, serviceTimeOptionEnabled } = this.state;
		let index = bookings
			.map((b) => {
				return b.status === 'cancel';
			})
			.indexOf(true);
		
		if (this.props.activeBookingListStatusTab === 'cancel') {
			if (index > -1) {
				return (
					<section>
						{bookings.map((booking) => {
							if (booking.status === 'cancel') {
								return (
									<BookingRow
										customQ={customQ}
										booking={booking}
										bookings={this.props.bookings}
										status={booking.status}
										key={booking.id}
										setStatus={this.props.setStatus}
										showAddCustomerModal={this.props.showAddCustomerModal}
										showNewAddBookingModal={this.props.showNewAddBookingModal}
										serviceTimeOptionEnabled={serviceTimeOptionEnabled}
									/>
								);
							}
						})}
					</section>
				);
			} else {
				return (
					<div className="noBookingView">
						<img src={require('../../../images/empty_booking.png')} height={25} alt="" />
					</div>
				);
			}
		}
	}

	renderNoShowBookings() {
		let { bookings, customQ, serviceTimeOptionEnabled } = this.state;
		let index = bookings
			.map((b) => {
				return b.status === 'no_show';
			})
			.indexOf(true);

		if (this.props.activeBookingListStatusTab === 'no_show') {
			if (index > -1) {
				return (
					<section>
						{bookings.map((booking) => {
							if (booking.status === 'no_show') {
								return (
									<BookingRow
										customQ={customQ}
										booking={booking}
										bookings={this.props.bookings}
										status={booking.status}
										key={booking.id}
										setStatus={this.props.setStatus}
										showAddCustomerModal={this.props.showAddCustomerModal}
										showNewAddBookingModal={this.props.showNewAddBookingModal}
										serviceTimeOptionEnabled={serviceTimeOptionEnabled}
									/>
								);
							}
						})}
					</section>
				);
			} else {
				return (
					<div className="noBookingView">
						<img src={require('../../../images/empty_booking.png')} height={25} alt="" />
					</div>
				);
			}
		}
	}

	renderShowBookings() {
		let { bookings, customQ, serviceTimeOptionEnabled } = this.state;
		let index = bookings
			.map((b) => {
				return b.status === 'show';
			})
			.indexOf(true);
		
			const showBookingList = bookings.filter((booking) => booking.status === 'show')
			const showTimeBookingList = showBookingList.sort((a, b) => {
				return new Date(a.updated_at) - new Date(b.updated_at);
			})
			
		if (this.props.activeBookingListStatusTab === 'show') {
			if (index > -1) {
				return (
					<section>
						{showTimeBookingList.map((booking, index) => {
							return (
								<BookingRow
									customQ={customQ}
									booking={booking}
									bookings={showTimeBookingList}
									status={booking.status}
									key={booking.id}
									setStatus={this.props.setStatus}
									showAddCustomerModal={this.props.showAddCustomerModal}
									showNewAddBookingModal={this.props.showNewAddBookingModal}
									standbyIndex={index}
									activeBookingListStatusTab={this.props.activeBookingListStatusTab}
									serviceTimeOptionEnabled={serviceTimeOptionEnabled}
								/>
							);
						})}
					</section>
				);
			} else {
				return (
					<div className="noBookingView">
						<img src={require('../../../images/empty_booking.png')} height={25} alt="" />
					</div>
				);
			}
		}
	}

	renderSeatedBookings() {
		let { bookings, customQ, serviceTimeOptionEnabled } = this.state;
		let index = bookings
			.map((b) => {
				return b.status === 'seated';
			})
			.indexOf(true);

			const seatedBookingList = bookings.filter((booking) => booking.status === 'seated')
			const seatedTimeBookingList = seatedBookingList.sort((a, b) => {
				return new Date(a.updated_at) - new Date(b.updated_at);
			})

		if (this.props.activeBookingListStatusTab === 'seated') {
			if (index > -1) {
				return (
					<section>
						{seatedTimeBookingList.map((booking) => {
							if (booking.status === 'seated') {
								return (
									<BookingRow
										customQ={customQ}
										booking={booking}
										bookings={seatedTimeBookingList}
										status={booking.status}
										key={booking.id}
										setStatus={this.props.setStatus}
										showAddCustomerModal={this.props.showAddCustomerModal}
										showNewAddBookingModal={this.props.showNewAddBookingModal}
										serviceTimeOptionEnabled={serviceTimeOptionEnabled}
									/>
								);
							}
						})}
					</section>
				);
			} else {
				return (
					<div className="noBookingView">
						<img src={require('../../../images/empty_booking.png')} height={25} alt="" />
					</div>
				);
			}
		}
	}

	renderUnconfirmedBookings() {
		let { bookings, customQ, serviceTimeOptionEnabled } = this.state;
		let index = bookings
			.map((b) => {
				return b.status === 'unconfirmed';
			})
			.indexOf(true);
		
		if(this.props.activeBookingListStatusTab === 'unconfirmed'){
			if (index > -1) {
				return (
					<section>
						{bookings.map((booking) => {
							if (booking.status === 'unconfirmed') {
								return (
									<BookingRow
										customQ={customQ}
										booking={booking}
										bookings={this.props.bookings}
										status={booking.status}
										key={booking.id}
										setStatus={this.props.setStatus}
										showAddCustomerModal={this.props.showAddCustomerModal}
										showNewAddBookingModal={this.props.showNewAddBookingModal}
										serviceTimeOptionEnabled={serviceTimeOptionEnabled}
									/>
								);
							}
						})}
					</section>
				);
			} else {
				return (
					<div className="noBookingView">
						<img src={require('../../../images/empty_booking.png')} height={25} alt="" />
					</div>
				);
			}
		}
	}

	render() {
		if (this.props.loading) {
			return <div style={{ textAlign: 'center', margin: '20px 0' }}>LOADING ...</div>;
		}

		return (
			<Fragment>
				<div className="bookingBar">
					<BookingListTab
						scrollToActiveCode={this.props.scrollToActiveCode}
						changeBookingListStatusTab={this.props.handleBookingListStatusTab}
						activeBookingListStatusTab={this.props.activeBookingListStatusTab}
						bookings={this.state.bookings} />
					<div className="bookingSearch">
						<BookingSearch />
					</div>
				</div>
				<div className="listViewWrapper expandWidth">
					{this.renderConfirmedBookings()}
					{this.renderShowBookings()}
					{this.renderSeatedBookings()}
					{this.renderFinishBookings()}
					{this.renderUnconfirmedBookings()}
					{this.renderNoShowBookings()}
					{this.rendCancelBookings()}
					{this.props.scrollToActiveCode && this.handleScroll()}
				</div>
			</Fragment>
		);
	}
}

