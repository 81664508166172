import React from 'react'
import $ from 'jquery'
var createReactClass = require('create-react-class');
export default createReactClass({
  handleSubmit() {
    var url = window.location.pathname+'/'+this.props.data.id;
    this.props.onSubmit(url, {}, 'DELETE', '刪除成功', 'tip');
    $(this.refs.modal).modal('toggle');
  },
  render() {
    return (
      <div className="modal fade deleteitem" tabIndex={-1} role="dialog" aria-labelledby="mySmallModalLabel" ref='modal'>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body-center">
              <h4 className="modal-title" id="myModalLabel">確定要刪除?</h4>
              <br />
              <button type="button" className="btn-length-180 btn-color-white btn-style4" data-dismiss="modal">取消</button>
              <button type="button" className="btn-length-180 btn-color-red btn-style4" onClick={this.handleSubmit}>刪除</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
});
