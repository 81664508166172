import axios from 'axios';
import { config } from '../../../utils/config';

const announcementAxios = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

/* 取得該日公告 */
export const getSpecifiedDateAnnouncements = (params) => announcementAxios.get('/dashboard/announcements', { params });

/* 取得該月份公告 */
export const getMonthAnnouncements = (params) => announcementAxios.get('/dashboard/announcements', { params });

/* 取得所有公告 (分頁) */
// ?category=regular
// ?category=long_term
// ?category=regular&page=1
// ?page=1 所有類型
// ?sort_by=start_time&order=desc // 以開始時間做降冪排序
// ?sort_by=end_time&order=asc // 以結束時間做升冪排序
export const getAnnouncements = (params) => announcementAxios.get('/dashboard/announcements', { params });

/* 取得 "結束" 分頁公告 */
export const getFinishedAnnouncementsByPage = (params) => announcementAxios.get('/dashboard/announcements/finished', { params });

/* 搜尋標題 */
export const searchAnnouncementsByTitle = (params) => announcementAxios.get('/dashboard/announcements/search', { params });

/* 新建公告 */
export const createAnnouncement = (announcement) => announcementAxios.post('/dashboard/announcements', announcement);

/* 更新公告 */
export const updateAnnouncement = (id, announcement) => announcementAxios.patch(`/dashboard/announcements/${id}`, announcement);

/* 刪除公告 */
export const deleteAnnouncement = (id) => announcementAxios.delete(`/dashboard/announcements/${id}`);

/* 多選刪除公告 */
export const bulkDeleteAnnouncement = (id) => announcementAxios.post('dashboard/announcements/bulk_delete', id);

/* 多選結束公告 */
export const bulkFinishedAnnouncement = (id) => announcementAxios.post('dashboard/announcements/bulk_set_finished', id);