import React, { useMemo, useRef, useContext } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';

const MenuCropper = ({ closeCropper }) => {
	let CropperRef = useRef(null);
	const { CouponAddState, CouponAddDispatch } = useContext(CouponAddStateContext);
	const { cropImg, cropImgType } = CouponAddState;
	const cropSettingMemo = useMemo(
		() => {
			const initialAspectRatio = cropImgType === 'landscape' ? 5 / 3 : 19 / 30;
			const aspectRatio = cropImgType === 'landscape' ? 5 / 3 : 19 / 30;
			const minCropBoxHeight = cropImgType === 'landscape' ? 60 : 150;
			const minCropBoxWidth = cropImgType === 'landscape' ? 100 : 95;

			return {
				initialAspectRatio,
				aspectRatio,
				minCropBoxHeight,
				minCropBoxWidth
			};
		},
		[ cropImgType ]
	);

	const onCropperInit = (cropper) => {
		CropperRef.current = cropper;
	};

	const zoomin = () => {
		CropperRef.current.zoom(0.1);
	};

	const zoomout = () => {
		CropperRef.current.zoom(-0.1);
	};

	const getCropData = () => {
		if (CropperRef.current !== null) {
			CropperRef.current.getCroppedCanvas().toBlob((blob) => {
				const objURL = window.URL.createObjectURL(blob);
				
                if(cropImgType==='landscape'){
					CouponAddDispatch({ type: 'setLandscapeImg', coverImgFile: blob, coverURL: objURL  });
                }

                if(cropImgType==='portrait'){
					CouponAddDispatch({ type: 'setPortraitImg', thumbnailImgFile: blob, thumbnailURL: objURL  });

                }

                closeCropper()
			});
		}
	};


	return (
		<div className="menuCropper">
			<div className="menuCropper__header">
				<h3>
					<button className="goBackBtn" onClick={closeCropper} />
					<span>編輯封面圖片</span>
				</h3>
				<button className="greenThemeBtn" onClick={getCropData}>
					確定
				</button>
			</div>

			<div className="menuCropper__body">
				<div>
					<Cropper
						src={cropImg}
						style={{ height: 300, width: '100%' }}
						initialAspectRatio={cropSettingMemo.initialAspectRatio}
						aspectRatio={cropSettingMemo.aspectRatio}
						minCropBoxHeight={cropSettingMemo.minCropBoxHeight}
						minCropBoxWidth={cropSettingMemo.minCropBoxWidth}
						viewMode={1}
						responsive={true}
						autoCropArea={1}
						guides={false}
						onInitialized={onCropperInit}
						dragMode="move"
						zoomOnWheel={false}
						checkOrientation={false}
						rotatable={false}
					/>
				</div>
			</div>
			<div className="menuCropper__footer">
				<div className="text-center">
					<button className="cropperZoomBtn cropperZoomout" onClick={zoomout} />
					<button className="cropperZoomBtn cropperZoomin" onClick={zoomin} />
				</div>
			</div>
		</div>
	);
};

export default MenuCropper;
