import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import LoginForm from './PageLoginForm';
import FlashMessage from '../commons/FlashMessage';
import { config } from '../../utils/config';
import { checkSession } from '../../actions/auth';
import { setAlertMessage } from '../../actions/flashMessage';

import ResetAccountModal from '../popup/resetAccount/resetAccountModal';
import ConfirmPopup from '../popup/confirmPopup';

const confirmPopupText = {
	title: '忘記店家帳號',
	content: '已將您的店家帳號寄送至您的信箱，若有任何疑問請洽詢MENU店+業務。'
};

var createReactClass = require('create-react-class');
const PageLoginBox = createReactClass({
	getInitialState() {
    return {
			email: '',
			remindError : {
				wrong: false,
				empty: false,
			},
			showResetAccountModal: false,
			showConfirmPopup: false
    };
  },
	toggleShowConfirmPopup() {
		this.setState({
			showConfirmPopup: !this.state.showConfirmPopup
		})
	},
	toggleResetAccountModal() {
    this.setState({
      showResetAccountModal: !this.state.showResetAccountModal,
      email: '',
			remindError : {
				wrong: false,
				empty: false,
			},
    });
  },
	handleEmail(e) {
    this.setState({
			email: e.target.value,
			remindError: {
				wrong: false,
				empty: false
			}
		});
  },
	handleLoginSubmit(d) {
		$.ajax({
			url: config.domain + '/checkname',
			dataType: 'json',
			type: 'POST',
			data: d,
			success: function(data) {
				sessionStorage.setItem('userId', d.shop_name);
				window.app.shop_display_name = data.display_name;
				browserHistory.push('/user_login?shop_name=' + d.shop_name);
			},
			error: function(xhr) {
				const { dispatch } = this.props;
				if (xhr.responseJSON) {
					dispatch(
						setAlertMessage(xhr.responseJSON.message, 'error')
					);
				} else {
					dispatch(setAlertMessage('錯誤', 'error'));
				}
			}.bind(this)
		});
	},
	handleRemindSubmit() {
		if(this.state.email.length === 0) {
			return this.setState({
				remindError: {
					wrong: false,
					empty: true,
				}
			});
		}

		$.ajax({
			url: config.domain + '/find_merchants',
			dataType: 'json',
			type: 'POST',
			data: {
				data: {email: this.state.email}
			},
			success: function(data) {
				this.setState({
					showResetAccountModal: false,
					showConfirmPopup: true
				});
			}.bind(this),
			error: function(xhr) {
				this.setState({
					remindError: {
						wrong: true,
						empty: false,
					}
				});
			}.bind(this)
		});
	},
	componentDidMount() {
		// $.ajax({
		//   url: config.domain + '/auth/is_login',
		//   dataType: 'json',
		//   xhrFields: { withCredentials: true },
		//   success: function(data) {
		//     //已登入
		//     //browserHistory.push({pathname: "/dashboard"});
		//   },
		//   error: function(xhr) {
		//     //還沒登入不做任何事
		//   }
		// })

		const { dispatch } = this.props;
		// 檢查是否登入，已登入直接到 dashboard
		dispatch(checkSession());

		$(function() {
			$('[data-toggle="popover"]').popover();
		});

		$('#exampleModal').on('show.bs.modal', function(event) {
			var button = $(event.relatedTarget); // Button that triggered the modal
			var recipient = button.data('whatever'); // Extract info from data-* attributes
			// If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
			// Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
			var modal = $(this);
			modal.find('.modal-title').text('New message to ' + recipient);
			modal.find('.modal-body input').val(recipient);
		});
	},
	componentDidUpdate(prevProps){
		if(this.props.user){
			const query = this.props.location.query;
      if (query.redirect) {
				browserHistory.push(query.redirect);
			} else {
				browserHistory.push('/dashboard');
			}
		}

	},
	render() {
		return (
			<div style={{ height: '100vh' }}>
				{/* Navigation */}
				<FlashMessage forwardRef='alert' />
				<nav
					className='navbar navbar-custom navbar-fixed-top'
					style={{ background: '#fff' }}>
					<div className='container'>
						<div className='navbar-header'>
							<Link
								className='navbar-brand page-scroll'
								to='/'
								style={{ color: '#000' }}>
								<span className='light glyphicon glyphicon-menu-left'>
									{' '}
									MENU店＋
								</span>
							</Link>
						</div>
					</div>
					{/* /.container */}
				</nav>
				{/* Intro Header */}
				<div className='intro welcome-bg' style={{ height: '100%' }}>
					<div className='intro-body'>
						<div className='container loginframe'>
							<div className='shoplogo_signin' />
							<LoginForm
								toggleResetAccountModal={this.toggleResetAccountModal}
								onLoginSubmit={this.handleLoginSubmit}
							/>
						</div>
					</div>
				</div>
				{/* Footer */}
				<footer className='menushop-index-footer'>
					<div className='container text-center'>
						<p>
							{' '}
							找活股份有限公司版權所有
							<br />
							＠2016 - 2017 FindLife Lnc. All rights reserved.
						</p>
					</div>
				</footer>

				{this.state.showResetAccountModal &&
					<ResetAccountModal
						type={'shopAccount'}
						remindError={this.state.remindError}
						handleEmail={this.handleEmail}
						submit={this.handleRemindSubmit}
						cancel={this.toggleResetAccountModal}
					/>
				}
				{
					this.state.showConfirmPopup &&
					<ConfirmPopup
						title={confirmPopupText.title}
						content={confirmPopupText.content}
						submit={this.toggleShowConfirmPopup}
					/>
				}
			</div>
		);
	}
});

function mapStateToProps(state) {
	return { user: state.auth.user };
}

export default connect(mapStateToProps)(PageLoginBox);
