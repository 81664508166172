import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';

import BookingSystemApi from '../../booking_settings/BookingSystemApi';

const BookingAPI = new BookingSystemApi();


const SwapMoreSeatPanel = ({
    swapBookingInfo,
    closeSwapMoreSeat,
    updateBooking,
    setShowSwapOverlayTables,
    setShowAttendanceOverMaxSeatsModal
    }) =>{
    const { CommomSettingState, setUuid } = useContext(CommomSettingContext);
    const { selectedBookingTableData, swapOverlayTableData } = CommomSettingState;
    
    useEffect(()=>{
        document.getElementById(swapBookingInfo.status).click();
        
        setTimeout(()=> {
            const $bookingCard = document.getElementById(swapBookingInfo.id);
            const $clone = $bookingCard.cloneNode(true);
            const $switchSeatPanel = document.getElementById('switchSeatPanel');
            const swapConfirmedBtn = document.getElementById('swapConfirmedBtn')
            const swapOriginalSeatText_noTag = 
                `<div style="position: absolute;
                    top: 151px;
                    left: 12px;
                    font-size: 14px;
                    color: #676767;">
                    原座位：
                </div>`
            const swapOriginalSeatText = 
                `<div style="position: absolute;
                    top: 126px;
                    left: 12px;
                    font-size: 14px;
                    color: #676767;">
                    原座位：
                </div>`

            if($bookingCard.children[1].children[0].classList.contains('onSiteTag')) {
                $switchSeatPanel.insertAdjacentHTML("beforeend", swapOriginalSeatText_noTag);
            } else {
                $switchSeatPanel.insertAdjacentHTML("beforeend", swapOriginalSeatText);
            }
    
            $switchSeatPanel.append($clone);
            swapConfirmedBtn.style.top = `${$clone.offsetHeight - 5}px`
            document.getElementById('confirmed').click();

            return()=>{
                $switchSeatPanel.innerHTML='';
            }
        },0)
    }, [swapBookingInfo])

    useEffect(()=>{
        const inserDom = `<div class="SwitchSeatPanelTitle">重新選擇安排座位</div>`;
        const $tablemap = document.getElementsByClassName('tablemap')[0];
        $tablemap.insertAdjacentHTML("afterbegin", inserDom);

        return()=>{
            const $SwitchSeatPanelTitle = document.getElementsByClassName("SwitchSeatPanelTitle")[0];
            $tablemap.removeChild($SwitchSeatPanelTitle);
        }
    }, [])

    useEffect(()=>{
        const queueSelectedTable = document.querySelector('.queueSelectedTable');
        queueSelectedTable.classList.add('queueTableTextColor')
    }, [])

	const toSeated = () => {
		//直接入座
        const newSeat = JSON.parse(JSON.stringify(selectedBookingTableData));

		//set uuid
		const eventId = uuidv4();
		setUuid(eventId);

        newSeat.forEach((t) => {
			t['max_seat'] = t.max_attendance;
			t['min_seat'] = t.min_attendance;
			t['table_number'] = t.name;

			delete t['max_attendance'];
			delete t['min_attendance'];
			delete t['name'];
			delete t['canvas_name'];
			delete t['canvas_space_id'];
			delete t['created_at'];
			delete t['height'];
			delete t['nextBooking'];
			delete t['positionX'];
			delete t['positionY'];
			delete t['shop_id'];
			delete t['table_type'];
			delete t['timeline'];
			delete t['updated_at'];
			delete t['width'];
		});

		const dataSubmit = {
			seating_json: JSON.stringify(newSeat),
			booking_datetime: moment(swapBookingInfo.booking_datetime).format('YYYY/MM/DD HH:mm'),
			date: moment(swapBookingInfo.booking_datetime).format('YYYY/MM/DD'),
			event_id: eventId
		};

		swapBooking(swapBookingInfo.id, dataSubmit);
	};

	const swapBooking = (id, dataSubmit) => {
		// console.log('dataSubmit----', dataSubmit);
		// setLoading(true);
		BookingAPI.updateBooking(id, dataSubmit)
			.then(() => {
				window.app.alert.setMessage('修改預約成功', 'done');
				closeSwapMoreSeat();
				updateBooking();

                // 選取桌位最大容納人數少於顧客人數，則提醒
                let selectedTables = []
                let selectedBookingTableMaxSeat = []

                for(let i = 0; i < selectedBookingTableData.length; i++ ) {
                    selectedTables.push(selectedBookingTableData[i].name)
                }

                for(let i = 0; i < selectedBookingTableData.length; i++) {
                    selectedBookingTableMaxSeat.push(selectedBookingTableData[i].max_attendance)
                }

                const seatMaxAttendance = selectedBookingTableMaxSeat.reduce((prev, cur) => prev + cur, 0);
                if(seatMaxAttendance < swapBookingInfo.attendance) {
                    setShowAttendanceOverMaxSeatsModal(selectedTables)
                }

                // 如果選取桌位包含原本的桌位，則不提醒
                for(let i = 0; i < selectedBookingTableData.length; i++) {
                    for(let j = 0; j < swapBookingInfo.seats.length; j++) {
                        if(selectedBookingTableData[i].name === swapBookingInfo.seats[j].name) {
                            const swapOriginalTableIndex = swapOverlayTableData.findIndex((t) => t === swapBookingInfo.seats[j].name);
                            swapOverlayTableData.splice(swapOriginalTableIndex, 1);
                        }
                    }
                }

                setShowSwapOverlayTables(swapOverlayTableData)
			})
			.catch(() => {
				window.app.alert.setMessage('請重新再試一次', 'error');
				closeSwapMoreSeat();
			});
	};

    return(
        <div id="switchSeatPanel" className="SwitchSeatPanel">
			<button className="closeSeatedPanel" onClick={()=> closeSwapMoreSeat()}/>
            <div id="swapConfirmedBtn" className="swapConfirmedBtn" onClick={()=> toSeated()}>確認</div>
        </div>
    )
}

export default SwapMoreSeatPanel;