import React from 'react'
import $ from 'jquery'
//import Dropzone from 'react-dropzone'
//import { Link } from 'react-router'
//import { handleError } from '../../libs/handler'
// import { config } from '../../utils/config'
import BindModal from './ShopFormBindModal'

var createReactClass = require('create-react-class');
export default createReactClass({
  getInitialState() {
    return { query: [], empty: false, shopBindModalVisible: false };
  },
  componentDidUpdate(prevProps){
    if(prevProps.query !== this.props.query){
      this.setState({ query:  this.props.query, empty:  this.props.empty });
    }

  },
  handleSelect(object_id) {
    this.setState({ query: [] });
    this.props.onSelect(object_id);
  },
  handleConfirm() {
    $(this.refs.confirm).modal('show')
  },
  renderQuery() {
    if (this.props.empty) {
      return (
        <div className="shopBind_query_false">
          <div className="tt-menu tt-open">
            <div className="tt-dataset tt-dataset-best-pictures">
              沒有搜尋結果，請重新輸入或自行建立店家
            </div>
          </div>
        </div>
      )
    } else {
      if (this.state.query.length > 0) {
        var queries = this.state.query.map(function(query) {
          return (
            <div className="suggestion tt-suggestion tt-selectable" 
              key={query.objectId}
              onClick={ () => this.handleSelect(query.objectId) }
            >
              <strong>{query.name}</strong> - {query.address} - <b>{'照片數：' + (typeof(query.photoCount)!=='undefined' ? query.photoCount : 0 )}</b>
            </div>
          );
        }.bind(this));
        return (
          <div className="shopBind_query_success">
            <div className="tt-menu tt-open ">
              <div className="tt-dataset tt-dataset-best-pictures">
                { queries }
              </div>
            </div>
          </div>
        )
      }
    }
  },
  render() {
    return (
      <div id="shopBind">
        <div className="shopBind">
          <div className="shopBind_input">
            <input
              id="queryShop"
              type="text" 
              ref='query'
              className="form-control"
              style={{ float: 'left' }}
              placeholder="請輸入店家名稱"
              name="display_name"
              title='query'
            />
            <input
              className='btn btn-success'
              type='submit'
              value='搜尋'
              onClick={ () => this.props.onQuery(this.refs.query.value) }
            />
          </div>
          {this.renderQuery()}
          <br/>
          <a
            href='#'
            onClick={() => this.props.onFormSwitch() }>
            找不到店家？來建立吧！
          </a>
          <BindModal
            select={this.props.select}
            visible={this.props.shopBindModalVisible}
            cancelSelect={this.props.onCancelSelect}
            logo={this.props.data.logo + '?' + (new Date()).getTime()}
            onSync={this.props.onSync}
          />
        </div>
      </div>
    );
  }
});
