import React, { useContext } from 'react';
import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';

const CouponInfoSection = ({ children }) => {
	return <section className="couponInfo">{React.Children.map(children, (child) => child)}</section>;
};

const CouponSectionTitle = () => {
	return <h2>優惠券資訊</h2>;
};

const CouponTitle = () => {
	const { CouponAddState, CouponAddDispatch } = useContext(CouponAddStateContext);
	const { couponSetting, checkSetting, originStatus } = CouponAddState;
	const { title } = couponSetting;

	const { CouponState } = useContext(CouponStateContext);
	const { editType } = CouponState;

	const errorStyle = checkSetting && title === '' ? 'errorStyle' : '';
	//已在進行的優惠券不得編輯
	const disableEdit = editType === 'edit' && (originStatus === 'enable' || originStatus === 'disable');

	const handleTile = (e) => {
		const txt = e.target.value;
		CouponAddDispatch({ type: 'setCouponTitle', txt });
	};

	return (
		<div>
			<p className="title">優惠券標題(字數25字)</p>
			<div className={`${errorStyle}`}>
				<input
					disabled={disableEdit}
					type="text"
					value={title}
					placeholder="優惠券標題"
					maxLength="25"
					onChange={(e) => handleTile(e)}
				/>
				{errorStyle !== '' && <p className="errorTxt">此欄位為必填。</p>}
			</div>
		</div>
	);
};

const CouponContent = () => {
	const { CouponAddState, CouponAddDispatch } = useContext(CouponAddStateContext);
	const { couponSetting, originStatus } = CouponAddState;
	const { item } = couponSetting;

	const { CouponState } = useContext(CouponStateContext);
	const { editType } = CouponState;

	//已在進行的優惠券不得編輯
	const disableEdit = editType === 'edit' && (originStatus === 'enable' || originStatus === 'disable');

	const handleSubTile = (e) => {
		const txt = e.target.value;
		CouponAddDispatch({ type: 'setSubCouponTitle', txt });
	};
	return (
		<div>
			<p className="title">兌換品項優惠內容(字數30字)</p>
			<input
				disabled={disableEdit}
				type="text"
				value={item}
				placeholder="兌換品項優惠內容"
				maxLength="30"
				onChange={(e) => handleSubTile(e)}
			/>
		</div>
	);
};


CouponInfoSection.Title = CouponSectionTitle;
CouponInfoSection.CouponTitle = CouponTitle;
CouponInfoSection.CouponContent = CouponContent;

export default CouponInfoSection;
