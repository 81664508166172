import React from 'react';
import { Modal } from 'antd';

const googleReservationMinBookingDay = 30;

const GoogleReservationRequiredCheckModal = ({
  enable = true,
  verifyRequired = false,
  phoneVerification = false,
  maxBookingTimeDay = googleReservationMinBookingDay,
  areaSetting = false,
  serviceTimeOptionEnabled = false,
  depositRequired = false,
  address = false,
  setGoogleReservationRequiredCheckModal,
  setShowHasNotEnoughPointNotice,
  showHasNotEnoughPointsNotice,
  submit
}) => {
	const cancelPopup = () => {
    if(areaSetting) {
      return setGoogleReservationRequiredCheckModal();
    } else {
      return setGoogleReservationRequiredCheckModal(false);
    }
  };

  const handleSubmit = () => {
    if(showHasNotEnoughPointsNotice) {
      cancelPopup();
      setShowHasNotEnoughPointNotice(true);
    } else {
      submit();
    }
  };

	return (
		<Modal
			title="確定儲存嗎？"
			centered
			visible
			className="bookingSettingPopup__check"
			footer={
				<>
					<button className='btn_cancel' onClick={cancelPopup}>取消</button>
					<button className='btn_save' onClick={handleSubmit}>確認</button>
				</>
			}
			transitionName="ant-move-down"
			maskClosable={false}
		>
			您的預約設定未符合下列Google預訂啟用條件，若變更設定系統將自動關閉Google預訂。確定儲存嗎？
      
      <ul className='error_text' style={{ margin: '12px 0 0' }}>
        {address && <li>店家地址（Google Maps 商家地址）：不得為空白</li>}
        {!enable && <li>線上預約開放狀態：開啟</li>}
        {maxBookingTimeDay < 30 && <li>最早可預約時間：不可小於30天</li>}
        {verifyRequired && <li>審核預約：關閉</li>}
        {phoneVerification && <li>電話認證：關閉</li>}
        {areaSetting && <li>預約組別設定：關閉</li>}
        {serviceTimeOptionEnabled && <li>服務時間多選選項：關閉</li>}
        {depositRequired && <li>預先收款功能：關閉</li>}
      </ul>
		</Modal>
	);
}

export default GoogleReservationRequiredCheckModal