import React from 'react'
import moment from 'moment'

import {
    Link
}

from 'react-router'
var createReactClass=require('create-react-class');

export default createReactClass( {
    render() {
        return (<div className='main-item'> <Link to= {
                {

                pathname: "/dashboard/bookings",
                query: {
                    date: new moment().format("YYYY-MM-DD"),
                        mode: "calendar"
                }
            }
        }

        role='button'> <div className='function-thub'> <img alt='booking'

        src= {
            require("../../images/component/f_reservation.svg")
        }

        /> <div className='caption function-title'> <h3>預約系統</h3> <p>管理顧客預約紀錄與設定</p> </div> </div> </Link> </div>);
    }
}

);