import React from 'react';

export const ConflictWarning = ({ targetTableName, swapTableName, cancelSwap, checkSwap }) => {
	return (
		<div className="ConflictWarningWrap">
			<div className="ConflictWarning">
				<div className="ConflictWarningHeader">
					<i className="fa fa-exclamation-triangle" aria-hidden="true" />桌次預約時段衝突
				</div>
				<div className="ConflictWarningBody">
					{targetTableName} 桌次與 {swapTableName} 桌次有重疊的預約時段，請問是否確認換桌？
				</div>
				<div className="ConflictWarningFooter">
					<button className="cancelBtn" onClick={() => cancelSwap()}>
						取消
					</button>
					<button className="checkBtn" onClick={() => checkSwap()}>
						確定
					</button>
				</div>
			</div>
		</div>
	);
};