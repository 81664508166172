import React, { useEffect } from 'react';
import { Link } from 'react-router';
import ScrollReveal from '../../libs/vendor/scrollreveal.min';
import PageNav from './PageNav';
import PageFooter from './PageFooter';
import PageSlogan from './PageSlogan';

import '../../sass/pageMENU.scss';

import AppleStore from '../../images/app_store.png';
import GooglePlay from '../../images/google_play.png';
import MenuFeature_logo_white from '../../images/page_MENU/MENU_feature_logo_white.png';
import Menu_web_cover from '../../images/page_MENU/MENU_web.gif';
import Menu_news_cover from '../../images/page_MENU/MENU_news_cover.png';

const apple_store_url = 'https://apps.apple.com/tw/app/menu%E7%BE%8E%E9%A3%9F%E8%AA%8C-%E7%BE%8E%E9%A3%9F%E8%A8%98%E9%8C%84-%E4%BD%A0%E6%88%91%E5%88%86%E4%BA%AB/id976520817';
const google_play_url = 'https://play.google.com/store/apps/details?id=com.findlife.menu&hl=zh_TW&gl=US';
const MENUtaiwan_url = 'https://menutaiwan.com/tw';
const MENUblog_url = 'https://findlife.com.tw/menu/blog/';

const PageMENU = () => {
  useEffect(() => {
		window.sr = new ScrollReveal();

    window.sr.reveal('.section_main h1', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000
		});

    window.sr.reveal('.section_main h3', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 300
		});

    window.sr.reveal('.section_main .section_main_logo', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 500
		});

    window.sr.reveal('.section_menu_web h1', {
			distance: '150px',
			origin: 'right',
			duration: 1000,
      viewFactor: 0.7
		});

    window.sr.reveal('.section_menu_web h3', {
			distance: '150px',
			origin: 'right',
			duration: 1000,
      delay: 300,
      viewFactor: 0.7
		});

    window.sr.reveal('.section_menu_web button', {
			distance: '150px',
			origin: 'right',
			duration: 1000,
      delay: 500
		});

    window.sr.reveal('.section_menu_web .menu_web_cover', {
			distance: '150px',
			origin: 'right',
			duration: 1000,
      delay: 300,
      viewFactor: 0.3
		});

    window.sr.reveal('.section_news h1', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      viewFactor: 0.3
		});

    window.sr.reveal('.section_news h3', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 300,
      viewFactor: 0.3
		});

    window.sr.reveal('.section_news button', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 300
		});

    window.sr.reveal('.section_news .news', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 500
		});

    window.sr.reveal('.section_news .menu_news_cover', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 300,
      viewFactor: 0.7
		});
  }, []);

  return (
    <div className='pageMenu'>
      <PageNav />

      <section className='section_main'>
        <div className='section_main_wrap'>
          <div className='title'>
            <h1>MENU美食誌</h1>
            <h3>結合美食APP社群，有效創造口碑</h3>
          </div>
          <div className='section_main_logo'>
            <img className='menu_logo' src={MenuFeature_logo_white} alt="MENUShop X MENU" />
            <div className='download_store'>
              <a href={apple_store_url} target="_blank" rel="noopener noreferrer">
                <img src={AppleStore} alt="AppleStore"/>
              </a>
              <a href={google_play_url} target="_blank" rel="noopener noreferrer">
                <img src={GooglePlay} alt="GooglePlay"/>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='section_menu_web'>
        <div className='content'>
          <h1>好口碑，就是要口耳相傳</h1>
          <h3>超過86萬個美食客用戶在MENU分享，讓美食飄香一傳十十傳百</h3>
          <a href={MENUtaiwan_url} target="_blank" rel="noopener noreferrer">
            <button>前往體驗美食城</button>
          </a>
        </div>
        <img className='menu_web_cover' src={Menu_web_cover} alt="" />
      </section>

      <section className='section_news'>
        <div className='content'>
          <h1>美食部落格深入報導</h1>
          <h3>
            更多美食特輯、好評推薦<br />
            外站媒體連動報導增加曝光度
          </h3>
          <a href={MENUblog_url} target="_blank" rel="noopener noreferrer">
            <button>MENU美食部落格</button>
          </a>
          <div className='news'>
            合作媒體平台：Yahoo、Line Today、自由時報、<br />
            東森新聞、聯合新聞、風傳媒...
          </div>
        </div>
        <div className='menu_news_cover'>
          <img src={Menu_news_cover} alt="MENU News" />
        </div>
      </section>

      <PageSlogan />
      <PageFooter />
    </div>
  )
}

export default PageMENU;