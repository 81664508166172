import React, { useState } from 'react';
import _ from 'lodash';
import { Modal } from 'antd';

import Table from './Table';
import TableInput from './TableInput';

const BookingSettingSeatsModal = ({ showModal, handleOk, handleCancel, groupSetting, groups }) => {
	const [ groupName, setGroupName ] = useState(groupSetting.group);
	const [ min, setMin ] = useState(groupSetting.min_seat);
	const [ max, setMax ] = useState(groupSetting.max_seat);
	const [ tables, setTables ] = useState(groupSetting.table_objs);
	const [ onlineTable, setOnlineTable ] = useState(groupSetting.open_qty);
	const [ openOnline, setOpenOnline ] = useState(groupSetting.combinable);
	const [ check, setCheck ] = useState(false);

	const decreaseMin = () => {
		setMin((old) => old - 1);
	};

	const increaseMin = () => {
		setMin((old) => old + 1);
	};

	const decreaseMax = () => {
		setMax((old) => old - 1);
	};

	const increaseMax = () => {
		setMax((old) => old + 1);
	};

	const addNewTable = () => {
		const newTables = _.cloneDeep(tables);

		newTables.push({
			table_number: '',
			idx: null,
			available: true
		});

		setTables(newTables);
	};

	const editTable = (newTableName, index) => {
		const newTables = _.cloneDeep(tables);
		newTables[index].table_number = newTableName.trim();
		setTables(newTables);
	};

	const removeTable = (index) => {
		const newTables = _.cloneDeep(tables);
		newTables[index].available = false;
		setTables(newTables);
	};

	const addTable = (index, tableName) => {
		const newTables = _.cloneDeep(tables);
		newTables.splice(index, 1, { table_number: tableName.trim(), idx: null, available: true });

		const $tablesWrap = document.getElementsByClassName('tablesWrap')[0]

		for(let i = index+1; i< $tablesWrap.childNodes.length; i++){
			if($tablesWrap.childNodes[i].classList.contains('tableInput')){
				$tablesWrap.childNodes[i].childNodes[0].childNodes[0].focus()
				break;
			}
		}

		setTables(newTables);
	};

	const removeNewTable = (index) => {
		const newTables = _.cloneDeep(tables);
		newTables.splice(index, 1);
		setTables(newTables);
	};

	const renderTable = () => {
		return tables.map((table, index) => {
			if (!table.available) return null;

			if (table.table_number === '')
				return (
					<TableInput
						key={index}
						groups={groups}
						tables={tables}
						addTable={(tableName) => addTable(index, tableName)}
						removeAddTable={() => removeNewTable(index)}
					/>
				);

			return (
				<Table
					key={index}
					groups={groups}
					table={table}
					tables={tables}
					editTable={(newTableName) => editTable(newTableName, index)}
					removeTable={() => removeTable(index)}
				/>
			);
		});
	};

	const handlegroupName = (e) => {
		const txt = e.target.value;
		setGroupName(txt);
	};

	const handleOnlineTable = (e) => {
		const txt = e.target.value;

		setOnlineTable(txt.trim());
		setCheck(false);
	};

	const submit = () => {
		setCheck(true);
		const actureTable = tables.filter((table) => table.available === true);
		const reg = /^\d+$/;

		const $newTableInput = document.getElementsByClassName('newTableInput')
		const $tableInput = document.getElementsByClassName('tableInput')


		if($newTableInput.length !== 0 || $tableInput.length !== 0) return window.app.alert.setMessage('請輸入桌次', 'error');
		if (groupName === '') return window.app.alert.setMessage('請填寫組別名稱', 'error');
		if (min > max) return window.app.alert.setMessage('最少可容納人數不可大於最多可容納人數', 'error');
		if (actureTable.length === 0) return window.app.alert.setMessage('請設定桌子', 'error');
		if (!reg.test(onlineTable.toString().trim()) || onlineTable.toString().trim() === '')
			return window.app.alert.setMessage('請輸入開放線上預約桌數', 'error');
		if (onlineTable > actureTable.length) return window.app.alert.setMessage('開放線上預約桌數不可多於總桌數', 'error');

		let newSetting = _.cloneDeep(groupSetting);

		newSetting = {
			...newSetting,
			combinable: openOnline,
			group: groupName,
			max_seat: max,
			min_seat: min,
			open_qty: onlineTable,
			table_objs: tables,
			quantity: tables.filter((table) => table.available === true).length
		};

		handleOk(newSetting);
	};

	const errorStyle = check && onlineTable > tables.length ? 'errorStyle' : '';

	return (
		<Modal
			wrapClassName="bookingSettingSeatsModal"
			destroyOnClose={true}
			title={<span className="bookingSettingSeatsModalTitle">新增組別</span>}
			visible={showModal}
			maskClosable={false}
			onOk={handleOk}
			onCancel={handleCancel}
			zIndex={1035}
			width={600}
			bodyStyle={{
				backgroundColor: '#f6f6f6'
			}}
			footer={[
				<button key="cancel" className="seatsModalBtn seatsModalBtn-cancel" onClick={() => handleCancel()}>
					取消
				</button>,
				<button key="submit" className="seatsModalBtn seatsModalBtn-submit" onClick={() => submit()}>
					確定
				</button>
			]}
		>
			<div className="bookingSettingSeatsModal__body">
				<section>
					<p className="sectionTitle">組別名稱</p>
					<div>
						<input
							type="text"
							value={groupName}
							placeholder="請輸入組別名稱"
							onChange={(e) => handlegroupName(e)}
						/>
					</div>
				</section>

				<section>
					<p className="sectionTitle">
						單一桌次可容納人數<span>
							{min}-{max}
						</span>人
					</p>

					<div className="limitRowWrap">
						<div className="limitRow">
							<span>最少可容納人數</span>
							<div>
								<button
									className="minus"
									disabled={min <= 1 ? true : false}
									onClick={() => decreaseMin()}
								>
									<i className="fa fa-minus" aria-hidden="true" />
								</button>
								<span className="count">{min}</span>
								<button className="plus" onClick={() => increaseMin()}>
									<i className="fa fa-plus" aria-hidden="true" />
								</button>
							</div>
						</div>
						<div className="limitRow">
							<span>最多可容納人數</span>
							<div>
								<button
									className="minus"
									disabled={max <= 1 ? true : false}
									onClick={() => decreaseMax()}
								>
									<i className="fa fa-minus" aria-hidden="true" />
								</button>
								<span className="count">{max}</span>
								<button className="plus" onClick={() => increaseMax()}>
									<i className="fa fa-plus" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
				</section>

				<section>
					<p className="sectionTitle">
						桌數<span>{tables.filter((table) => table.available === true).length}</span>
					</p>

					<div className="tablesWrap">
						{renderTable()}
						<div className="tableInfo">
							<button className="addTableBtn" onClick={() => addNewTable()}>
								<i className="fa fa-plus" aria-hidden="true" />新增桌次
							</button>
						</div>
					</div>
				</section>

				<section>
					<p className="sectionTitle">開放線上預約桌數</p>

					<div className={`checkOpenOnline ${errorStyle} `}>
						<input
							type="tel"
							placeholder="選擇開放線上預約桌數"
							value={onlineTable}
							onChange={(e) => handleOnlineTable(e)}
						/>
						{errorStyle !== '' && <p className="errTxt">開放線上預約桌數不可多於總桌數。</p>}
					</div>

					<div className="openOnline">
						<input
							id="openOnline"
							type="checkbox"
							checked={openOnline}
							onChange={() => setOpenOnline((old) => !old)}
						/>
						<label htmlFor="openOnline">開放線上預約併桌</label>
					</div>
				</section>
			</div>
		</Modal>
	);
};

export default BookingSettingSeatsModal;
