import React, { Component } from 'react';
// import moment from 'moment'
// import QueuePanelAPI from './api/QueuePanelAPI';
import QueueListItem from './QueueListItem';

export default class QueueSearchResult extends Component {

    
    refreshAction = (item) => {
        this.props.reSearchAction()
        this.props.refreshAction()
    }

    render() {

        let recordContents = []
        let count = 0
        if (this.props.records !== undefined) {
            this.props.records.forEach(item => {
                // console.log(item)

                if (item.status === "waiting") {

                    recordContents.push((<QueueListItem data={item} 
                        searching = {true}
                        hideAction = {false}
                        hideSeatedAction = {false}
                        hideStandbyAction = {false}
                        timeCheckingCondition = {this.props.timeCheckingCondition}
                        notificationAction={this.props.notificationAction}
                        refreshAction={() => {this.refreshAction(item)}} />))
                } else if (item.status === "seated") {
                    recordContents.push((<QueueListItem data={item} 
                        searching = {true}
                        hideAction = {true}
                        hideSeatedAction = {true}
                        hideStandbyAction = {true}
                        timeCheckingCondition = {this.props.timeCheckingCondition}
                        notificationAction={this.props.notificationAction}
                        refreshAction={() => {this.refreshAction(item)}} />))
                } else if (item.status === "standby") {
                    recordContents.push((<QueueListItem data={item} 
                        searching = {true}
                        hideAction = {false}
                        hideSeatedAction = {false}
                        hideStandbyAction = {true}
                        timeCheckingCondition = {this.props.timeCheckingCondition}
                        notificationAction={this.props.notificationAction}
                        refreshAction={() => {this.refreshAction(item)}} />))
                } else if (item.status === "canceled") {
                    recordContents.push((<QueueListItem data={item} 
                        searching = {true}
                        hideAction = {true}
                        hideSeatedAction = {true}
                        hideStandbyAction = {true}
                        timeCheckingCondition = {this.props.timeCheckingCondition}
                        notificationAction={this.props.notificationAction}
                        refreshAction={() => {this.refreshAction(item)}} />))
                }
            });
            count = this.props.records.length
        }

        return (
            <div className="queue-search-result">
                <div className="search-title-container">
                    <h3 className="search-result-title">搜尋結果</h3>
                    <button className="search-result-dismiss-button" onClick={this.props.clearSearchResult}>
                        <img src={require("../../images/queue/icon_dismiss.png")} alt="" />
                    </button>

                </div>
                <div>
                    <p className="search-result-subtitle">搜尋：{this.props.keyword !== undefined ? this.props.keyword : ""} (共{count}組)</p>
                    {/* <div>
                        排序
                    </div> */}
                    {recordContents}
                </div>


            </div>
        )
    }   
}

