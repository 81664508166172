import React from 'react';
import moment from 'moment';

const statusTxt = {
	unconfirmed: '待確認',
	confirmed: '確認預約',
	cancel: '取消預約',
	show: '已到店',
	no_show: '未到店',
	seated: '已入座',
	finish: '完成消費'
};

const genderTxt = {
	male: '先生',
	female: '小姐'
};

export default ({ booking, toList }) => {
	return (
		<div className="bookingCard">
			<p>{moment(booking.booking_datetime).format('YYYY/MM/DD')}</p>
			<div
				className="bookingCardContent"
				onClick={() => toList(moment(booking.booking_datetime).format('YYYY-MM-DD'), booking)}
			>
				<p className="time">{moment(booking.booking_datetime).format('HH:mm')}</p>
				<div className="infoFlex">
					<div className="infoLeft">
						<h2>
							{booking.last_name}
							<span>{booking.gender ? genderTxt[booking.gender] : null}</span>
						</h2>
						<div className="attendance">
							<span>{booking.attendance}</span>
							<span>位</span>
						</div>
					</div>
					<div className="infoRight">
						{JSON.parse(booking.seating_json).map((seat, index) => (
							<label key={index} className="seat">
								{seat.table_number}
							</label>
						))}
					</div>
				</div>
				<div className="bottom">
					<div>{booking.phone_number}</div>
					<div className="extra">
						<div className={`status status-${booking.status}`}>●<span>{statusTxt[booking.status]}</span></div>
						<div className="code">查詢代碼：{booking.active_code}</div>
					</div>
				</div>
			</div>
		</div>
	);
};
