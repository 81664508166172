import React, { useContext, useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';
import { LoadingOutlined } from '@ant-design/icons';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import '../../../sass/menu_coupon_popup.scss';

import MenuCouponApi from '../MenuCouponApi.js';

const menuCouponAPI = new MenuCouponApi();

const ScanPopup = ({ couponLists }) => {
  const [ validCoupon, setValidCoupon ] = useState(null); // coupon list 中對應到掃描的 coupon
  const [ scanCoupon, setScanCoupon ] = useState(null); // 掃描到的 coupon
  const [ exchangeStatus, setExchangeStatus ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ isOnline, setIsOnline ] = useState(window.navigator.onLine);
  const { CouponDispatch, getCouponList } = useContext(CouponStateContext);

  // scan range style
  useEffect(() => {
    const scanBox = document.getElementsByClassName('scanBox')[0];
    const scanText = document.getElementsByClassName('scanText')[0];
    
    if(validCoupon !== null) {
      scanBox.style.display = "none";
      scanText.style.display = "none";
    } else {
      scanBox.style.display = "block";
      scanText.style.display = "block";
    }
  }, [ validCoupon ]);

  // exchanged message
  useEffect(() => {
    const exchangedSuccess = document.getElementById("scanCoupon_exchangedSuccess");
    const exchangedError = document.getElementById("scanCoupon_exchangedError");
    const exchangeErrorMsg = document.getElementsByClassName('exchangeErrorMessage')[0];

    // 開啟時先清除訊息以免重疊
    if(exchangeErrorMsg) {
      exchangedError.removeChild(exchangeErrorMsg);
    };

    if(exchangedSuccess.style.display = "flex") {
      exchangedSuccess.style.display = "none";
    };

    if(exchangeStatus === '優惠券核銷成功') {
      exchangedSuccess.style.display = "flex";
      setTimeout(() => {
        exchangedSuccess.style.display = "none";
      }, 3000);
    } else if (exchangeStatus !== '') {
      const insertDom = `<span class="exchangeErrorMessage">${ exchangeStatus }</span>`
      exchangedError.insertAdjacentHTML('beforeend', insertDom);
      exchangedError.style.display = "flex";

      setTimeout(() => {  
        exchangedError.style.display = "none";
      }, 3000);
    }
  }, [ exchangeStatus ]);

  // 網路是否連線
  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);

    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  });

  const updateNetwork = () => {
    setIsOnline(window.navigator.onLine);
  };

  const handleScan = (data) => {
    if (data) {
      const result = JSON.parse(data);
      const menuUserId = result.menuUserId;
      const checkCoupon = result.shopId + '_' + result.voucherId;
      const couponIndex = couponLists.map((list) => {
        return list.shopUserId + '_' + list.id
      }).indexOf(checkCoupon);

      if(menuUserId !== '' && couponIndex !== -1) {
        setScanCoupon(result);
        setValidCoupon(couponLists[couponIndex]);
      } else {
        setValidCoupon(null);
      }
    }
  }

  const handleError = (err) => {
    const scanCouponError = document.getElementById("scanCoupon_noCamera");
    
    scanCouponError.style.display = "flex";
    setTimeout(() => {
      scanCouponError.style.display = "none";
    }, 3000);

    console.log('scan coupon err-----', err);
    CouponDispatch({ type: 'cancelPopup' });
  }

  const submit = async () => {
    setLoading(true);

    const couponInfo = {
      shopId: scanCoupon.shopId,
      menuUserId: scanCoupon.menuUserId,
      voucherId: scanCoupon.voucherId
    };

    try {
      const exchangeCoupon_res = await menuCouponAPI.exchangeCoupon(couponInfo);

      setLoading(false);
      setExchangeStatus(exchangeCoupon_res.message);
      getCouponList();
      CouponDispatch({ type: 'cancelPopup' });
    } catch(err) {
      setLoading(false);
      setExchangeStatus(err.responseJSON.message);
      CouponDispatch({ type: 'cancelPopup' });
      console.log('exchange coupon err-----', err);
    }
  }

  const renderCouponInfo = () => {
    const renderCouponCardImg = () => {
      if (validCoupon.thumbnailURL !== '') {
        return (
          <div
            className="scanCardImg defaultImg"
            style={{ backgroundImage: `url(${validCoupon.thumbnailURL})` }}
          />
        );
      }
  
      return <div className="scanCardImg defaultImg" />;
    }

    return (
      <div className="scanCouponInfo">
        <div>
          <h2>核銷確認</h2>
          <h3>經過核銷便無法回復，確定核銷該張優惠券嗎？</h3>
          <div className="scanCouponInfo_card">
            { renderCouponCardImg() }
            <div>
              <h2>{ validCoupon.title }</h2>
              <h3>{ validCoupon.item }</h3>
            </div>
          </div>
        </div>
        <div className="scanCoupon_btn">
          <button
            className="scanCoupon_btn_cancel"
            onClick={() => setValidCoupon(null)}
          >
            取消
          </button>
          <button
            className="scanCoupon_btn_sure"
            onClick={submit}
          >
            確定
          </button>
        </div>
      </div>
    )
  }

	return (
		<div className="scanPopupBody">
      { validCoupon === null ?       
        <QrReader
          className="scanner"
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        /> : renderCouponInfo()
      }

      { loading ? 				
        <div className="scan_loading">
          <div>
            <LoadingOutlined style={{ fontSize: 30 }} />
            <p>請稍候</p>
          </div>
				</div> : null
      }

      <div className="scanBox"></div>
      <div className="scanText">將鏡頭對準顧客裝置上的核銷碼</div>

      { !isOnline ?  <div className="scanCoupon_error_connect">發生錯誤，請重新嘗試。</div> : null }
		</div>
	);
};

export default ScanPopup;
