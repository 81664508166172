import moment from 'moment';
import _ from 'lodash';

import { seatedConflictTable } from '../utils/conflictTable';

export const countOverlayTable = (
	timeline,
	currentTimestamp,
	setCurrentBooking,
	setNextBooking,
	setDoubleBookingCount
) => {
	let tempCurrent = [];
	let tempNext = [];
	let inOneHourBooking = [];
	let inOneHourBookingCount = 0;
	const oneMoreHour = moment(currentTimestamp).add(1, 'h');

	// 當日無預約
	if (timeline.length === 0) {
		tempCurrent = [];
		tempNext = [];
		inOneHourBooking = [];
	}

	if (timeline.length !== 0) {
		const firstBookingTimestamp = moment(timeline[0].service_start_time);
		const lastBookingDiningTime = JSON.parse(timeline[timeline.length - 1].dining_time);
		const lastBookingTimeEnd = moment(timeline[timeline.length - 1].service_start_time).add(
			lastBookingDiningTime,
			'm'
		);

		// 時間未到達第一筆預約
		if (currentTimestamp < firstBookingTimestamp) {
			timeline.forEach((booking) => {
				const bookingTimestamp = moment(booking.service_start_time);

				if (bookingTimestamp > currentTimestamp && bookingTimestamp < oneMoreHour - 1) {
					inOneHourBooking.push(booking);
				}
			});

			// 1小時內的預約
			// let countCurrent=[]
			if (inOneHourBooking.length !== 0) {
				for (let i = 0; i <= inOneHourBooking.length; i++) {
					for (let j = i + 1; j <= inOneHourBooking.length; j++) {
						const conflict = seatedConflictTable({
							targetBooking: inOneHourBooking[i],
							seatedBooking: inOneHourBooking[j] ? inOneHourBooking[j] : {}
						});
						if (conflict) {
							inOneHourBookingCount = inOneHourBookingCount + 1;
							tempCurrent.push(inOneHourBooking[j]);
						} else break;
					}

					if (inOneHourBookingCount !== 0) {
						inOneHourBookingCount++;
						tempCurrent.unshift(inOneHourBooking[i]);
						break;
					}
				}
			}

			if (tempCurrent.length !== 0) {
				tempNext = [];
			} else {
				tempNext.push(timeline[0]);
			}
		}

		// 時間到達第一筆預約
		if (currentTimestamp >= firstBookingTimestamp) {
			const foundInSomeBooking = timeline.find((booking) => {
				const bookingTimestamp = moment(booking.service_start_time);
				const bookingEndTimestamp = moment(bookingTimestamp).add(booking.dining_time, 'm');

				return bookingTimestamp <= currentTimestamp && currentTimestamp <= bookingEndTimestamp - 1;
			});

			console.log('timeline----', timeline);

			// 時間到達某筆預約
			if (foundInSomeBooking !== undefined) {
				timeline.forEach((booking) => {
					const bookingTimestamp = moment(booking.service_start_time);
					const bookingEndTimestamp = moment(bookingTimestamp).add(booking.dining_time, 'm');

					if (currentTimestamp >= bookingTimestamp && currentTimestamp <= bookingEndTimestamp - 1) {
						// console.log('---現在時間 在 某筆預約時間內---');
						inOneHourBooking.push(booking);
						tempCurrent.push(booking);
					} else if (currentTimestamp <= bookingTimestamp && currentTimestamp <= bookingEndTimestamp - 1) {
						// console.log('---現在時間 已過上一筆預約 且未到 下一筆預約時間---', booking);
						tempNext.push(booking);
					}

					if (bookingTimestamp > currentTimestamp && bookingTimestamp < oneMoreHour - 1) {
						inOneHourBooking.push(booking);
					}
				});

				// 1小時內的預約
				let lastOverlayBookingId = null;
				if (inOneHourBooking.length !== 0) {
					for (let i = 0; i <= inOneHourBooking.length; i++) {
						for (let j = i + 1; j <= inOneHourBooking.length; j++) {
							const conflict = seatedConflictTable({
								targetBooking: inOneHourBooking[i],
								seatedBooking: inOneHourBooking[j] ? inOneHourBooking[j] : {}
							});
							if (conflict) {
								inOneHourBookingCount = inOneHourBookingCount + 1;
								tempCurrent.push(inOneHourBooking[j]);
								lastOverlayBookingId = inOneHourBooking[j].id;
							} else break;
						}

						if (inOneHourBookingCount !== 0) {
							inOneHourBookingCount++;
							tempCurrent.splice(i, 0, inOneHourBooking[i]);
							break;
						}
					}
				}

				if (inOneHourBookingCount !== 0) {
					tempNext = [];
				}

				if (lastOverlayBookingId !== null) {
					const lastOverlayBookingIndex = timeline.findIndex(
						(booking) => booking.id === lastOverlayBookingId
					);

					if (lastOverlayBookingIndex !== -1) {
						const nextBooking = timeline[lastOverlayBookingIndex + 1]
							? timeline[lastOverlayBookingIndex + 1]
							: null;

						if (nextBooking !== null) {
							tempNext = [];
							tempNext.push(nextBooking);
						}
					}
				}
			}

			// 時間不在某筆預約內
			if (foundInSomeBooking === undefined) {
				timeline.forEach((booking) => {
					const bookingTimestamp = moment(booking.service_start_time);
					const bookingEndTimestamp = moment(bookingTimestamp).add(booking.dining_time, 'm');
					if (currentTimestamp <= bookingTimestamp && currentTimestamp <= bookingEndTimestamp - 1) {
						tempNext.push(booking);
					}

					if (bookingTimestamp > currentTimestamp && bookingTimestamp < oneMoreHour - 1) {
						inOneHourBooking.push(booking);
					}
				});

				// 1小時內的預約
				let lastOverlayBookingId = null;
				if (inOneHourBooking.length !== 0) {
					for (let i = 0; i <= inOneHourBooking.length; i++) {
						for (let j = i + 1; j <= inOneHourBooking.length; j++) {
							const conflict = seatedConflictTable({
								targetBooking: inOneHourBooking[i],
								seatedBooking: inOneHourBooking[j] ? inOneHourBooking[j] : {}
							});
							if (conflict) {
								inOneHourBookingCount = inOneHourBookingCount + 1;
								tempCurrent.push(inOneHourBooking[j]);
								lastOverlayBookingId = inOneHourBooking[j].id;
							} else break;
						}

						if (inOneHourBookingCount !== 0) {
							inOneHourBookingCount++;
							tempCurrent.unshift(inOneHourBooking[i]);
							break;
						}
					}

					if (inOneHourBookingCount !== 0) tempNext = [];
				}

				if (lastOverlayBookingId !== null) {
					const lastOverlayBookingIndex = timeline.findIndex(
						(booking) => booking.id === lastOverlayBookingId
					);

					if (lastOverlayBookingIndex !== -1) {
						const nextBooking = timeline[lastOverlayBookingIndex + 1]
							? timeline[lastOverlayBookingIndex + 1]
							: null;

						if (nextBooking !== null) {
							tempNext = [];
							tempNext.push(nextBooking);
						}
					}
				}
			}
		}

		//現在時間已過最後一筆預約時間, 已無下一筆預約
		if (currentTimestamp > lastBookingTimeEnd - 1) {
			tempCurrent = [];
			tempNext = [];
			inOneHourBooking = [];
			inOneHourBookingCount = 0;
		}
	}

	tempCurrent = _.unionBy(tempCurrent, 'id').filter(Boolean);
	tempNext = _.unionBy(tempNext, 'id').filter(Boolean);

	setCurrentBooking(tempCurrent);
	setNextBooking(tempNext[0] ? [ tempNext[0] ] : []);
	setDoubleBookingCount(inOneHourBookingCount);
};


