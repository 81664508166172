import moment from 'moment';
import { useSelector } from 'react-redux';


export default (timeline) => {
	const SeatCondition = useSelector((state) => state.NewAddBooking_SeatCondition);
	const { bookingTimeRes } = SeatCondition;
	const currentTimestamp = moment(bookingTimeRes, 'YYYY/MM/DD HH:mm');

	let tempCurrent = [];
	let tempNext = [];

	if (timeline.length === 0) {
		tempCurrent = [];
		tempNext = [];
	}

	if (timeline.length !== 0) {
		const firstBookingTimestamp = moment(timeline[0].service_start_time);
		const lastBookingDiningTime = JSON.parse(timeline[timeline.length - 1].dining_time);
		const lastBookingTimeEnd = moment(timeline[timeline.length - 1].service_start_time).add(
			lastBookingDiningTime,
			'm'
		);

		if (currentTimestamp < firstBookingTimestamp) {
			tempCurrent = [];
			tempNext.push(timeline[0]);
		}

		if (currentTimestamp >= firstBookingTimestamp) {
			const foundInSomeBooking = timeline.find((booking) => {
				const bookingTimestamp = moment(booking.service_start_time);
				const bookingEndTimestamp = moment(bookingTimestamp).add(booking.dining_time, 'm');

				return bookingTimestamp <= currentTimestamp && currentTimestamp <= bookingEndTimestamp - 1;
			});

			if (foundInSomeBooking !== undefined) {
				// console.log('----到第一筆-----');
				timeline.forEach((booking, index) => {
					const bookingTimestamp = moment(booking.service_start_time);
					const bookingEndTimestamp = moment(bookingTimestamp).add(booking.dining_time, 'm');

					if (bookingTimestamp <= currentTimestamp && currentTimestamp <= bookingEndTimestamp - 1) {
						// console.log('---現在時間 在 某筆預約時間內---');

						tempCurrent.push(booking);
					} else if (currentTimestamp <= bookingTimestamp && currentTimestamp <= bookingEndTimestamp - 1) {
						// console.log('---現在時間 已過上一筆預約 且未到 下一筆預約時間---', booking);
						tempNext.push(booking);
					}
				});
			}

			if (foundInSomeBooking === undefined) {
				timeline.forEach((booking) => {
					const bookingTimestamp = moment(booking.service_start_time);
					const bookingEndTimestamp = moment(bookingTimestamp).add(booking.dining_time, 'm');
					if (currentTimestamp <= bookingTimestamp && currentTimestamp <= bookingEndTimestamp - 1) {
						// console.log('---現在時間 已過上一筆預約 且未到 下一筆預約時間---', booking);

						tempCurrent = [];
						tempNext.push(booking);
					}
				});
			}
		}

		if (currentTimestamp > lastBookingTimeEnd - 1) {
			//現在時間已過最後一筆預約時間, 已無下一筆預約

			tempCurrent = [];
			tempNext = [];
		}
	}

	// setCurrentBooking(tempCurrent.filter(Boolean));
	// setNextBooking(tempNext.filter(Boolean).slice(0, 1));

	return {
		currentBooking: tempCurrent.filter(Boolean),
		nextBooking: tempNext.filter(Boolean).slice(0, 1)
	}
};
