import React, { Fragment, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { CouponStateContext } from './MenuCouponContext/MenuCouponContext';
import { CouponAddStateContext } from './MenuCouponContext/MenuCouponAddContext';
import MenuCouponApi from './MenuCouponApi';

const menuCouponAPI = new MenuCouponApi();

const couponEditList = {
	enable: [
		{ title: '編輯', action: 'edit' },
		{ title: '預覽', action: 'preview' },
		{ title: '修改密碼', action: 'password' },
		{ title: '暫停', action: 'disable' },
		{ title: '分享', action: 'share' },
		{ title: '匯出', action: 'export' }
	],
	draft: [ { title: '編輯', action: 'edit' }, { title: '預覽', action: 'preview' }, { title: '刪除', action: 'delete' } ],
	standby: [
		{ title: '編輯', action: 'edit' },
		{ title: '預覽', action: 'preview' },
		{ title: '修改密碼', action: 'password' }
	],
	disable: [
		{ title: '編輯', action: 'edit' },
		{ title: '預覽', action: 'preview' },
		{ title: '修改密碼', action: 'password' },
		{ title: '取消暫停', action: 'cancel' },
		{ title: '匯出', action: 'export' }
	],
	expired: [ { title: '預覽', action: 'preview' }, { title: '匯出', action: 'export' } ]
};

const MenuCouponListCard = ({ children }) => {
	return <div className="menuCouponListCard">{React.Children.map(children, (child) => child)}</div>;
};

const MenuCouponListCardImg = ({ status, thumbnailURL }) => {
	let notActiveCard = '';

	if (status === 'draft' && status === 'disable') {
		notActiveCard = 'notActiveCard';
	}

	const renderCouponStatus = () => {
		if (status) return <label className={`couponStatus_${status}`} />;
	};
	return (
		<div className="menuCouponListCard__img">
			{renderCouponStatus()}
			<figure className={`${notActiveCard}`}>
				{thumbnailURL !== '' && <img src={thumbnailURL} alt="優惠券封面" />}
			</figure>
		</div>
	);
};

const MenuCouponListCardContent = ({ showType = 'listType', list }) => {
	const { CouponState, CouponDispatch } = useContext(CouponStateContext);
	const { couponLists } = CouponState;

	const { CouponAddDispatch } = useContext(CouponAddStateContext);

	const {
		id,
		status,
		title,
		subTitle,
		endDateTime,
		exchangedCount,
		sentCount,
		voucherCount,
		lastEditDateTime,
		publishDateTime,
		countLimited
	} = list;
	const lastEditTimeFormat = moment(new Date(lastEditDateTime)).format('YYYY-MM-DD HH:mm');
	let notActiveCard = '';
	let endCouponStyle = '';

	if (status === 'draft' && status === 'disable') {
		notActiveCard = 'notActiveCard';
	}

	if (status === 'expired') endCouponStyle = 'endCoupon';

	const newPublishTime =
		publishDateTime === null ? '未設置' : moment(new Date(publishDateTime)).format('YYYY-MM-DD HH:mm');
	const newEndDateTime = endDateTime === null ? '未設置' : moment(new Date(endDateTime)).format('YYYY-MM-DD HH:mm');

	const handleEditor = (item) => {
		if (item === 'edit') {
			CouponDispatch({ type: 'setEditorType', editType: item, editId: id });
			const findCoupondIndex = couponLists.map((list) => list.id).indexOf(id);
			const couponSettingState = _.cloneDeep(couponLists[findCoupondIndex]);
			CouponAddDispatch({ type: 'setEditorSetting', couponSettingState });
		}


		if (item === 'delete') {
			CouponDispatch({ type: 'setDeleteCoupon', title, id });
		}

		if (item === 'disable') {
			CouponDispatch({ type: 'setPauseCoupon', title, id });
		}

		if (item === 'password') {
			CouponDispatch({ type: 'setPasswordCoupon', title, id });
		}

		if (item === 'cancel') {
			CouponDispatch({ type: 'setCancelPause', title, id });
		}

		if (item === 'preview') {
			const previewIndex = couponLists.map((list) => list.id).indexOf(id);

			CouponDispatch({ type: 'setPreview', previewInfo: couponLists[previewIndex] });
		}

		if (item === 'share') {
			const shareIndex = couponLists.map((list) => list.id).indexOf(id);
			CouponDispatch({ type: 'showSharepopup', listInfo: couponLists[shareIndex] });
		}

		if (item === 'export') {
			const url = 'https://voucher.menushop.tw/voucherReport/'+id;
			const link = document.createElement('a');
			link.href = url;
			document.body.appendChild(link);
			link.click();
		};
		
	};

	const renderEditList = () => {
		return couponEditList[status].map((item) => {
			return (
				<li key={item.action} onClick={() => handleEditor(item.action)}>
					{item.title}
				</li>
			);
		});
	};

	const renderVoucherCount = () => {
		if (countLimited === 1) {
			return (
				<label className={`supplied  ${endCouponStyle}`}>
					<span>
						提供<strong>{voucherCount}</strong>張
					</span>
				</label>
			);
		}

		if (countLimited === 0) {
			return (
				<label className={`supplied  ${endCouponStyle}`}>
					<span>無限制張數</span>
				</label>
			);
		}
	};

	return (
		<div className={`menuCouponListCard__content ${notActiveCard}`}>
			<section className="contentTitle">
				<div>
					<h2>{title}</h2>
					<p>{subTitle}</p>
				</div>
				{showType === 'listType' && (
					<div className="listCardDropDown">
						<button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
						<div className="dropdown-menu dropdown-menu-right">
							<ul>{renderEditList()}</ul>
						</div>
					</div>
				)}
			</section>

			<section className="contentTime">
				<p className="publishTime">
					<span className="title">發布時間</span>
					{newPublishTime}
				</p>
				<p className="exchangeTime">
					<span className="title">兌換期限</span>
					{newEndDateTime}
				</p>
			</section>

			{showType === 'listType' && (
				<Fragment>
					<section className={`contentCount`}>
						<label className={`exchanged  ${endCouponStyle}`}>
							<span>
								已兌換<strong>{exchangedCount}</strong>份
							</span>
						</label>
						{renderVoucherCount()}

						<label className={`received  ${endCouponStyle}`}>
							<span>
								已領取<strong>{sentCount}</strong>份
							</span>
						</label>
					</section>

					<p className="contentUpdateTime">更新時間: {lastEditTimeFormat}</p>
				</Fragment>
			)}
		</div>
	);
};

MenuCouponListCard.Img = MenuCouponListCardImg;
MenuCouponListCard.Content = MenuCouponListCardContent;

export default MenuCouponListCard;
