import React, { useMemo } from 'react';

const weeks = [ '星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六' ];

const AreaBlock = ({ children }) => {
	return <div className="areaBlock">{React.Children.map(children, (child) => child)}</div>;
};

const AreaBlockHeader = ({ group, groupName, index, showWarning }) => {
	return (
		<div className="areaBlock__title">
			<h2>
				{group.name}
				<span>({groupName.length}組)</span>
			</h2>
			<div className="areaBlock__title__buttons">
				<button className="areaDelButton" onClick={() => showWarning('delete', index)}>
					<span>刪除</span>
				</button>
				<button className="areaEditButton" onClick={() => showWarning('edit', index)}>
					<span>修改</span>
				</button>
			</div>
		</div>
	);
};

const AreaBlockBody = ({ groupName, bookingTimeSetting, payment = {}, serviceTimeOptionEnabled }) => {
	const showTimeSetting = useMemo(
		() => {
			return bookingTimeSetting.length !== 0;
		},
		[ bookingTimeSetting ]
	);

	const rednerServiceTime = () => {
		return bookingTimeSetting.map((setting, index) => {
			// if (Object.keys(setting.space_group_settings).length === 0) return null;
			return (
				<AreaBlockServiceRow key={index}>
					<AreaBlockServiceRow.Title index={index} />
					<AreaBlockServiceRow.ServiceTime settingAns={setting} />
					<AreaBlockServiceRow.DiningTime
						fixedDiningTime={setting.dining_time}
						diningTimeOptions={setting.service_time_setting[0]}serviceTimeOptionEnabled={serviceTimeOptionEnabled}
					/>
				</AreaBlockServiceRow>
			);
		});
	};

	const renderPayment = () => {
		if (!payment.active) return null;

		if (payment.enable === 1) {
			return (
				<section>
					<p>付款金額</p>
					<p>
						{payment.setting1.deposit_people_required}以上(含)，每人{payment.setting1.deposit} 元。
					</p>
				</section>
			);
		}

		if (payment.enable === 2) {
			return (
				<section>
					<p>付款金額</p>
					<p>固定金額：{payment.setting2} 元。</p>
				</section>
			);
		}
	};

	return (
		<div className="areaBlock__body">
			<section>
				<p>組別</p>
				<div className="groups">
					{groupName.map((table, index) => {
						return (
							<span key={index} className="groups_table">
								{table.group}
							</span>
						);
					})}
				</div>
			</section>
			{showTimeSetting && (
				<section>
					<p>可預約時段與服務時間</p>
					{rednerServiceTime()}
				</section>
			)}
			{renderPayment()}
		</div>
	);
};

const AreaBlockServiceRow = ({ children }) => {
	return <div className="groupsServiceTime">{React.Children.map(children, (child) => child)}</div>;
};

const AreaBlockServiceRowTitle = ({ index }) => {
	return <div className="groupsServiceTime__title">開放時段{index + 1}</div>;
};

const AreaBlockServiceTime = ({ settingAns }) => {
	const renderTime = () => {
		const { enable, setting2, setting3 } = settingAns.setting_temp;

		if (enable === 2) return setting2.map((r) => `${r[0]}~${r[1]}`).join('、');
		if (enable === 3) return setting3.join('、');
	};

	const renderWeek = () => {
		return settingAns.week_days.map((w) => weeks[w]).join('、');
	};

	return (
		<div className="groupsServiceTime__content">
			<p>{renderWeek()}</p>
			<p>{renderTime()}</p>
		</div>
	);
};

const AreaBlockServiceRowDiningTime = ({ fixedDiningTime, diningTimeOptions, serviceTimeOptionEnabled }) => {
	let diningTime_all = [];
	let diningTime_min = 0;
	let diningTime_max = 0;
	
	if(serviceTimeOptionEnabled) {
		if(diningTimeOptions.mode === 'user_setting') {
			diningTime_all = diningTimeOptions.user_setting.service_time_setting;
	
			if(diningTimeOptions.user_setting.show_name) {
				return (
					<div className="groupsServiceTime__diningTime">
						服務時間：
						<span>依自訂時段</span>
						<div>服務時間選項名稱：開啟</div>
						<div>
							{diningTime_all.map((t, index) => {
								return (
									<div key={index}>
										<span>{t.name} ({Math.floor(parseInt(t.service_time, 10) / 60)} 小時 {Math.floor(parseInt(t.service_time, 10) % 60)} 分鐘)
										{
											index !== diningTime_all.length - 1 ? (<span>、</span>) : null
										}
										</span>
									</div>
								)
							})}
						</div>
					</div>
				)
			} else {
				return (
					<div className="groupsServiceTime__diningTime">
					服務時間：
					<span>依自訂時段</span>
					<div>服務時間選項名稱：關閉</div>
					<div>
						{diningTime_all.map((t, index) => {
							return (
								<span key={index}>
									<span>{Math.floor(parseInt(t.service_time, 10) / 60)} 小時 {Math.floor(parseInt(t.service_time, 10) % 60)} 分鐘
									{
										index !== diningTime_all.length - 1 ? (<span>、</span>) : null
									}
									</span>
								</span>
							)
						})}
					</div>
				</div>
				)
			}
		} else if (diningTimeOptions.mode === 'fixed') {
			diningTime_min = diningTimeOptions.fixed.min;
			diningTime_max = diningTimeOptions.fixed.max;
	
			return (
				<div className="groupsServiceTime__diningTime">
				服務時間：
					<span>依固定區間</span>
					<div>
						<span>從最短 {Math.floor(parseInt(diningTime_min, 10) / 60)} 小時 {Math.floor(parseInt(diningTime_min, 10) % 60)} 分鐘</span>
						～ 
						<span>到最長 {Math.floor(parseInt(diningTime_max, 10) / 60)} 小時 {Math.floor(parseInt(diningTime_max, 10) % 60)} 分鐘</span>
						</div>
					<div>服務時段區間:{diningTimeOptions.fixed.unit}分鐘</div>
				</div>
			)
		}
	} else {
		return (
			<div className="groupsServiceTime__diningTime" style={{ width: 'auto' }}>
				服務時間：{Math.floor(parseInt(fixedDiningTime, 10) / 60)} 小時 {Math.floor(parseInt(fixedDiningTime, 10) % 60)} 分鐘
			</div>
		)
	}
};

AreaBlock.Header = AreaBlockHeader;
AreaBlock.Body = AreaBlockBody;

AreaBlockServiceRow.Title = AreaBlockServiceRowTitle;
AreaBlockServiceRow.ServiceTime = AreaBlockServiceTime;
AreaBlockServiceRow.DiningTime = AreaBlockServiceRowDiningTime;

export default AreaBlock;
