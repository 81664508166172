import $ from 'jquery';
import axios from 'axios';
import { config } from '../../utils/config';

export default class MenuCouponApi {
	getShops(){
		return new Promise((resolve, reject) => {
			$.ajax({
				url: config.domain + '/dashboard/shops',
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	updateShopInfo(shopInfo) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: config.domain + '/dashboard/menu_vouchers/init_menu_voucher_shopuser',
				method: 'POST',
				data: shopInfo,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	getCoupon(shopName, sortType = 'createdAt', orderType = '1') {
		// skip: 0
		// limit: 2
		// order: 0 ASC 遞增 舊到新
		// order: 1 DESC 遞減 新到舊
		// sort: createdAt/exchangedCount/sentCount

		return new Promise((resolve, reject) => {
			$.ajax({
				url: `${config.coupon}/vouchers/${shopName}?sort=${sortType}&&order=${orderType}`,
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	addCoupon(couponSetting) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: `${config.coupon}/vouchers`,
				method: 'POST',
				data: couponSetting,
				processData: false,
				contentType: false,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	updateCoupon(couponSetting) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: `${config.coupon}/vouchers`,
				method: 'PUT',
				data: couponSetting,
				processData: false,
				contentType: false,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	deleteCoupon(couponId) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: `${config.coupon}/vouchers`,
				method: 'DELETE',
				data: JSON.parse(JSON.stringify({ voucherId: couponId })),
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	exchangeCoupon(couponInfo) {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: config.domain + '/dashboard/menu_vouchers/qrcode_exchange',
				method: 'PUT',
				data: couponInfo,
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}
}

export const menuCouponAxios = axios.create({
	baseURL: 'https://shopapi.menutaiwan.com/parse/',
	headers: {
		'X-Parse-Application-Id': '0kIpwFX71FtjVEpkJBjSwbbHUZmDBH2srjJ3qAKd',
		'X-Parse-Rest-Api-Key': 'vWTVYpokcS77UfHJIPhw442YtpQkpz0mH58wYfBr',
		'X-Parse-Master-Key': 'ZOETR9PXykKYF1GyorjzyN2EkVTBo1ZFYvx9gUk4',
		'Content-Type': 'application/json; charset=utf-8'
	}
});
