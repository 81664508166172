import _ from 'lodash';
import moment from 'moment';

import {
  ANNOUNCEMENTLIST_RESET,
  ANNOUNCEMENTLIST_CHANGEPAGE,
  ANNOUNCEMENTLIST_SETROLE,
  ANNOUNCEMENTLIST_FETCHSTART,
  ANNOUNCEMENTLIST_FETCHMONTH,
  ANNOUNCEMENTLIST_FETCOFFICIALTODAY,
  ANNOUNCEMENTLIST_FETCHOFFICIAL,
  ANNOUNCEMENTLIST_SETTODAY,
  ANNOUNCEMENTLIST_UPDATEDAY,
  ANNOUNCEMENTLIST_CHANGETAB,
  ANNOUNCEMENTLIST_CHANGEFILTER,
  ANNOUNCEMENTLIST_FILTERNUMBER,
  ANNOUNCEMENTLIST_SETTINGENDED,
  ANNOUNCEMENTLIST_SETTINGCANCEL,
  ANNOUNCEMENTLIST_SELECTED,
  ANNOUNCEMENTLIST_SHOWCANCELEDITMODAL,
  ANNOUNCEMENTLIST_SHOWLISTMODAL,
  ANNOUNCEMENTLIST_CLOSELISTMODAL,
  ANNOUNCEMENTLIST_LISTMODALSEARCH,
  ANNOUNCEMENTLIST_SEARCH,
  ANNOUNCEMENTLIST_RESETSEARCH,
  ANNOUNCEMENT_INIT,
  ANNOUNCEMENT_EDIT,
  ANNOUNCEMENT_CLEAR,
  ANNOUNCEMENT_CHANGE,
  ANNOUNCEMENT_SHOWADDMODAL,
  ANNOUNCEMENT_SHOWPREVIEWMODAL,
} from '../actions/announcementAction';

const announcementListInitState = {
  page: 1,
  originAnnouncements: [],
  officialAnnouncements: [],
  displayAnnouncements: [],
  sortBy: 'start_time',
  order: null,
  dayAnnouncements: [], // 該日
  todayAnnouncements: [], // 今日
  monthAnnouncements: [], // 當月
  todayOfficialAnnouncements: [],
  displayMonthAnnouncements: [],
  searchAnnouncements: [], // 搜尋結果
  selected: [], // 列表選取的公告
  settingType: 'finished', // finished or deleted
  showListType: 'day', // day or month
  currentMonth: moment().format('YYYY-MM'),
  currentDate: '',
  currentTab: 'shop', // shop or official
  currentFilter: 'all',
  role: 'shop', // shop or official
  searchMode: false,
  searchListTxt: '',
  searchModeModal: false,
  searchModalTxt: '',
  statusAnnouncementNum: [0, 0, 0, 0],
  showAnnouncementDeleteModal: false,
  showAnnouncementCancelModal: false,
  showAnnouncementListModal: false
}

const addAnnouncementInitState = {
  id: null,
  type: 'shop',
  category: 'regular', // regular 一般, normal 常態, official 找活
  title: '',
  content: '',
  startDateTime: '',
  endDateTime: '',
  showEnabled: false,
  finished: false,
  officialShowEnabled: true,
  officialDisplayMode: 'once',
  showAddAnnouncementModal: false,
  showPreviewAnnouncementModal: false
}

export function announcementListReducer (state = announcementListInitState, action) {
  let newState = _.cloneDeep(state);

  switch (action.type) {
    case ANNOUNCEMENTLIST_CHANGEPAGE:
			return Object.assign({}, newState, {
        page: action.page
			});
    case ANNOUNCEMENTLIST_SETROLE:
			return Object.assign({}, newState, {
        role: action.role
			});
    case ANNOUNCEMENTLIST_FETCHSTART:
			return Object.assign({}, newState, {
        originAnnouncements: action.lists
			});
    case ANNOUNCEMENTLIST_FETCHOFFICIAL:
      return Object.assign({}, newState, {
        officialAnnouncements: action.lists
			});
    case ANNOUNCEMENTLIST_FETCHMONTH:
			return Object.assign({}, newState, {
        monthAnnouncements: action.lists
			});
    case ANNOUNCEMENTLIST_FETCOFFICIALTODAY:
			return Object.assign({}, newState, {
        todayOfficialAnnouncements: action.lists
			});
    case ANNOUNCEMENTLIST_UPDATEDAY:
			return Object.assign({}, newState, {
        dayAnnouncements: action.lists
			});
    case ANNOUNCEMENTLIST_SETTODAY:
			return Object.assign({}, newState, {
        todayAnnouncements: action.lists
			});
    case ANNOUNCEMENTLIST_SEARCH:
			return searchList(state, action);
    case ANNOUNCEMENTLIST_RESETSEARCH:
      return Object.assign({}, newState, {
        searchMode: false,
        searchListTxt: '',
        searchModeModal: false,
        searchModalTxt: '',
        displayAnnouncements: []
			});
    case ANNOUNCEMENTLIST_SHOWCANCELEDITMODAL:
			return Object.assign({}, newState, {
        showAnnouncementCancelModal: true
			});
    case ANNOUNCEMENTLIST_SETTINGENDED:
			return Object.assign({}, newState, {
        showAnnouncementDeleteModal: true,
        settingType: action.settingType
			});
    case ANNOUNCEMENTLIST_SETTINGCANCEL:
			return Object.assign({}, newState, {
        showAnnouncementDeleteModal: false,
        showAnnouncementCancelModal: false
			});
    case ANNOUNCEMENTLIST_SELECTED:
			return Object.assign({}, newState, {
        selected: action.selected
			});
    case ANNOUNCEMENTLIST_CHANGETAB:
			return Object.assign({}, newState, {
        currentTab: action.tab
			});
    case ANNOUNCEMENTLIST_CHANGEFILTER:
      return setDisplayAnnouncement(state, action);
    case ANNOUNCEMENTLIST_FILTERNUMBER:
			return setStatusMessagesNum(state, action);
    case ANNOUNCEMENTLIST_SHOWLISTMODAL:
      return handleAnnouncementListModal(state, action);
    case ANNOUNCEMENTLIST_CLOSELISTMODAL:
      return Object.assign({}, newState, {
        showAnnouncementListModal: false,
        showListType: 'day',
        currentDate: '',
        searchModeModal: false,
        searchModalTxt: ''
			});
    case ANNOUNCEMENTLIST_LISTMODALSEARCH:
      return searchModal(state, action);
    case ANNOUNCEMENTLIST_RESET:
      return Object.assign({}, newState, {
        selected: [],
        settingType: 'finished',
        currentTab: 'shop',
        currentFilter: 'all',
        role: 'shop',
        searchMode: false,
        searchListTxt: '',
        searchModeModal: false,
        searchModalTxt: '',
        searchAnnouncements: [],
        showAnnouncementDeleteModal: false,
        showAnnouncementCancelModal: false,
        showAnnouncementListModal: false
			});
    default:
      return state;
  }
}

export function addAnnouncementReducer (state = addAnnouncementInitState, action) {
  let newState = _.cloneDeep(state);

  switch (action.type) {
    case ANNOUNCEMENT_EDIT:
      return editAnnouncement(state, action);
    case ANNOUNCEMENT_CHANGE:
      return announcementChange(state, action);
    case ANNOUNCEMENT_SHOWADDMODAL:
			return Object.assign({}, newState, {
        showAddAnnouncementModal: !newState.showAddAnnouncementModal
			});
    case ANNOUNCEMENT_SHOWPREVIEWMODAL:
			return Object.assign({}, newState, {
        showPreviewAnnouncementModal: !newState.showPreviewAnnouncementModal
			});
    case ANNOUNCEMENT_INIT:
			return Object.assign({}, newState, {
        id: null,
        category: 'regular',
        title: '',
        content: '',
        startDateTime: '',
        endDateTime: '',
        showEnabled: false,
        finished: false,
        officialShowEnabled: true,
        officialDisplayMode: 'once',
			});
    case ANNOUNCEMENT_CLEAR:
			return Object.assign({}, newState, {
        id: null,
        category: 'regular',
        title: '',
        content: '',
        startDateTime: '',
        endDateTime: '',
        showEnabled: false,
        finished: false,
        officialShowEnabled: true,
        officialDisplayMode: 'once',
        showAddAnnouncementModal: false,
        showPreviewAnnouncementModal: false
			});
    default:
      return state;
  }
}

function announcementChange(state, action) {
	let newState = _.cloneDeep(state);
	const { editType, value } = action;

  switch (editType) {
    case 'title':
      return Object.assign({}, newState, {
        title : value
      });
    case 'content':
      return Object.assign({}, newState, {
        content : value
      });
    case 'showEnabled':
      return Object.assign({}, newState, {
        showEnabled : value
      });
    case 'finished':
      return Object.assign({}, newState, {
        finished : value
      });
    case 'officialShowEnabled':
      return Object.assign({}, newState, {
        officialShowEnabled : value
      });
    case 'category':
      return Object.assign({}, newState, {
        category : value
      });
    case 'startDateTime':
      return Object.assign({}, newState, {
        startDateTime : value
      });
    case 'endDateTime':
      return Object.assign({}, newState, {
        endDateTime : value
      });
    case 'officialDisplayMode':
      return Object.assign({}, newState, {
        officialDisplayMode : value
      });
    default:
      return newState;
  }
}

function editAnnouncement(state, action) {
	let newState = _.cloneDeep(state);
	const { item } = action;
  const {
    id,
    type,
    category,
    title,
    content,
    start_time,
    end_time,
    finished,
    show_enabled,
    enabled,
    official_announcement_settings
  } = item;

  newState.id = id;
  newState.type = type ? type : 'shop';
  newState.category = category;
  newState.title = title;
  newState.content = content;
  newState.startDateTime = start_time;
  newState.endDateTime = end_time;
  newState.finished = finished;
  newState.showEnabled = show_enabled ? show_enabled : false;
  newState.officialShowEnabled = enabled ? enabled : false;
  newState.officialDisplayMode = official_announcement_settings ? official_announcement_settings.display_mode : null;

	return newState;
}

function setDisplayAnnouncement(state, action) {
  let newState = _.cloneDeep(state);
  const { currentTab, searchMode, searchAnnouncements } = newState;
  const { filter, page, lists, sortBy, order } = action;

	let filterAnnouncements = [];

	if(!searchMode) {
    filterAnnouncements = lists;
	} else {
    if(searchAnnouncements) {
      if(filter === 'regular') {
        filterAnnouncements = searchAnnouncements.filter((item) => item.category === 'regular');
      } else if(filter === 'long_term') {
        filterAnnouncements = searchAnnouncements.filter((item) => item.category === 'long_term');
      } else if(filter === 'finished'){
        filterAnnouncements = searchAnnouncements.filter((item) => item.finished);
      } else {
        filterAnnouncements = searchAnnouncements;
      }

      if(sortBy === 'start_time') {
        if(order === 'asc') {
          filterAnnouncements.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
        } else {
          filterAnnouncements.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
        }
      } else {
        if(order === 'asc') {
          filterAnnouncements.sort((a, b) => new Date(a.end_time) - new Date(b.end_time));
        } else {
          filterAnnouncements.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
        }
      }
    }
	}

	newState['currentFilter'] = filter;
	newState['page'] = page ? page : 1;
	newState['displayAnnouncements'] = filterAnnouncements;
  newState['sortBy'] = sortBy;
  newState['order'] = order;

  return newState;
}

function searchModal(state, action) {
  let newState = _.cloneDeep(state);
  const { searchModalTxt } = action;
  const { monthAnnouncements } = newState;

  let newDisplayAnnouncement = [];

  if(searchModalTxt.length === 0) {
    newDisplayAnnouncement = monthAnnouncements;
    newState['searchModeModal'] = false;

  } else {
    Object.keys(monthAnnouncements).forEach((key) => {
      if(monthAnnouncements[key].length > 0) {
        newDisplayAnnouncement[key] = [];

        for(let i = 0; i < monthAnnouncements[key].length; i++) {
          if(monthAnnouncements[key][i].title.includes(searchModalTxt)) {
            newDisplayAnnouncement[key].push(monthAnnouncements[key][i]);
          }
        }
      }
    })

    newState['searchModeModal'] = true;
  }

  newState['searchModalTxt'] = searchModalTxt;
	newState['displayMonthAnnouncements'] = newDisplayAnnouncement;
  return newState;
}

function searchList(state, action) {
  let newState = _.cloneDeep(state);
  const { searchText, lists } = action;

  let searchMode = false;
  let newSearchAnnouncement = [];

  if(searchText.length === 0) {
    newSearchAnnouncement = [];
    searchMode = false;
  } else {
    newSearchAnnouncement = lists;
    searchMode = true;
  }

  newState['searchMode'] = searchMode;
  newState['searchListTxt'] = searchText;
	newState['searchAnnouncements'] = newSearchAnnouncement;
  return newState;
}

function setStatusMessagesNum(state, action) {
	const newState = _.cloneDeep(state);
	const {
    currentTab,
		originAnnouncements,
    officialAnnouncements,
		searchAnnouncements,
		searchMode
	} = newState;

	let status_all, status_regular, status_long_term, status_finished;

  if(currentTab === 'shop' && !searchMode) {
    status_all = originAnnouncements;
    status_regular = originAnnouncements.filter((i) => i.category === 'regular'  && !i.finished);
    status_long_term = originAnnouncements.filter((i) => i.category === 'long_term' && !i.finished);
    status_finished = originAnnouncements.filter((i) => i.finished);
  }

  if(currentTab === 'official' && !searchMode) {
    status_all = officialAnnouncements;
    status_regular = officialAnnouncements.filter((i) => i.category === 'regular' && !i.finished);
    status_long_term = 0;
    status_finished = officialAnnouncements.filter((i) => i.finished);
  }
  
  if(searchMode) {
    status_all = searchAnnouncements;
    status_regular = searchAnnouncements.filter((i) => i.category === 'regular' && !i.finished);
    status_long_term = searchAnnouncements.filter((i) => i.category === 'long_term' && !i.finished);
    status_finished = searchAnnouncements.filter((i) =>  i.finished);
  }

	let statusNum = [
    status_all.length,
    status_regular.length,
    status_long_term.length,
    status_finished.length
  ];

  let officialStatusNum = _.cloneDeep(statusNum);
  officialStatusNum.splice(2, 1);

	newState['statusAnnouncementNum'] = currentTab === 'shop' ? statusNum : officialStatusNum;

	return newState;
}

function handleAnnouncementListModal(state, action) {
	const newState = _.cloneDeep(state);
  const { monthAnnouncements, dayAnnouncements } = newState;
  const { showListType, currentDate } = action;

	const date = currentDate ? moment(currentDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
	let effectDayAnnouncement = [];

  if(monthAnnouncements && monthAnnouncements[date]) {
    effectDayAnnouncement = monthAnnouncements[date].filter((i) => {
			return !i.finished && i.show_enabled;
    });
  } else {
    effectDayAnnouncement = dayAnnouncements;
  }

  effectDayAnnouncement.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

  if(showListType === 'day') {
    newState['currentDate'] = currentDate;
    newState['dayAnnouncements'] = effectDayAnnouncement;
  }
  
  newState['showAnnouncementListModal'] = true;
  newState['showListType'] = showListType;
  newState['displayMonthAnnouncements'] = monthAnnouncements;

  return newState;
}