import React from 'react'

function UserNav({ isMobile = false, activeTab, handleTab }) {
  return (
    <ul className={`tab ${isMobile ? 'tab_menu' : ''}`}>
      <li
        onClick={() => handleTab('profile')}
        className={`${activeTab === 'profile' ? 'active' : ''}`}
      >
        帳號資訊
      </li>
      <li
        onClick={() => handleTab('password')}
        className={`${activeTab === 'password' ? 'active' : ''}`}
      >
        變更密碼
      </li>
    </ul>
  )
}

export default UserNav