import React from "react";
const prefix = "img";

const files = {
	byId: {
		filter: {
			// width: 26,
			// height: 22,
			viewBox: [0, 0, 26, 22],
			data: `
    <path xmlns="http://www.w3.org/2000/svg" fill="#34BC75" fill-rule="nonzero" d="M19.303 3.16H6.697v.785a.784.784 0 0 1-.388.696.79.79 0 0 1-.8 0 .784.784 0 0 1-.388-.696V3.16H2.363a.786.786 0 0 0-.788.785V6.3h22.85v1.57H1.575V6.3h22.85v1.57H1.575v11.775c0 .434.353.785.788.785h21.274a.787.787 0 0 0 .788-.785v-15.7a.786.786 0 0 0-.788-.785h-2.758v.785a.784.784 0 0 1-.388.696.79.79 0 0 1-.8 0 .784.784 0 0 1-.388-.696V3.16zm1.576-1.57h2.758A2.36 2.36 0 0 1 26 3.945v15.7A2.36 2.36 0 0 1 23.637 22H2.363A2.36 2.36 0 0 1 0 19.645v-15.7A2.36 2.36 0 0 1 2.363 1.59h2.758V.805A.784.784 0 0 1 5.51.108a.79.79 0 0 1 .8 0 .784.784 0 0 1 .388.697v.785h12.606V.805a.784.784 0 0 1 .388-.697.79.79 0 0 1 .8 0 .784.784 0 0 1 .388.697v.785zM5.515 12.187a.787.787 0 0 1-.788-.785c0-.433.353-.785.788-.785h14.97c.435 0 .788.352.788.785a.787.787 0 0 1-.788.785H5.515zm0 3.925a.787.787 0 0 1-.788-.785c0-.433.353-.785.788-.785h12.606c.435 0 .788.352.788.785a.787.787 0 0 1-.788.785H5.515z"/>
	`
		},
		person: {
			// width: 25,
			// height: 17,
			viewBox: [0, 0, 25, 17],
			data: `
    <g xmlns="http://www.w3.org/2000/svg" fill="none" fill-rule="evenodd">
        <path d="M-1-5h27v27H-1z"/>
        <path fill="#34BC75" fill-rule="nonzero" d="M17 7.375A3.361 3.361 0 0 0 20.364 4a3.371 3.371 0 0 0-6.74 0A3.371 3.371 0 0 0 17 7.375zm-9 0A3.362 3.362 0 0 0 11.363 4a3.371 3.371 0 0 0-6.739 0A3.371 3.371 0 0 0 8 7.375H8zm0 2.25C5.38 9.625.125 10.94.125 13.563v2.812h15.75v-2.813c0-2.621-5.254-3.937-7.875-3.937zm9 0c-.326 0-.697.023-1.091.056 1.305.945 2.216 2.217 2.216 3.882v2.812h6.75v-2.813c0-2.621-5.254-3.937-7.875-3.937z"/>
    </g>
	`
		},
		money: {
			// width: 40,
			// height: 20,
			viewBox: [0, 0, 40, 20],
			data: `
    <g xmlns="http://www.w3.org/2000/svg" fill="none" fill-rule="nonzero">
        <rect width="38" height="18" x="1" y="1" stroke="#34BC75" stroke-width="2" rx="6"/>
        <path fill="#34BC75" d="M22.898 7.58c0 .3.218.543.487.543.268 0 .486-.243.486-.543 0-1.635-1.211-2.774-3.062-2.943v-.868c0-.3-.218-.543-.486-.543-.27 0-.487.243-.487.543v.868c-1.85.169-3.062 1.308-3.062 2.943 0 2.121 1.606 2.598 3.062 2.88v3.81c-1.05-.12-2.089-.65-2.089-1.85 0-.3-.218-.543-.486-.543-.269 0-.487.243-.487.543 0 1.635 1.211 2.774 3.062 2.943v.868c0 .3.218.543.487.543.268 0 .486-.243.486-.543v-.868c1.85-.169 3.062-1.308 3.062-2.943 0-2.122-1.606-2.599-3.062-2.88V5.73c1.05.12 2.09.65 2.09 1.85zm-4.833.203c0-1.282.962-1.847 1.935-1.977v3.871c-1.313-.317-1.935-.697-1.935-1.894zm4.516 4.435c0 1.281-.963 1.846-1.936 1.976v-3.871c1.313.317 1.936.697 1.936 1.895z"/>
    </g>
	`
		},
		shape: {
			// width: 27,
			// height: 27,
			viewBox: [0, 0, 27, 27],
			data: `
    <path xmlns="http://www.w3.org/2000/svg" fill="#34BC75" fill-rule="nonzero" d="M4.158 2C3.518 2 3 2.448 3 3v21c0 .553.518 1 1.157 1h19.686c.64 0 1.157-.448 1.157-1V3c0-.553-.518-1-1.157-1H4.157h.001zm-.636-2h19.956C25.423 0 27 1.343 27 3v21c0 1.656-1.576 3-3.521 3H3.52C1.576 27 0 25.656 0 24V3c0-1.656 1.576-3 3.521-3h.001zm3.663 8C6.531 8 6 7.552 6 7s.53-1 1.185-1h13.63C21.469 6 22 6.448 22 7s-.53 1-1.185 1H7.185zm.03 4c-.43.009-.833-.18-1.051-.493a.867.867 0 0 1 0-1.014c.218-.313.62-.502 1.051-.493h13.63c.642.014 1.155.457 1.155 1 0 .543-.513.986-1.156 1H7.215zm-.03 4c-.423 0-.814-.19-1.026-.5a.867.867 0 0 1 0-1c.212-.31.603-.5 1.026-.5h13.63c.654 0 1.185.448 1.185 1s-.53 1-1.185 1H7.185zm-.003 4c-.422 0-.812-.19-1.024-.5a.869.869 0 0 1 0-1c.212-.31.602-.5 1.024-.5h10.636c.653 0 1.182.448 1.182 1s-.53 1-1.182 1H7.182z"/>
	`
		},
		telephone: {
			// width: 15,
			// height: 15,
			viewBox: [0, 0, 15, 15],
			data: `
    <g xmlns="http://www.w3.org/2000/svg" fill="none" fill-rule="evenodd">
        <path d="M-2-3h19v19.531H-2z"/>
        <path fill="#34BC75" d="M14.049 10.616c-.575-.596-1.153-1.19-1.734-1.781-.308-.313-.668-.479-1.044-.479-.378 0-.737.167-1.043.476l-.076.08c-.309.313-.628.638-.937.964L9.03 9.78c-.14-.072-.276-.139-.394-.216-.926-.598-1.778-1.393-2.6-2.438a6.242 6.242 0 0 1-.876-1.41 54 54 0 0 0 .803-.822l.202-.207c.64-.662.638-1.521-.003-2.186-.196-.204-.398-.407-.59-.606l-.26-.266-.272-.282c-.2-.208-.4-.414-.604-.617C4.127.418 3.763.255 3.39.255c-.374 0-.736.162-1.042.474-.146.15-.296.301-.437.45a34.34 34.34 0 0 1-.64.65 2.23 2.23 0 0 0-.67 1.479c-.065.925.184 1.762.41 2.39.512 1.416 1.294 2.758 2.394 4.104a14.975 14.975 0 0 0 4.9 3.943c1.035.518 1.925.781 2.79.831.063.003.126.006.187.006.731 0 1.332-.266 1.786-.787.197-.225.406-.434.629-.656l.342-.346c.644-.665.646-1.518.01-2.177z"/>
    </g>
	`
		}
	},
	allIds: ["filter", "person", "money", "shape", "telephone"]
};

function SVG(props) {
	if (props.isHidden) {
		return <svg display='none'>{props.children}</svg>;
	}

	let file = files.byId[props.id];

	if (!file) return null;

	let width = props.width || file.width || null;
	let height = props.height || file.height || null;
	let viewBox = file.viewBox
		? file.viewBox.join(" ")
		: "0 0 " + props.width + " " + props.height;

	return props.isHidden ? (
		<svg display='none'>{props.children}</svg>
	) : (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			version='1.1'
			preserveAspectRatio='xMinYMin meet'
			width={width}
			height={height}
			viewBox={viewBox}
			style={props.style}>
			<use href={"#" + prefix + "-" + props.id} />
		</svg>
	);
}

function SVGSource(props) {
	return (
		<SVG isHidden={true}>
			<defs>
				{files.allIds.reduce((defs, fileId, fileIndex) => {
					const file = files.byId[fileId];
					return defs.concat(
						<g
							key={fileIndex}
							id={prefix + "-" + fileId}
							dangerouslySetInnerHTML={{ __html: file.data }}
						/>
					);
				}, [])}
			</defs>
		</SVG>
	);
}

export { SVG, SVGSource };
