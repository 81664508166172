import React, { useEffect } from 'react';


const SwapSeatPanel = ({swapBookingInfo, closeSwapSeate}) =>{
    useEffect(()=>{
        document.getElementById(swapBookingInfo.status).click();

        setTimeout(() => {
            const $bookingCard = document.getElementById(swapBookingInfo.id);
            const $clone = $bookingCard.cloneNode(true);
            const $switchSeatPanel = document.getElementById('switchSeatPanel');
    
            $switchSeatPanel.append($clone);
            document.getElementById('confirmed').click();
    
            return()=>{
                $switchSeatPanel.innerHTML='';
            }
        },0)

    }, [swapBookingInfo])

    useEffect(()=>{
        const inserDom = `<div class="SwitchSeatPanelTitle">請選擇欲交換的桌次</div>`;
        const $tablemap = document.getElementsByClassName('tablemap')[0];
        $tablemap.insertAdjacentHTML("afterbegin", inserDom);

        return()=>{
            const $SwitchSeatPanelTitle = document.getElementsByClassName("SwitchSeatPanelTitle")[0];
            $tablemap.removeChild($SwitchSeatPanelTitle);
        }
    }, [])


    return(
        <div id="switchSeatPanel" className="SwitchSeatPanel">
			<button className="closeSeatedPanel" onClick={()=> closeSwapSeate()}/>
        </div>
    )
}

export default SwapSeatPanel;