import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const CrmSubmitLoading = () => {
  return (
    <div className="crmMessageAddLoading">
      <LoadingOutlined style={{ fontSize: 30 }} />
      <p>Loading ...</p>
    </div>
  );
};

export default CrmSubmitLoading;