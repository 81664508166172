import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router';
import ScrollReveal from '../../libs/vendor/scrollreveal.min';
import PageNav from './PageNav';
import PageFooter from './PageFooter';
import PageSlogan from './PageSlogan';
import '../../sass/pageCoupon.scss';

import cover1 from '../../images/page_coupon/cover1.png';
import cover2 from '../../images/page_coupon/cover2.png';
import cover3 from '../../images/page_coupon/cover3_1.png';
import cover4 from '../../images/page_coupon/cover4.png';
import icon1 from '../../images/page_coupon/icon1.png';
import icon2 from '../../images/page_coupon/icon2.png';
import icon3 from '../../images/page_coupon/icon3.png';
import icon4 from '../../images/page_coupon/icon4.png';
import step1 from '../../images/page_coupon/step1.png';
import step2 from '../../images/page_coupon/step2.png';
import step3 from '../../images/page_coupon/step3.png';
import step4 from '../../images/page_coupon/step4.png';
import AppleStore from '../../images/app_store.png'
import GooglePlay from '../../images/google_play.png'
import Feature from '../../images/page_coupon/xx.png';

const apple_store_url = 'https://apps.apple.com/tw/app/menu%E7%BE%8E%E9%A3%9F%E8%AA%8C-%E7%BE%8E%E9%A3%9F%E8%A8%98%E9%8C%84-%E4%BD%A0%E6%88%91%E5%88%86%E4%BA%AB/id976520817'
const google_play_url = 'https://play.google.com/store/apps/details?id=com.findlife.menu&hl=zh_TW&gl=US'


const CoverContent = () => {
	return (
		<div className="coverContent">
			<Link to="/contact">
				<button className="q-btn">立即試用</button>
			</Link>
		</div>
	);
};

const PageCoupon = () => {
	useEffect(() => {
		window.sr = new ScrollReveal();
		window.sr.reveal('.couponSection_1 h1', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000
		});

		window.sr.reveal('.couponSection_1 h2', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
			delay: 500
		});

		window.sr.reveal('.couponSection_1 .coverContent', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
			delay: 500
		});

		window.sr.reveal('.couponSection_1 img', {
			distance: '350px',
			origin: 'right',
			duration: 1500,
			delay: 500
		});

		window.sr.reveal('.couponSection_1_1 p', {
			distance: '350px',
			origin: 'bottom',
			duration: 1500,
			delay: 500
		});


		window.sr.reveal('.couponSection_1_1 h2, .couponSection_1_1 h3', {
			distance: '350px',
			origin: 'right',
			duration: 1500,
			delay: 500
		});


		window.sr.reveal('.couponSection_2 img', {
			distance: '350px',
			origin: 'left',
			duration: 1500,
			delay: 500
		});

		window.sr.reveal('.couponSection_2 .subContent', {
			distance: '250px',
			origin: 'right',
			duration: 1000,
			delay: 500
		});

		window.sr.reveal('.couponSection_3 .flex:first-child', {
			distance: '350px',
			origin: 'left',
			duration: 1000,
			delay: 500
		});

		window.sr.reveal('.couponSection_3 .flexReverse', {
			distance: '350px',
			origin: 'right',
			duration: 1000,
			delay: 500
		});

		window.sr.reveal('.couponSection_4 h2', {
			distance: '350px',
			origin: 'bottom',
			duration: 1000,
			delay: 500
		});

		window.sr.reveal(
			'.couponSection_4 li',
			{
				distance: '350px',
				origin: 'bottom',
				duration: 1000,
				delay: 1000
			},
			300
		);

		window.sr.reveal('.couponSection_5 h2', {
			distance: '350px',
			origin: 'bottom',
			duration: 1000,
			delay: 1000
		});

		window.sr.reveal(
			'.couponSection_5 li',
			{
				distance: '350px',
				origin: 'bottom',
				duration: 1000,
				delay: 1000
			},
			300
		);
	}, []);

	return (
		<div className="pageCoupon">
			<Helmet>
				<meta
					property="og:description"
					content="結合華人最大美食社群 App —— MENU 美食誌，對超過 70 萬的黃金用戶宣傳您的優惠活動，深度分析，自動匹配潛力 VIP 顧客！"
				/>
				<meta
					name="description"
					content="結合華人最大美食社群 App —— MENU 美食誌，對超過 70 萬的黃金用戶宣傳您的優惠活動，深度分析，自動匹配潛力 VIP 顧客！"
				/>
			</Helmet>
			<PageNav />
			<section className="couponSection_1">
				<div className="cover">
					<div className="content">
						<h1>優惠券系統</h1>
						<h2>店家活動行銷好幫手</h2>
						<CoverContent />
					</div>
					<div className="img">
						<figure>
							<img src={cover1} alt="" />
						</figure>
						<CoverContent />
					</div>
				</div>
			</section>
			<section className="couponSection_1_1">
				<p>推出的店家優惠常常無法有效宣傳？</p>

				<h2>MENU 店+ 優惠券系統</h2>
				<h3>輕鬆上架 & 推廣你的最新優惠內容</h3>
			</section>
			<section className="couponSection_2">
				<figure>
					<img src={cover2} alt="" />
				</figure>

				<div className="subContent">
					<h2>全自動化上架</h2>

					<div>
						<h3>溝通成本總是讓人勞心又勞力？</h3>
						<p>MENU 店＋全自動化優惠券上架系統，隨時隨地都能上架 Coupon 優惠券。</p>
						<p>時刻追蹤活動成效，第一時間靈活調整超方便！</p>
					</div>
				</div>
			</section>

			<section className="couponSection_3">
				<div className="flex">
					<div className="img">
						<figure className="pcCover3">
							<img src={cover3} alt="" />
						</figure>

					</div>
					<div className="content text-center">
						<div className="subContent">
							<h2>用戶自主分享</h2>
							<div>
								<h3>在自家粉專發佈優惠卻無法有效推廣？</h3>
								<p>優惠券系統結合美食 App <strong>「MENU 美食誌」</strong>，</p>
								<p>超過 80 萬的吃貨客群宣傳您的優惠活動，</p>
								<p>有效幫助帶客，用戶還能幫你自主口碑擴散！</p>

								<div className="feature">
									<img src={Feature} alt="MEMU Shop x MEMU"/>
								</div>

								<div className="download">
									<div>
									<a href={apple_store_url} target="_blank" rel="noopener noreferrer">
										<img src={AppleStore} alt="AppleStore"/>
									</a>
										</div>
									<div>
									<a href={google_play_url} target="_blank" rel="noopener noreferrer">
										<img src={GooglePlay} alt="GooglePlay"/>
									</a>
										</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flexReverse">
					<div className="content text-center">
						<div className="subContent">
							<h2>
								投放給對的人，<br />才是對的優惠！
							</h2>
							<div>
								<p>百萬筆資料深度分析，比對顧客消費輪廓，</p>
								<p>為您找到最有價值的潛力顧客，讓顧客都能變成回頭客。</p>
								<p>效益極大化，不再大海撈針！</p>
							</div>
						</div>
					</div>
					<div className="img">
						<figure>
							<img src={cover4} alt="" />
						</figure>
					</div>
				</div>
			</section>
			<section className="couponSection_4">
				<h2>選擇 MENU 優惠券系統的理由</h2>

				<ul>
					<li>
						<div className="img">
							<figure>
								<img src={icon1} alt="" />
							</figure>
						</div>
						<div className="content">
							<h3>優惠券實名制</h3>
							<p>店家完整搜集顧客資料，不同顧客分門別類，整合 CRM 系統搭配行銷工具好簡單！</p>
						</div>
					</li>
					<li>
						<div className="img">
							<figure>
								<img src={icon2} alt="" />
							</figure>
						</div>
						<div className="content">
							<h3>輕鬆分析成效</h3>
							<p>每日報表即時呈現，輕鬆匯出分析每檔報表，結果成效一目瞭然。</p>
						</div>
					</li>
					<li>
						<div className="img">
							<figure>
								<img src={icon3} alt="" />
							</figure>
						</div>
						<div className="content">
							<h3>成交不抽成</h3>
							<p>告別複雜金流與昂貴成交手續費，顧客到店消費付款，幫你自然帶客不抽成。</p>
						</div>
					</li>
					<li>
						<div className="img">
							<figure>
								<img src={icon4} alt="" />
							</figure>
						</div>
						<div className="content">
							<h3>TA 精準投放</h3>
							<p>發揮社群力，精準描繪顧客興趣愛好，在茫茫人海中和你一起找出死忠 VIP。</p>
						</div>
					</li>
				</ul>
			</section>
			<section className="couponSection_5">
				<h2>兌換流程超簡單</h2>
				<ul>
					<li>
						<figure>
							<img src={step1} alt="" />
						</figure>
						<p>店家上架優惠券。</p>
					</li>
					<li>
						<figure>
							<img src={step2} alt="" />
						</figure>
						<p>顧客領取後採「先到先贏」制度，並可檢視剩餘張數。</p>
					</li>
					<li>
						<figure>
							<img src={step3} alt="" />
						</figure>
						<p>顧客實際前往店家，由店家操作 App 核銷。</p>
					</li>
					<li>
						<figure>
							<img src={step4} alt="" />
						</figure>
						<p>顧客自主發文宣傳，社群發酵帶動話題。</p>
					</li>
				</ul>
			</section>

			<PageSlogan />
			<PageFooter />
		</div>
	);
};

export default PageCoupon;
