import React, { Component } from "react";
import $ from "jquery";
// import _ from 'lodash'
// import { handleError } from '../../libs/handler';
import BookingSystemApi from "./BookingSystemApi";
import classNames from "classnames";

//tagEditable: 調用x-editable的cless
const defaultCustomQSetting = JSON.stringify({
	question_type: "question",
	title: "",
	content: "{}",
	required: true,
	multiple_selected: false,
	is_available: true
});
const defaultSelect =
	'{"name": "", "min": 1, "max": 2, "available": true, "newOption": true}';
const defaultTag = '{"name": "請填寫", "available": true, "newOption": true}';
const qTypeMap = { quantity: "數量統計", tag: "選擇", question: "問答" };
const QUANTITY = "quantity",
	TAG = "tag",
	QUESTION = "question";
let maxIndex = 0;
let optionStyle = false,
	emptyStyle = false;

export default class CustomQuestion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			init: false,
			isHiddenModal: true,
			customized_questions: [],
			newCustomQ: [],
			deleteCustomQ: [],
			updateCustomQ: [],
			customQSetting: defaultCustomQSetting,
			qIndex: null,
			isSaved: true,
			sending: false
		};

		this.loadSetting = this.loadSetting.bind(this);

		this.renderCustomQ = this.renderCustomQ.bind(this);

		this.addQuestion = this.addQuestion.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.modify = this.modify.bind(this);
		this.deleteList = this.deleteList.bind(this);
		this.move = this.move.bind(this);
		this.submit = this.submit.bind(this);

		this.handleModalSubmit = this.handleModalSubmit.bind(this);
	}

	routerWillLeave = nextLocation => {
		// console.log('Router Leave');
		if (!this.state.isSaved)
			return "您剛剛做的服務標記更動尚未儲存，請問您是否要離開?";
	};

	componentDidMount() {
		this.API = new BookingSystemApi();
		this.loadSetting();
		this.props.router.setRouteLeaveHook(
			this.props.route,
			this.routerWillLeave
		);
	}

	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}

	loadSetting(status) {
		this.API.getQuestions().then(data => {
			if (status === "update") {
				this.setState(
					{
						customized_questions: data
					},
					() => window.app.alert.setMessage("儲存成功", "done")
				);
			} else {
				this.setState({
					customized_questions: data,
					init: true
				});
			}
		});
	}

	renderCustomQ() {
		let { customized_questions } = this.state;
		let allQLength = customized_questions.length;

		return customized_questions.map((q, index) => {
			return (
				<QTypeBlock
					key={index}
					index={index}
					detail={q}
					deleteList={this.deleteList}
					last={index + 1 === allQLength}
					move={this.move}
					modify={this.modify}
				/>
			);
		});
	}

	addQuestion() {
		this.setState(
			{
				isHiddenModal: false,
				customQSetting: defaultCustomQSetting,
				isSaved: false
			},
			() => this.questionModal.show()
		);
	}
	closeModal() {
		this.setState({
			isHiddenModal: true,
			qIndex: null
		});
	}

	handleModalSubmit(customQ) {
		let { customized_questions, qIndex } = this.state;

		if (customQ.id === undefined && !customQ.addNew) {
			//新增
			customized_questions.push(customQ);
			customQ["addNew"] = true;
			window.app.alert.setMessage("新增完成", "done");
		} else {
			//修改
			customized_questions[qIndex] = customQ;
			customized_questions[qIndex]["modify"] = true;
			window.app.alert.setMessage("修改完成", "done");
		}

		this.questionModal.hide();

		this.setState({
			customized_questions,
			isHiddenModal: true,
			qIndex: null
		});
	}

	modify(index) {
		let { customized_questions } = this.state;
		let propsSelect = JSON.stringify(customized_questions[index]);

		this.setState(
			{
				customQSetting: propsSelect,
				isHiddenModal: false,
				qIndex: index,
				isSaved: false
			},
			() => this.questionModal.show()
		);
	}

	deleteList(index, id) {
		let { customized_questions, deleteCustomQ } = this.state;
		let quesiton = customized_questions[index];

		if (quesiton.id) {
			let res = window.confirm(
				"若刪除已儲存的題目，將會同時刪除已建立預約中的題目及所選取的選項"
			);

			if (res) {
				customized_questions.splice(index, 1);
				if (id) {
					deleteCustomQ.push(id);
				}

				this.setState(
					{
						customized_questions,
						deleteCustomQ,
						isSaved: false
					},
					() => window.app.alert.setMessage("已刪除", "done")
				);
			}
		} else if (!quesiton.id) {
			customized_questions.splice(index, 1);
			if (id) {
				deleteCustomQ.push(id);
			}

			this.setState(
				{
					customized_questions,
					deleteCustomQ,
					isSaved: false
				},
				() => window.app.alert.setMessage("已刪除", "done")
			);
		}
	}

	move(index, type) {
		let { customized_questions } = this.state;
		let now = JSON.parse(JSON.stringify(customized_questions[index]));

		if (type === "up") {
			let pre = JSON.parse(
				JSON.stringify(customized_questions[index - 1])
			);

			pre["modify"] = true;
			now["modify"] = true;

			customized_questions[index] = pre;
			customized_questions[index - 1] = now;
		} else if (type === "down") {
			let next = JSON.parse(
				JSON.stringify(customized_questions[index + 1])
			);

			next["modify"] = true;
			now["modify"] = true;

			customized_questions[index] = next;
			customized_questions[index + 1] = now;
		}

		this.setState({
			customized_questions,
			isSaved: false
		});
	}

	submit() {
		let {
			customized_questions,
			deleteCustomQ,
			newCustomQ,
			updateCustomQ
		} = this.state;
		// console.log("customized_questions::", customized_questions)
		customized_questions.map((q, index) => {
			q.question_order = index + 1;

			if (q.question_type !== QUESTION) {
				let contentObject = JSON.parse(q.content);
				let newContent = contentObject;
				for (let i in newContent) {
					delete newContent[i]["newOption"];
				}
				q.content = JSON.stringify(newContent);
			}

			if (!q.id && q.addNew) {
				newCustomQ.push(JSON.parse(JSON.stringify(q)));
				q.id = -1; //以防按了兩次儲存造成重複新增
			} else if (q.modify) {
				updateCustomQ.push(JSON.parse(JSON.stringify(q)));
				delete q["modify"]; //以防按了兩次儲存造成重複新增
			}

			return true;
		});

		// console.log("newCustomQ新增:::", newCustomQ)
		// console.log("updateCustomQ更新:::", updateCustomQ)
		// console.log("deleteCustomQ刪除:::", deleteCustomQ)

		this.setState(
			{
				newCustomQ,
				updateCustomQ,
				deleteCustomQ,
				isSaved: true,
				sending: true
			},
			() => this.deleteApi()
		);
	}

	deleteApi = () => {
		let { deleteCustomQ } = this.state;

		this.API.deleteCustomQuestion(deleteCustomQ)
			.then(data => {
				// console.log("1-2.刪除完成")
				this.setState({ deleteCustomQ: [] }, () => this.createApi());
			})
			.catch(error => {
				window.app.alert.setMessage("儲存失敗 請稍後再試", "error");
				this.setState({
					sending: false
				});
			});
	};

	createApi = () => {
		let { newCustomQ } = this.state;
		this.API.createCustomQuestion(newCustomQ)
			.then(data => {
				// console.log("2-2.新增完成")
				this.setState({ newCustomQ: [] }, () => this.updateApi());
			})
			.catch(error => {
				window.app.alert.setMessage("儲存失敗 請稍後再試", "error");
				this.setState({
					sending: false
				});
			});
	};

	updateApi = () => {
		let { updateCustomQ } = this.state;

		this.API.updateCustomQuestion(updateCustomQ)
			.then(data => {
				// console.log("3-2.更新完成")
				this.setState(
					{
						updateCustomQ: [],
						sending: false
					},
					() => this.loadSetting("update")
				);
				// this.loadSetting()
			})
			.catch(error => {
				window.app.alert.setMessage("儲存失敗 請稍後再試", "error");
				this.setState({
					sending: false
				});
			});
	};

	render() {
		if (!this.state.init) {
			return <div className='pageLoading'>讀取中..</div>;
		}
		return (
			<>
				<div className='main overflow'>
					{this.state.sending && (
						<div className='lightBoxLayer'>
							<h4>儲存中</h4>
						</div>
					)}
					<div style={{ display: "flex" }}>
						<div style={{ flexGrow: 1 }}>
							<h3>自訂預約問卷</h3>
							<h5>您可以設定預約問卷的題目，讓顧客在線上預約時回答。</h5>
						</div>
					</div>
					<hr />
					<div>
						<button
							className='msBtn'
							onClick={() => this.addQuestion()}>
							＋ 新增題目
						</button>
						<br />
						<hr />
					</div>
					<div className='customQuestion'>{this.renderCustomQ()}</div>
					{!this.state.isHiddenModal && (
						<CustomQuestionModal
							customQSetting={JSON.parse(this.state.customQSetting)}
							closeModal={this.closeModal}
							handleModalSubmit={this.handleModalSubmit}
							ref={ref => {
								this.questionModal = ref;
							}}
						/>
					)}
				</div>

				<div className='fix_bottom'>
          <button
            className='btn_submit'
            onClick={this.submit}
          >
            儲存
          </button>
        </div>
			</>
		);
	}
}

class QTypeBlock extends Component {
	renderQType = () => {
		let { detail } = this.props;
		let multipleTxt = detail.multiple_selected ? "（複選）" : "";

		switch (detail.question_type) {
			case QUANTITY:
				return `數量統計${multipleTxt}`;
			case TAG:
				return `選擇標籤${multipleTxt}`;
			case QUESTION:
				return `問答${multipleTxt}`;
			default:
				break;
		}
	};

	renderOption = () => {
		let { detail } = this.props;
		let newDetail = JSON.parse(detail.content);

		if (detail.question_type === QUANTITY) {
			return (
				<div className='settingTblRow quantity_tag'>
					<div className='header'>設定選項</div>
					<div className='text'>
						{Object.keys(newDetail).map((c, index) => {
							return (
								<QTag
									key={c}
									name={newDetail[c].name}
									min={newDetail[c].min}
									max={newDetail[c].max}
								/>
							);
						})}
					</div>
				</div>
			);
		} else if (detail.question_type === TAG) {
			return (
				<div className='settingTblRow'>
					<div className='header'>設定選項</div>
					<div className='text'>
						{Object.keys(newDetail).map((c, index) => {
							return <QTag key={c} name={newDetail[c].name} />;
						})}
					</div>
				</div>
			);
		}
	};

	renderMoveIcon = (index, last) => {
		if (index === 0) {
			return (
				<a
					className='msLink'
					onClick={() => this.props.move(index, "down")}>
					<img
						style={{ height: "20px" }}
						src={require("../../images/queue/down_icon.png")}
						alt='MoveDown'
					/>
				</a>
			);
		} else if (last) {
			return (
				<a
					className='msLink'
					onClick={() => this.props.move(index, "up")}>
					<img
						style={{ height: "20px" }}
						src={require("../../images/queue/up_icon.png")}
						alt='MoveUp'
					/>
				</a>
			);
		} else {
			return (
				<span>
					<a
						className='msLink'
						onClick={() => this.props.move(index, "up")}>
						<img
							style={{ height: "20px" }}
							src={require("../../images/queue/up_icon.png")}
							alt='MoveUp'
						/>
					</a>
					<a
						className='msLink'
						onClick={() => this.props.move(index, "down")}>
						<img
							style={{ height: "20px" }}
							src={require("../../images/queue/down_icon.png")}
							alt='MoveDown'
						/>
					</a>
				</span>
			);
		}
	};

	render() {
		let { index, detail, last } = this.props;
		return (
			<section
				className={classNames("cqBlock", {
					closeBlock: !detail.is_available
				})}>
				<div className='cqTitle'>
					<div className='cqTitle__cell'>
						<img
							src={require("../../images/service_mark.png")}
							alt=''
						/>
					</div>
					<div className='cqTitle__cell rangeTitle'>
						<h4>
							{index + 1}：{detail.title}
							{detail.id && detail.id !== -1 ? (
								<span> (已發佈)</span>
							) : (
								""
							)}
						</h4>
					</div>
					<div className='cqTitle__cell'>
						<a
							className='msLink'
							onClick={() => this.props.modify(index)}>
							<img
								style={{ height: "20px" }}
								src={require("../../images/queue/pen_icon.png")}
								alt='Edit'
							/>
						</a>
						<a
							className='msLink'
							onClick={() =>
								this.props.deleteList(index, detail.id)
							}>
							<img
								style={{ height: "20px" }}
								src={require("../../images/queue/trashcan_icon.png")}
								alt='Delete'
							/>
						</a>
						{this.renderMoveIcon(index, last)}
					</div>
				</div>
				<div className='settingTbl cq-content'>
					<div className='settingTblRow'>
						<div className='header'>題目類型</div>
						<div className='text'>{this.renderQType()}</div>
					</div>
					{this.renderOption()}
					<div className='settingTblRow'>
						<div className='header'>
							必填與否
							<i
								className='fa fa-question-circle-o'
								aria-hidden='true'
								data-toggle='tooltip'
								data-placement='bottom'
								title='如為必填，顧客必須填寫回答才能建立預約'
							/>
						</div>
						<div className='text'>
							{detail.required ? "必填" : "選填"}
						</div>
					</div>
					<div className='settingTblRow'>
						<div className='header'>
							開放狀態
							<i
								className='fa fa-question-circle-o'
								aria-hidden='true'
								data-toggle='tooltip'
								data-placement='bottom'
								title='題目是否於線上預約及自建預約中啟用顯示'
							/>
						</div>
						<div className='text'>
							{detail.is_available ? "開啟" : "關閉"}
						</div>
					</div>
				</div>
			</section>
		);
	}
}

class CustomQuestionModal extends Component {
	constructor(props) {
		super(props);
		let { customQSetting } = this.props;
		this.state = {
			customQSetting: customQSetting,
			contentArray_quantity: [],
			contentArray_tag: [],
			contentArray_question: [],
			reRender: false,
			modifyTag: JSON.parse(customQSetting.content)
		};
	}

	componentDidMount() {
		this.sortOption();
		$('[data-toggle="tooltip"]').tooltip();
	}
	componentDidUpdate() {
		$('[data-toggle="tooltip"]').tooltip();
	}

	sortOption = () => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag
		} = this.state;
		if (!customQSetting.id) {
			let contentObject = JSON.parse(customQSetting.content);

			if (customQSetting.question_type === TAG) {
				Object.keys(contentObject).map(c => {
					contentArray_tag.push(contentObject[c]);
				});
			} else if (customQSetting.question_type === QUANTITY) {
				Object.keys(contentObject).map(c => {
					contentArray_quantity.push(contentObject[c]);
				});
			}
			this.setState({ contentArray_tag, contentArray_quantity });
		}
	};

	show = () => {
		$(this.refs.modal).modal({ show: true, backdrop: "static" });
	};

	hide = () => {
		$(this.refs.modal).modal("hide");
	};

	handalQuestionTypeChange = e => {
		let { customQSetting } = this.state;
		let type = e.target.value;

		customQSetting["question_type"] = type;
		this.setState({ customQSetting });
	};

	addDefaultSelect = type => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;

		if (type === TAG) {
			if (customQSetting.id) {
				modifyTag[maxIndex + 1] = JSON.parse(defaultTag);
			} else {
				contentArray_tag.push(JSON.parse(defaultTag));
			}
		} else if (type === QUANTITY) {
			if (customQSetting.id) {
				modifyTag[maxIndex + 1] = JSON.parse(defaultSelect);
			} else {
				contentArray_quantity.push(JSON.parse(defaultSelect));
			}
		}
		this.setState({ contentArray_quantity, contentArray_tag, modifyTag });
	};

	del = (id, type) => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		if (customQSetting.id) {
			//刪除既有題目的選項
			delete modifyTag[id];
		} else {
			if (type === TAG) {
				contentArray_tag.splice(id, 1);
			} else if (type === QUANTITY) {
				contentArray_quantity.splice(id, 1);
			}
		}

		this.setState({ contentArray_quantity, contentArray_tag, modifyTag });
	};

	handleDefaultSetting = (e, id, type) => {
		let {
			contentArray_quantity,
			contentArray_tag,
			contentArray_question,
			customQSetting,
			modifyTag
		} = this.state;
		let attributeName = e.target.getAttribute("name");
		let txt = e.target.value;

		if (customQSetting.id) {
			if (attributeName === "min") {
				modifyTag[id]["min"] = parseInt(txt, 10);
			} else if (attributeName === "max") {
				modifyTag[id]["max"] = parseInt(txt, 10);
			} else if (attributeName === "name") {
				modifyTag[id]["name"] = txt;
			}
		} else {
			if (attributeName === "name") {
				if (type === TAG) {
					contentArray_tag[id]["name"] = txt;
				} else if (type === QUANTITY) {
					contentArray_quantity[id]["name"] = txt;
				}
			} else if (attributeName === "min") {
				contentArray_quantity[id]["min"] = parseInt(txt, 10);
			} else if (attributeName === "max") {
				contentArray_quantity[id]["max"] = parseInt(txt, 10);
			}
		}

		this.setState({
			contentArray_quantity,
			contentArray_tag,
			contentArray_question,
			modifyTag
		});
	};

	renderQType = () => {
		let { customQSetting } = this.state;

		if (customQSetting.id) {
			// 已儲存題目不能修改類型
			return <div>{qTypeMap[customQSetting.question_type]}</div>;
		} else {
			// 新增的題目
			return (
				<select
					value={customQSetting.question_type}
					onChange={e => this.handalQuestionTypeChange(e)}>
					<option value={QUESTION}>問答</option>
					<option value={TAG}>選擇</option>
					<option value={QUANTITY}>數量統計</option>
				</select>
			);
		}
	};

	renderQuestonType = () => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		let answerOption = null;
		if (customQSetting.id) {
			answerOption = modifyTag;
		} else {
			if (customQSetting.question_type === QUANTITY) {
				answerOption = contentArray_quantity;
			} else if (customQSetting.question_type === TAG) {
				answerOption = contentArray_tag;
			}
		}
		// console.log("modifyTag---", modifyTag)

		switch (customQSetting.question_type) {
			case QUESTION:
				return (
					<QTypeThree
						customQSetting={customQSetting}
						handleModeSettingChange={this.handleModeSettingChange}
					/>
				);
			case TAG:
				return (
					<QTypeTwo
						customQSetting={customQSetting}
						content={answerOption}
						addDefaultSelect={this.addDefaultSelect}
						del={this.del}
						handleModeSettingChange={this.handleModeSettingChange}
					/>
				);
			case QUANTITY:
				return (
					<QTypeOne
						customQSetting={customQSetting}
						content={answerOption}
						addDefaultSelect={this.addDefaultSelect}
						del={this.del}
						handleDefaultSetting={this.handleDefaultSetting}
						handleModeSettingChange={this.handleModeSettingChange}
					/>
				);
			default:
				break;
		}
	};

	handleModeSettingChange = e => {
		let { customQSetting } = this.state;
		switch (e.target.getAttribute("name")) {
			case "required":
				customQSetting["required"] = !customQSetting["required"];
				this.setState({ customQSetting });
				break;
			case "is_available":
				customQSetting["is_available"] = !customQSetting[
					"is_available"
				];
				this.setState({ customQSetting });
				break;
			case "multiple_selected":
				customQSetting["multiple_selected"] = !customQSetting[
					"multiple_selected"
				];
				this.setState({ customQSetting });
				break;
			case "title":
				let titleTxt = e.target.value;
				customQSetting["title"] = titleTxt;
				this.setState({ customQSetting });
				break;
			default:
				break;
		}
	};

	check = () => {
		let {
			customQSetting,
			reRender,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		let content = {};
		let index = null;

		// console.log("contentArray_quantity:::", contentArray_quantity)
		// console.log("contentArray_tag:::", contentArray_tag)
		// console.log("修改modifyTag---", modifyTag)

		if (customQSetting.question_type === QUANTITY) {
			if (customQSetting.id) {
				index = Object.keys(modifyTag)
					.map(t => {
						return modifyTag[t].name.trim();
					})
					.indexOf("");

				Object.keys(modifyTag).map(option => {
					if (
						parseInt(modifyTag[option].min, 10) >
						parseInt(modifyTag[option].max, 10)
					) {
						let min = parseInt(modifyTag[option].max, 10),
							max = parseInt(modifyTag[option].min, 10);

						modifyTag[option].max = max;
						modifyTag[option].min = min;
					}
					return true;
				});
			} else {
				index = contentArray_quantity
					.map(t => {
						return t.name.trim();
					})
					.indexOf("");
				contentArray_quantity.map(option => {
					if (option.min > option.max) {
						let min = parseInt(option.max, 10),
							max = parseInt(option.min, 10);

						option.max = max;
						option.min = min;
					}
					return true;
				});
			}
		} else if (customQSetting.question_type === TAG) {
			if (customQSetting.id) {
				index = Object.keys(modifyTag)
					.map(t => {
						return modifyTag[t].name;
					})
					.indexOf("請填寫");
			} else {
				index = contentArray_tag
					.map(t => {
						return t.name;
					})
					.indexOf("請填寫");
			}
		} else if (customQSetting.question_type === QUESTION) {
			index = -1;
		}

		if (customQSetting.title.trim() === "") {
			emptyStyle = true;
			this.setState({ reRender: !reRender });
		}

		if (index > -1) {
			optionStyle = true;
			this.setState({ reRender: !reRender });
		}

		if (index === -1 && customQSetting.title.trim() !== "") {
			if (customQSetting.id) {
				customQSetting["content"] = JSON.stringify(modifyTag);
			} else {
				if (customQSetting.question_type === QUANTITY) {
					contentArray_quantity.map((c, id) => {
						content[id] = c;
						return content;
					});
				} else if (customQSetting.question_type === TAG) {
					contentArray_tag.map((c, id) => {
						content[id] = c;
						return content;
					});
				} else if (customQSetting.question_type === QUESTION) {
					content = {};
				}

				customQSetting["content"] = JSON.stringify(content);
			}

			// console.log("customQSetting::", customQSetting['content'])
			maxIndex = 0;
			this.setState(
				{
					customQSetting,
					contentArray_quantity: [],
					contentArray_tag: [],
					contentArray_question: []
				},
				() => this.props.handleModalSubmit(customQSetting)
			);
		}
	};

	renderFooterBtn = () => {
		let {
			customQSetting,
			contentArray_quantity,
			contentArray_tag,
			modifyTag
		} = this.state;
		let disableBtn = true;

		if (customQSetting.id) {
			if (
				Object.keys(modifyTag).length === 0 &&
				customQSetting.question_type !== QUESTION
			) {
				disableBtn = true;
			} else {
				disableBtn = false;
			}
		} else {
			switch (customQSetting.question_type) {
				case QUANTITY:
					if (contentArray_quantity.length === 0) {
						disableBtn = true;
					} else {
						disableBtn = false;
					}
					break;
				case TAG:
					if (contentArray_tag.length === 0) {
						disableBtn = true;
					} else {
						disableBtn = false;
					}
					break;
				case QUESTION:
					disableBtn = false;
					break;
				default:
					break;
			}
		}

		return (
			<button
				disabled={disableBtn}
				style={{ padding: "0 55px" }}
				type='button'
				onClick={() => this.check()}
				className={
					"msBtn msBtnMain" + (disableBtn ? " disabledFooterBtn" : "")
				}>
				確認
			</button>
		);
	};

	render() {
		return (
			<div className='modal fade timeSetting cqModal' ref='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<button
								type='button'
								className='close'
								data-dismiss='modal'
								aria-label='Close'
								onClick={() => this.props.closeModal()}>
								<span aria-hidden='true'>×</span>
							</button>
							<h4>編輯設定</h4>
						</div>
						<div className='modal-body'>
							<div className='modalRow'>
								<div className='modalRow__title'>題目類型</div>
								<div className='modalRow__value'>
									{this.renderQType()}
								</div>
							</div>
							{this.renderQuestonType()}
						</div>
						<div className='modal-footer'>
							{this.renderFooterBtn()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
class QTypeOne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: this.props.content,
			customQSetting: this.props.customQSetting
		};

		optionStyle = false;
		emptyStyle = false;
	}
	componentDidMount() {
		$('[data-toggle="tooltip"]').tooltip();
	}

	autoGrow = e => {
		e.target.style.height = e.target.scrollHeight + "px";
	};

	renderNoteTxt = () => {
		let { customQSetting } = this.state;

		if (customQSetting.id && customQSetting.id !== -1) {
			return (
				<p className='note'>
					<i
						className='fa fa-exclamation-circle'
						aria-hidden='true'
					/>
					若您刪除了已經儲存的選項，將會同時刪除已建立預約中所選取的這項選項，建議您在修改前先匯出預約記錄
				</p>
			);
		}
	};

	renderNumOption = () => {
		let option = [];
		for (let i = 1; i < 100; i++) {
			option.push(i);
		}

		return option.map(o => {
			return (
				<option key={o} value={o}>
					{o}
				</option>
			);
		});
	};

	renderOptionTitle = (selection, id) => {
		if (!selection.newOption) {
			//既有的題目“舊”選項不能修改選項題目
			return (
				<input
					type='text'
					maxLength="20"
					className={
						"disableInputStyle" +
						(optionStyle && selection.name.trim() === ""
							? "emptyStyle"
							: "")
					}
					placeholder='標籤字數最多不可超過二十字'
					value={selection.name}
					name='name'
					disabled='disabled'
				/>
			);
		} else {
			//既有的題目“新”選項能修改選項題目
			//新增的題目選項皆可修改題目
			return (
				<input
					type='text'
					maxLength="20"
					className={
						optionStyle && selection.name.trim() === ""
							? "emptyStyle"
							: ""
					}
					placeholder='標籤字數最多不可超過二十字'
					value={selection.name}
					name='name'
					onChange={e =>
						this.props.handleDefaultSetting(e, id, QUANTITY)
					}
				/>
			);
		}
	};

	renderTagRow = () => {
		let { content, customQSetting } = this.state;
		if (customQSetting.id) {
			//既有的題目{}
			return Object.keys(content).map((select, id) => {
				//maxIndex儲存最大index已供新增選項時使用
				//check()的時候maxIndx = 0
				if (maxIndex < parseInt(select, 10)) {
					maxIndex = parseInt(select, 10);
				}
				return (
					<div key={id} className='tagRow'>
						<div>
							{this.renderOptionTitle(content[select], select)}
						</div>
						<div>
							<span>
								數量
								<i
									className='fa fa-question-circle-o'
									aria-hidden='true'
									data-toggle='tooltip'
									data-placement='bottom'
									title='顧客可選擇的數量範圍'
								/>
							</span>
							<select
								value={content[select].min}
								name='min'
								onChange={e =>
									this.props.handleDefaultSetting(
										e,
										select,
										QUANTITY
									)
								}>
								{this.renderNumOption()}
							</select>
							<span>~</span>
							<select
								value={content[select].max}
								name='max'
								onChange={e =>
									this.props.handleDefaultSetting(
										e,
										select,
										QUANTITY
									)
								}>
								{this.renderNumOption()}
							</select>
						</div>
						<div>
							<a onClick={() => this.props.del(select, QUANTITY)}>
								<i
									className='fa fa-trash-o'
									aria-hidden='true'
								/>
							</a>
						</div>
					</div>
				);
			});
		} else {
			//新的題目[]
			return content.map((select, id) => {
				return (
					<div key={id} className='tagRow'>
						<div>{this.renderOptionTitle(select, id)}</div>
						<div>
							<span>
								數量
								<i
									className='fa fa-question-circle-o'
									aria-hidden='true'
									data-toggle='tooltip'
									data-placement='bottom'
									title='顧客可選擇的數量範圍'
								/>
							</span>
							<select
								value={select.min}
								name='min'
								onChange={e =>
									this.props.handleDefaultSetting(
										e,
										id,
										QUANTITY
									)
								}>
								{this.renderNumOption()}
							</select>
							<span>~</span>
							<select
								value={select.max}
								name='max'
								onChange={e =>
									this.props.handleDefaultSetting(
										e,
										id,
										QUANTITY
									)
								}>
								{this.renderNumOption()}
							</select>
						</div>
						<div>
							<a onClick={() => this.props.del(id, QUANTITY)}>
								<i
									className='fa fa-trash-o'
									aria-hidden='true'
								/>
							</a>
						</div>
					</div>
				);
			});
		}
	};

	render() {
		let { customQSetting } = this.state;
		return (
			<div>
				<div className='modalRow'>
					<div className='modalRow__title'>題目標題</div>
					<div className='modalRow__value'>
						<textarea
							className={
								emptyStyle && customQSetting.title.trim() === ""
									? "emptyStyle"
									: ""
							}
							name='title'
							value={customQSetting.title}
							placeholder='輸入題目'
							onKeyUp={e => this.autoGrow(e)}
							onChange={e =>
								this.props.handleModeSettingChange(e)
							}
						/>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>設定標籤</div>
					<div className='modalRow__value'>
						<a
							className='msBtn'
							onClick={() =>
								this.props.addDefaultSelect(QUANTITY)
							}>
							+ 新增選項
						</a>
						<div className='tag-container'>
							{this.renderTagRow()}
							{this.renderNoteTxt()}
						</div>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>
						必填與否
						<i
							className='fa fa-question-circle-o'
							aria-hidden='true'
							data-toggle='tooltip'
							data-placement='bottom'
							title='如為必填，顧客必須填寫回答才能建立預約'
						/>
					</div>
					<div className='modalRow__value'>
						<label className='requireLable'>
							<input
								type='radio'
								checked={customQSetting.required}
								name='required'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>必填</span>
						</label>
						<label className='requireLable'>
							<input
								type='radio'
								checked={!customQSetting.required}
								name='required'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>選填</span>
						</label>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>
						單/複選
						<i
							className='fa fa-question-circle-o'
							aria-hidden='true'
							data-toggle='tooltip'
							data-placement='bottom'
							title='顧客可選擇一項或多項標記'
						/>
					</div>
					<div className='modalRow__value'>
						<label className='requireLable'>
							<input
								type='radio'
								checked={!customQSetting.multiple_selected}
								name='multiple_selected'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>單選</span>
						</label>
						<label className='requireLable'>
							<input
								type='radio'
								checked={customQSetting.multiple_selected}
								name='multiple_selected'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>複選</span>
						</label>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>
						開放狀態
						<i
							className='fa fa-question-circle-o'
							aria-hidden='true'
							data-toggle='tooltip'
							data-placement='bottom'
							title='題目是否於線上預約及自建預約中啟用顯示'
						/>
					</div>
					<div className='modalRow__value'>
						<label className='msSwitch'>
							<input
								type='checkbox'
								name='is_available'
								checked={customQSetting.is_available}
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span className='slider' />
						</label>
					</div>
				</div>
			</div>
		);
	}
}

class QTypeTwo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultTagArray: this.props.content,
			customQSetting: this.props.customQSetting
		};
		optionStyle = false;
		emptyStyle = false;
	}
	componentDidMount() {
		$('[data-toggle="tooltip"]').tooltip();
		this.qeditable();
	}

	componentDidUpdate() {
		this.qeditable();
	}

	autoGrow = e => {
		e.target.style.height = e.target.scrollHeight + "px";
	};

	qeditable = () => {
		let { defaultTagArray } = this.state;
		let that = this;

		$(".tagEditable").editable({
			highlight: false,
			validate: function(value) {
				if (!$.trim(value)) {
					return "不能為空";
				}
			},
			success: function(response, newValue) {
				let { idx } = this.dataset;

				defaultTagArray[idx]["name"] = newValue;

				// $(this).editable('disable').editable('enable')
				that.setState({ defaultTagArray });
			}
		});

		$(".tagEditable").on("shown", function(e, editable) {
			e.target.previousSibling.classList.add("show");

			if (e.target.innerText !== "請填寫") {
				editable.input.$input.val(e.target.innerText);
			} else {
				editable.input.$input.val("");
			}
		});

		$(".tagEditable").on("hidden", function(e) {
			e.target.previousSibling.classList.remove("show");
		});
	};

	renderNoteTxt = () => {
		let { customQSetting } = this.state;

		if (customQSetting.id && customQSetting.id !== -1) {
			return (
				<p className='note'>
					<i
						className='fa fa-exclamation-circle'
						aria-hidden='true'
					/>
					若您刪除了已經儲存的選項，將會同時刪除已建立預約中所選取的這項選項，建議您在修改前先匯出預約記錄
				</p>
			);
		}
	};

	renderTagRow = () => {
		let { customQSetting, defaultTagArray } = this.state;
		if (customQSetting.id) {
			return (
				//既有題目{}
				Object.keys(defaultTagArray).map(tag => {
					//maxIndex儲存最大index已供新增選項時使用
					//check()的時候maxIndx = 0
					if (maxIndex < parseInt(tag, 10)) {
						maxIndex = parseInt(tag, 10);
					}
					if (defaultTagArray[tag]["newOption"]) {
						//既有題目“新增”的選項：可修改
						if (defaultTagArray[tag]["name"] === "請填寫") {
							//尚未輸入選項時顯示紅色"empty"
							return (
								<div key={tag} className='menuTagBtn empty'>
									<div
										className='deleteBtn'
										onClick={() => this.props.del(tag, TAG)}
									/>
									<div
										data-idx={tag}
										className='tagTxt tagEditable'>
										{defaultTagArray[tag].name}
									</div>
								</div>
							);
						} else {
							//已輸入選項
							return (
								<div key={tag} className='menuTagBtn'>
									<div
										className='deleteBtn'
										onClick={() => this.props.del(tag, TAG)}
									/>
									<div
										data-idx={tag}
										className='tagTxt tagEditable'>
										{defaultTagArray[tag].name}
									</div>
								</div>
							);
						}
					} else {
						//既有題目的“舊”選項：不可修改
						return (
							<div key={tag} className='menuTagBtn disableTagBtn'>
								<div
									className='deleteBtn'
									onClick={() => this.props.del(tag, TAG)}
								/>
								<div className='tagTxt'>
									{defaultTagArray[tag].name}
								</div>
							</div>
						);
					}
				})
			);
		} else {
			return (
				//新增的題目[]：選項皆可修改
				defaultTagArray.map((tag, id) => {
					if (tag.name === "請填寫") {
						return (
							<div key={id} className='menuTagBtn empty'>
								<div
									className='deleteBtn'
									onClick={() => this.props.del(id, "tag")}
								/>
								<div
									data-idx={id}
									className='tagTxt tagEditable'>
									{tag.name}
								</div>
							</div>
						);
					} else {
						return (
							<div key={id} className='menuTagBtn'>
								<div
									className='deleteBtn'
									onClick={() => this.props.del(id, TAG)}
								/>
								<div
									data-idx={id}
									className='tagTxt tagEditable'>
									{tag.name}
								</div>
							</div>
						);
					}
				})
			);
		}
	};

	render() {
		let { customQSetting } = this.state;
		return (
			<div>
				<div className='modalRow'>
					<div className='modalRow__title'>題目標題</div>
					<div className='modalRow__value'>
						<textarea
							name='title'
							className={
								emptyStyle && customQSetting.title.trim() === ""
									? "emptyStyle"
									: ""
							}
							value={customQSetting.title}
							placeholder='輸入題目'
							onKeyUp={e => this.autoGrow(e)}
							onChange={e =>
								this.props.handleModeSettingChange(e)
							}
						/>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>設定標籤</div>
					<div className='modalRow__value'>
						<a
							className='msBtn'
							onClick={() => this.props.addDefaultSelect(TAG)}>
							+ 新增標籤
						</a>
						<div className='qcTag-container'>
							{this.renderTagRow()}
							{this.renderNoteTxt()}
						</div>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>
						必填與否
						<i
							className='fa fa-question-circle-o'
							aria-hidden='true'
							data-toggle='tooltip'
							data-placement='bottom'
							title='如為必填，顧客必須填寫回答才能建立預約'
						/>
					</div>
					<div className='modalRow__value'>
						<label className='requireLable'>
							<input
								type='radio'
								checked={customQSetting.required}
								name='required'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>必填</span>
						</label>
						<label className='requireLable'>
							<input
								type='radio'
								checked={!customQSetting.required}
								name='required'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>選填</span>
						</label>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>
						單/複選
						<i
							className='fa fa-question-circle-o'
							aria-hidden='true'
							data-toggle='tooltip'
							data-placement='bottom'
							title='顧客可選擇一項或多項標記'
						/>
					</div>
					<div className='modalRow__value'>
						<label className='requireLable'>
							<input
								type='radio'
								checked={!customQSetting.multiple_selected}
								name='multiple_selected'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>單選</span>
						</label>
						<label className='requireLable'>
							<input
								type='radio'
								checked={customQSetting.multiple_selected}
								name='multiple_selected'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>複選</span>
						</label>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>
						開放狀態
						<i
							className='fa fa-question-circle-o'
							aria-hidden='true'
							data-toggle='tooltip'
							data-placement='bottom'
							title='題目是否於線上預約及自建預約中啟用顯示'
						/>
					</div>
					<div className='modalRow__value'>
						<label className='msSwitch'>
							<input
								type='checkbox'
								name='is_available'
								checked={customQSetting.is_available}
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span className='slider' />
						</label>
					</div>
				</div>
			</div>
		);
	}
}

class QTypeThree extends Component {
	componentDidMount() {
		$('[data-toggle="tooltip"]').tooltip();
	}

	autoGrow = e => {
		e.target.style.height = e.target.scrollHeight + "px";
	};

	render() {
		let { customQSetting } = this.props;
		return (
			<div>
				<div className='modalRow'>
					<div className='modalRow__title'>題目標題</div>
					<div className='modalRow__value'>
						<textarea
							name='title'
							className={
								emptyStyle && customQSetting.title.trim() === ""
									? "emptyStyle"
									: ""
							}
							value={customQSetting.title}
							placeholder='輸入題目'
							onKeyUp={e => this.autoGrow(e)}
							onChange={e =>
								this.props.handleModeSettingChange(e)
							}
						/>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>
						必填與否
						<i
							className='fa fa-question-circle-o'
							aria-hidden='true'
							data-toggle='tooltip'
							data-placement='bottom'
							title='如為必填，顧客必須填寫回答才能建立預約'
						/>
					</div>
					<div className='modalRow__value'>
						<label className='requireLable'>
							<input
								type='radio'
								checked={customQSetting.required}
								name='required'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>必填</span>
						</label>
						<label className='requireLable'>
							<input
								type='radio'
								checked={!customQSetting.required}
								name='required'
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span>選填</span>
						</label>
					</div>
				</div>
				<div className='modalRow'>
					<div className='modalRow__title'>
						開放狀態
						<i
							className='fa fa-question-circle-o'
							aria-hidden='true'
							data-toggle='tooltip'
							data-placement='bottom'
							title='題目是否於線上預約及自建預約中啟用顯示'
						/>
					</div>
					<div className='modalRow__value'>
						<label className='msSwitch'>
							<input
								type='checkbox'
								name='is_available'
								checked={customQSetting.is_available}
								onChange={e =>
									this.props.handleModeSettingChange(e)
								}
							/>
							<span className='slider' />
						</label>
					</div>
				</div>
			</div>
		);
	}
}

const QTag = props => {
	let min = props.min === undefined ? "" : `(${props.min}`,
		max = props.max === undefined ? "" : `~${props.max})`;

	return (
		<div className='menuTagBtn'>
			{props.name}
			{min}
			{max}
		</div>
	);
};
