export default[
    '4225-倉儲服務',
    '4722-旅行社',
    '4812-電話通訊設備及服務',
    '4899-有線電視',
    '5045-3C 商品',
    '5094-寶石/黃金/珠寶貴重物',
    '5192-書報雜誌',
    '5261-園藝用品',
    '5399-一般商品買賣',
    '5422-冷凍食品',
    '5462-西點麵包',
    '5499-食品名特產',
    '5699-服飾配件',
    '5732-電器行',
    '5812-餐廳',
    '5941-運動商品',
    '5946-攝影用品',
    '5963-直銷',
    '5977-化妝/美容保養產品',
    '5992-花店',
    '5995-寵物用品',
    '7011-飯店/民宿',
    '7261-喪葬服務及用品',
    '7298-美容美體服務',
    '7311-廣告服務',
    '7372-網路資訊服務',
    '7392-諮詢服務',
    '7519-休閒交通工具租借',
    '7996-樂區 / 博覽會',
    '7999-娛樂休閒服務',
    '8220-學校',
    '8299-補習/教學服務',
    '8398-社會福利團體',
    '8651-政治團體',
    '8661-宗教團體',
    '8999-其他專業服務'
]