import React, { useContext } from 'react';

import { MessageContext } from '../crmContext/MessageContext';

const CrmMessageEmpty = ({ messageStatus }) => {
  const { MessageState } = useContext(MessageContext);
  const { searchMode } = MessageState;

  const searchMessageEmpty = () => {
    return <div className='crmMessageEmpty_content'>沒有符合搜尋條件的訊息。</div>
  }

  const messageEmptyContent = () => {
    if(messageStatus === 'sent') {
      return (
        <div className="crmMessageEmpty_content">
          尚未傳送訊息給顧客， <br />
          您傳送給顧客的訊息記錄將顯示於寄件備份。
        </div>
      )
    } else if (messageStatus === 'draft') {
      return (
        <div className="crmMessageEmpty_content">
          您目前沒有草稿內容。 <br />
          未完成傳送的訊息內容將顯示於此。
        </div>
      )
    } else if (messageStatus === 'scheduling') {
      return (
        <div className="crmMessageEmpty_content">
          您目前沒有排程訊息。 <br />
          排程的訊息內容將顯示於此。
        </div>
      )
    }
  }
  
  return (
    <div className="crmMessageEmpty">
      { searchMode ? searchMessageEmpty() : messageEmptyContent() }
    </div>
  )
}

export default CrmMessageEmpty;
