import { useCallback } from 'react';

import BookingSystemApi from '../../booking_settings/BookingSystemApi';

const API = new BookingSystemApi();

export default (newModuleBooking) => {
	return useCallback(
		(id, dataSubmit) => {
			API.updateBooking(id, dataSubmit)
				.then(() => {
					newModuleBooking();
				})
				.catch(() => {
					window.app.alert.setMessage('請重新再試一次', 'error');
				});
		},
		[ newModuleBooking ]
	);
};
