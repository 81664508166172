import React from 'react';
import PageNav from './PageNav';
import PageFooter from './PageFooter';

import '../../sass/pageTOSandPrivacyPolicy.scss';

const PagePrivacyPolicy = () => {
  return (
    <div className='pagePrivacyPolicy'>
      <PageNav />
      
      <div className='page_container'>
        <h1>隱私權保護條款</h1>
        <h2>MENU店+ 找活股份有限公司 隱私權保護條款</h2>
        <section>
          <p>本隱私權政策所稱之「本系統」，係指MENU店+所有者，即找活股份有限公司（以下簡稱「找活」），所提供之訂位、候位系統及線上訂位網站。</p>
          <p>為保障您使用本系統之隱私權及相關權利，找活將遵循《中華民國個人資料保護法》之要求，保護及尊重您的個人隱私。</p>
          <p>依照《個人資料保護法》第8條規定，本系統之隱私權保護條款（以下簡稱「本條款」）將幫助您了解我們如何蒐集、處理、利用及保護個人資料。一旦您開始使用本系統服務，即表示您已同意找活依照本隱私權政策蒐集、處理、利用及保護您的個人資料。為保障您的權益，請詳閱以下內容：</p>
        </section>
        <section>
          <h4>一、蒐集和使用個人資訊</h4>
          <p>找活依據《個人資料保護法》規定，向您告知以下事項：</p>
          <p>
            蒐集目的：
            <ul className='Privacy_list'>
              <li>控管您在與找活合作餐廳（下稱「合作餐廳」）之訂、候位；於您用餐後進行滿意度調查，並將您的個人資料及上述調查結果由找活或找活指定之第三方進行數據分析，分析結果將用於合作餐廳以及本系統之行銷目的。</li>
              <li>符合找活在遵守法規、業務、財務、稅賦、營運或風險管理方面之需求 （包括但不限於自行或共同行銷、統計調查與分析、內部控制、管理與查核等）。</li>
            </ul>
            （法定特定目的項目編號為：○四○、○六九、○七七、○九○、○九一、○九八、一二九、一三五、一三六、一三七、一五二、一五七、一八一）
          </p>
          <p>
            蒐集之個人資料：
            <ul className='Privacy_list'>
              <li>身分資料，例如：姓名、職稱、住址、工作地址、以前地址、住家電話號碼、行動電話、即時通帳號、網路平台申請之帳號、通訊及戶籍地址、電子郵遞地址、身分證號、統一編號、證照號碼等。</li>
              <li>特徵資料，例如：年齡、性別、出生年月日、出生地、國籍等。</li>
              <li>財務交易資料，例如：收付金額、支付方式、往來紀錄等。</li>
              <li>未分類之資料，例如：用餐意見、無法歸類之信件、檔案、報告或電子郵件。</li>
            </ul>
          </p>
          <p>（上述資料之類別，法定編號包含：C001辨識個人者、C003政府資料中之辨識者、C011個人描述、C093財務交易、C132未分類之資料）</p>
        </section>
        <section>
          <h4>二、個人資料利用之對象、方式</h4>
          <p>找活依據《個人資料保護法》規定，向您告知以下事項：</p>
          <ul className='Privacy_list'>
            <li>個人資料將用於本系統之合作餐廳訂位、候位功能（下稱「本系統服務」），當您向合作餐廳訂位完成後，您同意合作餐廳將會提供您的個人資料予找活，本系統將會依據您提供之個人資料採取後續措施，包括但不限於：傳送確認簡訊、通知簡訊以及用餐後滿意度調查等，以控管您於合作餐廳之訂、候位情形。</li>
            <li>其他於特定目的與使用類別之範圍內，基於推廣本系統及合作餐廳之業務需求，而由找活或找活指定之第三方以電腦化或其他相類之數據方式處理、利用之行為。</li>
            <li>作為找活指定之第三方各種推播資訊之基礎，包含但不限於透過電子郵件、實體郵件、電話與簡訊等方式提供找活指定之第三方或其關係企業之行銷資訊。</li>
            <li>作為找活指定之第三方或其關係企業優化服務內容之市場調查依據，並得與其內部之管理系統結合、匯入、建檔或以一定之方式備份、留存。</li>
            <li>其他符合《個人資料保護法》第20條規定之利用。</li>
          </ul>
        </section>
        <section>
          <h4>三、個人資料之期限、地區</h4>
          <p>找活依據《個人資料保護法》規定，向您告知以下事項：</p>
          <ul className='Privacy_list'>
            <li>期限：蒐集個人資料特定目的持續期間、依法或依契約約定之資料留存屆滿期限，或找活訂定之屆滿期限，以其中時間較長者為準。</li>
            <li>地區：台灣及任何本系統服務所及之地區，包含日後於台灣以外找活之商業判斷而有必要使用該等個人資料之地區。</li>
          </ul>
        </section>
        <section>
          <h4>四、會員就個人資料之權利</h4>
          <p>依照《個人資料保護法》第3條規定，您得就所提供之個人資料行使下列權利：</p>
          <ul className='Privacy_list'>
            <li>查詢或要求審閱或複製您的個人資料。找活得依法收取必要之成本與費用。</li>
            <li>要求找活補充或更正您的個人資料。行使本項權利時，您應依法提出合理解釋。</li>
            <li>要求找活停止蒐集、處理或使用，或刪除您的個人資料。若您希望行使上述權利，您可將要求寄至：support@findlife.com.tw，找活將根據您的要求處理。</li>
            <li>然於找活尚未查證您為本人前，您不得申請查詢、複製、請求閱覽或申請變動不屬於本人會員之個人資料及帳號內容。</li>
            <li>您可隨時依據前述規定，要求停止蒐集、處理、利用或刪除屬於您的個人資料，然資料一旦經停止蒐集、處理、利用或刪除者，找活有權向您終止本系統服務。</li>
            <li>於妨害國家重大利益、妨害公務機關執行法定職務、妨害蒐集機關或第三人重大利益時，找活有權拒絕您主動提出變更、刪除或異動個人資料之請求。</li>
            <li>您得自由選擇是否由提供個人資料予找活，惟一旦您拒絕提供找活進行本系統服務所需之個人資料，或所提供之個人資料不完整，將導致本系統服務無法執行，找活得拒絕向您提供本系統服務。</li>
            <li>您同意找活有權修訂前述權利行使相關規定，並通知您修正後的內容綱要及指定網頁，通知方式得為口頭、書面，或經由電話、簡訊、電子郵件、傳真、電子文件、辦事處或網站公告，或足以令您知悉或理解修正內容之任何其他方式 （包括但不限於以上述方式提供途徑，進入揭露修正後規定之任何網站）。</li>
          </ul>
        </section>
        <section>
          <h4>五、隱私權保護條款修訂</h4>
          <p>找活將會不時修訂隱私權保護條款。您有義務隨時確認其已審閱最新版本的隱私權保護條款，如法律要求，找活會通知您隱私權保護條款的變更。您如果對於本隱私權保護條款或與個人資料有關之相關事項有任何疑問，可以利用 support@findlife.com.tw 與我們聯絡。</p>
        </section>
      </div>

      <PageFooter />
    </div>
  )
}

export default PagePrivacyPolicy;