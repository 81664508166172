import React, { useContext } from 'react';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import '../../../sass/menu_coupon_popup.scss';

import MenuCouponApi from '../MenuCouponApi';
const menuCouponAPI = new MenuCouponApi();

const DeleteCouponPopup = () => {
    const { CouponState, CouponDispatch } = useContext(CouponStateContext);
    const { couponName, couponId } = CouponState;
    

    const cancelPopup=()=>{
        CouponDispatch({type: 'cancelPopup'})
	}
	
	const confirmPopup = async()=>{
		try {
			await menuCouponAPI.deleteCoupon(couponId);
			CouponDispatch({type: 'confirmDeletePopup'})

		} catch (err) {
			console.log('deleteCoupon err-----', err);
		}
	}

	return (
		<div className="menuCouponPopupCard">
			<div className="menuCouponPopupCard__header">
				<h2>刪除優惠券</h2>
			</div>
			<div className="menuCouponPopupCard__body">
				<p>確定要刪除優惠券嗎？</p>
				<p>優惠券：{couponName}</p>
			</div>
			<div className="menuCouponPopupCard__footer">
				<div className="footrBtnWrap">
					<button className="grayThemeBtn" onClick={()=> cancelPopup()}>取消</button>
					<button className="greenThemeBtn" onClick={()=> confirmPopup()}>確認刪除</button>
				</div>
			</div>
		</div>
	);
};

export default DeleteCouponPopup;
