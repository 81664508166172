export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE"

export function setAlertMessage(message, messageType="done"){
  return (dispatch) => {
    setTimeout(function(){
      dispatch(
        {type: SET_ALERT_MESSAGE,
        message: message,
        messageType: messageType}
      )
    },100)
    return dispatch({
        type: SET_ALERT_MESSAGE,
        message: "",
        messageType: ""
    })
  }
}