import React, { useEffect } from 'react';
import { Link } from 'react-router';
import ScrollReveal from '../../libs/vendor/scrollreveal.min';
import PageNav from './PageNav';
import PageFooter from './PageFooter';
import PageSlogan from './PageSlogan';

import '../../sass/pageCRM.scss';

import crmFeature_cover_device from '../../images/page_CRM/CRM_Empty_Mockup_v2.png';
import crmFeature_cover_1 from '../../images/page_CRM/CRM_Customers_Mockup_v2.png';
import crmFeature_cover_2 from '../../images/page_CRM/CRM_Message_Mockup_v2.png';

import crmFeature_customer from '../../images/page_CRM/CRM_Customers.gif';
import crmFeature_msg from '../../images/page_CRM/CRM_Messages.gif';
import crmFeature_bind from '../../images/page_CRM/picsCrmFeature_1.png';
import crmFeature_push from '../../images/page_CRM/picsCrmFeature_2.png';
import logo from '../../images/menuShop_logo.svg';

const CoverContent = () => {
	return (
		<div className="coverContent">
			<Link to="/contact">
				<button className="q-btn">立即試用</button>
			</Link>
		</div>
	);
};

const PageCRM = () => {
  useEffect(() => {
		window.sr = new ScrollReveal();

    window.sr.reveal('.crmSection_1 h1', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000
		});

    window.sr.reveal('.crmSection_1 h3', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
			delay: 500
		});

    window.sr.reveal('.crmSection_1 .coverContent', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
			delay: 700
		});

    window.sr.reveal('.crmSection_1 .intro_1', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
			delay: 700
		});

    window.sr.reveal('.crmSection_1 img', {
			distance: '350px',
			origin: 'bottom',
			duration: 1500,
			delay: 500
		});

    window.sr.reveal('.crmSection_1 .intro_2', {
			distance: '200px',
			origin: 'right',
			duration: 1500,
      delay: 500
    });

    window.sr.reveal('.crmSection_2 .content, .crmSection_2 img', {
			distance: '350px',
			origin: 'left',
			duration: 1500,
			delay: 500
		});

    window.sr.reveal('.crmSection_3 .content, .crmSection_3 img', {
			distance: '350px',
			origin: 'right',
			duration: 1500,
			delay: 500
		});

    window.sr.reveal('.crmSection_4 .content, .crmSection_4 img', {
			distance: '350px',
			origin: 'left',
			duration: 1500,
			delay: 500
		});

    window.sr.reveal('.crmSection_5 .content, .crmSection_5 img', {
			distance: '350px',
			origin: 'right',
			duration: 1500,
			delay: 500
		});
  }, []);

  return (
    <div className='pageCRM'>
      <PageNav />

      <section className='crmSection_1'>
        <div className='content'>
          <h1>CRM 顧客關係管理系統</h1>
          <h3>建立您的顧客關係，提升營運及滿意度</h3>
          <CoverContent />
          <div className='text_gray intro_1'>將提供您一個月試用服務。</div>
          <div className="cover_container">
            <img className='device_bg' src={crmFeature_cover_device} alt="" />
            <img className='screen_1' src={crmFeature_cover_1} alt="" />
            <img className='screen_2' src={crmFeature_cover_2} alt="" />
          </div>
          <div className='text_gray intro_2'>我們不僅為您紀錄顧客預約，透過 <strong>顧客關係管理系統</strong> 您可以更有效的推播行銷內容給指定顧客，實現顧客滿意度及業績提升。</div>
        </div>
      </section>

      {/* <section className='crmSection_2'>
        <div className='content'>
          <h2>顧客資訊，一目瞭然</h2>
          <h3>為您完整記錄顧客的來店紀錄及喜好</h3>
          <div className="img img_small">
            <figure>
              <img src={crmFeature_customer} alt="" />
            </figure>
          </div>
          <ul>
            <li>為顧客建立標籤備註。紀錄 VIP 顧客，提供專屬的服務內容。</li>
            <li>紀錄顧客的不同分店消費紀錄，檢視顧客喜好分店、來店次數，掌握您的忠誠顧客。</li>
            <li>檢視與該顧客的推播紀錄。最新活動精準傳送給您的目標受眾。</li>
          </ul>
        </div>
        <div className="img">
          <figure>
            <img src={crmFeature_customer} alt="" />
          </figure>
        </div>
      </section> */}

      <section className='crmSection_3'>
        <div className="img">
          <figure>
            <img src={crmFeature_msg} alt="" />
          </figure>
        </div>
        <div className='content'>
          <h2>客製化發送簡訊推播</h2>
          <h3>多方篩選傳送對象，傳送你的最新優惠</h3>
          <div className="img img_small">
            <figure>
              <img src={crmFeature_msg} alt="" />
            </figure>
          </div>
          <ul>
            <li>顧客分類變得更簡單！透過到店時間範圍及次數、性別及標記篩選目標顧客。</li>
            <li>設定顧客篩選條件，將您的最新優惠及活動傳送給您的目標對象。</li>
            <li>不論是立即傳送優惠訊息，或是搭配活動檔期安排指定時程，您的優惠內容將即時傳送給您的顧客。</li>
          </ul>
        </div>
      </section>

      <section className='crmSection_4'>
        <div className='content'>
          <h2>
            綁定 MENU 美食誌 <br />
            連動粉絲資訊
          </h2>
          <h3>在 86 萬用戶社群平台獲得粉絲</h3>
          <div className="img img_small">
            <figure>
              <img src={crmFeature_bind} alt="" />
            </figure>
          </div>
          <ul>
            <li>擔心推播不夠有效？86萬用戶讓你傳送你的最新優惠。</li>
            <li>在最大美食平台分享你的最新活動，觸及更多美食客。</li>
          </ul>
        </div>
        <div className="img">
          <figure>
            <img src={crmFeature_bind} alt="" />
          </figure>
        </div>
      </section>

      <section className='crmSection_5'>
        <div className="img">
          <figure>
            <img src={crmFeature_push} alt="" />
          </figure>
        </div>
        <div className='content'>
          <h2>向美食客發送訊息推播</h2>
          <h3>為你的粉絲獻上最新優惠吧！</h3>
          <div className="img img_small">
            <figure>
              <img src={crmFeature_push} alt="" />
            </figure>
          </div>
          <ul>
            <li>結合文字、圖片、優惠券的客製化推播訊息，讓粉絲更了解你的價值與優勢！</li>
            <li>將優惠券傳送給您的粉絲，優惠精準觸及。</li>
            <li>精準行銷，提升顧客來訪率。</li>
          </ul>
        </div>
      </section>

      <PageSlogan />
      <PageFooter />
    </div>
  )
}

export default PageCRM;