import React, { Component } from 'react';
import _ from 'lodash';
import QueueOptionArea from './QueueOptionArea';
import QueueListItem from './QueueListItem';
import classNames from 'classnames';
import QueuePanelAPI from './api/QueuePanelAPI';
import QueueSystemAPI from '../queue_system_settings/QueueSystemAPI';
import QueueSearchResult from './QueueSearchResult';
import { getModalClose, getModalPause, getModalStart } from './QueuePanelModal';
import { getCurrentDateString, getCurrentChineseDay } from './QueuePanelHelper';
import { notification } from 'antd';
import { handleWebsocketEventRegistry, cancelWebsocketSubscription } from '../../libs/handler';

const Link = require('react-router').Link;

const openNotification = (title, message, backgroundColor, borderColor) => {
	notification.config({
		placement: 'topRight',
		top: 100
	});

	notification.open({
		message: title,
		description: message,
		style: {
			background: backgroundColor,
			borderStyle: 'solid',
			borderWidth: '1px',
			borderColor: borderColor
		}
	});
};

export default class QueuePanel extends Component {
	state = {
		isOpened: false,
		isEmpty: false,
		hideOptionArea: false,
		currentState: 'normal',
		currentQueueID: null,
		currentTag: '1',
		currentSelectTagTitle: '全部',
		currentStartTime: '',
		searchRecords: [],
		keyword: '',
		groupTypes: [],
		totalWaitingCount: 0,
		totalSeatedCount: 0,
		totalStandbyCount: 0,
		totalFinishCount: 0,
		waitingRecords: [],
		seatedRecords: [],
		standbyRecords: [],
		canceledRecords: [],
		customQuestions: [],
		timeCheckingCondition: '',
		smsRemainCount: 0,
		smsNotify: true,
		emailLineUpRemindEnabled: true,
		smsLineUpRemindEnabled: false,
		tabSelected: {
			waitSelected: true,
			seatedSelected: false,
			standbySelected: false
		}
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.resize);
		cancelWebsocketSubscription([ 'sync_frontend' ]);
	}

	resize = () => {
		let w_w = window.innerWidth;
		let optionsArea = document.getElementsByClassName('queue-dashboard-options-area')[0];

		if (!this.setResize && w_w <= 768) {
			this.setResize = true;
			if(optionsArea) optionsArea.style.display = null;
		} else if (this.setResize && w_w > 768) {
			this.setResize = false;
			if(optionsArea) optionsArea.style.display = 'block';
		}
	};

	componentDidMount() {
		this.API = new QueuePanelAPI();
		this.systemAPI = new QueueSystemAPI();

		//排隊列表
		handleWebsocketEventRegistry(
			'sync_frontend',
			function(data) {
				this.getQueueList();
				// this.refreshCurrentContent()
			}.bind(this)
		);

		//開啟&關閉&暫停排隊
		handleWebsocketEventRegistry(
			'sync_queue_groups',
			function(data) {
				// console.log('data-----', data);
				// this.getQueueList();
				this.refreshCurrentContent();
			}.bind(this)
		);
		// this.modal = new QueuePanelModal()

		this.setResize = false;
		window.addEventListener('resize', this.resize);

		// 確認是不是第一次使用！
		/*
        this.systemAPI.getQueueSettings().then(data => {
            if (data.display_name === null || data.display_name === undefined) {
                $('#startQueueModal').modal('toggle')
            }
        }).catch( error => {
            console.log(error)
        })
        })*/

		this.API
			.getListedQueueGroup()
			.then((data) => {
				// 先確認是否已經設定過  Queue Group
				if (data !== undefined && data['queue_groups'].length > 0) {
					this.setState({
						groupTypes: data['queue_groups'],
						currentStartTime: data['start_time'] ? '開始時間:' + data['start_time'] : ''
					});
					return this.API.getQueueRecord('waiting');
				} else {
					this.setState({
						currentState: 'settings'
					});
				}
			})
			.then((data) => {
				// 透過返回的內容來看是否已經開啟新時段
				if (data.queue_records === null) {
					this.setState(
						{
							currentState: 'pause'
						},
						() => {}
					);
				} else if (data.queue_records.length === 0) {
					this.setState(
						{
							currentState: 'empty'
							// currentState: "normal"
						},
						() => {
							this.startUpdateContent();
						}
					);
				} else {
					this.setState(
						{
							currentState: 'normal'
						},
						() => {
							this.startUpdateContent();
						}
					);
				}

				return this.systemAPI.getQueueSettings();
			})
			.then((data) => {
				this.setState({
					timeCheckingCondition: data['time_checking'],
					smsRemainCount: data['msg_point'],
					smsNotify: data['sms_enable'],
					emailLineUpRemindEnabled: data.email_notification_settings.line_up_remind['enabled'],
					smsLineUpRemindEnabled: data.sms_notification_settings.line_up_remind['enabled']
					// customQuestions: data['queue_customized_questions']
				});
			})
			.catch((error) => {
				console.log(error);
				if (error.number === 300) {
					// 沒有 Record
					this.setState({
						currentState: 'pause'
					});
				} else {
					// 沒有 Group
					this.setState({
						currentState: 'settings'
					});
				}
			});
	}

	getQueueList = () => {
		let newTabSelected = Object.assign({}, this.state.tabSelected);
		let activeTab = Object.keys(newTabSelected).filter((tab) => {
			return this.state.tabSelected[tab] === true;
		});
		let activeTabTxt = activeTab[0].toString();
		let queryTab = 'waiting';

		if (activeTabTxt === 'waitSelected') {
			queryTab = 'waiting';
		} else if (activeTabTxt === 'seatedSelected') {
			queryTab = 'seated';
		} else if (activeTabTxt === 'standbySelected') {
			queryTab = 'standby';
		}

		this.API.getListedQueueGroup().then((data) => {
			this.setState({ groupTypes: data.queue_groups });
		});

		this.API.getQueueRecord(queryTab).then((data) => {
			// console.log('getQueueRecord----', data);
			if (activeTabTxt === 'waitSelected') {
				this.setState({
					waitingRecords: data.queue_records ? data.queue_records : [],
					isEmpty: data.queue_records && data.queue_records.length === 0 ? true : false,
					totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
					totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
					totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
				});
			} else if (activeTabTxt === 'seatedSelected') {
				this.setState({
					seatedRecords: data.queue_records ? data.queue_records : [],
					isEmpty: data.queue_records && data.queue_records.length === 0 ? true : false,
					totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
					totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
					totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
				});
			} else if (activeTabTxt === 'standbySelected') {
				this.setState({
					standbyRecords: data.queue_records ? data.queue_records : [],
					isEmpty: data.queue_records && data.queue_records.length === 0 ? true : false,
					totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
					totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
					totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
				});
			}
		});
	};

	startUpdateContent = () => {
		this.refreshCurrentContent();
		if (this.timer !== undefined) {
			clearInterval(this.timer);
		}
		//this.timer = setInterval(this.refreshCurrentContent, 10000)
	};

	stopUpdateContent = () => {
		if (this.timer !== undefined) {
			clearInterval(this.timer);
		}
	};

	stopOnlineQueue = () => {
		this.API.updateQueueGroupStatus('pause').then(
			(data) => {
				this.loadGroupContent();
			},
			(error) => {
				this.setState({
					currentState: 'normal'
				});
			}
		);
	};

	startOnlineQueue = () => {
		this.API.updateQueueGroupStatus('start').then(
			(data) => {
				this.loadGroupContent();
			},
			(error) => {
				this.setState({
					currentState: 'normal'
				});
			}
		);
	};

	startUseQueueSystem = () => {
		this.API.createQueueSettings((data) => {
			if (data !== undefined) {
				// console.log("開始使用排隊系統！")
			}
		});
	};

	closeTimeSection = () => {
		this.API.updateQueueGroupStatus('end').then(
			(data) => {
				clearInterval(this.timer);
				this.setState({
					isOpened: false,
					currentState: 'pause',
					currentStartTime: '',
					totalWaitingCount: 0,
					totalStandbyCount: 0,
					totalSeatedCount: 0,
					totalFinishCount: 0,
					waitingRecords: [],
					seatedRecords: [],
					standbyRecords: [],
					canceledRecords: []
				});
				this.loadGroupContent();
			},
			(error) => {
				this.setState({
					currentState: 'normal'
				});
			}
		);
	};

	reSearchAction = () => {
		this.API.queryRecord(this.state.keyword).then(
			(data) => {
				this.setState({
					searchRecords: data['queue_records']
				});
			},
			(error) => {
				console.log(error);
			}
		);
	};

	refreshCurrentContent = () => {
		this.loadGroupContent();

		switch (this.state.currentTag) {
			case '1':
				this.loadWaitingRecordContent(getCurrentDateString());
				break;
			case '2':
				this.loadSeatedRecordContent(getCurrentDateString());
				break;
			case '3':
				this.loadStandbyRecordContent(getCurrentDateString());
				break;
			case '4':
				this.loadCanceledRecordContent(getCurrentDateString());
				break;
			default:
				// console.log("Not support")
				this.loadWaitingRecordContent(getCurrentDateString());
				break;
		}
	};

	loadGroupContent = () => {
		this.API.getListedQueueGroup().then(
			(data) => {
				if (data !== undefined) {
					this.setState({
						groupTypes: data['queue_groups'],
						currentStartTime: data['start_time'] ? '開始時間:' + data['start_time'] : ''
					});
				} else {
					this.setState({
						currentState: 'settings'
					});
				}
			},
			(error) => {
				this.setState({
					currentState: 'settings'
				});
			}
		);
	};

	/*
        ​status:
        ​​waiting: 1, 
        ​​seated: 2, 
        ​​standby: 3, 
        ​​canceled: 4
    */

	loadWaitingRecordContent = (dateString) => {
		this.API.getQueueRecord('waiting').then(
			(data) => {
				// console.log('loadWaitingRecordContent data----', data);
				if (data['queue_records'] === null) {
					this.setState({
						currentState: this.state.groupTypes.length === 0 ? 'settings' : 'pause'
					});
				} else {
					if (data['queue_records'] !== undefined) {
						if (data['queue_records'].length === 0) {
							if (this.state.currentState === 'add') {
								this.setState({
									isEmpty: true,
									waitingRecords: [],
									totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
									totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
									totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
								});
							} else {
								this.setState({
									currentState: 'normal',
									isEmpty: true,
									waitingRecords: [],
									totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
									totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
									totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
								});
							}
						} else {
							if (this.state.currentState === 'addSuccess' || this.state.currentState === 'addFailed') {
								this.setState({
									isEmpty: false,
									waitingRecords: _.sortBy(data['queue_records'], [ 'group_name', 'waiting_number' ]),
									totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
									totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
									totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
								});
							} else if (this.state.currentState === 'add') {
								this.setState({
									isEmpty: false,
									waitingRecords: _.sortBy(data['queue_records'], [ 'group_name', 'waiting_number' ]),
									totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
									totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
									totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
								});
							} else {
								this.setState({
									currentState: 'normal',
									isEmpty: false,
									waitingRecords: _.sortBy(data['queue_records'], [ 'group_name', 'waiting_number' ]),
									totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
									totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
									totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
								});
							}
						}
					}
				}
			},
			(error) => {}
		);
	};

	loadSeatedRecordContent = async (dateString) => {
		try {
			let seatedArray = [],
				toBookingArray = [];
			const seated = await this.API.getQueueRecord('seated');
			const toBooking = await this.API.getQueueRecord('to_booking');

			if (seated.queue_records) {
				seatedArray = seated.queue_records;
			}

			if (toBooking.queue_records) {
				toBookingArray = toBooking.queue_records;
			}

			let newArray = _.concat(seatedArray, toBookingArray);

			this.setState({
				isEmpty: newArray.length === 0 ? true : false,
				seatedRecords: _.sortBy(newArray, [ 'updated_at' ]),
				totalStandbyCount: seated['standby_count'] ? seated['standby_count'] : 0,
				totalWaitingCount: seated['waiting_count'] ? seated['waiting_count'] : 0,
				totalFinishCount: seated['finish_count'] ? seated['finish_count'] : 0
			});
		} catch (err) {
			console.log('loadSeatedRecordContent', err);
		}
	};

	loadStandbyRecordContent = (dateString) => {
		this.API.getQueueRecord('standby').then(
			(data) => {
				if (data['queue_records'] === null) {
					this.setState({
						currentState: this.state.groupTypes.length === 0 ? 'settings' : 'pause'
					});
				} else {
					if (data['queue_records'] !== undefined) {
						if (data['queue_records'].length === 0) {
							this.setState({
								// currentState: 'normal',
								isEmpty: true,
								standbyRecords: [],
								totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
								totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
								totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
							});
						} else {
							this.setState({
								// currentState: this.state.currentState === 'addSuccess' ? 'addSuccess' : 'normal',
								isEmpty: false,
								standbyRecords: _.sortBy(data['queue_records'], [ 'updated_at' ]),
								totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
								totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
								totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
							});
						}
					}
				}
			},
			(error) => {}
		);
	};

	loadCanceledRecordContent = (dateString) => {
		this.API.getQueueRecord('canceled').then(
			(data) => {
				if (data['queue_records'] === null) {
					this.setState({
						currentState: this.state.groupTypes.length === 0 ? 'settings' : 'pause'
					});
				} else {
					if (data['queue_records'] !== undefined) {
						if (data['queue_records'].length === 0) {
							this.setState({
								// currentState: 'normal',
								// isEmpty: true,
								canceledRecords: [],
								totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
								totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
								totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
							});
						} else {
							this.setState({
								// currentState: this.state.currentState === 'addSuccess' ? 'addSuccess' : 'normal',
								isEmpty: false,
								canceledRecords: data['queue_records'],
								totalStandbyCount: data['standby_count'] ? data['standby_count'] : 0,
								totalWaitingCount: data['waiting_count'] ? data['waiting_count'] : 0,
								totalFinishCount: data['finish_count'] ? data['finish_count'] : 0
							});
						}
					}
				}
			},
			(error) => {}
		);
	};

	handleSearchAction = (e) => {
		if (e.key === 'Enter' && e.target.value.length > 0) {
			let keyword = e.target.value;
			this.API.queryRecord(e.target.value).then(
				(data) => {
					// console.log(data["queue_records"])
					this.setState({
						searchRecords: data['queue_records'],
						keyword: keyword
					});
				},
				(error) => {
					console.log(error);
				}
			);
		}
	};

	startAddCustomers = (queueID) => {
		// 編輯狀態
		let currentGroupType = _.find(this.state.groupTypes, (type) => {
			return type.id === queueID;
		});
		if (currentGroupType !== undefined) {
			clearInterval(this.timer);
			this.setState({
				currentState: 'add',
				currentQueueID: queueID,
				currentGroupType: currentGroupType
			});
		}
	};

	updateOptionAreaState = (state) => {
		this.setState(
			{
				hideOptionArea: true
			},
			() => {}
		);

		setTimeout(() => {
			if (state !== 'add') {
				this.setState({
					hideOptionArea: false,
					currentState: state,
					currentQueueID: null
				});
			} else {
				this.setState({
					hideOptionArea: false,
					currentState: state
				});
			}

			if (state === 'normal') {
				this.startUpdateContent();
			} else if (state === 'addSuccess') {
				switch (this.state.currentTag) {
					case '1':
						this.loadWaitingRecordContent(getCurrentDateString());
						break;
					case '2':
						this.loadSeatedRecordContent(getCurrentDateString());
						break;
					case '3':
						this.loadStandbyRecordContent(getCurrentDateString());
						break;
					case '4':
						this.loadCanceledRecordContent(getCurrentDateString());
						break;
					default:
						// console.log("Not support")
						this.loadWaitingRecordContent(getCurrentDateString());
						break;
				}
			}
		}, 300);
	};

	getFilterTagContents = () => {
		let tagContents = [];

		if (this.state.tabSelected.waitSelected === true) {
			if (this.state.currentSelectTagTitle === '全部') {
				tagContents.push(
					<div key={'waitingRecords'} className="queue-dashbaord-tag selected-blue">
						全部({this.state.waitingRecords.length})
					</div>
				);
			} else {
				tagContents.push(
					<div
						key={'waitingRecords'}
						className="queue-dashbaord-tag"
						onClick={(e) => {
							this.setState({
								currentSelectTagTitle: '全部'
							});
						}}
					>
						全部({this.state.waitingRecords.length})
					</div>
				);
			}

			let tagContentsObject = {};
			this.state.waitingRecords.forEach((record) => {
				if (tagContentsObject[record.group_name] === undefined) {
					tagContentsObject[record.group_name] = 1;
				} else {
					let currentCount = tagContentsObject[record.group_name];
					currentCount = currentCount + 1;
					tagContentsObject[record.group_name] = currentCount;
				}
			});

			this.state.groupTypes.forEach((groupType, index) => {
				if (
					this.state.currentSelectTagTitle === groupType.title &&
					tagContentsObject[groupType.title] !== undefined
				) {
					tagContents.push(
						<div key={index} className="queue-dashbaord-tag selected-blue">
							{groupType.title}({tagContentsObject[groupType.title]})
						</div>
					);
				} else if (tagContentsObject[groupType.title] !== undefined) {
					tagContents.push(
						<div
							key={index}
							className="queue-dashbaord-tag"
							onClick={(e) => {
								this.setState({
									currentSelectTagTitle: groupType.title
								});
							}}
						>
							{groupType.title}({tagContentsObject[groupType.title]})
						</div>
					);
				}
			});
		} else if (this.state.tabSelected.standbySelected === true) {
			if (this.state.currentSelectTagTitle === '全部') {
				tagContents.push(
					<div key={'standbyRecords'} className="queue-dashbaord-tag selected-yellow">
						全部({this.state.standbyRecords.length})
					</div>
				);
			} else {
				tagContents.push(
					<div
						key={'standbyRecords'}
						className="queue-dashbaord-tag"
						onClick={(e) => {
							this.setState({
								currentSelectTagTitle: '全部'
							});
						}}
					>
						全部({this.state.standbyRecords.length})
					</div>
				);
			}

			let tagContentsObject = {};
			this.state.standbyRecords.forEach((record) => {
				if (tagContentsObject[record.group_name] === undefined) {
					tagContentsObject[record.group_name] = 1;
				} else {
					let currentCount = tagContentsObject[record.group_name];
					currentCount = currentCount + 1;
					tagContentsObject[record.group_name] = currentCount;
				}
			});

			this.state.groupTypes.forEach((groupType, index) => {
				if (
					this.state.currentSelectTagTitle === groupType.title &&
					tagContentsObject[groupType.title] !== undefined
				) {
					tagContents.push(
						<div key={index} className="queue-dashbaord-tag selected-yellow">
							{groupType.title}({tagContentsObject[groupType.title]})
						</div>
					);
				} else if (tagContentsObject[groupType.title] !== undefined) {
					tagContents.push(
						<div
							key={index}
							className="queue-dashbaord-tag"
							onClick={(e) => {
								this.setState({
									currentSelectTagTitle: groupType.title
								});
							}}
						>
							{groupType.title}({tagContentsObject[groupType.title]})
						</div>
					);
				}
			});
		} else {
			if (this.state.seatedRecords.length > 0) {
				if (this.state.currentSelectTagTitle === '已報到') {
					tagContents.push(
						<div key={'seatedRecords'} className="queue-dashbaord-tag selected-green">
							已報到({this.state.seatedRecords.length})
						</div>
					);
				} else {
					tagContents.push(
						<div
							key={'seatedRecords'}
							className="queue-dashbaord-tag"
							onClick={(e) => {
								this.loadSeatedRecordContent(getCurrentDateString());
								this.setState({
									currentSelectTagTitle: '已報到',
									currentTag: '2'
								});
							}}
						>
							已報到({this.state.seatedRecords.length})
						</div>
					);
				}
			}

			if (this.state.canceledRecords.length > 0) {
				if (this.state.currentSelectTagTitle === '已取消') {
					tagContents.push(
						<div key={'canceledRecords'} className="queue-dashbaord-tag selected-green">
							已取消({this.state.canceledRecords.length})
						</div>
					);
				} else {
					tagContents.push(
						<div
							key={'canceledRecords'}
							className="queue-dashbaord-tag"
							onClick={(e) => {
								this.loadCanceledRecordContent(getCurrentDateString());
								this.setState({
									currentSelectTagTitle: '已取消',
									currentTag: '4'
								});
							}}
						>
							已取消({this.state.canceledRecords.length})
						</div>
					);
				}
			}

			let tagContentsObject = {};
			this.state.seatedRecords.forEach((record) => {
				if (tagContentsObject[record.group_name] === undefined) {
					tagContentsObject[record.group_name] = 1;
				} else {
					let currentCount = tagContentsObject[record.group_name];
					currentCount = currentCount + 1;
					tagContentsObject[record.group_name] = currentCount;
				}
			});

			this.state.groupTypes.forEach((groupType, index) => {
				if (
					this.state.currentSelectTagTitle === groupType.title &&
					tagContentsObject[groupType.title] !== undefined
				) {
					tagContents.push(
						<div key={index} className="queue-dashbaord-tag selected-blue">
							{groupType.title}({tagContentsObject[groupType.title]})
						</div>
					);
				} else if (tagContentsObject[groupType.title] !== undefined) {
					tagContents.push(
						<div
							key={index}
							className="queue-dashbaord-tag"
							onClick={(e) => {
								this.setState({
									currentSelectTagTitle: groupType.title
								});
							}}
						>
							{groupType.title}({tagContentsObject[groupType.title]})
						</div>
					);
				}
			});
		}

		return tagContents;
	};

	renderOptionsArea = () => {
		return (
			<QueueOptionArea
				timeCheckingCondition={this.state.timeCheckingCondition}
				currentState={this.state.currentState}
				currentQueueID={this.state.currentQueueID}
				currentGroupType={this.state.currentGroupType}
				groupTypes={this.state.groupTypes}
				customQuestions={this.state.customQuestions}
				hideOptionArea={this.state.hideOptionArea}
				startAddCustomers={this.startAddCustomers}
				updateOptionAreaState={this.updateOptionAreaState}
				refreshAction={this.refreshCurrentContent}
				stopTimer={this.stopUpdateContent}
				startTimer={this.startUpdateContent}
			/>
		);
	};

	render() {
		let customerArea;
		let actionsArea;
		let currentListItems = [];
		let queueListItemArea = [];
		let tagContents = this.getFilterTagContents();

		// let optionsArea = (
		//     <QueueOptionArea
		//         timeCheckingCondition = {this.state.timeCheckingCondition}
		//         currentState={this.state.currentState}
		//         currentQueueID={this.state.currentQueueID}
		//         currentGroupType={this.state.currentGroupType}
		//         groupTypes={this.state.groupTypes}
		//         customQuestions={this.state.customQuestions}
		//         hideOptionArea={this.state.hideOptionArea}
		//         startAddCustomers={this.startAddCustomers}
		//         updateOptionAreaState={this.updateOptionAreaState}
		//         refreshAction={this.refreshCurrentContent}
		//         stopTimer={this.stopUpdateContent}
		//         startTimer={this.startUpdateContent}
		//         />
		// )

		if (this.state.tabSelected.waitSelected === true) {
			// 如果現在是選擇「等候中」
			if (this.state.currentSelectTagTitle === '全部') {
				let sort_waitingRecords = _.sortBy(this.state.waitingRecords, [ 'group_name', 'waiting_number' ]);
				currentListItems = sort_waitingRecords;
			} else if (this.state.waitingRecords !== undefined && Array.isArray(this.state.waitingRecords)) {
				currentListItems = this.state.waitingRecords.filter((element) => {
					return element['group_name'] === this.state.currentSelectTagTitle;
				});
			}

			currentListItems.forEach((item, index) => {
				queueListItemArea.push(
					<QueueListItem
						key={index}
						data={item}
						hideStandbyAction={false}
						hideMailBtn={!this.state.emailLineUpRemindEnabled && !this.state.smsLineUpRemindEnabled || !this.state.smsLineUpRemindEnabled && item.email === ''}
						timeCheckingCondition={this.state.timeCheckingCondition}
						notificationAction={openNotification}
						refreshAction={this.refreshCurrentContent}
					/>
				);
			});
		} else if (this.state.tabSelected.seatedSelected === true) {
			// 如果現在是選擇「完成」
			if (this.state.currentSelectTagTitle === '已報到') {
				currentListItems = this.state.seatedRecords;
			} else if (this.state.currentSelectTagTitle === '已取消') {
				currentListItems = this.state.canceledRecords;
			} else if (this.state.seatedRecords !== undefined && Array.isArray(this.state.seatedRecords)) {
				currentListItems = this.state.seatedRecords.filter((element) => {
					return element['group_name'] === this.state.currentSelectTagTitle;
				});
			}

			currentListItems.forEach((item, index) => {
				queueListItemArea.push(
					<QueueListItem
						data={item}
						key={index}
						hideAction={true}
						hideStandbyAction={true}
						hideSeatedAction={true}
						hideMailBtn={!this.state.emailLineUpRemindEnabled && !this.state.smsLineUpRemindEnabled || !this.state.smsLineUpRemindEnabled && item.email === ''}
						notificationAction={openNotification}
						timeCheckingCondition={this.state.timeCheckingCondition}
						refreshAction={this.refreshCurrentContent}
					/>
				);
			});
		} else if (this.state.tabSelected.standbySelected === true) {
			// 如果現在是選擇「過號」
			if (this.state.currentSelectTagTitle === '全部') {
				currentListItems = this.state.standbyRecords;
			} else if (this.state.standbyRecords !== undefined && Array.isArray(this.state.standbyRecords)) {
				currentListItems = this.state.standbyRecords.filter((element) => {
					return element['group_name'] === this.state.currentSelectTagTitle;
				});
			}

			currentListItems.forEach((item, index) => {
				queueListItemArea.push(
					<QueueListItem
						key={index}
						data={item}
						hideAction={false}
						hideStandbyAction={true}
						hideSeatedAction={false}
						hideMailBtn={!this.state.emailLineUpRemindEnabled && !this.state.smsLineUpRemindEnabled || !this.state.smsLineUpRemindEnabled && item.email === ''}
						notificationAction={openNotification}
						timeCheckingCondition={this.state.timeCheckingCondition}
						refreshAction={this.refreshCurrentContent}
					/>
				);
			});
		}

		switch (this.state.currentState) {
			case 'settings':
				customerArea = (
					<div>
						<div className="queue-dashbaord-tag-container" />
						<div className="queue-dashbaord-list-container empty">
							<img src={require('../../images/queue/img_settings_empty.png')} alt="" />
							<p>
								Hi, 歡迎使用排隊系統！<br />請先前往
								<Link
									to={{ pathname: '/dashboard/setting/group', state: { source: 'queue' } }}
									style={{ color: '#404040', textDecoration: 'none' }}
								>
									<span>設定</span>
								</Link>
							</p>
						</div>
					</div>
				);
				actionsArea = null;
				break;
			case 'pause':
				customerArea = (
					<div>
						<div className="queue-dashbaord-tag-container" />
						<div className="queue-dashbaord-list-container empty">
							<p className="list-pause-description">尚未開始營業，請先開始新時段</p>
							<button
								onClick={(e) => {
									// 開啟新時段？
									this.API.updateQueueGroupStatus('start').then(
										(data) => {
											this.startUpdateContent();
											this.setState({
												isOpened: true,
												currentState: 'normal'
											});
										},
										(error) => {
											this.setState({
												currentState: 'pause'
											});
										}
									);
								}}
							>
								開始新時段
							</button>
						</div>
					</div>
				);
				actionsArea = (
					<div className="header-btn-container">
						<button
							className="header-btn open"
							style={{ marginRight: '0px' }}
							onClick={(e) => {
								// 開啟新時段？
								this.API.updateQueueGroupStatus('start').then(
									(data) => {
										this.startUpdateContent();
										this.setState({
											isOpened: true,
											currentState: 'normal'
										});
									},
									(error) => {
										this.setState({
											currentState: 'pause'
										});
									}
								);
							}}
						>
							開始新時段
						</button>
					</div>
				);
				break;
			case 'addSuccess':
			case 'addFailed':
			case 'add':
			case 'customQuestion':
			case 'normal':
				if (this.state.isEmpty) {
					customerArea = (
						<div>
							<div className="queue-dashbaord-tag-container">{tagContents}</div>
							<div className="queue-dashbaord-list-container empty">
								<img src={require('../../images/queue/img_customer_empty.png')} alt="" />
								<p>人客人客，快來喔！</p>
							</div>
						</div>
					);
				} else {
					customerArea = (
						<div>
							<div className="queue-dashbaord-tag-container">
								{tagContents}
								{/* <div className="queue-dashbaord-tag selected">全部</div> */}
							</div>
							<div className="queue-dashbaord-list-container">{queueListItemArea}</div>
						</div>
					);
				}

				let allGroupIsPaused = true;
				this.state.groupTypes.forEach((groupType) => {
					if (groupType.status !== 'pause') {
						allGroupIsPaused = false;
					}
				});

				if (allGroupIsPaused) {
					actionsArea = (
						<div className="header-btn-container">
							<button className="header-btn" data-toggle="modal" data-target="#closeModal">
								結束本時段
							</button>
							<button className="header-btn open" onClick={this.startOnlineQueue}>
								重啟線上
							</button>
							<input
								className="header-search"
								type="text"
								placeholder="搜尋顧客姓名或電話"
								onKeyPress={this.handleSearchAction}
								ref={(ref) => (this.searchRef = ref)}
							/>
						</div>
					);
				} else {
					actionsArea = (
						<div className="header-btn-container">
							<button className="header-btn" data-toggle="modal" data-target="#closeModal">
								結束本時段
							</button>
							<button className="header-btn" data-toggle="modal" data-target="#closeModal-pause">
								暫停線上
							</button>
							<input
								className="header-search"
								type="text"
								placeholder="搜尋顧客姓名或電話"
								onKeyPress={this.handleSearchAction}
								ref={(ref) => (this.searchRef = ref)}
							/>
						</div>
					);
				}

				break;
			default:
				console.log(' ');
				break;
		}

		return (
			<div>
				<div className="container container-shadow">
					<div className="queue-title">
						<div className="queue-header-name">
							<Link
								to="/dashboard/queue_system"
								style={{
									color: '#404040',
									textDecoration: 'none'
								}}
							>
								<img src={require('../../images/queue/img_queue_header_icon.png')} alt="" />
							</Link>
							<Link
								to="/dashboard/queue_system"
								style={{
									color: '#404040',
									textDecoration: 'none'
								}}
							>
								<h2>排隊系統</h2>
							</Link>
							{this.state.smsRemainCount >= 0 ? (
								<h3>
									通知：email、、簡訊（剩餘：
									{this.state.smsRemainCount}點）
								</h3>
							) : (
								<h3>
									通知：email、、簡訊（剩餘：
									{this.state.smsRemainCount}點）請補充
								</h3>
							)}
						</div>
						<p className="note">簡訊只在通知顧客即將到號時發送，顧客排隊成功僅發送e-mail通知</p>
					</div>
					<div className="queue-header-settings">
						<div className="queue-header-right-setting">
							<Link to="/dashboard/queue_system/chart" style={{ color: '#404040' }}>
								<button className="header-action-button">
									<img src={require('../../images/queue/queue_icon_calendar.png')} alt="" />
									<p>月曆</p>
								</button>
							</Link>
							<Link
								to={{ pathname: '/dashboard/setting', state: { source: 'queue' } }}
								style={{ color: '#404040' }}
							>
								<button className="header-action-button">
									<img src={require('../../images/queue/queue_icon_settings.png')} alt="" />
									<p>設定</p>
								</button>
							</Link>
						</div>
					</div>
					<div className="queue-header">
						<div className="queue-header-left">
							<div className="queue-header-time-select">
								{getCurrentDateString('/') + ' ' + getCurrentChineseDay()}
								{this.state.currentState !== 'pause' ? (
									<div className="queue-header-time-starttime">{this.state.currentStartTime}</div>
								) : null}
							</div>
						</div>
						<div className="queue-header-right">{actionsArea}</div>
					</div>
					<div className="queue-dashboard">
						{this.renderOptionsArea()}
						{/* {optionsArea} */}

						{this.state.keyword !== '' ? (
							<div className="queue-dashboard-search">
								<QueueSearchResult
									timeCheckingCondition={this.state.timeCheckingCondition}
									notificationAction={openNotification}
									refreshAction={this.refreshCurrentContent}
									reSearchAction={this.reSearchAction}
									clearSearchResult={(e) => {
										this.searchRef.value = '';
										this.setState({
											searchRecords: [],
											keyword: ''
										});
									}}
									keyword={this.state.keyword}
									records={this.state.searchRecords}
								/>
							</div>
						) : (
							<div className="queue-dashboard-customers">
								<div className="queue-dashboard-customers-container" />
								<button className="button-third-bottom" />
								<button className="button-second-bottom" />
								<button className="button-first-bottom" />
								<button
									onClick={(e) => {
										this.loadStandbyRecordContent(getCurrentDateString());
										this.setState({
											currentSelectTagTitle: '全部',
											currentTag: '3',
											tabSelected: {
												waitSelected: false,
												seatedSelected: false,
												standbySelected: true
											}
										});
									}}
									className={classNames('button-third', {
										selected: this.state.tabSelected.standbySelected
									})}
								>
									<p>過號({this.state.totalStandbyCount})</p>
								</button>
								<button
									onClick={(e) => {
										this.loadSeatedRecordContent(getCurrentDateString());
										this.loadCanceledRecordContent(getCurrentDateString());
										this.setState({
											currentSelectTagTitle: '已報到',
											currentTag: '2',
											tabSelected: {
												waitSelected: false,
												seatedSelected: true,
												standbySelected: false
											}
										});
									}}
									className={classNames('button-second', {
										selected: this.state.tabSelected.seatedSelected
									})}
								>
									<p>完成({this.state.totalFinishCount})</p>
								</button>
								<button
									onClick={(e) => {
										this.loadWaitingRecordContent(getCurrentDateString());
										this.setState({
											currentSelectTagTitle: '全部',
											currentTag: '1',
											tabSelected: {
												waitSelected: true,
												seatedSelected: false,
												standbySelected: false
											}
										});
									}}
									className={classNames('button-first', {
										selected: this.state.tabSelected.waitSelected
									})}
								>
									<p>等候中({this.state.totalWaitingCount})</p>
								</button>
								<div className="cover-button-area" />
								{customerArea}
							</div>
						)}
					</div>
					<Link to="/dashboard/queue_system_settings" />
				</div>
				{getModalClose(this.closeTimeSection)}
				{getModalPause(this.stopOnlineQueue)}
				{getModalStart(this.startOnlineQueue)}
			</div>
		);
	}
}
