import React, { useState, useEffect } from 'react';
import { tableBookingNum } from '../utils/data';

export const TableBookingNumPad = ({ closeTableBooking, setBookingTime }) => {
	const [ attendance, setAttendance ] = useState([ 0 ]);
	const [ displayAttendance, setDisplayAttendance ] = useState('0');
	const [ attendanceActiveStyle, setAttendanceActiveStyle ] = useState('');

	useEffect(
		() => {
			const attendanceNumber = parseInt(attendance.join(''), 10);
			if (attendanceNumber > 0) {
				setAttendanceActiveStyle('attendanceActive');
			} else {
				setAttendanceActiveStyle('');
			}

			setDisplayAttendance(attendance.join(''));
		},
		[ attendance ]
	);

	// const renderAttendance = () => {
	// 	const attendanceActive = attendanceNumber > 0? 'attendanceActive':'';
	// 	const attendanceNumber = parseInt(attendance.join(''), 10);

	// 	return <span className={attendanceActive}>{attendanceNumber}</span>;
	// };

	const keyIn = (number) => {
		let newAttendance = [ ...attendance ];
		const attendanceNumber = parseInt(newAttendance.join(), 10);

		if (attendanceNumber === 0) {
			setAttendance([ number ]);
		} else {
			newAttendance.push(number);
			setAttendance(newAttendance);
		}
	};

	const deleteNum = () => {
		let newAttendance = [ ...attendance ];
		newAttendance.pop();

		if (newAttendance.length === 0) {
			newAttendance = [ 0 ];
		}

		setAttendance(newAttendance);
	};

	const checkAttendance = (attendance) => {
		if (attendance <= 0) {
			return window.app.alert.setMessage('請輸入人數', 'error');
		}

		setBookingTime(attendance);
	};

	return (
		<div className="TableBookingNumPadWrap">
			<div className="TableBookingNumPad__layout" />

			<div className="TableBookingPanel TableBookingNumPad">
				<button className="TableBookingNumPadClose" onClick={() => closeTableBooking()} />
				<div className="TableBookingNumPad__header">
					<span className={attendanceActiveStyle}>{displayAttendance}</span>
				</div>

				<div className="TableBookingNumPad__body">
					{tableBookingNum.map((number) => {
						if (number === 'delete') {
							return (
								<button key={number} className="numpad-delete" onClick={() => deleteNum()}>
									<img
										className="numPad_del"
										src={require('../../../images/newModule/delete.svg')}
										alt="delete"
									/>
								</button>
							);
						} else if (number === 'check') {
							return (
								<button
									key={number}
									className="numpad-check"
									onClick={() => checkAttendance(parseInt(attendance.join(''), 10))}
								>
									確認
								</button>
							);
						} else {
							return (
								<button key={number} onClick={() => keyIn(number)}>
									{number}
								</button>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};
