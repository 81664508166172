import React from 'react';

import '../menuUser.scss';


const MenuAccountBindFooter = () => {
	return (
    <footer>
      <div>
        <a href="mailto:findlife99@gmail.com">
					<img src={require('../../../../images/pageIdx/email.png')} alt="" />
				</a>
				<a href="https://www.facebook.com/menushoptw/" target="_blank" rel="noopener noreferrer">
					<img src={require('../../../../images/pageIdx/fb.png')} alt="" />
				</a>
      </div>
      <div>
        找活股份有限公司版權所有
        <br />
        ＠2016 - 2021  FindLife Lnc. All rights reserved.
      </div>
    </footer>
	);
};

export default MenuAccountBindFooter;

