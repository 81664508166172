import React, { useState } from 'react';
import { Link } from 'react-router';
import MenuAppIntroModal from '../menu_user/MenuAppIntro';
import MenuUserDeleteModal from '../menu_user/MenuUserDelete'

import { unbindMenuUser } from '../menu_user/MenuUserApi';

const MenuUserInfo = ({ menuUserInfo, getMenuUserBindStatus }) => {
  const [ visible, setVisible ] = useState(false);
  const [ checkDelete, setCheckDelete ] = useState(false);


  const handleMenuAppIntroModal = () => {
    const menuAppIntroModalVisible = !visible;
    setVisible(menuAppIntroModalVisible)
  };

  const handleCheckDeleteModal = () => {
    const checkDeleteModalVisible = !checkDelete;
    setCheckDelete(checkDeleteModalVisible)
  };

  const deleteMenuUser = () => {
    unbindMenuUser()
      .then(res => {
        getMenuUserBindStatus();
        setCheckDelete(false);
      }).catch(err => {
          console.log(err);
      })
  }

  const renderMenuAccount = () => {
    if(menuUserInfo.displayName === '' && menuUserInfo.menuUserId === '') {
      return (
        <div className="menuAccountInfoBlock_content">
          <div className="menuAccountInfoBlock_content_why" 
            onClick={handleMenuAppIntroModal}>
            為什麼要綁定美食誌帳號？
          </div>
          <Link to={'/dashboard/menu_user'}>
            <button>前往綁定</button>
					</Link>
        </div>
      )
    } else {
      return (
      <div className="menuAccountInfoBlock_info">
        <div className="menuAccountInfoBlock_logo">
          { menuUserInfo.thumbnailURL ? 
            <img src={menuUserInfo.thumbnailURL} alt="" /> : ''
          }
          </div>
        <div className="menuAccountInfoBlock_info_right">
          <div className="menuAccountInfoBlock_info_title">使用者名稱</div>
          <div className="menuAccountInfoBlock_info_content">
            { menuUserInfo.displayName }
          </div>
          <div className="menuAccountInfoBlock_info_title">MENU ID</div>
          <div className="menuAccountInfoBlock_info_content">
            { menuUserInfo.menuUserId }
          </div>
          <div className="text-right">
            <button onClick={handleCheckDeleteModal}>解除綁定</button>
          </div>
        </div>
      </div>
      )
    }
  };

	return (
		<div className="menuAccountInfoBlock">
			<div className="menuAccountInfoBlock_title">綁定美食誌帳號</div>
			<div className="menuAccountInfoBlock_intro">透過綁定美食誌，你能透過 MENU 美食誌的私訊功能與您的粉絲互動，分享最新的優惠內容及優惠券資訊給你的粉絲，完成促銷。</div>
			{ renderMenuAccount() }
      <MenuAppIntroModal 
        visible={visible}
        handleMenuAppIntroModal={handleMenuAppIntroModal}
      />
      <MenuUserDeleteModal
        checkDelete={checkDelete}
        handleCheckDelete={handleCheckDeleteModal}
        deleteMenuUser={deleteMenuUser}
      />
		</div>
	);
};

export default MenuUserInfo;

