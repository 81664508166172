import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { handleHeaderFilter, announcementSelected } from '../../../actions/announcementAction';

const shopFilter = {
	all: '全部',
	regular: '一般',
	long_term: '常態',
	finished: '結束'
};

const officialFilter = {
	all: '全部',
	regular: '當前',
	finished: '結束'
};

const AnnouncementHeaderFilter = () => {
  const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
		sortBy,
		order,
		currentTab,
		currentFilter,
		statusAnnouncementNum
	} = announcementListReducer;

  const dispatch = useDispatch();

  const switchFilter = (filterType) => {
		dispatch(handleHeaderFilter(filterType, 1, sortBy, order));
		dispatch(announcementSelected([]));
  };

  const renderAnnouncementType = () => {
		const filterType = currentTab === 'shop' ? shopFilter : officialFilter;
		return (
			<ul>
				{Object.keys(filterType).map((item, index) => {
					return (
						<li
              key={item}
              className={currentFilter === item ? 'active' : ''}
              onClick={() => switchFilter(item)}
            >
							<div>{filterType[item]} ({statusAnnouncementNum[index]})</div>
						</li>
					);
				})}
			</ul>
		);
	};

  return (
    <div className='announcementHeaderFilter'>
      {renderAnnouncementType()}
    </div>
  )
};

export default AnnouncementHeaderFilter;