import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div className='pageNotFound'>
        <img src={require('../../images/404_not_found.svg')} alt="" />
      </div>
    );
  }
}
