import React from 'react'
import $ from 'jquery'
import _ from 'lodash'
import { config } from '../../utils/config'
import {handleError} from '../../libs/handler'
import moment from 'moment/min/moment-with-locales.min'

// var createReactClass = require('create-react-class');
moment.locale('zh-tw');

let getDate = new Date();
let currentYear = getDate.getFullYear();


export default class  extends React.Component {
  constructor(props){
    super(props)
    this.load();
    this.state = {
      activeYear: currentYear,
      disableDates: [],
      init: false,
      isSaved: true,
      disableDatesObj:{},
      sending: false
    }
    this.chooseDate = this.chooseDate.bind(this);
    this.removeDisableDates = this.removeDisableDates.bind(this);
    this.nextYear = this.nextYear.bind(this);
    this.preYear = this.preYear.bind(this);
    this.createRows = this.createRows.bind(this);
  }
  routerWillLeave = (nextLocation) => {
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if(!this.state.isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?'
  }
  componentDidMount(){
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
   
  }


  load = () => {
    $.ajax({url: config.domain + '/dashboard/booking_settings', 
          method: 'GET', 
          xhrFields: { withCredentials: true }})
      .done(function(data){
        // this.setState(data);
        this.setState({disableDates: data.disable_dates ,init: true});
      }.bind(this))
      .fail(function(xhr){
        handleError(xhr);
      });
  }
  submit = () => {
    this.setState({sending: true})
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
          method: 'PATCH',
          dataType: "json",
          processData: false,
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({
            booking_settings: {
              disable_dates: this.state.disableDates,
            }
          }),
          xhrFields: { withCredentials: true }})
      .done((data) => {
        this.setState({isSaved: true, sending: false})
        window.app.alert.setMessage("更新成功",'done')
      })
      .fail(function(xhr){
        handleError(xhr);
        this.setState({sending: false})
        window.app.alert.setMessage("請稍後再試",'done')
      });
  }
  

  createRows(year, month){
    // console.log(month)
    let {disableDatesObj, disableDates} = this.state;

    let createTable=[];

    let firstday = new Date(year, month-1, 1),  //查詢月份的第一天
        dayOfWeek = firstday.getDay(), //第一天星期幾 0:Sun 1:Mon 2:Tues
        days_per_month = new Date(year,month,0).getDate();  //當月天數

    let days_per_lastMonth =  new Date(year, month-1, 0).getDate(); //上個月天數

    // console.log(days_per_lastMonth)

    let todayStyle='',
        vacantStyle='vacant',
        chosenDateStyle='';

    let str_nums = Math.ceil((dayOfWeek + days_per_month)/7)  //日曆需要幾行

    disableDates.map((item)=>{
      // console.log(item)
      return disableDatesObj[item]=1;
    })

    for(let i =0; i< str_nums ; i++){
      let tableTr=[];
      for(let j=0; j<7; j++){
        let dayIndex = 7 * i + j; //建立索引, 從0開始
        let date = dayIndex - dayOfWeek + 1;
        let cell;
        let dateKey =  dayIndex - dayOfWeek + 1;
        if(date <= 0){
          date = date + days_per_lastMonth;
          vacantStyle = 'vacant';
        }
        else if(date > days_per_month){
            vacantStyle = 'vacant';
            date = date - days_per_month;
        }
        else{
          date= dayIndex - dayOfWeek + 1;
          vacantStyle='';
        }

        if (dateKey<10 && dateKey>0){
          dateKey = "0"+dateKey
        }

        if(month<10 && month>0 && month.toString().length<2){
          month = "0"+ month;
        }

        cell = year+'-'+month+'-'+dateKey;
    

        if(disableDatesObj[cell] === 1){
          chosenDateStyle = "chosenDate" ;
        }else{
          chosenDateStyle = "" ;
        }

        tableTr.push(
          <div key={dateKey} data-dayindex={dateKey} data-month={month} data-year={year} className={["tableCell", todayStyle, vacantStyle].join(' ')}>
            <span className={chosenDateStyle} onClick={(e)=> this.chooseDate(e)}>
              <span>
                {date}
              </span>
            </span>
          </div>
        )
      }

      createTable.push(
        <div key={i} className="tableRow tableCellRow">{tableTr}</div>
      )
    }

      return  createTable;
  }

  chooseDate(e){
    const {parentNode, className} = e.target;
    const { disableDates } = this.state;

    let invalidDate = parentNode.parentNode.className.indexOf("vacant"),
        chosenDateStyle =  parentNode.className.indexOf("chosenDate");

    let chosenDay = parentNode.parentNode.dataset.dayindex,
        chosenMonth = parentNode.parentNode.dataset.month,
        chosenYear = parentNode.parentNode.dataset.year;



    let disableDay = chosenYear + "-" + chosenMonth + "-" + chosenDay;

    // console.log("chosenYear>>>", chosenYear)
    // console.log("chosenMonth>>>", chosenMonth)
    // console.log("chosenDay>>>", chosenDay)

    if(chosenYear && chosenMonth && chosenDay){
      if(invalidDate === -1){
        if(chosenDateStyle === -1){
          e.target.parentNode.className = "chosenDate";
          this.state.disableDates.push(disableDay);
          this.state.disableDates.sort();
        }else{
          e.target.parentNode.className = "";
          this.removeDisableDates(disableDates, disableDay)
          this.state.disableDates.sort();
        }
      }
    }

   

    this.setState({isSaved: false})

    // console.log(disableDates) 
  }

  removeDisableDates(array, ele){
    const index = array.indexOf(ele);
    array.splice(index, 1)
  }

  createFullYearMonth(){
    let {activeYear} = this.state;
    return(
      [1,2,3,4,5,6,7,8,9,10,11,12].map((e, i)=>{
        return(
          <Month  key={i}  
                  createRows={this.createRows(activeYear, e)}
                  showYear={activeYear}
                  showMonth = {e}
          />
        )
      })
    )
  }


  nextYear(){
    let {activeYear} = this.state;
    this.setState({activeYear:activeYear+1})
  }

  preYear(){
    let {activeYear} = this.state;
    this.setState({activeYear:activeYear-1})
  }




  render() {
    if (this.state.init !== true) return <div className="pageLoading">讀取中..</div>;
    return (
      <>
        <div className="main overflow">
          {this.state.sending && (
              <div className='lightBoxLayer'>
                <h4>儲存中</h4>
              </div>
            )}
          <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1}}>
              <h3>不可預約日期設定</h3>
              <h5>
                您可以將特定日期設定為不可預約，以滿足例如：臨時店休、特殊活動等情境。
                「不可預約日期設定」的規則將優先於「預約時段設定」與「例外預約日期設定」的規則。
              </h5>
            </div> 
          </div>
          <hr />
          <div className="disableDateCalendar">
          <h1> 
              <span onClick={()=> this.preYear()}> <i className="fa fa-chevron-left" aria-hidden="true"></i> </span>
              <span>
                {this.state.activeYear} 
              </span>
              <span onClick={()=> this.nextYear()}> <i className="fa fa-chevron-right" aria-hidden="true"></i> </span>

              <span className="note">
                <div className="dis_dot"></div>
                不可預約
              </span>
              
            </h1>
          
            
            <div className="radio monthWrapper">
              {
                this.createFullYearMonth()
              }
            </div>
          </div>
          <br />
        </div>

        <hr className='main_width' />

        <div className='fix_bottom'>
          <button
            className='btn_submit'
            onClick={this.submit}
          >
            儲存
          </button>
        </div>
      </>
    );
  }
}



class Month extends React.Component {
  render() {
    return (
      <div className="calendarTable">
          <div className="tableMonth">
              {this.props.showMonth}月, {this.props.showYear}
          </div>
         
          <div className="tableRow tableRowTitle">
              <div className="tableCell">日</div>
              <div className="tableCell">一</div>
              <div className="tableCell">二</div>
              <div className="tableCell">三</div>
              <div className="tableCell">四</div>
              <div className="tableCell">五</div>
              <div className="tableCell">六</div>
          </div>

          <div className="monthCell">
              {
                  this.props.createRows
              }
          </div>

      </div>
    );
  }
}
