/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { config } from '../../utils/config';
import QueueSystemAPI from './QueueSystemAPI';
import QueuePanelAPI from '../queue_system/api/QueuePanelAPI';

import QRCode from 'qrcode.react';
import Reorder, { reorder } from 'react-reorder';
import $ from 'jquery';

export default class QueueSettingDisplay extends Component {
	state = {
		queuePageLink: '',
		displayName: '',
		branch: '',
		address: '',
		phone: '',
		introduction: '',
		showWaitingGroup: false,
		showWaitingTime: false,
		externalLinkText: '',
		externalLink: '',
		pauseText: '',
		shopName: '',
		isSaved: true,
		menuFiles: [],
		deleteMenuImages: [],
		remote_line_up: true,
		menuPreviewUrls: [],
		email_required: false,
        refresh_live_url: false,
        queueEnded: false,
		shopNote: '',
		allowDuplicatedPhone: false
	};

	componentDidMount() {
		this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
        this.API = new QueueSystemAPI();
        this.GROUP = new QueuePanelAPI();
        
		this.API.getQueueSettings().then(
			(data) => {
				this.setState({
					displayName: data['display_name'],
					branch: data['branch'],
					queuePageLink: data['queue_page_link'],
					address: data['addr'],
					phone: data['phone'],
					introduction: data['intro'],
					externalLink: data['external_link'],
					externalLinkText: data['external_link_text'],
					pauseText: data['pause_msg'],
					showWaitingGroup: data['show_waiting_group'],
					showWaitingTime: data['show_waiting_time'],
					backgroundImage: data['background_img'],
					menuPreviewUrls: data['queue_menu_imgs'],
					shopName: data['shop_name'],
					email_required: data['email_required'],
					remote_line_up: data['remote_line_up'],
					live_page_url: data['live_page_url'],
          refresh_live_url: data['refresh_live_url'],
					allowDuplicatedPhone: data['allow_duplicated_phone']
				});

				if(data['shop_note'] === null) {
					this.setState({ shopNote: '' })
				} else {
					this.setState({ shopNote: data['shop_note'] })
				}
			},
			(error) => {}
        );
        this.GROUP.getListedQueueGroup().then(data=>{
            let endIndex = data.queue_groups.map(group=>{return group.status}).indexOf("end");
            if(endIndex === -1){
                this.setState({queueEnded: false})
            }else{
                this.setState({queueEnded: true})
            }
        }).catch(err=>{

        })
	}

	routerWillLeave = (nextLocation) => {
		if (!this.state.isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?';
	};

	_handleImageChange = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		if (file.size > 2097152) {
			window.app.alert.setMessage('檔案大小不可超過 2MB', 'error');
		} else {
			reader.onloadend = (ev) => {
				this.setState(
					{
						file: file,
						imagePreviewUrl: reader.result,
						isSaved: false
					},
					() => {
						this.setState({ isSaved: false });
					}
				);
			};

			reader.readAsDataURL(file);
		}
	};

	_handleMenuImageChange = (e) => {
		e.preventDefault();

		let files = e.target.files;
		for (let i = 0; i < files.length; i++) {
			let reader = new FileReader();
			let file = files[i];
			if (file.size > 2097152) {
				window.app.alert.setMessage('檔案大小不可超過 2MB', 'error');
			} else {
				reader.onloadend = (event) => {
					let { menuFiles, menuPreviewUrls } = this.state;
					// let order = menuPreviewUrls.length + 1
					let time = new Date(),
						timeStamp = time.getTime().toString();

					if (menuPreviewUrls.length >= 5) {
						window.app.alert.setMessage('最多上傳5張', 'error');
					} else {
						menuPreviewUrls.push({
							url: reader.result,
							fileImgOrder: timeStamp
						});
						file.fileImgOrder = timeStamp;
						menuFiles.push(file);
						this.setState({
							menuFiles: menuFiles,
							menuPreviewUrls: menuPreviewUrls,
							isSaved: false
						});
					}
				};
			}
			reader.readAsDataURL(file);
		}
	};

	delMenuImg(imgIndex, file) {
		let { menuPreviewUrls, menuFiles } = this.state;

		menuPreviewUrls.splice(imgIndex, 1);

		if (!file.id) {
			menuFiles.some((item, index) => {
				if (item['fileImgOrder'] === file['fileImgOrder']) {
					menuFiles.splice(index, 1);
				}
			});
		}

		if (menuPreviewUrls.length === 0) {
			this.setState({ isSaved: false, menuPreviewUrls: menuPreviewUrls });
		} else {
			this.onReorder();
		}
	}

	onReorder(event, previousIndex, nextIndex, fromId, toId) {
		this.setState({
			isSaved: false,
			menuPreviewUrls: reorder(this.state.menuPreviewUrls, previousIndex, nextIndex)
		});
	}

	componentDidUpdate(prevProps, prevState) {
		$(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
	}

	downloadImage = (e) => {
		var canvas = document.getElementById('qrcode').firstChild;
		var button = document.getElementById('qrcode-btn');
		let newCanvas = document.createElement('canvas');
		let ctx = newCanvas.getContext('2d');

		newCanvas.width = canvas.width + 40;
		newCanvas.height = canvas.height + 40;

		let centerWidth = newCanvas.width / 2 - canvas.width / 2,
			centerHeight = newCanvas.height / 2 - canvas.height / 2;

		ctx.fillStyle = '#fff';
		ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
		ctx.drawImage(canvas, centerWidth, centerHeight);

		button.setAttribute('href', newCanvas.toDataURL('image/png'));
	};

	render() {
		return (
			<>
				<div className="main overflow" style={{ maxWidth: '1000px' }}>
					<div style={{ display: 'flex' }}>
						<div style={{ flexGrow: 1 }}>
							<h3>線上排隊設定</h3>
							<h5>您可以設定是否開放線上排隊，以及設定線上排隊頁面的內容。</h5>
						</div>
					</div>
					<hr />
					<div className="rangeTitle">
						<img src={require('../../images/homepage.svg')} alt="" />
						<h4>
							前往排隊展示頁
							<div style={{ fontSize: '18px', color: '#575757', letterSpacing: '1px', marginTop: '10px' }}>
								可供顧客查看排隊即時動態
							</div>
						</h4>
					</div>
					<div className="settingTbl">
						<div className="settingTblRow">
							<div className="header">排隊展示頁</div>
							<div className="text">
								<a
									rel="noopener noreferrer"
									href="/dashboard/queue_system/display/store"
									target="_blank"
									style={{ color: '#404040', textDecoration: 'none' }}
								>
									<label className="msBtn" style={{ marginTop: '16px' }}>
										前往展示頁
									</label>
								</a>
							</div>
						</div>
						<div className="settingTblRow">
							<div className="header">
															QR Code
													</div>
							<div className="text">
								<a
									className="msBtn"
									id="qrcode-btn"
									// href="#"
									download
									style={{
										marginTop: '16px',
										textDecoration: 'none',
										fontWeight: 'bold',
										borderColor: this.state.queueEnded && this.state.refresh_live_url? "rgba(0, 0, 0, 0.25)" : "rgb(51, 51, 51)"
									}}
									disabled={this.state.queueEnded && this.state.refresh_live_url}
									onClick={this.downloadImage}
								>
									下載 QR Code
								</a>
															{
																this.state.queueEnded && this.state.refresh_live_url && <p className="text-danger" style={{fontSize: 14}}>請開始新排隊，才會產生有效的QR code。</p>
															}
								<div id="qrcode" hidden>
									<QRCode size={220} value={config.queue_domain + '/live/' + this.state.live_page_url} />
								</div>
							</div>
						</div>
					</div>

					<br />
					<br />
					<br />
					<div className="rangeTitle">
						<img src={require('../../images/clock.svg')} alt="Setting" />
						<h4>排隊系統開放設定</h4>
					</div>
					<div className="settingTbl">
						<div className="settingTblRow">
							<div className="header" data-toggle="tooltip" data-placement="bottom" title="">
								遠端排隊
								<i className="fa fa-question-circle-o" aria-hidden="true" />
							</div>
							<div className="text">
								<label className="msSwitch">
									<input
										type="checkbox"
										name="enable"
										checked={this.state.remote_line_up}
										onChange={(e) => {
											this.setState({
												remote_line_up: e.target.checked
											});
										}}
									/>
									<span className="slider" />
								</label>
							</div>
						</div>

						{this.state.remote_line_up ? (
							<div className="settingTblRow">
								<div className="header" data-toggle="tooltip" data-placement="bottom" title="" />
								<div className="text">
									<a
										style={{ fontSize: '18px', fontWeight: 'normal' }}
										className="msLink"
																			target="_blank"
																			rel='noopener noreferrer'
										href={config.queue_domain + '/' + this.state.shopName}
									>
										{config.queue_domain}/{this.state.shopName}
									</a>
								</div>
							</div>
						) : null}
						<div className="settingTblRow">
							<div
								className="header"
								data-toggle="tooltip"
								data-placement="bottom"
								title="關閉排隊後，舊有的排隊QR code將會失效。"
							>
								更新線上排隊網址
								<i className="fa fa-question-circle-o" aria-hidden="true" />
							</div>
							<div className="text">
								<label className="msSwitch">
									<input
										type="checkbox"
										name="enable"
										checked={this.state.refresh_live_url}
										onChange={(e) => {
											this.setState({
																							refresh_live_url: e.target.checked,
											});
										}}
									/>
									<span className="slider" />
								</label>
															{
																	this.state.refresh_live_url && <p className="text-danger" style={{fontSize: 14}}>若開啟此功能且關閉排隊，舊有QR Code網址將會失效，請重新下載QR Code</p>
															}
							</div>
						</div>
						<div className="settingTblRow">
							<div className="header">背景圖片 </div>
							<div className="text">
								<p className="help-block">
									<img
										src={this.state.imagePreviewUrl || this.state.backgroundImage}
										style={{ maxWidth: '100%', maxHeight: '230px' }}
										alt=""
									/>
								</p>

								<div className="file-upload">
									<span>建議尺寸: 1170*300 px</span>
									<label htmlFor="upload" className="msBtn" style={{ marginLeft: '15px' }}>
										+ 上傳圖片
									</label>
									<input
										id="upload"
										onChange={this._handleImageChange}
										className="file-upload__input"
										type="file"
										name="file-upload"
										accept="image/x-png,image/gif,image/jpeg"
									/>
								</div>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header">店家名稱 </div>
							<div className="text">
								<input
									className="form-control input-lg"
									placeholder="店家名稱"
									value={this.state.displayName}
									style={{ width: '360px', display: 'inline', marginRight: '12px' }}
									name="store_name"
									disabled={true}
									onChange={(e) => {
										this.setState({
											displayName: e.target.value,
											isSaved: false
										});
									}}
								/>
								<input
									className="form-control input-lg"
									placeholder="分店名"
									value={this.state.branch}
									style={{ width: '150px', display: 'inline', marginRight: '12px' }}
									name="store_branch"
									disabled={true}
									onChange={(e) => {
										this.setState({
											branch: e.target.value,
											isSaved: false
										});
									}}
								/>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header">店家地址 </div>
							<div className="text">
								<input
									className="form-control input-lg"
									value={this.state.address}
									style={{ width: '360px', display: 'inline', marginRight: '12px' }}
									name="store_address"
									disabled={true}
									onChange={(e) => {
										this.setState({
											address: e.target.value,
											isSaved: false
										});
									}}
								/>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header">店家電話 </div>
							<div className="text">
								<input
									className="form-control input-lg"
									style={{ width: '360px', display: 'inline', marginRight: '12px' }}
									value={this.state.phone}
									name="store_phone"
									disabled={true}
									onChange={(e) => {
										var isnum = /^[0-9\s]+$/.test(e.target.value);
										if (isnum) {
											this.setState({
												phone: e.target.value,
												isSaved: false
											});
										}
									}}
								/>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header">店家簡介 </div>
							<div className="text">
								<textarea
									rows="7"
									cols="50"
									value={this.state.introduction}
									name="note"
									className="form-control"
									onChange={(e) => {
										this.setState({
											introduction: e.target.value,
											isSaved: false
										});
									}}
								/>
							</div>
						</div>
						<br />
						<br />
						<div className="settingTblRow">
							<div className="header">
								<span data-toggle="tooltip" data-placement="bottom" title="顧客可在登記排隊頁面看到您上傳的圖片">
									上傳菜單
								</span>
								<i className="fa fa-question-circle-o" aria-hidden="true" />
							</div>
							<div className="text">
								{
									<Reorder
										reorderId="q-list"
										reorderGroup="reorder-group"
										onReorder={this.onReorder.bind(this)}
										disabled={false}
										placeholder={<div className="menuDivStylePlaceHolder" />}
									>
										{this.state.menuPreviewUrls.map((item, index) => (
											<div key={index} className="menuDivStyle">
												<button className="menuDel" onClick={() => this.delMenuImg(index, item)} />
												<img src={item.url} className="menuStyle" alt="" />
											</div>
										))}
									</Reorder>
								}

								<div className="file-upload" style={{ display: 'block', width: '100%' }}>
									<span>最多上傳5張，圖片大小限制2MB，建議比例 3:4</span>
									<label htmlFor="upload-menu" className="msBtn" style={{ marginLeft: '16px' }}>
										+ 上傳圖片
									</label>
									<input
										id="upload-menu"
										multiple
										onChange={this._handleMenuImageChange}
										className="file-upload__input"
										type="file"
										name="file-upload-menu"
										accept="image/x-png,image/gif,image/jpeg"
									/>
								</div>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header" data-toggle="tooltip" data-placement="bottom" title="排隊是否需要填寫e-mail">
								e-mail必填
								<i className="fa fa-question-circle-o" aria-hidden="true" />
							</div>
							<div className="text">
								<label className="msSwitch">
									<input
										type="checkbox"
										name="enable"
										checked={this.state.email_required}
										onChange={(e) => {
											this.setState({
												email_required: e.target.checked
											});
										}}
									/>
									<span className="slider" />
								</label>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header">電話重複預約</div>
							<div className="text">
								<label className="msSwitch">
									<input
										type="checkbox"
										name="enable"
										checked={this.state.allowDuplicatedPhone}
										onChange={(e) => {
											this.setState({
												allowDuplicatedPhone: e.target.checked
											});
										}}
									/>
									<span className="slider" />
								</label>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header" data-toggle="tooltip" data-placement="bottom" title="顯示於顧客確認排隊取號頁">
								導外按鈕
								<i className="fa fa-question-circle-o" aria-hidden="true" />
							</div>
							<div className="text">
								<input
									placeholder="10字內，如：店家官網"
									className="form-control input-lg"
									style={{ width: '360px', display: 'inline', marginRight: '12px' }}
									value={this.state.externalLinkText}
									name="external_link_text"
									onChange={(e) => {
										if (e.target.value.length <= 10) {
											this.setState({
												externalLinkText: e.target.value,
												isSaved: false
											});
										}
									}}
								/>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header">外部連結 </div>
							<div className="text">
								<input
									placeholder="輸入網址"
									className="form-control input-lg"
									style={{ width: '360px', display: 'inline', marginRight: '12px' }}
									value={this.state.externalLink}
									name="external_link"
									onChange={(e) => {
										this.setState({
											externalLink: e.target.value,
											isSaved: false
										});
									}}
								/>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div className="header">店家備註文案</div>
							<div className="text">
								<textarea
									rows="7"
									cols="30"
									name="note_text"
									className="form-control"
									value={this.state.shopNote}
									onChange={(e) => {
										this.setState({
											shopNote: e.target.value,
											isSaved: false
										});
									}}
								/>
							</div>
						</div>
						<br />
						<div className="settingTblRow">
							<div
								className="header"
								data-toggle="tooltip"
								data-placement="bottom"
								title="暫停所有組別線上排隊時，顧客可看到的說明文字"
							>
								暫停排隊文案
								<i className="fa fa-question-circle-o" aria-hidden="true" />
							</div>
							<div className="text">
								<textarea
									rows="7"
									cols="50"
									// onChange={this.onChange}
									// defaultValue={this.state.note}
									value={this.state.pauseText}
									name="pause_text"
									className="form-control"
									onChange={(e) => {
										this.setState({
											pauseText: e.target.value,
											isSaved: false
										});
									}}
								/>
							</div>
						</div>
					</div>
					<hr />
				</div>

				<div className='fix_bottom'>
					<button
						className="btn_submit"
						onClick={(ev) => {
							this.API
								.updateQueueSettings({
									display_name: this.state.displayName,
									branch: this.state.branch,
									addr: this.state.address,
									phone: this.state.phone,
									intro: this.state.introduction,
									external_link: this.state.externalLink,
									external_link_text: this.state.externalLinkText,
									pause_msg: this.state.pauseText,
									show_waiting_group: this.state.showWaitingGroup,
									show_waiting_time: this.state.showWaitingTime,
									email_required: this.state.email_required,
									remote_line_up: this.state.remote_line_up,
									refresh_live_url: this.state.refresh_live_url,
									shop_note: this.state.shopNote,
									allow_duplicated_phone: this.state.allowDuplicatedPhone
								})
								.then(
									(data) => {
										window.app.alert.setMessage('儲存成功', 'done');
										this.setState({
											isSaved: true
										});
									},
									(error) => {
										window.app.alert.setMessage('儲存失敗', 'error');
									}
								);

							if (this.state.file !== undefined) {
								this.API.updateQueueBackgroundImage(this.state.file).then(
									(data) => {
										this.setState({
											file: undefined
										});
									},
									(error) => {}
								);
							}
							if (this.state.menuFiles !== undefined) {
								this.API.updateQueueMenuImage(this.state.menuFiles, this.state.menuPreviewUrls).then(
									(data) => {
										this.setState({
											menuFiles: []
										});
									},
									(error) => {}
								);
							}
						}}
					>
						儲存
					</button>
				</div>
			</>
		);
	}
}
