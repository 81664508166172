import React, { useState, useEffect, useCallback, useRef, Fragment, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router';
import $ from 'jquery';
import _ from 'lodash';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
	closeAreaModal,
	changeAreaBlockTitle,
	chooseTableGroup,
	checkModalState,
	openEditAvailableTime,
	togglePayment,
	changeDepositEnable,
	changeDeposit,
	changeDepositPeople,
	addNewWeekSetting,
	delSetting
} from '../../../actions/bookingSettingAreaAction';
import SettingEditor from './AreaEditor';

const weeksTxt = [ '星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六' ];

const AreaModal = ({setSaved, serviceTimeOptionEnabled}) => {
	const dispatch = useDispatch();
	const BSareaSetting = useSelector((state) => state.BookingSettingAreaReducer);
	const { tables, space_groups, targetIndex } = BSareaSetting;
	const BSareaModalState = useSelector((state) => state.BookingSettingAreaModalReducer);
	const {
		areaBlockTables,
		areaBlockTitle,
		areaBlockWeekSettings,
		paymentSettings,
		activeEditId,
		editMode,
		setting,
		weekSettingType,
		spgMerchantId
	} = BSareaModalState;

	useEffect(() => {
		show();

		return () => {
			$('.areaModal').modal('hide');
		};
	}, []);

	const show = () => {
		$('.areaModal').modal({ show: true, backdrop: 'static' });
	};

	const handleAreaTitle = (e) => {
		const txt = e.target.value;
		dispatch(changeAreaBlockTitle(txt));
	};

	const handleTableGroup = useCallback(
		(table) => {
			dispatch(chooseTableGroup(table));
		},
		[ dispatch ]
	);

	const renderTableGroups = useCallback(
		() => {
			return _.unionBy(tables, 'group').map((table) => {
				const active = _.includes(areaBlockTables, table.id);
				return (
					<span
						key={table.id}
						className={classNames('groups_table', { active })}
						onClick={() => handleTableGroup(table)}
					>
						{table.group}
					</span>
				);
			});
		},
		[ areaBlockTables, handleTableGroup, tables ]
	);

	const hideModal = () => {
		dispatch(closeAreaModal());
	};

	const checkModal = () => {
		if (areaBlockTitle.trim() === '') {
			window.app.alert.setMessage('請輸入選項名稱', 'tip');
			return;
		}
		if (areaBlockTables.length === 0) {
			window.app.alert.setMessage('請選擇包含的組別', 'tip');
			return;
		}

		const duplicateName = space_groups
			.map((group, index) => {
				if (targetIndex !== index) return group.name;
				return true;
			})
			.indexOf(areaBlockTitle.trim());

		if (duplicateName !== -1) {
			window.app.alert.setMessage('區域標題重複', 'error');
			return;
		}

		dispatch(checkModalState());
		setSaved()
		$('.areaModal').modal('hide');
		dispatch(closeAreaModal());
	};

	const editView=useCallback((index=1)=>{
		const activeWeeks = setting.week_days.map((week) => weeksTxt[week]);
		const activeTimes = setting.setting_temp.setting3;
		const activeTimeRange = setting.setting_temp.setting2;

		return (
			<SettingEditor key={index}>
				<SettingEditor.Title />
				<SettingEditor.Week activeOption={activeWeeks} settingTye="week" />
				<SettingEditor.Enable_2 enable={setting.setting_temp.enable} />
				{setting.setting_temp.enable === 2 && (
					<React.Fragment>
						<SettingEditor.TimeRange activeTimeRange={activeTimeRange} />
						<SettingEditor.Interval interval={setting.booking_time_interval} />
					</React.Fragment>
				)}
				<SettingEditor.Enable_3 enable={setting.setting_temp.enable} />
				{setting.setting_temp.enable === 3 && (
					<SettingEditor.Time activeOption={activeTimes} settingTye="time" />
				)}
				<hr/>
				<SettingEditor.DiningTime
					fixedDiningTime={setting.dining_time}
					diningTimeOptions={setting.service_time_setting[0]}
					serviceTimeOptionEnabled={serviceTimeOptionEnabled}
				/>
			</SettingEditor>
		);
	}, [setting])

	const renderEditMode = () => {
		if (editMode && weekSettingType==='add') {
			return editView()
		}
	};

	const renderTimeSetting = useCallback(() => {
		// if (areaBlockWeekSettings.length === 0 && weekSettingType === '') {
		// 	return <NoSetting>＊無指定的預約時段</NoSetting>;
		// }

		return (
			<div className="availableTime">
				{areaBlockWeekSettings.map((setting, index) => {
					if (weekSettingType==='edit' &&  activeEditId=== index ) {
						return editView(index)
					}

					const showButton = activeEditId === null;
					return (
						<AvailableTimeRow key={index}>
							<AvailableTimeRow.Content
								index={index + 1}
								weekDays={setting.week_days}
								fixedDiningTime={setting.dining_time}
								diningTimeOptions={setting.service_time_setting[0]}
								serviceTimeOptionEnabled={serviceTimeOptionEnabled}
							/>

							<AvailableTimeRow.Button
								show={showButton}
								index={index}
							/>
						</AvailableTimeRow>
					);
				})}
			</div>
		);
	}, [activeEditId, areaBlockWeekSettings, editView, weekSettingType]);

	const renderPaymentSetting = () => {

		if(spgMerchantId === ''){
			return (
				<NoSetting>
					＊您尚未開通付款功能設定，請至<span><Link to={'/dashboard/setting/payment'}> 付款設定 </Link></span>啟用設定
				</NoSetting>
			)
		}

		return (
			<PaymentRow>
				<PaymentRow.Setting paymentSettingsActive={paymentSettings.active} />
				{paymentSettings.active && <PaymentRow.Price paymentSettings={paymentSettings} />}
			</PaymentRow>
		);

	};

	const handleNewWeekSetting = () => {
		dispatch(addNewWeekSetting());
		addNewWeekSettingBtnNoShow()
	};

	return (
		<Fragment>
			<div className="modal fade areaModal">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button
								onClick={() => hideModal()}
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
							<h3>編輯預約選項</h3>
						</div>
						<div className="modal-body">
							<section>
								<h4 style={{marginBottom: '8px'}}>選項名稱</h4>
								<input
									value={areaBlockTitle}
									type="text"
									placeholder="請輸入選項名稱"
									onChange={(e) => handleAreaTitle(e)}
								/>
							</section>
							<section>
								<h4 style={{marginBottom: '16px'}}>包含組別</h4>
								<div>{renderTableGroups()}</div>
							</section>
							<section>
								<h4 className="weekSettings" style={{marginBottom: 0}}>
									預約時段設定
								</h4>
								<div className="bookingSettingAreaModal_subtitle">選擇營業日提供服務的時間，顧客能在您設定的時間預約服務。</div>
								<button id="addNewWeekSettingBtn" className="addButtonStyle" disabled={weekSettingType!==''} onClick={() => handleNewWeekSetting()}>
									+ 新增時段
								</button>
								{renderEditMode()}
								{renderTimeSetting()}
							</section>
							<hr/>
							<section>
								<h4 style={{marginBottom: '16px'}}>付款金額</h4>
								{renderPaymentSetting()}
							</section>
						</div>
						<div className="modal-footer">
							<button className="cancel-btn" onClick={() => dispatch(closeAreaModal())}>
								取消
							</button>
							<button className="solid-btn" disabled={weekSettingType !== ''} onClick={() => checkModal()}>
								完成
							</button>
						</div>

						<div id="areaModalNumpad" />
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const NoSetting = ({ children }) => {
	return <div className="noSetting">{React.Children.map(children, (child) => child)}</div>;
};

const AvailableTimeRow = ({ children }) => {
	return <div className="settingRow active">{React.Children.map(children, (child) => child)}</div>;
};

const AvailableTimeRowContent = ({ weekDays, index, fixedDiningTime, diningTimeOptions, serviceTimeOptionEnabled }) => {
	const diningTimeModeContent = () => {
		let diningTime_min = 0;
		let diningTime_max = 0;

		if(serviceTimeOptionEnabled) {
			if(diningTimeOptions.mode === 'user_setting') {
				if(diningTimeOptions.user_setting.show_name) {
					return (
						<div  style={{marginTop: '12px'}}>
							<span>服務時間：依自訂時段</span>
							<div>服務時間選項名稱：開啟</div>
							<div>
								{diningTimeOptions.user_setting.service_time_setting.map((t, index) => {
									return (
										<div key={index}>
											<span>{t.name} ({Math.floor(parseInt(t.service_time, 10) / 60)} 小時 {Math.floor(parseInt(t.service_time, 10) % 60)} 分鐘)
											{
												index !== diningTimeOptions.user_setting.service_time_setting.length - 1 ? (<span>、</span>) : null
											}
											</span>
										</div>
									)
								})}
							</div>
						</div>
					)
				} else {
					return (
						<div  style={{marginTop: '12px'}}>
							<span>服務時間：依自訂時段</span>
							<div>服務時間選項名稱：關閉</div>
							<div>
								{diningTimeOptions.user_setting.service_time_setting.map((t, index) => {
									return (
										<span key={index}>
											<span>{Math.floor(parseInt(t.service_time, 10) / 60)} 小時 {Math.floor(parseInt(t.service_time, 10) % 60)} 分鐘
											{
												index !== diningTimeOptions.user_setting.service_time_setting.length - 1 ? (<span>、</span>) : null
											}
											</span>
										</span>
									)
								})}
							</div>
						</div>
					)
				}
			} else if (diningTimeOptions.mode === 'fixed') {
				diningTime_min = diningTimeOptions.fixed.min;
				diningTime_max = diningTimeOptions.fixed.max;
	
				return (
					<div  style={{marginTop: '12px'}}>
						<span>服務時間：依固定區間</span>
						<div>
							<span>從最短 {Math.floor(parseInt(diningTime_min, 10) / 60)} 小時 {Math.floor(parseInt(diningTime_min, 10) % 60)} 分鐘</span>
							<span>~</span>
							<span>到最長 {Math.floor(parseInt(diningTime_max, 10) / 60)} 小時 {Math.floor(parseInt(diningTime_max, 10) % 60)} 分鐘</span>
							</div>
						<div>服務時段區間：{diningTimeOptions.fixed.unit}分鐘</div>
					</div>
				)
			}
		} else {
			return (
				<div>
					<span>服務時間： {Math.floor(parseInt(fixedDiningTime, 10) / 60)} 小時 {Math.floor(parseInt(fixedDiningTime, 10) % 60)} 分鐘</span>
				</div>
			)
		}
	}

	return (
		<div className="settingRow__content">
			<p className="settingRow__Title">開放時段{index}</p>
			<div className="settingRow__setting">
				<span>{weekDays.map((w) => weeksTxt[w]).join('、')}</span>
				{diningTimeModeContent()}
			</div>
		</div>
	);
};

const addNewWeekSettingBtnNoShow = () => {
	const addNewWeekSettingBtn = document.getElementById('addNewWeekSettingBtn');
	addNewWeekSettingBtn.style.display = 'none';
}

const AvailableTimeRowButton = ({ show, index }) => {
	const dispatch = useDispatch();

	const handleEdit = () => {
		dispatch(openEditAvailableTime(index));
		addNewWeekSettingBtnNoShow();
	};

	const handleDel = ()=>{
		dispatch(delSetting(index))
	}

	if (!show) return null;
	return (
		<div className="settingRow__button">
			<button className="areaEditButton" onClick={() => handleEdit()}>
				<span>編輯</span>
			</button>
			<button className="areaDelButton" onClick={() => handleDel()}>
				<span>刪除</span>
			</button>
		</div>
	);
};

const PaymentRow = ({ children }) => {
	return <div className="payment">{React.Children.map(children, (child) => child)}</div>;
};

const PaymentRowToggle = ({ paymentSettingsActive=false }) => {
	const dispatch = useDispatch();

	const handleTogglePayment = () => {
		dispatch(togglePayment());
	};

	return (
		<div className="settingRow">
			<div className="settingRow__content">
				<p className="settingRow__Title">線上付款</p>
			</div>
			<div className="settingRow__button">
				<label className="roundSwitcher">
					<input
						className="tgl"
						type="checkbox"
						checked={paymentSettingsActive}
						onChange={() => handleTogglePayment()}
					/>
					<span className="tgl-btn" />
				</label>
			</div>
		</div>
	);
};

const PaymentRowPrice = ({ paymentSettings }) => {
	const dispatch = useDispatch();
	const { enable, setting1, setting2 } = paymentSettings;
	const { deposit, deposit_people_required } = setting1;

	const handleDepotionEnable = (enable) => {
		dispatch(changeDepositEnable(enable));
	};

	const handleDepotionSettingDeposit = (enable, e) => {
		const value = parseInt(e.target.value, 10);
		dispatch(changeDeposit(enable, value));
	};

	const handleDepotionSettingDepositPeople = (e) => {
		const value = parseInt(e.target.value, 10);
		dispatch(changeDepositPeople(value));
	};

	return (
		<div className="paymentSetting">
			<div className="paymentSettingRow">
				<input
					type="radio"
					name="paymentSetting"
					checked={enable === 1}
					onChange={() => handleDepotionEnable(1)}
				/>
				<div>
					<input
						type="number"
						min="1"
						placeholder="1"
						value={deposit_people_required}
						onChange={(e) => handleDepotionSettingDepositPeople(e)}
					/>
					<span>人以上（含），每人</span>
					<input
						type="number"
						min="1"
						placeholder="1"
						value={deposit}
						onChange={(e) => handleDepotionSettingDeposit(1, e)}
					/>
					<span>元</span>
				</div>
			</div>
			<div className="paymentSettingRow">
				<input
					type="radio"
					name="paymentSetting"
					checked={enable === 2}
					onChange={() => handleDepotionEnable(2)}
				/>
				<div>
					<span>固定金額</span>
					<input
						type="number"
						min="1"
						placeholder="1"
						value={setting2}
						onChange={(e) => handleDepotionSettingDeposit(2, e)}
					/>
					<span>元</span>
				</div>
			</div>
		</div>
	);
};

AvailableTimeRow.Content = AvailableTimeRowContent;
AvailableTimeRow.Button = AvailableTimeRowButton;

PaymentRow.Setting = PaymentRowToggle;
PaymentRow.Price = PaymentRowPrice;

export default AreaModal;
