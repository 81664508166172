import React, { useState } from 'react';
import $ from 'jquery';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import PageNav from './PageNav';
import PageFooter from './PageFooter';

import '../../sass/pageContact.scss';

const PageContactNew = () => {
  const [ formData, setFormData] = useState({
    shop_name: '',
    user_name: '',
    phone_number: '',
    email: '',
    address: '',
    line_id: '',
    shop_url: '',
    sources: [],
    user_interests: [],
    note: '',
    sended: false
  });

  const [ emptyRequiredFields, setEmptyRequiredFields ] = useState({
    shop_name: false,
    user_name: false,
    phone_number: false,
    email_empty: false,
    email_format: false,
    address: false,
    sources: false,
    user_interests: false
  }); // 缺少必填欄位

  const [ params ] = useState(window.contactParams);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if(name === 'email') {
      setEmptyRequiredFields({ ...emptyRequiredFields, email_empty: false, email_format: false });
    } else {
      setEmptyRequiredFields({ ...emptyRequiredFields, [name]: false });
    }
  };

  const handlePhone = (e) => {
    const reg = /^[0-9]*$/;
    let value = e.target.value;

    if(reg.test(value)) {
      setFormData({ ...formData, phone_number: value });
      setEmptyRequiredFields({ ...emptyRequiredFields, phone_number: false });
    }
  };

  const handleSource = (e) => {
    let value = e.target.value;
    let idx = formData.sources.indexOf(value);
    let newSource = _.cloneDeep(formData.sources);

    if(idx !== -1) {
      newSource.splice(idx, 1);
    } else {
      newSource.push(value);
    }

    setFormData({ ...formData, sources: newSource });
    setEmptyRequiredFields({ ...emptyRequiredFields, sources: false });
  };

  const handleUserInterests = (e) => {
    let value = e.target.value;
    let idx = formData.user_interests.indexOf(value);
    let newUserInterests = _.cloneDeep(formData.user_interests);

    if(idx !== -1) {
      newUserInterests.splice(idx, 1);
    } else {
      newUserInterests.push(value);
    }

    setFormData({ ...formData, user_interests: newUserInterests });
    setEmptyRequiredFields({ ...emptyRequiredFields, user_interests: false });
  };

  const handleSubmit = (e) => {
    const emailReg = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/;
    const newEmptyRequiredFields = {
      shop_name: formData.shop_name.length === 0 ? true : false,
      user_name: formData.user_name.length === 0 ? true : false,
      phone_number: formData.phone_number.length === 0 ? true : false,
      email_empty: formData.email.length === 0 ? true : false,
      email_format: !emailReg.test(formData.email) ? true : false,
      address: formData.address.length === 0 ? true : false,
      sources: formData.sources.length === 0 ? true : false,
      user_interests: formData.user_interests.length === 0 ? true : false
    };

    setEmptyRequiredFields(newEmptyRequiredFields);

    if(Object.values(newEmptyRequiredFields).some((requiredFields) => requiredFields)) return;

    $.ajax({
      url: window.domain + '/contact_submit',
      data: { data: formData },
      dataType: 'json',
      type: 'POST',
      xhrFields: { withCredentials: true },
      success: function(res) {
        setFormData({ ...formData, sended: true });
        browserHistory.push({
          pathname: '/contact/done', 
          state:{ sended: true }
        });
      }.bind(this),
      error: function() {}
    });
  };

  return (
    <div>
      <PageNav />
      <div className="contact">
        <div
          className="container"
          style={{
            maxWidth: '630px',
            display: (formData.sended ? 'none' : 'block')
          }}
        >
          <div className="title">
            申請試用
            <br />
            MENU店+ 預約排隊系統
          </div>
          <div className='subtitle'>邀請您與我們一起把工作變簡單。</div>
          <div className='text'>
            <p>多元功能與划算計費，適合各式產業；歡迎洽談專案合作。</p>
            <p>請留下聯絡資訊，我們將儘快安排專人為您服務。</p>
          </div>
          {/* content */}
          <div className='form'>
            <div className='form_content'>
              <div className='form_row'>
                <div>店家名稱*</div>
                <div>
                  <input
                    type="text"
                    name='shop_name'
                    className={emptyRequiredFields.shop_name && 'input_error'}
                    value={formData.shop_name}
                    placeholder="MENU店+"
                    onChange={handleInputChange}
                  />
                  {emptyRequiredFields.shop_name && <div className='text_error'>請輸入店家名稱</div>}
                </div>
              </div>
              <div className='form_row'>
                <div>店家所在地址*</div>
                <div>
                  <input
                    type="text"
                    name='address'
                    className={emptyRequiredFields.address && 'input_error'}
                    value={formData.address}
                    placeholder="台北市大同區延平北路一段xx號"
                    onChange={handleInputChange}
                  />
                  {emptyRequiredFields.address && <div className='text_error'>請輸入店家所在地址</div>}
                </div>
              </div>
              <div className='form_row'>
                <div>申請人*</div>
                <div>
                  <input
                    type="text"
                    name='user_name'
                    className={emptyRequiredFields.user_name && 'input_error'}
                    value={formData.user_name}
                    placeholder="楊老闆"
                    onChange={handleInputChange}
                  />
                  {emptyRequiredFields.user_name && <div className='text_error'>請輸入申請人</div>}
                </div>
              </div>
              <div className='form_row'>
                <div>聯絡電話*</div>
                <div>
                  <input
                    type="text"
                    name='phone_number'
                    className={emptyRequiredFields.phone_number && 'input_error'}
                    value={formData.phone_number}
                    pattern="[0-9]+"
                    placeholder="0988816888"
                    onChange={handlePhone}
                  />
                  {emptyRequiredFields.phone_number && <div className='text_error'>請輸入聯絡電話</div>}
                </div>
              </div>
              <div className='form_row'>
                <div>聯絡信箱*</div>
                <div>
                  <input
                    type="text"
                    name='email'
                    className={
                      (
                        emptyRequiredFields.email_empty ||
                        emptyRequiredFields.email_format
                      ) && 'input_error'
                    }
                    value={formData.email}
                    placeholder="support@findlife.com.tw"
                    onChange={handleInputChange}
                  />
                  {emptyRequiredFields.email_empty && <div className='text_error'>請輸入聯絡信箱</div>}
                  {emptyRequiredFields.email_format && <div className='text_error'>請輸入正確的信箱</div>}
                </div>
              </div>
              <div className='form_row'>
                <div>LINE ID</div>
                <div>
                  <input
                    type="text"
                    name='line_id'
                    className="form-control"
                    value={formData.line_id}
                    placeholder="menushop"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='form_row'>
                <div>店家網站或粉絲專頁連結</div>
                <div>
                  <input
                    type="text"
                    name='shop_url'
                    className="form-control"
                    value={formData.shop_url}
                    placeholder="menushop.tw"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='form_block'>
                <div className='form_row'>
                  <div>您是從哪得知MENU店+？*</div>
                  {emptyRequiredFields.sources && <div className='text_error' style={{ marginTop: '4px' }}>請至少勾選一個項目</div>}
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='search'
                        checked={formData.sources.includes('search')}
                        onChange={handleSource}
                      />
                      <span>關鍵字搜尋</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='socialMedia'
                        checked={formData.sources.includes('socialMedia')}
                        onChange={handleSource}
                      />
                      <span>社群網站（Facebook / Instagram）</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='professionIntroduction'
                        checked={formData.sources.includes('professionIntroduction')}
                        onChange={handleSource}
                      />
                      <span>同業介紹</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='professionSystem'
                        checked={formData.sources.includes('professionSystem')}
                        onChange={handleSource}
                      />
                      <span>看到同業的訂位系統</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='menuApp'
                        checked={formData.sources.includes('menuApp')}
                        onChange={handleSource}
                      />
                      <span>MENU美食誌</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='other'
                        checked={formData.sources.includes('other')}
                        onChange={handleSource}
                      />
                      <span>其他</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='form_block'>
                <div className='form_row'>
                  <div>您對哪些功能有興趣？*</div>
                  {emptyRequiredFields.user_interests && <div className='text_error' style={{ marginTop: '4px' }}>請至少勾選一個項目</div>}
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='bookingOnlineSystem'
                        checked={formData.user_interests.includes('bookingOnlineSystem')}
                        onChange={handleUserInterests}
                      />
                      <span>線上預約</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='queueSystem'
                        checked={formData.user_interests.includes('queueSystem')}
                        onChange={handleUserInterests}
                      />
                      <span>排隊候位</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='CRM'
                        checked={formData.user_interests.includes('CRM')}
                        onChange={handleUserInterests}
                      />
                      <span>顧客關係管理</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value='other'
                        checked={formData.user_interests.includes('other')}
                        onChange={handleUserInterests}
                      />
                      <span>其他</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='form_block form_textarea'>
                <div className='form_row'>
                  <div>備註</div>
                  <div>
                    <textarea
                      name='note'
                      value={formData.note}
                      placeholder='若有其他疑問或需求都歡迎提出'
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <button className="btn-submit" onClick={handleSubmit}>送出申請</button>
            <div className='text_note'>
              <p>此表單僅用於申請系統試用。</p>
              <p>如需預約餐廳或其他服務，請直接聯繫該店家。</p>
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </div>
  )
}

export default PageContactNew;