import React, { Fragment } from "react";
import { Modal } from 'antd';
import '../customAntdPopup.scss';

const ResetAccountModal = ({
  type,
  handleEmail,
  remindError,
  submit,
  cancel
}) => {
  const title = type === 'shopAccount' ? '忘記商家帳號' : '忘記管理者帳號或密碼';
  const content = type === 'shopAccount' ? '請輸入申請人聯絡Email，我們會將您的商家帳號發送至您的信箱，若有任何疑問請洽詢MENU店+業務。' : '請輸入申請人聯絡Email，我們會將管理者帳號與重置後的密碼發送至您的信箱，若有任何疑問請洽詢MENU店+業務。';
  return (
    <Modal
      title={title}
      visible
      width={640}
      centered
      className="resetAccountModal common_ant_modal"
      footer={
        <Fragment>
          <button
            className="btn_cancel"
            onClick={cancel}
          >
            取消
          </button>
          <button
            className="btn_save"
            onClick={submit}
          >
            送出
          </button>
        </Fragment>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      <p>{content}</p>
      <input
        type="text"
        placeholder="請輸入申請人Email"
        className={`${remindError.empty || remindError.wrong ? 'input_error' : ''}`}
        onChange={handleEmail}
      />
      {remindError.empty && <div className="text_error">請輸入申請人Email</div>}
      {remindError.wrong && <div className="text_error">查無此信箱</div>}
    </Modal>
  )
}

export default ResetAccountModal;