import axios from 'axios';
import { config } from '../../../utils/config';

const reportAxios = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

/* 取得有第一筆預約資料的日期 */
export const getFirstBookingDate = () => reportAxios.get('/dashboard/project_report/first_booking_date');

/* 取得日期範圍內的預約資料 */
export const getBookingByTimeDimension = (params) => reportAxios.get('/dashboard/project_report/bookings.json', { params });

/* 取得日期範圍內的全部優惠券資料 */
export const getVoucherByTimeDimension = (params) => reportAxios.get('/dashboard/project_report/vouchers.json', { params });