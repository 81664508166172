import React from 'react';
import bookingListEmpty from '../../../images/empty_booking.png';
import { bookingStatusTabJson } from '../utils/data';


export const BookingTags = ({ activeBookingStatusTab, chageBookingStatusTab, bookings }) => {
	return (
		<ul className="bookingSystem__status">
			{Object.keys(bookingStatusTabJson).map((status) => {
				let active = ''
				if(bookingStatusTabJson[status] === bookingStatusTabJson[activeBookingStatusTab] && status === 'confirmed') {
					active = 'bookingSystem__status-active bookingSystem__status-confirmed'
				} else if (bookingStatusTabJson[status] === bookingStatusTabJson[activeBookingStatusTab] && status === 'show') {
					active = 'bookingSystem__status-active bookingSystem__status-show'
				} else if (bookingStatusTabJson[status] === bookingStatusTabJson[activeBookingStatusTab] && status === 'seated') {
					active = 'bookingSystem__status-active bookingSystem__status-seated'
				} else if (bookingStatusTabJson[status] === bookingStatusTabJson[activeBookingStatusTab] && status === 'finish') {
					active = 'bookingSystem__status-active bookingSystem__status-finish'
				} else if (bookingStatusTabJson[status] === bookingStatusTabJson[activeBookingStatusTab] && status === 'unconfirmed') {
					active = 'bookingSystem__status-active bookingSystem__status-unconfirmed'
				} else if (bookingStatusTabJson[status] === bookingStatusTabJson[activeBookingStatusTab] && status === 'no_show') {
					active = 'bookingSystem__status-active bookingSystem__status-no_show'
				} else if (bookingStatusTabJson[status] === bookingStatusTabJson[activeBookingStatusTab] && status === 'cancel') {
					active = 'bookingSystem__status-active bookingSystem__status-cancel'
				}
				
				let statusBookings = bookings.filter((booking) => booking.status === status)
				return (
					<li key={status} id={status} className={active} onClick={() => chageBookingStatusTab(status)}>
						{bookingStatusTabJson[status]}
						<div>{statusBookings.length} 組</div>
					</li>
				);
			})}
		</ul>
	);
};

export const EmptyBookingList = () => {
	return (
		<div className="bookingListEmpty">
			<img src={bookingListEmpty} alt="bookingListEmpty" />
		</div>
	);
};
