import React, { Component } from 'react';
import moment from 'moment';
import { getEstimatedWaitingTime } from './QueuePanelHelper';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// import 'antd/dist/antd.css';
import QueuePanelAPI from './api/QueuePanelAPI';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class QueueListItem extends Component {
	state = {
		notificationLoading: false,
		seatedLoading: false,
		standbyLoading: false,
		hideMailBtn: false,
		editing: false,
		shopmemoTxt: '',
    	tempShopmemoTxt: '',
	};

	sendNotification = (id) => {
		this.setState({
			notificationLoading: true
		});
		setTimeout(() => {
			this.API.sendNotification(id).then(
				(data) => {
					this.setState({
						notificationLoading: false
					});
					if (data !== undefined) {
						this.props.refreshAction();
						// this.props.notificationAction("簡訊發送成功", " 已發送簡訊給 " + this.props.data.name, "#ffffff", "#ffffff")
					}
				},
				(error) => {}
			);
		}, 500);
	};

	changeListItemToSeated = (id) => {
		this.setState({
			seatedLoading: true
		});
		setTimeout(() => {
			this.API.updateQueueRecord(
				id,
				'seated',
				moment().diff(moment(this.props.data.created_at), 'minutes'),
				(data) => {
					this.setState({
						seatedLoading: false
					});
					if (data !== undefined) {
						this.props.refreshAction();
						//this.props.notificationAction("報到成功", this.props.data.name + " 已經順利報到", "#F0FFF9", "#3FBA87")
					}
				}
			);
		}, 500);
	};

	changeListItemToStandby = (id) => {
		this.setState({
			standbyLoading: true
		});

		setTimeout(() => {
			this.API.updateQueueRecord(
				id,
				'standby',
				moment().diff(moment(this.props.data.created_at), 'minutes'),
				(data) => {
					this.setState({
						standbyLoading: false
					});
					if (data !== undefined) {
						this.props.refreshAction();
						//this.props.notificationAction("過號成功", this.props.data.name + " 已經過號", "#FFF2DC", "#F6A622")
					}
				}
			);
		}, 500);
	};

	componentDidMount() {
		this.API = new QueuePanelAPI();
		this.setState({shopmemoTxt: this.props.data.shop_memo, tempShopmemoTxt: this.props.data.shop_memo})
  }
  
  componentDidUpdate(prevProps){

    if(prevProps.data !== this.props.data){
      	this.setState({shopmemoTxt: this.props.data.shop_memo, tempShopmemoTxt: this.props.data.shop_memo})
    }

  }

	renderShopmemo = () => {
    	const { editing, shopmemoTxt } = this.state;
    
		if (editing) {
			return this.renderEditMode();
		} else {
			if (!shopmemoTxt) {
				return (
					<div className="queue-list-item-shopmemo">
						<p className="eidtWrap" onClick={() => this.switchEditMode(true)}>
							<span className="edit-img">
								<img src={require('../../images/queue/memo.svg')} alt="Shop Memo" />
							</span>
							<span className="edit-txt">新增備註</span>
						</p>
					</div>
				);
			} else if (shopmemoTxt.trim() === '') {
				return (
					<div className="queue-list-item-shopmemo">
						<p className="eidtWrap" onClick={() => this.switchEditMode(true)}>
							<span className="edit-img">
								<img src={require('../../images/queue/memo.svg')} alt="Shop Memo" />
							</span>
							<span className="edit-txt">新增備註</span>
						</p>
					</div>
				);
			} else {
				return (
					<div className="queue-list-item-shopmemo">
						<div className="shopmemo">{shopmemoTxt}</div>
						<div className="editBtn">
							<p className="eidtWrap" onClick={() => this.switchEditMode(true)}>
								<span className="edit-img">
									<img src={require('../../images/queue/memo.svg')} alt="Shop Memo" />
								</span>
								<span className="edit-txt">編輯</span>
							</p>
						</div>
					</div>
				);
			}
		}
	};

	renderEditMode = () => {
		const { shopmemoTxt } = this.state;

		return (
			<div className="queue-list-item-shopmemo shopmemo_editMode">
				<textarea value={shopmemoTxt || ''} placeholder="填寫備註" onChange={(e) => this.changeShopmemo(e)} />
				<div className="btns">
					<button className="cancel" onClick={() => this.discardMemo()}>
						取消
					</button>
					<button className="check" onClick={() => this.checkShopMemo()}>
						確定
					</button>
				</div>
			</div>
		);
	};

	switchEditMode = (setting) => {
		this.setState({ editing: setting });
	};

	discardMemo = () => {
		const { tempShopmemoTxt } = this.state;
		this.setState({ shopmemoTxt: tempShopmemoTxt, editing: false });
	};

	changeShopmemo = (e) => {
		let txt = e.target.value;

		this.setState({ shopmemoTxt: txt });
	};

	checkShopMemo = () => {
		const { id } = this.props.data;
		const { shopmemoTxt } = this.state;

		this.API
			.updateQueueShopMemo(id, shopmemoTxt)
			.then((data) => {
				this.switchEditMode(false);
				this.setState({tempShopmemoTxt: shopmemoTxt})
			})
			.catch((err) => {
				window.app.alert.setMessage('請稍後再試', 'error');
			});
	};

	renderEmail=()=>{
		const {email} = this.props.data;
		if(email.trim() === '') return null;

		return <p>{email}</p>
	}

	render() {
		let showEstimateTime =
			this.props.timeCheckingCondition !== undefined && this.props.timeCheckingCondition !== '[]';
		let spanClassName = '';
		let mailBtn;
		let estimatedContent = getEstimatedWaitingTime(
			this.props.timeCheckingCondition,
			this.props.data.waiting_groups
		);
		let estimatedWaitingTime = estimatedContent;

		if (typeof estimatedContent === 'string') {
			estimatedWaitingTime = estimatedContent.startsWith('少於') ? estimatedContent.substr(2) : estimatedContent;
		}

		if (moment().diff(moment(this.props.data.created_at), 'minutes') >= estimatedWaitingTime) {
			spanClassName = 'expired';
		}

		if (this.props.hideAction) {
			mailBtn =
				this.props.data.is_notified === true ? (
					<button>
						<img src={require('../../images/queue/btn_mail_sent.png')} alt="" />
					</button>
				) : (
					<button>
						<img src={require('../../images/queue/btn_mail_open.png')} alt="" />
					</button>
				);
		} else {
			mailBtn =
				this.props.data.is_notified === true ? (
					<button onClick={(e) => this.sendNotification(this.props.data.id)}>
						<img
							src={require('../../images/queue/btn_mail_sent.png')}
							style={this.state.notificationLoading ? { opacity: 0 } : {}}
							alt=""
						/>
						<Spin
							indicator={antIcon}
							spinning={this.state.notificationLoading}
							style={{
								position: 'absolute',
								left: '12px',
								top: '12px',
								color: '#4A90E2'
							}}
						/>
					</button>
				) : (
					<button onClick={(e) => this.sendNotification(this.props.data.id)}>
						<img
							src={require('../../images/queue/btn_mail_open.png')}
							style={this.state.notificationLoading ? { opacity: 0 } : {}}
							alt=""
						/>
						<Spin
							indicator={antIcon}
							spinning={this.state.notificationLoading}
							style={{
								position: 'absolute',
								left: '12px',
								top: '12px',
								color: '#4A90E2'
							}}
						/>
					</button>
				);
		}

		let searchCategory = null;
		if (this.props.searching === true) {
			switch (this.props.data.status) {
				case 'waiting':
					searchCategory = (
						<div
							style={{
								fontSize: '12px',
								background: '#5a92da',
								borderRadius: '4px',
								padding: '2px 6px',
								color: 'white',
								width: '48px'
							}}
						>
							等候中
						</div>
					);
					break;
				case 'seated':
					searchCategory = (
						<div
							style={{
								fontSize: '12px',
								background: '#3fba87',
								borderRadius: '4px',
								padding: '2px 6px',
								color: 'white',
								width: '48px'
							}}
						>
							已報到
						</div>
					);
					break;
				case 'standby':
					searchCategory = (
						<div
							style={{
								fontSize: '12px',
								background: '#f6a622',
								borderRadius: '4px',
								padding: '2px 6px',
								color: 'white',
								width: '48px'
							}}
						>
							過號
						</div>
					);
					break;
				case 'canceled':
					searchCategory = (
						<div
							style={{
								fontSize: '12px',
								background: '#979797',
								borderRadius: '4px',
								padding: '2px 6px',
								color: 'white',
								width: '48px'
							}}
						>
							已取消
						</div>
					);
					break;
				default:
			}
		}

		return (
			<div className="queue-list-item" style={this.props.showBottomSeparator ? { borderBottom: 'none' } : {}}>
				<div className="queue-list-item-main">
					<div className="list-item-category">
						{this.props.data.group_name}
						{searchCategory}
					</div>
					<div className="list-item-number">{this.props.data.waiting_number}</div>
					<div className="list-item-customer">
						<h3>
							{this.props.data.name}{' '}
							{this.props.data.gender === 'other' ? (
								''
							) : this.props.data.gender === 'male' ? (
								'先生'
							) : (
								'小姐'
							)}{' '}
							({this.props.data.people_count}位)
						</h3>
						<p>{this.props.data.phone}</p>
						{this.renderEmail()}
					</div>
					{this.props.data.memo ? <div className="queue-list-item-memo">{this.props.data.memo}</div> : null}

					{this.renderShopmemo()}

					<div className="list-item-actions">
						{this.props.hideStandbyAction ? null : (
							<button onClick={(e) => this.changeListItemToStandby(this.props.data.id)}>
								<img
									src={require('../../images/queue/btn_cancel.png')}
									style={this.state.standbyLoading ? { opacity: 0 } : {}}
									alt=""
								/>
								<Spin
									indicator={antIcon}
									spinning={this.state.standbyLoading}
									style={{
										position: 'absolute',
										left: '12px',
										top: '12px',
										color: '#F6A622'
									}}
								/>
							</button>
						)}
						{this.props.hideSeatedAction ? null : (
							<button
								onClick={(e) => this.changeListItemToSeated(this.props.data.id)}
								disabled={this.state.seatedLoading}
							>
								<img
									src={require('../../images/queue/btn_check_ok.png')}
									style={this.state.seatedLoading ? { opacity: 0 } : {}}
									alt=""
								/>
								<Spin
									indicator={antIcon}
									spinning={this.state.seatedLoading}
									style={{
										position: 'absolute',
										left: '12px',
										top: '12px',
										color: '#3FBA87'
									}}
								/>
							</button>
						)}
						{this.props.hideMailBtn ? null : mailBtn}
					</div>
				</div>

				{showEstimateTime ? (
					<div className="queue-list-item-info">
						{this.props.data.source === 'online' ? '線上 ' : '自建 '}
						{moment(this.props.data.created_at).format('HH:mm')}
						{!this.props.hideAction && !this.props.hideSeatedAction ? (
							'｜前面有 ' + this.props.data.waiting_groups + '組'
						) : (
							''
						)}
						｜預計：
						{getEstimatedWaitingTime(this.props.timeCheckingCondition, this.props.data.waiting_groups)}
						分鐘 ｜已等待：
						<span className={spanClassName}>
							{this.props.data['waiting_time'] === null ? (
								moment().diff(moment(this.props.data.created_at), 'minutes')
							) : (
								this.props.data.waiting_time
							)}
							分鐘
						</span>
					</div>
				) : (
					<div className="queue-list-item-info">
						{this.props.data.source === 'online' ? '線上 ' : '自建 '}
						{moment(this.props.data.created_at).format('HH:mm')}
						{!this.props.hideAction && !this.props.hideSeatedAction ? (
							'｜前面有 ' + this.props.data.waiting_groups + '組'
						) : (
							''
						)}
						｜已等待：
						<span>
							{this.props.data['waiting_time'] === null ? (
								moment().diff(moment(this.props.data.created_at), 'minutes')
							) : (
								this.props.data.waiting_time
							)}
							分鐘
						</span>
					</div>
				)}
			</div>
		);
	}
}

QueueListItem.defaultProps = {
	refreshAction: () => {}
};
