import moment from 'moment';

export function seatedConflictTable({targetBooking = {}, seatedBooking = {}}) {
	//入座檢查
	//要入座的位置若是空的就無須檢查
	let targetBookingStartTime = moment(targetBooking.service_start_time),
		targetBookingEndTime = moment(targetBooking.service_start_time).add(targetBooking.dining_time, 'm');

	//false無衝突 true有衝突
	let conflict = false;

	if (Object.keys(seatedBooking).length !== 0) {
		let seatedBookingStartTime = moment(seatedBooking.service_start_time),
			seatedBookingEndTime = moment(seatedBooking.service_start_time).add(seatedBooking.dining_time, 'm');
		conflict = targetBookingStartTime < seatedBookingEndTime && targetBookingEndTime > seatedBookingStartTime;
	}

	return conflict;
}

export function swapConflictTable({targetBooking = {}, preBookinging = {}, nextBooking = {}}) {
	//交換檢查

	let targetBookingStartTime = moment(targetBooking.service_start_time),
		targetBookingEndTime = moment(targetBooking.service_start_time).add(targetBooking.dining_time, 'm');
	let preBookingStartTime = null,
		preBookingEndTime = null;
	let nextBookingStartTime = null,
		nextBookingEndTime = null;

	//false無衝突 true有衝突
	let pre = false,
		next = false;

	if (Object.keys(preBookinging).length !== 0) {
		preBookingStartTime = moment(preBookinging.service_start_time);
		preBookingEndTime = moment(preBookinging.service_start_time).add(preBookinging.dining_time, 'm');
		pre = targetBookingStartTime < preBookingEndTime && targetBookingEndTime > preBookingStartTime;
	}

	if (Object.keys(nextBooking).length !== 0) {
		nextBookingStartTime = moment(nextBooking.service_start_time);
		nextBookingEndTime = moment(nextBooking.service_start_time).add(nextBooking.dining_time, 'm');
		next = targetBookingStartTime < nextBookingEndTime && targetBookingEndTime > nextBookingStartTime;
	}

	return pre || next;
}
