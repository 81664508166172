import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, LabelList } from 'recharts';
import FileSaver from 'file-saver';
import { useCurrentPng } from "recharts-to-png";

import { dateRangeOption } from './utils/data';

import './reportPanel.scss';

import { 
  getFirstBookingDate,
  getBookingByTimeDimension,
  getVoucherByTimeDimension
} from './api/ReportApi';
import MenuCouponApi from '../menu_coupon/MenuCouponApi';
const menuCouponAPI = new MenuCouponApi();

const ReportPanel = () => {
  const auth = useSelector((state) => state.auth);
	const { name } = auth.shop;

  const [ shopName, setShopName ] = useState('');
  const [ shopCreateDate, setShopCreateDate ] = useState('');

  const [ startDate, setStartDate ] = useState(null); // 從日期
  const [ endDate, setEndDate ] = useState(new Date()); // 到日期
  const [ dateRange, setDateRange ] = useState('last_year');
  const [ dateUnit, setDateUnit ] = useState('month');
  const [ reportType, setReportType ] = useState('bookings'); // 報表資料類型
  const [ allCoupons, setAllCoupons ] = useState([]); // 優惠券
  const [ coupons, setCoupons ] = useState([]); // 優惠券：進行中, 結束
  const [ selectCoupon, setSelectCoupon ] = useState('');
  const [ exportType, setExportType ] = useState(null); // 匯出類型
  const [ loading, setLoading ] = useState(false);

  const [ couponError, setCouponError ] = useState(false);
  const [ exportError, setExportError ] = useState(false);

  const [ chartData, setChartData ] = useState(null); // 顯示的圖表
  const [ chartWidth, setChartWidth ] = useState(0);

  const [ getPng, { ref } ] = useCurrentPng();

  const validCoupon = (startDate, endDate, allCoupons) => {
    let newCoupons = [];
    const getDate = (d) => new Date(d);

    // 優惠券的 “開始日期”或 “結束日期” 與 “日期範圍” 有重疊
    newCoupons = allCoupons.filter((coupon) => {
      return !(
        getDate(coupon.startDateTime) > getDate(endDate) ||
        getDate(coupon.endDateTime) < getDate(startDate)
      )
    });

    setCoupons(newCoupons);
  };

  const getCoupons = useCallback(
		async (startDate, today) => {
			try {
				const list = await menuCouponAPI.getCoupon(name);
        const selectAllCoupon = [{
          title: '全部優惠券',
          id: 'all'
        }];

        let coupons = [];
        let newCoupons = [];

        coupons = list.filter((coupon) => {
          return coupon.status === 'enable' || coupon.status === 'expired'
        });

        newCoupons = selectAllCoupon.concat(coupons);
				setAllCoupons(newCoupons);
        validCoupon(startDate, today, newCoupons);
			} catch (err) {
				console.log('setting report get coupon err-----', err);
			}
		},
		[ name ]
	);

  const getShopInfo = async () => {
    try {
      const data = await menuCouponAPI.getShops();
      setShopName(data.shop.display_name);
      setShopCreateDate(data.shop.created_at);
    } catch (err) {
      console.log('get shop name err-----', err);
    }
  };

  const getBookingByTimeData = async (startDate, endDate, unit) => {
    setLoading(true);

    const params = {
      "dimension": unit,
      "startDate": moment(startDate).format('YYYY-MM-DD'),
      "endDate":  moment(endDate).format('YYYY-MM-DD')
    };

    try {
      const { data } = await getBookingByTimeDimension(params);

      if(unit === 'day') {
        const newData = _.cloneDeep(data.project_reports);

        newData.forEach(item => {
          item.name = item.name.substring(5);
        });

        setChartData(newData);
      } else {
        setChartData(data.project_reports);
      }
      setLoading(false);
    } catch(err) {
      console.log('get bookings by time dimension err ----------', err);
    };
  };

  useEffect(() => {  
    let today = new Date();
    let startDate = '';

    startDate = today.setFullYear(today.getFullYear() - 1);

    setStartDate(startDate);
    getCoupons(startDate, new Date());
    getShopInfo();
    getBookingByTimeData(startDate, new Date(), 'month');
  }, [ getCoupons ]);

  useEffect(() => {
    let newChartWidth = 0;

    // 圖表寬度
    switch (dateRange) {
      case 'last_7':
        newChartWidth = 780;
        break;
      case 'last_30':
        newChartWidth = 1800;
        break;
      case 'last_90':
        newChartWidth = 1800;
        break;
      case 'last_half_year':
        if(dateUnit === 'week') {
          newChartWidth = 3600;
        } else {
          newChartWidth = 780;
        }
        break;
      case 'last_year':
        if(dateUnit === 'week') {
          newChartWidth = 7000;
        } else {
          newChartWidth = 1300;
        }
        break;
      default:
      newChartWidth = 780;
    }

    setChartWidth(parseInt(newChartWidth, 10));
  }, [ dateRange, dateUnit ]);

  useEffect(() => {
    const couponSelect = document.getElementsByClassName('coupon_select')[0];
    const couponText = document.getElementsByClassName('coupon_text')[0];
    
    if(reportType === 'vouchers') {
      if(couponError) {
        couponSelect.style.borderColor = '#EF6868';
        couponText.style.color = '#EF6868';
      } else {
        couponSelect.style.borderColor = '#C8C8C8';
        couponText.style.color = '#676767';
      }
    }
  }, [ reportType, couponError ])

  const getVoucherByTimeData = async (startDate, endDate, unit, voucherId) => {
    setLoading(true);

    const params = {
      "dimension": unit,
      "startDate": moment(startDate).format('YYYY-MM-DD'),
      "endDate":  moment(endDate).format('YYYY-MM-DD')
    };

    if(voucherId !== 'all') {
      params.voucherId = voucherId
    }

    try {
      const { data } = await getVoucherByTimeDimension(params);

      if(unit === 'day') {
        const newData = _.cloneDeep(data.project_reports);

        newData.forEach(item => {
          item.name = item.name.substring(5);
        });

        setChartData(newData);
      } else {
        setChartData(data.project_reports);
      }

      setLoading(false);
    } catch(err) {
      console.log('get vouchers by time dimension err ----------', err);
    };
  };

  const handleDisabledDate = (current) => {
    const date = new Date(shopCreateDate);
    // Can not select days before first booking date
    return current < moment().year(date.getFullYear()).month(date.getMonth()).date(date.getDate() - 1);
  };

  const handleExportChart = useCallback(async () => {
    const png = await getPng();

    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(png, 'myChart.png');
    }
  }, [ getPng ]);

  const handleDateCalc = (endDate, range, unit) => {
    let newStartDate = new Date(endDate);

    if(range === 'last_7') {
      // 過去7天
      newStartDate.setDate(endDate.getDate() - 6);
    } else if(range === 'last_30') {
      // 過去30天
      newStartDate.setDate(endDate.getDate() - 29);
    } else if(range === 'last_90') {
      // 過去90天
      newStartDate.setDate(endDate.getDate() - 89);
    } else if(range === 'last_half_year') {
      // 過去半年
      newStartDate.setMonth(endDate.getMonth() - 6);
    } else {
      // 過去一年
      newStartDate.setFullYear(endDate.getFullYear() - 1);
    }

    setStartDate(newStartDate);
    validCoupon(newStartDate, endDate, allCoupons);

    if(reportType === 'bookings') {
      getBookingByTimeData(newStartDate, endDate, unit);
    } else {
      getVoucherByTimeData(newStartDate, endDate, unit, selectCoupon);
    }
  };

  const handleEndDate = (date) => {
    let newEndDate = new Date(date);

    setEndDate(newEndDate);
    handleDateCalc(newEndDate, dateRange, dateUnit);
  };
  
  const handleDateRange = (e) => {
    let newRange = e.target.value;
    let newDateUnit = '';
    let index = dateRangeOption.findIndex((range) => range.value === newRange );

    if(newRange === 'last_half_year' || newRange === 'last_year') {
      newDateUnit = dateRangeOption[index].option[1].value;
    } else {
      newDateUnit = dateRangeOption[index].option[0].value;
    }

    setDateUnit(newDateUnit);
    setDateRange(newRange);
    handleDateCalc(endDate, newRange, newDateUnit);
  };

  const handleDateUnit = (e) => {
    let unit = e.target.value;

    setDateUnit(unit);
    handleDateCalc(endDate, dateRange, unit);
  };

  const handleReportType = (e) => {
    let type = e.target.value;

    setChartData(null);

    if(type === 'bookings') {
      setSelectCoupon('');
      getBookingByTimeData(startDate, endDate, dateUnit);
    } else {
      if(selectCoupon) getVoucherByTimeData(startDate, endDate, dateUnit, selectCoupon);
    }

    setReportType(type);
  };

  const handleExportType = (e) => {
    let type = e.target.value;
    setExportType(type);
  };

  const handleSelectCoupon = (e) => {
    let value = e.target.value;

    getVoucherByTimeData(startDate, endDate, dateUnit, value);
    setSelectCoupon(value);
  }

  const handleExportCsv = () => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    const anchor = document.createElement('a');
    anchor.href = window.domain +
      '/dashboard/project_report/' + reportType +
      '.csv?dimension=' + dateUnit +
      '&startDate=' + start_date +
      '&endDate=' + end_date;
    
    if(selectCoupon && selectCoupon !== 'all') anchor.href += `&voucherId=${selectCoupon}`;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const submit = () => {
    let error_coupon = false;
    let error_export = false;

    if(reportType === 'vouchers' && !selectCoupon) {
      error_coupon = true;
      setCouponError(error_coupon);
    } else {
      error_coupon = false;
      setCouponError(error_coupon);
    }

    if(!exportType) {
      error_export = true;
      setExportError(error_export);
    } else {
      error_export = false;
      setExportError(error_export);
    }

    if(!error_coupon && !error_export) {
      if(exportType === 'csv') {
        // 匯出 csv 檔
        handleExportCsv();
      } else {
        // 匯出折線圖
        handleExportChart();
      }
    }
  };

  const renderCouponSelect = () => {
    return (
      <div className='select_coupon'>
        <select
          className="report_select coupon_select"
          value={selectCoupon}
          onChange={(e) => handleSelectCoupon(e)}
          required
        >
          <option disabled defaultValue value=''>選擇優惠券</option>
          { coupons.map((coupon) => {
            return (
              <option key={coupon.id} value={coupon.id}>
                { coupon.title }
              </option>
            );
          })}
        </select>
        <div className='coupon_text'>選擇匯出的優惠券。</div>
      </div>
    )
  };

  const renderDateUnit = () => {
    let newDateUnit = [];
    let index = dateRangeOption.findIndex((range) => range.value === dateRange );
    newDateUnit = dateRangeOption[index].option;

    return (
      <select
        className="report_select"
        value={dateUnit}
        onChange={(e) => handleDateUnit(e)}
      >
        { newDateUnit.map((unit, index) => {
          return (
            <option key={index} value={unit.value}>
              { unit.title }
            </option>
          );
        })}
      </select>
    )
  };

  const renderFromDate = () => {
    let newDate = moment(startDate).format('YYYY年MM月DD日');
    return newDate;
  };

  const renderChart = () => {
    return (
      <div className='chart_box'>
        { loading &&
          <div className='loading'>
            <LoadingOutlined style={{ fontSize: '24px' }} />
            Loading ...
          </div>
        }
        <LineChart
          width={chartWidth}
          height={350}
          data={chartData}
          margin={{ top: 5, right: 20, left: -10, bottom: 30 }}
          ref={ref}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name">
            <Label
              value={menuShopAndShopName}
              offset={-25}
              position="insideBottomLeft"
            />
          </XAxis>
          <YAxis />
          <Tooltip />
          <Legend
            verticalAlign="top"
            align='left'
            iconType='rect'
            iconSize={18}
            height={50}
          />
          <Line
            type="monotone"
            dataKey={reportType === 'bookings' ? '預約人數' : '領取張數'}
            stroke="#349699"
            activeDot={{ r: 5 }}
          >
            <LabelList
              dataKey={reportType === 'bookings' ? '預約人數' : '領取張數'}
              position="top"
            />
          </Line>
          <Line
            type="monotone"
            dataKey={reportType === 'bookings' ? '預約筆數' : '核銷張數'}
            stroke="#FF991F"
            activeDot={{ r: 5 }}
          >
            <LabelList
              dataKey={reportType === 'bookings' ? '預約筆數' : '核銷張數'}
              position="top"
            />
          </Line>
        </LineChart>
      </div>
    )
  };

  const menuShopAndShopName = `MENU店+線上訂候位平台整合系統 - ${shopName}`;

  return (
    <div className='main overflow reportPanel' style={{ maxWidth: '840px' }}>
			<div style={{ display: 'flex' }}>
				<div style={{ flexGrow: 1 }}>
					<h3>匯出數據報表</h3>
					<h5>您可以在這裡匯出預約或優惠券的報表結果。</h5>
				</div>
			</div>
      <hr />

      <div className='block'>
        <div className='subtitle'>資料日期範圍設定</div>
        <div className='date_range'>
          <div className='date_range_block'>
            <div className='text' style={{ marginBottom: '20px' }}>從</div>
            <div className='content'>{ renderFromDate() }</div>
          </div>
          <div className='date_range_block'>
            <div className='text'>到</div>
            <div className='content'>
              <DatePicker
                className="dateRangePicker"
                placeholder='該日期之後'
                defaultValue={endDate && moment(endDate, 'YYYY-MM-DD')}
                onChange={(date) => handleEndDate(date)}
                allowClear={false}
                disabledDate={handleDisabledDate}
              />
            </div>
          </div>
          <div className='date_range_block'>
            <div className='text'>日期範圍</div>
            <div className='content'>
              <select
                className="report_select"
                value={dateRange}
                onChange={(e) => handleDateRange(e)}
              >
                { dateRangeOption.map((range, index) => {
                  return (
                    <option key={index} value={range.value}>
                      { range.title }
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className='date_range_block'>
            <div className='text'>報表時間單位</div>
            <div className='content'>
              { renderDateUnit() }
            </div>
            <div className='content' style={{ marginTop: '8px' }}>作為匯出報表的基本單位。</div>
          </div>
        </div>
      </div>

      <div className='block'>
        <div className='subtitle'>選擇報表資料類型</div>
        <label className='report_radio' style={{ marginTop: '16px' }}>
          <input
            type="radio"
            name="report_type"
            value='bookings'
            checked={reportType === 'bookings'}
            onChange={(e) => handleReportType(e)}
          />
          <div className='option'>
            <div>預約</div>
            <p>包含每個時間單位的預約人數及預約筆數。</p>
          </div>
        </label>
        <label className='report_radio'>
          <input
            type="radio"
            name="report_type"
            value='vouchers'
            checked={reportType === 'vouchers'}
            onChange={(e) => handleReportType(e)}
          />
          <div className='option'>
            <div>優惠券</div>
            <p>包含每個時間單位的優惠券領取張數及核銷張數。</p>
          </div>
        </label>
        { reportType === 'vouchers' && renderCouponSelect() }
      </div>

      { renderChart() }

      <div className='block'>
        <div className='subtitle'>選擇匯出類型</div>
        { exportError && <div className='error_text'>選擇匯出的類型。</div> }
        <label className='report_checkbox' style={{ marginTop: '16px' }}>
          <input
            type="checkbox"
            value='csv'
            onChange={(e) => handleExportType(e)}
            checked={exportType === 'csv'}
          />
          <span>CSV</span>
        </label>
        <label className='report_checkbox'>
          <input
            type="checkbox"
            value='chart'
            onChange={(e) => handleExportType(e)}
            checked={exportType === 'chart'}
          />
          <span>折線圖</span>
        </label>
      </div>

      <button
        className='btn_export'
        onClick={() => submit()}
      >
        匯出報表
      </button>
    </div>
  )
};

export default ReportPanel;