import React from 'react';
import { useDrag } from 'react-dnd';
import moment from 'moment';

const scale = 48;
const statusColor = {
	confirmed: '#3fba88',
	unconfirmed: '#bad959',
	show: '#42a0a4',
	seated: '#27496e',
	noShow: '#676767',
	cancel: '#c8c8c8',
	finish: '#664c77'
};

export default ({ date, setBookingListInfo, booking, tableName, startDrag, bookingIndex }) => {
	const BookingID = `${booking.id}_${tableName}`;
	const [ { opacity }, drag ] = useDrag({
		item: { id: BookingID, type: 'bookingCell' },
		begin() {
			startDrag({ booking, tableName, bookingIndex });
		},
		collect: (monitor) => {
			return {
				opacity: monitor.isDragging() ? 0.4 : 1
			};
		}
	});

	const time = moment(new Date(booking['service_start_time']));
	const duration = moment.duration(time.diff(new Date(date))).asHours() * 2 * scale + 1;
	const style = {
		left: duration,
		width: booking.dining_time / 30 * scale - 2,
		backgroundColor: statusColor[booking.status]
	};

	const renderBookingTime = () => {
		if (parseInt(booking.dining_time, 10) >= 30) {
			return <span className="bookingTime">{booking.time}</span>;
		}
	};

	const renderBookingName = () => {
		if (parseInt(booking.dining_time, 10) >= 31) {
			return <span className="bookingName">{booking.last_name}</span>;
		}
	};

	const renderBookingPhone = () => {
		if (parseInt(booking.dining_time, 10) >= 31) {
			return <span className="bookingPhone">{booking.phone_number}</span>;
		}
	};

	const renderBookingAttendace = () => {
		if (parseInt(booking.dining_time, 10) >= 60) {
			return <span className="bookingAttendance">{booking.attendance}人</span>;
		}
	};

	return (
		<div
			ref={drag}
			style={{
				...style,
				opacity
			}}
			className="timelineBookingCell"
			onClick={(e) => setBookingListInfo(e, booking)}
		>
			{renderBookingTime()}
			{renderBookingName()}
			{renderBookingPhone()}
			{renderBookingAttendace()}
		</div>
	);
};
