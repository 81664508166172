import React from 'react';
import { Link } from 'react-router';

const SettingIcon = () => {
	return (
		<div className="main-item">
			<Link
					to={{
						pathname: '/dashboard/setting'
					}}
					role="button"
				>
					<div className="function-thub">
						<img alt="booking" src={require('../../images/component/f_setting.svg')} />
						<div className="caption function-title">
							<h3>設定</h3> <p>店家系統設定</p>
						</div>
					</div>
				</Link>
		</div>
	);
};

export default SettingIcon;
