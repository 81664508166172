import React from 'react';

import '../../stylesheets/new_module_layout.css';

const MenuCouponEntry = ({ children }) => (
	<div className="menuCouponWrap newModuleWrap" style={{ minHeight: window.innerHeight }}>
		<div className="newModule">
			<aside className="newModule__aside" />
			<div className="newModule__main">
				<div className="newModule__main__radius">{children}</div>
			</div>
		</div>
		<div id="modalPortal" />
	</div>
);

export default MenuCouponEntry;
