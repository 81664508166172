import { Component } from "react";
import { connect } from "react-redux";

class RestrictPage extends Component {
  componentDidMount() {
    this.checkUser();
  }

  componentDidUpdate(prevProps){
    if (prevProps.user !== this.props.user) {
      this.checkUser();
    }
  }

  checkUser() {
    const { router, user } = this.props;
    // If this page is restricted, go to loginPage first.
    // (But pass on this page's path in order to redirect back upon login)
    if (!user) {
      const path = this.props.location.pathname + encodeURIComponent(this.props.location.search);
      router.push(`/login?redirect=${path}`)
    } else {
      if(user.first_time_login_password) {
        router.push('/dashboard/users/password')
      }
    }
  }

  render() {
    const { user } = this.props;

    if (user) {
      return this.props.children;
    }else{
      return null
    }
  }
}

// RestrictPage.propTypes = {
//   user: PropTypes.object,
//   children: PropTypes.object,
//   location: PropTypes.object
// };

// RestrictPage.contextTypes = {
//   router: PropTypes.object.isRequired
// };

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps)(RestrictPage);
