import React, { useState, useContext, Fragment } from 'react';
import { createPortal } from 'react-dom';

import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';
import { ListStateContext } from '../NewModuleReducer/ListReducer';
import { WarningTemplate } from '../NewModuleComponent/NewModuleWarning';

const QueueActionButton = ({ updateQueueSystem, setShowQueueGroupPanel }) => {
	const { ListReducerState } = useContext(ListStateContext);

	const { queueGroup, queueState } = ListReducerState;

	const { CommomSettingState } = useContext(CommomSettingContext);
	const { isToday } = CommomSettingState;

	const [ showEndQueue, setShowEndQueue ] = useState(false);
	const [ showPauseQueue, setShowPauseQueue ] = useState(false);

	const showWarning = (state) => {
		if (state === 'end') {
			setShowEndQueue(true);
		} else if (state === 'pause') {
			setShowPauseQueue(true);
		}
	};

	const confirmWarning = (state) => {
		updateQueueSystem(state);

		if (state === 'end') {
			setShowEndQueue(false);
			setShowQueueGroupPanel(false)
		} else if (state === 'pause') {
			setShowPauseQueue(false);
		}
	};

	const cancelWarning = (state) => {
		if (state === 'end') {
			setShowEndQueue(false);
		} else if (state === 'pause') {
			setShowPauseQueue(false);
		}
	};

	const renderActionButton = () => {
		//當日才顯示按鈕且有設定排隊組別
		if (isToday && queueGroup.length !== 0) {
			if (queueState === 'normal') {
				return (
					<div className="queueBtnWrap">
						<button className="queueBtn-finish" onClick={() => showWarning('end')}>
							<i className="glyphicon glyphicon-stop"/> 結束排隊
						</button>
						<button className="queueBtn-pause" onClick={() => showWarning('pause')}>
							<i className="glyphicon glyphicon-pause" /> 暫停排隊
						</button>
					</div>
				);
			} else if (queueState === 'pause') {
				return (
					<div className="queueBtnWrap">
						<button className="queueBtn-finish" onClick={() => showWarning('end')}>
							<i className="glyphicon glyphicon-stop"/> 結束排隊
						</button>
						<button className="queueBtn-pause" onClick={() => updateQueueSystem('start')}>
							<i className="glyphicon glyphicon-play"/> 重啟排隊
						</button>
					</div>
				);
			}
		}
	};

	return (
		<Fragment>
			{renderActionButton()}

			{/* 結束排隊警告 */}
			{showEndQueue && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title="結束線上排隊"
						type="end"
					>
						<p>您是否確定“結束線上排隊”？</p>
						<p>結束後，今日所有排隊將失效</p>
					</WarningTemplate>
				</Portal>
			)}

			{/* 暫停排隊警告 */}
			{showPauseQueue && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title="暫停線上排隊"
						type="pause"
					>
						<p>您是否確定“暫停線上排隊”？</p>
						<p>暫停後，顧客將無法使用線上排隊</p>
					</WarningTemplate>
				</Portal>
			)}
		</Fragment>
	);
};

const Portal = ({ children }) => {
	return createPortal(children, document.getElementById('modalPortal'));
};

export default QueueActionButton;
