import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, browserHistory } from 'react-router';

import '../../sass/menu_coupon_empty.scss';



const MenuCouponEmpty = ({ addCoupon }) => {

	return (
		<div className="menuCouponEmpty">
			<h2>歡迎使用優惠券專區</h2>
            <p>開始新增優惠券，您將可在這裡檢視/管理您所建立的優惠券。</p>

            <button className="greenThemeBtn" onClick={addCoupon}>新增第一張優惠券</button>
            <p>您所建立的優惠券將會上架於 美食誌 App。</p>
		</div>
	);
};

export default MenuCouponEmpty;

