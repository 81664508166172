import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';

import {
  showAnnouncementAddModal,
  announcementSettingCancel,
  clearAnnouncement
} from '../../../actions/announcementAction';

const AnnouncementCancelModal = () => {
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    id,
    showPreviewAnnouncementModal
  } = announcementInfo;
  const dispatch = useDispatch();
  const title = id ? '編輯' : '新增';

  const cancelPopup = () => {
    dispatch(announcementSettingCancel());
  };

  const submit = () => {
    // reload data
    if(showPreviewAnnouncementModal) {
      dispatch(showAnnouncementAddModal());
      dispatch(announcementSettingCancel());
    } else {
      dispatch(clearAnnouncement());
      dispatch(announcementSettingCancel());
    }
  };

  const renderBtn = () => {
    return (
      <div>
        <button
          className='btn_cancel'
          onClick={() => cancelPopup()}
        >
          取消
        </button>
        <button
          className='btn_save'
          onClick={() => submit()}
        >
          確認
        </button>
      </div>
    )
  }

  return (
    <Modal
      title={`放棄${title}嗎？`}
      visible
      centered
      className="announcementCancelModal"
      onCancel={cancelPopup}
      footer={renderBtn()}
			transitionName="ant-move-down"
      maskClosable={false}
    >
      退出將不會保留公告內容。確定退出嗎？
    </Modal>
  );
};

export default AnnouncementCancelModal;