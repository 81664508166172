import $ from 'jquery';
import { config } from '../../utils/config';

export default class PageApi {
	getUsers(){
		return new Promise((resolve, reject) => {
			$.ajax({
				url: config.domain + '/dashboard/users/profile.json',
				method: 'GET',
				xhrFields: { withCredentials: true }
			})
				.done((data) => {
					resolve(data);
				})
				.fail((xhr) => {
					reject(xhr);
				});
		});
	}

	updateUsers(userId, value){
		return new Promise((resolve, reject)=>{
			$.ajax({
				url: config.domain + '/dashboard/users/'+userId,
				method: 'PATCH',
				dataType: 'json',
				data:{user:{seen_features: JSON.stringify(value)}},
				xhrFields: { withCredentials: true }
			})
				.done(() => {
				})
				.fail((xhr) =>{
					reject(xhr);
				});
		})
	}
}

