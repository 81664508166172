import axios from 'axios';
import { config } from '../../../utils/config';

const HaveSeenNewFeatureApi = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

export const getUsers = () => HaveSeenNewFeatureApi.get('/dashboard/users/profile.json');
export const updateUsers = (userId, value) =>
	HaveSeenNewFeatureApi.patch(`/dashboard/users/${userId}`, {
		user: { seen_features: JSON.stringify(value) }
	});
