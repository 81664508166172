import React, { useEffect }  from 'react';
import { bookingStatusTabJson } from '../../new_module/utils/data';

export const BookingListTab = ({scrollToActiveCode, activeBookingListStatusTab, changeBookingListStatusTab, bookings}) => {

	useEffect(() => {
		if(scrollToActiveCode) {
			setTimeout(() => {
				changeBookingListStatusTab(scrollToActiveCode.status);
			}, 0)
		}
	}, [])
	
	return (
		<ul className="bookingList__status">
			{Object.keys(bookingStatusTabJson).map((status) => {
        let active = bookingStatusTabJson[status] === bookingStatusTabJson[activeBookingListStatusTab] ? "bookingList__status-active" : ""
				
				let statusBookings = bookings.filter((booking) => booking.status === status)
				return (
					<li id={status} key={status} className={active}  onClick={() => changeBookingListStatusTab(status)}>
						{bookingStatusTabJson[status]} <span>{statusBookings.length}</span> 組
					</li>
				);
			})}
		</ul>
	);
};
