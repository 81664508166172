import React, { useState, useContext } from 'react';
import moment from 'moment';

import { useInterval } from './hooks/useInterval';

import { BaseSettingContext } from '../new_module/NewModuleReducer/BaseSettingReducer';

// waiting standby canceled
const status = {
	waiting: '等待中',
	standby: '過號',
	canceled: '取消'
};

const gender = {
	male: '先生',
	female: '小姐'
};

export default ({ queue, toList }) => {
	const { BaseSettingState } = useContext(BaseSettingContext);
	const { timeChecking } = BaseSettingState;

	const getEstimatedWaitingTime = (waitingGroups) => {
		let timeCheckingConditions = [];
		try {
			timeCheckingConditions = JSON.parse(timeChecking);
		} catch (e) {
			// console.log('Parse condition failed');
		}

		if (Array.isArray(timeCheckingConditions) && timeCheckingConditions.length > 1) {
			timeCheckingConditions.sort((first, second) => first.count - second.count);
		}

		let estimatedWaitingTime = 0;

		for (let i = 0; i < timeCheckingConditions.length; i++) {
			const element = timeCheckingConditions[i];
			if (waitingGroups <= element.count) {
				if (i === 0) {
					if (waitingGroups === element.count) {
						estimatedWaitingTime = element.time;
					} else {
						estimatedWaitingTime = '少於 ' + element.time;
					}
				} else {
					const prevElement = timeCheckingConditions[i - 1];
					estimatedWaitingTime = prevElement.time;
				}
				break;
			} else {
				const prevElement = timeCheckingConditions[i];
				estimatedWaitingTime = prevElement.time;
			}
		}

		return estimatedWaitingTime;
	};

	return (
		<div
			className="bookingCard queueCard"
			onClick={() => toList(moment(queue.created_at).format('YYYY-MM-DD'), queue)}
		>
			<div className="queueCardTop">
				<span className={`statusTag statusTag-${queue.status}`}>{status[queue.status]}</span>
				<span className="groupName">{queue.group_name}</span>
			</div>
			<div className="queueCardContent">
				<div className="infoFlex">
					<div className="infoLeft">
						<h2>
							{queue.name}
							<span>{queue.gende ? gender[queue.gender] : null}</span>
						</h2>
						<div className="attendance">
							<span>{queue.people_count}</span>
							<span>人</span>
						</div>
					</div>
					<div className="infoRight infoRight-waiting">{queue.waiting_number}</div>
				</div>
			</div>
			<div className="bottom">
				<div>{queue.phone}</div>
				<div className="extra">
					<div>前面有 {queue.waiting_groups} 組</div>
					<div className="code">
						預計等待 {getEstimatedWaitingTime(queue.waiting_groups)} 分鐘
						<WaitingTimeCount queue={queue} />
					</div>
				</div>
			</div>
		</div>
	);
};

const WaitingTimeCount = ({ queue }) => {
	const [ currentStamp, setCurrentStamp ] = useState(moment());
	const [ interval ] = useState(queue.waiting_time === null ? 1000 : null);

	useInterval(() => {
		setCurrentStamp(moment());
	}, interval);

	return (
		<span className="waitingTimeCount">
			已等待 <span className="queueStatus-alert">
				{queue.waiting_time === null ? (
					currentStamp.diff(moment(queue.created_at), 'minutes')
				) : (
					queue.waiting_time
				)}
			</span> 分鐘
		</span>
	);
};
