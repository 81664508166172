import React, { useEffect, useContext } from 'react';
import { SignUpStateContext } from '../MenuCouponContext/MenuCouponSignUpContext';
import SignUpStepOne from './SignUpStep_1';
import SignUpDone from './SignUpDone';

import '../../../sass/menu_coupon_signup.scss';

const MenuCouponSignUp = () => {
	const { SignUpState } = useContext(SignUpStateContext);
	const { step } = SignUpState;

	// shopInfo 店家資訊
	// done 綁定完成

	useEffect(() => {
		const $body = document.getElementsByTagName('body')[0];
		$body.classList.add('fixBody');

		return () => {
			$body.classList.remove('fixBody');
		};
	}, []);

	return (
		<section className="menuCouponSignUp">
			{step === 'shopInfo' && <SignUpStepOne />}
			{step === 'done' && <SignUpDone />}
		</section>
	);
};

export default MenuCouponSignUp;
