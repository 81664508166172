import _ from 'lodash';
import moment from 'moment';
import BookingSystemApi from '../components/booking_settings/BookingSystemApi';

export const BOOKINGSETTINGAREA_FETCHSTART = 'BOOKINGSETTINGAREA_FETCHSTART';
export const BOOKINGSETTINGAREA_FETCHSUCCESS = 'BOOKINGSETTINGAREA_FETCHSUCCESS';
export const BOOKINGSETTINGAREA_FETCHFAILURE = 'BOOKINGSETTINGAREA_FETCHFAILURE';
export const BOOKINGSETTINGAREA_SWITCHSETTING = 'BOOKINGSETTINGAREA_SWITCHSETTING';
export const BOOKINGSETTINGAREA_CHAGEAREATITLE = 'BOOKINGSETTINGAREA_CHAGEAREATITLE';
export const BOOKINGSETTINGAREA_ADDNEWAREABLOCKOPTION = 'BOOKINGSETTINGAREA_ADDNEWAREABLOCKOPTION';
export const BOOKINGSETTINGAREA_OPENMODAL = 'BOOKINGSETTINGAREA_OPENMODAL';
export const BOOKINGSETTINGAREA_CLOSEMODAL = 'BOOKINGSETTINGAREA_CLOSEMODAL';
export const BOOKINGSETTINGAREA_CLEARSETTING = 'BOOKINGSETTINGAREA_CLEARSETTING';
export const BOOKINGSETTINGAREA_SETAREAOPTION = 'BOOKINGSETTINGAREA_SETAREAOPTION';
export const BOOKINGSETTINGAREA_CANCELWARNING = 'BOOKINGSETTINGAREA_CANCELWARNING';
export const BOOKINGSETTINGAREA_DELETEAREABLOCK = 'BOOKINGSETTINGAREA_DELETEAREABLOCK';
export const BOOKINGSETTINGAREA_EDITAREABLOCK = 'BOOKINGSETTINGAREA_EDITAREABLOCK';
export const BOOKINGSETTINGAREA_CHANGEAREABLOCKTITLE = 'BOOKINGSETTINGAREA_CHANGEAREABLOCKTITLE';
export const BOOKINGSETTINGAREA_CLICKTABLEGROUP = 'BOOKINGSETTINGAREA_CLICKTABLEGROUP';
export const BOOKINGSETTINGAREA_INITMODALSTATE = 'BOOKINGSETTINGAREA_INITMODALSTATE';
export const BOOKINGSETTINGAREA_UPDATEMODALSTATE = 'BOOKINGSETTINGAREA_UPDATEMODALSTATE';
export const BOOKINGSETTINGAREA_CLEARMODALSTATE = 'BOOKINGSETTINGAREA_CLEARMODALSTATE';
export const BOOKINGSETTINGAREA_SENDING = 'BOOKINGSETTINGAREA_SENDING';
export const BOOKINGSETTINGAREA_DISABLESENDING = 'BOOKINGSETTINGAREA_DISABLESENDING';
export const BOOKINGSETTINGAREA_UPDATEAREA = 'BOOKINGSETTINGAREA_UPDATEAREA';
export const BOOKINGSETTINGAREA_SYNCAVAILABLETIME = 'BOOKINGSETTINGAREA_SYNCAVAILABLETIME';
export const BOOKINGSETTINGAREA_EDITAVAILABLETIME = 'BOOKINGSETTINGAREA_EDITAVAILABLETIME';
export const BOOKINGSETTINGAREA_CHANGESETTINGWEEK = 'BOOKINGSETTINGAREA_CHANGESETTINGWEEK';
export const BOOKINGSETTINGAREA_CHANGESETTINGTIME = 'BOOKINGSETTINGAREA_CHANGESETTINGTIME';
export const BOOKINGSETTINGAREA_CHANGEDISPLAYTIME = 'BOOKINGSETTINGAREA_CHANGEDISPLAYTIME';
export const BOOKINGSETTINGAREA_CHANGEDININGTIMEFIXED = 'BOOKINGSETTINGAREA_CHANGEDININGTIMEFIXED';
export const BOOKINGSETTINGAREA_CHANGEDININGTIMEUSERSETTING = 'BOOKINGSETTINGAREA_CHANGEDININGTIMEUSERSETTING';
export const BOOKINGSETTINGAREA_CANCELSETTINGEDIT = 'BOOKINGSETTINGAREA_CANCELSETTINGEDIT';
export const BOOKINGSETTINGAREA_SAVESETTINGEDIT = 'BOOKINGSETTINGAREA_SAVESETTINGEDIT';
export const BOOKINGSETTINGAREA_TOGGLEPAYMENT = 'BOOKINGSETTINGAREA_TOGGLEPAYMENT';
export const BOOKINGSETTINGAREA_CHANGEDEPOSITENABLE = 'BOOKINGSETTINGAREA_CHANGEDEPOSITENABLE';
export const BOOKINGSETTINGAREA_CHANGEDEPOSIT = 'BOOKINGSETTINGAREA_CHANGEDEPOSIT';
export const BOOKINGSETTINGAREA_SYNCMODALPAYMENT = 'BOOKINGSETTINGAREA_SYNCMODALPAYMENT';
export const BOOKINGSETTINGAREA_CHANGEDEPOSITPEOPLE = 'BOOKINGSETTINGAREA_CHANGEDEPOSITPEOPLE';
export const BOOKINGSETTINGAREA_ERRORTIME = 'BOOKINGSETTINGAREA_ERRORTIME';
export const BOOKINGSETTINGAREA_ADDNEWWEEKSETTING = 'BOOKINGSETTINGAREA_ADDNEWWEEKSETTING';
export const BOOKINGSETTINGAREA_CHANGEENABLE = 'BOOKINGSETTINGAREA_CHANGEENABLE';
export const BOOKINGSETTINGAREA_CHANGEFIXEDDININGTIME = 'BOOKINGSETTINGAREA_CHANGEFIXEDDININGTIME';
export const BOOKINGSETTINGAREA_CHANGEDININGTIMEMODE = 'BOOKINGSETTINGAREA_CHANGEDININGTIMEMODE';
export const BOOKINGSETTINGAREA_ADDTIMERANGE = 'BOOKINGSETTINGAREA_ADDTIMERANGE';
export const BOOKINGSETTINGAREA_ADDDININGTIMERANGE = 'BOOKINGSETTINGAREA_ADDDININGTIMERANGE';
export const BOOKINGSETTINGAREA_DININGTIMESHOWTIME = 'BOOKINGSETTINGAREA_DININGTIMESHOWTIME';
export const BOOKINGSETTINGAREA_DININGTIMENAMEUPDATE = 'BOOKINGSETTINGAREA_DININGTIMENAMEUPDATE';
export const BOOKINGSETTINGAREA_ADDTIME = 'BOOKINGSETTINGAREA_ADDTIME';
export const BOOKINGSETTINGAREA_CHANGEINTERVALTIME = 'BOOKINGSETTINGAREA_CHANGEINTERVALTIME';
export const BOOKINGSETTINGAREA_CHANGEDININGTIMEINTERVALTIME = 'BOOKINGSETTINGAREA_CHANGEDININGTIMEINTERVALTIME';
export const BOOKINGSETTINGAREA_REMOVETIME = 'BOOKINGSETTINGAREA_REMOVETIME';
export const BOOKINGSETTINGAREA_CHECKADD = 'BOOKINGSETTINGAREA_CHECKADD';
export const BOOKINGSETTINGAREA_CHECKEDIT = 'BOOKINGSETTINGAREA_CHECKEDIT';
export const BOOKINGSETTINGAREA_DELSETTING = 'BOOKINGSETTINGAREA_DELSETTING';
export const BOOKINGSETTINGAREA_DELTIMERANGE = 'BOOKINGSETTINGAREA_DELTIMERANGE';
export const BOOKINGSETTINGAREA_DELDININGTIMERANGE = 'BOOKINGSETTINGAREA_DELDININGTIMERANGE';
export const BOOKINGSETTINGAREA_GOOGLERESERVATIONREQUIREDCHECK = 'BOOKINGSETTINGAREA_GOOGLERESERVATIONREQUIREDCHECK';

const bookingSettingAPI = new BookingSystemApi();


export function BSettingAreaFetching() {
	return async (dispatch) => {
		dispatch({ type: BOOKINGSETTINGAREA_FETCHSTART });
		try {
			const { results } = await bookingSettingAPI.getBookingSettingSeats();
			const BookingSetting = await bookingSettingAPI.getBookingSetting();

			const {
				space_group_selection,
				space_group_selection_text,
				space_groups,
				deposit_people_required,
				deposit,
				dining_time,
				spg_merchant_id,
				service_time_option_enabled,
				google_reservation
			} = BookingSetting;

			// console.log('BookingSetting----', BookingSetting);
			// const tables = _.sortBy(results, ['group', 'max_seat'])

			// space_groups.map((group) => {
			// 	group.bookingDateSetting = [];
			// 	group.payment = {};
			// 	return null
			// });

			space_groups.forEach((group) => {
				group.time_settings = JSON.parse(group.time_settings);
				group.payment_settings = JSON.parse(group.payment_settings);
			});

			dispatch(
				BSettingAreaFetchingSuccess({
					tables: results,
					areaSetting: space_group_selection,
					areaSettingTitle: space_group_selection_text,
					space_groups,
					depositPeopleRequired: deposit_people_required,
					deposit,
					serviceTimeOptionEnabled: service_time_option_enabled,
					google_reservation
				})
			);
			dispatch({
				type: BOOKINGSETTINGAREA_SYNCAVAILABLETIME,
				systemDiningTime: dining_time,
				spgMerchantId :spg_merchant_id
			});
		} catch (error) {
			// dispatch({ type: BOOKINGSETTINGAREA_FETCHFAILURE, error });
			console.log('booking setting area fetch failed', error);
		}
	};
}

function BSettingAreaFetchingSuccess({
	tables,
	areaSetting,
	areaSettingTitle,
	space_groups,
	depositPeopleRequired,
	deposit,
	serviceTimeOptionEnabled,
	google_reservation
}) {
	return {
		type: BOOKINGSETTINGAREA_FETCHSUCCESS,
		tables,
		areaSetting,
		areaSettingTitle,
		space_groups,
		depositPeopleRequired,
		deposit,
		serviceTimeOptionEnabled,
		google_reservation
	};
}

export function switchAreaSetting() {
	return {
		type: BOOKINGSETTINGAREA_SWITCHSETTING
	};
}

export function changeAreaSettingTitle(txt) {
	return {
		type: BOOKINGSETTINGAREA_CHAGEAREATITLE,
		txt
	};
}

export function addNewAreaBlock() {
	return (dispatch, getState) => {
		const { depositPeopleRequired, deposit } = getState().BookingSettingAreaReducer;

		dispatch({ type: BOOKINGSETTINGAREA_ADDNEWAREABLOCKOPTION });
		dispatch({
			type: BOOKINGSETTINGAREA_SYNCMODALPAYMENT,
			paymentSystemSetting: { depositPeopleRequired, deposit }
		});
		dispatch({ type: BOOKINGSETTINGAREA_OPENMODAL });
	};
}

export function openAreaModal() {
	return {
		type: BOOKINGSETTINGAREA_OPENMODAL
	};
}

export function closeAreaModal() {
	return (dispatch) => {
		dispatch({ type: BOOKINGSETTINGAREA_CLOSEMODAL });
		dispatch({ type: BOOKINGSETTINGAREA_CLEARMODALSTATE });
	};
}

export function clearBSAreaState() {
	return {
		type: BOOKINGSETTINGAREA_CLEARSETTING
	};
}

export function setAreaOption(clickType, id) {
	return {
		type: BOOKINGSETTINGAREA_SETAREAOPTION,
		clickType,
		id
	};
}

export function checkAreaWarning() {
	return (dispatch, getState) => {
		const { type } = getState().BookingSettingAreaReducer;

		if (type === 'delete') dispatch({ type: BOOKINGSETTINGAREA_DELETEAREABLOCK });

		if (type === 'edit') {
			dispatch({ type: BOOKINGSETTINGAREA_EDITAREABLOCK });
			const { setting, depositPeopleRequired, deposit } = getState().BookingSettingAreaReducer;

			dispatch(initAreaModalState({ setting, depositPeopleRequired, deposit }));
		}
	};
}

export function cancelAreaWarning() {
	return {
		type: BOOKINGSETTINGAREA_CANCELWARNING
	};
}

export function initAreaModalState({ setting, depositPeopleRequired, deposit }) {
	return {
		type: BOOKINGSETTINGAREA_INITMODALSTATE,
		setting,
		depositPeopleRequired,
		deposit
	};
}

export function changeAreaBlockTitle(txt) {
	return {
		type: BOOKINGSETTINGAREA_CHANGEAREABLOCKTITLE,
		txt
	};
}

export function chooseTableGroup(table) {
	return {
		type: BOOKINGSETTINGAREA_CLICKTABLEGROUP,
		table
	};
}

export function checkModalState() {
	return (dispatch, getState) => {
		const {
			areaBlockTitle,
			areaBlockTables,
			areaBlockWeekSettings,
			paymentSettings
		} = getState().BookingSettingAreaModalReducer;

		dispatch({
			type: BOOKINGSETTINGAREA_UPDATEMODALSTATE,
			areaBlockTitle,
			areaBlockTables,
			areaBlockWeekSettings,
			paymentSettings
		});
	};
}

export function handleGoogleReservationRequiredModal(value) {
	return {
		type: BOOKINGSETTINGAREA_GOOGLERESERVATIONREQUIREDCHECK,
		value
	};
}

export function BSareaSettingCheck() {
	return async (dispatch, getState) => {
		const {
			areaSetting,
			areaSettingTitle,
			space_groups,
			google_reservation,
		} = getState().BookingSettingAreaReducer;

		if (areaSetting && areaSettingTitle.trim() === '') {
			window.app.alert.setMessage('請填寫預約選項標題', 'error');
			return;
		}

		if (areaSetting && space_groups.length === 0) {
			window.app.alert.setMessage('請設定至少一組預約選項', 'error');
			return;
		}

		if(google_reservation && areaSetting) {
			dispatch({ type: BOOKINGSETTINGAREA_GOOGLERESERVATIONREQUIREDCHECK, value: true })
			return;
		}

		dispatch(BSettingAreaSubmit());
	}
}

export function BSettingAreaSubmit() {
	return async (dispatch, getState) => {
		const {
			areaSetting,
			areaSettingTitle,
			space_groups,
			deleted_space_groups,
		} = getState().BookingSettingAreaReducer;

		dispatch({ type: BOOKINGSETTINGAREA_SENDING });
		dispatch({ type: BOOKINGSETTINGAREA_GOOGLERESERVATIONREQUIREDCHECK, value: false })

		const bookingSetting = JSON.stringify({
			booking_settings: {
				space_group_selection: areaSetting,
				space_group_selection_text: areaSettingTitle
			}
		});

		space_groups.forEach((group) => {
			group.time_settings = JSON.stringify(group.time_settings);
			group.payment_settings = JSON.stringify(group.payment_settings);
		});

		try {
			await bookingSettingAPI.updateBookingSetting(bookingSetting);
			const space_groups_res = await bookingSettingAPI.spaceGroupSetting(space_groups, deleted_space_groups);

			dispatch({ type: BOOKINGSETTINGAREA_UPDATEAREA, res: space_groups_res });

			window.app.alert.setMessage('儲存成功', 'done');
		} catch (err) {
			window.app.alert.setMessage('請稍後再試', 'error');
			dispatch({ type: BOOKINGSETTINGAREA_DISABLESENDING });
		}
	};
}

export function openEditAvailableTime(index) {
	return {
		type: BOOKINGSETTINGAREA_EDITAVAILABLETIME,
		index
	};
}

export function changeSettingWeek(week) {
	return {
		type: BOOKINGSETTINGAREA_CHANGESETTINGWEEK,
		week
	};
}

export function changeSettingTime(time) {
	return {
		type: BOOKINGSETTINGAREA_CHANGESETTINGTIME,
		time
	};
}

export function checkNumpadTime({ displayTime, timeRangeIndex, timeIndex }) {
	return (dispatch, getState) => {
		return new Promise((resolve) => {

			dispatch({
				type: BOOKINGSETTINGAREA_CHANGEDISPLAYTIME,
				displayTime,
				timeRangeIndex,
				timeIndex
			});

			resolve();
		});
	};
}

export function changeDiningTimeFixed({ rangeType, timeType, time }) {
	return (dispatch, getState) => {
		const { setting } = getState().BookingSettingAreaModalReducer;

		const diningTime_min = parseInt(setting.service_time_setting[0].fixed.min, 10);
		const diningTime_max = parseInt(setting.service_time_setting[0].fixed.max, 10);
		
		if (rangeType === 'min') {
			if (timeType === 'hour') {
				const min = diningTime_min % 60;
				setting.service_time_setting[0].fixed.min = parseInt(time, 10) * 60 + min;
			} else if (timeType === 'min') {
				const hour = Math.floor(diningTime_min / 60);
				setting.service_time_setting[0].fixed.min = parseInt(time, 10) + hour * 60;
			}
		} else if (rangeType === 'max') {
			if (timeType === 'hour') {
				const min = diningTime_max % 60;
				setting.service_time_setting[0].fixed.max = parseInt(time, 10) * 60 + min;
			} else if (timeType === 'min') {
				const hour = Math.floor(diningTime_max / 60);
				setting.service_time_setting[0].fixed.max = parseInt(time, 10) + hour * 60;
			}
		}

		dispatch({
			type: BOOKINGSETTINGAREA_CHANGEDININGTIMEFIXED,
			rangeType,
			timeType,
			time
		});
	};
}

export function changeDiningTimeUserSetting({ timeType, id, time }) {
	return (dispatch, getState) => {
		const { setting } = getState().BookingSettingAreaModalReducer;

		const diningTime = parseInt(setting.service_time_setting[0].user_setting.service_time_setting[id].service_time, 10) ;
		
		if (timeType === 'hour') {
			const min = diningTime % 60;
			setting.service_time_setting[0].user_setting.service_time_setting[id].service_time = parseInt(time, 10) * 60 + min;
		} else if (timeType === 'min') {
			const hour = Math.floor(diningTime / 60);
			setting.service_time_setting[0].user_setting.service_time_setting[id].service_time = parseInt(time, 10) + hour * 60;
		}

		dispatch({
			type: BOOKINGSETTINGAREA_CHANGEDININGTIMEUSERSETTING,
			timeType,
			id,
			time
		});
	};
}

export function cancelSettingEdit() {
	return (dispatch) => {
		dispatch({
			type: BOOKINGSETTINGAREA_CANCELSETTINGEDIT
		});
	};
}

export function saveSettingEdit() {
	return (dispatch, getState) => {
		const { weekSettingType, setting } = getState().BookingSettingAreaModalReducer;
		const { week_days } = setting;
		const { enable, setting2, setting3 } = setting.setting_temp;

		if (week_days.length === 0) {
			window.app.alert.setMessage('請選擇開放營業日', 'error');
			return;
		}

		if (enable === 3 && setting3.length === 0) {
			window.app.alert.setMessage('請選擇時段區間', 'error');
			return;
		}

		if (enable === 2) {
			if (setting2.length === 0) return window.app.alert.setMessage('請設定固定區間', 'error');

			let errorTime = [];
			setting2.map((r, index) => {
				if (r[0] > r[1]) errorTime.push(index);
				return null;
			});

			if (errorTime.length !== 0) {
				window.app.alert.setMessage('區段開始時間大於結束時間', 'tip');
				dispatch({
					type: BOOKINGSETTINGAREA_ERRORTIME,
					errorTime
				});
				return;
			}
		}

		// 服務時間 - 固定時段檢查
		if(setting.service_time_setting[0].mode === 'fixed') {
			if(setting.service_time_setting[0].fixed.min > setting.service_time_setting[0].fixed.max){
				window.app.alert.setMessage('最短服務時間大於最長服務時間', 'tip');
				return;
			}

			if((setting.service_time_setting[0].fixed.max - setting.service_time_setting[0].fixed.min) < setting.service_time_setting[0].fixed.unit){
				window.app.alert.setMessage('預約時段區間大於服務時間', 'tip');
				return;
			}
		}

		// 服務時間 - 自訂時段檢查
		if(setting.service_time_setting[0].mode === 'user_setting') {
			if(setting.service_time_setting[0].user_setting.show_name) {
				for (let i=0; i<setting.service_time_setting[0].user_setting.service_time_setting.length-1; i++) {
					for (let j=i+1; j<setting.service_time_setting[0].user_setting.service_time_setting.length;j++) {
						if(setting.service_time_setting[0].user_setting.service_time_setting[i].name === setting.service_time_setting[0].user_setting.service_time_setting[j].name) {
							window.app.alert.setMessage(`服務時間選項 ${i+1} 和 選項 ${j+1} 名稱重複`, 'tip');
					return;
						}
					}
				}

				for(let i=0; i<setting.service_time_setting[0].user_setting.service_time_setting.length; i++) {
					if(setting.service_time_setting[0].user_setting.service_time_setting[i].name.length === 0) {
						window.app.alert.setMessage(`請輸入服務時間選項 ${i+1} 名稱`, 'tip');
						return;
					}
				}
			} else {
				for (let i=0; i<setting.service_time_setting[0].user_setting.service_time_setting.length-1; i++) {
					for (let j=i+1; j<setting.service_time_setting[0].user_setting.service_time_setting.length;j++) {
						if(setting.service_time_setting[0].user_setting.service_time_setting[i].service_time === setting.service_time_setting[0].user_setting.service_time_setting[j].service_time) {
							window.app.alert.setMessage(`服務時間選項 ${i+1} 和 選項 ${j+1} 重複`, 'tip');
					return;
						}
					}
				}	
			}
		}

		const addNewWeekSettingBtn = document.getElementById('addNewWeekSettingBtn');
		addNewWeekSettingBtn.style.display = 'block';

		if (weekSettingType === 'add') dispatch({ type: BOOKINGSETTINGAREA_CHECKADD });
		if (weekSettingType === 'edit') dispatch({ type: BOOKINGSETTINGAREA_CHECKEDIT });

		dispatch({
			type: BOOKINGSETTINGAREA_SAVESETTINGEDIT
		});
	};
}

export function togglePayment() {
	return {
		type: BOOKINGSETTINGAREA_TOGGLEPAYMENT
	};
}

export function changeDepositEnable(enable) {
	return {
		type: BOOKINGSETTINGAREA_CHANGEDEPOSITENABLE,
		enable
	};
}

export function changeDeposit(enable, value) {
	return {
		type: BOOKINGSETTINGAREA_CHANGEDEPOSIT,
		enable,
		value
	};
}

export function changeDepositPeople(value) {
	return {
		type: BOOKINGSETTINGAREA_CHANGEDEPOSITPEOPLE,
		value
	};
}

export function addNewWeekSetting() {
	return {
		type: BOOKINGSETTINGAREA_ADDNEWWEEKSETTING
	};
}

export function changeEnable(enable) {
	return {
		type: BOOKINGSETTINGAREA_CHANGEENABLE,
		enable
	};
}

export function changeFixedDiningTime({ timeType, time }) {
	return (dispatch, getState) => {
		const { setting } = getState().BookingSettingAreaModalReducer;
		const { dining_time } = setting;
		
		const diningHour = Math.floor(parseInt(dining_time, 10) / 60),
		diningMin = parseInt(dining_time, 10) % 60;
		
		if (timeType === 'hour') {
			if (diningMin === 0 && parseInt(time, 10) === 0) {
			window.app.alert.setMessage('服務時間至少要五分鐘', 'error');
			return;
			}
		}
		
		if (timeType === 'min') {
			if (diningHour === 0 && parseInt(time, 10) === 0) {
			window.app.alert.setMessage('服務時間至少要五分鐘', 'error');
			return;
			}
		}

		dispatch({
			type: BOOKINGSETTINGAREA_CHANGEFIXEDDININGTIME,
			timeType,
			time
		});
	};
}

export function changeDiningTimeMode(mode) {
	return {
		type: BOOKINGSETTINGAREA_CHANGEDININGTIMEMODE,
		mode
	};
}

export function addTimeRange() {
	return {
		type: BOOKINGSETTINGAREA_ADDTIMERANGE
	};
}

export function diningTimeShowTime() {
	return {
		type: BOOKINGSETTINGAREA_DININGTIMESHOWTIME
	};
}

export function updateDiningTimeName({ id, name }) {
	return (dispatch, getState) => {
		const { setting } = getState().BookingSettingAreaModalReducer;
		const diningTimeNameValid = document.querySelectorAll('.diningTime_option_name')[id]
		const diningTimeNameValidTxt = document.querySelectorAll('.diningTime_option_name_valid')[id]

		setting.service_time_setting[0].user_setting.service_time_setting[id].name = name;

		if(name.length > 0) {
			diningTimeNameValidTxt.style.display = 'none';
			diningTimeNameValid.style.borderColor = '#c8c8c8';
			diningTimeNameValid.style.outlineColor = '#1890ff';

			setting.service_time_setting[0].user_setting.service_time_setting[id].name = name;
		} else {
			diningTimeNameValidTxt.style.display = 'block';
			diningTimeNameValid.style.borderColor = '#CE4949';
			diningTimeNameValid.style.outlineColor = '#CE4949';

			setting.service_time_setting[0].user_setting.service_time_setting[id].name = name;
		}

		dispatch({
			type: BOOKINGSETTINGAREA_DININGTIMENAMEUPDATE,
			id,
			name
		});
	};
}

export function addDiningTimeRange() {
	return {
		type: BOOKINGSETTINGAREA_ADDDININGTIMERANGE
	};
}

export function addTime(time) {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: BOOKINGSETTINGAREA_ADDTIME,
				time
			});

			resolve();
		});
	};
}

export function changeIntervalTime(time) {
	return {
		type: BOOKINGSETTINGAREA_CHANGEINTERVALTIME,
		time
	};
}

export function changeDiningTimeIntervalTime(time) {
	return {
		type: BOOKINGSETTINGAREA_CHANGEDININGTIMEINTERVALTIME,
		time
	};
}

export function removeTime(time) {
	return {
		type: BOOKINGSETTINGAREA_REMOVETIME,
		time
	};
}

export function delSetting(index) {
	return {
		type: BOOKINGSETTINGAREA_DELSETTING,
		index
	};
}

export function delTimeRange(index) {
	return {
		type: BOOKINGSETTINGAREA_DELTIMERANGE,
		index
	};
}

export function delDiningTimeRange(index) {
	return {
		type: BOOKINGSETTINGAREA_DELDININGTIMERANGE,
		index
	};
}
