import React from 'react';
import { CalendarOutlined } from '@ant-design/icons';


export default ()=>{
    return(
        <div className="emptyRes text-center">
            <CalendarOutlined />
            <span>
                搜尋無結果。
            </span>
        </div>
    )
}