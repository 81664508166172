import React from 'react';
import { Link } from 'react-router';

const CouponIcon = () => {
	return (
		<div className='main-item menu_coupon'>
			<div className='menuCouponItem'>
				<Link
					to={{
						pathname: '/dashboard/menu_coupon'
					}}
					role="button"
				>
					<div className="function-thub">
						<img alt="booking" src={require('../../images/component/f_menuCoupon.png')} />
						<div className="caption function-title">
							<h3>優惠券系統</h3> <p>建立MENU美食誌優惠券</p>
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default CouponIcon;
