import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import _ from 'lodash';

import BookingSystemApi from '../BookingSystemApi';
import BsTestBlock from './BsTestBlock';
import NewEditModal from './BsEditModal';

import GoogleReservationRequiredCheckModal from '../bookingSettingsPopup/GoogleReservationRequiredCheckModal';

const hour = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23 ];
const min = [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55 ];

const api = new BookingSystemApi();

const BsTest = (props) => {
	// const auth = useSelector((state) => state.auth);
	// const { origin } = auth.shop;

	const [ init, setInit ] = useState(false);
	const [ sending, setSending ] = useState(false);
	const [ isSaved, setIsSaved ] = useState(true);
	const [ weekSettings, setWeekSettings ] = useState([]);
	const [ diningTime, setDiningTime ] = useState(0);
	const [ serviceTimeTitle, setServiceTimeTitle ] = useState('')
	const [ serviceTimeOptionEnabled, setServiceTimeOptionEnabled ] = useState(false)
	const [ chosenWeek, setChosenWeek ] = useState([]);
	const [ showModal, setShowModal ] = useState(false);
	const [ editSetting, setEditSetting ] = useState({});

	const [ googleReservation, setGoogleReservation ] = useState(false);
	const [ showGoogleReservationRequiredCheckModal, setGoogleReservationRequiredCheckModal ] = useState(false);

	const editIndexRef = useRef(null);

	const routerWillLeave = useCallback(
		() => {
			if (!isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?';
		},
		[ isSaved ]
	);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, routerWillLeave);
		},
		[ props, routerWillLeave ]
	);

	useEffect(() => {
		getBookingSetting();
	}, []);

	const getBookingSetting = async () => {
		try {
			const data = await api.getBookingSetting();
			const { week_settings, dining_time, service_time_title, service_time_option_enabled, google_reservation } = data;

			setWeekSettings(week_settings);
			setDiningTime(dining_time);
			setServiceTimeTitle(service_time_title);
			setServiceTimeOptionEnabled(service_time_option_enabled);
			setChosenWeek(_.uniq(sortChosenWeek(week_settings).sort()));
			setGoogleReservation(google_reservation);
			setInit(true);
		} catch (err) {
			console.log('bstest getBookingSetting err---', err);
			window.app.alert.setMessage('請稍後再試', 'error');
		}
	};

	const newUpdateServiceTimeTitle = (e) => {
		const serviceTimeTitle = e.target.value;

		setServiceTimeTitle(serviceTimeTitle);
	};

	const newUpdateDiningTime = (type, e) => {
		let new_dining_time_hour = Math.floor(parseInt(diningTime, 10) / 60),
			new_dining_time_min = parseInt(diningTime, 10) % 60;

		setIsSaved(false);

		if (type === 'hour') {
			if (parseInt(new_dining_time_min, 10) === 0 && parseInt(e.target.value, 10) === 0) {
				window.app.alert.setMessage('服務時間至少要五分鐘', 'error');
			} else {
				updateBookingDiningTime('hour', e.target.value);
			}
		} else {
			if (parseInt(new_dining_time_hour, 10) === 0 && parseInt(e.target.value, 10) === 0) {
				window.app.alert.setMessage('服務時間至少要五分鐘', 'error');
			} else {
				updateBookingDiningTime('min', e.target.value);
			}
		}
	};

	const updateBookingDiningTime = (timeType, time) => {
		const newDiningTime = parseInt(diningTime, 10);

		if (timeType === 'hour') {
			const min = newDiningTime % 60;
			setDiningTime(parseInt(time, 10) * 60 + min);
		} else if (timeType === 'min') {
			const hour = Math.floor(diningTime / 60);
			setDiningTime(parseInt(time, 10) + hour * 60);
		}
	};

	const handleServiceTimeOptionEnabled = () => {
		let newServiceTimeOptionEnabled;
		newServiceTimeOptionEnabled = !serviceTimeOptionEnabled

		setServiceTimeOptionEnabled(newServiceTimeOptionEnabled);
	}

	const renderServiceTimeOptionsSwitch = () => {
		return (
			<div className="diningTime_options">
				<div className="diningTime_options_switch_block">
					<div className="diningTime_options_title">提供多種服務時間長度選項</div>
					<Switch
						checked={serviceTimeOptionEnabled}
						onChange={() => handleServiceTimeOptionEnabled()}
					/>
				</div>
				<div className="diningTime_options_info">
					關閉時，每筆線上預約的服務時間為固定長度。
					開啟後，您可以在「預約時段設定」或「預約組別設定」頁面的開放時段中，設定不同時間長度的服務時間選項。顧客進行線上預約時，可以選擇不同的服務時間選項。
				</div>
			</div>
		)
	};

	const renderNewDiningTimeName = () => {
		if(serviceTimeOptionEnabled) {
			return (
				<div className="diningTimeName">
					<h4>服務時間選項標題</h4>
					<div className="settingInfo">您可以自訂顧客在線上預約頁面中看到的服務時間選項標題。</div>
					<input
						className="diningTimeName__input"
						type="text"
						value={serviceTimeTitle}
						onChange={(e) => {newUpdateServiceTimeTitle(e)}}
						maxLength="10"
						placeholder={`請輸入服務時間標題`}
					/>
					<div className="diningTimeName__txt">填入 10 字（含）以內標題。</div>
				</div>
			)
		};
	};

	const renderNewDiningTime = () => {
		const hourValue = Math.floor(parseInt(diningTime, 10) / 60),
			minValue = parseInt(diningTime, 10) % 60;
		const diningHour = hour

		return (
			<div className="diningTime">
				<select
					className="diningTime__select"
					value={hourValue}
					onChange={(e) => newUpdateDiningTime('hour', e)}
				>
					{diningHour.map((h) => {
						return (
							<option key={h} value={h}>
								{h}
							</option>
						);
					})}
				</select>
				<span className="diningTime__txt">小時</span>
				<select className="diningTime__select" value={minValue} onChange={(e) => newUpdateDiningTime('min', e)}>
					{min.map((m) => {
						return (
							<option key={m} value={m}>
								{m}
							</option>
						);
					})}
				</select>
				<span className="diningTime__txt">分鐘</span>
			</div>
		);
	};

	const closeEditor = useCallback(() => {
		setShowModal(false);
		editIndexRef.current = null;
	}, []);

	const openEditor = useCallback(
		(type, idx) => {
			if (type === 'addNew') {
				editIndexRef.current = null;
				setEditSetting({
					week_days: [],
					hours: [],
					setting_temp: {
						enable: 2,
						setting2: [ [ '00:00', '00:30' ] ],
						setting3: [ '00:00' ]
					},
					dining_time: diningTime,
					max_people_at_a_time: 0,
					booking_time_interval: 30,
					service_time_setting: [{
						mode: 'user_setting',
						user_setting: {
							show_name: true,
							service_time_setting: [{
								name: "選項名稱",
								service_time: diningTime
							}]
						},
						fixed: {
							service_time: [],
							max: 240,
							min: 60,
							unit: 30
						}
					}]
				});
			}

			if (type === 'edit') {
				editIndexRef.current = idx;
				setEditSetting(_.cloneDeep(weekSettings[idx]));
			}

			setShowModal(true);
		},
		[ weekSettings, diningTime ]
	);

	const deleteOpening = useCallback(
		(index) => {
			let newWeekSettings = _.cloneDeep(weekSettings);
			let deleteWeek = chosenWeek.filter((e) => newWeekSettings[index].week_days.indexOf(e) === -1);

			newWeekSettings.splice(index, 1);

			setChosenWeek(deleteWeek);
			setWeekSettings(newWeekSettings);
		},
		[ chosenWeek, weekSettings ]
	);

	const renderWeekSetting = () => {
		return weekSettings.map((setting, index) => (
			<BsTestBlock
				key={index}
				weekSetting={setting}
				idx={index}
				openEditor={openEditor}
				deleteOpening={deleteOpening}
				serviceTimeOptionEnabled={serviceTimeOptionEnabled}
			/>
		));
	};

	const editSubmit = useCallback(
		(setting, index) => {
			const newWeekSettings = _.cloneDeep(weekSettings);
			newWeekSettings[index] = setting;
			const weekDays = newWeekSettings.map((setting) => setting.week_days);
			setChosenWeek(_.flatten(weekDays).sort())
			setWeekSettings(newWeekSettings);
		},
		[ weekSettings ]
	);

	const addSubmit = useCallback(
		(setting) => {
			const newWeekSettings = _.cloneDeep(weekSettings);
			newWeekSettings.push(setting);
			const weekDays = newWeekSettings.map((setting) => setting.week_days);
			setChosenWeek(_.flatten(weekDays).sort())
			setWeekSettings(newWeekSettings);
		},
		[ weekSettings ]
	);

	const updateSettings = useCallback(
		(newSettings) => {
			setShowModal(false);
			setIsSaved(false);
			const timeRange = _.cloneDeep(newSettings.setting_temp.setting2),
				timeRangeLength = timeRange.length;

			if (timeRangeLength > 1) {
				let sortArray = _.sortBy(timeRange);

				for (let i = 0; i < sortArray.length; i++) {
					if (!sortArray[i]) break;
					const one = parseInt(sortArray[i][1].slice(0, 2), 10) * 60 + parseInt(sortArray[i][1].slice(3), 10);
					for (let j = i + 1; j <= i + 1; j++) {
						if (!sortArray[j]) break;
						const second =
							parseInt(sortArray[j][0].slice(0, 2), 10) * 60 + parseInt(sortArray[j][0].slice(3), 10);
						if (one > second) {
							let second_till =
								parseInt(sortArray[j][1].slice(0, 2), 10) * 60 + parseInt(sortArray[j][1].slice(3), 10);
							let newArray = [];
							if (one > second_till) {
								newArray = [ sortArray[i][0], sortArray[i][1] ];
							} else {
								newArray = [ sortArray[i][0], sortArray[j][1] ];
							}
							sortArray.splice(j + 1, 0, newArray);
							sortArray.splice(i, 2);
							i = -1;
							continue;
						}
					}
				}
				newSettings['setting_temp']['setting2'] = sortArray;
			}

			console.log('newSettings----', newSettings);

			newSettings['week_days'] = newSettings['week_days'].sort();

			if (editIndexRef.current !== undefined && editIndexRef.current !== null) {
				editSubmit(newSettings, editIndexRef.current);
			} else {
				addSubmit(newSettings);
			}
			editIndexRef.current = null;
		},
		[ addSubmit, editSubmit ]
	);

	const handleSubmit = () => {
		const week_days = _.flatten(weekSettings.map((s) => s.week_days));

		if (week_days.length !== _.uniq(week_days).length) {
			window.app.alert.setMessage('您在多個時段中選擇了重複的營業日，請您再修改', 'error');
			return;
		}

		if(googleReservation && serviceTimeOptionEnabled) {
			return setGoogleReservationRequiredCheckModal(true);
		}

		submit();
	};

	const submit = () => {
		setSending(true);
		setGoogleReservationRequiredCheckModal(false);

		const bsSettings = JSON.stringify({
			booking_settings: {
				week_settings: JSON.stringify(weekSettings),
				dining_time: diningTime,
				service_time_title: serviceTimeTitle,
				service_time_option_enabled: serviceTimeOptionEnabled
			}
		});

		api
			.updateBookingSetting(bsSettings)
			.then(() => {
				window.app.alert.setMessage('更新成功', 'done');
				setSending(false);
				setIsSaved(true);
			})
			.catch((error) => {
				window.app.alert.setMessage('請稍後再試', 'error');
				setSending(false);
			});
	};

	if (!init) return <div className="pageLoading">讀取中..</div>;
	return (
		<>
			<div className="main overflow bsTest">
				{sending && (
					<div className="lightBoxLayer">
						<h4>儲存中</h4>
					</div>
				)}
				<div style={{ display: 'flex' }}>
					<div style={{ flexGrow: 1, marginBottom: '32px' }}>
						<h3>預約時段設定</h3>
						<h5>您可以設定線上預約的開放日期、服務時段、與服務時間長度。</h5>
					</div>
				</div>
				<h4>自建預約預設服務時間</h4>
				<div className="settingInfo">您在系統建立的每筆預約將以下方設定的時間長度作為預設值。</div>
				<div className="settings-form">{renderNewDiningTime()}</div>
				<hr />
				<h4>服務時間長度設定</h4>
				<div>{renderServiceTimeOptionsSwitch()}</div>
				<div>{renderNewDiningTimeName()}</div>
				<hr />
				<div>{renderWeekSetting()}</div>
				{showModal && (
					<NewEditModal
						updateSettings={updateSettings}
						closeEditorModal={closeEditor}
						editSetting={editSetting}
						chosenWeek={chosenWeek}
						setChosenWeek={setChosenWeek}
						diningTime={diningTime}
						serviceTimeOptionEnabled={serviceTimeOptionEnabled}
					/>
				)}

				<button className="msBtn" onClick={() => openEditor('addNew')}>
					＋ 新增時段
				</button>
				<br />
				<hr />

			{
				showGoogleReservationRequiredCheckModal &&
				<GoogleReservationRequiredCheckModal
					serviceTimeOptionEnabled={serviceTimeOptionEnabled}
					setGoogleReservationRequiredCheckModal={setGoogleReservationRequiredCheckModal}
					submit={submit}
				/>
			}
			</div>

			<div className='fix_bottom'>
				<button
					className='btn_submit'
					onClick={handleSubmit}
				>
					儲存
				</button>
			</div>
		</>
	);
};

function sortChosenWeek(dayWeeks) {
	let chosenWeekArray = [];
	dayWeeks.map((week) => {
		week.week_days.map((w) => {
			chosenWeekArray.push(w);
			return true;
		});
		return true;
	});

	return chosenWeekArray;
}

export default BsTest;
