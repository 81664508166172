import React, { Fragment, useReducer, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { signupState, SignUpStateContext, SignUpReducer } from './MenuCouponContext/MenuCouponSignUpContext';
import MenuCouponSignUp from './MenuCouponSignUp/MenuCouponSignUp';
import MenuCoupon from './MenuCoupon';
import MenuCouponApi from './MenuCouponApi';

const menuCouponAPI = new MenuCouponApi();

const MenuCouponPanel = () => {
	const auth = useSelector((state) => state.auth);
	const { shop: shopState } = auth;
	const [ SignUpState, SignUpStateDispatch ] = useReducer(SignUpReducer, signupState);
	const { menuShopUser } = SignUpState;

	const getShopInfo = useCallback(
		async () => {
			try {
				if (!Boolean(shopState.parse_obj_id)) {
					const shop = await menuCouponAPI.getShops();
					const { shop: shopInfo } = shop;

					if (!Boolean(shopInfo.parse_obj_id)) {
						Modal.info({
							content: <p>您尚未設定店家資料，請至「設定」設定您的店家資料</p>,
							okText: '確定前往',
							onOk: () => {
								browserHistory.push({
									pathname: '/dashboard/setting',
									state: {
										source: 'coupon'
									}
								});
							}
						});
					} else {
						SignUpStateDispatch({
							type: 'setInitShopInfo',
							shop: {
								menuShopUser: shopInfo.menu_shop_user,
								shopName: shopInfo.display_name || '',
								shopBranch: shopInfo.branch || '',
								tel: shopInfo.phone_number || '',
								address: shopInfo.address || ''
							}
						});
					}
				}

				if (Boolean(shopState.parse_obj_id)) {
					SignUpStateDispatch({
						type: 'setInitShopInfo',
						shop: {
							menuShopUser: shopState.menu_shop_user,
							shopName: shopState.display_name || '',
							shopBranch: shopState.branch || '',
							tel: shopState.phone_number || '',
							address: shopState.address || ''
						}
					});
				}
			} catch (err) {
				console.log('nextStep err----', err);
			}
		},
		[
			shopState.address,
			shopState.branch,
			shopState.display_name,
			shopState.menu_shop_user,
			shopState.parse_obj_id,
			shopState.phone_number
		]
	);

	useEffect(
		() => {
			getShopInfo();
		},
		[ getShopInfo ]
	);

	if (menuShopUser === null)
		return (
			<div style={{ textAlign: 'center', marginTop: 100 }}>
				<LoadingOutlined style={{ fontSize: 30 }} />
				<p>Loading ...</p>
			</div>
		);

	return (
		<SignUpStateContext.Provider value={{ SignUpState, SignUpStateDispatch }}>
			<Fragment>{menuShopUser === false ? <MenuCouponSignUp /> : <MenuCoupon />}</Fragment>
		</SignUpStateContext.Provider>
	);
};

export default MenuCouponPanel;
