import React, {  useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import '../../../sass/menu_coupon_header_filter.scss';
import MenuCouponApi from '../MenuCouponApi';

const menuCouponAPI = new MenuCouponApi();

const filterType = {
	all: '全部',
	enable: '進行中',
	draft: '草稿',
	standby: '已排定',
	disable: '暫停',
	expired: '已結束'
};

const MenuCouponHeaderFilter = () => {
	const auth = useSelector((state) => state.auth);
	const { name } = auth.shop;

	const { CouponState, CouponDispatch } = useContext(CouponStateContext);
	const { couponLists, activeCategory } = CouponState;
	const filterTypeRef = useRef(filterType);


	const switchCategory = (category)=>{
		CouponDispatch({type: 'switchCategory', category})

	}

	const changeSortType = async({sortType, orderType})=>{
		try {
			CouponDispatch({ type: 'setLoading', loading: true });
			const list = await menuCouponAPI.getCoupon(name, sortType, orderType);
			CouponDispatch({ type: 'getCouponList', list });
	
		} catch (err) {
			console.log('changeSortType err-----', err);
		}
	}

	const renderCouponType = () => {
		const { current } = filterTypeRef;
		return (
			<ul>
				{Object.keys(current).map((item) => {
					const activeStyle = activeCategory === item ? 'active' : '';
					const filterTypeLength = couponLists.filter((filterItem) => filterItem.status === item)
						.length;
					if (item === 'all') {
						return (
							<li key={item} className={`${activeStyle}`} onClick={()=>switchCategory(item)}>
								{current[item]}({couponLists.length})
							</li>
						);
					}

					return (
						<li key={item} className={`${activeStyle}`} onClick={()=>switchCategory(item)}>
							{current[item]}({filterTypeLength})
						</li>
					);
				})}
			</ul>
		);
	};

	if (couponLists.length === 0) return null;
	return (
		<div className="menuCouponHeaderFilter">
			<div className="menuCouponHeaderFilter__list">{renderCouponType()}</div>
			{activeCategory === 'all' && (
				<div className="menuCouponHeaderFilter__button">
					<button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
					<div className="dropdown-menu dropdown-menu-right">
						<ul>
							<li onClick={()=> changeSortType({sortType: 'createdAt', orderType:'1'})}>由新到舊</li>
							<li onClick={()=> changeSortType({sortType: 'createdAt', orderType:'0'})}>由舊到新</li>
							<li onClick={()=>  changeSortType({sortType: 'sentCount', orderType:'1'})}>領取數</li>
							<li onClick={()=>  changeSortType({sortType: 'exchangedCount', orderType:'1'})}>兌換數</li>
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};

export default MenuCouponHeaderFilter;
