import React from 'react'
import $ from 'jquery'
import { config } from '../../utils/config'
var createReactClass = require('create-react-class');
export default createReactClass({
  handleSubmit(e) {
    e.preventDefault();
    var data = { customer: this.state.info };
    $.ajax({
      url: config.domain + '/dashboard/customers',
      dataType: 'json',
      type: 'POST',
      data: data,
      xhrFields: { withCredentials: true },
      success: function(d) {
        this.handleTagSubmit(d.meta);
      }.bind(this),
      error: function(xhr) {
        window.app.alert.setMessage(xhr.responseJSON.message, 'error');
      }
    })
  },
  handleTagSubmit(id) {
    var data = { checked: this.state.checked, id: id };
    $.ajax({
      url: config.domain + '/dashboard/customer_tags.json',
      dataType: 'json',
      data: data,
      type: 'POST',
      xhrFields: { withCredentials: true },
      success: function(d) {
        if (typeof(this.props.onSubmitCallback) !== 'undefined') {
          this.props.onSubmitCallback();
        }
        window.app.alert.setMessage(d.message, 'done');
        // $(this.refs.modal).modal('toggle');
        $(this.refs.modal).modal('hide');
        this.clearData();
      }.bind(this),
      error: function(xhr) {
        window.app.alert.setMessage(xhr.responseJSON.message, 'error');
      }
    })
  },
  getTags() {
    $.ajax({
      url: config.domain + '/dashboard/customer_tags.json',
      dataType: 'json',
      xhrFields: { withCredentials: true },
      success: function(data) {
        this.setState({tags: data.customer_tags});
      }.bind(this),
      error: function() {
      }
    });
  },
  getDefaultProps() {
    return {
      url: '/dashboard/customers'
    };
  },
  getInitialState() {
    return {
      info: {
        last_name: '',
        gender: 'other',
        phone_number: '',
        tele_number: '',
        email: '',
        note: ''
      },
      tags: [],
      checked: []
    }
  },
  show(){
    $(this.refs.modal).modal('show');
  },
  _setInitialState(data) {
    var info = $.extend({}, this.state.info);
    $.extend(info, data);
    info['phone_number'] = ((typeof(data)==='undefined'||typeof(data.phone_number)==='undefined') ? '' : data.phone_number);
    this.setState({
      info: info
    });
  },
  componentDidMount() {
    this._setInitialState(this.props.data);
    this.getTags();
  },
  componentDidUpdate(prevProps){
    if(this.props.data && prevProps.data !== this.props.data){
      this._setInitialState(this.props.data);
    }
  },
  handleInputChange(e) {
    var new_state = this.state.info;
    new_state[e.target.name] = e.target.value;
    this.setState({info: new_state});
  },
  handleTagInputChange(e) {
    var checked = $.extend([], this.state.checked);
    var id = parseInt(e.target.value, 10);
    var index = checked.indexOf(id)
    index === -1 ? checked.push(id) : checked.splice(index, 1)
    this.setState({ checked: checked })
  },
  clearData() {
    this.setState(
      {
        info: {
          last_name: '',
          gender: 'other',
          phone_number: '',
          tele_number: '',
          email: '',
          note: ''
        },
        checked: []
      },
    );
  },
  render() {
    return (
      <div className="modal fade" id="addMemberModal" ref='modal'>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              <h4 className="modal-title">新增顧客</h4>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xs-5">
                      <div className="form-group">
                        <label>姓名*</label>
                        <input className="form-control"
                               name="last_name"
                               value={this.state.info.last_name}
                               onChange={this.handleInputChange}
                               placeholder="輸入姓" />
                      </div>
                    </div>
                    <div className="col-xs-5">
                    <div className="form-group select required member_gender">
                        <label className="select required control-label" htmlFor="member_gender">性別</label>
                        <select className="select required form-control"
                                name="gender"
                                value={this.state.info.gender}
                                onChange={this.handleInputChange}
                                required="required"
                                aria-required="true"
                                id="member_gender">
                          <option value='other'>其他</option>
                          <option value='male'>男性</option>
                          <option value='female'>女性</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-5">
                    <div className="form-group">
                        <label>手機電話*</label>
                        <input className="form-control"
                               name="phone_number"
                               value={this.state.info.phone_number}
                               onChange={this.handleInputChange}
                               placeholder="輸入手機電話" />
                      </div>
                    </div>
                    <div className="col-xs-5">
                      <div className="form-group">
                        <label>市話</label>
                        <input className="form-control"
                               name='tele_number'
                               placeholder="輸入市話"
                               value={this.state.info.tele_number}
                               onChange={this.handleInputChange} />
                      </div>
                     
                    </div>
                  </div>
                  <div className="row">
                    
                    <div className="col-xs-5">
                      <div className="form-group">
                        <label>email</label>
                        <input className="form-control"
                               name='email'
                               placeholder="tom@example.com"
                               value={this.state.info.email}
                               onChange={this.handleInputChange} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xs-12'>
                      <label>標記</label>
                      <div className="checkbox btn-group customer_tag" style={{marginTop: '-10px'}}>
                        {this.state.tags.map(function(tag){
                          return ( 
                            <label key={tag.id}>
                              <input className="checkbox_tag" 
                                     type="checkbox"
                                     name="tag" 
                                     value={tag.id}
                                     checked={this.state.checked.includes(tag.id)}
                                     onChange={this.handleTagInputChange} />
                              <img src={tag.image} alt="presentation"/>
                              {tag.description}
                            </label>
                          );
                        }.bind(this))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="form-group">
                        <label>備註</label>
                        <textarea className="text optional form-control"
                                  name="note"
                                  placeholder='您可以輸入顧客的用餐喜好，例如：不吃香菜/需兒童椅'
                                  value={this.state.info.note}
                                  onChange={this.handleInputChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-color-white btn-style4" data-dismiss="modal" onClick={this.clearData}>取消</button>
                <input type="submit" className="btn btn-color-black btn-style4" value="儲存" />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
});
