import React from 'react';

export const ServiceTag = ({ ele, handleTagChange, customQAns }) => {
	const options = JSON.parse(ele.content);
	return (
		<div className="customerInfoRow">
			<p className="customerInfoRow__title">
				{ele.title}
				<span style={{ marginLeft: '5px' }}>{ele.multiple_selected ? '(複選)' : '(單選)'}</span>
			</p>
			<div className="customerInfoRow__content">
				<div className="tagWrapper">
					{Object.keys(options).map((o) => {
						const active = customQAns[o] ? 'active' : '';

						return (
							<span
								key={o}
								className={active}
								onClick={() => handleTagChange(ele.id, ele.multiple_selected, o)}
							>
								{options[o].name}
							</span>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export const Questionnaire = ({ ele, customQAns, handleQuestionChange }) => {
	return (
		<div className="customerInfoRow">
			<p className="customerInfoRow__title">{ele.title}</p>
			<div className="customerInfoRow__content">
				<textarea value={customQAns} onChange={(e) => handleQuestionChange(ele.id, e)} />
			</div>
		</div>
	);
};

export const Quantity = ({ ele, options, customQAns, handleQuantityChange }) => {
	const numberOption = JSON.parse(ele.content);

	return (
		<div className="customerInfoRow">
			<p className="customerInfoRow__title">
				{ele.title}
				<span style={{ marginLeft: '5px' }}>{ele.multiple_selected ? '(複選)' : '(單選)'}</span>
			</p>

			<div className="customerInfoRow__content">
				<div className="selectWrap">
					{Object.keys(numberOption).map((o) => {
						const active = customQAns[o] && customQAns[o] !== 0 ? 'active' : '';
						const selectValue = customQAns[o] && customQAns[o] !== 0 ? customQAns[o] : 0;

						return (
							<select
								key={o}
								className={active}
								value={selectValue}
								onChange={(e) => handleQuantityChange(ele.id, e, ele.multiple_selected, o)}
							>
								{options[o].map((number) => {
									return (
										<option key={number} value={number}>
											{numberOption[o].name} x {number}
										</option>
									);
								})}
							</select>
						);
					})}
				</div>
			</div>
		</div>
	);
};
