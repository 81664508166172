import { browserHistory } from 'react-router'
import $ from 'jquery'
import IO from 'socket.io-client'
import { config } from '../utils/config'

var socket = {}

var handleError = function(xhr) {
	switch(xhr.status) {
		case 401:
      browserHistory.push('/login');
      break;
    default:
	}
};

var handleWebsocketConnection = function() {
    if(Object.keys(socket).length !== 0){
      socket.close();
    }
    $.ajax({
      url: config.domain + '/dashboard/get_apikey',
      dataType: 'json',
      xhrFields: { withCredentials: true },
      success: function(data) {
        socket = IO(config.ws_domain, {query: 'apikey='+data.apikey});
      },
      error: function(xhr) {
      }
    });
};

var handleWebsocketEventRegistry = function(event, callback) {
  var empty_socket = Object.keys(socket).length === 0 && socket.constructor === Object;
  if (!empty_socket) {
    socket.on(event, function(data) {
      callback(data);
    });
  } else {
    setTimeout(function() {
      handleWebsocketEventRegistry(event, callback);
    }, 500);
  }
};

let cancelWebsocketSubscription = (event)=>{
  socket.removeAllListeners(event);
}

export { handleError, handleWebsocketConnection, handleWebsocketEventRegistry, cancelWebsocketSubscription }