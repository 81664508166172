import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';

import {
  announcementSetting,
  announcementSettingEnded,
  showAnnouncementAddModal
} from '../../actions/announcementAction';

const day_list = ['日', '一', '二', '三', '四', '五', '六'];
const announceEditList = [
  { title: '編輯', action: 'edit' },
  { title: '設為結束', action: 'finished' },
  { title: '刪除', action: 'deleted' },
];

const AnnouncementCard = ({ data, item, idx, onlyRead = false, date = '' }) => {
  const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
    showListType
	} = announcementListReducer;
  const dispatch = useDispatch();

  const handleContentExpend = () => {
    const lists = document.getElementsByClassName('announcementCard');
    const card = document.getElementsByClassName(`${date + '_' + idx}`)[0];

    if(card.classList.contains('open')) {
      return card.classList.remove('open');
    }

    for(let i = 0; i < lists.length; i++) {
      lists[i].classList.remove('open');
    }

    card.classList.add('open');
  };

  const handleEditor = (item, id) => {
    let findIndex, announcementState;

    if(showListType === 'month') {
      findIndex = data[date].map((list) => list.id).indexOf(id);
      announcementState = _.cloneDeep(data[date][findIndex]);
    } else {
      findIndex = data.map((list) => list.id).indexOf(id);
      announcementState = _.cloneDeep(data[findIndex]);
    }

    dispatch(announcementSetting(announcementState));

    if (item === 'edit') {
      dispatch(showAnnouncementAddModal());
		} else{
      dispatch(announcementSettingEnded(item));
    }
  };

  const renderEditList = (id) => {
    return announceEditList.map((item) => {
      return (
        <li key={item.action} onClick={() => handleEditor(item.action, id)}>
          {item.title}
        </li>
      );
    });
	};

  const renderDateTime = () => {
    let day_start = new Date(item.start_time).getDay();
    let day_end = new Date(item.end_time).getDay();
    let startTime = 
      moment(item.start_time).format('YYYY-MM-DD') + 
      '（' + day_list[day_start] + '）' +
      moment(item.start_time).format('HH:mm');
    let endTime = 
      moment(item.end_time).format('YYYY-MM-DD') +
      '（' + day_list[day_end] + '）' +
      moment(item.end_time).format('HH:mm');

    return (
      <Fragment>
				{startTime} - <br />
				{endTime}
      </Fragment>
    )
  };

  return (
    <div
      key={idx}
      className={`announcementCard ${date + '_' + idx}`}
      onClick={() => handleContentExpend(idx)}
    >
      <div className='title'>
        <div>{ item.title }</div>
        { !onlyRead &&
          <div className="listCardDropDown" onClick={(e) => e.stopPropagation()}>
            <button className='editDropdown' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <MoreOutlined />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>{renderEditList(item.id)}</ul>
            </div>
          </div>
        }
      </div>
      <div className='dateTime'>
        { renderDateTime() }
        <div className='announcementBlock_arrow' />
      </div>
      <div className='content'>
        { item.content }
      </div>
    </div>
  )
};

export default AnnouncementCard;