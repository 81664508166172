const $status_confirmed = 'rgb(63, 186, 136)';
const $status_unconfirmed = 'rgb(246, 166, 34)';
const $status_cancel = 'rgb(151, 151, 151)';
const $status_noShow = 'rgb(103, 103, 103)';
const $status_show = 'rgb(52, 150, 153)';
const $status_seated = 'rgb(77, 159, 255)';
const $status_check = 'rgb(67, 70, 73)';
const $tableStatusEmptyColor = 'rgb(255, 255, 255)';

export const tableStatus = (status) => {
	let tableStatusColor = $tableStatusEmptyColor;
	switch (status) {
		case 'unconfirmed':
			tableStatusColor = $status_unconfirmed;
			break;
		case 'confirmed':
			tableStatusColor = $status_confirmed;
			break;
		case 'show':
			tableStatusColor = $status_show;
			break;
		case 'seated':
			tableStatusColor = $status_seated;
			break;
		case 'finish':
			tableStatusColor = $status_check;
			break;
		case 'no_show':
			tableStatusColor = $status_noShow;
			break;
		case 'cancel':
			tableStatusColor = $status_cancel;
			break;
		default:
			tableStatusColor = $tableStatusEmptyColor;
			break;
	}

	return {
		tableStatusColor
	};
};