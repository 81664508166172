import React, { Component } from 'react';

export default class QueueChartToolTip extends Component {
    render() {

        const { active, payload, external, label } = this.props;
        let currData = external.filter(entry => (entry.name === label))[0];

        if (currData !== undefined) {
                return (
                    <div className="container-chart-tip">
                    
                        <p className="chart-tip-date">{label}</p>
                        <div className="chart-tip-infoitem">
                            <span className="chart-tip-color" />
                            <span className="chart-tip-content">
                                報到：{currData["seated"]}
                            </span>
                        </div>
                        <div className="chart-tip-infoitem">
                            <span className="chart-tip-color blue" />
                            <span className="chart-tip-content">
                                失效：{currData["unavailable"]}
                            </span>
                        </div>
                        <div className="chart-tip-infoitem">
                            <span className="chart-tip-color yellow" />
                            <span className="chart-tip-content">
                                過號：{currData["standby"]}
                            </span>
                        </div>
                        <div className="chart-tip-infoitem">
                            <span className="chart-tip-color red" />
                            <span className="chart-tip-content">
                                取消：{currData["cancel"]}
                            </span>
                        </div>
                    </div>
                )
        } else {
            return null;
        }
    }
}
