import React from "react";
import { Link } from "react-router";
import QueuePanelAPI from "../queue_system/api/QueuePanelAPI";
import QueueSystemAPI from "../queue_system_settings/QueueSystemAPI";
import $ from "jquery";
import { browserHistory } from "react-router";
var createReactClass = require("create-react-class");
export default createReactClass({
	getInitialState() {
		return{
			activated: true,
			asking: true
		}
	},

	componentDidMount() {
		this.API = new QueuePanelAPI();
		this.systemAPI = new QueueSystemAPI();
		this.systemAPI
			.getQueueSettings()
			.then(data => {
				if (
					data.display_name === null ||
					data.display_name === undefined
				) {
					this.setState({ activated: false });
				}
			})
			.catch(error => {});
	},

	checkQueueSetting() {
		if (this.state.asking) {
			$("#startQueueModal").modal("toggle");
			this.setState({ asking: false });
		}
	},

	doSome() {
		this.systemAPI.createQueueSettings(data => {
			if (data !== undefined) {
				// console.log("開始使用排隊系統！");
			}
		});
		browserHistory.push("/dashboard/queue_system");
	},

	doCancel() {
		this.setState({ asking: true });
	},

	render() {
		if (this.state.activated) {
			return (
				<div className='main-item'>
					<Link to='/dashboard/queue_system' role='button'>
						<div className='function-thub'>
							<img
								alt='queue'
								src={require("../../images/component/f_queue.svg")}
							/>
							<div className='caption function-title'>
								<h3>排隊系統</h3>
								<p>顧客排隊系統</p>
							</div>
						</div>
					</Link>
				</div>
			);
		} else {
			return (
				<div className='main-item' onClick={this.checkQueueSetting}>
					<Link to='' role='button'>
						<div className='function-thub'>
							<img
								alt='queue'
								src={require("../../images/component/f_queue.svg")}
							/>
							<div className='caption function-title'>
								<h3>排隊系統</h3>
								<p>顧客排隊系統</p>
							</div>
						</div>
					</Link>
					<div
						className='modal fade'
						id='startQueueModal'
						tabindex='-1'
						role='dialog'
						aria-labelledby='closeModalLabel'
						aria-hidden='true'>
						<div
							className='modal-dialog modal-dialog-centered'
							role='document'>
							<div className='modal-content queue-modal-content'>
								<h5
									className='queue-modal-title'
									id='closeModalLabel'>
									開始使用排隊系統
								</h5>
								<div className='queue-modal-body'>
									是否開始使用排隊系統？
								</div>
								<div className='queue-modal-footer'>
									<button
										type='button'
										className='queue-btn-primary'
										data-dismiss='modal'
										onClick={this.doSome}>
										確認
									</button>
									<button
										type='button'
										className='queue-btn-normal'
										data-dismiss='modal'
										onClick={this.doCancel}>
										取消
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
});
