import React from 'react';

const scale = 90;

const BookingTableBookingGap = ({ dining_time, booking_duration, left, table }) => {
	const booking_gap_width = booking_duration * 3 - 5;
	const booking_gap_left = left + dining_time / 30 * scale;

	return (
		<div
			className="bookingCell bookingCell_gap"
			style={{
				left: booking_gap_left,
				width: booking_gap_width
			}}
		>
			<div>
				<span>{booking_duration}分鐘</span>
				<p>
					({table.min_seat} - {table.max_seat}人)
				</p>
			</div>
		</div>
	);
};

export default BookingTableBookingGap;
