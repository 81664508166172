import { SET_ALERT_MESSAGE } from "../actions/flashMessage"

const initialState = { message: "", messageType: ""}
export default function flashMessage(state = initialState, action) {
  switch (action.type) {
    case SET_ALERT_MESSAGE:
      return Object.assign({}, state, {message: action.message, messageType: action.messageType })
    default:
      return state
  }
}