import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import '../../../sass/menu_coupon_popup.scss';
import MenuCouponApi from '../MenuCouponApi';

const menuCouponAPI = new MenuCouponApi();

const PauseCouponPopup = () => {
	const auth = useSelector((state) => state.auth);
	const { name } = auth.shop;
	const { CouponState, CouponDispatch } = useContext(CouponStateContext);
	const { newConfirmCode, couponName, couponLists, couponId } = CouponState;

	const cancelPopup = () => {
		CouponDispatch({ type: 'cancelPopup' });
	};

	const changePassword = async()=>{
		const findIndex = couponLists.map((list) => list.id).indexOf(couponId);
		const couponSetting = couponLists[findIndex];

		let fd  = new FormData()

		fd.append("shopUserId", couponSetting.shopUserId)
		fd.append("id", couponSetting.id)
		fd.append("confirmCode", newConfirmCode.join(''))
		
		try {
			await menuCouponAPI.updateCoupon(fd);
			const list = await menuCouponAPI.getCoupon(name);
			CouponDispatch({ type: 'confirmPasswordPopup' });
			CouponDispatch({ type: 'getCouponList', list });
		} catch (err) {
			console.log('updateCoupon err-----', err);
		}
	}
	

	const handlePasswordChange = (e, index) => {
		const txt = e.target.value;
		const reg = /^\d+$/;
		const regTest = reg.test(txt);
		if (txt !== '' && !regTest) {
			return window.app.alert.setMessage('請輸入數字', 'error');
		}

		const copiedPassword = [ ...newConfirmCode ];
		copiedPassword[index] = txt;

		CouponDispatch({ type: 'setNewPassword', confirmCode: copiedPassword });

		const currentIndex = parseInt(e.target.getAttribute('tabIndex'), 10)
        const $passwordInput = document.getElementsByClassName('passwordInput')[0]
        const $input = $passwordInput.getElementsByTagName('input');
        
        if(currentIndex<3){
            $input[currentIndex + 1].focus();
        }
	};


	const renderPassword = () => {
		return (
			<div className="passwordInput">
				<input
					tabIndex="0"
					type="tel"
					maxLength="1"
					value={newConfirmCode[0]}
					onChange={(e) => handlePasswordChange(e, 0)}
				/>
				<input
					tabIndex="1"
					type="tel"
					maxLength="1"
					value={newConfirmCode[1]}
					onChange={(e) => handlePasswordChange(e, 1)}
				/>
				<input
					tabIndex="2"
					type="tel"
					maxLength="1"
					value={newConfirmCode[2]}
					onChange={(e) => handlePasswordChange(e, 2)}
				/>
				<input
					tabIndex="3"
					type="tel"
					maxLength="1"
					value={newConfirmCode[3]}
					onChange={(e) => handlePasswordChange(e, 3)}
				/>
			</div>
		);
    };
    
    const submitDisable = newConfirmCode.some(password=> password ==="");

	return (
		<div className="menuCouponPopupCard passwordPopup">
			<div className="menuCouponPopupCard__header">
				<h2>修改優惠券密碼</h2>
			</div>
			<div className="menuCouponPopupCard__body">
				<p>優惠券：{couponName}</p>
				<div className="passwordWrap">
					<p>輸入新密碼4位數字</p>

					{renderPassword()}
				</div>
			</div>
			<div className="menuCouponPopupCard__footer">
				<div className="footrBtnWrap">
					<button className="grayThemeBtn" onClick={() => cancelPopup()}>
						取消
					</button>
					<button disabled={submitDisable} className="greenThemeBtn" onClick={() => changePassword()}>
						確認修改
					</button>
				</div>
			</div>
		</div>
	);
};

export default PauseCouponPopup;
