import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { ScanOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../sass/menu_coupon_header.scss';


const MenuCouponHeader = ({addCoupon , scanCoupon}) => {
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<div className="menuCouponHeader">
			<div className="menuCouponHeader__title">
				<h1>優惠券總覽</h1>
			</div>
			<div className="menuCouponHeader__button">
				<button onClick={scanCoupon}>
					<ScanOutlined/>
					核銷優惠券
				</button>
				<button onClick={addCoupon}>
					<PlusOutlined />
					新增優惠券
				</button>
			</div>
		</div>
	);
};

export default MenuCouponHeader;
