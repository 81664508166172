import React, { Fragment, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';

const CouponPasswordSection = ({ children }) => {
	return <section className="couponAttention">{React.Children.map(children, (child) => child)}</section>;
};

const CouponSectionTitle = () => {
	return <h2>優惠券密碼</h2>;
};

const CouponPasswordTxt = () => {
	const { CouponAddState, CouponAddDispatch } = useContext(CouponAddStateContext);
	const { couponSetting, checkSetting } = CouponAddState;
	const { confirmCode } = couponSetting;
	const errorStyle = checkSetting && confirmCode === '' ? 'errorStyle' : '';

	const handlePassword = (e) => {
		const reg = /^\d+$/;
		const txt = e.target.value;
		const regTest = reg.test(txt);
		if(txt !== '' && !regTest){return window.app.alert.setMessage('請輸入數字', 'error')}
		CouponAddDispatch({ type: 'setPassword', confirmCode: txt });
	};

	return (
		<div className={`${errorStyle}`}>
			<input type="tel" placeholder="填寫四位數字" maxLength="4" value={confirmCode} onChange={(e) => handlePassword(e)} />
			{errorStyle !== '' && <p className="errorTxt">此欄位為必填。</p>}
		</div>
	);
};

CouponPasswordSection.Title = CouponSectionTitle;
CouponPasswordSection.Password = CouponPasswordTxt;

export default CouponPasswordSection;
