import React from 'react'
import Call from './Call'
var createReactClass = require('create-react-class');
export default createReactClass({
	render() {
    var calls = this.props.data.map(function(call) {
      return <Call key={call.id} 
                   data={call} 
                   onTempChange={this.props.onTempChange}
                   onAddClick={this.props.onAddClick}
                   onSubmit={this.props.onSubmit} />
    }.bind(this));
	  return (
	    <table className="table table-style table-hover phonecall-tbl">
        <thead>
          <tr>
            <th colSpan={5}>{this.props.header}</th>
          </tr>
        </thead>
        <tbody>
          {calls}
        </tbody>
      </table>
	  );
	}
})
