import axios from 'axios';
import { config } from '../../../utils/config';

const crmMessageSmsAxios = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

/* 取得 sms 訊息 */
export const getCrmMessagesSms = () => crmMessageSmsAxios.get('/dashboard/crm/message/sms');

/* 新建簡訊訊息 */
export const createCrmSms = (crmSmsContent) => crmMessageSmsAxios.post('/dashboard/crm/message/sms', crmSmsContent);

/* 修改簡訊訊息 */
export const updateCrmSms = (crmSmsId, crmSmsContent) => crmMessageSmsAxios.patch(`/dashboard/crm/message/sms/${crmSmsId}`, crmSmsContent);

/* 修改排程 */
export const updateCrmSmsSchedule = (crmSmsId, scheduleTime) => crmMessageSmsAxios.patch(`dashboard/crm/message/sms/${crmSmsId}/scheduling`, scheduleTime);

/* 取消排程 */
export const cancelCrmSmsSchedule = (crmSmsId) => crmMessageSmsAxios.delete(`dashboard/crm/message/sms/${crmSmsId}/scheduling`);

/* 刪除簡訊訊息 */
export const deleteCrmSms = (crmSmsId) => crmMessageSmsAxios.delete(`dashboard/crm/message/sms/${crmSmsId}`);

/* 篩選顧客 */
export const filterCustomers = (params) => crmMessageSmsAxios.get('/dashboard/crm/message/sms/filter_customers', { params });

/* 檢查內文 */
export const validateCrmSmsContent = (crmSmsContent) => crmMessageSmsAxios.post('/dashboard/crm/message/sms/validate_content', crmSmsContent);

/* 傳送測試簡訊 */
export const sendTestingSms = (testingContent) => crmMessageSmsAxios.post('/dashboard/crm/message/sms/send_test_sms', testingContent);