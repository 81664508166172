import React, { useState, useContext } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useInterval } from '../utils/data';
import QueuePanelAPI from '../../queue_system/api/QueuePanelAPI';

//reduecer
import { BaseSettingContext } from '../NewModuleReducer/BaseSettingReducer';
import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';

const queueAPI = new QueuePanelAPI();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const QueueListCard = ({ children,  queue }) => {
	return <section id={queue.id} className="queueList__card">{React.Children.map(children, (child) => child)}</section>;
};

const WaitingTimeCount = ({ queue }) => {
	const [ currentStamp, setCurrentStamp ] = useState(moment());
	const [ interval ] = useState(queue.waiting_time === null ? 1000 : null);

	useInterval(() => {
		setCurrentStamp(moment());
	}, interval);

	return (
		<p>
			已等待<span className="queueStatus-alert">
				{queue.waiting_time === null ? (
					currentStamp.diff(moment(queue.created_at), 'minutes')
				) : (
					queue.waiting_time
				)}
			</span>分鐘
		</p>
	);
};

const getEstimatedWaitingTime = (time_checking, groupCount) => {
	let timeCheckingConditions = [];
	try {
		timeCheckingConditions = JSON.parse(time_checking);
	} catch (e) {
		// console.log('Parse condition failed');
	}

	if (Array.isArray(timeCheckingConditions) && timeCheckingConditions.length > 1) {
		timeCheckingConditions.sort((first, second) => {
			return first.count - second.count;
		});
	}

	let estimatedWaitingTime = 0;

	for (let i = 0; i < timeCheckingConditions.length; i++) {
		const element = timeCheckingConditions[i];
		if (groupCount <= element.count) {
			if (i === 0) {
				if (groupCount === element.count) {
					estimatedWaitingTime = element.time;
				} else {
					estimatedWaitingTime = '少於 ' + element.time;
				}
			} else {
				const prevElement = timeCheckingConditions[i - 1];
				estimatedWaitingTime = prevElement.time;
			}
			break;
		} else {
			const prevElement = timeCheckingConditions[i];
			estimatedWaitingTime = prevElement.time;
		}
	}

	return estimatedWaitingTime;
};

const QueueListCardHeader = ({ queue }) => {
	const { BaseSettingState } = useContext(BaseSettingContext);
	const { timeChecking } = BaseSettingState;

	const { CommomSettingState } = useContext(CommomSettingContext);
	const { isToday } = CommomSettingState;

	const renderWaitingInfo = () => {
		const sourceTxt = queue.source === 'offline' ? '自建' : '線上';
		if (isToday) {
			return (
				<p>
					{sourceTxt} | 前面有{queue.waiting_groups}組
				</p>
			);
		} else {
			return <p>{sourceTxt}</p>;
		}
	};

	const renderWaitingTime = () => {
		if (isToday) {
			return (
				<React.Fragment>
					<p>
						預計等待
						<span className="queueStatus-alert">
							{getEstimatedWaitingTime(timeChecking, queue.waiting_groups)}
						</span>
						分鐘
					</p>
					<WaitingTimeCount queue={queue} />
				</React.Fragment>
			);
		}
	};

	return (
		<div className="queueList__card__header">
			<div className="queueGroupName">
				<span>{queue.group_name}</span>
			</div>
			<div className="queueStatus">
				{renderWaitingInfo()}
				{renderWaitingTime()}
			</div>
		</div>
	);
};

const QueueListCardBody = ({ children }) => (
	<div className="queueList__card__body">{React.Children.map(children, (child) => child)}</div>
);

const QueueListCardBodyInfo = ({ queue }) => {
	const rednerGender = () => {
		if (queue.gender === 'male') {
			return <span className="gender">先生</span>;
		}

		if (queue.gender === 'female') {
			return <span className="gender">小姐</span>;
		}
	};

	return (
		<div className="queueInfo">
			<div className="queueName">
				<span>{queue.name}</span>
				{rednerGender()}
				<span>({queue.people_count}人)</span>
				<p>{queue.phone}</p>
				{queue.email.trim() === '' ? null : <p>{queue.email}</p>}
			</div>
			<div className="queueNumber">{queue.waiting_number}</div>
		</div>
	);
};

const QueueShopMemo = ({ queue }) => {
	const {setUuid} = useContext(CommomSettingContext)

	const [ editMode, setEditMode ] = useState(false);
	const [ shopMemo, setShopMemo ] = useState(queue.shop_memo ? queue.shop_memo : '');
	const [ tempShopmemoTxt ] = useState(shopMemo);

	const replaceShopMemo = (memo) => {
		queue['shop_memo'] = memo;
	};

	const renderQueueShopMemo = () => {
		if (!editMode) {
			return (
				<React.Fragment>
					<div className="text-left">
						<div className="eidtWrap" onClick={() => setEditMode(true)}>
							<div className="eidtWrap__img" />
							<div className="eidtWrap__txt">備註</div>
						</div>
					</div>
					{shopMemo !== '' && <div className="queueShopMemo__txt">{shopMemo}</div>}
				</React.Fragment>
			);
		}

		return (
			<div className="shopmemo__editMode">
				<textarea value={shopMemo} placeholder="填寫備註" onChange={(e) => handleShopMemo(e)} />
				<div className="shopmemo__editMode__btn" onClick={() => checkMemo()}>
					<button className="btn-cancel" onClick={() => discardMemo()}>
						取消
					</button>
					<button className="btn-check">確定</button>
				</div>
			</div>
		);
	};

	const handleShopMemo = (e) => {
		const txt = e.target.value;
		setShopMemo(txt);
	};

	const discardMemo = () => {
		setEditMode(false);
		setShopMemo(tempShopmemoTxt);
	};

	const checkMemo = () => {
		//uuid
		const eventId = uuidv4();
		setUuid(eventId)

		queueAPI
			.updateQueueShopMemo(queue.id, shopMemo, eventId)
			.then(() => {
				setEditMode(false);
				setShopMemo(shopMemo);
				replaceShopMemo(shopMemo);
			})
			.catch(() => {
				window.app.alert.setMessage('請稍後再試', 'error');
			});
	};

	return <div className="queueShopMemo">{renderQueueShopMemo()}</div>;
};

const QueueListCardBodyButton = ({ queue, startQueueTimeline, updateQueueRecord }) => {
	const { CommomSettingState, CommomSettingDispatch, setUuid } = useContext(CommomSettingContext);
	const { mode, isToday } = CommomSettingState;

	const { BaseSettingState } = useContext(BaseSettingContext);
	const { smsEnable, showCanvas } = BaseSettingState;

	const [ standbyLoading, setStandbyLoading ] = useState(false);
	const [ emailLoading, setEmailLoading ] = useState(false);

	const moveTableMap = () => {
		CommomSettingDispatch({ type: 'chooseQueueSeated', queue });

		const $canvas = document.getElementsByClassName('tablemap')[0];
		const $newModule = document.getElementsByClassName('newModule')[0];
		const $newModuleMain = document.getElementsByClassName('newModule__main')[0];

		$newModuleMain.style.overflow = 'visible';
		$newModule.style.zIndex = 1032;

		$('#canvasSeated').show();
		$('#canvasSeated').append($canvas);
	};

	const queueSeated = () => {
		if (showCanvas && mode === 'tablemap') {
			moveTableMap();
		} else {
			CommomSettingDispatch({ type: 'setMode', mode: 'timeline' });
			startQueueTimeline(queue);
		}
	};

	const changeListItemToStandby = (id) => {
		setStandbyLoading(true);
		const createdTime = moment().diff(moment(queue.created_at), 'minutes');
		//uuid
		const eventId = uuidv4()
		setUuid(eventId)

		setTimeout(() => {
			queueAPI.updateQueueRecord(id, 'standby', createdTime, (data) => {
				setStandbyLoading(false);

				if (data !== undefined) updateQueueRecord();
			}, eventId);
		}, 500);
	};

	const sendNotification = (id) => {
		setEmailLoading(true);
		setTimeout(() => {
			//set uuid
			const eventId = uuidv4();
			setUuid(eventId)

			queueAPI.sendNotification(id, eventId).then(
				(data) => {
					setEmailLoading(false);

					if (data !== undefined) {
						updateQueueRecord();
					}
				},
				(error) => {
					console.log('sendNotification error---', error);
				}
			);
		}, 500);
	};

	const renderMemo = () => {
		if (queue.memo) {
			return <div className="queueMemo-customer">{queue.memo}</div>;
		}
	};

	const renderStandbyBtn = () => {
		if (standbyLoading) {
			return (
				<button className="queueActionBtn-cancel">
					<Spin
						indicator={antIcon}
						spinning={standbyLoading}
						style={{
							color: '#C8C8C8'
						}}
					/>
				</button>
			);
		} else if (queue.status === 'waiting') {
			return (
				<button className="queueActionBtn-cancel" onClick={() => changeListItemToStandby(queue.id)}>
					<i />
				</button>
			);
		}
	};

	const renderEmailBtn = () => {
		if (queue.email || smsEnable) {
			if (emailLoading) {
				return (
					<button className="queueActionBtn-email">
						<Spin
							indicator={antIcon}
							spinning={emailLoading}
							style={{
								color: '#3D77A2'
							}}
						/>
					</button>
				);
			} else if (queue.status === 'canceled' && queue.is_notified) {
				return (
					<button className="queueActionBtn-emailSended" style={{ cursor: 'default' }}>
						<i />
					</button>
				);
			} else if (queue.status === 'canceled' && !queue.is_notified) {
				return (
					<button className="queueActionBtn-email" style={{ cursor: 'default' }}>
						<i />
					</button>
				);
			} else if (queue.status !== 'canceled' && queue.is_notified) {
				return (
					<button className="queueActionBtn-emailSended" onClick={() => sendNotification(queue.id)}>
						<i />
					</button>
				);
			} else if (queue.status !== 'canceled' && !queue.is_notified) {
				return (
					<button className="queueActionBtn-email" onClick={() => sendNotification(queue.id)}>
						<i />
					</button>
				);
			}
		}
	};

	const renderSeatedBtn = () => {
		if (queue.status !== 'canceled') {
			return (
				<button className="queueActionBtn-check" onClick={() => queueSeated()}>
					<i />
				</button>
			);
		}
	};

	return (
		<div className="queueTools">
			<div className="queueMemo">{renderMemo()}</div>
			<div className="queueActionBtns">
				{isToday && renderStandbyBtn()}
				{renderEmailBtn()}
				{isToday && renderSeatedBtn()}
			</div>
		</div>
	);
};

QueueListCard.Header = QueueListCardHeader;
QueueListCard.Body = QueueListCardBody;

QueueListCard.Body.Info = QueueListCardBodyInfo;
QueueListCard.Body.Memo = QueueShopMemo;
QueueListCard.Body.Button = QueueListCardBodyButton;

export default QueueListCard;
