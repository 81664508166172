import React, { useEffect } from 'react';
import { Link } from 'react-router';
import ScrollReveal from '../../libs/vendor/scrollreveal.min';
import PageNav from './PageNav';
import PageFooter from './PageFooter';
import PageSlogan from './PageSlogan';

import '../../sass/pageAnnouncement.scss';

import cover_announcement from '../../images/page_announcement/cover_announcement.gif';
import cover_month_announcement from '../../images/page_announcement/cover_month_announcement.gif';
import cover_official_announcement from '../../images/page_announcement/cover_official_announcement.png';
import announcement_notice from '../../images/page_announcement/announcement_notice.svg';
import announcement_rules from '../../images/page_announcement/announcement_rules.svg';
import announcement_mascot from '../../images/page_announcement/announcement_mascot.svg';


const PageAnnouncement = () => {
  useEffect(() => {
    window.sr = new ScrollReveal();

    window.sr.reveal('.section_hero h1, .section_hero h3', {
			distance: '100px',
			origin: 'top',
			duration: 1000
		});

    window.sr.reveal('.section_hero button', {
			distance: '100px',
			origin: 'top',
			duration: 1000,
      delay: 300
		});

    window.sr.reveal('.section_hero .cover', {
			distance: '150px',
			origin: 'right',
			duration: 1000,
      delay: 500
		});

    window.sr.reveal('.section_calendar h1', {
			distance: '100px',
			origin: 'bottom',
			duration: 1000,
      viewFactor: 1
		});

    window.sr.reveal('.section_calendar h3', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      viewFactor: 0.7
		});

    window.sr.reveal('.section_calendar img', {
			distance: '150px',
			origin: 'left',
			duration: 1000,
      delay: 700
		});

    window.sr.reveal('.section_advantage .notice_block h1', {
			distance: '150px',
			origin: 'top',
			duration: 1000,
      viewFactor: 1
		});

    window.sr.reveal('.section_advantage .notice_block h3', {
			distance: '150px',
			origin: 'top',
			duration: 1000,
      delay: 500,
      viewFactor: 0.7
		});

    window.sr.reveal('.section_advantage .notice_img', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 700,
      rotate: { x: 0, y: 0, z: 50 },
		});

    window.sr.reveal('.section_advantage .rules_block h1', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 500
		});

    window.sr.reveal('.section_advantage .rules_block h3', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 700
		});

    window.sr.reveal('.section_advantage .rules_img', {
			distance: '200px',
			origin: 'bottom',
			duration: 1000,
      delay: 700,
      rotate: { x: 0, y: 0, z: -50 }
		});

    window.sr.reveal('.section_announcement h1', {
			distance: '100px',
			origin: 'bottom',
			duration: 1000,
      delay: 500
		});

    window.sr.reveal('.section_announcement h3', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
      delay: 700
		});

    window.sr.reveal('.section_announcement img', {
			distance: '150px',
			origin: 'bottom',
			duration: 1500,
      delay: 900
		});
  }, []);
  return (
    <div className='pageAnnouncement'>
      <PageNav/>
      
      <section className='section_hero'>
        <div className='content'>
          <h1>公告系統</h1>
          <h3>提升店內溝通效率，重要事項不遺漏！</h3>
          <Link to="/contact">
            <button>立即試用</button>
          </Link>
        </div>
        <img className='cover' src={cover_announcement} alt="" />
      </section>

      <section className='section_calendar'>
        <h1>店內重要活動，<br />友善布達提醒</h1>
        <h3>月曆備忘模式，可即時閱覽每日重要訊息</h3>
        <div>
          <img src={cover_month_announcement} alt="" />
        </div>
      </section>

      <section className='section_advantage'>
        <div className='notice_block'>
          <h1>工作交接不遺漏</h1>
          <h3>
            員工工作日誌、重要賓客、補貨備料 <br />
            店內大小事，輕鬆交接交辦
          </h3>
          <div className='notice_box'>
            <img className='notice_img' src={announcement_notice} alt="" />
            <div className='mascot'>
              <img className='advantage_mascot' src={announcement_mascot} alt="" />
            </div>
          </div>
        </div>
        <div className='mascot_mobile'>
          <img className='advantage_mascot' src={announcement_mascot} alt="" />
        </div>
        <div className='rules_block'>
          <img className='rules_img' src={announcement_rules} alt="" />
          <h1>店內規範不遺忘</h1>
          <h3>
            常態公告功能，永久保存，隨時提醒 <br />
            減少員工訓練成本
          </h3>
        </div>
      </section>

      <section className='section_announcement'>
        <div>
          <h1>系統狀態即時通知</h1>
          <h3>
            店+官方行銷活動合作、系統維修預告 <br />
            官方消息一手掌握，讓你不慌不忙地應變，安心使用
          </h3>
        </div>
        <img src={cover_official_announcement} alt="" />
      </section>
      <PageSlogan/>
      <PageFooter/>
    </div>
  )
}

export default PageAnnouncement;