import React, { Component } from 'react';
import QueueSystemAPI from './QueueSystemAPI';
import $ from 'jquery';

export default class QueueSettingTime extends Component {


    state = {
        showWaitingGroup: false,
        showWaitingTime: false,
        timeCheckConditions: [],
        isSaved: true
    }

    addTimeCheckCondition = () => {
        let timeCheckConditions = this.state.timeCheckConditions

        if (timeCheckConditions.length < 5) {
            timeCheckConditions.push({
                count: 0,
                time: 0
            })
            this.setState({
                timeCheckConditions: timeCheckConditions,
                isSaved: false
            })
        } else {
            alert("最多只能新增五組時間判斷喔！")
        }
    }

    routerWillLeave = (nextLocation) => {
        if(!this.state.isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?'
    }

    componentDidMount() {
        this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
        this.API = new QueueSystemAPI()
        this.API.getQueueSettings().then(data => {
            // console.log(data)
            let timeCheckingConditions = []
            try {
                timeCheckingConditions = JSON.parse(data["time_checking"])
            } catch (e) {
                console.log("Parse condition failed")
            }

            this.setState({
                showWaitingGroup: data["show_waiting_group"],
                showWaitingTime: data["show_waiting_time"],
                timeCheckConditions: timeCheckingConditions
            })

        }, error => {
            
        })
    }

    componentDidUpdate(prevProps, prevState) {
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
        })      
    }

    render() {


        let timeCheckConditions = []
        this.state.timeCheckConditions.forEach((element, index) => {
            timeCheckConditions.push(
                <div key={index} style={{marginBottom: '32px', display: 'flex', alignItems: 'center'}}>當等待組數 > 
                    <input className="form-control input-lg" 
                        style={{width: '80px', display: 'inline', marginLeft: '12px', marginRight: '12px'}}  
                        value={element.count}
                        onChange={e => {
                            let conditions = this.state.timeCheckConditions
                            conditions[[index]].count = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10)
                            this.setState({
                                timeCheckConditions: conditions,
                                isSaved: false
                            })
                        }}/>
                    組，等待時間為
                    <input className="form-control input-lg" 
                        style={{width: '80px', display: 'inline', marginLeft: '12px', marginRight: '12px'}}
                        value={element.time}
                        onChange={e => {
                            let conditions = this.state.timeCheckConditions
                            conditions[[index]].time = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10) 
                            this.setState({
                                timeCheckConditions: conditions,
                                isSaved: false
                            })
                        }}  />
                    分鐘
                    <button className="setting-delete-button" onClick={e => {
                        let timeCheckConditions = this.state.timeCheckConditions
                        timeCheckConditions.splice(index, 1)
                        this.setState({
                            timeCheckConditions: timeCheckConditions,
                            isSaved: false
                        })
                    }}>
                        <img src={require("../../images/queue/icon_delete.png")} alt="" />
                        <p>刪除</p>
                    </button>
                </div>
            )
        })

        return (
            <>
                <div className="main overflow">
                    <div style={{display: 'flex'}}>
                        <div style={{flexGrow: 1}}>
                            <h3>預計等候時間設定</h3>
                            <h5>您可以設定是否在線上排隊頁面中，讓顧客檢視等候的組數與預計等候時間。預計等候時間將依據您設定的時間判斷規則顯示。</h5>
                        </div>
                    </div>
                    <hr />
                    <div className="settingTbl" style={{marginLeft: 0}}>
                        <div className="settingTblRow">
                            <div className="header" data-toggle="tooltip" data-placement="bottom" title="顧客是否能看到目前尚在排隊等待中的組數">
                                顯示等候組數 
                                <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                            </div>
                            <div className="text">
                                <label className="msSwitch">
                                <input type="checkbox" 
                                    name="enable" 
                                    checked={this.state.showWaitingGroup}
                                    onChange={e => {
                                        this.setState({
                                            showWaitingGroup: e.target.checked
                                        })
                                    }}/>
                                <span className="slider"></span>
                                </label>
                            </div>
                        </div>
                        <br/>
                        <div className="settingTblRow">
                            <div className="header" data-toggle="tooltip" data-placement="bottom" title="顧客是否能看到目前預估等待的時間">
                                顯示等候時間 
                                <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                            </div>
                            <div className="text">
                                <label className="msSwitch">
                                <input type="checkbox" 
                                    name="enable" 
                                    checked={this.state.showWaitingTime}
                                    onChange={e => {
                                        if (this.state.timeCheckConditions.length > 0) {
                                            this.setState({
                                                showWaitingTime: e.target.checked,
                                                isSaved: false
                                            })
                                        } else {
                                            this.setState({
                                                showWaitingTime: false
                                            })
                                        }
                                    }}/>
                                <span className="slider"></span>
                                </label>
                            </div>
                        </div>
                        <br/>
                        <div className="settingTblRow">
                            <div className="header">時間判斷 </div>
                            <div className="text">
                                {timeCheckConditions}
                                <button className="msBtn" 
                                    onClick={this.addTimeCheckCondition}
                                    >＋ 時間判斷
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className='main_width' />

                <div className='fix_bottom'>
                    <button
                        className='btn_submit'
                        onClick={ev => {

                            let hasConflict = false
                            let firstConflictIndex = 0 
                            let secondConflictIndex = 0 
                            this.state.timeCheckConditions.forEach((condition, index) => {
                                if (!hasConflict) {
                                    firstConflictIndex = index 
                                    this.state.timeCheckConditions.forEach((innerCondition, innerIndex) => {
                                        if (!hasConflict) {
                                            secondConflictIndex = innerIndex
                                            if (innerCondition.count < condition.count && innerCondition.time > condition.time) {
                                                hasConflict = true
                                            }
                                        }
                                    })   
                                }                         
                            })

                            if (!hasConflict) {
                                this.API.updateQueueSettings({
                                    show_waiting_group: this.state.showWaitingGroup,
                                    show_waiting_time: this.state.showWaitingTime,
                                    time_checking: JSON.stringify(this.state.timeCheckConditions)
                                }).then(data => {
                                    window.app.alert.setMessage("儲存成功", "done");
                                    this.setState({
                                        isSaved: true
                                    })
                                }, error => {
                                    window.app.alert.setMessage("儲存失敗", "error");
                                })
                            } else {
                                alert("第"+(firstConflictIndex+1)+"組和第"+(secondConflictIndex+1)+"組的條件有衝突喔！")
                            }
                        }}>儲存</button>
                </div>
            </>
        )
    }
}
