import React from 'react';
import Banner from '../../../images/newModule/newFeature2.png';

export default ({ submit }) => (
	<div className="page1">
		<h3>暫停/結束排隊</h3>
		<p>您可以在【新增排隊】中找到暫停或結束排隊功能，使用此功能以避免顧客在閉店時段新增排隊候位。</p>

		<div className="newFeatureBanner">
			<img src={Banner} alt="Haven Seen New Feature ?" />
		</div>

		<button onClick={submit}>確定</button>
	</div>
);
