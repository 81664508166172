import React from 'react'
import $ from 'jquery'
import { config } from '../../utils/config'
import { handleError } from '../../libs/handler'
var createReactClass = require('create-react-class');
export default createReactClass({
    getInitialState() {
      return {
        users: []
      }
    },
    componentDidMount() {
        if(typeof(window.app.current_user)!=='undefined') {
          this.getUsers();
        } else {
          setTimeout( () => this.getUsers(), 100 )
        }
    },
    getUsers() {
      $.ajax({
        url: config.domain + '/dashboard/users.json',
        dataType: 'json',
        xhrFields: { withCredentials: true },
        success: function(data) {
          this.setState({users: data.users});
        }.bind(this),
        error: function(xhr) {
          handleError(xhr);
        }
      });
    },
    addUser(user, callback){
        var url = config.domain + "/dashboard/users.json";
        var data = user;
        var that = this;
        $.ajax({
            type:"POST",
            url: url,
            data: data,
            xhrFields: { withCredentials: true },
            success:function(data)
            {
                // console.log(data);
                that.getUsers();
                $(that.refs.addUserModal.refs.modal).modal('hide');
                window.app.alert.setMessage("新增成功","m-alert m-alert-done");
                if (typeof(callback) === "function") {
                   callback();
                }
            },
            error: function(xhr, status, error){
                window.app.alert.setMessage(xhr.responseJSON.message, 'error')
            }
        });
    },
    deleteUser(id){
        var url = config.domain + "/dashboard/users/" + id;
        var that = this;
        $.ajax({
            type:"DELETE",
            dataType: "json",
            url: url,
            xhrFields: { withCredentials: true },
            success:function(data)
            {
                that.getUsers();
                $(that.refs.deleteUserModal.refs.modal).modal('hide');
                window.app.alert.setMessage("移除成功","m-alert m-alert-done");

            },
            error: function(xhr, status, error){
                $(that.refs.deleteUserModal.refs.modal).modal('hide');
                window.app.alert.setMessage("移除失敗","m-alert m-alert-done");
            }
        });
    },
    render() {
        return (
            <div>
                <div className="container container-shadow">
                    {/* title*/}
                    <div className="func-title">
                        <img src={require("../../images/component/f_controlpanel.svg")} alt="" />
                        <h1>人員帳號設定</h1>
                    </div>
                    {/* content */}
                    <div>
                        <div className="menu-section">
                            <div className="menu-panel">
                                {/* Table */}
                                <UserTable users={this.state.users}
                                    deleteUserModal={this.refs.deleteUserModal}
                                    resetUserPwdModal={this.refs.resetUserPwdModal} />
                            </div>
                        </div>
                    </div>
                </div>
                <AddUserModal submitHandler={this.addUser} ref="addUserModal"/>
                <DeleteUserModal submitHandler={this.deleteUser} ref="deleteUserModal" />
                <ResetUserPwdModal ref="resetUserPwdModal"
                    domain={window.domain}
                    getUsers={this.getUsers}
                    alert={this.refs.alert}/>
            </div>
        );
    }
});

var UserRow = createReactClass({
    getInitialState() {
      return ({
        permission: window.app.current_user.permission.user
      })
    },
    showDeleteUserModal(id){
        this.props.deleteUserModal.showModal(id);
    },
    renderPwd(pwd) {
      if(this.state.permission.delete) {
        return (
          <td className="shop-application">{pwd}</td>
        )
      } else {
        return (
          <td className="shop-application" />
        )
      }
      
    },
    renderButton(user) {
      if(user.id === window.app.current_user.id) {
        return (
          <td style={{textAlign: 'right'}}>
          </td>
        )
      } else {
        if(this.state.permission.delete) {
          return (
            <td style={{textAlign: 'right'}}>
              <button className="btn btn-color-white btn-style3" data-toggle="modal" onClick={this.showDeleteUserModal.bind(null, user.id)}><span className="glyphicon glyphicon-remove" aria-hidden="true" data-target=".deletemember"/> 移除</button>
              <button className="btn btn-color-white btn-style3" data-toggle="modal" onClick={this.props.resetUserPwdModal.showModal.bind(null, user.id)} ><span className="glyphicon glyphicon-refresh" aria-hidden="true" /> 重設密碼</button>
            </td>
          )
        } else {
          return (
            <td style={{textAlign: 'right'}}>
            </td>
          )
        }
      }
    },
    render() {
        var user = this.props.user;
        var firstPwd = null;
        if(this.props.user.first_time_login_password) {
            firstPwd = "初次登入密碼：" + this.props.user.first_time_login_password;
        }
        return (
            <tr>
                <td className="shop-username">{user.name}</td>
                <td className="shop-account">{user.user_id}</td>
                {this.renderPwd(firstPwd)}
                {this.renderButton(user)}
            </tr>
        );
    }
})

var UserTable = createReactClass({
    getInitialState() {
      return ({
        users: this.props.users
      })
    },
    componentDidUpdate(prevProps){
      if(prevProps.users !== this.props.users){
        this.setState({ users: this.props.users })
      }
    },
    renderButton(permission) {
      if(permission.create) {
        return (
          <div className="nav nav-pills fun-bar">
            <button type="button" className="btn-style3 btn-color-white" data-toggle="modal" data-target="#addModal" data-whatever="@mdo"><i className="fa fa-plus" /> 新增人員</button>
            <br/>
            <br/>
          </div>
        )
      }
    },
    render() {
        var users = this.state.users;
        var contents = [];
        var that = this;
        if(Object.keys(this.state.users).length>0) {
          var permission = window.app.current_user.permission.user
          for(var group in users) {
              if(users.hasOwnProperty(group)){
                contents.push(<thead key={group + "_h"}>
                    <tr>
                        <th>姓名</th>
                        <th>帳號</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>);
                var tbody = [];
                // eslint-disable-next-line
                users[group].forEach(function(user){
                    tbody.push(<UserRow
                        user={user}
                        key={user.id}
                        deleteUserModal={that.props.deleteUserModal}
                        resetUserPwdModal={that.props.resetUserPwdModal}
                    />);
                });
                contents.push(<tbody key={group}>{tbody}</tbody>);
              }
          }
          return (
            <div>
              {this.renderButton(permission)}
              <table className="table table-hover table-style">
                {contents}
              </table>
            </div>
          );
  
        } else {
          return (<div />)
        }
    }
})

var DeleteUserModal = createReactClass({
    showModal: function(id){
        this.setState({id: id});
        $(this.refs.modal).modal('show');
    },
    submitHandler: function(){
        this.props.submitHandler(this.state.id);
    },
    render: function(){
        return(
            <div ref="modal" className="modal fade deletemember" tabIndex={-1} role="dialog" aria-labelledby="mySmallModalLabel">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body-center">
                            <h4 className="modal-title" id="myModalLabel">確定要刪除成員?</h4>
                            <br />
                            <button type="button" className="btn-length-180 btn-color-white btn-style4" data-dismiss="modal">取消</button>
                            <button type="button" onClick={this.submitHandler} className="btn-length-180 btn-color-red btn-style4">刪除</button>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
});

var ResetUserPwdModal = createReactClass({
    showModal: function(id){
        this.setState({id: id});
        $(this.refs.modal).modal('show');
    },
    resetPassword: function(id){
        var that = this;
        $.ajax({
            type:"POST",
            url: window.domain + '/dashboard/users/' + that.state.id + '/generate_password',
            xhrFields: { withCredentials: true },
            success:function(data)
            {
                that.props.getUsers();
                $(that.refs.modal).modal('hide');
                window.app.alert.setMessage("成功重設密碼","done");
            },
            error: function(xhr, status, error){
                window.app.alert.setMessage("重設密碼失敗","error");
            }
        });
    },
    render: function(){
        return(
        <div ref="modal" className="modal fade" id="ResetModal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body-center">
                        <h4 className="modal-title" id="myModalLabel">確定要幫􏰊此人重設密碼?</h4>
                        <br />
                        <button type="button" onClick={this.resetPassword} className="btn-length-180 btn-color-red btn-style4">重設密碼</button>
                        <button type="button" className="btn-length-180 btn-color-white btn-style4" data-dismiss="modal">取消</button>
                    </div>
                </div>
            </div>
        </div>);
    }
});

var AddUserModal = createReactClass({
    submitHandler: function(){
        var userData = $(this.refs.form).serializeArray();
        this.props.submitHandler(userData, function(){
            $(this.refs.form)[0].reset();
        }.bind(this))
    },
    resetForm: function(){
        $(this.refs.form)[0].reset();
    },
    render: function() {
        return (
            <div ref="modal" className="modal fade" id="addModal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                            <h4 className="modal-title" id="myModalLabel">新增人員</h4>
                        </div>
                        <form ref="form">
                            <div className="modal-body">
                                <div ref="msg"></div>
                                <div className="form-group">
                                    <label className="control-label">帳號</label>
                                    <input type="text" name="user_id" className="form-control" placeholder="輸入帳號" />
                                </div>
                                <input type="hidden" value="admin" />
                                <div className="form-group">
                                    <label className="control-label">姓名</label>
                                    <input type="text" name="name" className="form-control" placeholder="輸入姓名" />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">第一次登入密碼</label><br />
                                    <input name="first_time_login_password" className="form-control" placeholder="隨機密碼" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn-color-white btn-style4" onClick={this.resetForm} data-dismiss="modal">取消</button>
                                <button type="button" className="btn-color-black btn-style4" onClick={this.submitHandler} >新增</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
});
