import React, { useEffect } from 'react';

export const seatAlertTitle = '桌次有尚未結束的預約';

export const bookingStatus = [
	{
		status: 'unconfirmed',
        status_t: '待確認',
	},
	{
		status: 'confirmed',
        status_t: '確認預約',
	},
	{
		status: 'show',
        status_t: '已到店',
	},
	{
		status: 'seated',
        status_t: '已入座',
	},
	{
		status: 'finish',
        status_t: '完成消費',
	},
	{
		status: 'no_show',
        status_t: '未到店',
	},
	{
		status: 'cancel',
        status_t: '取消預約',
	}
];

export const bookingStatusObj = {
	unconfirmed: '待確認',
	confirmed: '確認預約',
	show: '已到店',
	seated: '已入座',
	finish: '完成消費',
	no_show: '未到店',
	cancel: '取消預約'
}


export const time = [
	'00:00',
	'00:30',
	'01:00',
	'01:30',
	'02:00',
	'02:30',
	'03:00',
	'03:30',
	'04:00',
	'04:30',
	'05:00',
	'05:30',
	'06:00',
	'06:30',
	'07:00',
	'07:30',
	'08:00',
	'08:30',
	'09:00',
	'09:30',
	'10:00',
	'10:30',
	'11:00',
	'11:30',
	'12:00',
	'12:30',
	'13:00',
	'13:30',
	'14:00',
	'14:30',
	'15:00',
	'15:30',
	'16:00',
	'16:30',
	'17:00',
	'17:30',
	'18:00',
	'18:30',
	'19:00',
	'19:30',
	'20:00',
	'20:30',
	'21:00',
	'21:30',
	'22:00',
	'22:30',
	'23:00',
	'23:30',
	'24:00'
];

export const statusColorBtn = {
	unconfirmed: '#f6a622',
	confirmed: '#3fba88',
	show: '#349699',
	seated: '#4d9fff',
	finish: '#4a78b4',
	no_show: '#676767',
	cancel: '#979797'
};


export function useInterval(callback, delay) {
	const savedCallback = React.useRef();

	// 保存新回调
	useEffect(() => {
		savedCallback.current = callback;
	});

	// 建立 interval
	useEffect(
		() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				return () => {
					clearInterval(id);
				};
			}
		},
		[ delay ]
	);
}


// NewModule.js

export const view = [ 'tablemap', 'timeline' ];

export const weeks = {
	Sunday: '星期日',
	Monday: '星期一',
	Tuesday: '星期二',
	Wednesday: '星期三',
	Thursday: '星期四',
	Friday: '星期五',
	Saturday: '星期六'
};

export const systemTabs = {
	booking: '已來店顧客',
	queue: '排隊中顧客'
};

//NewModule_bookings

export const bookingStatusTabJson = {
	confirmed: '確認預約',
	show: '已到店',
	seated: '已入座',
	finish: '結帳完成',
	unconfirmed: '待確認',
	no_show: '未到店',
	cancel: '取消預約'
};

//TableBookking.js
export const tableBookingNum = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 'delete', 0, 'check' ];
export const telNumpad = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear', 0, 'delete' ];



