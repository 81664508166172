import React, { Fragment, useEffect, useContext }  from 'react';
import _ from 'lodash';
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';

import { MessageContext } from '../crmContext/MessageContext';
import { MessageAddStateContext } from '../crmContext/MessageAddContext';

const Message = ({ message }) => {
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { messageStatus, messages } = MessageState;

  const { MessageAddDispatch } = useContext(MessageAddStateContext);

  const {
    id,
    type,
    status,
    title,
    content, // sms
    contents, // menuTalk
    schedule_time,
    filter_tag,
    estimated_msg_points,
    consumed_msg_points,
    estimated_sending_counts,
    sending_counts
  } = message;

  const messageEditList = {
    draft: [
      { title: '繼續編輯', action: 'edit' },
      { title: '刪除', action: 'delete' }
    ],
    scheduling: [
      { title: '修改排程時間', action: 'editTime' },
      { title: '取消排程', action: 'cancel' }
    ]
  };

  useEffect(() => {
    const messageStyle = document.getElementsByClassName('message_list');

    if(messageStatus === 'sent' || messageStatus === 'scheduling') {
      for (let i = 0; i < messageStyle.length; i++) {
        messageStyle[i].style.cursor = 'pointer';
      }
    } else {
      for (let i = 0; i < messageStyle.length; i++) {
        messageStyle[i].style.cursor = 'default';
      }
    }
  }, [ messageStatus ]);

  const previewMessage = () => {
    if(messageStatus !== 'draft') {
      const previewIndex = messages.map((list) => list.id).indexOf(id);
      MessageStateDispatch({ type: 'setPreviewMessage', previewInfo: messages[previewIndex] });
    }
  };

  const handleEditor = (item) => {
    if (item === 'edit') {
      if(type === 'sms') {
        MessageStateDispatch({ type: 'switchMode', mode: 'addMessage', editType: 'sms'});
      } else {
        MessageStateDispatch({ type: 'switchMode', mode: 'addMessage', editType: 'menuTalk'});
      }

			const findMsgIndex = messages.map((list) => list.id).indexOf(id);
			const msgSettingState = _.cloneDeep(messages[findMsgIndex]);

			MessageAddDispatch({ type: 'setEditorSetting', msgSettingState });
		};

    if (item === 'delete') {
			MessageStateDispatch({
        type: 'setDeleteMessage',
        deleteType: item,
        messageType: type,
        id
      });
		};

    if (item === 'editTime') {
      const findMsgIndex = messages.map((list) => list.id).indexOf(id);
			const msgSettingState = _.cloneDeep(messages[findMsgIndex]);

			MessageAddDispatch({ type: 'setEditorSetting', msgSettingState });
			MessageStateDispatch({
        type: 'setEditDateTime',
        messageType: type,
        id
      });
		};

    if (item === 'cancel') {
      MessageStateDispatch({
        type: 'setDeleteMessage',
        deleteType: item,
        messageType: type,
        id
      });
		};
  };

  const renderEditList = () => {
		return messageEditList[messageStatus].map((item) => {
			return (
				<li key={item.action} onClick={() => handleEditor(item.action)}>
					{item.title}
				</li>
			);
		});
	};

  const renderTime = () => {
    let scheduleTime = '';

    if(!schedule_time) {
      scheduleTime = '';
    } else {
      scheduleTime = moment(new Date(schedule_time)).format('YYYY年MM月DD日 HH:mm:ss');
    }

    if(messageStatus !== 'draft') {
      return <div className="time">{ scheduleTime }</div>
    }
  };

  const renderPoints = () => {
    if(type === 'sms') {
      if(status === 'scheduled' || status === 'real_time') {
        return (
          <Fragment>
            <div>{ estimated_sending_counts } 則</div>
            <div>{ estimated_msg_points } 點</div>
          </Fragment>
        )
      } else if(status === 'finished') {
        return (
          <Fragment>
            <div>{ sending_counts } 則</div>
            <div>{ consumed_msg_points } 點</div>
          </Fragment>
        )
      }
    } else {
      if(status !== 'draft') {
        return (
          <Fragment>
            <div>{ sending_counts } 則</div>
          </Fragment>
        )
      }
    }
  };

  const renderContent = () => {
    if(type === 'sms') {
      return (
        <div className="content">
          { content }
        </div>
      )
    } else {
      const menuTalkContent = JSON.parse(contents);
      let menuTalkText = '';
      let coupon_counts = 0;
      let img_counts = 0;


      for(let i = 0; i < menuTalkContent.length; i++) {
        if(menuTalkContent[i].type === 'text') {
          menuTalkText = menuTalkContent[i].text;
          break;
        }
      }

      for(let i = 0; i < menuTalkContent.length; i++) {
        if(menuTalkContent[i].type === 'image') {
          img_counts++;
        } else if(menuTalkContent[i].type === 'voucher') {
          coupon_counts++;
        }
      }

      return (
        <div>
          { menuTalkText !== '' ? 
            <div className="content">
              { menuTalkText }
            </div> : null
          }
          <div className='menuTalkContent_tags'>
            { coupon_counts !== 0 ? 
              <div className='tag'>
                <img src={require('../../../images/crm/coupon_icon.svg')} alt='' />
                優惠券 { coupon_counts > 1 ? coupon_counts : '' }
              </div> : null
            }
            { img_counts !== 0 ? 
              <div className='tag'>
                <img src={require('../../../images/crm/edit_img_icon.svg')} alt='' />
                圖片 { img_counts > 1 ? img_counts : '' }
              </div> : null
            }
          </div>
        </div>
      )
    }
  };

  return (
    <div className="message_list" onClick={() => previewMessage()}>
      <div className="header">
        <div className="title">{ title }</div>
        <div className="block_right">
          { renderTime() }
          { messageStatus !== 'sent' ?
            <div className="listCardDropDown" onClick={(e) => e.stopPropagation()}>
              <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <MoreOutlined />
              </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <ul>{renderEditList()}</ul>
                </div>
            </div> : null
          }
        </div>
      </div>
      <div className="tags">
        <div className="menu">{ type === 'sms' ? '簡訊' : 'MENU 私訊' }</div>
        { filter_tag && <div>{ filter_tag }</div> }
        { renderPoints() }
      </div>
      { renderContent() }
    </div>
  )
}

export default Message;