import React from 'react'
import $ from 'jquery'
import { Link } from 'react-router'

var createReactClass = require('create-react-class');
export default createReactClass({
  getInitialState() {
    return {name: '', password: '', token: '', shop_name: ''};
  },
  componentDidMount() {
    var shop_name = window.location.search.split('=').pop();
    this.setState({shop_name: shop_name});
  },
  handleNameChange(e) {
    this.setState({name: e.target.value});
  },
  handlePasswordChange(e) {
    this.setState({password: e.target.value});
  },
  handleSubmit(e) {
    e.preventDefault();
    var name = this.state.name.trim();
    var password = this.state.password.trim();
    // var token = $('meta[name=csrf-token]').attr('content');
    if (!name || !password) {
      return;
    }
    this.props.onUserLoginSubmit(
      name,password,this.state.shop_name
    );
  },
  render() {
    return (
      <form onSubmit={this.handleSubmit} className="form-signin-user">
        <h3 style={{ paddingLeft: '10px' }}>輸入帳號密碼</h3>
        <label htmlFor="shopaccount" className="sr-only">請輸入你的員工帳號</label>
        <div className="input-group">
          <span className="input-group-addon" id="sizing-addon2"><i className="fa fa-user" /></span>
          <input 
            type="account" 
            className="form-control" 
            placeholder="帳號" 
            aria-describedby="sizing-addon2"
            value={this.state.name}
            onChange={this.handleNameChange} 
            autoFocus
          />
        </div>
        <h3 />
        <div className="input-group">
          <span className="input-group-addon" id="sizing-addon2"><i className="fa fa-unlock-alt" /></span>
          <input 
            type="password" 
            className="form-control" 
            placeholder="密碼" 
            aria-describedby="sizing-addon2"
            value={this.state.password}
            onChange={this.handlePasswordChange} />
        </div>
        <button
          className="btn-menushop btn-color-green btn-signin"
          type="submit"
        >
          進入商家平台
        </button>
        <div className="help userframe_font">
          <ul>
            <li>
              <a role="button" onClick={this.props.toggleResetAccountModal}>
                <i className="fa fa-question-circle fa-fw" />
                忘記管理者帳號或密碼
              </a>
            </li>
            <li>
              <a className='tip'>
                <i className="fa fa-question-circle fa-fw" />
                若您非帳號管理者，如有任何帳號與密碼問題請與您的管理者聯繫
              </a>
            </li>
            <li className='return'>
              <Link to="/login">
                <img src={require('../../images/arrow_return.svg')} alt="" />
                重新輸入商家帳號
              </Link>
            </li>
          </ul>
        </div>
      </form>
    );
  }
});
