
import React from 'react';


export function getModalPause(action) {
    return (
        <div className="modal fade" id="closeModal-pause" tabIndex="-1" 
            role="dialog" 
            aria-labelledby="closeModalLabel" 
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content queue-modal-content">
                    <h5 className="queue-modal-title" id="closeModalLabel">暫停線上排隊</h5>
                    <div className="queue-modal-body">
                        您是否確認暫停線上排隊？<br/>
                        暫停線上排隊後，顧客將無法線上預約。
                    </div>
                    <div className="queue-modal-footer">
                        <button type="button" className="queue-btn-primary" data-dismiss="modal" onClick={action}>確認</button>
                        <button type="button" className="queue-btn-normal" data-dismiss="modal">取消</button>
                    </div>
                </div>
            </div>
        </div>)
} 

export function getModalStart(action) {
    return (
        <div className="modal fade" id="startQueueModal" tabIndex="-1" 
            role="dialog" 
            aria-labelledby="closeModalLabel" 
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content queue-modal-content">
                    <h5 className="queue-modal-title" id="closeModalLabel">開始使用排隊系統</h5>
                    <div className="queue-modal-body">
                        是否開始使用排隊系統？
                    </div>
                    <div className="queue-modal-footer">
                        <button type="button" className="queue-btn-primary" data-dismiss="modal" onClick={action}>確認</button>
                        <button type="button" className="queue-btn-normal" data-dismiss="modal">取消</button>
                    </div>
                </div>
            </div>
        </div>)
} 

export function getModalClose(action) {
    return (
        <div className="modal fade" id="closeModal" tabIndex="-1" 
            role="dialog" 
            aria-labelledby="closeModalLabel" 
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content queue-modal-content">
                    <h5 className="queue-modal-title" id="closeModalLabel">結束本時段排段</h5>
                    <div className="queue-modal-body">
                        您是否確認結束本時段排隊？<br/>
                        結束排隊後，所有等候中的排隊皆會失效。
                    </div>
                    <div className="queue-modal-footer">
                        <button type="button" className="queue-btn-primary" data-dismiss="modal" onClick={action}>確認</button>
                        <button type="button" className="queue-btn-normal" data-dismiss="modal">取消</button>
                    </div>
                </div>
            </div>
        </div>)
} 

