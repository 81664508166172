import React from 'react';
import { Modal, Button, ConfigProvider } from 'antd';

import './menuUserDelete.scss';

export default ({ checkDelete, handleCheckDelete, deleteMenuUser}) => {
	return (
		<Modal
			destroyOnClose
			centered
			visible={checkDelete}
			wrapClassName="menuAccountDeleteModal"
      title="確定解除綁定嗎？"
			footer={[
        <ConfigProvider key="menuAccountDelete" autoInsertSpaceInButton={false}>
          <Button className="btn_cancel" onClick={handleCheckDelete}>
            取消
          </Button>
          <Button className="btn_save" onClick={deleteMenuUser}>
            解除綁定
          </Button>
        </ConfigProvider>
      ]}
      transitionName="ant-move-down"
		>
			<div className="menuAccountDeleteModalBody">
        解除綁定將無法發送優惠訊息給您的
        <br />
        MENU粉絲，確定解除綁定嗎？
      </div>
		</Modal>
	);
};
