import React, { Component } from 'react';
import { Switch } from 'antd';

export default class QueueOptionSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			switchStatus: true,
			currentState: 'normal',
			isAvailable: props.optionParam.status === 'start'
		};
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.state === 'editing' &&
			(this.state.currentState !== 'editing' &&
				this.state.currentState !== undefined)
		) {
			this.setState({
				currentState: this.props.state,
				isAvailable: this.props.optionParam.status === 'start'
			});
		} else if (prevProps.state !== this.props.state) {
			this.setState({
				currentState: this.props.state
			});
		}
	}

	switchOnChange = active => {
		if (this.props.optionParam['auto_pause'] === false) {
			if (active) {
				this.props.updateSelectStatus(
					this.props.optionParam.id,
					'start'
				);
			} else {
				this.props.updateSelectStatus(
					this.props.optionParam.id,
					'pause'
				);
			}

			this.setState({
				isAvailable: active
			});
		} else {
			if (active === true) {
				alert('已達上限組數，系統已經自動暫停排隊');
			}
			this.setState({
				isAvailable: false
			});
		}
	};

	render() {
		let selectContent;

		switch (this.state.currentState) {
			case 'normal':
				selectContent = (
					<div
						className='queue-option-select'
						onClick={this.props.selectAction}>
						<div className='queue-option-select-title'>
							<h3>{this.props.optionParam.title}</h3>
							<p>
								等候組數{' '}
								{this.props.optionParam.current_waiting_groups}{' '}
							</p>
						</div>
						<div className='queue-option-select-number'>
							<p>{this.props.optionParam.next_number}</p>
						</div>
					</div>
				);
				break;
			case 'pause':
				selectContent = (
					<div
						className='queue-option-select'
						onClick={this.props.selectAction}>
						<div className='queue-option-select-title pauseTitle'>
							<h3>{this.props.optionParam.title}</h3>
							<p>
								等候組數{' '}
								{this.props.optionParam.current_waiting_groups}{' '}
							</p>
						</div>
						<img
							className='pause-icon'
							src={require('../../images/queue/pause_button.png')}
							alt=''
						/>
						{/* <p>暫停中</p> */}
					</div>
				);
				break;
			case 'autopause':
				selectContent = (
					<div
						className='queue-option-select'
						onClick={this.props.selectAction}>
						<div className='queue-option-select-title pauseTitle'>
							<h3>{this.props.optionParam.title}</h3>
							<p>
								（已滿）等候組數{' '}
								{this.props.optionParam.current_waiting_groups}{' '}
							</p>
						</div>
						<img
							className='pause-icon'
							src={require('../../images/queue/pause_button.png')}
							alt=''
						/>
						{/* <p>暫停中</p> */}
					</div>
				);
				break;
			case 'unavailable':
				selectContent = (
					<div className='queue-option-select'>
						<div className='queue-option-select-title unavailableTitle'>
							<h3>{this.props.optionParam.title}</h3>
							<p>
								等候組數{' '}
								{this.props.optionParam.current_waiting_groups}{' '}
							</p>
						</div>
						<div className='queue-option-select-number unavailable'>
							<p>未開放</p>
						</div>
					</div>
				);
				break;
			case 'editing':
				selectContent = (
					<div className='queue-option-select'>
						<div className='queue-option-select-title editTitle'>
							<h3>{this.props.optionParam.title}</h3>
							<p>
								等候組數{' '}
								{this.props.optionParam.current_waiting_groups}{' '}
							</p>
						</div>
						<div className='queue-option-select-number'>
							<div className='queue-option-switch-container'>
								<Switch
									className='queue-option-switch'
									style={{ width: 30 }}
									checked={this.state.isAvailable}
									onChange={this.switchOnChange}
								/>
							</div>
							<p>{this.props.optionParam.next_number}</p>
						</div>
					</div>
				);
				break;
			default:
				selectContent = null;
		}
		return selectContent;
	}
}
