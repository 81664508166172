import React, { useState, Fragment, useRef } from 'react';
import { Modal, Form, Input, Button, Spin, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import BookingLists from './BookingLists';
import QueueLists from './QueueLists';

// import useGetQueueSetting from './hooks/useGetQueueSettings';
import useBookingSubmit from './hooks/useBookingSubmit';
import useNewModuleSubmit from './hooks/useNewModuleSubmit';

import '../../sass/booking-search.scss';

const { Item } = Form;
const { TabPane } = Tabs;
export default ({ searchType = 'booking', iconSize = 20 }) => {
	const [ form ] = Form.useForm();
	const { validateFields } = form;

	const inputRef = useRef();
	const [ visible, setVisible ] = useState(false);
	const [ init, setInit ] = useState(false);
	const { BookingLoading, bookingSearchRes, setBookingSearchRes, BookingSubmit } = useBookingSubmit(setInit);
	const { newModuleLoading, newModuleSearchRes, setNewModuleSearchRes, newModuleSubmit } = useNewModuleSubmit(
		setInit
	);

	const tabsProps =
		searchType === 'booking'
			? { renderTabBar: () => <div />, defaultActiveKey: 'booking' }
			: {
					defaultActiveKey:
						newModuleSearchRes['queue_records'] && newModuleSearchRes['queue_records']['today'].length !== 0
							? 'queue'
							: 'booking',
					tabBarStyle: {
						backgroundColor: 'rgba(118, 118, 128, 0.12)',
						marginTop: 10,
						marginBottom: 0,
						padding: 2,
						borderRadius: 6
					}
				};

	const handleSubmit = () => {
		validateFields().then(({ searchInput }) => {
			inputRef.current.blur();
			if (searchType === 'booking') BookingSubmit(searchInput);
			if (searchType === 'newModule') newModuleSubmit(searchInput);
		});
	};

	const reset = () => {
		setInit(false);
		setVisible(false);
		setBookingSearchRes({
			today: [],
			future: [],
			past: []
		});
		setNewModuleSearchRes({});
	};

	return (
		<Fragment>
			<button className="bookingSearchBtn" onClick={() => setVisible(true)}>
				<span>顧客搜尋</span>
				<SearchOutlined style={{ fontSize: iconSize }} />
			</button>

			{visible && (
				<Modal
					destroyOnClose
					visible={visible}
					wrapClassName="bookingSearchModal"
					footer={null}
					onCancel={reset}
					style={{ top: 50 }}
				>
					<Form form={form} className="bookingSearchInput" onFinish={handleSubmit}>
						<Item>
							<Item
								noStyle
								name={[ 'searchInput' ]}
								initialValue={''}
								validateTrigger="onSubmit"
								rules={[ { required: true, message: '輸入有效顧客姓名、電話、電子信箱或預約代碼' } ]}
							>
								<Input
									ref={inputRef}
									className="searchInput"
									placeholder="搜尋顧客姓名、電話、電子信箱或預約代碼"
									allowClear
									onPressEnter={handleSubmit}
								/>
							</Item>
							<Button className="searchIcon" htmlType="submit">
								<SearchOutlined style={{ fontSize: 20 }} />
							</Button>
						</Item>

						{init && (
							<Fragment>
								{BookingLoading || newModuleLoading ? (
									<Spin />
								) : (
									<Tabs {...tabsProps}>
										<TabPane tab="預約顧客" key="booking">
											<div className="searchRes">
												<BookingLists
													searchType={searchType}
													bookingSearchRes={
														searchType === 'booking' ? (
															bookingSearchRes
														) : (
															newModuleSearchRes['bookings']
														)
													}
													reset={reset}
												/>
											</div>
										</TabPane>
										{searchType === 'newModule' && (
											<TabPane tab="排隊顧客" key="queue">
												<div className="searchRes">
													<QueueLists
														searchType={searchType}
														bookingSearchRes={newModuleSearchRes['queue_records']['today']}
														reset={reset}
													/>
												</div>
											</TabPane>
										)}
									</Tabs>
								)}
							</Fragment>
						)}
					</Form>
				</Modal>
			)}
		</Fragment>
	);
};
