import React, { useState, useEffect, useReducer } from 'react';
import { userBindState, UserBindContext, UserBindReducer } from './MenuUserBindContext/MenuUser_bindContext';

import Bar from './MenuUserBindCommon/MenuUserBind_bar';
import Footer from './MenuUserBindCommon/MenuUserBind_footer';
import UserVerification from './MenuUserBindCommon/MenuUserVerificationModal';

import { getMenuUserInfo, searchMenuUser } from '../menu_user/MenuUserApi'

const MenuAccountBox = () => {
	const [ menuUser, setMenuUser ] = useState('');
	const [ bindError, setBindError ] = useState(false);
	const [ bindDisabled, setBindDisabled ] = useState(false);

	const [ UserBindState, UserBindStateDispatch ] = useReducer(UserBindReducer, userBindState);
	const { step } = UserBindState;

	const itunes_link = 'https://itunes.apple.com/tw/app/menu%E7%BE%8E%E9%A3%9F%E8%AA%8C-%E7%BE%8E%E9%A3%9F%E8%A8%98%E9%8C%84-%E4%BD%A0%E6%88%91%E5%88%86%E4%BA%AB/id976520817?mt=8';
	const googlestore_link = 'https://play.google.com/store/apps/details?id=com.findlife.menu&hl=zh_TW';

	useEffect(() => {
			window.scrollTo(0, 0);
		},[]);

	useEffect(() => {
		getBindStatus();
	}, [])

	useEffect(() => {
		const search_input_block = document.getElementsByClassName('search_input')[0];

		if(bindError) {
			search_input_block.classList.add('search_input_error');
		} else {
			search_input_block.classList.remove('search_input_error');
		}
	},[ bindError ]);

	// 點擊 search input 會有 focus style，點空白處取消
	useEffect(() => {
		const inputFocus = (e) => {
			const search_input = document.getElementById('search_input');
			const search_input_block = document.getElementsByClassName('search_input')[0];
		
			if (e.target === search_input) {
				search_input_block.classList.add('search_input_focus');
			} else if (search_input_block) {
				search_input_block.classList.remove('search_input_focus');
			}
		}

		if(!bindDisabled) {
			document.addEventListener('click', inputFocus);
		}
		return () => {
			document.removeEventListener('click', inputFocus);
		}
	})

	const getBindStatus = async () => {
		try {
			const { data } = await getMenuUserInfo();

			if(data.menu_user.bind_menu) {
				setBindDisabled(true);
			} else {
				setBindDisabled(false);
			}
		} catch(err) {
			setBindDisabled(false);
			console.log(err)
		}
	};

	const goDownload_iOS = () => {
		window.open(itunes_link, '_blank');
	};

	const goDownload_Android = () => {
		window.open(googlestore_link, '_blank');
	};

	const goHaveMenuAccount = () => {
    const downloadMenuApp = document.getElementById('have_account');
    downloadMenuApp.scrollIntoView({ behavior: "smooth" });
  };

	const handleMenuAccount = (e) => {
		const menuUser = e.target.value;
		setMenuUser(menuUser);
	};

	const submit = async () => {
		try {
			const { data } = await searchMenuUser(menuUser);

			setBindError('');
			UserBindStateDispatch({ 
				type: 'setInitUserInfo',
				menuUserId: menuUser,
				displayName: data.menu_user.name,
				thumbnailURL: data.menu_user.thumbnailURL
			});
			UserBindStateDispatch({ type: 'goUserVerification', step: 'userVerification' });
		} catch(err) {
			setBindError(true);
		}
	};

	return (
		<UserBindContext.Provider value={{ UserBindState, UserBindStateDispatch }}>
			<div id="menu_account">
				<Bar />
				<div id="instant_bind">
					<img className="logo" src={require('../../../images/menuUser/Web_MENUXMENULogo.svg')} alt="" />
					<div className="title">
						<div>立即綁定</div>
						<div>與 86萬名用戶分享你的商家吧！</div>
					</div>
					<div className="search_account">
						<div>輸入您的 MENU ID 完成綁定：</div>
						<div className="search_input">
							<i className="fa fa-search searchIcon" aria-hidden="true"></i>
							<input
								id="search_input"
								type="text"
								placeholder="輸入您的使用者帳號"
								value={menuUser}
								onChange={handleMenuAccount}
								autoComplete="off"
								disabled={bindDisabled}
							/>
							<button onClick={submit} disabled={bindDisabled}>確認</button>
						</div>

						{ bindError && <div className="search_input_error_text">抱歉，未找到此帳號，請再嘗試一次。</div> }

					</div>
					<div className="find_account" onClick={goHaveMenuAccount}>如何找到我的 MENU ID ？</div>
				</div>

				<div id="howTo_bind">
					<div className="howTo_bind_title">如何綁定 MENU 美食誌？</div>
					<div className="without_account">
						<div id="without_account_download" className="without_account_download">
							<div>若您還沒有帳號：</div>
							<div>掃描 QRCode 下載 <strong>MENU 美食誌</strong> 並完成註冊。</div>
							<div>下載 <strong>MENU 美食誌</strong> 並完成註冊。</div>
							<div>
								<button onClick={goDownload_iOS}>下載 iOS 版</button>
								<button onClick={goDownload_Android}>下載 Android 版</button>
							</div>
						</div>
						<div className="menuApp_QRCode">
							<img src={require('../../../images/menuUser/Web_Connect_DownloadAppQRCode_WithFrame.svg')} alt="" />
						</div>
					</div>
					<div id="have_account" className="have_account">
						<div className="have_account_step">
							<div>若您已有美食誌帳號：</div>
							<div>在 MENU 美食誌 App 找到你的 MENU ID 。</div>
							<ul>
								<li>登入您的 <strong>MENU 美食誌帳號</strong>。</li>
								<li>
									點擊首頁右下角個人頁面按鈕
									<img src={require('../../../images/menuUser/Icons_Connect_Info_ProfilePage_Pics.svg')} alt="" />
									。
								</li>
								<li>
									進入右上角的設定按鈕
									<img src={require('../../../images/menuUser/Icons_Connect_Info_Setting_Pics.svg')} alt="" />
									，點擊「個人資訊」，您可以在 MENU ID 欄位找到由 10 碼英數文字組成的 MENU ID 。
								</li>
								<li>回到此網頁輸入您的 MENU ID 。</li>
							</ul>
						</div>
						<div className="have_account_img">
							<img src={require('../../../images/menuUser/Web_Connect_ConnectAccountInfoPic.svg')} alt="" />
							<img src={require('../../../images/menuUser/Mobile_Connect_ConnectAccountInfoPic.svg')} alt="" />
						</div>
					</div>
				</div>

				<div id="download_menuApp">
					<div>
						<img src={require('../../../images/menuCoupon/menuAppIcon.png')} alt="" />
						<img src={require('../../../images/menuUser/logo_noIcon_black.svg')} alt="" />
					</div>
					<div>
						<button onClick={goDownload_iOS}>下載 iOS 版</button>
						<button onClick={goDownload_Android}>下載 Android 版</button>
					</div>
				</div>
				<Footer />

				{ step === 'userVerification' && <UserVerification/> }
			</div>
		</UserBindContext.Provider>
	);
};

export default MenuAccountBox;

