import React from "react";
import { connect } from "react-redux";
import $ from 'jquery'
import { Link, browserHistory } from 'react-router'
import PageUserLoginForm from './PageUserLoginForm'
import FlashMessage from '../commons/FlashMessage'
import { config } from '../../utils/config'
import { login } from "../../actions/auth";

import ResetAccountModal from '../popup/resetAccount/resetAccountModal';
import ConfirmPopup from '../popup/confirmPopup';

const confirmPopupText = {
	title: '忘記管理者帳號或密碼',
	content: '已將您的管理者帳號與密碼寄送至您的信箱，若有任何疑問請洽詢MENU店+業務。'
};

var createReactClass = require('create-react-class');
const PageUserLoginBox = createReactClass({
  
  // componentWillMount() {
  //   console.log("this.props---", this.props)
  //   const user = this.props.user;
  //   if (user) {
  //     browserHistory.push(`/dashboard`)
  //   }
  // },
  getInitialState() {
    return {
			email: '',
			remindError : {
				wrong: false,
				empty: false,
			},
			showResetAccountModal: false,
			showConfirmPopup: false
    };
  },
  toggleShowConfirmPopup() {
		this.setState({
			showConfirmPopup: !this.state.showConfirmPopup
		})
	},
	toggleResetAccountModal() {
    this.setState({
      showResetAccountModal: !this.state.showResetAccountModal,
      email: '',
			remindError : {
				wrong: false,
				empty: false,
			},
    });
  },
  handleEmail(e) {
    this.setState({
			email: e.target.value,
			remindError: {
				wrong: false,
				empty: false
			}
		});
  },
  handleUserLoginSubmit(user_id, password, shop_name) {
    let { dispatch } = this.props;
    dispatch(login(user_id, password, shop_name));
    // $.ajax({
    //   url: config.domain + this.props.url,
    //   dataType: 'json',
    //   type: 'POST',
    //   data: d,
    //   xhrFields: {withCredentials: true },
    //   success: function(data) {
    //     browserHistory.push(data.redirect_url);
    //   },
    //   error: function(xhr) {
    //     this.refs.alert.setMessage(xhr.responseJSON.error, 'error');
    //   }.bind(this)
    // });
  },
  handleRemindSubmit() {
    if(this.state.email.length === 0) {
			return this.setState({
				remindError: {
					wrong: false,
					empty: true,
				}
			});
		}

    $.ajax({
      url: config.domain + '/reset_owner_password',
      dataType: 'json',
      type: 'POST',
      data: {
        data : {
          shop: this.props.location.query.shop_name,
          email: this.state.email
        }
      },
      success: function(data) {
        this.setState({
					showResetAccountModal: false,
					showConfirmPopup: true
				});
      }.bind(this),
      error: function(xhr) {
				this.setState({
					remindError: {
						wrong: true,
						empty: false,
					},
				});
      }.bind(this)
    });
  },
  _getShopDisplayName() {
    return window.app.shop_display_name
  },
  getDefaultProps() {
    return {
      url: '/auth/sign_in.json'
    };
  },
  getInitialState() {
    return {data: []};
  },
  componentDidUpdate(){
    if(this.props.user){
      browserHistory.push('/dashboard');
    }
  },
  componentDidMount() {
    const user = this.props.user;
    if (user) {
      browserHistory.push(`/dashboard`)
    }
    
    $(function () {
      $('[data-toggle="popover"]').popover()
    });
  },
  render() {
    let user = this.props.user;
    let name = (user != null) ? user.name : '';
    return (
      <div className="intro">
        <div className="intro-body">
          <div className="container">
            <FlashMessage ref='alert' />
            <h2>歡迎登入{this._getShopDisplayName()}{name}</h2>
            <PageUserLoginForm
              toggleResetAccountModal={this.toggleResetAccountModal}
              onUserLoginSubmit={this.handleUserLoginSubmit}
            />
          </div>
        </div>

        {this.state.showResetAccountModal &&
					<ResetAccountModal
						type={'userAccount'}
						remindError={this.state.remindError}
						handleEmail={this.handleEmail}
						submit={this.handleRemindSubmit}
						cancel={this.toggleResetAccountModal}
					/>
				}
				{
					this.state.showConfirmPopup &&
					<ConfirmPopup
						title={confirmPopupText.title}
						content={confirmPopupText.content}
						submit={this.toggleShowConfirmPopup}
					/>
				}
      </div>
    );
  }
});


function mapStateToProps(state) {
  const { auth } = state;
  if (auth) {
    return { user: auth.user, loginError: auth.loginError };
  }

  return { user: null };
}

export default connect(mapStateToProps)(PageUserLoginBox);