import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  handleHeaderTab
} from '../../../actions/announcementAction';

const announcementStatusTab = {
	shop: '公告',
	official: '找活公告'
};

const AnnouncementTab = () => {
  const auth = useSelector((state) => state.auth);
  const { shop } = auth;

  const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
    currentTab
	} = announcementListReducer;
  const dispatch = useDispatch();


  return (
    <div className='announcementHeaderTab'>
      <div className="announcement_type">
        {Object.keys(announcementStatusTab).map((option, index) => {
					return (
            <div
              key={option}
              className={option === currentTab ? 'active' : ''}
              onClick={() => dispatch(handleHeaderTab(option))}
            >
              { option === 'shop' ? `${shop.display_name} 公告` : announcementStatusTab[option] }
            </div>
          );
				})}
      </div>
    </div>
  )
};

export default AnnouncementTab;