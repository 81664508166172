import React, { useContext } from 'react';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import '../../../sass/menu_coupon_popup.scss';
import MenuCouponListCard from '../ListCard';


const SharePopup = () => {
	const { CouponState } = useContext(CouponStateContext);
	const { listInfo } = CouponState;

	const copy = () => {
		const el = document.getElementById('copyInput');
		const range = document.createRange();
		window.app.alert.setMessage('已複製成功', 'info');
		range.selectNodeContents(el);
		window.getSelection().addRange(range);

		const selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
		el.select();
		el.setSelectionRange(0, 999999);
		document.execCommand('copy');

		window.getSelection().removeAllRanges();
	};

	return (
		<div className="sharePopupBody">
			<p>
				您已成功發布<span>「{listInfo.title}」</span>優惠券。分享優惠券讓更多人知道優惠訊息吧！
			</p>
			<MenuCouponListCard>
				<MenuCouponListCard.Img thumbnailURL={listInfo.thumbnailURL} />
				<MenuCouponListCard.Content showType="sharePopup" list={listInfo} />
			</MenuCouponListCard>

			<div className="copyUrl">
				<div>
					<input id="copyInput" readOnly type="text" value={`https://menutaiwan.com/tw/coupon/${listInfo.id}`} />
				</div>
				<button onClick={() => copy()}>複製連結</button>
			</div>
		</div>
	);
};

export default SharePopup;
