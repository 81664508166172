import React, { useState } from 'react';
import moment from 'moment';
import { useInterval } from '../../../new_module/utils/data';

const scale = 90,
    cellHeight = 76;

const BookingTableCurrentTime = () => {
	const [ currentTime, setCurrentTime ] = useState(moment().format('HH:mm'));

	useInterval(() => {
		setCurrentTime(moment().format('HH:mm'));
	}, 1000);

	const currentTimeLeft = moment.duration(moment().format('HH:mm')).asHours() * 2 * scale + scale;
	return (
		<label className="currentTime" style={{ left: currentTimeLeft }}>
			{currentTime}
		</label>
	);
};

const BookingTableCurrentTimeWrap = ({tables, filter}) => {
    const [ currentTimeStamp, setCurrentTimeStamp ] = useState(moment());
    const newTables = tables.filter((t) => filter[t.group] !== false);

	useInterval(() => {
		setCurrentTimeStamp(moment());
	}, 1000);

    const currentTimeLeft = moment.duration(currentTimeStamp.format('HH:mm')).asHours() * 2 * scale + scale;
	const currentTimeLineHeight = newTables.length * cellHeight;
    
	return (
		<div
			className="currentTimeLine"
			style={{
				width: currentTimeLeft,
				height: currentTimeLineHeight,
				left: currentTimeLeft
			}}
		/>
	);
};

export { BookingTableCurrentTime, BookingTableCurrentTimeWrap };
