import React, { useCallback } from 'react';
import moment from 'moment';

import MonthTableRowCell from './MonthTableRowCell';

const Month = ({
	year,
	month,
	monthBookingEffectiveCount,
	monthBookingAttendance,
	monthBookingUnconfirmedCount,
	holiday,
	changeUrlDate
}) => {
	const today = moment(new Date()).format('YYYY/MM/DD');

	// console.log('monthBookingUnconfirmedCount---', monthBookingUnconfirmedCount);
	const createRows = useCallback(
		() => {
			let createTable = [];
			const firstday = new Date(year, month - 1, 1), //查詢月份的第一天
				dayOfWeek = firstday.getDay(), //第一天星期幾 0:Sun 1:Mon 2:Tues
				days_per_month = new Date(year, month, 0).getDate(); //當月天數
			const days_per_lastMonth = new Date(year, month - 1, 0).getDate(); //上個月天數
			const str_nums = Math.ceil((dayOfWeek + days_per_month) / 7); //日曆需要幾行
			let vacantStyle = 'vacant';

			for (let i = 0; i < str_nums; i++) {
				let tableTr = [];
				for (let j = 0; j < 7; j++) {
					const dayIndex = 7 * i + j; //建立索引, 從0開始
					let date = dayIndex - dayOfWeek + 1;
					let dateKey = dayIndex - dayOfWeek + 1;
					let holidayMonth = month;
					let date_month = month;
					let cellYear = year;

					if (date <= 0) {
						// 前一個月
						vacantStyle = 'monthTableRow__cell-vacant';
						date = date + days_per_lastMonth;
						dateKey = dateKey + days_per_lastMonth;
						holidayMonth = month - 1 === 0 ? 12 : month - 1;
						date_month = month - 1 === 0 ? 12 : month - 1;
						cellYear = month - 1 === 0 ? year - 1 : year;
					} else if (date > days_per_month) {
						// 下一個月
						vacantStyle = 'monthTableRow__cell-vacant';
						date = date - days_per_month;
						dateKey = dateKey - days_per_month;
						holidayMonth = month + 1 > 12 ? 1 : month + 1;
						date_month = month + 1 > 12 ? 1 : month + 1;
						cellYear = month + 1 > 12 ? year + 1 : year;
					} else {
						// 當月
						date = dayIndex - dayOfWeek + 1;
						vacantStyle = '';
					}

					if (dateKey < 10 && dateKey > 0) {
						dateKey = '0' + dateKey;
					}

					if (date_month < 10 && date_month > 0 && date_month.toString().length < 2) {
						date_month = '0' + date_month;
					}

					const cell = String(cellYear) + String(date_month) + String(dateKey);
					const isToday = `${cellYear}/${date_month}/${dateKey}` === today ? 'monthTableRow__cell-active' : '';
					const bookingCount = monthBookingEffectiveCount[cell];
					const bookingAttendanceCount = monthBookingAttendance[cell];
					const unconfirmedCount = monthBookingUnconfirmedCount[cell] ? true : false;
					const isHolidayStyle = holiday[`${cellYear}/${holidayMonth}/${date}`];

					// console.log("cell---", cell)

					tableTr.push(
						<MonthTableRowCell
							key={`${cellYear}-${date_month}-${dateKey}`}
							queryDate={`${cellYear}-${date_month}-${dateKey}`}
							date={date}
							vacantStyle={vacantStyle}
							bookingCount={bookingCount}
							bookingAttendanceCount={bookingAttendanceCount}
							isToday={isToday}
							unconfirmedCount={unconfirmedCount}
							isHolidayStyle={isHolidayStyle}
							changeUrlDate={changeUrlDate}
						/>
					);
				}

				createTable.push(
					<div key={i} className="monthTableRow">
						{tableTr}
					</div>
				);
			}

			return createTable;
		},
		[
			year,
			month,
			today,
			monthBookingEffectiveCount,
			monthBookingAttendance,
			monthBookingUnconfirmedCount,
			holiday,
			changeUrlDate
		]
	);

	return (
		<div className="monthTable">
			<div className="monthTableRow monthTableTitleRow">
				<div className="monthTableRow__cell">日</div>
				<div className="monthTableRow__cell">一</div>
				<div className="monthTableRow__cell">二</div>
				<div className="monthTableRow__cell">三</div>
				<div className="monthTableRow__cell">四</div>
				<div className="monthTableRow__cell">五</div>
				<div className="monthTableRow__cell">六</div>
			</div>

			{createRows()}
		</div>
	);
};

export default Month;
