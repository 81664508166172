import {  createContext } from 'react';
import _ from 'lodash';


export const baseSetting = {
	autoTable: false,
	diningTime: 0,
	showCanvas: false,
	timeChecking: '',
	smsEnable: false,
	customQ: [],
	customQAns: {},
	tempCustomQAns: {},
	canvasWidth: 1024,
	canvasHeight: 700
};

export const BaseSettingContext = createContext(baseSetting);

export function BaseSettingReducer(state, action) {
	const newState = _.cloneDeep(state);
	switch (action.type) {
		case 'setBaseSetting':
			return setBaseSetting(newState, action);
		default:
			return state;
	}
}

function setBaseSetting(state, action) {
	const { booking_setting, queue_setting, customized_questions } = action;
	const { auto_table_distribution, dining_time, canvas_enabled } = booking_setting;
	const { time_checking, sms_enable } = queue_setting;
	let customQAns = {};

	customized_questions.map((q) => {
		if (q.question_type === 'question') {
			customQAns[q.id] = '';
		} else {
			customQAns[q.id] = {};
		}
		return null;
	});

	return Object.assign({}, state, {
		...state,
		autoTable: auto_table_distribution,
		diningTime: dining_time,
		showCanvas: canvas_enabled,
		timeChecking: time_checking,
		smsEnable: sms_enable,
		customQ: customized_questions,
		customQAns,
		tempCustomQAns: JSON.parse(JSON.stringify(customQAns))
	});
}
