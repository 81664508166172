import React from 'react';

const NotEnoughtWarnging = ({ bookingAttend, tempSeating, cancelWaring, checkWarning }) => {
	const maxCount = tempSeating.reduce((res, table) => (res += table.max_seat), 0);
	const attendanceCount = Math.abs(maxCount - bookingAttend);

	return (
		<div className="NotEnoughtWarnging">
			<div>
				<h2 className="text-center">
					尚不足 <span>{attendanceCount}</span> 人座位
				</h2>
				<p className="text-center">您所選的桌次不足預約人數，是否仍要建立預約？</p>

				<div className="buttons text-center">
					<button onClick={() => cancelWaring()}>重新選位</button>
					<button onClick={() => checkWarning()}>確認</button>
				</div>
			</div>
		</div>
	);
};

export default NotEnoughtWarnging;