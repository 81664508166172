import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router';
import { handleWebsocketEventRegistry } from '../../libs/handler';
import { config } from '../../utils/config';
import { notification } from 'antd';
var createReactClass = require('create-react-class');

const openNotification = (title, message, backgroundColor, borderColor) => {
	notification.config({
		placement: 'bottomLeft',
		top: 100
	});

	notification.open({
		message: title,
		description: message,
		style: {
			background: backgroundColor,
			borderStyle: 'solid',
			borderWidth: '1px',
			borderColor: borderColor
		}
	});
};

export default createReactClass({
	_getNotifications() {
		$.ajax({
			url: config.domain + '/dashboard/notifications',
			dataType: 'json',
			xhrFields: { withCredentials: true },
			success: function(data) {
				this.setState({ data: data.notifications, unchecked_count: data.meta.unchecked_count });
			}.bind(this),
			error: function() {}
		});
	},
	_getPassedTime(time_str) {
		var time = new Date(time_str);
		var now = new Date();
		var second_diff = Math.floor((now - time) / 1000);
		var minute_diff = Math.floor(second_diff / 60);
		var hour_diff = Math.floor(minute_diff / 60);
		var day_diff = Math.floor(hour_diff / 24);

		if (minute_diff < 2) {
			return '剛剛';
		} else if (minute_diff < 60) {
			return minute_diff + '分鐘前';
		} else if (hour_diff < 24) {
			return hour_diff + '小時前';
		} else {
			return day_diff + '天前';
		}
	},
	_resetUncheckedCount() {
		$.ajax({
			url: config.domain + '/dashboard/notifications/reset_unchecked_count',
			dataType: 'json',
			type: 'POST',
			xhrFields: { withCredentials: true },
			success: function(data) {
				this.setState({ unchecked_count: 0 });
			}.bind(this),
			error: function() {}
		});
	},
	getInitialState() {
		return {
			unchecked_count: 0,
			data: []
		};
	},

	componentDidMount() {
		this._getNotifications();
		handleWebsocketEventRegistry(
			'notify',
			function(data) {
				var unchecked_count = this.state.unchecked_count;
				var ns = $.extend([], this.state.data);
				ns.unshift(data);
				this.setState({ unchecked_count: unchecked_count + 1, data: ns });
			}.bind(this)
		);

		handleWebsocketEventRegistry(
			'queue_notify',
			function(data) {
				openNotification(
					data.message_title,
					data.group_title + ' : ' + data.name + data.message,
					data.background_color,
					data.border_color
				);
			}
		);
	},

	render() {
		return (
			<li className="header-notify" onClick={this._resetUncheckedCount}>
				{
					this.props.disabled // 未改密碼前不可點
					?	<a>
							<i className="navNotificationIcon" />
							<span className="notify-badge">{this.state.unchecked_count}</span>
						</a>
					: <a href="#" id="notify-status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i className="navNotificationIcon" />
							<span className="notify-badge">{this.state.unchecked_count}</span>
						</a>
				}
				<ul className="dropdown-menu notification-list" aria-labelledby="notify-status">
					{this.state.data.map(
						function(notification) {
							let addition_query = {};
							if (notification.module_name === 'bookings') {
								addition_query = { mode: 'list' };
							}
							return (
								<div key={notification.id}>
									<li>
										<Link
											to={{
												pathname: '/dashboard/' + notification.module_name,
												query: { ...notification.parameters, ...addition_query }
											}}
											className="media"
										>
											<div className="media-left media-middle notification-icon">
												<img
													className="media-object"
													src={require('../../images/module_icon_' +
														notification.module_name +
														'.svg')}
													alt=""
												/>
											</div>
											<div className="media-body notify-msn">
												<h5>
													<span>{'[' + notification.module_name_display + '] '}</span>
													{notification.message}
												</h5>
												<h5 className="notify-time">
													{this._getPassedTime(notification.created_at)}
												</h5>
											</div>
										</Link>
									</li>
									<li role="separator" className="divider" />
								</div>
							);
						}.bind(this)
					)}
				</ul>
			</li>
		);
	}
});
