import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SWITCH_USER_SUCCESS,
  COUPON_BINDIND,
} from "../actions/auth";

//import { loadUserProfile } from "../utils/apiUtils";

const initialState = {
  user: null,
  password: null,
  userRole: null,
  loggingIn: false,
  loggingOut: false,
  loginError: null,
  switchable_users: [],
};

// function initializeState() {
//   const userProfile = loadUserProfile();
//   return Object.assign({}, initialState, userProfile);
// }

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, { loggingIn: true });
    case COUPON_BINDIND:
      return Object.assign({}, state, {
        ...state,
        shop:{
          ...state.shop,
          menu_shop_user: true
        }
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loggingIn: false,
        user: action.user,
        booking_setting: action.booking_setting,
        shop: action.shop,
        switchable_users: action.switchable_users
      });
    case LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        user: null,
        role: null,
        loginError: action.error
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        user: null,
        userRole: null,
        loginError: null
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };
    case SWITCH_USER_SUCCESS:
      return {
        ...state,
        user: null
      }
    default:
      return state;
  }
}
