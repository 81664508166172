import React from 'react';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';


import wall_1 from '../../../images/tablemap/wall-1.svg';
import wall_2 from '../../../images/tablemap/wall-2.svg';
import wall_3 from '../../../images/tablemap/wall-3.svg';
import wall_4 from '../../../images/tablemap/wall-4.svg';
import wall_5 from '../../../images/tablemap/wall-5.svg';
import wall_6 from '../../../images/tablemap/wall-6.svg';
import toilet from '../../../images/tablemap/toilet.svg';
import exit from '../../../images/tablemap/exit.svg';

const eleList = {
	wall_1,
	wall_2,
	wall_3,
	wall_4,
	wall_5,
	wall_6,
	toilet,
	exit
};


const URLImage = ({ img }) => {
	const imgRef = React.useRef();
	const [ image ] = useImage(eleList[img.name]);

	return (
		<Group preventDefault={false} x={img.positionX} y={img.positionY}>
			<Image ref={imgRef} width={img.width} height={img.height} image={image} preventDefault={false} />
		</Group>
	);
};

export default URLImage;
