import React, { Fragment, useEffect, useCallback, useContext }  from 'react';

import { MessageContext } from '../crmContext/MessageContext';

const CrmMessageNotification = () => {
  const { MessageState, MessageStateDispatch } = useContext(MessageContext);
  const { notificationType, msgType } = MessageState;

  useEffect(() => {
    setTimeout(() => {
      MessageStateDispatch({ type: 'closeMessageNotification' });
    }, 3000)
  }, [ MessageStateDispatch ])

	const switchTab = useCallback((tab) => {
		MessageStateDispatch({ type: 'switchMessageStatus', messageStatus: tab });
		MessageStateDispatch({ type: 'closeMessageNotification' });
	}, [ MessageStateDispatch ]);

  const renderContent = () => {
    let msgTypeText = msgType === 'sms' ? '簡訊' : 'MENU 私訊';

    if(notificationType === 'draft') {
      return (
        <Fragment>
          <div>{ msgTypeText }已儲存至草稿</div>
          <button onClick={() => switchTab('draft')}>前往查看</button>
        </Fragment>
      )
    } else if(notificationType === 'scheduling') {
      return (
        <Fragment>
          <div>已排程{ msgTypeText }傳送時間</div>
          <button onClick={() => switchTab('scheduling')}>查看排程訊息</button>
        </Fragment>
      )
    } else if(notificationType === 'sent') {
      return (
        <Fragment>
          <div>已傳送{ msgTypeText }</div>
          <button onClick={() => switchTab('sent')}>查看寄件備份</button>
        </Fragment>
      )
    }
  };

  return (
    <div className="crmMessageNotification">
      { renderContent() }
    </div>
  )
}

export default CrmMessageNotification;