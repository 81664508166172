// 排隊頁面 ikea 客製化店家清單
export const queueDisplayLists_ikea = [
  2604,
  2636,
  2637,
  2638,
  2639,
  2640,
  2649,
  2650,
  2651,
  2652,
  2653,
  2654,
  2655,
  2657,
  2658,
  2661,
  2674,
  2676,
  2677,
  2678,
  2679,
  2681,
  2682,
  2683,
  2684,
  2685,
  2686,
  2687,
  2689,
  2690,
  2700
]