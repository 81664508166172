import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { SignUpStateContext } from '../MenuCouponContext/MenuCouponSignUpContext';
import MenuCouponApi from '../MenuCouponApi';

const menuCouponAPI = new MenuCouponApi();

const categoryType = [ '美食', '旅遊', '美妝', '時尚', '娛樂' ];

const SignUpStepOne = () => {
	const dispatch = useDispatch();
	const { SignUpState, SignUpStateDispatch } = useContext(SignUpStateContext);
	const { shopName, shopBranch, tel, address, category } = SignUpState.shopInfo;
	const [ loading, setLoading ] = useState(false);

	const handleChange = (e) => {
		const txt = e.target.value;
		const name = e.target.getAttribute('name');

		SignUpStateDispatch({ type: 'setShopInfo', name, txt });
	};

	const chooseCategory = (type) => {
		SignUpStateDispatch({ type: 'setShopInfo', name: 'category', txt: type });
	};

	const renderCategory = () => {
		return categoryType.map((type) => {
			const active = type === category ? 'active' : '';
			return (
				<label key={type} className={`${active}`} onClick={() => chooseCategory(type)}>
					{type}
				</label>
			);
		});
	};

	const nextStep = async () => {
		try {
			setLoading(true);
			await menuCouponAPI.updateShopInfo({
				shop: {
					display_name: shopName,
					address,
					phone_number: tel,
					branch: shopBranch,
					category
				}
			});
			setLoading(false);
			dispatch({ type: 'COUPON_BINDIND' });
			SignUpStateDispatch({ type: 'goNextStep', step: 'done' });
		} catch (err) {
			console.log('nextStep err----', err);
			window.app.alert.setMessage('已綁定過', 'error');
			setLoading(false);
		}
	};

	const renderSetShopInfo = () => {
		return (
			<div>
				<h2 className="text-center">店家基本資訊</h2>

				<div className="shopInfoRow">
					<p className="title">店家名稱 (必填)</p>
					<div>
						<input
							type="text"
							placeholder="請輸入店家名稱"
							value={shopName}
							name="shopName"
							onChange={(e) => handleChange(e)}
						/>
					</div>
				</div>

				<div className="shopInfoRow">
					<p className="title">分店名稱</p>
					<div>
						<input
							type="text"
							placeholder="請輸入分店名稱"
							value={shopBranch}
							name="shopBranch"
							onChange={(e) => handleChange(e)}
						/>
					</div>
				</div>
				<div className="shopInfoRow">
					<p className="title">電話號碼</p>
					<div>
						<input
							type="tel"
							placeholder="請輸入電話號碼"
							value={tel}
							name="tel"
							onChange={(e) => handleChange(e)}
						/>
					</div>
				</div>
				<div className="shopInfoRow">
					<p className="title">地址 (必填)</p>
					<div>
						<input
							type="text"
							placeholder="請輸入地址"
							value={address}
							name="address"
							onChange={(e) => handleChange(e)}
						/>
					</div>
				</div>
			</div>
		);
	};

	const disable = shopName.trim() === '' || address.trim() === '' || category === '';

	return (
		<div className="signUpStep_1">
			<h1 className="text-center">歡迎使用 優惠券系統</h1>

			<div className="shopInfo">
				{renderSetShopInfo()}

				<div>
					<h2 className="text-center">店家類別</h2>
					<div className="categoryLable">{renderCategory()}</div>
				</div>

				<div className="text-center">
					<button disabled={disable} className="greenThemeBtn" onClick={() => nextStep()}>
						下一步
					</button>
				</div>
			</div>
			{loading && (
				<Modal
					destroyOnClose
					visible={loading}
					zIndex="1035"
					width={200}
					footer={null}
					closable={false}
					maskClosable={false}
					bodyStyle={{ padding: 10, textAlign: 'center', fontSize: 16, fontWeight: 500 }}
				>
					<LoadingOutlined style={{ fontSize: 30 }} />
					<p style={{ margin: '5px 0 0 0' }}>Loading ...</p>
				</Modal>
			)}
		</div>
	);
};

export default SignUpStepOne;
