import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import moment from 'moment';

import { WarningTemplate } from '../NewModuleComponent/NewModuleWarning';
import { CustomerTag, customQAns } from '../NewModuleComponent/NewModuleTools';

import { seatAlertTitle, time, useInterval } from '../utils/data';
import {Portal} from '../utils/Portal'

import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';
import { BaseSettingContext } from '../NewModuleReducer/BaseSettingReducer';

import BookingSystemApi from '../../booking_settings/BookingSystemApi';

import '../../../stylesheets/new_module_tablemap.css';

const BookingAPI = new BookingSystemApi();

const CurrentBooking = ({ children }) => {
	return (
		<div className="canvasTableBookingInfo__currentBooking">{React.Children.map(children, (child) => child)}</div>
	);
};

const CurrentBookingHeader = ({ currentBooking, switchMode }) => {
	const endTime = useMemo(
		() => {
			return moment(currentBooking.booking_datetime)
				.add(currentBooking.original_dining_time, 'm')
				.format('HH:mm');
		},
		[ currentBooking ]
	);

	return (
		<div className={`canvasTableBookingInfo__currentBooking__header ${currentBooking.status + 'Status'}`}>
			<h3 className="currentBooking__header__title">
				{currentBooking.time} - {endTime}
			</h3>
			{currentBooking.status !== 'finish' && (
				<button onClick={() => switchMode(2, currentBooking)}>{currentBooking.status_t}</button>
			)}
		</div>
	);
};

const CurrentBookingBody = ({ currentBooking, systemMode, swapMode, swapMoreSeatMode }) => {
	const { BaseSettingState } = useContext(BaseSettingContext);
	const { customQ } = BaseSettingState;

	const { editBooking } = useContext(CommomSettingContext);

	const customQAnsMemo = useMemo(
		() => {
			return customQAns(customQ, currentBooking['customized_questions']);
		},
		[ customQ, currentBooking ]
	);

	const renderCurrentBookingSource = () => {
		if (currentBooking.source === 'online') {
			if (currentBooking.source_url_t === 'google') {
				return <span className="bookingSourceIcon bookingSourceIcon-google" />;
			} else if (currentBooking.source_url_t === 'facebook') {
				return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
			} else if (currentBooking.source_url_t === 'instagram') {
				return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
			} else if (currentBooking.source_url_t === 'menuApp') {
				return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
			} else if (currentBooking.source_url_t === 'other') {
				return <span className="bookingSourceIcon bookingSourceIcon-other" />;
			}
		}

		if(currentBooking.source === 'google_booking' && currentBooking.source_url_t === 'google_reservation') {
			return <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />;
		}
	};

	const renderOnsiteTag = () => {
		if (currentBooking.on_site) {
			return <span className="onSiteTag">現場</span>;
		}
	};

	const renderBookingStatusTime = useCallback(
		() => {
			let statusTime = JSON.parse(currentBooking.status_time);
			const { status } = currentBooking;
			const time = statusTime[currentBooking.status].slice(-8, -3);

			if (status === 'show') {
				return <span className="stautsBtnTime">{time}報到</span>;
			} else if (status === 'seated') {
				return <span className="stautsBtnTime stautsBtnTime-seated">{time}入座</span>;
			} else if (status === 'finish') {
				const seatedTime = statusTime['seated'].slice(-8, -3);
				const seatedTimeTxt = seatedTime.length !== 0 ? `${seatedTime}入座 - ` : '';
				return (
					<span className="stautsBtnTime stautsBtnTime-finish">
						{seatedTimeTxt}
						{time}完成消費
					</span>
				);
			}
		},
		[ currentBooking ]
	);

	const renderSeats = () => {
		if (swapMode || swapMoreSeatMode || systemMode === 'timeline') {
			return (
				<ul className="swapSeatWrap">
					{currentBooking.seats.map((seat) => {
						return <li key={seat.table_number}>{seat.table_number}</li>;
					})}
				</ul>
			);
		}
	};

	const renderShopMemo = () => {
		if (currentBooking.shop_memo && currentBooking.shop_memo.trim() !== null) {
			return <p className="currentBooking__body__shopMemo">{currentBooking.shop_memo}</p>;
		}
		return null;
	};

	const renderCustomerMemo = () => {
		if (currentBooking.memo !== null) {
			return <p className="currentBooking__body__customerMemo">{currentBooking.memo}</p>;
		}

		return null;
	};

	return (
		<div className="canvasTableBookingInfo__currentBooking__body">
			<PassedDiningTime systemMode={systemMode} currentBooking={currentBooking} />
			<div className="currentBooking__info">
				<p>
					{renderCurrentBookingSource()}
					{renderOnsiteTag()}
					{renderBookingStatusTime()}
				</p>
				<div className="info__top">
					<div className="info__top__name">
						<CustomerTag booking={currentBooking} />
						<span className="name">{currentBooking.last_name}</span>
						<span className="info__top__gender">{currentBooking.gender_desc}</span>
						<span className="info__top__edit" onClick={() => editBooking(currentBooking)} />
						<p className="info__top__tel">{currentBooking.phone_number}</p>
					</div>
					<div className="info__top__attendance">
						<span>{currentBooking.attendance}</span>位
					</div>
				</div>
				{renderSeats()}

				{renderShopMemo()}
				{renderCustomerMemo()}

				<div className="currentBooking__body__customerTag">
					<ul>
						{customQAnsMemo.serviceTagBlock.map((tag, index) => {
							return <li key={index}>{tag}</li>;
						})}
						{customQAnsMemo.quantityBlock.map((ele, index) => {
							return (
								<li key={index}>
									{ele.name}x{ele.value}
								</li>
							);
						})}
					</ul>
				</div>
				<div className="currentBooking__body__noteWRap">
					<ul>
						{customQAnsMemo.questionsBlock.map((q, index) => {
							return <li key={index}>{q}</li>;
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

const CurrentBookingFooter = ({
	currentBooking,
	systemMode,
	swithcStatus,
	swapMode = false,
	swapMoreSeatMode,
	startSwapSeat = () => {},
	checkConflict = () => {},
	startSwapMoreSeat = () => {}
}) => {
	const { CommomSettingState } = useContext(CommomSettingContext);
	const { isToday } = CommomSettingState;

	const renderSwapBtn = () => {
		if (systemMode !== 'timeline') {
			if (!swapMode && !swapMoreSeatMode) {
				//開始交換模式
				return <React.Fragment>
					<button className="switchBtn" onClick={() => startSwapSeat(currentBooking)}>交換</button>
					<button className="swapBtn" onClick={() => startSwapMoreSeat(currentBooking)}>選位</button>
				</React.Fragment>
			} else if (swapMode) {
				//交換模式
				return <SwapNSeatBtn checkConflict={checkConflict} beSwappedInfo={currentBooking} />;
			}
		}
	};

	return (
		<div className="canvasTableBookingInfo__currentBooking__footer">
			{renderSwapBtn()}
            { isToday && !swapMode && !swapMoreSeatMode && (
                <StatusBtn
                    currentBooking={currentBooking}
                    swithcStatus={swithcStatus}
                    systemMode={systemMode}
                />
            )}
		</div>
	);
};

const SwapNSeatBtn = ({ checkConflict, beSwappedInfo = {} }) => {
	return (
		<div className="bookingStatusBtnWrap">
			<button className="seatedBtn" onClick={() => checkConflict('seated', beSwappedInfo)}>
				入座
			</button>
			<button className="switchBtn_1" onClick={() => checkConflict('swap', beSwappedInfo)}>
				交換
			</button>
		</div>
	);
};

const StatusBtn = ({ systemMode, currentBooking, swithcStatus }) => {
	const [ currentTime, setCurrentTime ] = useState(moment());
	const [ intervalTime, setIntervalTime ] = useState(systemMode === 'timeline' ? 1000 : null);
	const today = moment().format('YYYY/MM/DD');
	const pre = moment(new Date(currentBooking.booking_datetime)).add(-60, 'm').format('YYYY/MM/DD HH:mm');
	const tomorrow = moment(new Date(today)).add(1, 'd').format('YYYY/MM/DD HH:mm');
	const [ hideStatusBtn, setHideStatusBtn ] = useState(true);

	const findStatus = useCallback(
		() => {
			if (currentTime.isBefore(new Date(pre))) {
				//當天時間>預約60分鐘
				// console.log('----預約>60分鐘----');
				setHideStatusBtn(true);
			} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
				//當天時間<=預約60分鐘
				// console.log('----預約前60分鐘----');
				setHideStatusBtn(false);
			}
		},
		[ pre, tomorrow, currentTime ]
	);

	useEffect(
		() => {
			if (systemMode === 'timeline') {
				setIntervalTime(1000);
				findStatus();
			}
		},
		[ systemMode, findStatus ]
	);

	useInterval(() => {
		setCurrentTime(moment());
	}, intervalTime);

	const renderStatusBtn = () => {
		if (systemMode !== 'timeline') {
			return (
				<div className="bookingStatusBtnWrap">
					{<ShortCutStatusBtn booking={currentBooking} swithcStatus={swithcStatus} />}
				</div>
			);
		} else if (systemMode === 'timeline' && currentBooking.status !== 'finish') {
			if (!hideStatusBtn) {
				return (
					<div className="bookingStatusBtnWrap">
						{<ShortCutStatusBtn booking={currentBooking} swithcStatus={swithcStatus} />}
					</div>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	return renderStatusBtn();
};

const ShortCutStatusBtn = ({ booking, swithcStatus }) => {
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);

	const seatedWarning = useCallback(
		() => {
			BookingAPI.checkSeated(booking.id).then((data) => {
				if (data.overlay_table.length !== 0) {
					setShowWarning(true);
					setWarningTales(data.overlay_table);
				} else {
					swithcStatus(booking, 'seated');
				}
			});
		},
		[ booking, swithcStatus ]
	);

	const cancelWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
	};

	const confirmWarning = () => {
		swithcStatus(booking, 'seated');
		setShowWarning(false);
		setWarningTales([]);
	};

	const renderBtn = () => {
		switch (booking.status) {
			case 'confirmed':
				return (
					<React.Fragment>
						<button
							className="currentBooking__statusBtn currentBooking__statusBtn-noshow"
							onClick={() => swithcStatus(booking, 'no_show')}
						>
							未到店
						</button>
						<button
							className="currentBooking__statusBtn currentBooking__statusBtn-seated"
							onClick={() => seatedWarning()}
						>
							入座
						</button>
					</React.Fragment>
				);
			case 'show':
				return (
					<React.Fragment>
						<button
							className="currentBooking__statusBtn currentBooking__statusBtn-seated"
							onClick={() => seatedWarning()}
						>
							入座
						</button>
					</React.Fragment>
				);

			case 'seated':
				return (
					<React.Fragment>
						<button
							className="currentBooking__statusBtn currentBooking__statusBtn-finish"
							onClick={() => swithcStatus(booking, 'finish')}
						>
							結帳
						</button>
					</React.Fragment>
				);

			default:
				return (
					<React.Fragment>
						<button
							className="currentBooking__statusBtn currentBooking__statusBtn-cancel"
							onClick={() => swithcStatus(booking, 'cancel')}
						>
							取消預約
						</button>
						<button
							className="currentBooking__statusBtn currentBooking__statusBtn-confirmed"
							onClick={() => swithcStatus(booking, 'confirmed')}
						>
							確認預約
						</button>
					</React.Fragment>
				);
		}
	};

	return (
		<React.Fragment>
			{renderBtn()}
			{showWarning && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title={seatAlertTitle}
					>
						<p>
							桌次
							{warningTales.map((table) => {
								return (
									<span key={table} className="tableName">
										{table}
									</span>
								);
							})}
							有尚未結束的預約
						</p>
						<p>是否確定入座？</p>
					</WarningTemplate>
				</Portal>
			)}
		</React.Fragment>
	);
};

const PassedDiningTime = ({ currentBooking, systemMode }) => {
	const { CommomSettingState } = useContext(CommomSettingContext);
	const { date, moduleSelectedBookingTime, moduleTimePaused } = CommomSettingState;

	const [ intervalTime, setIntervalTime ] = useState(1000);
	const [ currentTimestamp, setCurrentTimestamp ] = useState(moment());
	// const [ selectedTime, setSelectedTime ] = useState(moduleSelectedBookingTime);
	const [ serviceStartTime, setServiceStartTime ] = useState(moment(currentBooking.service_start_time));
	const [ diningTime, setDiningTime ] = useState(JSON.parse(currentBooking.dining_time));
	const [ duration, setDuration ] = useState(moment.duration(currentTimestamp.diff(serviceStartTime)));
	const [ durationHour, setDurationHour ] = useState(duration.hours());
	const [ durationMin, setDurationMin ] = useState(duration.minutes());
	const [ durationDiff, setDurationDiff ] = useState(duration.asMinutes()); //已用餐幾分鐘

	let showDiningTimeBool = false;

	if (currentTimestamp >= serviceStartTime && durationDiff <= diningTime) {
		showDiningTimeBool = true;
	}

	const [ showDiningTime, setShowDiningTime ] = useState(showDiningTimeBool);

	// const diningStatus=useMemo(()=>{
	// 	return durationDiff > diningTime - 30 ? 'diningTime-alert' : 'diningTime-normal'
	// }, [durationDiff, diningTime])

	useEffect(
		() => {
			const newServiceStartTime = moment(currentBooking.service_start_time);
			const newDiningTime = JSON.parse(currentBooking.dining_time);
			const newDuration = moment.duration(moment().diff(newServiceStartTime));
			const newDurationDiff = newDuration.asMinutes();

			let showDiningTimeBool = false;

			if (moment() >= newServiceStartTime && newDurationDiff <= newDiningTime) {
				showDiningTimeBool = true;
			}

			setServiceStartTime(newServiceStartTime);
			setDiningTime(newDiningTime);
			setShowDiningTime(showDiningTimeBool);
		},
		[ currentBooking ]
	);

	useEffect(
		() => {
			if (systemMode === 'tablemap') {
				if (!moduleTimePaused) {
					setIntervalTime(1000);
				} else if (moduleTimePaused) {
					// setSelectedTime(moduleSelectedBookingTime);
					setCurrentTimestamp(moment(new Date(date + ' ' + time[moduleSelectedBookingTime])));
					setIntervalTime(null);
				}
			}
		},
		[ moduleSelectedBookingTime, date, moduleTimePaused, systemMode ]
	);

	useEffect(
		() => {
			if (currentTimestamp < serviceStartTime) {
				//尚未到預約時間不顯示用餐時間
				setShowDiningTime(false);
				setIntervalTime(null);
				return;
			} else {
				if (durationDiff > diningTime && showDiningTime) {
					//超過用餐時間不顯示也不計時
					setIntervalTime(null);
					setShowDiningTime(false);
					return;
				}

				const durationCount = moment.duration(currentTimestamp.diff(serviceStartTime));
				const durationHourCount = durationCount.hours(),
					durationMinCount = durationCount.minutes(),
					durationDiffCount = durationCount.asMinutes(); //已用餐幾分鐘

				setDuration(durationCount);
				setDurationHour(durationHourCount);
				setDurationMin(durationMinCount);
				setDurationDiff(durationDiffCount);

				if (durationDiff <= diningTime && !showDiningTime) {
					//尚未超過用餐時間
					setShowDiningTime(true);
				}
			}
		},
		[
			currentTimestamp,
			diningTime,
			durationDiff,
			intervalTime,
			moduleTimePaused,
			serviceStartTime,
			showDiningTime,
			systemMode
		]
	);

	useInterval(() => {
		setCurrentTimestamp(moment());
	}, intervalTime);

	return (
		<div>
			{showDiningTime &&
			currentBooking.status !== 'finish' && (
				<p className={`currentBooking__diningTime`}>
					(已用餐<span>{durationHour}</span>小時<span>{durationMin}</span>分鐘)
				</p>
			)}
		</div>
	);
};

CurrentBooking.Header = CurrentBookingHeader;
CurrentBooking.Body = CurrentBookingBody;
CurrentBooking.Footer = CurrentBookingFooter;

export default CurrentBooking;
