//import React from 'react'
import React from 'react'
import $ from 'jquery'
// import _ from 'lodash'
// import { config } from '../../utils/config'
import {handleError} from '../../libs/handler'
// import { CLIENT_RENEG_LIMIT } from 'tls';

// import PropTypes from 'prop-types';
// import zipData from '../../utils/zipData';
// import twCity from '../../utils/twCity';
// import businessType from '../../utils/businessType';

// var createReactClass = require('create-react-class');


export default class extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      init: false,
      isSaved: true,
      goRegister: false,
    }
  }
  routerWillLeave = (nextLocation) => {
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if(!this.state.isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?'
  }

  componentDidMount(){
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    if (this.props.location.query.code != undefined){
      this.initToken(this.props.location.query.code)
    }
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
          method: 'GET', 
          xhrFields: { withCredentials: true }})
      .done(function(data){
        this.setState(data);
        //this.setState({init: true});
      }.bind(this))
      .fail(function(xhr){
        handleError(xhr);
      });

      $.ajax({url: window.domain + '/dashboard/booking_settings/calendar_settings', 
      method: 'GET', 
      xhrFields: { withCredentials: true }})
      .done(function(data){

        this.setState(data);
        this.setState({init: true});
      }.bind(this))
      .fail(function(xhr){
        handleError(xhr);
      });
    
    }
    componentDidUpdate(prevProps, prevState) {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }

  onChange = (e, msg) => {
    var name = e.target.name
    var val = e.target.value
    if(e.target.type == 'checkbox'){ val = e.target.checked }
    var result = {}
    result[name] = val
    this.setState(result, ()=>this.setState({isSaved: false}))
    if(msg){
      this.checkInput(e, msg)
    }
  }

  submit = () =>{
    $.ajax({url: window.domain + '/dashboard/booking_settings/calendar_settings', 
        data: { booking_settings:{
          calendar_enabled: this.state.calendar_enabled
        },
      calendar_settings:{
        google_calendar_id: this.state.google_calendar_id
      }},
        method: 'PATCH', 
        xhrFields: { withCredentials: true }})
      .done((data) => {
        this.setState({isSaved: true})
        window.app.alert.setMessage("儲存成功", "done");
      })
      .fail(function(xhr){
        window.app.alert.setMessage("error");  
        window.app.alert.setMessage(xhr.responseJSON.errors.join(' '), "error");        
        handleError(xhr);
      });
  }

clickRegister = () => {
  this.setState({goRegister: true});
  $.ajax({url: window.domain + '/dashboard/booking_settings/init_google_calendar', 
  method: 'GET', 
  xhrFields: { withCredentials: true }})
  .done(function(data){
    window.location = data
  })
  .fail(function(xhr){
  handleError(xhr);
  });

}

initToken = (auth_code) => {
  
  $.ajax({url: window.domain + '/dashboard/booking_settings/init_google_token', 
  method: 'POST', 
  data: {auth_code: auth_code},
  async: false ,
  xhrFields: { withCredentials: true }})
  .done(function(data){
    // console.log('Init Google Token')
    // console.log(data)
    this.setState()
  }.bind(this))
  .fail(function(xhr){
  handleError(xhr);
  });
}

  render() {

    // TODO: the checking is temporarily now, need to modify it.
    if(this.state.init && (this.state.access_token == null || this.state.access_token == '')){

      return (
        <div className="main overflow">
          <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1}}>
              <h3>Google日曆同步設定</h3>
              <h5>您可以透過同步功能，在您的Google日曆中建立MENU店+的專屬日曆，以在您的Google日曆中檢視顧客的預約結果。</h5>
            </div>

          </div>
          <hr/>
          <div style={{marginLeft: 64, marginTop: 143}}>
            <h5 style={{ fontSize: 24}}>登入您常用的Google帳戶與預約系統行事曆串接，MENU店+<br/>的新增與修改預約將會與您的Google日曆同步更新。</h5>
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: 59, marginLeft:64}}>
            <button className="msBtn msBtnMain" onClick={this.clickRegister}>開始設定</button><br/><hr/>
          </div>
      </div>
      )

    }

    if(this.state.init){
      return (
        <>
          <div className="main overflow">
            <div style={{display: 'flex'}}>
              <div style={{flexGrow: 1}}>
                <h3>Google日曆同步設定</h3>
                  <h5>您可以透過同步功能，在您的Google日曆中建立MENU店+的專屬日曆，以在您的Google日曆中檢視顧客的預約結果。</h5>
              </div>
            </div>
            <hr/>
            <div className="rangeTitle">
              <img src={require("../../images/check_icon.png")} alt='check'/>
              <h4 style={{color:'#3fba87', fontWeight: 'bold'}}>Google日曆功能已啟用</h4>
            </div>
            <div className="reservation-settings " style={{marginTop: 41, paddingTop: 0}}>
              <h5 style={{fontSize: '18px'}}>MENU店+已經在您的Google帳號: <span style={{color:'#3fba87'}}>{this.state.user_google_email}</span> 的日曆中<br/>
                  建立了日曆 <span style={{color:'#3fba87'}}>{this.state.google_calendar_name}</span>，<br/>
                  您現在可以在Google日曆中同步查看您的MENU店+預約。</h5>
            </div>
            <div className="settingTbl" style={{marginTop:28, marginLeft: 0}}>
              <div className="settingTblRow">
              <div className="header"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="同步預約功能到Google日曆"
                  style={{flexBasis: '30%'}}>
                  同步MENU店+ 預約
                  <i className="fa fa-question-circle-o" aria-hidden="true" style={{marginLeft: 5}}></i>
                </div>
                <div className="text">
                  <label className="msSwitch">
                    <input type="checkbox" name="calendar_enabled" onChange={this.onChange} checked={this.state.calendar_enabled} />
                    <span className="slider"></span>
                  </label>
                  
                </div>
              </div>
            </div>
          </div>

          <hr className='main_width' />

          <div className='fix_bottom'>
            <button
              className='btn_submit'
              onClick={this.submit}
            >
              儲存
            </button>
          </div>
        </>
      );
    }else {
      return <div className="pageLoading">讀取中..</div>;
    }
  }
}
