import React from "react";
import { Link } from "react-router";
var createReactClass = require("create-react-class");
export default createReactClass({
	render() {
		return (
			<div className='main-item'>
				<Link to='/dashboard/customers' role='button'>
					<div className='function-thub'>
						<img
							alt='customer'
							src={require("../../images/component/crm_logo.svg")}
						/>
						<div className='caption function-title'>
							<h3>顧客關係管理</h3>
							<p>管理顧客相關資訊</p>
						</div>
					</div>
				</Link>
			</div>
		);
	}
});
