import React from 'react';
import { Link } from 'react-router';
import ScrollReveal from "../../libs/vendor/scrollreveal.min";
import PageNav from './PageNav';
import PageFooter from './PageFooter';
import PageSlogan from './PageSlogan';

import '../../sass/pageBookingSystem.scss';

var createReactClass = require('create-react-class');

export default createReactClass({
	componentDidMount() {
		window.contactParams = this.props.location.query;

		const sr = ScrollReveal();
		sr.reveal(".section_cover .preview", {
      distance: "50px",
      origin: "right",
      duration: 500,
      delay: 300
    });

		sr.reveal(".section_flow h1, .section_flow img",
			{
				distance: "50px",
				origin: "left",
				duration: 500,
				delay: 300,
				viewFactor: 0.7
			},
			300
		);

		sr.reveal(
      ".section_auto .content_block",
      {
        scale: 0.9,
        opacity: 0,
        distance: "20px",
        origin: "bottom",
        duration: 500,
        delay: 300
      },
      300
    );

		// google 預約
		sr.reveal(
      ".section_google .google_cover",
      {
        scale: 1.2,
        opacity: 0,
        distance: "20px",
        origin: "right",
        duration: 500,
        delay: 300
      },
      300
    );

		sr.reveal(
      ".section_google h1, .section_google .google_feat div",
      {
        scale: 0.9,
        opacity: 0,
        distance: "20px",
        origin: "right",
        duration: 500,
        delay: 300
      },
      300
    );

		sr.reveal(
      ".section_booking h1, .section_booking h3, .section_booking img",
      {
        scale: 0.9,
        opacity: 0,
        distance: "100px",
        origin: "top",
        duration: 500,
        delay: 500,
				viewFactor: 0.7
      },
      300
    );

		sr.reveal(
      ".section_tablemap h1, .section_tablemap div, .section_tablemap img",
      {
        scale: 0.9,
        opacity: 0,
        distance: "30px",
        origin: "right",
        duration: 500,
        delay: 350
      },
      200
    );

		sr.reveal(
      ".section_beforehand .content_block",
      {
        scale: 1.2,
        opacity: 0,
        distance: "20px",
        origin: "top",
        duration: 500,
        delay: 300,
				viewFactor: 0.5
      },
      500
    );
	},
	render() {
		return (
			<div className='pageBookingSystem'>
				<PageNav />

				<section id="header-2" className="section_cover">
					<div className="description">
						<h2>
							線上預約系統
						</h2>
						<h3>
							拋棄厚重的紙本紀錄
							<br />
							預約訂位管理，隨時掌握
						</h3>
						<a href="https://booking.menushop.tw/findlife11" target="_blank" rel="noopener noreferrer">
							<div className="button">瞧瞧示範店家</div>
						</a>
					</div>
					<div className="preview">
						<img src={require('../../images/page_bookingSystem/notebook.png')} alt="" />
					</div>
				</section>

				<section className='section_flow'>
					<div className='container_center'>
						<h1>
							直覺簡單的操作流程，<br />
							預約訂位與顧客資料一次到位。
						</h1>
						<img src={require('../../images/page_bookingSystem/flow.svg')} alt="" />
					</div>
				</section>

				<section className='section_auto'>
					<h1>自動化預約管理</h1>
					<div className='content'>
						<div className='content_block'>
							<img src={require('../../images/page_bookingSystem/auto_1.png')} alt="" />
							<div><span>自動回寄簡訊、LINE及Email確認預約</span></div>
						</div>
						<div className='content_block'>
							<img src={require('../../images/page_bookingSystem/auto_2.png')} alt="" />
							<div><span>同時自動建立顧客資訊</span></div>
						</div>
						<div className='content_block'>
							<img src={require('../../images/page_bookingSystem/auto_3.png')} alt="" />
							<div><span>省去人力資源，提高管理效率</span></div>
						</div>
					</div>
				</section>

				{/* google 預約 */}
				<section className='section_google'>
					<img className='google_cover' src={require('../../images/page_bookingSystem/P4-1x3.png')} alt="" />
					<div className='content'>
						<div className='feature'>
							<h1>免費開通<br />「透過Google預訂」功能</h1>
							<div className='google_feat'>
								<div><span className='icon_check'/>無需任何加購、沒有額外組數收費</div>
								<div><span className='icon_check'/>在Google搜尋、地圖上新增您的「訂位」按鈕</div>
								<div><span className='icon_check'/>無需跳轉網頁，讓顧客一站式完成訂位，減少轉換流失</div>
								<div><span className='icon_check'/>由MENU店+專人協助進行系統設定，讓您輕鬆上手</div>
							</div>
						</div>
						<img className='google_bg' src={require('../../images/page_bookingSystem/P4_bg.png')} alt="" />
					</div>
				</section>

				<section className='section_booking'>
					<h1>預約資訊一目瞭然</h1>
					<h3>服務時間、顧客資訊、多種顯示模式，即時掌握店內狀況</h3>
					<img src={require('../../images/page_bookingSystem/bookingSystem.gif')} alt="" />
				</section>

				<section className='section_tablemap'>
					<div className='desc'>
						<div>
							<h1>現場座位圖排位功能</h1>
							<div className='desc_feat'>
								<div><span className='icon_check'/>直覺操作</div>
								<div><span className='icon_check'/>圖示服務帶位</div>
								<div><span className='icon_check'/>輕鬆Hold住全場</div>
							</div>
						</div>
					</div>
					<img src={require('../../images/page_bookingSystem/tablemap.png')} alt="" />
				</section>

				<section className='section_beforehand'>
					<h1>
						常常被客人放鳥嗎？<br />
						我們還有其他功能可以幫助你解決問題！
					</h1>
					<div className='content'>
						<div className='content_block'>
							<img src={require('../../images/page_bookingSystem/beforehand_blockCard.png')} alt="" />
							<h3>黑名單屏蔽功能</h3>
							<div>
								客人至上只能來者不拒？別擔心！<br />
								我們有黑名單顧客預約警示與阻擋功能，<br />
								讓你優雅杜絕奧客
							</div>
						</div>
						<div className='content_block'>
							<img src={require('../../images/page_bookingSystem/beforehand_moneyCard.png')} alt="" />
							<h3>線上預付訂金服務</h3>
							<div>為顧客提供便利性，建立雙贏不吃虧</div>
						</div>
					</div>
				</section>

				<PageSlogan />
				<PageFooter />
			</div>
		);
	}
});
