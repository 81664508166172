import React from 'react';
import { Modal } from 'antd';

import './styles/attendanceOverMaxSeats.scss';

export default ({ conflictTables, setConflictTables }) => {
	return (
		<Modal
			destroyOnClose
      centered
			visible={conflictTables.length !== 0}
			wrapClassName="attendanceOverMaxSeatsModal"
			footer={
				<button
					onClick={() => {
						setConflictTables([]);
					}}
				>
					確定
				</button>
			}
			title="顧客人數過多"
			onOk={() => setConflictTables([])}
			onCancel={() => setConflictTables([])}
		>
			<div className="attendanceOverMaxSeatsModalBody">該組顧客超過 {conflictTables.join('、')} 可容納最高人數。</div>
		</Modal>
	);
};
