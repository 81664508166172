import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router'
import { SearchOutlined } from '@ant-design/icons';

import Tab from './AnnouncementHeaderTab';
import Filter from './AnnouncementHeaderFilter';

import { setAllOfficialAnnouncementDisplay } from '../api/OfficialAnnouncementApi';

import {
  handleSearchList,
  resetSearch,
  handleHeaderFilter,
  announcementSelected,
  announcementSettingEnded,
  showAnnouncementAddModal,
  clearAnnouncement
} from '../../../actions/announcementAction';

const statusSelectList = [
  { title: '每日顯示一次', action: 'once' },
  { title: '不再顯示', action: 'never' }
];

const AnnouncementHeader = () => {
  const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
    page,
    role,
    searchListTxt,
    currentTab,
		selected,
    currentFilter,
    sortBy,
    order,
    originAnnouncements,
    displayAnnouncements
	} = announcementListReducer;

  const dispatch = useDispatch();

  const [ searchText, setSearchText ] = useState('');

  const resizeScreen = useCallback(() => {
    const title = document.getElementsByTagName('h1')[0];
    const searchInput = document.getElementsByClassName('searchInput')[0];
    const closeSearch = document.getElementsByClassName('clearSearch_mobile')[0];

    let w_w = window.innerWidth;

    if(w_w > 768) {
      title.style.display = 'inline-block';
      searchInput.style.display = 'block';
      closeSearch.style.display = 'none';
    } else {
      if(closeSearch.style.display === 'inline-block') {
        searchInput.style.display = 'block';
      } else {
        if(searchInput.value.length > 0) {
          showSearch();
        } else {
          searchInput.style.display = 'none';
        }
      }
    }
	}, []);

  useEffect(() => {
    window.addEventListener('resize', resizeScreen);
    return () => {
      window.removeEventListener('resize', resizeScreen);
    }
  }, [ resizeScreen ]);

  useEffect(() => {
    dispatch(handleSearchList(searchText));
  }, [ dispatch, searchText ]);

  useEffect(() => {
    const btnSearch = document.getElementsByClassName('btn_search')[0];
    const clearIcon = document.getElementsByClassName('clearSearch')[0];


    if(searchListTxt.length > 0) {
      btnSearch.classList.add('in_search');
      clearIcon.style.display = 'inline-block';
    } else {
      btnSearch.classList.remove('in_search');
      clearIcon.style.display = 'none';
    }
  }, [ searchListTxt ]);

  const addAnnouncement = () => {
    dispatch(clearAnnouncement())
    dispatch(showAnnouncementAddModal());
  };

  const cancelSearch = () => {
    setSearchText('');
    dispatch(resetSearch());
    dispatch(handleHeaderFilter(currentFilter, 1))
  };

  const closeSearch = () => {
    const title = document.getElementsByTagName('h1')[0];
    const closeIcon = document.getElementsByClassName('clearSearch_mobile')[0];
    const searchInput = document.getElementsByClassName('searchInput')[0];


    title.style.display = 'inline-block';
    closeIcon.style.display = 'none';
    searchInput.style.display = 'none';

    if(searchText.length > 0) {
      cancelSearch();
    }
  };

  const showSearch = () => {
    const title = document.getElementsByTagName('h1')[0];
    const searchInput = document.getElementsByClassName('searchInput')[0];
    const closeIcon = document.getElementsByClassName('clearSearch_mobile')[0];

    title.style.display = 'none';
    searchInput.focus();
    searchInput.style.display = 'block';
    closeIcon.style.display = 'block';
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleAction = (item) => {
    dispatch(announcementSettingEnded(item));
  };

  const handleSelectAll = () => {
    let newSelected = _.cloneDeep(selected);

    if(newSelected.length === displayAnnouncements.length) {
      newSelected = [];
    } else {
      newSelected = [];
      displayAnnouncements.map((item) => {
        newSelected.push(item.id);
      })  
    }
    dispatch(announcementSelected(newSelected));
  }

  const handleStatusChange = async(item) => {
    try {
      await setAllOfficialAnnouncementDisplay({ 'display_mode': item });
      dispatch(handleHeaderFilter(currentFilter, page));
    } catch(err) {
      console.log('change all official announcements display mode err ----------', err);
    }
  };

  const handleSort = (type) => {
    let newOrder = null;

    if(!order || sortBy !== type) {
      newOrder = 'desc';
    } else if(order === 'desc') {
      newOrder = 'asc';
    } else {
      newOrder = null;
    }

    dispatch(handleHeaderFilter(currentFilter, page, type, newOrder));
  };

  const renderStartTimeSort = () => {
    if(sortBy === 'start_time' && order === 'desc') {
      return <span className='active'>▼</span>
    } else if(sortBy === 'start_time' && order === 'asc') {
      return <span className='active'>▲</span>
    } else {
      return <span>▼</span>
    }
  };

  const renderEndTimeSort = () => {
    if(sortBy === 'end_time' && order === 'desc') {
      return <span className='active'>▼</span>
    } else if(sortBy === 'end_time' && order === 'asc') {
      return <span className='active'>▲</span>
    } else {
      return <span>▼</span>
    }
  };

  const renderStatusSelectList = () => {
    return statusSelectList.map((item) => {
      return (
        <li key={item.action} onClick={() => handleStatusChange(item.action)}>
          全部{item.title}
        </li>
      );
    });
	};

  const renderAction = () => {
    let btnStyle = `announcementHeader_action ${selected.length !== 0 ? 'btn_effective' : 'btn_disabled'}`;
    let disabled = selected.length === 0 ? true : false;

    if(currentTab === 'shop' || (currentTab === 'official' && role === 'official')) {
      return (
        <div className={btnStyle}>
          <input
            type="checkbox"
            className='announcementHeader_action_check'
            checked={selected.length === displayAnnouncements.length}
            onChange={() => handleSelectAll()}
          />
          <div className='announcementHeader_action_right'>
            <div style={{ display: 'flex' }}>
              <button
                className='action_delete'
                disabled={disabled}
                onClick={() => handleAction('deleted')}
              >
                <span>刪除</span>
              </button>
              {
                currentFilter !== 'finished' &&
                <button
                  className='action_finished'
                  disabled={disabled}
                  onClick={() => handleAction('finished')}
                >
                  <span>設為結束</span>
                </button>
              }
            </div>
            <div className='announcementHeader_action_sort'>
              <div
                className='start'
                onClick={() => handleSort('start_time')}
              >
                { renderStartTimeSort() }
                開始
              </div>
              <div
                className='end'
                onClick={() => handleSort('end_time')}
              >
                { renderEndTimeSort() }
                結束
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='announcementHeader_action announcementHeader_action_small'>
          <div
            className="statusCardDropDown"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={`${currentFilter === 'finished' ? 'editDropdown_disabled' : ''} editDropdown`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled={currentFilter === 'finished'}
            >
              首頁彈出提醒
              <span className='dropdownIcon' />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>{renderStatusSelectList()}</ul>
            </div>
          </div>
          <div className='announcementHeader_action_sort'>
              <div
                className='start'
                onClick={() => handleSort('start_time')}
              >
                { renderStartTimeSort() }
                開始
              </div>
              <div
                className='end'
                onClick={() => handleSort('end_time')}
              >
                { renderEndTimeSort() }
                結束
              </div>
            </div>
        </div>
      )
    }
  };

  return (
    <div id='announcementHeader'>
      <div className='announcementHeader'>
        <div className='func-title fix-funcTitle'>
          <Link>
            <img src={require('../../../images/component/announcement_logo.svg')} alt="" />
            <h1>公告系統</h1>
          </Link>
        </div>
        <div className='actionBtn'>
          <button className="btn_search">
            <SearchOutlined
              className="search_large"
            />
            <SearchOutlined
              className="search_small"
              onClick={() => showSearch()}
            />
            <input
              type="text"
              className="searchInput"
              value={searchText}
              placeholder="請輸入關鍵字"
              onChange={(e) => handleSearch(e)}
            />
            <img
              className="clearSearch"
              src={require('../../../images/crm/cancel_icon.svg')}
              onClick={() => cancelSearch()}
              alt=''
            />
            <img
              className="clearSearch_mobile"
              src={require('../../../images/crm/cancel_icon.svg')}
              onClick={() => closeSearch()}
              alt=''
            />
          </button>
          <button
            onClick={() => addAnnouncement()}
            type="button"
            className="ms-btn-green btn_add"
          >
            <img
              className="crossImg_add"
              src={require('../../../images/icon_cross.svg')}
              alt=""
            />
            <span style={{ verticalAlign: 'middle' }}>
              新增公告
            </span>
          </button>
        </div>
      </div>

      <Tab />
      <Filter />
      { renderAction() }
    </div>
  )
};

export default AnnouncementHeader;