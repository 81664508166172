import React, { useState, useCallback } from 'react';
import moment from 'moment';

import useInterval from '../utils/useInterval';

export default ({ statusBtnRes, booking, statusEdit }) => {
	const today = moment().format('YYYY/MM/DD');
	const pre = moment(new Date(booking.booking_datetime)).add(-60, 'm').format('YYYY/MM/DD HH:mm');
	const tomorrow = moment(new Date(today)).add(1, 'd').format('YYYY/MM/DD HH:mm');
	const [ hideStatusBtn, setHideStatusBtn ] = useState(true);

	const findStatus = useCallback(
		() => {
			let currentTime = moment();

			if (currentTime.isBefore(new Date(pre))) {
				//當天時間>預約60分鐘
				// console.log('----預約>60分鐘----');
				setHideStatusBtn(true);
			} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
				//當天時間<=預約60分鐘
				// console.log('----預約前60分鐘----');
				setHideStatusBtn(false);
			}
		},
		[ pre, tomorrow ]
	);

	useInterval(() => {
		findStatus();
	}, 1000);

	const rednerBtn = useCallback(
		() => {
			if (!hideStatusBtn) {
				return (
					<div className="statusBtn">
						{statusBtnRes.stautsBtn}
						{
							statusBtnRes.stautsBtnTime !== null ? 
							<div style={{marginTop: '12px'}}>{statusBtnRes.stautsBtnTime}</div> : null
						}
						{statusBtnRes.statusEdit}
					</div>
				);
			} else {
				return null;
			}
		},
		[ hideStatusBtn, statusBtnRes ]
	);

	return rednerBtn();
};
