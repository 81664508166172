import React, { useContext } from 'react';
import Datetime from 'react-datetime';
import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

var yesterday = moment().subtract(1, 'day');
const Expire = ({ children }) => {
	return <section className="couponInfo">{React.Children.map(children, (child) => child)}</section>;
};

const ExpireTitle = () => {
	return <h2>活動時間</h2>;
};

const ExpireContent = () => {
	const { CouponAddState, CouponAddDispatch } = useContext(CouponAddStateContext);
	const { couponSetting, checkSetting, originStatus } = CouponAddState;
	const { endDateTime } = couponSetting;

	const { CouponState } = useContext(CouponStateContext);
    const { editType } = CouponState;

	const endErrorStyle = checkSetting && endDateTime === '' ? 'errorStyle' : '';

	const todayEndTime = moment().hours(23).minutes(59).seconds(0);

	const defaultDateTime = () => {
		if(endDateTime === '') {
			CouponAddDispatch({ type: 'setEndDateTime', endDateTime: todayEndTime });
		}
	}

	return (
		<div className={`expiredDate ${endErrorStyle}`}>
			<span className="title">從上架時間到</span>
			<div>
				<Datetime
					className="datetimePicker"
					value={endDateTime}
					onOpen={()=> defaultDateTime()}
          onChange={(datetime)=>{
            CouponAddDispatch({ type: 'setEndDateTime', endDateTime: datetime });
          }}
					dateFormat="YYYY-MM-DD"
					timeFormat="HH:mm"
					inputProps={{
						readOnly: true,
					}}
					isValidDate={(currentDate) => {
						return currentDate.isAfter(yesterday);
					}}
				/>
			</div>
		</div>
	);
};

Expire.Title = ExpireTitle;
Expire.Content = ExpireContent;

export default Expire;
