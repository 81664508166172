import { createContext } from 'react';
import _ from 'lodash';

export const couponInitState = {
	loading: true,
	mode: 'list',
	couponLists: [],
	editType: '',
	editId: null,
	activeCategory: 'all',
	showCancelPopup: false,
	showDeletePopup: false,
	showPausePopup: false,
	showPasswordPopup: false,
	showCancelPausePopup: false,
	showPreviewPopup: false,
	showSharePopup: false,
	showScanPopup: false,
	previewInfo:{},
	listInfo: {},
	couponName: '',
	couponId: null,
	newConfirmCode: [ '', '', '', '' ],
	sortType: 'createdAt',
	orderType: '0'
};

export const CouponStateContext = createContext(couponInitState);

export function CouponReducer(state, action) {
	const newState = _.cloneDeep(state);
	switch (action.type) {
		case 'setLoading':
			return Object.assign({}, newState, {
				loading: action.loading,
			});
		case 'getCouponList':
			return getMenuCoupon(state, action);
		case 'addCoupon':
			return Object.assign({}, newState, {
				mode: 'add',
				editType: 'add',
				editId: null
			});
		case 'closeAddCoupon':
			return Object.assign({}, newState, {
				mode: 'list',
				editType: '',
				editId: null
			});
		case 'setEditorType':
			return editType(state, action);
		case 'switchCategory':
			return Object.assign({}, newState, {
				activeCategory: action.category
			});
		case 'addNewCouponSetting':
			return Object.assign({}, newState, {
				mode: 'list',
				editType: ''
			});
		case 'setDeleteCoupon':
			return Object.assign({}, newState, {
				showDeletePopup: true,
				couponName: action.title,
				couponId: action.id
			});
		case 'cancelPopup':
			return Object.assign({}, newState, {
				showCancelPopup: false,
				showDeletePopup: false,
				showPausePopup: false,
				showPasswordPopup: false,
				showCancelPausePopup: false,
				showPreviewPopup: false,
				showSharePopup: false,
				showScanPopup: false,
				previewInfo:{},
				listInfo: {},
				couponName: '',
				couponId: null,
				newConfirmCode: [ '', '', '', '' ]
			});
		case 'confirmDeletePopup':
			return deleteCoupon(state);
		case 'setPauseCoupon':
			return Object.assign({}, newState, {
				showPausePopup: true,
				couponName: action.title,
				couponId: action.id
			});
		case 'confirmPausePopup':
			return pauseCoupon(state);
		case 'setPasswordCoupon':
			return Object.assign({}, newState, {
				showPasswordPopup: true,
				couponName: action.title,
				couponId: action.id
			});
		case 'setNewPassword':
			return Object.assign({}, newState, {
				newConfirmCode: action.confirmCode
			});
		case 'confirmPasswordPopup':
			return newConfirmCode(state);
		case 'setCancelPause':
			return Object.assign({}, newState, {
				showCancelPausePopup: true,
				couponId: action.id,
				couponName: action.title
			});
		case 'setPreview':
			return Object.assign({}, newState, {
				showPreviewPopup: true,
				previewInfo: action.previewInfo
			});
		case 'confirmCancelPausePopup':
			return cancelPause(state);
		case 'showSharepopup':
			return Object.assign({}, newState, {
				showSharePopup: action.listInfo.status === 'enable',
				listInfo: action.listInfo
			})
		case 'showScanPopup':
			return Object.assign({}, newState, {
				showScanPopup: true
			})
		default:
			return state;
	}
}

function editType(state, action) {
	const newState = _.cloneDeep(state);
	const { editType, editId } = action;

	newState['mode'] = editType === 'edit' ? 'add' : 'list';
	newState['editType'] = editType;
	newState['editId'] = editId;

	return newState;
}

function deleteCoupon(state) {
	let newState = _.cloneDeep(state);
	const { couponLists, couponId } = newState;

	const findDeleteIndex = couponLists.map((list) => list.id).indexOf(couponId);

	couponLists.splice(findDeleteIndex, 1);

	newState['showDeletePopup'] = false;
	newState['couponName'] = '';
	newState['couponId'] = null;

	return newState;
}

function pauseCoupon(state) {
	let newState = _.cloneDeep(state);
	newState['showPausePopup'] = false;
	newState['couponId'] = null;
	newState['couponName'] = '';

	return newState;
}

function newConfirmCode(state) {
	let newState = _.cloneDeep(state);

	newState['showPasswordPopup'] = false;
	newState['newConfirmCode'] = [ '', '', '', '' ];

	return newState;
}

function cancelPause(state) {
	let newState = _.cloneDeep(state);
	newState['showCancelPausePopup'] = false;
	newState['couponId'] = null;
	newState['couponName'] = '';

	return newState;
}

function getMenuCoupon(state, action) {
	let newState = _.cloneDeep(state);
	const { list } = action;

	newState['couponLists'] = _.cloneDeep(list);
	newState['loading'] = false;


	return newState;
}
