import React from 'react';
import './bsTest.scss';


const weekNumTrans = [ '日', '一', '二', '三', '四', '五', '六' ];
const type_t = { 2: '依固定區間', 3: '依自訂時段' };
const number = [ '〇', '一', '二', '三', '四', '五', '六', '七', '八', '九' ];

const BsTestBlock = ({ idx, weekSetting, openEditor, deleteOpening, serviceTimeOptionEnabled }) => {
	const renderWeekDays = () => {
		let weekDays = [];
		if (weekSetting.week_days) {
			weekDays = weekSetting.week_days
				.map((day) => {
					return `星期${weekNumTrans[day]}`;
				})
				.join('、');
		}
		return weekDays;
	};

	const renderOpeningHours = () => {
		if (weekSetting.setting_temp.enable === 3) {
			return weekSetting.setting_temp.setting3.join('、');
		} else if (weekSetting.setting_temp.enable === 2) {
			return weekSetting.setting_temp.setting2.map((r) => `${r[0]}~${r[1]}`).join('、');
		}
	};

	const renderDiningTime = () => {
		let diningTime = [];
		let diningTime_min = 0;
		let diningTime_max = 0;
		
		if(serviceTimeOptionEnabled) {
			if(weekSetting.service_time_setting[0].mode === 'user_setting') {
				diningTime = weekSetting.service_time_setting[0].user_setting.service_time_setting;
	
				if(weekSetting.service_time_setting[0].user_setting.show_name) {
					return (
						<div>
							<div className="text">依自訂時段</div>
							<div className="diningTime_userSetting">
								{diningTime.map((t, index) => {
									return (
										<div key={index}>
											<span className="text">{t.name} ({Math.floor(parseInt(t.service_time, 10) / 60)} 小時 {Math.floor(parseInt(t.service_time, 10) % 60)} 分鐘)
											{
												index !== diningTime.length - 1 ? (<span>、</span>) : null
											}
											</span>
										</div>
									)
								})}
							</div>
						</div>
					)
				} else {
					return (
						<div>
							<div className="text">依自訂時段</div>
							<div className="diningTime_userSetting">
								{diningTime.map((t, index) => {
									return (
										<span key={index}>
											<span className="text">{Math.floor(parseInt(t.service_time, 10) / 60)} 小時 {Math.floor(parseInt(t.service_time, 10) % 60)} 分鐘
											{
												index !== diningTime.length - 1 ? (<span>、</span>) : null
											}
											</span>
										</span>
									)
								})}
							</div>
						</div>
					)
				}
			} else if (weekSetting.service_time_setting[0].mode === 'fixed') {
				diningTime_min = weekSetting.service_time_setting[0].fixed.min;
				diningTime_max = weekSetting.service_time_setting[0].fixed.max;
	
				return (
					<div>
						<div className="text">依固定區間</div>
						<div className="text">從最短 {Math.floor(parseInt(diningTime_min, 10) / 60)} 小時 {Math.floor(parseInt(diningTime_min, 10) % 60)} 分鐘</div>
						<div className="text">到最長 {Math.floor(parseInt(diningTime_max, 10) / 60)} 小時 {Math.floor(parseInt(diningTime_max, 10) % 60)} 分鐘</div>
					</div>
				)
			}
		} else {
			let diningTime = weekSetting.dining_time;

			return (
				<div className="text">{Math.floor(parseInt(diningTime, 10) / 60)} 小時 {Math.floor(parseInt(diningTime, 10) % 60)} 分鐘</div>
			)
		}
	};

	const renderDiningTimeShowName = () => {
		let show_name = ''

		if (weekSetting.service_time_setting[0].user_setting.show_name) {
			show_name = '開啟';
		} else {
			show_name = '關閉';
		}

		if(serviceTimeOptionEnabled) {
			if (weekSetting.service_time_setting[0].mode === 'user_setting') {
				return (
					<div className="settingTblRow">
						<div className="header" data-toggle="tooltip" data-placement="bottom" title="開啟時，您可以設定服務時間名稱並顯示於前台供顧客選取">
							服務時間選項名稱
							<i className="fa fa-question-circle-o" aria-hidden="true" />
						</div>
						<div className="text">{show_name}</div>
					</div>
				)
			}
		}
	}

	const renderDiningTimeInterval = () => {
		let interval = 30;

		if (weekSetting.service_time_setting[0].fixed.unit) {
			interval = weekSetting.service_time_setting[0].fixed.unit;
		}

		if(serviceTimeOptionEnabled) {
			if (weekSetting.service_time_setting[0].mode === 'fixed') {
				return (
					<div className="settingTblRow">
						<div className="header" data-toggle="tooltip" data-placement="bottom" title="顧客能在此範圍的時間選取服務時間">
							服務時段區間
							<i className="fa fa-question-circle-o" aria-hidden="true" />
						</div>
						<div className="text">{interval}分鐘</div>
					</div>
				);
			}
		}
	};

	const renderBookingTimeInterval = () => {
		let interval = 30;

		if (weekSetting.booking_time_interval) {
			interval = weekSetting.booking_time_interval;
		}

		if (weekSetting.setting_temp.enable === 2) {
			return (
				<div className="settingTblRow">
					<div className="header" data-toggle="tooltip" data-placement="bottom" title="顧客可預約的時間間隔">
						預約時段區間
						<i className="fa fa-question-circle-o" aria-hidden="true" />
					</div>
					<div className="text">{interval}分鐘</div>
				</div>
			);
		}
	};

	return (
		<div className="rangeBlock">
			<div className="rangeTitle">
				<img src={require('../../../images/clock.svg')} alt="Time" />
				<h4>開放時段{number[idx + 1]}</h4>
				<div>
					<button className="msLink" onClick={() => openEditor('edit', idx)}>
						編輯
					</button>
					<button className="msLink" onClick={() => deleteOpening(idx)}>
						刪除
					</button>
				</div>
			</div>
			<div className="settingTbl">
				<div className="settingTblRow">
					<div className="header">開放營業日</div>
					<div className="text">{renderWeekDays()}</div>
				</div>
				<div className="settingTblRow">
					<div className="header">開放時段區間</div>
					<div className="text">
						{type_t[weekSetting.setting_temp.enable]}
						<br />
						{renderOpeningHours()}
					</div>
				</div>
				{renderBookingTimeInterval()}
				<div className="settingTblRow">
					<div className="header">
						<span data-toggle="tooltip" data-placement="bottom" title="預約的顧客用餐多久後，該座位可再次開放下一組預約">
							服務時間
							<i className="fa fa-question-circle-o" aria-hidden="true" />
						</span>
					</div>
					{renderDiningTime()}
				</div>
				{renderDiningTimeInterval()}
				{renderDiningTimeShowName()}
				<div className="settingTblRow">
					<div className="header">
						<span data-toggle="tooltip" data-placement="bottom" title="同時間最大接客量，超過則不再開放線上接客，自建預約不在此限">
							同時預約人數
							<i className="fa fa-question-circle-o" aria-hidden="true" />
						</span>
					</div>
					<div className="text">
						{weekSetting.max_people_at_a_time === '' ||
						weekSetting.max_people_at_a_time === null ||
						weekSetting.max_people_at_a_time === 0 ? (
							'無限制'
						) : (
							weekSetting.max_people_at_a_time + ' 人'
						)}
					</div>
				</div>
			</div>

			<hr />
		</div>
	);
};

export default BsTestBlock;
