import React, { useState } from 'react';

const timeKeyboardCell = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, '重設', 0, '確認' ];
let count = 0;
let KEYBOARD_RESET = '重設',
	KEYBOARD_CONFIRM = '確認';

const TimeKeyboard = ({ timePickerSetting, closeTimeCell, timeCellSubmit }) => {
	const [time, setTime]=useState(timePickerSetting)
	
	const handleClose = () => {
		count = 0;
		closeTimeCell();
	};


	const handleTimeClick = (keyboard) => {
		let newTime = time.split('');
		if (count === 0) {
			newTime = [ '-', '-', ':', '-', '-' ];
			newTime[count] = keyboard;
			count = 1;
		} else if (count === 1) {
			newTime[count] = keyboard;
			count = 3;
		} else if (count === 3) {
			newTime[count] = keyboard;
			count = 4;
		} else if (count === 4) {
			newTime[count] = keyboard;
			count = 0;
		}

		setTime(newTime.join(''));
	};

	const handleReSetTime = () => {
		const reset = [ '-', '-', ':', '-', '-' ];
		count = 0;
		setTime(reset.join(''));
	};

	const handleSubmit = () => {
		let check = true;
		let hour = parseInt(time.substr(0, 2), 10),
			min = parseInt(time.substr(3), 10);
		let multiple_five = min % 5;

		if (count !== 0 || time[0] === '-') {
			window.app.alert.setMessage('請輸入完整時間', 'tip');
			check = false;
			return;
		}

		if (hour > 23) {
			window.app.alert.setMessage('時間格式錯誤', 'error');
			check = false;
			return;
		} else if (min > 59) {
			window.app.alert.setMessage('時間格式錯誤', 'error');
			check = false;
			return;
		} else if (min <= 59 && multiple_five !== 0) {
			window.app.alert.setMessage('須以五分鐘為一單位', 'error');
			check = false;
			return;
		}

		if (check) {
            timeCellSubmit(time);
		}
	};

	const renderTimeKeyboardCell = () => {
		return timeKeyboardCell.map((keyboard, id) => {
			if (keyboard === KEYBOARD_RESET) {
				return (
					<div key={id} className="tKeyborad-body-cell" onClick={() => handleReSetTime()}>
						{keyboard}
					</div>
				);
			} else if (keyboard === KEYBOARD_CONFIRM) {
				return (
					<div key={id} className="tKeyborad-body-cell" onClick={() => handleSubmit()}>
						{keyboard}
					</div>
				);
			} else {
				return (
					<div key={id} className="tKeyborad-body-cell" onClick={() => handleTimeClick(keyboard)}>
						{keyboard}
					</div>
				);
			}
		});
	};

	return (
		<div className="n-timeCellWrapper">
			<div className="n-timeCellLayout" />

			<div className="tKeyborad">
				<div className="tKeyborad-header">
					{time}
					<span className="tKeyboard-del" onClick={() => handleClose()} />
				</div>
				<div className="tKeyborad-body">{renderTimeKeyboardCell()}</div>
			</div>
		</div>
	);
};

export default TimeKeyboard;
