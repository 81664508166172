import React, { Fragment, useCallback, useContext } from 'react';
import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';

const MenuCouponUploadImg = ({ children }) => {
	return <section className="uploadImgSection">{React.Children.map(children, (child) => child)}</section>;
};

const MenuCouponUploadImgTitle = () => {
	return <h2>封面照片</h2>;
};

const MenuCouponLandscape = ({ handleUploadImg, renderEditCropIcon }) => {
	const { CouponAddState } = useContext(CouponAddStateContext);
	const { couponSetting, checkSetting } = CouponAddState;
	const { coverImgFile, coverURL } = couponSetting;


	const renderLandscape = () => {
		if (coverImgFile === null && coverURL === '') {
			return (
				<Fragment>
					<input
						type="file"
						accept="image/x-png,image/gif,image/jpeg"
						id="upload_landscape"
						className="file-upload__input"
						onChange={(e) => handleUploadImg(e, 'setLandscapeImg')}
					/>
					<label htmlFor="upload_landscape" className="add_landscape" />
				</Fragment>
			);
		}

		if (coverURL !== '') {
			return (
				<Fragment>
					{renderEditCropIcon('landscape', 'setDeleteLandscape')}

					<figure>
						<img src={coverURL} alt="" />
					</figure>
				</Fragment>
			);
		}
	};

	const errorStyle = checkSetting && coverImgFile === null && coverURL === '' ? 'errorStyle' : '';
	return <div className={`landscape ${errorStyle}`}>{renderLandscape()}</div>;
};

const MenuCouponPortrait = ({ handleUploadImg, renderEditCropIcon }) => {
	const { CouponAddState } = useContext(CouponAddStateContext);
	const { couponSetting, checkSetting } = CouponAddState;
	const { thumbnailImgFile, thumbnailURL } = couponSetting;

	const renderPortrait = () => {
		if (thumbnailImgFile === null && thumbnailURL === '') {
			return (
				<Fragment>
					<input
						type="file"
						accept="image/*"
						id="upload_portrait"
						className="file-upload__input"
						onChange={(e) => handleUploadImg(e, 'setPortraitImg')}
					/>
					<label htmlFor="upload_portrait" className="add_landscape" />
				</Fragment>
			);
		}

		if (thumbnailURL !== '') {
			return (
				<Fragment>
					{renderEditCropIcon('portrait', 'setDeletePortrait')}

					<figure>
						<img src={thumbnailURL} alt="" />
					</figure>
				</Fragment>
			);
		}
	};

	const errorStyle = checkSetting && thumbnailImgFile === null && thumbnailURL === '' ? 'errorStyle' : '';
	return <div className={`portrait ${errorStyle}`}>{renderPortrait()}</div>;
};

const UploadImgDescription = () => {
	return (
		<div className="uploadImgDescription">
			<p>橫幅最佳寬高比例為5:3，直幅最佳寬高比例為19:30</p>
		</div>
	);
};

const UploadImgHOC = (Component) =>
	function Wrap() {
		const { CouponAddDispatch } = useContext(CouponAddStateContext);

		const toCrop = useCallback(
			(imgType) => {
				CouponAddDispatch({ type: 'setCropImg', imgType });
				CouponAddDispatch({ type: 'setShowCropper', showCropper: true });
			},
			[ CouponAddDispatch ]
		);

		const handleUploadImg = useCallback(
			(e, actionType) => {
				e.preventDefault();

				const reader = new FileReader();
				const file = e.target.files[0];

				if (file.size > 2097152) {
					window.app.alert.setMessage('檔案大小不可超過 2MB', 'error');
				} else {
					reader.onloadend = (e) => {
						// const base64Img = e.target.result;
						const blob = new Blob([ file ], { type: 'image/png' });
						const objURL = window.URL.createObjectURL(file);

						if (actionType === 'setLandscapeImg') {
							CouponAddDispatch({
								type: 'setLandscapeImg',
								coverImgFile: blob,
								coverURL: objURL
							});

							toCrop('landscape');
						}

						if (actionType === 'setPortraitImg') {
							CouponAddDispatch({
								type: 'setPortraitImg',
								thumbnailImgFile: blob,
								thumbnailURL: objURL
							});

							toCrop('portrait');
						}
					};
					reader.readAsDataURL(file);
				}
			},
			[ CouponAddDispatch, toCrop ]
		);

		const removeImg = useCallback(
			(actionType) => {
				CouponAddDispatch({ type: actionType });
			},
			[ CouponAddDispatch ]
		);

		const renderEditCropIcon = useCallback(
			(imgType, setImgType) => {
				return (
					<Fragment>
						<button className="editCrop" onClick={() => toCrop(imgType)} />
						<button className="removeImg" onClick={() => removeImg(setImgType)} />
					</Fragment>
				);
			},
			[ removeImg, toCrop ]
		);

		return <Component handleUploadImg={handleUploadImg} renderEditCropIcon={renderEditCropIcon} />;
	};

MenuCouponUploadImg.Title = MenuCouponUploadImgTitle;
MenuCouponUploadImg.Landscape = UploadImgHOC(MenuCouponLandscape);
MenuCouponUploadImg.Portrait = UploadImgHOC(MenuCouponPortrait);
MenuCouponUploadImg.Description = UploadImgDescription;

export default MenuCouponUploadImg;
