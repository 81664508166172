import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import '../../../sass/menu_coupon_popup.scss';
import MenuCouponApi from '../MenuCouponApi';

const menuCouponAPI = new MenuCouponApi();


const PauseCouponPopup = () => {
	const auth = useSelector((state) => state.auth);
	const { name } = auth.shop;
	const { CouponState, CouponDispatch } = useContext(CouponStateContext);
	const { couponName, couponLists, couponId } = CouponState;


	const cancelPaused = async()=>{
		const findIndex = couponLists.map((list) => list.id).indexOf(couponId);
		const couponSetting = couponLists[findIndex];
		const fd = new FormData()

		fd.append("shopUserId", couponSetting.shopUserId)
		fd.append("id", couponSetting.id)
		fd.append("status", 'enable')
	
		try {
			await menuCouponAPI.updateCoupon(fd);
			const list = await menuCouponAPI.getCoupon(name);
			CouponDispatch({ type: 'confirmCancelPausePopup' });
			CouponDispatch({ type: 'getCouponList', list });
	
		} catch (err) {
			console.log('updateCoupon err-----', err);
		}
	}

	const cancelPopup = () => {
		CouponDispatch({ type: 'cancelPopup' });
	};


	return (
		<div className="menuCouponPopupCard">
			<div className="menuCouponPopupCard__header">
				<h2>取消暫停優惠券</h2>
			</div>
			<div className="menuCouponPopupCard__body">
				<p>確定要取消優惠券嗎？</p>
				<p>優惠券：{couponName}</p>
			</div>
			<div className="menuCouponPopupCard__footer">
				<div className="footrBtnWrap">
					<button className="grayThemeBtn" onClick={() => cancelPopup()}>
						取消
					</button>
					<button className="greenThemeBtn" onClick={() => cancelPaused()}>
						確認
					</button>
				</div>
			</div>
		</div>
	);
};

export default PauseCouponPopup;
