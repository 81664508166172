import React from 'react';
import { Link } from 'react-router';
import PageNav from './PageNav';
import PageFooter from './PageFooter';

import '../../sass/pageTOSandPrivacyPolicy.scss';

const PageTOS = () => {
  return (
    <div className='pageTOS'>
      <PageNav />
      
      <div className='page_container'>
        <h1>使用者條款</h1>
        <h2>MENU店+ 找活股份有限公司使用者條款</h2>
        <section>
          <p>當您直接或間接使用找活股份有限公司（以下簡稱「MENU店+」）之網站或服務時，即表示您已閱讀、瞭解、同意接受並遵守下列使用者條款 (以下簡稱「本使用者條款」) 。MENU店+ 保有不定期更新、修改與補充使用者條款之權利。您應於使用MENU店+ 之網站或服務前仔細閱讀本使用者條款之全部條款，並定期檢閱MENU店+ 網站有關使用者條款之更新公告。若您不同意本使用者條款之內容或不同意MENU店+ 得隨時修改之，或者您所屬的國家或地域排除本使用者條款內容之全部或一部時，請您立即停止使用MENU店+ 之網站及服務。</p>
          <p>MENU店+ 提供顧客關係管理服務，包括但不限於訂位平台串連、自動化訂位確認、桌況管理、客戶服務、客戶資訊統計與營運管理及行銷等（詳情請見<a className='link' href="https://menushop.tw" target="_blank" rel="noopener noreferrer">https://menushop.tw</a>下稱「服務」）。</p>
          <p>
            一經使用MENU店+ 網站及服務，即表示您聲明並保證：
            <ul className='tos_list'>
              <li>您是具備完全權利能力和行為能力的自然人、法人或其他組織，並已接受本使用者條款，且依法有權為之。若您不具備前述主體資格，則您及您的法定代理人應承擔因此而導致的一切後果，且MENU店+ 有權向您及您的法定代理人索償（包含但不限於律師費用等）。</li>
              <li>依照相關法規，您有權使用MENU店+ 之網站及服務；在法規許可範圍內，您同意MENU店+ 得為遵守相關法律或依相關法律之強制規定，使用您的資料並經營MENU店+ 之網站及服務。</li>
              <li>您不為他人或為任何不法目的使用MENU店+ 之網站及服務，亦不得使用MENU店+ 之網站及服務進行任何不正當或有損MENU店+ 商譽或信譽之活動。</li>
              <li>您將遵守中華民國相關法規或您實際使用MENU店+ 服務之國家／地區之司法管轄，並不得利用MENU店+ 之網站及服務從事任何侵害他人或法人組織之權益之行為，如<a className='link' href="https://www.un.org/zh/about-us/universal-declaration-of-human-rights" target="_blank" rel="noopener noreferrer">聯合國世界人權宣言所定義</a>；</li>
              <li>如違反任何應適用之法規，您將自行負擔相關法律責任；您同意MENU店+ 在發生特定情事（包含但不限於下述者）時，得立即終止您對MENU店+ 網站及服務之使用權利，且如因下列情事而致MENU店+ 受有任何損失或損害，MENU店+ 並得向您請求損害賠償（包含但不限於訴訟費用、律師費用及商譽損失）：
                <ul className='tos_list'>
                  <li>您違反本使用者條款。</li>
                  <li>試圖自己或協助他人未經授權存取本網站。</li>
                  <li>您使用或錯誤使用 MENU店+ 之網站或服務。</li>
                  <li>以機器人或其他技術方法，進行變更、刪除、破壞MENU店+ 網站之資料內容或設備，或是對本網站之經營造成不良影響。</li>
                  <li>藉由MENU店+ 網站設計存在之漏洞，或以越過、破壞MENU店+ 網站安全維護機制等其他技術方法，變更、刪除或蒐集他人之個人資料。</li>
                  <li>您違反任何相關法規。</li>
                </ul>
              </li>
            </ul>
          </p>
          <p>倘在您使用MENU店+ 之網站或服務時有任何可疑活動，MENU店+ 有權隨時取消或終止您使用網站及服務之權利。您有義務遵守上述要求，或接受使用網站及服務權利之暫停或終止。若您認為有違上述義務之情事，請寄送電子郵件至：support@findlife.com.tw 通知MENU店+。</p>
        </section>
        <section>
          <h4>有限授權</h4>
          <p>MENU店+ 授權您存取及使用MENU店+ 之網站及服務，該授權為有限、非專屬及不可移轉之權利，惟MENU店+ 有權將所有權利、利益或義務移轉予他人，本使用者條款應對MENU店+ 之繼受人或受讓人發生全部效力。</p>
        </section>
        <section>
          <h4>使用者行為規範</h4>
          <p>
            您僅可基於MENU店+ 服務預定之目的，或其他MENU店+ 明文允許之目的存取及使用本網站，除基於前述目的外，任何基於其他目的之使用皆應禁止。您於存取或使用 MENU店+ 網站時，應同意及保證不為下列事項：
            <ul className='tos_list'>
              <li>侵入本網站，或對本網站進行反組譯、還原工程、解譯、解碼或以其他方式取得原始碼。</li>
              <li>就MENU店+ 網站進行修改、改寫、改善、強化、翻譯或製作衍生著作。</li>
              <li>使用本網站時違犯任何相關法規。</li>
              <li>移除、變更或隱蔽MENU店+ 或其供應商、合作夥伴、授權人之資訊。</li>
              <li>任何法人或個人未經MENU店+ 之程序化授權，不得手動／自動建立訂位並轉賣或轉讓給任何用戶或訂位服務商。MENU店+ 有權保留或取消因上述目的產生之訂位，概不退費或提供補償。</li>
              <li>利用網路或其他有致消費者混淆之方式自行對外提供MENU店+ 之服務。</li>
              <li>利用MENU店+ 網站寄發自動問卷或商業廣告。</li>
              <li>未經授權使用 MENU店+ 之專有資訊或智慧財產權。</li>
              <li>獲取或試圖未經授權存取或使用MENU店+ 網站及服務之內容。</li>
              <li>於MENU店+ 網站使用或試圖使用網路蜘蛛。</li>
              <li>造成基礎設備不成比例的大流量。</li>
              <li>上傳包含病毒、木馬程式或蠕蟲之任何資料。</li>
            </ul>
          </p>
        </section>
        <section>
          <h4>智慧財產權保護</h4>
          <p>MENU店+ 網站所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由 MENU店+ 或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須事前依法取得MENU店+ 及相關權利人的書面同意。如有違反前述規定，您應對 MENU店+ 及其所受影響之客戶負損害賠償責任（包含但不限於律師費用等）。</p>
        </section>
        <section>
          <h4>隱私權保護</h4>
          <p>
            保護您的隱私權對MENU店+ 至關重要，MENU店+ 已針對相關事項制定隱私權政策，詳情請參見：	  <Link className='link' to="/privacyPolicy">
              隱私權條款
            </Link>
            。
          </p>
        </section>
        <section>
          <h4>免責聲明</h4>
          <p>您亦同意，MENU店+ 得依其單方裁量，隨時通知終止或停止您對MENU店+ 網站之存取及使用，就MENU店+ 單方終止或停止使用者存取或使用MENU店+ 之網站及服務之行為，而對使用者或任何第三方所生的損失及損害，MENU店+ 概不負責。</p>
          <p>MENU店+ 網站係依現況現狀提供您使用，MENU店+ 並保留隨時修改各項服務功能之一或全部之權利。MENU店+ 對於MENU店+ 之網站及服務內容不作任何明示、暗示或法定的擔保、主張或聲明，包括但不限於對於品質、效能、無侵權、適售性或特定用途適用性之擔保，或因交易過程、按慣例或行業常規而衍生之擔保。在不受限上述條款之前提下，且在法律容許的最大限度內，MENU店+ 不保證提供MENU店+ 之網站、服務，以及其所含功能可被存取、不會中斷、及時提供、安全可靠、正確、完整或無錯誤，亦不保證瑕疵（如有）會被更正。</p>
          <p>MENU店+ 不保證MENU店+ 網站及MENU店+ 網站伺服器完全沒有任何病毒、蠕蟲、軟體鎖、致死裝置、木馬、路由、陷阱門、定時炸彈或任何其他有害之程式碼、指令、程式或元件，MENU店+ 對於您使用或無法使用MENU店+ 之網站與服務而造成之損害及獲損失均不負賠償責任，亦不負通知義務。</p>
          <p>MENU店+ 亦不保證MENU店+ 之網路及服務不會因任何第三方之行為（包括但不限於網路攻擊）而受到不利影響。MENU店+ 不擔保預見、防免、減輕該等第三方之行為，或對該等第三方之行為採取適當措施，如任何人該等第三方之行為授有損害或損失， MENU店+ 概不負責。</p>
          <p>
            您瞭解MENU店+ 未對本網站上內容加以任何事先審查，對使用者之使用行為亦無法進行全面控制，您使用任何MENU店+ 網站或服務內容時，包括內容之正確性、完整性或實用性時，您同意將自行加以判斷並承擔所有風險。但MENU店+ 有權自行判斷是否移除違反本使用者條款或其他引起反感之任何內容。您瞭解並同意，MENU店+ 基於以下目的之合理必要範圍內，認定必須將網站上內容加以保存或揭露予政府機關、司法警察、未成年人之監護人及相關會員時，得逕自加以保存及揭露：
            <ul className='tos_list'>
              <li>為遵守法令或政府機關之要求。</li>
              <li>為提供本網站服務所必須者。</li>
              <li>為防止他人權益之重大危害而有必要者。</li>
              <li>為免除使用者及公眾之生命、身體、自由、權利、財產上之急迫危險者。</li>
              <li>其他MENU店+ 認為有必要的情況。</li>
            </ul>
          </p>
          <p>MENU店+ 有權於任何時間暫時或永久修改，或終止MENU店+ 之網路或服務（或其任何部分），而無論其通知與否，您同意對於MENU店+ 網站及服務所作的任何修改、暫停或終止，MENU店+ 對您和任何第三人均無需承擔任何責任。</p>
          <p>您承認在法律容許的最大限度內，您個人應承擔使用MENU店+ 網站及MENU店+ 服務或其效能之全部相關風險。</p>
        </section>
        <section>
          <h4>第三方內容與網站</h4>
          <p>MENU店＋ 網站可能會提供第三方內容或連結至其他外部連結，您可能因此連結其他業者經營或銷售之網站，惟不代表MENU店+ 與該等業者有任何關係。MENU店+ 不控制且不為第三方之內容與網站背書或保證其內容之正確性與完整性，亦不對第三方網站的內容、隱私政策與行為承擔責任。如您因使用或依賴任何此類網站或資源發布目的或經由此類網站或資源獲得的任何內容、商品或服務所產生的任何損害或損失，概由您自行負責，MENU店+ 不負任何直接或間接之責任。MENU店+ 無義務確保該些連結將使您進入完全無風險之網站，MENU店+ 網站外之其他網站可能涉及法律或其他實際風險，您應自行採取預防措施，以確保您選擇使用之網站合法且無惡意程式。</p>
        </section>
        <section>
          <h4>責任限制</h4>
          <p>於法律允許之最大範圍內，MENU店+ 將不負責任何MENU店+ 網站及服務所造成的損害、利益損失、收入損失、生意損失、機會損失、資料遺失、間接或是因果損失等。並且MENU店+ 對任何單一使用者之責任，將以MENU店+ 對該會員所收取的費用為上限。</p>
        </section>
        <section>
          <h4>賠償</h4>
          <p>於法律允許之最大範圍內，對於因您使用MENU店+ 網站或服務，或因您違反本使用者條款，致使MENU店+ 遭受或發生之任何訴訟、賠償責任、訴訟費用、賠償請求、直接或間接之損失或損害等，您同意全額（包含律師費用）賠償MENU店+ 、其董事、股東、經理人、員工、代理人、使用人、關係企業及合作廠商。</p>
        </section>
        <section>
          <h4>不可抗力</h4>
          <p>基於不可抗力、法律或政策的變化或其他非MENU店+ 所能控制的因素，致MENU店+ 不能履行本網站服務時，MENU店+ 可隨時停止提供相關的服務。</p>
        </section>
        <section>
          <h4>使用者條款之修訂</h4>
          <p>MENU店+ 有權於任何時間修改或變更本使用者條款之內容，或暫停、終止MENU店+ 網站及服務，且毋須另行通知。本使用者條款一旦發生變動，MENU店+ 將在本網站上公布其修改內容，您應隨時注意該等修改或變更。而MENU店+ 修改後之本使用者條款一旦於本網站上公布，即有效取代先前與您之使用者條款。您於本使用者條款之任何修改或變更後繼續使用MENU店+ 網站及服務，即視為您已閱讀、瞭解並同意接受該等前述修改或變更。</p>
        </section>
        <section>
          <h4>通知</h4>
          <p>本使用者條款及任何其他的協議、告示或其他關於您使用MENU店+ 網站及服務之通知，您同意MENU店+ 得使用電子方式通知您，例如：電子郵件、於MENU店+ 網站或合作網站上公布、無線通訊裝置通知等。您同意MENU店+ 以電子方式發出前述通知之日即視為通知已送達。因資訊傳輸等原因導致您未在前述通知發出當日收到該等通知者， MENU店+ 不負擔任何責任。</p>
        </section>
        <section>
          <h4>準據法</h4>
          <p>本使用者條款之效力、解釋、適用及爭議解決，均應以中華民國法律為準據法，並依該法解釋。凡雙方因使用MENU店+ 網站、服務或違反本使用者條款所引起之一切糾紛、爭議或歧見，雙方同意先本誠信原則協商之，協商不成而涉訟時，雙方同意以台北地方法院為第一審管轄法院執行具約束力之仲裁。</p>
          <p>您與MENU店+ 僅得以自己名義對他方提起主張與請求，不可為任何團體訴訟、代表訴訟、合併程序或包含多位聲請人之程序的當事人。</p>
        </section>
        <section>
          <h4>條文可分割性</h4>
          <p>如本使用者條款之任何條款被認定為違法、無效或不可執行，該等條款應視為具可分割性，不影響其他條款之有效性及可執行性。</p>
        </section>
        <section>
          <h4>棄權</h4>
          <p>如MENU店+ 遲延或未執行依本使用者條款所擁有權利時，不構成對該等權利之棄權。</p>
        </section>
        <section>
          <h4>完整性</h4>
          <p>本使用者條款及MENU店+ 之任何政策或執行規則構成您與MENU店+ 間之完整合約，並取代雙方先前之任何形式的合意、溝通或建議（包括但不限於舊版使用者條款）。</p>
        </section>
        <div className='page_contact'>若您對上述使用者條款有任何疑問或意見，或您獲悉任何相關違規行為，請洽詢MENU店+：support@findlife.com.tw。</div>
      </div>

      <PageFooter />
    </div>
  )
}

export default PageTOS;