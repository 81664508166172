import React, { Fragment, useContext } from 'react';
import { browserHistory } from 'react-router';

import Empty from './Empty';
import BookingCard from './BookingCard';

import { CommomSettingContext } from '../new_module/NewModuleReducer/CommomReuducer';
import { ListStateContext } from '../new_module/NewModuleReducer/ListReducer';

export default ({ searchType, bookingSearchRes, reset }) => {
	const { CommomSettingDispatch } = useContext(CommomSettingContext);
	const { ListStateDispatch } = useContext(ListStateContext);

	const bookingLength = Object.values(bookingSearchRes).reduce((res, booking) => (res += booking.length), 0);

	const toList = (toDate, booking) => {
		const { active_code: activeCode, status } = booking;
		const { query: { date, mode } } = browserHistory.getCurrentLocation();
		const IsToday = date === toDate;

		reset();

		if (mode === 'list' && IsToday && searchType === 'booking') {
			document.getElementById(status).click();
			
			setTimeout(() => {
				const $booking = document.getElementById(activeCode);
				$booking.scrollIntoView();
				$booking.classList.add('active_scroll');
			}, 0);

			setTimeout(() => {
				const $booking = document.getElementById(activeCode);
				$booking.classList.remove('active_scroll');
			}, 1500);
		}

		if (mode === 'calendar' && searchType === 'booking') {
			browserHistory.push({
				pathname: '/dashboard/bookings',
				query: {
					date: toDate,
					mode: 'list'
				},
				state: {
					activeCode,
					status
				}
			});
		} else if (mode === 'list' && !IsToday && searchType === 'booking') {
			browserHistory.push({
				pathname: '/dashboard/bookings',
				query: {
					date: toDate,
					mode: 'list'
				},
				state: {
					activeCode,
					status
				}
			});
		}

		if (IsToday && searchType === 'newModule') {
			ListStateDispatch({ type: 'setActiveSystem', systemTab: 'booking' });
			ListStateDispatch({ type: 'searchBooking', booking });
		}

		if (!IsToday && searchType === 'newModule') {
			CommomSettingDispatch({ type: 'changeUrlDate', queryDate: toDate });
			ListStateDispatch({ type: 'setActiveSystem', systemTab: 'booking' });
			ListStateDispatch({ type: 'searchBooking', booking });
		}
	};

	if (bookingLength === 0) return <Empty />;
	return (
		<Fragment>
			<div className="bookingSection todayBooking">
				<h2>今日預約</h2>
				{bookingSearchRes['today'].length === 0 ? (
					<Empty />
				) : (
					bookingSearchRes['today'].map((booking) => (
						<BookingCard key={booking.id} booking={booking} toList={toList} />
					))
				)}
			</div>
			<div className="bookingSection incomingBooking">
				<h2>未來預約</h2>
				{bookingSearchRes['future'].length === 0 ? (
					<Empty />
				) : (
					bookingSearchRes['future'].map((booking) => (
						<BookingCard key={booking.id} booking={booking} toList={toList} />
					))
				)}
			</div>
			<div className="bookingSection pastBooking">
				<h2>過去預約</h2>
				{bookingSearchRes['past'].length === 0 ? (
					<Empty />
				) : (
					bookingSearchRes['past'].map((booking) => (
						<BookingCard key={booking.id} booking={booking} toList={toList} />
					))
				)}
			</div>
		</Fragment>
	);
};
