import React, { useState, useCallback } from 'react';
import { Label, Tag, Text, Rect, Group, Shape } from 'react-konva';
import moment from 'moment';

import useInterval from '../utils/useInterval';
import { time } from '../../new_module/utils/data';
import { tableStatus } from './utils/tableStatus';

const $tableStatusEmptyColor = 'rgb(255, 255, 255)';
const $selectedColor = '#faa329';
const $progressBarColor = '#c8c8c8';
const $progressBarAlertColor = 'rgb(206, 73, 73)';

export default ({
	shapeProps,
	timeline,
	selectTable,
	isSelected,
	selectedBookingTime,
	timerPaused,
	date,
	setBookingListInfoBookings
}) => {
	const [ currentTimestamp, setCurrentTimestamp ] = useState(moment());
	const RectangleRef = React.useRef();
	const ShapeRef = React.useRef();
	const [ diningTimerAlertVisible, setDiningTimerAlertVisible ] = useState(false);
	const [ blankBookingVisible, setBlankBookingVisible ] = useState(false);
	const [ nextTableBookingVisible, setNextTableBookingVisible ] = useState(false);
	const [ tableStatusColor, setTableStatusColor ] = useState($tableStatusEmptyColor);
	const [ selectedVisible, setSeletedVisible ] = useState(false);
	const [ nextTableBookingTime, setNextTableBookingTime ] = useState(null);
	const [ progressBarVisible, setProgressBarVisible ] = useState(false);
	const [ progressBarColor, setProgressBarColor ] = useState($progressBarColor);
	const [ progressBar, setProgressBar ] = useState(0);
	const [ opacity ] = useState(1);
	const [ intervalTime, setIntervalTime ] = useState(timerPaused ? null : 1000);
	const [ selectedTime, setSelectedTime ] = useState(selectedBookingTime);
	const [ currentBooking, setCurrentBooking ] = useState({});
	const [ nextBooking, setNextBooking ] = useState({});
	const [ nextNextBooking, setNextNextBooking ] = useState({});

	let progressBarWidth = shapeProps.width - 10,
		halfProgressBarWidth = progressBarWidth / 2;

	const findBooking = useCallback(
		() => {
			if (timeline.length === 0) {
				// 該桌次無任何預約
				// dining time alert visible: false
				// table status color: $tableStatusEmptyColor
				// progressbar visible: false
				// next table booking visible: false
				// blank booking visile: false

				setCurrentBooking({});
				setNextBooking({});
				setNextNextBooking({});

				setDiningTimerAlertVisible(false);
				setTableStatusColor($tableStatusEmptyColor);
				setProgressBarVisible(false);
				setNextTableBookingVisible(false);
				setBlankBookingVisible(true);
			} else {
				let firstBookingTimestamp = moment(timeline[0].service_start_time);
				let bookingDiff = moment.duration(firstBookingTimestamp.diff(currentTimestamp)),
					durationDiff = bookingDiff.asMinutes();

				if (currentTimestamp < firstBookingTimestamp) {
					// 未到第一筆預約時間
					// dining time alert visible: false
					// table status color: $tableStatusEmptyColor
					// progressbar visible: false
					// next table booking visible: true
					// next table booking time 第一筆預約時間
					// blank booking visile: false

					if (durationDiff <= 5) {
						setDiningTimerAlertVisible(true);
					} else {
						setDiningTimerAlertVisible(false);
					}

					if (timeline[0].status === 'seated' || timeline[0].status === 'show') {
						setTableStatusColor(tableStatus(timeline[0].status).tableStatusColor);
					} else {
						setTableStatusColor($tableStatusEmptyColor);
					}

					setCurrentBooking({});
					setNextBooking(timeline[0]);
					setNextNextBooking(timeline[1] ? timeline[1] : {});

					setProgressBarVisible(false);
					setNextTableBookingVisible(true);
					setNextTableBookingTime(timeline[0].time);
					setBlankBookingVisible(false);
				} else if (currentTimestamp >= firstBookingTimestamp) {
					// 已到第一筆預約時間

					for (let i = 0; i < timeline.length; i++) {
						let diningTime = JSON.parse(timeline[i].dining_time),
							lastBookingDiningTime = JSON.parse(timeline[timeline.length - 1].dining_time);
						let bookingTimestamp = moment(timeline[i].service_start_time),
							bookingTimeEnd = moment(bookingTimestamp).add(diningTime, 'm').format('YYYY-MM-DD HH:mm');
						let bookingTimeEndTimestamp = moment(bookingTimeEnd);
						let lastBookingTimeEnd = moment(timeline[timeline.length - 1].service_start_time)
								.add(lastBookingDiningTime, 'm')
								.format('YYYY-MM-DD HH:mm'),
							lastBookingTimeEndTimestamp = moment(lastBookingTimeEnd);

						if (bookingTimestamp <= currentTimestamp && currentTimestamp <= bookingTimeEndTimestamp - 1) {
							// console.log('---現在時間 在 某筆預約時間內---', timeline[i]);

							// 現在時間在某比預約時間內
							// dining time alert visible
							// table status color: booking status
							// progressbar visible: true
							// next table booking visible: false
							// blank booking visible: false

							let passTimeDuration = moment.duration(currentTimestamp.diff(bookingTimestamp));
							let passTimeDurationDiff = passTimeDuration.asMinutes();
							let pxUnit = progressBarWidth / diningTime;

							if (passTimeDurationDiff > diningTime - 30) {
								//用餐時間剩餘30分鐘
								setProgressBarColor($progressBarAlertColor);
							} else {
								setProgressBarColor($progressBarColor);
							}

							setCurrentBooking(timeline[i]);
							setNextBooking(timeline[i + 1] ? timeline[i + 1] : {});
							setNextNextBooking({});

							setTableStatusColor(tableStatus(timeline[i].status).tableStatusColor);
							setProgressBar(passTimeDurationDiff * pxUnit);

							setDiningTimerAlertVisible(false);
							setProgressBarVisible(true);
							setNextTableBookingVisible(false);
							setBlankBookingVisible(false);

							break;
						} else if (
							currentTimestamp <= bookingTimestamp &&
							currentTimestamp <= bookingTimeEndTimestamp - 1
						) {
							// console.log('---現在時間 已過上一筆預約 且未到 下一筆預約時間---');
							let nextBookingTimestamp = moment(timeline[i].service_start_time);
							let nextBookingDiff = moment.duration(nextBookingTimestamp.diff(currentTimestamp)),
								nextBookingDurationDiff = nextBookingDiff.asMinutes();
							// 現在時間未到下一筆預約時間
							// dining time alert visible: false
							// table status color: $tableStatusEmptyColor
							// progressbar visible: false
							// next table booking visible: false
							// next table booking time: 下一筆預約時間
							// blank booking visible: false
							if (nextBookingDurationDiff <= 5) {
								setDiningTimerAlertVisible(true);
							} else {
								setDiningTimerAlertVisible(false);
							}

							if (timeline[i].status === 'seated' || timeline[i].status === 'show') {
								setTableStatusColor(tableStatus(timeline[i].status).tableStatusColor);
							} else {
								setTableStatusColor($tableStatusEmptyColor);
							}

							setCurrentBooking({});
							setNextBooking(timeline[i] ? timeline[i] : {});
							setNextNextBooking(timeline[i + 1] ? timeline[i + 1] : {});

							setProgressBar(0);
							setProgressBarVisible(false);
							setNextTableBookingVisible(true);
							setNextTableBookingTime(timeline[i].time);
							setBlankBookingVisible(false);

							break;
						} else if (currentTimestamp > lastBookingTimeEndTimestamp - 1) {
							//現在時間已過最後一筆預約時間, 已無下一筆預約
							// dining time alert visible: false
							// table status color: $tableStatusEmptyColor
							// progressbar visible: false
							// next table booking visible: false
							// blank booking visible: true

							setCurrentBooking({});
							setNextBooking({});
							setNextNextBooking({});

							setDiningTimerAlertVisible(false);
							setTableStatusColor($tableStatusEmptyColor);
							setProgressBarVisible(false);
							setNextTableBookingVisible(false);
							setBlankBookingVisible(true);
						}
					}
				}
			}
		},
		[ currentTimestamp, progressBarWidth, timeline ]
	);

	useInterval(() => {
		setCurrentTimestamp(moment());
	}, intervalTime);

	React.useEffect(
		() => {
			// console.log('selectedTime----', selectedTime);
			setSelectedTime(selectedBookingTime);
			setCurrentTimestamp(moment(new Date(date + ' ' + time[selectedTime])));
			// setIntervalTime(1000);
			if (!timerPaused) {
				setIntervalTime(1000);
			} else if (timerPaused) {
				setIntervalTime(null);
			}
		},
		[ selectedBookingTime, selectedTime, date, timerPaused ]
	);

	React.useEffect(
		() => {
			if (isSelected) {
				setSeletedVisible(true);
			} else {
				setSeletedVisible(false);
			}
		},
		[ isSelected ]
	);

	React.useEffect(
		() => {
			findBooking();
		},
		[ findBooking ]
	);

	return (
		<Group
			opacity={opacity}
			x={shapeProps.positionX}
			y={shapeProps.positionY}
			onClick={() => {
				selectTable(shapeProps, shapeProps.timeline);
				setBookingListInfoBookings({
					currentBooking,
					nextBooking,
					nextNextBooking
				});
			}}
			onTouchStart={() => {
				selectTable(shapeProps, shapeProps.timeline);
				setBookingListInfoBookings({
					currentBooking,
					nextBooking,
					nextNextBooking
				});
			}}
		>
			<Rect
				visible={selectedVisible}
				width={shapeProps.width + 16}
				height={shapeProps.height + 16}
				x={-8}
				y={-8}
				fill={'rgba(0,0,0,0)'}
				cornerRadius={8}
				stroke={$selectedColor}
				strokeWidth={3}
			/>

			<Rect
				ref={RectangleRef}
				{...shapeProps}
				preventDefault={false}
				fill={tableStatusColor}
				cornerRadius={8}
				stroke={'#5c5c5c'}
				strokeWidth={2}
			/>

			{/* table info */}
			<Shape
				preventDefault={false}
				ref={ShapeRef}
				sceneFunc={function sceneFunc(context, shape) {
					let centerX = shapeProps.width / 2,
						centerY = 12;

					context.font = 'bold 18px PingFang TC,STHeiti Light,Microsoft JhengHei,Helvetica,Arial';
					let text_1 = shapeProps.name;
					let text_w_1 = context.measureText(text_1).width / 2;
					context.fillText(text_1, centerX - text_w_1, centerY + 13);

					context.font = '13px PingFang TC,STHeiti Light,Microsoft JhengHei,Helvetica,Arial';
					let text_2 = '(' + shapeProps.min_attendance + '-' + shapeProps.max_attendance + '人)';
					let text_w_2 = context.measureText(text_2).width / 2;
					context.fillStyle = '#979797';
					context.fillText(text_2, centerX - text_w_2, centerY + 30);
				}}
			/>

			{/* progress bar */}
			<Group preventDefault={false} visible={progressBarVisible}>
				<Rect
					width={progressBarWidth}
					height={6}
					x={shapeProps.width / 2 - halfProgressBarWidth}
					y={shapeProps.height - 15}
					fill={'#eee'}
					cornerRadius={3}
				/>

				<Rect
					width={progressBar}
					height={6}
					x={shapeProps.width / 2 - halfProgressBarWidth}
					y={shapeProps.height - 15}
					fill={progressBarColor}
					cornerRadius={3}
				/>
			</Group>

			{/* dining time alert */}
			<Label preventDefault={false} visible={diningTimerAlertVisible} x={shapeProps.width / 2 - 20} y={-8}>
				<Tag fill={'#c03d3d'} cornerRadius={10} />
				<Text
					text={'\uf0f3'}
					fontFamily="FontAwesome"
					width={40}
					height={20}
					fontSize={11}
					fill={'white'}
					align="center"
					verticalAlign="middle"
				/>
			</Label>

			{/* blank booking table */}
			<Label
				preventDefault={false}
				visible={blankBookingVisible}
				x={shapeProps.width / 2 - 30}
				y={shapeProps.height - 25}
			>
				<Tag preventDefault={false} fill={'#47c26c'} cornerRadius={10} />
				<Text
					preventDefault={false}
					text="尚無預約"
					width={60}
					height={20}
					padding={5}
					fontSize={11}
					fill={'white'}
					align="center"
					verticalAlign="middle"
				/>
			</Label>

			{/* 下一筆預約時間 */}
			<Text
				preventDefault={false}
				visible={nextTableBookingVisible}
				x={shapeProps.width / 2 - 20}
				y={shapeProps.height - 20}
				width={40}
				text={nextTableBookingTime}
				align="center"
				fontSize={14}
				fill={'#5c5c5c'}
			/>
		</Group>
	);
};
