import React, { Fragment, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';


const CancelAddPopup = ({ confirmPopup, cancelPopup, saveDraft }) => {
	const { CouponState } = useContext(CouponStateContext);
	const { editType} = CouponState;

	const { CouponAddState } = useContext(CouponAddStateContext);
	const {couponSetting}=CouponAddState;


	const renderHeader = () => {
		const txt = editType === 'add' ? '新增' : '修改';

		return <h2>取消{txt}優惠券</h2>;
	};

	const renderBody = () => {
		const txt = editType === 'add' ? '新增' : '修改';

		return (
			<Fragment>
				<p>確定取消{txt}優惠券嗎？取消將不保留前面的編輯內容。</p>
				{editType === 'add' && <p>您亦可將新增內容儲存草稿。</p>}
			</Fragment>
		);
	};

	const renderSaveDraftBtn = () => {
		if (editType ==='edit' && couponSetting.status !== 'draft') return null;
		return (
			<button className="greenThemeBtn" onClick={saveDraft}>
				儲存草稿
			</button>
		);
	};

	return (
		<div className="cancelAddPopup">
			<div className="cancelAddPopup__header">{renderHeader()}</div>
			<div className="cancelAddPopup__body">
				{renderBody()}
			</div>

			<div className="cancelAddPopup__foter">
				<div>
					{renderSaveDraftBtn()}
				</div>
				<div>
					<button className="grayThemeBtn" onClick={cancelPopup}>
						取消
					</button>
					<button className="deepGrayThemeBtn" onClick={confirmPopup}>
						確定
					</button>
				</div>
			</div>
		</div>
	);
};

export default CancelAddPopup;
