import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import createLogger from "redux-logger";
import auth from "../reducers/auth";
import alerts from "../reducers/alerts";
import flashMessage from "../reducers/flashMessage"
import { selectedUsersPage, usersByPage } from "../reducers/users";
import { selectedReposPage, reposByPage } from "../reducers/repos";
import {
  NewAddBooking_ModalSetting,
  NewAddBooking_CustomerInfo,
  NewAddBooking_SeatCondition,
  NewAddBooking_UI,
  NewAddBooking_Question
} from '../reducers/addBookingReducer'
import {BookingSettingAreaReducer, BookingSettingAreaModalReducer} from '../reducers/bookingSettingAreaReducer'
import { announcementListReducer, addAnnouncementReducer } from '../reducers/announcementReducer';

const logger = createLogger();
const rootReducer = combineReducers({
  auth,
  alerts,
  flashMessage,
  selectedUsersPage,
  usersByPage,
  selectedReposPage,
  reposByPage,
  NewAddBooking_ModalSetting,
  NewAddBooking_CustomerInfo,
  NewAddBooking_SeatCondition,
  NewAddBooking_UI,
  NewAddBooking_Question,
  BookingSettingAreaReducer,
  BookingSettingAreaModalReducer,
  announcementListReducer,
  addAnnouncementReducer
});

const initialState = {};

export default function configureStore() {
  let store;

  if (module.hot) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(thunkMiddleware, logger),
        window.devToolsExtension ? window.devToolsExtension() : f => f
      )
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(thunkMiddleware), f => f)
    );
  }

  return store;
}
