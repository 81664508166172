export const dateRangeOption  = [
  {
    title: '過去7天',
    value: 'last_7',
    option: [
      { title: '每日', value: 'day' }
    ]
  },
  {
    title: '過去30天',
    value: 'last_30',
    option: [
      { title: '每日', value: 'day' }
    ]
  },
  {
    title: '過去90天',
    value: 'last_90',
    option: [
      { title: '每週', value: 'week' }
    ]
  },
  {
    title: '過去半年',
    value: 'last_half_year',
    option: [
      { title: '每週', value: 'week' },
      { title: '每月', value: 'month' }
    ]
  },
  {
    title: '過去一年',
    value: 'last_year',
    option: [
      { title: '每週', value: 'week' },
      { title: '每月', value: 'month' }
    ]
  }
];