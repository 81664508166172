import React from 'react'

function UserPassword({
  oldPassword,
  newPassword,
  newPasswordConfirm,
  passwordError,
  handleInputChange,
  submit
}) {
  const {
    oldPasswordEmpty,
    oldPasswordWrong,
    newPasswordLength,
    confirmPasswordLength,
    passwordValidate
  } = passwordError;
  return (
    <div className='userBLock'>
      <div className='title'>變更密碼</div>
      <div className='row__input'>
        <div className="subtitle">舊密碼</div>
        <input
          type="password"
          name='old_password'
          className={`${oldPasswordEmpty || oldPasswordWrong ? 'input_error' : ''}`}
          value={oldPassword}
          onChange={handleInputChange}
        />
        { oldPasswordEmpty && <div className='text_error'>請輸入舊密碼</div> }
        { oldPasswordWrong && <div className='text_error'>舊密碼輸入不正確</div> }
      </div>
      <div className='row__input'>
        <div className="subtitle">輸入新密碼</div>
        <input
          type="password"
          name='new_password'
          className={`${newPasswordLength || passwordValidate? 'input_error' : ''}`}
          value={newPassword}
          onChange={handleInputChange}
          placeholder='請輸入至少4個字元'
        />
        { passwordValidate && <div className='text_error'>密碼輸入不一致</div>}
        { newPasswordLength && <div className='text_error'>密碼長度不可少於4個字元</div>}
      </div>
      <div className='row__input'>
        <div className="subtitle">確認新密碼</div>
        <input
          type="password"
          name='new_password_confirm'
          className={`${passwordValidate || confirmPasswordLength ? 'input_error' : ''}`}
          value={newPasswordConfirm}
          onChange={handleInputChange}
          placeholder='請輸入至少4個字元'
        />
        { passwordValidate && <div className='text_error'>密碼輸入不一致</div>}
        { confirmPasswordLength && <div className='text_error'>密碼長度不可少於4個字元</div>}
      </div>
      <hr />
      <div className='fix_btn'>
        <button className='btn_submit' onClick={submit}>儲存</button>
      </div>
    </div>
  )
}

export default UserPassword