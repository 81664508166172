import React from 'react'
import $ from 'jquery'
import { Link } from 'react-router'
var createReactClass = require('create-react-class');

export default createReactClass({
  getStatus() {
    var status = this.props.data.status;
    if (status==='unchecked') {
      status = '待確認';
    } else {
      status = '已確認';
    } 
    return status;
  },
  getButtonStyle() {
    return (this.props.data.customer_id!=='' ? {display: 'none'} : {});
  },
  getCallState() {
    var call_state;
    if (this.props.data.state_code==='ring_off') {
      call_state = React.createElement('span', {}, 
        <img src={require("../../images/component/call_missed.svg")} alt="" />
      );
    } else {
      call_state = React.createElement('span');
    }
    return call_state;
  },
  handleUpdateClick(e) {
    var url = window.location.pathname+'/'+this.props.data.id;
    var data = $.extend({}, this.props.data);
    var status = $(e.target).attr('data-status');
    data['status'] = status;
    this.props.onSubmit(url, {call: data}, 'PUT', '更新成功', 'done');
  },
  handleCreateClick(e) {
    this.props.onTempChange(this.props.data);
    this.props.onAddClick()
  },
  handleDeleteClick(e) {
    this.props.onTempChange(this.props.data);
  },
  _getCount() {
    return (this.props.data.count>1 ? '('+this.props.data.count+')' : '');
  },
  _getLink() {
    return (this.props.data.customer_id!=='' ? '/dashboard/customers/'+this.props.data.customer_id : window.location.pathname);
  },
  renderAvatar(state_code){
    if (state_code==='hang_up') {
      return <img src={require("../../images/component/profile_user_green.svg")} alt="" />
    } else {
      return <img src={require("../../images/component/profile_user_orange.svg")} alt="" />
    }
  },
  render() {
	  return (
      <tr>
        <td className="avatar">
          {this.getCallState()}
          
          {this.renderAvatar(this.props.data.state_code)}
        </td>
        <td style={{width: '550px'}}>
          <Link to={this._getLink()}>
            <div className="name">{this.props.data.name + this._getCount()}</div>
          </Link>
          <span className="phone"> {this.props.data.phone_number} </span>
        </td>
        <td className="phonecall-status item-position-relative">

        </td>
        <td><span className="call-date">{this.props.data.ring_on_at} </span></td>
        <td className="action">
          <a href="#" 
             className="btn btn-color-white btn-style3" 
             onClick={this.handleCreateClick}
             style={this.getButtonStyle()}>
            <span className="glyphicon glyphicon-plus" 
                   aria-hidden="true" /> 
             儲存至顧客關係系統
          </a>
          <a href="#" 
             className="btn btn-color-white btn-style3" 
             data-toggle="modal" 
             data-target=".deleteitem"
             style={{display: 'none'}}
             onClick={this.handleDeleteClick}>
            <span className="glyphicon glyphicon-trash" 
                  aria-hidden="true" />
            刪除
          </a>
        </td>
      </tr>
	  );
	}
})
