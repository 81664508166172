import React, { useState, useCallback, useEffect, useContext } from 'react';
import { SignUpStateContext } from '../MenuCouponContext/MenuCouponSignUpContext';
import MenuAppIcon from '../../../images/menuCoupon/menuAppIcon.png';
import Ios from '../../../images/menuCoupon/ios.png';
import Googleplay from '../../../images/menuCoupon/googleplay.png';
import Sample from '../../../images/menuCoupon/sample.png';

const SignUpDone = () => {
    const {SignUpState, SignUpStateDispatch} = useContext(SignUpStateContext)
    const {shopInfo}=SignUpState;

    const goMenuCoupoun = () =>{
		SignUpStateDispatch({ type: 'setMenuShopUser', menuShopUser: true });
    }

	return (
		<div className="signUpDone">
			<div className="signUpDoneLayout">
                <div className="signUpDoneSample">
                    <div className="menuApp">
                        <figure>
                            <div>
                                <img src={MenuAppIcon} alt=""/>
                            </div>
                            <figcaption>MENU美食誌</figcaption>
                        </figure>
                    </div>
                    <p className="infoTxt text-center">
                        現在您可透過優惠券專區於<span>MENU美食誌</span>上架優惠券，供上萬名使用者獲得您的最新優惠內容。
                    </p>

                    <div className="sampleImg">
                        <figure>
                            <img src={Sample} alt=""/>
                        </figure>
                    </div>

                    <br/>
                    <br/>

                    <div className="downloadTxt text-center">
                        <h2>下載 MENU美食誌</h2>

                        <br/>

                        <p>發掘探索更多潛在顧客，</p>
                        <p>透過社群與顧客互動。</p>
                    </div>

                    <br/>
                    <br/>

                    {/* <div className="downloadImg">
                        <figure>
                            <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                                <img src={Ios} alt="Apple Store"/>
                            </a>
                        </figure>
                        <figure>
                            <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                                <img src={Googleplay} alt="Google Play"/>
                            </a>
                        </figure>
                    </div> */}

                </div>
                <div className="signInInfoCard">
                    <h2 className="signInInfoCard__title text-center">您的店家資訊</h2>
                    <h1 className="text-center">{shopInfo.shopName}</h1>

                    <div className="signInInfoCard__info">
                        <p>分店名稱</p>
                        <h2>{shopInfo.shopBranch}</h2>
                    </div>
                    <div className="signInInfoCard__info">
                        <p>電話號碼</p>
                        <h2>{shopInfo.tel}</h2>
                    </div>
                    <div className="signInInfoCard__info">
                        <p>地址</p>
                        <h2>{shopInfo.address}</h2>
                    </div>
                    <div className="signInInfoCard__info">
                        <p>店家類別</p>
                        <h2>{shopInfo.category}</h2>
                    </div>

                    <div className="text-center">
                        <button className="greenThemeBtn" onClick={()=> goMenuCoupoun()}>我知道了</button>
                    </div>
                </div>
			</div>
		</div>
	);
};

export default SignUpDone;
