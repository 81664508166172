import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { Rect, Group } from 'react-konva';
import moment from 'moment';

import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';

import {
	TableInfo,
	ProgressBar,
	Alert,
	NextTableBooking,
	AddBookingBtn,
	TableSelected,
	SameBookingCount,
	BookingCountIcon,
	displayCanvasTable
} from './Items';

import { countOverlayTable } from './countOverlayTable';

import * as colorStyle from './contant';
import { time, useInterval } from '../utils/data';

const {
	$tableStatusEmptyColor,
	$tableBorderColor_normal,
	$progressBarNormalColor,
	$canvasTableNameColor,
	$canvasTableAttendanceColor,
	$tableNextBookingColor_normal,
	$tableNextBookingTxtColor_white,
	$selectedColor
} = colorStyle;

const RectShape = ({
	shapeProps,
	selectTable,
	isSelected,
	timeline,
	setBookingListInfoBookings,
	selectedTableToSeated,
	activeSystem,
	removeSelectedTableToseated,
	isSeatedTable,
	tableBooking,
	tableBookingStep,
	tableBookingDateTime,
	swapMode,
	swapMoreSeatMode,
	selectSwapSeat,
	swapBookingInfo,
	isSwapSeat,
	swapMoreSeatCheckOverlayTable,
	target
}) => {
	const { CommomSettingState } = useContext(CommomSettingContext);
	const { date, moduleTimePaused, moduleSelectedBookingTime, queueSeatedData } = CommomSettingState;

	const GroupRef = React.useRef();
	const RectangleRef = React.useRef();
	const [ currentTimestamp, setCurrentTimestamp ] = useState(moment());
	const [ intervalTime, setIntervalTime ] = useState(moduleTimePaused ? null : 1000);
	const [ opacity, setOpacity ] = useState(1);
	const [ selectedVisible, setSeletedVisible ] = useState(false);
	const [ tableStatusColor, setTableStatusColor ] = useState($tableStatusEmptyColor);
	const [ tableBorderColor, setTableBorderColor ] = useState($tableBorderColor_normal);
	const [ progressBarVisible, setProgressBarVisible ] = useState(false);
	const [ progressBar, setProgressBar ] = useState(0);
	const [ progressBarColor, setProgressBarColor ] = useState($progressBarNormalColor);
	const [ diningTimerAlertVisible, setDiningTimerAlertVisible ] = useState(false);
	const [ nextTableBookingVisible, setNextTableBookingVisible ] = useState(false);
	const [ nextTableBookingTime, setNextTableBookingTime ] = useState(null);
	const [ canvasTableNameColor, setCanvasTableNameColor ] = useState($canvasTableNameColor);
	const [ canvasTableAttendanceColor, setCanvasTableAttendanceColor ] = useState($canvasTableAttendanceColor);
	const [ addBookingBtnVisible, setAddBookingBtnVisible ] = useState(false);
	const [ tableNextBookingColor, setTableNextBookingColor ] = useState($tableNextBookingColor_normal);
	const [ tableNextBookingTxtColor, setTableNextBookingTxtColor ] = useState($tableNextBookingTxtColor_white);
	const [ tableNextBookingBorderColor, setTableNextBookingBorderColor ] = useState($tableNextBookingColor_normal);
	const [ currentBooking, setCurrentBooking ] = useState([]);
	const [ nextBooking, setNextBooking ] = useState([]);
	const [ tableSelectedVisible, setTableSelectedVisible ] = useState(false);
	const [ tableSwapMoreSeatSelectedVisible, setTableSwapMoreSeatSelectedVisible ] = useState(false);
	// const [ targetBooking, setTargetBooking ] = useState({}); //目標預約
	const [ swappedBooking, setSwappedBooking ] = useState({}); //被換的預約

	const [ doubleBookingCount, setDoubleBookingCount ] = useState(0);

	const progressBarWidth = shapeProps.width - 10;

	useEffect(
		() => {
			if (isSeatedTable) {
				setTableSelectedVisible(true);
				setAddBookingBtnVisible(false);
				setTableSwapMoreSeatSelectedVisible(true)
			} else {
				setTableSelectedVisible(false);
				setAddBookingBtnVisible(true);
				setTableSwapMoreSeatSelectedVisible(false)
			}
		},
		[ isSeatedTable ]
	);

	//排隊入座
	//選桌子建立預約
	useEffect(
		() => {
			if (!tableBooking && Object.keys(queueSeatedData).length === 0) {
				//正常模式 && 交換位子
				if (isSelected) {
					setSeletedVisible(true);
				} else {
					setSeletedVisible(false);
				}
			} else if (tableBooking || Object.keys(queueSeatedData).length !== 0) {
				//選桌子建立預約 or 排隊入座模式
				setSeletedVisible(false);
			}
		},
		[ isSelected, queueSeatedData, tableBooking, tableBookingStep, shapeProps, swapMode, swapMoreSeatMode ]
	);

	useInterval(() => {
		setCurrentTimestamp(moment());
	}, intervalTime);

	useEffect(
		() => {
			if (!swapMode && !swapMoreSeatMode && !tableBooking && Object.keys(queueSeatedData).length === 0) {
				//正常模式
				if (!moduleTimePaused) {
					setIntervalTime(1000);
					setCurrentTimestamp(moment());
				} else if (moduleTimePaused) {
					setIntervalTime(null);
					setCurrentTimestamp(moment(new Date(date + ' ' + time[moduleSelectedBookingTime])));
				}
			} else if (activeSystem === 'queue' && Object.keys(queueSeatedData).length !== 0) {
				//排隊入座模式: 現在時間
				setIntervalTime(1000);
				setCurrentTimestamp(moment());
			} else if (tableBooking && tableBookingStep === 3) {
				//選桌子建立預約
				setCurrentTimestamp(tableBookingDateTime);
				setIntervalTime(null);
			} else if (swapMode || swapMoreSeatMode) {
				setCurrentTimestamp(moment(new Date(tableBookingDateTime)));
				setIntervalTime(null);
			}
		},
		[
			date,
			activeSystem,
			tableBookingStep,
			tableBooking,
			tableBookingDateTime,
			swapMode,
			swapMoreSeatMode,
			queueSeatedData,
			moduleTimePaused,
			moduleSelectedBookingTime
		]
	);

	useEffect(
		() => {
			displayCanvasTable({
				currentTimestamp,
				activeSystem,
				queueSeatedData,
				tableBooking,
				tableBookingStep,
				swapMode,
				swapMoreSeatMode,
				timeline,
				isSwapSeat,
				isSelected,
				progressBarWidth,
				setTableStatusColor,
				setTableBorderColor,
				setCanvasTableNameColor,
				setCanvasTableAttendanceColor,
				setDiningTimerAlertVisible,
				setProgressBarVisible,
				setNextTableBookingVisible,
				setOpacity,
				setAddBookingBtnVisible,
				setTableSelectedVisible,
				setTableNextBookingColor,
				setTableNextBookingTxtColor,
				setNextTableBookingTime,
				setProgressBarColor,
				setProgressBar,
				setSwappedBooking,
				setTableNextBookingBorderColor
			});

			countOverlayTable(timeline, currentTimestamp, setCurrentBooking, setNextBooking, setDoubleBookingCount);
		},
		[
			activeSystem,
			currentTimestamp,
			isSwapSeat,
			isSelected,
			progressBarWidth,
			queueSeatedData,
			setBookingListInfoBookings,
			swapBookingInfo,
			swapMode,
			swapMoreSeatMode,
			tableBooking,
			tableBookingStep,
			timeline
		]
	);

	const renderAlert = useCallback(
		() => {
			if (diningTimerAlertVisible) {
				return <Alert diningTimerAlertVisible={diningTimerAlertVisible} shapeProps={shapeProps} />;
			}
		},
		[ shapeProps, diningTimerAlertVisible ]
	);

	const renderProgressBar = useCallback(
		() => {
			if (progressBarVisible) {
				return (
					<ProgressBar
						shapeProps={shapeProps}
						progressBarVisible={progressBarVisible}
						progressBar={progressBar}
						progressBarColor={progressBarColor}
					/>
				);
			}
		},
		[ shapeProps, progressBarVisible, progressBar, progressBarColor ]
	);

	const renderNextTableBooking = useCallback(
		() => {
			if (nextTableBookingVisible) {
				return (
					<NextTableBooking
						shapeProps={shapeProps}
						nextTableBookingVisible={nextTableBookingVisible}
						tableNextBookingColor={tableNextBookingColor}
						nextTableBookingTime={nextTableBookingTime}
						tableNextBookingTxtColor={tableNextBookingTxtColor}
						tableNextBookingBorderColor={tableNextBookingBorderColor}
					/>
				);
			}
		},
		[
			nextTableBookingVisible,
			shapeProps,
			tableNextBookingColor,
			nextTableBookingTime,
			tableNextBookingTxtColor,
			tableNextBookingBorderColor
		]
	);

	const renderShapeInfo = useCallback(
		(shapeProps) => {
			return (
				<TableInfo
					shapeProps={shapeProps}
					canvasTableNameColor={canvasTableNameColor}
					canvasTableAttendanceColor={canvasTableAttendanceColor}
				/>
			);
		},
		[ canvasTableAttendanceColor, canvasTableNameColor ]
	);

	const renderAddBookingBtn = useCallback(
		() => {
			if (addBookingBtnVisible) {
				return <AddBookingBtn addBookingBtnVisible={addBookingBtnVisible} shapeProps={shapeProps} />;
			}
		},
		[ shapeProps, addBookingBtnVisible ]
	);

	const renderTableSelected = useCallback(
		() => {
			if (tableSelectedVisible) {
				return <TableSelected shapeProps={shapeProps} tableSelectedVisible={tableSelectedVisible} />;
			}
		},
		[ shapeProps, tableSelectedVisible ]
	);

	const renderSameBookingCount = useCallback(
		() => {
			if (tableBooking && tableBookingStep === 3 && currentBooking.length !== 0 || swapMoreSeatMode && currentBooking.length !== 0) {
				return <SameBookingCount shapeProps={shapeProps} sameBookingCount={currentBooking.length} swapMoreSeatMode={swapMoreSeatMode} isSelected={isSelected} />;
			}
		},
		[ currentBooking.length, shapeProps, tableBooking, tableBookingStep ]
	);

	const renderRect = useCallback(
		(shapeProps) => {
			return (
				<Rect
					ref={RectangleRef}
					{...shapeProps}
					preventDefault={false}
					fill={tableStatusColor}
					cornerRadius={8}
					stroke={tableBorderColor}
					strokeWidth={2}
				/>
			);
		},
		[ tableStatusColor, tableBorderColor ]
	);

	const renderBookingCountIcon = useCallback(
		() => {
			return doubleBookingCount <= 1 ? null : (
				<BookingCountIcon
					shapeProps={shapeProps}
					bookingCount={doubleBookingCount}
					status={currentBooking[0] ? currentBooking[0].status : 'confirmed'}
				/>
			);
		},
		[ doubleBookingCount, currentBooking, shapeProps ]
	);

	const ShapreInfoMemo = useMemo(
		() => {
			return renderShapeInfo(shapeProps);
		},
		[ renderShapeInfo, shapeProps ]
	);

	const RectMemo = useMemo(
		() => {
			return renderRect(shapeProps);
		},
		[ renderRect, shapeProps ]
	);

	return (
		<Group
			ref={GroupRef}
			opacity={opacity}
			x={shapeProps.positionX}
			y={shapeProps.positionY}
			onTouchStart={() => {
				if (
					addBookingBtnVisible &&
					(Object.keys(queueSeatedData).length !== 0 || (tableBooking && tableBookingStep === 3))
				) {
					//排隊入座or點桌子建立預約
					if (isSeatedTable) {
						removeSelectedTableToseated(shapeProps);
					} else {
						shapeProps.nextBooking = nextBooking;
						selectedTableToSeated(shapeProps);
					}
				} else if (swapMode) {
					//換位模式：一換一
					if (!isSelected) {
						//非同筆預約桌子才顯示換桌資訊
						selectSwapSeat(
							shapeProps,
							{
								currentBooking,
								nextBooking
							},
							swappedBooking
						);
					}
				} else if (swapMoreSeatMode) {
					// 換位模式：可一換多
					swapMoreSeatCheckOverlayTable(target, swappedBooking, shapeProps)

					if (isSeatedTable) {
						removeSelectedTableToseated(shapeProps);
					} else {
						shapeProps.nextBooking = nextBooking;
						selectedTableToSeated(shapeProps);
					}
				} else if (
					!swapMode && !swapMoreSeatMode &&
					Object.keys(queueSeatedData).length === 0 &&
					(!tableBooking && tableBookingStep !== 3)
				) {
					//正常模式
					setBookingListInfoBookings({
						currentBooking,
						nextBooking
					});

					selectTable(shapeProps, currentBooking, nextBooking);
				}
			}}
			onClick={() => {
				if (
					addBookingBtnVisible &&
					(Object.keys(queueSeatedData).length !== 0 || (tableBooking && tableBookingStep === 3))
				) {
					//排隊入座or點桌子建立預約
					if (isSeatedTable) {
						removeSelectedTableToseated(shapeProps);
					} else {
						shapeProps.nextBooking = nextBooking;
						selectedTableToSeated(shapeProps);
					}
				} else if (swapMode) {
					//換位模式：一換一
					if (!isSelected) {
						//非同筆預約桌子才顯示換桌資訊
						selectSwapSeat(
							shapeProps,
							{
								currentBooking,
								nextBooking
							},
							swappedBooking
						);
					}
				} else if (swapMoreSeatMode) {
					// 換位模式：可一換多
					swapMoreSeatCheckOverlayTable(target, swappedBooking, shapeProps)

					if (isSeatedTable) {
						removeSelectedTableToseated(shapeProps);
					} else {
						shapeProps.nextBooking = nextBooking;
						selectedTableToSeated(shapeProps);
					}
				} else if (
					!swapMode && !swapMoreSeatMode &&
					Object.keys(queueSeatedData).length === 0 &&
					(!tableBooking && tableBookingStep !== 3)
				) {
					//正常模式
					setBookingListInfoBookings({
						currentBooking,
						nextBooking
					});

					selectTable(shapeProps, currentBooking, nextBooking);
				}
			}}
		>
			{
				!swapMoreSeatMode ?
				<Rect
					preventDefault={false}
					visible={selectedVisible}
					width={shapeProps.width + 8}
					height={shapeProps.height + 8}
					x={-4}
					y={-4}
					fill={'rgba(0,0,0,0)'}
					cornerRadius={8}
					stroke={$selectedColor}
					strokeWidth={3}
				/> : null
			}

			<Rect
				preventDefault={false}
				visible={tableSelectedVisible}
				width={shapeProps.width + 8}
				height={shapeProps.height + 8}
				x={-4}
				y={-4}
				fill={'rgba(0,0,0,0)'}
				cornerRadius={8}
				stroke={$selectedColor}
				strokeWidth={3}
			/>

			<Rect
				preventDefault={false}
				visible={tableSwapMoreSeatSelectedVisible}
				width={shapeProps.width + 8}
				height={shapeProps.height + 8}
				x={-4}
				y={-4}
				fill={'rgba(0,0,0,0)'}
				cornerRadius={8}
				stroke={$selectedColor}
				strokeWidth={3}
			/>

			{/* 基本圖型 */}
			{RectMemo}

			{/* table info */}
			{ShapreInfoMemo}

			{/* progress bar */}
			{renderProgressBar()}

			{/* dining time alert */}
			{renderAlert()}

			{/* 下一筆預約時間 */}
			{renderNextTableBooking()}

			{/* 建立預約按鈕 */}
			{renderAddBookingBtn()}

			{/* 建立預約按鈕 取消桌子*/}
			{renderTableSelected()}

			{/* 強制建立預約顯示n組顧客 */}
			{renderSameBookingCount()}

			{/* 併桌數量 */}
			{!swapMoreSeatMode ? renderBookingCountIcon() : null }
		</Group>
	);
};

export default RectShape;
