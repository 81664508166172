import React, { useState, useCallback, useEffect, useContext, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Spin } from 'antd';

import { weeks } from '../utils/data';
import { Portal } from '../utils/Portal';
import BookingSystemApi from '../../booking_settings/BookingSystemApi';

import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';
import { ListStateContext } from '../NewModuleReducer/ListReducer';

import { updateDayAnnouncements, announcementFetchMonth } from '../../../actions/announcementAction';

import Month from './Month';

import '../../../stylesheets/new_module_calendar.css';

const API = new BookingSystemApi();

const Calendar = () => {
	const { ListStateDispatch } = useContext(ListStateContext);

	const { CommomSettingState, CommomSettingDispatch } = useContext(CommomSettingContext);
	const { date, isToday } = CommomSettingState;

	const [ showCalendar, setShowCalendar ] = useState(false);
	const [ queryYear, setQueryYear ] = useState(null);
	const [ queryMonth, setQueryMonth ] = useState(null);
	const [ monthBookingTotalEffectiveCount, setMonthBookingTotalEffectiveCount ] = useState(0);
	const [ monthBookingEffectiveCount, setMonthBookingEffectiveCount ] = useState({});
	const [ monthBookingAttendance, setMonthBookingAttendance ] = useState({});
	const [ monthBookingTotalAttendance, setMonthBookingTotalAttendance ] = useState(0);
	const [ monthBookingUnconfirmedCount, setMonthBookingUnconfirmedCount ] = useState(0);
	const [ holiday, setHoliday ] = useState({});

	const dispatch = useDispatch();

	useEffect(
		() => {
			const urlYear = date !== '' ? moment(date, 'YYYY/MM/DD').format('YYYY') : null;
			const urlMonth = date !== '' ? moment(date, 'YYYY/MM/DD').format('MM') : null;

			setQueryYear(parseInt(urlYear, 10));
			setQueryMonth(parseInt(urlMonth, 10));
		},
		[ date ]
	);

	const getMonthEffectiveCount = useCallback(
		async (queryYear, queryMonth) => {
			if (date === '') return;

			const month = `${queryYear}/${queryMonth}`;
			try {
				API.getMonthStatus(month, 'effective').then((data) => {
					const total = Object.values(data).reduce((res, key) => (res += key), 0);

					setMonthBookingTotalEffectiveCount(total);
					setMonthBookingEffectiveCount(data);
				});
			} catch (err) {
				console.log('getMonthEffectiveCount err-----', err);
			}
		},
		[ date ]
	);

	const getMonthAttendance = useCallback(
		async (queryYear, queryMonth) => {
			if (!date || date === '') return;

			const month = `${queryYear}/${queryMonth}`;
			try {
				API.getMonthStatus(month, 'attendance').then((data) => {
					const total = Object.values(data).reduce((res, key) => (res += key), 0);

					setMonthBookingAttendance(data);
					setMonthBookingTotalAttendance(total);
				});
			} catch (err) {
				console.log('getMonthAttendance error-----', err);
			}
		},
		[ date ]
	);

	const getMonthUnconfirmedCount = useCallback(
		async (queryYear, queryMonth) => {
			if (!date || date === '') return;

			const month = `${queryYear}/${queryMonth}`;
			try {
				API.getMonthStatus(month, 'unconfirmed').then((data) => {
					setMonthBookingUnconfirmedCount(data);
				});
			} catch (err) {
				console.log('getMonthUnconfirmedCount error-----', err);
			}
		},
		[ date ]
	);

	const getHolidays = useCallback(async () => {
		let holidayData = {};
		try {
			API.holidays().then((data) => {
				// console.log('data----', data);
				let holidayTxt;
				data.forEach((item) => {
					if (item.isHoliday === '是') {
						holidayTxt = 'yes';
					} else {
						holidayTxt = 'no';
					}

					holidayData[item.date] = {
						name: item.name,
						isHoliday: holidayTxt
					};
				});

				setHoliday(holidayData);
			});
		} catch (err) {
			console.log('getHolidays error-----', err);
		}
	}, []);

	const calendarSetting = useCallback(
		() => {
			getMonthEffectiveCount(queryYear, queryMonth);
			getMonthAttendance(queryYear, queryMonth);
			getMonthUnconfirmedCount(queryYear, queryMonth);
			getHolidays();
		},
		[ getHolidays, getMonthAttendance, getMonthEffectiveCount, getMonthUnconfirmedCount, queryMonth, queryYear ]
	);

	useEffect(
		() => {
			if (!queryYear || !queryMonth) return;
			calendarSetting();
		},
		[ calendarSetting, queryYear, queryMonth ]
	);

	const handlePreMonth = () => {
		let newYear = queryMonth - 1 === 0 ? queryYear - 1 : queryYear;
		let newMonth = queryMonth - 1 === 0 ? 12 : queryMonth - 1;
		setQueryYear(newYear);
		setQueryMonth(newMonth);

		let fetchMonth = `${newYear}-${newMonth}`
		dispatch(announcementFetchMonth(fetchMonth));
	};

	const handleNextMonth = () => {
		let newYear = queryMonth + 1 === 13 ? queryYear + 1 : queryYear;
		let newMonth = queryMonth + 1 === 13 ? 1 : queryMonth + 1;
		setQueryYear(newYear);
		setQueryMonth(newMonth);

		let fetchMonth = `${newYear}-${newMonth}`
		dispatch(announcementFetchMonth(fetchMonth));
	};

	const changeUrlDate = (queryDate) => {
		CommomSettingDispatch({ type: 'changeUrlDate', queryDate });

		setShowCalendar(false);
		ListStateDispatch({ type: 'setActiveQueueStatusTab', date: queryDate });
	};

	const prevDay = () => {
		const prev = moment(new Date(date)).add(-1, 'd').format('YYYY-MM-DD');
		CommomSettingDispatch({ type: 'changeUrlDate', queryDate: prev });
		ListStateDispatch({ type: 'setActiveQueueStatusTab', date: prev });
		dispatch(updateDayAnnouncements(prev));
	};

	const nextDay = () => {
		const next = moment(new Date(date)).add(1, 'd').format('YYYY-MM-DD');
		CommomSettingDispatch({ type: 'changeUrlDate', queryDate: next });
		ListStateDispatch({ type: 'setActiveQueueStatusTab', date: next });
		dispatch(updateDayAnnouncements(next));
	};

	const openCalendar = () => {
		setShowCalendar(true);
	};

	const closeCalender = () => {
		setShowCalendar(false);
	};

	if (date === '') return <Spin />;
	return (
		<Fragment>
			<div className={`calendarWrap ${isToday ? 'todayStyle' : ''}`}>
				<span className="calendarBtn prevBtn" onClick={prevDay}>
					<i className="fa fa-caret-left" aria-hidden="true" />
				</span>
				<span className="calendarDisplay" onClick={openCalendar}>
					<span>
						{moment(new Date(date)).format('YYYY/MM/DD')} {weeks[moment(new Date(date)).format('dddd')]}
					</span>
				</span>
				<span className="calendarBtn nextBtn" onClick={nextDay}>
					<i className="fa fa-caret-right" aria-hidden="true" />
				</span>
			</div>

			{showCalendar && (
				<Portal>
					<div className="newModuleCalendar">
						<div className="newModuleCalendarWrap">
							<button className="closeNewModuleCalendar" onClick={closeCalender} />

							<div className="newModuleCalendarTable">
								<div className="newModuleCalendar__header">
									<button className="calendarBtn calendarBtn-prev" onClick={handlePreMonth} />
									<div>
										<span>
											{queryYear} {queryMonth}月
										</span>
									</div>
									<button className="calendarBtn calendarBtn-next" onClick={handleNextMonth} />
								</div>
								<div className="newModuleCalendarTable__body">
									<p>
										{queryMonth}月筆成交: {monthBookingTotalEffectiveCount}筆成交 ({monthBookingTotalAttendance}人)
									</p>
									{
										<Month
											year={queryYear}
											month={queryMonth}
											holiday={holiday}
											monthBookingUnconfirmedCount={monthBookingUnconfirmedCount}
											monthBookingEffectiveCount={monthBookingEffectiveCount}
											monthBookingAttendance={monthBookingAttendance}
											changeUrlDate={changeUrlDate}
										/>
									}
								</div>
							</div>
						</div>
					</div>
				</Portal>
			)}
		</Fragment>
	);
};

export default Calendar;
