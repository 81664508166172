import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BookingSystemApi from './BookingSystemApi';
import verifiedIcon from '../../images/icon_verified.svg';
import deleteIcon from '../../images/icon_delete.svg';

const API = new BookingSystemApi();

const GoogleBusinessInfo = ({
  google_reservation_enabled,
  google_merchant_category,
  address
}) => {
  return (
    <div>
      <div className='row_title mb-4'>您的Google商家資訊</div>
      <div className='title_text'>此為您提供的Google商家資訊。若您日後有於Google更改商家類別，請通知MENU店+業務以確保Google預訂的相關使用權益</div>
      <div className='mt-20'>
        <div className='row_subtitle-16 mb-8'>Google Maps 商家地點類別</div>
        <input
          className='row_input'
          type="text"
          disabled
          value={google_merchant_category ? google_merchant_category : '未設定'}
        />
      </div>
      {
        google_reservation_enabled &&
        <div className='mt-20'>
          <div className='row_subtitle-16 mb-8'>Google Maps 商家地址</div>
          <input
            className='row_input mb-2'
            type="text"
            disabled
            value={address}
          />
          <div className='additional_text'>請確認此地址與您在Google Maps上設置的商家地址是一致的。如需修改請至「設定」 {'>'} 「商家基本資訊」</div>
        </div>
      }
    </div>
  )
};

const BookingSettingGoogleReservation = () => {
  const auth = useSelector((state) => state.auth);
  const { shop } = auth;
  const {
    google_reservation_enabled,
    google_merchant_category
  } = shop;

  const [ init, setInit ] = useState(false);
  const [ sending, setSending ] = useState(false);

  const [ address, setAddress ] = useState(''); // 店家地址
  const [ onlineEnabled, setOnlineEnabled ] = useState(false); // 線上預約開放狀態
  const [ maxBookingTimeDay, setMaxBookingTimeDay ] = useState(0); // 最早可預約時間
  const [ areaSettingEnabled, setAreaSettingEnabled ] = useState(false); // 最早可預約時間
  const [ serviceTimeOptionEnabled, setServiceTimeOptionEnabled ] = useState(false); // 服務時間多選選項
  const [ phoneVerification, setPhoneVerification ] = useState(false); // 電話認證
  const [ depositRequired, setDepositRequired ] = useState(false); // 預先收款功能
  const [ verifyRequired, setVerifyRequired ] = useState(false); // 審核預約

  const [ isGoogleReservationEnabled, setIsGoogleReservationEnabled ] = useState(false);
  const [ googleReservation, setGoogleReservation ] = useState(false);

  useEffect(() => {
		getBookingSetting();
	}, []);

	const getBookingSetting = async () => {
		try {
			const data = await API.getBookingSetting();
			const {
        shop, 
        enable,
        max_booking_time_day,
        space_group_selection,
        service_time_option_enabled,
        phone_verification,
        deposit_required,
        verify_required,
        google_reservation
      } = data;
      const { address } = shop;

      if(
        address &&
        enable &&
        max_booking_time_day >= 30 &&
        !space_group_selection &&
        !service_time_option_enabled &&
        !phone_verification &&
        !deposit_required &&
        !verify_required
      ) {
        setIsGoogleReservationEnabled(true)
      }

      setAddress(address);
      setOnlineEnabled(enable);
      setMaxBookingTimeDay(max_booking_time_day);
      setAreaSettingEnabled(space_group_selection);
      setServiceTimeOptionEnabled(service_time_option_enabled);
      setPhoneVerification(phone_verification);
      setDepositRequired(deposit_required);
      setVerifyRequired(verify_required);
      setGoogleReservation(google_reservation);

      setInit(true);
		} catch (err) {
			console.log('google reservation getBookingSetting err---', err);
			window.app.alert.setMessage('請稍後再試', 'error');
		}
	};

  const onChange = () => {
    setGoogleReservation(!googleReservation);
  }

  const submit = async() => {
    setSending(true);

    try {
      const googleReservationSetting = JSON.stringify({
        booking_settings: {
          google_reservation: googleReservation
        }
      });

      await API.updateBookingSetting(googleReservationSetting);
      window.app.alert.setMessage('儲存成功', 'done');
      setSending(false);
    } catch (err) {
      console.log('google reservation update err---', err);
			window.app.alert.setMessage('請稍後再試', 'error');
      setSending(false);
    }
  }

  const renderEnabled = () => {
    return (
      <>
        <div className='settingTbl'>
          <div className="settingTblRow fix-settingTblRow">
            <div className="header fix-header">啟用狀態</div>
            <div className="text fix-text">
              <label className="msSwitch">
                <input
                  type="checkbox"
                  name="enable"
                  onChange={onChange}
                  checked={googleReservation}
                  value={true}
                  disabled={!isGoogleReservationEnabled}
                />
                <span className="slider" />
              </label>
            </div>
          </div>
        </div>

        <GoogleBusinessInfo
          google_reservation_enabled={google_reservation_enabled}
          google_merchant_category={google_merchant_category}
          address={address}
        />

        <hr className='hr-32' />

        <div className='row_subtitle-18 mb-4'>Google預訂啟用條件</div>
        <div className='title_text'>為配合Google預訂平台的功能限制，以及保障您與消費者在不同管道進行預約的權益，您的預約設定需符合以下條件才可開啟Google預訂功能</div>
        { !isGoogleReservationEnabled &&
          <div className='alert_text error_text mt-4'>*您尚有預約設定不符合啟用條件，因此無法開啟Google預訂功能</div>
        }

        <div className='block_googleRequired mt-20 mb-32'>
          <div className={address ? 'success_text' : 'error_text'}>
            {
              address
              ? <img src={verifiedIcon} alt="" />
              : <img src={deleteIcon} alt="" />
            }
            店家地址（Google Maps 商家地址）：不得為空白
          </div>
          <div className={onlineEnabled ? 'success_text' : 'error_text'}>
            {
              onlineEnabled
              ? <img src={verifiedIcon} alt="" />
              : <img src={deleteIcon} alt="" />
            }
            線上預約開放狀態：開啟
          </div>
          <div className={maxBookingTimeDay >= 30 ? 'success_text' : 'error_text'}>
            {
              maxBookingTimeDay >= 30
              ? <img src={verifiedIcon} alt="" />
              : <img src={deleteIcon} alt="" />
            } 
            最早可預約時間：不可小於30天
          </div>
          <div className={!areaSettingEnabled ? 'success_text' : 'error_text'}>
            {
              !areaSettingEnabled
              ? <img src={verifiedIcon} alt="" />
              : <img src={deleteIcon} alt="" />
            }
            預約組別設定：關閉
          </div>
          <div className={!serviceTimeOptionEnabled ? 'success_text' : 'error_text'}>
            {
              !serviceTimeOptionEnabled
              ? <img src={verifiedIcon} alt="" />
              : <img src={deleteIcon} alt="" />
            }
            服務時間多選選項：關閉
          </div>
          <div className={!phoneVerification ? 'success_text' : 'error_text'}>
            {
              !phoneVerification
              ? <img src={verifiedIcon} alt="" />
              : <img src={deleteIcon} alt="" />
            }
            電話認證：關閉
          </div>
          <div className={!depositRequired ? 'success_text' : 'error_text'}>
            {
              !depositRequired
              ? <img src={verifiedIcon} alt="" />
              : <img src={deleteIcon} alt="" />
            }
            預先收款功能：關閉
          </div>
          <div className={!verifyRequired ? 'success_text' : 'error_text'}>
            {
              !verifyRequired
              ? <img src={verifiedIcon} alt="" />
              : <img src={deleteIcon} alt="" />
            }
            審核預約：關閉
          </div>
        </div>
        <div className='block_notice'>
          <div className='title'>其他說明</div>
          <ul>
            <li>調整預約設定需等待Google同步處理後，才會更新在Google Maps預訂功能上。</li>
            <li>Google預訂中，同一筆預約的人數上限為20人；若您的開放預約人數限制設定為20人以上，在Google預訂中仍僅開放20人。</li>
            <li>Google預訂目前不支援「自訂預約問卷」功能，若開啟此功能消費者仍可於Google Maps上建立預約，但無法填寫問卷內容。</li>
          </ul>
        </div>
        <hr className='hr-32 md-hidden' />
      </>
    );
  };

  const renderDisabled = () => {
    return (
      <div>
        <h4 style={{ margin: '80px 0 36px' }}>Google預訂功能目前不支援您的商家在Google Maps上所設定的地點類別。若需更新資訊，或有任何需要協助的地方，請聯繫MENU店+業務。</h4>
        <GoogleBusinessInfo
          google_reservation_enabled={google_reservation_enabled}
          google_merchant_category={google_merchant_category}
        />
      </div>
    )
  };

  if (!init) return <div className="pageLoading">讀取中..</div>;
  return (
    <>
      <div className='main overflow bookingSettingGoogleReservation'>
        {sending && (
          <div className="lightBoxLayer">
            <h4>儲存中</h4>
          </div>
        )}
        <h3>Google預訂設定</h3>
        <h5>您可以決定是否開啟Google預訂功能，允許消費者在Google Maps上直接進行預約</h5>
  
        <hr />
  
        {google_reservation_enabled ? renderEnabled() : renderDisabled()}
      </div>
  
      {google_reservation_enabled && (
        <div className='fix_bottom'>
          <button className='btn_submit' onClick={submit}>儲存</button>
        </div>
      )}
    </>
  )
}

export default BookingSettingGoogleReservation