//import React from 'react'
import React from 'react'
import $ from 'jquery'
// import _ from 'lodash'
import { config } from '../../utils/config'
import {handleError} from '../../libs/handler'
// import { CLIENT_RENEG_LIMIT } from 'tls';

import PropTypes from 'prop-types';
import zipData from '../../utils/zipData';
import twCity from '../../utils/twCity';
import businessType from '../../utils/businessType';

import GoogleReservationRequiredCheckModal from './bookingSettingsPopup/GoogleReservationRequiredCheckModal'
// import { Z_BLOCK } from 'zlib';

// var createReactClass = require('create-react-class');


export default class extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      init: false,
      isSaved: true,
      goRegister: false,
      sending: false,
      showGoogleReservationRequiredCheckModal: false,
    }
  }
  routerWillLeave = (nextLocation) => {
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if(!this.state.isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?'
  }

  change = (e) => {
    //var name = e.target.name
    var val = e.target.value
    this.setState({
        sms_enable : val,
        isSaved: false
    })
  }

  clickRegister = () => {
    this.setState({goRegister: true});
  }

  componentDidMount(){
    this.setState({
      emptyDepositAlert: false,
      emptyPeopleAlert: false,
      invalid_people_input: false,
      invalid_deposit_input: false,
    });
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
          method: 'GET', 
          xhrFields: { withCredentials: true }})
      .done(function(data){
        this.setState(data);
       // this.setState({init: true});
      }.bind(this))
      .fail(function(xhr){
        handleError(xhr);
      });

    $.ajax({url: window.domain + '/dashboard/booking_settings/spg_data',
      method: 'GET',
      xhrFields: { withCredentials: true }})
    .done(function(data){
      this.setState(data);
      this.setState({init: true});
    }.bind(this))
    .fail(function(xhr){
      handleError(xhr);
     });
  }

  componentDidUpdate(prevProps, prevState) {
    $('[data-toggle="tooltip"]').tooltip();
  }

  onChange = (e, msg) => {
    var name = e.target.name
    var val = e.target.value
    if(e.target.type == 'checkbox'){ val = e.target.checked }
    var result = {}
    result[name] = val;
    if (name == 'deposit_people_required' && val.length > 0){
      result['emptyPeopleAlert'] = false;
    }

    if (name == 'deposit' && val.length > 0){
      result['emptyDepositAlert'] = false;
    }

    if ((name == 'deposit_people_required') && val >= 0){
      result['invalid_people_input'] = false;
    }

    if ((name == 'deposit') && val >= 0){
      result['invalid_deposit_input'] = false;
    }

    this.setState(result, ()=>this.setState({isSaved: false}))

    // customize the error message for wrong input format
    if(msg){
      this.checkInput(e, msg)
    }
  }

  handleSubmit = () => {
    if (this.state.deposit_required && (this.state.deposit_people_required == '' || this.state.deposit_people_required == null || this.state.deposit_people_required == undefined || this.state.deposit_people_required.length == 0 )){
      this.setState({
				emptyPeopleAlert: true
			});
    }

    else if (this.state.deposit_required && (this.state.deposit == '' || this.state.deposit == null || this.state.deposit == undefined || this.state.deposit.length == 0 )){
      this.setState({
				emptyDepositAlert: true
			});
    }

    else if (this.state.deposit_required && this.state.deposit_people_required <= 0){
      this.setState({
				invalid_people_input: true
			});
    }

    else if (this.state.deposit_required && this.state.deposit <= 0 ){
      this.setState({
				invalid_deposit_input: true
			});
    }

    else if (this.state.google_reservation && this.state.deposit_required) {
      this.setState({showGoogleReservationRequiredCheckModal: true});
    }

    else{
      this.submit();
    }
  }

  submit = () =>{
    this.setState({sending: true, showGoogleReservationRequiredCheckModal: false})

    $.ajax({url: window.domain + '/dashboard/booking_settings', 
        data: {booking_settings:{
          verify_required: this.state.verify_required,
          deposit: this.state.deposit,
          deposit_required: this.state.deposit_required,
          verify_max_time: this.state.verify_max_time,
          pay_max_time: this.state.pay_max_time,
          booking_editable: this.state.booking_editable,
          spg_merchant_id: this.state.spg_merchant_id,
          spg_hash_key: this.state.spg_hash_key,
          spg_hash_iv: this.state.spg_hash_iv,
          deposit_people_required: this.state.deposit_people_required
        }},
        method: 'PATCH', 
        xhrFields: { withCredentials: true }})
      .done((data) => {
        this.setState({isSaved: true, sending: false})
        window.app.alert.setMessage("儲存成功", "done");
      })
      .fail(function(xhr){
        this.setState({sending: false})
        window.app.alert.setMessage("請稍後再試", "error");        
        handleError(xhr);
      });
    }

  submit_register = (e) =>{
    e.preventDefault();
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
        data: {payment_settings:{
          member_unified: this.state.member_unified,
          manager_id: this.state.manager_id,
          manager_id_type: this.state.manager_id_type,
          member_name: this.state.member_name,
          member_phone: this.state.member_phone,
          manager_name: this.state.manager_name,
          manager_name_e: this.state.manager_name_e,
          login_account: this.state.login_account,
          manager_mobile: this.state.manager_mobile,
          manager_email: this.state.manager_email,
          merchant_name: this.state.merchant_name,
          merchant_name_e: this.state.merchant_name_e,
          merchant_web_URL: config.booking_domain + "/" + this.state.shop.name,
          merchant_addr_city: this.state.merchant_addr_city,
          merchant_addr_area: this.state.merchant_addr_area,
          merchant_addr_code: this.state.merchant_addr_code,
          merchant_addr: this.state.merchant_addr,
          national_e: this.state.national_e,
          city_e: this.state.city_e,
          merchant_desc: this.state.merchant_desc,
          merchant_type: this.state.merchant_type,
          business_type: this.state.business_type,
          bank_code: this.state.bank_code,
          sub_bank_code: this.state.sub_bank_code,
          bank_account: this.state.bank_account
        }},
        method: 'POST', 
        xhrFields: { withCredentials: true }})
      .done((data) => {
        this.setState({isSaved: true})
        window.app.alert.setMessage("商店建立成功", "done");
        this.setState({goRegister: false});
        this.setState({register_success: true});
        this.setState(data);
      })
      .fail(function(xhr){
        //window.app.alert.setMessage(xhr.responseJSON.errors.join(' '), "error");
        window.app.alert.setMessage(xhr.responseJSON.errors, "m-alert m-alert-error");
        handleError(xhr);
      });
  }

  goSpg = () =>{
    window.open("https://www.newebpay.com/")
  }

  checkInput(e, msg){
    e.target.setCustomValidity("");
    if (!e.target.validity.valid){
      e.target.setCustomValidity(msg)
    }
  }

handleChange = (data) => {
  let keys_array = Object.keys(data)
  let result = {}
  keys_array.forEach(element => {
    result[element] = data[element]
  });
  this.setState(result, ()=>this.setState({isSaved: false}))
};

  render() {

    const {spg_merchant_id, spg_hash_key, spg_hash_iv, credit_enabled} = this.state;

    if(this.state.init && (spg_merchant_id == null || spg_merchant_id == '' ||
        spg_hash_key == null || spg_hash_key == '' ||
        spg_hash_iv == null || spg_hash_iv == '')){
       

        if (this.state.goRegister){
          //if (false){
            return (

                <div className="main overflow">
                  <div style={{display: 'flex'}}>
                    <div style={{flexGrow: 1}}>
                      <h3>預先收款功能</h3>
                      <h5>您可以設定是否開啟預先收款功能與收款條件。</h5>
                    </div>
                  </div> 
                  <hr style={{borderTop: "1px solid rgb(51, 51, 51, 0.3)"}}/>
                  <div className="settingTblRow">
                  <img style={{width:26, height:30}}src={require("../../images/pen_icon.png")} />
                  <div style={{fontSize: 24, display:"inline-Block", marginLeft:24, marginBottom:40}}>智付通企業帳戶註冊</div>
                  </div>
                  <form id="myform" className="settingTbl" style={{marginLeft: 50, marginTop:0}} onSubmit={this.submit_register}>
                    <div className="settingTblRow">
                      <div className="header" data-toggle="tooltip" data-placement="bottom" title="為企業會員登入所使用的帳號" style={{flexBasis: '15%'}}>
                        統一編號 <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                      </div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="member_unified" onChange={this.onChange} 
                         type="text" required />
                      </div>  
                    </div>

                    <div className="form-inline" style={{flexGrow:1, marginBottom: 16}}>
                      <div className="header col-sm-4" data-toggle="tooltip" data-placement="bottom" title="請提供與經濟部公司登記所記載相同之代表人身分證字號。若代表人擁有本國籍，請務必提供身分證字號，外國籍請提供居留證證號或稅籍編號" style={{padding: 0}}>
                        企業代表人身分證字號 <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                      </div>
                      <div className="col-sm-8"  style={{paddingLeft: 0}}>
                        <div>
                          <div className="radio" style={{marginRight: '33px', marginTop: 5, fontSize:18}}>
                            <label>
                              <input type="radio" name="manager_id_type" onClick={e => this.onChange(e)} required value="1"/>
                              {"本國籍"}
                            </label>
                          </div>
                          <div className="radio" style={{marginRight: '33px', marginTop: 5, fontSize:18}}>
                            <label>
                              <input type="radio" name="manager_id_type" onClick={e => this.onChange(e)}  value="2"/>
                              {"居留證號"}
                            </label>
                          </div>
                          <div className="radio" style={{marginRight: '33px', marginTop: 5, fontSize:18}}>
                            <label>
                              <input type="radio" name="manager_id_type" onClick={e => this.onChange(e)}  value="3"/>
                              {"稅務編號 "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}></div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="manager_id" onChange={this.onChange} type="text" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" data-toggle="tooltip" data-placement="bottom" title="您提領智付通帳戶款項所使用的金融帳號戶名" style={{flexBasis: '15%'}}>
                        企業名稱 <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                      </div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} maxLength="60"
                        name="member_name" onChange={this.onChange} type="text" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>會員電話</div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="member_phone" placeholder="例: 0x-000111 或 09xx-000111" 
                         onChange={(e) => this.onChange(e, e.target.placeholder)} type="text" pattern="\d+-\d+" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" data-toggle="tooltip" data-placement="bottom" title="管理者帳號密碼為企業會員的最高權限人員所持有，該人員即為本服務主要聯絡人，請正確填寫。" style={{flexBasis: '15%'}}>
                        管理者中文姓名 <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                      </div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="manager_name" onChange={this.onChange} type="text" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>管理者英文姓名</div>
                      <div className="text">
                        <input className="form-control input-lg" name="manager_name_e" placeholder="格式為名, 姓，例: Xiao ming,Wang" style={{width: '80%'}}
                        onChange={(e) => this.onChange(e, e.target.placeholder)} type="text" pattern="[a-zA-Z,. ]+" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" data-toggle="tooltip" data-placement="bottom" title='為企業會員登入所使用的管理者帳號，英數混合、最長請勿超過20個字元。可接受"_" "." "@"三種符號' style={{flexBasis: '15%'}}>
                        管理者帳號  <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                      </div>
                      <div className="text">
                        <input className="form-control input-lg" name="login_account"  placeholder="只接受英文、數字及'_' '.' '@'三種符號" style={{width: '80%'}}
                        onChange={(e) => this.onChange(e, e.target.placeholder)} type="text" pattern="[0-9a-zA-Z_.@]+" maxLength="20" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>管理者行動電話號碼</div>
                      <div className="text">
                        <input className="form-control input-lg" name="manager_mobile" placeholder="格式為10碼數字，例:0912000111" style={{width: '80%'}}
                        onChange={(e) => this.onChange(e, e.target.placeholder)} type="text" pattern="[0-9]{10}" maxLength="10" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>管理者E-mail</div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="manager_email" onChange={this.onChange} type="email" 
                         maxLength="40" required />
                      </div>
                    </div>
                    <div>
                      <hr style={{borderTop: "1px solid rgb(51, 51, 51, 0.3)", marginTop:52, marginBottom:52}}/>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>店家中文名稱</div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="merchant_name" onChange={this.onChange} type="text"  maxLength="20" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>店家英文名稱</div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="merchant_name_e" onChange={this.onChange} type="text" maxLength="100" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>聯絡地址</div>
                      <div className="text">
                        <TWzipcode
                          //css={['form-control county-sel', 'form-control ', 'form-control ']}
                          handleChangeCounty={this.handleChange}
                          handleChangeDistrict={this.handleChange}
                          handleChangeZipcode={this.handleChange}
                          countyValue={ this.state.county }
                        />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}></div>
                      <div className="text">
                        <input className="form-control input-lg" name="merchant_addr" onChange={this.onChange} type="text" required />
                      </div>
                    </div>
                    
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>設立登記營業國家/城市(英)</div>
                      <div className="text ">

                          <div className="col-sm-3" style={{padding: 0, width:114}}>
                            <select
                              name="national_e"
                              className="form-control input-lg"
                              style={{padding: 10}}
                              //onChange={ this.onChange }
                              value={ this.state.national_e ? this.state.national_e : '' }
                            >
                              <option value="Taiwan">Taiwan</option>);
                            </select>
                          </div>
                          <div className="col-sm-6" style={{marginLeft: 3}}>
                          <Dropdown
                            data = {twCity}
                            handleChange = {this.handleChange}
                            selectName = "city_e"
                            selectValue = {this.state.city_e}
                          />
                          </div >
                        
                      </div>
                    </div>
                    
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>販售商品型態</div>
                      <div className="text">
                      <div style={{fontSize: '16px'}} className="form-inline">
                        <Dropdown
                          data = {['1.實體商品', '2.服務', '3.虛擬商品', '4.票券']}
                          handleChange = {this.handleChange}
                          selectName = "merchant_type"
                          selectValue = {this.state.merchant_type}
                        />
                        </div>
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>商品類別</div>
                      <div className="text">
                      <div style={{fontSize: '16px'}} className="form-inline">
                        <Dropdown
                          data = {businessType}
                          handleChange = {this.handleChange}
                          selectName = "business_type"
                          selectValue = {this.state.business_type}
                          required
                        />
                        </div>
                      </div>
                    </div>
                    <div className="settingTblRow">

                      <div className="header" style={{flexBasis: '15%'}}>商店簡介</div>

                      <div className="text">
                        <textarea className="form-control input-lg" style={{maxWidth:523, height:168}} name="merchant_desc" placeholder="請填寫合作商店簡介，字數為255字以內" onChange={this.onChange} type="text" required maxLength='255' resize='vertical' />
                      </div>

                    </div>

                    <div>
                      <hr style={{borderTop: "1px solid rgb(51, 51, 51, 0.3)", marginTop:52, marginBottom:52}}/>
                    </div>
                    <div className="settingTblRow" style={{ width: '100%'}}>
                      <div className="header " style={{ flexBasis: '15%' }}>帳戶注意事項</div>
                      <div className="text" style={{ paddingLeft: 0 }}>
                        <ul style={{ fontSize: '16', paddingLeft: '20' }}>
                          <li >您所設定的銀行帳戶將成為日後接受提領匯入款項的帳號。</li>
                          <li>您提供的銀行帳戶都需經過身份驗證才能開始接受指定匯入。</li>
                          <li>驗證作業約需<span style={{ color: '#3fba87'}}>五個工作天。</span></li>
                          <li>銀行帳戶戶名必定為會員所有，請勿填寫與戶名不符的銀行資料。</li>
                        </ul>      
                      </div>
                      </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>金融機構代碼</div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="bank_code" onChange={this.onChange } type="text" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>分行代碼</div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="sub_bank_code" onChange={this.onChange } type="text" required />
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>帳戶戶名</div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="bank_account" value={this.state.member_name} disabled/>
                      </div>
                    </div>
                    <div className="settingTblRow">
                      <div className="header" style={{flexBasis: '15%'}}>帳號帳戶</div>
                      <div className="text">
                        <input className="form-control input-lg" style={{width: '80%'}} name="bank_account" onChange={this.onChange } type="text" required />
                      </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'left', marginTop:70}}>
                      <input className="msBtn msBtnMain" style={{width: 170, height: 48}} type="submit" value="註冊" />
                    </div>
                  </form>
                </div>
            )
          }
        if (this.state.register_success){
        //else{
          return(
            <div className="main overflow">
              <div style={{flexGrow: 1 }}>
                <h3>預先收款功能</h3>
                <h5>您可以設定是否開啟預先收款功能與收款條件。</h5>
              </div>
              <hr/>
              <div className="settingTbl" style={{ marginLeft: 0}}>
                <div className="settingTblRow">
                  <div className="rangeTitle" style={{marginTop: 44}}>
                    <img src={require("../../images/check_icon.png")} />
                    <h4 style={{color:'#3fba87', fontWeight: 'bold'}}>恭喜您，已經完成智付通企業會員註冊</h4>
                  </div>
                </div>

                <table className="table table-bordered table-hover" width="100%">

                  <tbody>
                    <tr>
                      <td colSpan={2}>您的註冊資料如下:</td>
                    </tr>
                    <tr>
                      <td>公司名稱 {this.state.member_name}</td>
                      <td>商店名稱 {this.state.merchant_name} </td>
                    </tr>
                    <tr>
                      <td>商店代號 {this.state.merchant_id}</td>
                      <td>統一編號 {this.state.member_unified}</td>
                    </tr>
                    <tr>
                      <td>管理帳號 {this.state.login_account}</td>
                      <td>公司電話 {this.state.member_phone}</td>
                    </tr>
                    <tr>
                      <td>管理者姓名 {this.state.manager_name}</td>
                      <td>管理者手機 {this.state.manager_mobile}</td>
                    </tr>
                    <tr>
                      <td>管理者信箱 {this.state.manager_email}</td>
                      <td></td>
                    </tr>
                    </tbody>

                </table>

                <div className="settingTblRow "style={{marginTop: 40, marginBottom: 8}}>
                  <h5 style={{ color:'#3fba87', fontSize: 18, margin: 'unset'}}>帳戶驗證說明</h5>
                </div>

                <div className="settingTblRow" style={{marginBottom: 20}}>
                  <div className="text" style={{ paddingLeft: 0 , color:'#000000'}}>
                    <ul style={{ fontSize: '16', paddingLeft: '20' }}>
                      <li >會員及商店建立完成後，智付通將發送「<span style={{ color: '#3fba87'}}>會員註冊完成通知信</span>」及「<span style={{ color: '#3fba87'}}>會員商店建立完成通知信</span>」給您。</li>
                      <li>智付通將主動進行<span style={{ color: '#3fba87'}}>融機構帳戶一元驗證作業</span>，以存入新台幣一元至您設定於智付通平台金融機構帳戶之方式，
                          進行驗證帳戶資訊是否有效（一元無需返回），成功後您將會收到由智付通發出的驗證完成通知信，若失敗智付通將發送
                          電子信件通知合作商店，合作商店需至智付通平台重新提供正確金融機構帳戶資訊，並重新驗證。
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="settingTblRow "style={{marginBottom: 8}}>
                  <h5 style={{ color:'#3fba87', fontSize: 18, margin: 'unset'}}>信用卡收款說明</h5>
                </div>

                <div className="settingTblRow" style={{marginBottom: 20}}>
                     <div className="text" style={{ paddingLeft: 0 , color:'#000000'}}>
                        <ul style={{ fontSize: '16', paddingLeft: '20' }}>
                          <li >MENU店家提供顧客線上信用卡付款（無分期付款，需一起付清），在智付通帳戶及商店建立完成後，
                               您必須等待<span style={{ color: '#3fba87'}}>3-4</span>天由智付通啟用您的信用卡收款後，方可使用本系統進行線上收款。
                          </li>
                          <li>您可以登入您的智付通帳戶，至[銷售中心] > [商店詳細資料]，查看您的信用卡收款方式申請進度。
                          </li>
                        </ul>
                      </div>
                  </div>

                  <div className="settingTblRow "style={{marginBottom: 8}}>
                    <h5 style={{ color:'#3fba87', fontSize: 18, margin: 'unset'}}>修改密碼說明</h5>
                  </div>

                  <div className="settingTblRow" style={{marginBottom: 20}}>
                     <div className="text" style={{ paddingLeft: 0 , color:'#000000'}}>
                        <ul style={{ fontSize: '16', paddingLeft: '20' }}>
                          <li >您可立即登入智付通帳戶修改您的密碼，網站路徑為：《登入》>《企業會員登入》>《會員中心》>《基本資料設定》>《異動密碼》。
                          </li>
                          <li>企業會員管理者帳號為「管理者帳號」，密碼預設為「企業統編」。
                          </li>
                        </ul>
                      </div>
                </div>

              </div>
              <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                <button className="msBtn msBtnMain" onClick={this.goSpg}>前往智付通</button><br/><hr/>
              </div>
            </div>
        )
      }

      return (
       
      <div className="main overflow">
        <div style={{flexGrow: 1}}>
          <h3>預先收款功能</h3>
          <h5>您可以設定是否開啟預先收款功能與收款條件。</h5>
        </div> 
        <hr/>

        <div style={{fontSize: '24px', color: '#575757', letterSpacing: '1px', marginTop: '10px', display: 'flex', marginTop: 80, marginLeft: 50}}>
          開始啟用收款服務!<br/>
          請您先註冊智付通會員，同時建立合作商店，<br/>
          即可開始以該商店帳號開始收款。
        </div>
        <div style={{fontSize: '14px', color: '#575757', letterSpacing: '1px', marginTop: '10px', display: 'flex', marginLeft: 50}}>
          目前暫時提供企業會員註冊，如您需註冊個人帳戶收款，請直接與menu店+聯絡，由專人協助您開立帳戶。
          </div>
        <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginLeft:50}}>
          <button className="msBtn msBtnMain" onClick={this.clickRegister}>開始註冊</button><br/><hr/>
        </div> 
      </div>

      )
      
    }

    if(this.state.init){
      return (
        <>
          <div className="main overflow">
            {this.state.sending && (
              <div className='lightBoxLayer'>
                <h4>儲存中</h4>
              </div>
            )}
            <div style={{display: 'flex'}}>
              <div style={{flexGrow: 1}}>
                <h3>預先收款功能</h3>
                <h5>您可以設定是否開啟預先收款功能與收款條件。</h5>
              </div> 
            </div>
            <hr/>
            <div className="settingTbl" style={{marginLeft: 0}}>
            { credit_enabled ? "":
              (<div className="settingTblRow">
                <div className="header" style={{color:'#3fba87', width: '100%'}}>您的信用卡收款功能尚等待智付通審核中，目前無法線上收款，請耐心等候。</div>
              </div>)
            }
              <div className="settingTblRow">
                <div className="header">商店代號 </div>
                <div className="text"> {spg_merchant_id} </div>
              </div>
              <div className="settingTblRow">
                <div className="header">線上付款 </div>
                <div className="text">
                  <label className="msSwitch">
                    <input type="checkbox" name="deposit_required" onChange={this.onChange} disabled={!this.state.credit_enabled} 
                          checked={this.state.deposit_required && this.state.credit_enabled} />
                    <span className="slider"></span>
                  </label>
                  
                </div>
              </div>
              <div className="settingTblRow">
                <div className="header" data-toggle="tooltip" data-placement="bottom" title="顧客線上建立的預約若在您指定的人數以上，將會自動開啟線上刷卡預付訂金。">
                  付款條件 <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                </div>
                <div className="text">
                  <input onChange={this.onChange} defaultValue={this.state.deposit_people_required} disabled={!this.state.deposit_required || !this.state.credit_enabled}
                      name="deposit_people_required" type="number" min="1" style={(this.state.emptyPeopleAlert || this.state.invalid_people_input) ? {width: '114px', display:'inline-block', borderColor: "#CE3636"}:{width: '114px', display:'inline-block'}} className="form-control input-lg" />
                  &nbsp;人以上（含)，每人&nbsp;
                  <input onChange={this.onChange} defaultValue={this.state.deposit} disabled={!this.state.deposit_required || !this.state.credit_enabled}
                      name="deposit" type="number" min="1" style={(this.state.emptyDepositAlert || this.state.invalid_deposit_input) ? {width: '114px', display:'inline-block', borderColor: "#CE3636"}:{width: '114px', display:'inline-block'}} className="form-control input-lg" />
                  &nbsp;元
                  {this.state.emptyDepositAlert || this.state.emptyPeopleAlert ? <div style={{ color: '#CE3636', fontSize: '16px'}}>人數與金額不可為空白！</div> :""}
                  {this.state.invalid_people_input || this.state.invalid_deposit_input ? <div style={{ color: '#CE3636', fontSize: '16px'}}>請填寫大於或等於1的人數或金額。</div> :""}
                </div>

              </div>
              <div className="settingTblRow">
                <div className="header">注意事項</div>
                <div className="text">
                <div>
                <ul style={{ fontSize: '16', paddingLeft: '20' }}>
                            <li>開啟線上付款功能後，所有線上預約的客戶都必須在預約成立後<span style={{ color: '#ce4949'}}>30分鐘</span>內完成線上信用卡付款，此筆預約才會成功，
                                若逾期系統則會自動取消預約。
                            </li>
                            <li>顧客線上付款的金額將匯入您申請的智付通帳戶，您可登入智付通帳號查詢相關帳務以及撥款明細。
                                MENU店家不會介入您與智付通或您與付款人間的帳務問題，請您直接聯絡智付通或是付款人。
                            </li>
                            <li>智付通企業會員管理者帳號為「管理者帳號」，密碼預設為「企業統編」。</li>
                </ul>
              </div>
                </div>
              </div>


            </div>

          </div>

          <hr className='main_width' />

          <div className='fix_bottom'>
            {
              credit_enabled 
              ? (
                <button
                  className='btn_submit'
                  onClick={this.handleSubmit}
                >
                  儲存
                </button>
              )
              : ''
            }
          </div>

          { this.state.showGoogleReservationRequiredCheckModal && 
            <GoogleReservationRequiredCheckModal
              depositRequired={this.state.deposit_required}
              setGoogleReservationRequiredCheckModal={() => this.setState({ showGoogleReservationRequiredCheckModal: false })}
              submit={this.submit}
            />
          }
        </>
      );
    }else {
      return <div className="pageLoading">讀取中..</div>
    }
  }
}

class TWzipcode extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      county: '',
      counties: Object.keys(zipData),
      district: '',
      districts: [],
      zipcode: '',
    }
  }

  static propTypes = {
    countyFieldName: PropTypes.string,
    countyValue: PropTypes.string,
    districtFieldName: PropTypes.string,
    districtValue: PropTypes.string,
    handleChangeCounty: PropTypes.func,
    handleChangeDistrict: PropTypes.func,
    handleChangeZipcode: PropTypes.func,
    zipcodeFieldName: PropTypes.string,
    zipcodeValue: PropTypes.string,
  };

  static defaultProps = {
    countyFieldName: 'merchant_addr_city',
    countyValue: '',
    districtFieldName: 'merchant_addr_area',
    districtValue: '',
    handleChangeCounty: undefined,
    handleChangeDistrict: undefined,
    handleChangeZipcode: undefined,
    zipcodeFieldName: 'merchant_addr_code',
    zipcodeValue: '',
  };

  componentDidMount() {

    const {
      countyValue,
      districtValue,
      detect,
      zipcodeValue,
    } = this.props;
    const counties = Object.keys(zipData);
    const county = (countyValue === '') ? '' : countyValue;
    let districts = ['--'];
    const district = (districtValue === '') ?  '' : districtValue;
    let zipcode = '';
    this.setState({
      county,
      counties,
      district,
      districts,
      zipcode,
    });
  }
  
  changeCounty = (county) => {
    const districts = Object.keys(zipData[county]).map((d) => d, []);
    const { handleChangeCounty } = this.props;
    this.setState({
      county,
      district: districts[0],
      zipcode: zipData[county][districts[0]],
      districts,
    }, () => {
      if (typeof handleChangeCounty === 'function') {
        handleChangeCounty({
          merchant_addr_city: this.state.county,
          merchant_addr_area: this.state.district,
          merchant_addr_code: this.state.zipcode,
        });
      }
    });
  };

  changeDistrict = (district) => {
    const zipcode = zipData[this.state.county][[district][0]];
    const { handleChangeDistrict } = this.props;

    this.setState({
      district,
      zipcode,
    }, () => {
      if (typeof handleChangeDistrict === 'function') {
        handleChangeDistrict({
          merchant_addr_area: this.state.district,
          merchant_addr_code: this.state.zipcode,
        });
      }
    });

  };

  render() {
    const {
      countyFieldName,
      districtFieldName,
      zipcodeFieldName,
      zipcodePlaceholder,
    } = this.props;

    const {
      counties ,
      county,
      districts,
      district,
      zipcode,
    } = this.state;

    return (
      <div >
        <tr>
          <td>
            <div style={{marginRight: 10}}>
              <County
                fieldName={ countyFieldName }
                className=""
                data={ counties }
                value={ county }
                changeCounty={ this.changeCounty }
              />
             </div>
          </td>
          <td>
            <div style={{marginRight: 10}}>
              <District
                fieldName={ districtFieldName }
                className=""
                data={ districts }
                value={ district }
                changeDistrict={ this.changeDistrict }
              />
            </div>
          </td>
          <div >
            <ZipCode
              name={ zipcodeFieldName }
              className=""
              value={ zipcode }
              placeholder={ zipcodePlaceholder }
            />
           </div>
        </tr>
      </div>
    );
  }
}

class County extends React.Component {
  static propTypes = {
    changeCounty: PropTypes.func,
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    fieldName: PropTypes.string,
  };

  onChange = (e) => {
    this.props.changeCounty(e.target.value);
  };

  render() {
    const {
      data,
      fieldName,
      className,
      value,
    } = this.props;

    const counties = data.map((v) =>
      <option key={ v } value={ v }>{v}</option>);
    return (
      <select
        name={ fieldName }
        style={{padding: 10}}
        className="form-control input-lg" 
        onChange={ this.onChange }
        value={ value }
        required
      >
      <option value='' disabled >城市</option>);
      { counties }
      
      </select>
    );
  }
}

class District extends React.Component {
  static propTypes = {
    changeDistrict: PropTypes.func,
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    fieldName: PropTypes.string,
  };

  onChange = (e) => {
    const currentDistrict = e.target.value;
    if (currentDistrict != '--'){
      this.props.changeDistrict(currentDistrict);
  }
  };

  render() {

    const {
      data,
      fieldName,
      className,
      value,
    } = this.props;

    const districts = data.map((v) =>
      <option key={ v } value={ v }>{v}</option>);

    return (
      <select
        name={ fieldName }
        style={{padding: 10}}
        className="form-control input-lg"
        onChange={ this.onChange }
        value={ value }
        require
      >
        <option value='' disabled >區</option>);
        { districts }
      </select>
    );
  }
}

class ZipCode extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    fieldName: PropTypes.string,
    placeholder: PropTypes.string,
  };

  render() {
    return (
      <input
        type="text"
        className="form-control input-lg"
        style={{width: 150}}
        name={ this.props.fieldName }
        value={ this.props.value }
        placeholder={ "郵遞區號" }
        maxLength="3"
        disabled
      />
    );
  }
}

class Dropdown extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value:'',
  }
  }
  
  static propTypes = {
    handleChangeCounty: PropTypes.func,
    selectName: PropTypes.string,
    selectValue: PropTypes.string,
  };

  static defaultProps = {
    data: [],
    handleChangeCounty: undefined,
    selectName: '',
    selectValue: '',
  };

  onChange = (e) => {
    const keyName = e.target.name;
    const currentValue = e.target.value;
    this.props.handleChange({[keyName]: currentValue});
  };

  render() {

    const {
      data,
      handleChange,
      selectName,
      selectValue,
    } = this.props;
    
    const options = data.map((v) =>
      <option value={ v }>{v}</option>);
    const value = (selectValue === '') ? '' : selectValue;

    return (
      <select
        name={ this.props.selectName }
        className="form-control input-lg"
        onChange={ this.onChange }
        value = { value }
        required
      > 
      <option value='' disabled>請選擇</option>)
        { options }
      </select>
    );
  }
}