import { useCallback, useState } from 'react';
import { getNewModuleSearchRes } from '../api/getSearchRes';

export default (setInit) => {
	const [ newModuleLoading, setNewModuleLoading ] = useState(false);
	const [ newModuleSearchRes, setNewModuleSearchRes ] = useState({});

	return {
		newModuleLoading,
		newModuleSearchRes,
		setNewModuleSearchRes,
		newModuleSubmit: useCallback(
			async (searchInput) => {
				setNewModuleLoading(true);
				await getNewModuleSearchRes(searchInput)
					.then(({ data }) => {
						setInit(true);
						setNewModuleSearchRes(data);
						setNewModuleLoading(false);
					})
					.catch((err) => {
						console.log('search err---', err);
					});
			},
			[ setInit ]
		)
	};
};
