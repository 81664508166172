import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Modal } from 'antd';

import {
  announcementFetchAll,
  handleHeaderFilter,
  announcementOfficialFetch,
  announcementFetchMonth,
  updateDayAnnouncements,
  announcementSelected,
  announcementSettingCancel,
  clearAnnouncement,
  handleSearchList
} from '../../../actions/announcementAction';

import {
  deleteAnnouncement,
  updateAnnouncement,
  bulkDeleteAnnouncement,
  bulkFinishedAnnouncement
} from '../api/AnnouncementApi';
import {
  updateOfficialAnnouncement,
  deleteOfficialAnnouncement,
  bulkDeleteOfficialAnnouncement,
  bulkFinishedOfficialAnnouncement
} from '../api/OfficialAnnouncementApi';

const AnnouncementCancelModal = () => {
  const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
    role,
    currentDate,
    currentFilter,
    selected,
		settingType,
    showAnnouncementListModal,
    searchListTxt
	} = announcementListReducer;
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    id,
    category,
    title,
    content,
    startDateTime,
    endDateTime,
    showEnabled,
    officialShowEnabled
  } = announcementInfo;
  const dispatch = useDispatch();
  const modalTitle = settingType === 'deleted' ? '刪除' : '設為結束';

  const cancelPopup = () => {
    dispatch(announcementSettingCancel());
  };

  const handleDeleted = async() => {
    // 多選
    if(!id && selected.length !== 0) {
      try {
        await bulkDeleteAnnouncement({ id: selected });

        dispatch(announcementSelected([]));
        dispatch(announcementFetchAll());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage('已刪除公告', 'done');

        if(searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt))
        }
      } catch(err) {
        console.log('bulk deleted announcements err ----------', err);
      }
    } else {
      try {
        await deleteAnnouncement(id);

        dispatch(announcementFetchAll());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage('已刪除公告', 'done');

        if(showAnnouncementListModal) {
          dispatch(updateDayAnnouncements(currentDate));
          dispatch(announcementFetchMonth(currentDate));
        }

        if(searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt))
        }
      } catch(err) {
        console.log('deleted announcements err ----------', err);
      }
    }
  };

  const handleOfficialDeleted = async() => {
    if(!id && selected.length !== 0) {
      try {
        await bulkDeleteOfficialAnnouncement({ id: selected });

        dispatch(announcementSelected([]));
        dispatch(announcementOfficialFetch());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage('已刪除公告', 'done');

        if(searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt))
        }
      } catch(err) {
        console.log('bulk deleted official announcements err ----------', err);
      }
    } else {
      try {
        await deleteOfficialAnnouncement(id);

        dispatch(announcementOfficialFetch());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage('已刪除公告', 'done');

        if(searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt))
        }
      } catch(err) {
        console.log('deleted official announcement err ----------', err);
      }
    }
  };

  const handleFinished = async() => {
    let announcement = {
      title: title,
      content: content,
      category: category,
      finished: true,
      start_time: moment(startDateTime),
      end_time: moment(endDateTime),
      show_enabled: showEnabled
    }

    if(!id && selected.length !== 0) {
      try {
        await bulkFinishedAnnouncement({ id: selected });
        dispatch(announcementSelected([]));
        dispatch(announcementFetchAll());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage('已設為結束公告', 'done');

        if(searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt))
        }
      } catch(err) {
        console.log('bulk finished announcements err ----------', err);
      }
    } else {
      try {
        await updateAnnouncement(id, announcement);
        dispatch(announcementFetchAll());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage('已設為結束公告', 'done');

        if(showAnnouncementListModal) {
          dispatch(updateDayAnnouncements(currentDate));
          dispatch(announcementFetchMonth(currentDate));
        }

        if(searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt))
        }
      } catch(err) {
        console.log('finished announcements err ----------', err);
      }
    }
  };

  const handleOfficialFinished = async() => {
    let announcement = {
      title: title,
      content: content,
      category: 'regular',
      start_time: moment(startDateTime),
      end_time: moment(endDateTime),
      finished: true,
      enabled: officialShowEnabled
    }

    if(!id && selected.length !== 0) {
      try {
        await bulkFinishedOfficialAnnouncement({ id: selected });

        dispatch(announcementSelected([]));
        dispatch(announcementOfficialFetch());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage('已設為結束公告', 'done');

        if(searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt))
        }
      } catch(err) {
        console.log('bulk finished official announcements err ----------', err);
      }
    } else {
      try {
        await updateOfficialAnnouncement(id, announcement);

        dispatch(announcementOfficialFetch());
        dispatch(handleHeaderFilter(currentFilter));
        dispatch(clearAnnouncement());
        dispatch(announcementSettingCancel());
        window.app.alert.setMessage('已設為結束公告', 'done');

        if(searchListTxt.length > 0) {
          dispatch(handleSearchList(searchListTxt))
        }
      } catch(err) {
        console.log('finished official announcements err ----------', err);
      }
    }
  };

  const renderBtn = () => {
    if(settingType === 'deleted') {
      return (
        <div>
          <button
            className='btn_cancel'
            onClick={() => cancelPopup()}
          >
            取消
          </button>
          <button
            className='btn_save'
            onClick={role === 'shop' ? handleDeleted : handleOfficialDeleted}
          >
            刪除
          </button>
        </div>
      )
    } else {
      return (
        <div>
          <button
            className='btn_cancel'
            onClick={() => cancelPopup()}
          >
            取消
          </button>
          <button
            className='btn_save'
            onClick={role === 'shop' ? handleFinished : handleOfficialFinished}
          >
            確認
          </button>
        </div>
      )
    }
  }

  const renderContent = () => {
    const selectedText = `已選取 ${selected.length} 則公告，`;

    if(settingType === 'deleted') {
      return (
        <div>{!id && selectedText }刪除後將不會保留公告內容。確定刪除嗎？</div>
      )
    } else {
      return (
        <div>{!id && selectedText }設為結束將不再顯示於系統首頁與預約系統，若要再顯示需重新排定時間。確定設為結束嗎？</div>
      )
    }
  };

  return (
    <Modal
      title={`確定${modalTitle}嗎？`}
      visible
      centered
      className="announcementCancelModal"
      onCancel={cancelPopup}
      footer={renderBtn()}
			transitionName="ant-move-down"
      maskClosable={false}
    >
      { renderContent() }
    </Modal>
  );
};

export default AnnouncementCancelModal;