import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import BookingSystemApi from '../../booking_settings/BookingSystemApi';

import { WarningTemplate } from '../NewModuleComponent/NewModuleWarning';

import { seatAlertTitle, bookingStatus, useInterval } from '../utils/data';
import { Portal } from '../utils/Portal';

const BookingAPI = new BookingSystemApi();

export const StatusOptions = ({ isToday, date, switchMode, swithcStatus, booking }) => {
	const [ currentTime, setCurrentTime ] = useState(moment());
	const today = moment();
	const pre = moment(new Date(booking.booking_datetime)).add(-60, 'm');
	const tomorrow = moment(new Date(today)).add(1, 'd');
	const [ delay, setDelay ] = useState(isToday ? 1000 : null);
	const [ bookingStatusBtn, setBookingStatusBtn ] = useState(3); //1: 前一天 2:當天時間>預約60分鐘 3:當天時間<=預約60分鐘
	const [ showWarning, setShowWarning ] = useState(false);
	const [ warningTales, setWarningTales ] = useState([]);

	const renderBookingStatusOptionHeader = () => {
		let statusStyle = booking.status + 'Status';

		return (
			<div className={`bookingStatusOption__header ${statusStyle}`}>
				<h3 className="currentBooking__header__title">{booking.time}</h3>
				<button onClick={() => switchMode(1, {})}>
					<i className="fa fa-times" aria-hidden="true" />
				</button>
			</div>
		);
	};

	const findStatus = useCallback(
		() => {
			if (currentTime.isAfter(new Date(date), 'date')) {
				//過去: 前一天
				//待確認 確認預約 取消預約 完成預約
				//不顯示 finish show
				setBookingStatusBtn(1);
			} else if (currentTime.isBefore(new Date(pre))) {
				//當天時間>預約60分鐘
				//待確認 確認預約 已到店 取消預約
				setBookingStatusBtn(2);
			} else if (currentTime.isBetween(new Date(pre), new Date(tomorrow), null, '[)')) {
				//當天時間<=預約60分鐘
				//待確認 確認預約 已到店 取消預約 未到店 已入座 完成消費
				setBookingStatusBtn(3);
			}
		},
		[ currentTime, date, pre, tomorrow ]
	);

	useEffect(
		() => {
			if (isToday) {
				setDelay(1000);
			} else {
				setDelay(null);
			}
			findStatus();
		},
		[ isToday, findStatus ]
	);

	useInterval(() => {
		setCurrentTime(moment());
	}, delay);

	const seatedWarning = useCallback(
		() => {
			BookingAPI.checkSeated(booking.id).then((data) => {
				if (data.overlay_table.length !== 0) {
					setShowWarning(true);
					setWarningTales(data.overlay_table);
				} else {
					swithcStatus(booking, 'seated');
				}
			});
		},
		[ booking, swithcStatus ]
	);

	const renderBookingStatusOption = useCallback(
		() => {
			return (
				<ul>
					{bookingStatus.map((t) => {
						if (bookingStatusBtn === 1) {
							if (t.status === 'show' || t.status === 'no_show' || t.status === 'seated') {
								return null;
							}
						} else if (bookingStatusBtn === 2) {
							if (t.status === 'no_show' || t.status === 'seated' || t.status === 'finish') {
								return null;
							}
						}

						if (t.status === 'seated') {
							return (
								<li key={t.status}>
									<span className={`${t.status + 'Status'}`} onClick={() => seatedWarning()}>
										{t.status_t}
									</span>
								</li>
							);
						} else {
							return (
								<li key={t.status}>
									<span
										className={`${t.status + 'Status'}`}
										onClick={() => swithcStatus(booking, t.status)}
									>
										{t.status_t}
									</span>
								</li>
							);
						}
					})}
				</ul>
			);
		},
		[ bookingStatusBtn, seatedWarning, booking, swithcStatus ]
	);

	const cancelWarning = () => {
		setShowWarning(false);
		setWarningTales([]);
	};

	const confirmWarning = () => {
		setShowWarning(false);
		swithcStatus(booking, 'seated');
		setWarningTales([]);
	};

	return (
		<div className="bookingStatusOption">
			{renderBookingStatusOptionHeader()}

			<div className="bookingStatusOption__body">{renderBookingStatusOption()}</div>

			{showWarning && (
				<Portal>
					<WarningTemplate
						cancelWarning={cancelWarning}
						confirmWarning={confirmWarning}
						title={seatAlertTitle}
					>
						<p>
							桌次
							{warningTales.map((table) => {
								return (
									<span key={table} className="tableName">
										{table}
									</span>
								);
							})}
							有尚未結束的預約
						</p>
						<p>是否確定入座？</p>
					</WarningTemplate>
				</Portal>
			)}
		</div>
	);
};