import React, { Fragment } from "react";
import { Modal } from 'antd';
import './customAntdPopup.scss';

const ConfirmPopup = ({
  title,
  content,
  submit,
}) => {
  return (
    <Modal
      title={title}
      visible
      width={374}
      centered
      className="common_ant_modal"
      footer={
        <button
          className="btn_save"
          onClick={submit}
        >
          確定
        </button>
      }
      transitionName="ant-move-down"
      maskClosable={false}
    >
      {content}
    </Modal>
  )
}

export default ConfirmPopup;