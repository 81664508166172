import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Modal, DatePicker } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import {
  announcementFetchAll,
  handleHeaderFilter,
  announcementFetchMonth,
  announcementOfficialFetch,
  updateDayAnnouncements,
  showAnnouncementCancelModal,
  showAnnouncementAddModal,
  announcementChange,
  clearAnnouncement,
  handleSearchList
} from '../../../actions/announcementAction';

import {
  createAnnouncement,
  updateAnnouncement
} from '../api/AnnouncementApi';
import { 
  createOfficialAnnouncement,
  updateOfficialAnnouncement
} from '../api/OfficialAnnouncementApi';

const { RangePicker } = DatePicker;

const AnnouncementAddModal = () => {
  const announcementInfo = useSelector((state) => state.addAnnouncementReducer);
  const {
    id,
    category,
    title,
    content,
    startDateTime,
    endDateTime,
    finished,
    showEnabled,
    officialShowEnabled
  } = announcementInfo;

  const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
    role,
    currentDate,
    currentFilter,
    searchListTxt,
    showAnnouncementListModal
	} = announcementListReducer;

  const dispatch = useDispatch();
  const [ isSaved, setIsSaved ] = useState(true);

  useEffect(() => {
    const titleInput = document.getElementsByClassName('announcementTitle')[0];
    const contentInput = document.getElementsByClassName('announcementContent')[0];

    if(title.length > 0) {
      titleInput.classList.remove('input_error');
    }

    if(content.length > 0) {
      contentInput.classList.remove('input_error');
    }
  }, [ title, content ])

  useEffect(() => {
    // 關閉 mobile 原生鍵盤
    const startTimePicker = document.getElementsByClassName('ant-picker-input')[0];
    const endTimePicker = document.getElementsByClassName('ant-picker-input')[1];
    
    if(startTimePicker && endTimePicker) {
      const startTimeInput = startTimePicker.children[0];
      const endTimeInput = endTimePicker.children[0];

      startTimeInput.setAttribute('inputmode', 'none');
      endTimeInput.setAttribute('inputmode', 'none');
    }
  }, [ category ]);
  
  const cancelPopup = () => {
    if(finished || isSaved) {
      dispatch(showAnnouncementAddModal());
    } else {
      dispatch(showAnnouncementCancelModal());
    }
  };

  const range = (start, end) => {
    const result = [];
  
    for (let i = start; i < end; i++) {
      result.push(i);
    }
  
    return result;
  };

  const disabledDate = (current) => {
    // Can not select days before today
    const time = moment().endOf('day').subtract(1, 'days');
    return current && current < time;
  };
  
  const disabledRangeTime = (_, type) => {
    const today = moment().date();
    const selectedDate = moment(_).date();
    const hour = moment().hour();
    const min = moment().minute();

    if (type === 'end') {
      return {
        disabledHours: () => {
          if(today === selectedDate) {
            return range(0, hour);
          }
        },
        disabledMinutes: (selectedHour) => {
          if(today === selectedDate && selectedHour === hour) {
            return range(0, min);
          }
        }
      };
    }
  
    return {
      disabledHours: null,
      disabledMinutes: null,
      disabledSeconds: null,
    };
  };

  const handleChange = (e) => {
    let type = e.target.name;
    let value = e.target.value;

    if(type === 'finished') {
      dispatch(announcementChange(type, false));
      dispatch(announcementChange('startDateTime', ''));
      dispatch(announcementChange('endDateTime', ''));
    } else if(type === 'showEnabled') {
      dispatch(announcementChange(type, !showEnabled));
    } else if(type === 'officialShowEnabled') {
      dispatch(announcementChange(type, !officialShowEnabled));
    } else {
      dispatch(announcementChange(type, value));
    }

    setIsSaved(false);
  };

  const handleDateTime = (value, dateString) => {
    dispatch(announcementChange('startDateTime', dateString[0]));
    dispatch(announcementChange('endDateTime', dateString[1]));
  };

  const submit = async () => {
    const titleInput = document.getElementsByClassName('announcementTitle')[0];
    const contentInput = document.getElementsByClassName('announcementContent')[0];
    const timeInput = document.getElementsByClassName('announcementTime')[0];

    if(
      category === 'regular' &&
      (moment(startDateTime) > moment(endDateTime)) &&
      !finished
    ) {
      return window.app.alert.setMessage('公告開始時間不得大於公告結束時間', 'error');
    };

    if(
      category === 'regular' &&
      (moment(endDateTime) < moment()) &&
      !finished
    ) {
      return window.app.alert.setMessage('公告時間不得小於當前時間', 'error');
    };

    if(title.trim().length === 0) {
      titleInput.classList.add('input_error');
      window.app.alert.setMessage('請輸入公告標題', 'error');
    } else {
      titleInput.classList.remove('input_error');
    };

    if(content.trim().length === 0) {
      contentInput.classList.add('input_error');
      window.app.alert.setMessage('請輸入公告內容', 'error');
    } else {
      contentInput.classList.remove('input_error');
    };

    if(category === 'regular') {
      if(!startDateTime || !endDateTime) {
        timeInput.classList.add('input_error');
        window.app.alert.setMessage('請選擇公告時間', 'error');
      } else {
        timeInput.classList.remove('input_error');
      }
    };

    if(
      title.trim().length === 0 ||
      content.trim().length === 0 ||
      (category === 'regular' && !startDateTime) ||
      (category === 'regular' && !endDateTime)
    ) {
      return;
    };

    let announcement = {
      title: title,
      content: content,
      category: category,
      start_time: moment(startDateTime),
      end_time: moment(endDateTime),
      show_enabled: showEnabled,
      finished: finished
    };

    let officialAnnouncement = {
      title: title,
      content: content,
      category: 'regular',
      start_time: moment(startDateTime),
      end_time: moment(endDateTime),
      enabled: officialShowEnabled,
      finished: finished
    };

    if(id) {
      if(role === 'shop') {
        try {
          await updateAnnouncement(id, announcement);
          dispatch(showAnnouncementAddModal());
          dispatch(announcementFetchAll());
          dispatch(handleHeaderFilter(currentFilter));
          window.app.alert.setMessage('已儲存公告', 'done');

          if(showAnnouncementListModal) {
            dispatch(updateDayAnnouncements(currentDate));
            dispatch(announcementFetchMonth());
          }

          if(searchListTxt.length > 0) {
            dispatch(handleSearchList(searchListTxt))
          }
        } catch(err) {
          console.log('update announcement err ----------', err);
        }
      } else {
        try {
          await updateOfficialAnnouncement(id, officialAnnouncement);
          dispatch(showAnnouncementAddModal());
          dispatch(announcementOfficialFetch());
          dispatch(handleHeaderFilter(currentFilter));
          window.app.alert.setMessage('已儲存公告', 'done');

          if(searchListTxt.length > 0) {
            dispatch(handleSearchList(searchListTxt))
          }
        } catch(err) {
          console.log('update official announcement err ----------', err);
        }
      }
    } else {
      if(role === 'shop') {
        try {
          await createAnnouncement(announcement);
          dispatch(clearAnnouncement());
          dispatch(announcementFetchAll());
          dispatch(handleHeaderFilter(currentFilter));
          window.app.alert.setMessage('已新增公告', 'done');

          if(showAnnouncementListModal) {
            dispatch(updateDayAnnouncements(currentDate));
            dispatch(announcementFetchMonth());
          }

          if(searchListTxt.length > 0) {
            dispatch(handleSearchList(searchListTxt))
          }
        } catch(err) {
          console.log('create announcement err ----------', err);
        }
      } else {
        try {
          await createOfficialAnnouncement(officialAnnouncement);
          dispatch(clearAnnouncement());
          dispatch(announcementOfficialFetch());
          dispatch(handleHeaderFilter(currentFilter));
          window.app.alert.setMessage('已新增公告', 'done');

          if(searchListTxt.length > 0) {
            dispatch(handleSearchList(searchListTxt))
          }
        } catch(err) {
          console.log('create official announcement err ----------', err);
        }
      }
    }
  };

  const renderFinishedEdit = () => {
    return (
      <div className='announcementFinished'>
        <div>此公告已結束，按下「重新編輯」可重新選擇發布時間。</div>
        <button
          className='btn_finished'
          name='finished'
          onClick={(e) => handleChange(e)}
        >
          重新編輯
        </button>
      </div>
    )
  };

  const renderType = () => {
    if(role === 'shop') {
      return (
        <div className='announcement_flex'>
          <label>
            <input
              type="radio"
              name='category'
              value='regular'
              checked={category === 'regular'}
              onChange={(e) => handleChange(e)}
              disabled={finished}
              />
            一般公告
          </label>
          <div>
            <label>
              <input
                type="radio"
                name='category'
                value='long_term'
                checked={category === 'long_term'}
                onChange={(e) => handleChange(e)}
                disabled={finished}
              />
              常態公告
            </label>
            <div style={{ fontSize: '12px', fontWeight: '400' }}>為永久公告，無時間限制，僅顯示在公告系統頁面</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='announcement_flex'>
          <label>
            <input
              type="radio"
              defaultChecked
            />
            找活公告
          </label>
        </div>
      )
    }
  };

  const renderDateTime = () => {
    if(category === 'regular') {
      return (
        <RangePicker
          className='announcementTime'
          format="YYYY-MM-DD HH:mm"
          value={startDateTime && endDateTime ? [moment(startDateTime), moment(endDateTime)] : null}
          showTime={{
            hideDisabledOptions: false,
            format: 'HH:mm',
            defaultValue: [
              moment('00:00', 'HH:mm'),
              moment('23:59', 'HH:mm')
            ],
          }}
          placeholder={['從該日開始時間', '至該日結束時間']}
          onChange={handleDateTime}
          disabledDate={disabledDate}
          disabledTime={disabledRangeTime}
          disabled={finished}
        />
      )
    } else {
      return (
        <div className='dateTime_disabled'>
          無時間限制
          <CalendarOutlined />
        </div>
      )
    }
  };

  const renderDisplayCheck = () => {
    if(role === 'shop' && category !== 'long_term') {
      return (
        <div className='announcement_row announcement_check'>
          <label>
            <input
              type="checkbox"
              name='showEnabled'
              value={showEnabled}
              checked={showEnabled}
              onChange={(e) => handleChange(e)}
              disabled={finished}
            />
            顯示在店+ 系統首頁與預約系統頁面
          </label>
        </div>
      )
    }

    if(role === 'official') {
      return (
        <div className='announcement_flex official_radio'>
          <label>
            <input
              type="radio"
              name='officialShowEnabled'
              value={true}
              checked={officialShowEnabled}
              onChange={(e) => handleChange(e)}
              />
            公開發佈
          </label>
          <div>
            <label>
              <input
                type="radio"
                name='officialShowEnabled'
                value={false}
                checked={!officialShowEnabled}
                onChange={(e) => handleChange(e)}
                />
              關閉公告
            </label>
            <div style={{ fontSize: '12px', fontWeight: '400' }}>將不會在店家的公告系統中顯示</div>
          </div>
        </div>
      )
    }
  }
  
  return (
    <Modal
      title={`${ id ? '編輯': '新增'}公告`}
      visible
      centered
      className={`announcementAddModal ${finished ? 'addModal_finished' : ''}`}
      onCancel={cancelPopup}
      footer={
        <div>
          <button className='btn_cancel' onClick={() => cancelPopup()}>取消</button>
          <button
            className={`btn_save ${finished && isSaved ? 'btn_disabled' : ''}`}
            onClick={() => submit()}
            disabled={finished && isSaved}
          >
            { id ? '儲存' : '新增' }
          </button>
        </div>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      { finished && renderFinishedEdit() }
      <div className='announcement_row'>
        <h4>公告類別狀態</h4>
        { renderType() }
      </div>

      <div className='announcement_row'>
        <h4>公告時間範圍</h4>
        { renderDateTime() }
      </div>

      <div className='announcement_row'>
        <h4>公告標題</h4>
        <input
          className='announcementTitle'
          type="text"
          name='title'
          value={title}
          placeholder='請輸入公告標題'
          maxLength={30}
          onChange={(e) => handleChange(e)}
          disabled={finished}
        />
      </div>

      <div className='announcement_row'>
        <h4>公告內容</h4>
        <textarea
          className='announcementContent'
          value={content}
          name='content'
          placeholder='請輸入公告內容'
          onChange={(e) => handleChange(e)}
          disabled={finished}
        />
      </div>

      { renderDisplayCheck() }
    </Modal>
  );
};

export default AnnouncementAddModal;