import React, { useCallback, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import Page1 from './Page1';
import Page2 from './Page2';
import { updateUsers, getUsers } from '../api/newModulwApi';

export default () => {
	const { user } = useSelector((state) => state.auth);
	const { id } = user;

	const [ newFeature, setNewFeature ] = useState(JSON.parse(user.seen_features));
	const [ visible, setVisible ] = useState(false);
	const [ step, setStep ] = useState(1);

	const init = useCallback(
		async () => {
			await getUsers()
				.then(({ data: { user: { seen_features } } }) => {
					const seenFeatures = JSON.parse(seen_features);
					setNewFeature(seenFeatures);
					setVisible(seenFeatures['queue_btn_remind'] ? false : true);
				})
				.catch((err) => {
					console.log('new module getFeature err----', err);
				});
		},
		[ setNewFeature ]
	);

	useEffect(
		() => {
			const { seen_features } = user;
			const parseSeenFeatures = JSON.parse(seen_features);
			if (!parseSeenFeatures['queue_btn_remind']) init();
		},
		[ init, user ]
	);

	const submit = useCallback(
		() => {
			const newFeatureState = Object.assign({}, newFeature);
			newFeatureState['queue_btn_remind'] = true;

			setNewFeature(newFeatureState);
			setVisible(false);

			updateUsers(id, newFeatureState);
		},
		[ newFeature, id ]
	);

	if (newFeature['queue_btn_remind']) return null;
	return (
		<Modal
			destroyOnClose
			width={663}
			visible={visible}
			maskClosable={false}
			footer={null}
			onOk={() => console.log('okok')}
			wrapClassName="NewFeatureModal"
			closable={false}
		>
			{step === 1 ? <Page1 setStep={setStep} /> : <Page2 submit={submit} />}
		</Modal>
	);
};
