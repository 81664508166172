import React from 'react';

import '../menuUser.scss';


const MenuAccountBindBar = () => {
  const goInstantBindBlock = () => {
    const instantBindBlock = document.getElementById('instant_bind');
    instantBindBlock.scrollIntoView({ behavior: "smooth" });
  };

  const goHowToBindBlock = () => {
    const howToBindBlock = document.getElementById('howTo_bind');
    howToBindBlock.scrollIntoView({ behavior: "smooth" });
  };

  const goDownloadMenuApp = () => {
    const downloadMenuApp = document.getElementById('without_account_download');
    downloadMenuApp.scrollIntoView({ behavior: "smooth" });
  };

	return (
		<div className="menu_account_bar">
      <img src={require("../../../../images/menuCoupon/menuAppIcon.png")} alt="" />
      <div>
        <span className="bar_text" onClick={goInstantBindBlock}>立即綁定</span>
        <span className="bar_text" onClick={goHowToBindBlock}>如何綁定？</span>
      </div>
      <div className="bar_text_download" onClick={goDownloadMenuApp}>立即下載</div>
		</div>
	);
};

export default MenuAccountBindBar;

