import React, { useState, useEffect, useMemo, useContext } from 'react';
import moment from 'moment';

import { CustomerTag } from '../NewModuleComponent/NewModuleTools';

import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';

import '../../../stylesheets/new_module_tablemap.css';

const NextBooking = ({ children }) => {
	return <div className="canvasTableBookingInfo__nextBooking">{React.Children.map(children, (child) => child)}</div>;
};

const NextBookingHeader = () => {
	return (
		<div className="canvasTableBookingInfo__nextBooking__header">
			<h3>下一筆預約</h3>
		</div>
	);
};

const NextBookingBody = ({ nextBooking, switchMode, swapMode, swapMoreSeatMode }) => {
	const { editBooking } = useContext(CommomSettingContext);

	const statusStyle = nextBooking.status + 'Status';

	const renderNextBookingSource = () => {
		if (nextBooking.source === 'online') {
			if (nextBooking.source_url_t === 'google') {
				return <span className="bookingSourceIcon bookingSourceIcon-google" />;
			} else if (nextBooking.source_url_t === 'facebook') {
				return <span className="bookingSourceIcon bookingSourceIcon-fb" />;
			} else if (nextBooking.source_url_t === 'instagram') {
				return <span className="bookingSourceIcon bookingSourceIcon-instagram" />;
			} else if (nextBooking.source_url_t === 'menuApp') {
				return <span className="bookingSourceIcon bookingSourceIcon-menu" />;
			} else if (nextBooking.source_url_t === 'other') {
				return <span className="bookingSourceIcon bookingSourceIcon-other" />;
			}
		}

		if(nextBooking.source === 'google_booking' && nextBooking.source_url_t === 'google_reservation') {
			return <span className="bookingSourceIcon bookingSourceIcon-google_reservation" />;
		}
	};

	const renderOnsiteTag = () => {
		if (nextBooking.on_site) {
			return <span className="onSiteTag">現場</span>;
		}
	};

	const renderSeats = () => {
		if (swapMode || swapMoreSeatMode) {
			return (
				<ul className="swapSeatWrap">
					{nextBooking.seats.map((seat) => {
						return <li key={seat.table_number}>{seat.table_number}</li>;
					})}
				</ul>
			);
		}
	};

	return (
		<div className="canvasTableBookingInfo__nextBooking__body">
			<div className="canvasTableBookingInfo__nextBookingTop">
				<div className="nextBookingTop__time">
					<span>{renderNextBookingSource()}</span>
					{renderOnsiteTag()}
					<span>{nextBooking.time}</span>
				</div>
				<button
					className={`nextBookingTop__statusBtn ${statusStyle}`}
					onClick={() => switchMode(2, nextBooking)}
				>
					<i className="fa fa-circle" aria-hidden="true" />
					<span style={{ color: '#676767' }}>{nextBooking.status_t}</span>
				</button>
			</div>
			<div className="info__top info__top_margin_bottom">
				<div className="info__top__name">
					<CustomerTag booking={nextBooking} />

					<span className="name">{nextBooking.last_name}</span>
					<span className="info__top__gender">{nextBooking.gender_desc}</span>
					<span className="info__top__edit" onClick={() => editBooking(nextBooking)} />
					<p className="info__top__tel">{nextBooking.phone_number}</p>
				</div>
				<div className="info__top__attendance">
					<span>{nextBooking.attendance}</span>位
				</div>
			</div>
			{renderSeats()}
		</div>
	);
};

const NextBookingButton = ({ currentBooking = [], nextBooking, swapMode, swapMoreSeatMode, startSwapSeat, startSwapMoreSeat, checkConflict = () => {} }) => {
	const hasCurrentBooking = currentBooking.length !== 0;
	const [ hideSwapButton, setHideSwapButton ] = useState(hasCurrentBooking);

	useEffect(
		() => {
			setHideSwapButton(hasCurrentBooking);
		},
		[currentBooking, hasCurrentBooking]
	);

	const conflict = useMemo(
		() => {
			//false無衝突 true有衝突
			let conflictTable = false;

			if (currentBooking.length !== 0) {
				const targetBookingStartTime = moment(currentBooking[0].service_start_time),
					targetBookingEndTime = moment(currentBooking[0].service_start_time).add(
						currentBooking.dining_time,
						'm'
					);
				const seatedBookingStartTime = moment(nextBooking.service_start_time),
					seatedBookingEndTime = moment(nextBooking.service_start_time).add(nextBooking.dining_time, 'm');
				conflictTable =
					targetBookingStartTime < seatedBookingEndTime && targetBookingEndTime > seatedBookingStartTime;
			}

			return conflictTable;
		},
		[ currentBooking, nextBooking ]
	);

	const renderSwapBtn = () => {
		if (!swapMode && !swapMoreSeatMode && !conflict) {
			return (
				<div className="canvasTableBookingInfo__nextBooking__footer">
					<button className="switchBtn" onClick={() => startSwapSeat(nextBooking)}>交換</button>
					<button className="swapBtn" onClick={() => startSwapMoreSeat(nextBooking)}>選位</button>
				</div>
			);
		} else if (!swapMode && conflict) {
			return (
				<div className="canvasTableBookingInfo__nextBooking__footer">
					<p>與上一筆預約衝突</p>
				</div>
			);
		} else if (swapMode && !swapMoreSeatMode && !hideSwapButton) {
			return (
				<div className="canvasTableBookingInfo__nextBooking__footer">
					<button className="switchBtn_1" onClick={() => checkConflict('swap', nextBooking)}>
						交換
					</button>
				</div>
			);
		}
	};
	return <React.Fragment>{renderSwapBtn()}</React.Fragment>;
};

NextBooking.Header = NextBookingHeader;
NextBooking.Body = NextBookingBody;
NextBooking.Button = NextBookingButton;

export default NextBooking;
