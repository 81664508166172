import { createContext } from 'react';
import _ from 'lodash';

// shopInfo 店家資訊
// done 綁定完成

// bindingWay 選擇綁定方式 search | menuAccount
// search 搜尋帳號
// menuAccount 建立美食誌帳號
// menuAccountValidation 驗證碼 (不管選什麼方式都要驗證)
// menuAccountPassword 建立美食誌帳號密碼
export const signupState = {
	signup: false,
	bindingWay: 'menuAccount',
	step: 'shopInfo',
	menuShopUser: null,
	shopInfo: {
		parseObjId: '',
		shopName: '',
		shopBranch: '',
		tel: '',
		address: '',
		category: ''
	},
	email: '',
	showValidationPopup: false
};

export const SignUpStateContext = createContext(signupState);

export function SignUpReducer(state, action) {
	const newState = _.cloneDeep(state);
	switch (action.type) {
		case 'setInitShopInfo':
			return setInitShopInfo(state, action);
		case 'setMenuShopUser':
			return Object.assign(
				newState,
				{},
				{
					menuShopUser: action.menuShopUser
				}
			);
		case 'setShopInfo':
			return shopInfo(state, action);
		case 'goNextStep':
			return Object.assign(
				newState,
				{},
				{
					step: action.step
				}
			);
		case 'setBindingWay':
			return Object.assign(
				newState,
				{},
				{
					bindingWay: action.bindingWay
				}
			);
		case 'sendValidation':
			return Object.assign(
				newState,
				{},
				{
					showValidationPopup: true
				}
			);
		case 'closeValidation':
			return Object.assign(
				newState,
				{},
				{
					showValidationPopup: false
				}
			);
		case 'keepEmail':
			return Object.assign(newState, {
				email: action.email
			});
		default:
			return state;
	}
}

function setInitShopInfo(state, action) {
	const newState = _.cloneDeep(state);
	const { shop } = action;

	let shopInfo = {
		parseObjId: shop.parseObjId,
		shopName: shop.shopName,
		shopBranch: shop.shopBranch,
		tel: shop.tel,
		address: shop.address,
		category: '',
	};

	newState['menuShopUser'] = shop.menuShopUser;
	newState['shopInfo'] = shopInfo;

	return newState;
}

function shopInfo(state, action) {
	const newState = _.cloneDeep(state);
	const { name, txt } = action;
	const { shopInfo } = newState;

	shopInfo[name] = txt;

	return newState;
}
