import React from 'react';
import { Link } from 'react-router';


const AnnouncementIcon = () => {
	return (
		<div className="main-item">
			<Link
				to="/dashboard/announcement"
				role="button"
			>
				<div className="function-thub">
					<img alt="booking" src={require('../../images/component/announcement_logo.svg')} />
					<div className="caption function-title">
						<h3>公告系統</h3> <p>管理店家公告事項</p>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default AnnouncementIcon;