import React from 'react'
import $ from 'jquery'
import _ from 'lodash'
import { config } from '../../utils/config'
import {handleError} from '../../libs/handler'
var createReactClass = require('create-react-class');

export default class extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      init: false,
      isSaved: true
    }
  }
  routerWillLeave = (nextLocation) => {
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if(!this.state.isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?'
  }

  change = (e) => {
    //var name = e.target.name
    var val = e.target.value
    this.setState({
        sms_enable : val,
        isSaved: false
    })
  }


  componentDidMount(){
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
          method: 'GET', 
          xhrFields: { withCredentials: true }})
      .done(function(data){
        this.setState(data);
        this.setState({init: true});
      }.bind(this))
      .fail(function(xhr){
        handleError(xhr);
      });
  }

  onChange = (e) => {
    var name = e.target.name
    var val = e.target.value
    if(e.target.type == 'checkbox'){ val = e.target.checked }
    if(name=="max_multiple_seats") val = parseInt(val)
    var result = {}
    result[name] = val
    this.setState(result, ()=>this.setState({isSaved: false}))
  }

  submit = () =>{
    $.ajax({url: window.domain + '/dashboard/booking_settings', 
        data: {booking_settings:{
          verify_required: this.state.verify_required,
          deposit: this.state.deposit,
          deposit_required: this.state.deposit_required,
          verify_max_time: this.state.verify_max_time,
          pay_max_time: this.state.pay_max_time,
          booking_editable: this.state.booking_editable,
          spg_merchant_id: this.state.spg_merchant_id,
          spg_hash_key: this.state.spg_hash_key,
          spg_hash_iv: this.state.spg_hash_iv
        }},
        method: 'PATCH', 
        xhrFields: { withCredentials: true }})
      .done((data) => {
        this.setState({isSaved: true})
        window.app.alert.setMessage("儲存成功", "done");
      })
      .fail(function(xhr){
        window.app.alert.setMessage(xhr.responseJSON.errors.join(' '), "error");        
        handleError(xhr);
      });
  }
  render() {
    if(this.state.init){
      return (
        <div className="main overflow">
          <div style={{display: 'flex'}}>
            <div style={{flexGrow: 1}}>
              <h3>進階設定</h3>
              <h5>開發中的設定...</h5>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div className="msBtn msBtnMain" onClick={this.submit}>儲存</div>
            </div>  
          </div>
          <hr/>
          <div className="settingTbl" style={{marginLeft: 0}}>
            <div className="settingTblRow">
              <div className="header">審核訂位 </div>
              <div className="text">
                <label className="msSwitch">
                  <input type="checkbox" name="verify_required" onChange={this.onChange} checked={this.state.verify_required} />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">審核回覆期限 </div>
              <div className="text">
                <input className="form-control input-lg" name="verify_max_time" onChange={this.onChange} defaultValue={this.state.verify_max_time} />
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">付款期限 </div>
              <div className="text">
                <input className="form-control input-lg" name="pay_max_time" onChange={this.onChange} defaultValue={this.state.pay_max_time} />
              </div>
            </div>
            <hr/>
            <div className="settingTblRow">
              <div className="header">預付訂金 </div>
              <div className="text">
                <label className="msSwitch">
                  <input type="checkbox" name="deposit_required" onChange={this.onChange} checked={this.state.deposit_required} />
                  <span className="slider"></span>
                </label>
                
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">訂金金額 </div>
              <div className="text">
                <input onChange={this.onChange} defaultValue={this.state.deposit} disabled={!this.state.deposit_required} name="deposit" type="number" className="form-control input-lg"/>
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">修改訂單 </div>
              <div className="text">
                <label className="msSwitch">
                  <input type="checkbox" name="booking_editable" onChange={this.onChange} checked={this.state.booking_editable} value={true}/>
                  <span className="slider"></span>
                </label>

              </div>
            </div>
            <hr/>
            <div className="settingTblRow">
              <div className="header">merchant_id </div>
              <div className="text">
                <input className="form-control input-lg" name="spg_merchant_id" onChange={this.onChange} defaultValue={this.state.spg_merchant_id} />
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">hash_key </div>
              <div className="text">
                <input className="form-control input-lg" name="spg_hash_key" onChange={this.onChange} defaultValue={this.state.spg_hash_key} />
              </div>
            </div>
            <div className="settingTblRow">
              <div className="header">hash_iv </div>
              <div className="text">
                <input className="form-control input-lg" name="spg_hash_iv" onChange={this.onChange} defaultValue={this.state.spg_hash_iv} />
              </div>
            </div>
          </div>

        </div>
      );
    }else {
      return <hr/>
    }
  }
}