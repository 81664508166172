import React, { useState, useEffect, useContext, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'antd';
import _ from 'lodash';
import { CouponStateContext } from './MenuCouponContext/MenuCouponContext';
import MenuCouponPopup from './MenuCouponPopup/MenuCouponPopup';
import DeleteCouponPopup from './MenuCouponPopup/DeleteCouponPopup';
import PauseCouponPopup from './MenuCouponPopup/PauseCouponPopup';
import PasswordPopup from './MenuCouponPopup/PasswordPopup';
import CancelPausePopup from './MenuCouponPopup/CancelPausePopup';
import PreviewPopup from './MenuCouponPopup/Preview';
import SharePopup from './MenuCouponPopup/SharePopup'
import MenuCouponListCard from './ListCard';

import '../../sass/menu_coupon_list.scss';


const MenuCouponList = () => {
	const { CouponState, CouponDispatch } = useContext(CouponStateContext);
	const {
		couponLists,
		activeCategory,
		showDeletePopup,
		showPausePopup,
		showPasswordPopup,
		showCancelPausePopup,
		showPreviewPopup,
		showSharePopup
	} = CouponState;
	const [ filterCouponLists, setFilterCouponLists ] = useState(couponLists);

	useEffect(() => {
		const $newModuleMainRadius = document.getElementsByClassName('newModule__main__radius')[0];
		$newModuleMainRadius.scrollTop = 0;
	}, [CouponDispatch]);

	useEffect(
		() => {
			if (activeCategory !== 'all') {
				const filterLists = couponLists.filter((list) => list.status === activeCategory);
				setFilterCouponLists(filterLists);
			} else {
				const newLists = _.cloneDeep(couponLists);
				setFilterCouponLists(newLists);
			}
		},
		[ couponLists, activeCategory ]
	);

	return (
		<Fragment>
			<div className="menuCouponList">
				{filterCouponLists.map((list, index) => {
					return (
						<MenuCouponListCard key={index}>
							<MenuCouponListCard.Img status={list.status} thumbnailURL={list.thumbnailURL} />
							<MenuCouponListCard.Content list={list} />
						</MenuCouponListCard>
					);
				})}
			</div>
			{showDeletePopup && (
				<Portal>
					<MenuCouponPopup>
						<DeleteCouponPopup />
					</MenuCouponPopup>
				</Portal>
			)}

			{showPausePopup && (
				<Portal>
					<MenuCouponPopup>
						<PauseCouponPopup />
					</MenuCouponPopup>
				</Portal>
			)}

			{showPasswordPopup && (
				<Portal>
					<MenuCouponPopup>
						<PasswordPopup />
					</MenuCouponPopup>
				</Portal>
			)}

			{showCancelPausePopup && (
				<Portal>
					<MenuCouponPopup>
						<CancelPausePopup />
					</MenuCouponPopup>
				</Portal>
			)}

			{showPreviewPopup && (
				<Modal
					className="previewPopup"
					destroyOnClose
					title="預覽優惠券"
					visible={showPreviewPopup}
					footer={null}
					onCancel={() => {
						CouponDispatch({ type: 'cancelPopup' });
					}}
				>
					<PreviewPopup />
				</Modal>
			)}

			{showSharePopup && (
				<Modal
				className="sharePopup"
				destroyOnClose
				title="分享優惠券"
				width={580}
				visible={showSharePopup}
				footer={null}
				onCancel={() => {
					CouponDispatch({ type: 'cancelPopup' });
				}}
			>
				<SharePopup />
			</Modal>
			)}
		</Fragment>
	);
};


const Portal = ({ children }) => {
	return createPortal(children, document.getElementById('modalPortal'));
};

export default MenuCouponList;
