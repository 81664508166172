import React, { useState, useEffect, useContext, useRef } from 'react';
// import BraftEditor from 'braft-editor';
// import 'braft-editor/dist/index.css';

import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';



const CouponAttentionSection = ({ children }) => {
	return <section className="couponAttention">{React.Children.map(children, (child) => child)}</section>;
};

const CouponSectionTitle = () => {
	return <h2>優惠券注意事項</h2>;
};

function AttentionTxtCount(txt) {
	const newTxt = txt.trim().replace(/\r?\n|\r/g, '');
	return newTxt.length;
}

const CouponAttentionTxt = () => {
	// const initialText = '';
	const { CouponAddState, CouponAddDispatch } = useContext(CouponAddStateContext);
	const { couponSetting, originStatus } = CouponAddState;
	const { description } = couponSetting;

	const { CouponState } = useContext(CouponStateContext);
	const { editType } = CouponState;

	const minRowsRef = useRef(3);
	const [ rows, setRows ] = useState(3);





	const handleAttention = (e) => {
		const txt = e.target.value;
		const textareaLineHeight = 24;
		const previousRows = e.target.rows;
		e.target.rows = minRowsRef.current;

		const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
		if (currentRows === previousRows) {
			e.target.rows = currentRows;
		}

		CouponAddDispatch({ type: 'setAttentionText', description: txt });
		setRows(currentRows);
	};

	return (
		<div className='attentionWrap'>
			<textarea
				id="attention"
				className="attentionEditor"
				placeholder="填寫優惠券相關說明及注意事項"
				rows={rows}
				value={description}
				onChange={(e) => handleAttention(e)}
			/>
		</div>
	);
};

CouponAttentionSection.Title = CouponSectionTitle;
CouponAttentionSection.Conetnt = CouponAttentionTxt;

export default CouponAttentionSection;
