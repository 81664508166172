import axios from 'axios';
import { config } from '../../../utils/config';

const officialAnnouncementAxios = axios.create({
	baseURL: config.domain,
	withCredentials: true
});

/* 取得所有找活公告 (分頁) */
// ?page=1 所有類型
export const getAllOfficialAnnouncements = (params) => officialAnnouncementAxios.get('/dashboard/official_announcements', { params });

/* 取得 "結束" 分頁公告 */
export const getFinishedOfficialAnnouncementsByPage = (params) => officialAnnouncementAxios.get('/dashboard/official_announcements/finished', { params });

/* 取得今日顯示於後台首頁的找活公告 */
export const getDisplayOfficialAnnouncements = () => officialAnnouncementAxios.get('/dashboard/official_announcements/display');

/* 取得今日找活公告 */
export const getTodayOfficialAnnouncements = () => officialAnnouncementAxios.get('/dashboard/official_announcements/today_announcements');

/* 取得該日找活公告 */
export const getMonthOfficialAnnouncements = (params) => officialAnnouncementAxios.get('/dashboard/official_announcements', { params });

/* 新建找活公告 */
export const createOfficialAnnouncement = (officialAnnouncement) => officialAnnouncementAxios.post('/dashboard/official_announcements', officialAnnouncement);

/* 更新找活公告 */
export const updateOfficialAnnouncement = (id, officialAnnouncement) => officialAnnouncementAxios.patch(`/dashboard/official_announcements/${id}`, officialAnnouncement);

/* 取得指定找活公告 */
export const getOfficialAnnouncement = (id) => officialAnnouncementAxios.get(`/dashboard/official_announcements/${id}`);

/* 刪除公告 */
export const deleteOfficialAnnouncement = (id) => officialAnnouncementAxios.delete(`/dashboard/official_announcements/${id}`);

/* 多選刪除找活公告 */
export const bulkDeleteOfficialAnnouncement = (id) => officialAnnouncementAxios.post('dashboard/official_announcements/bulk_delete', id);

/* 多選結束找活公告 */
export const bulkFinishedOfficialAnnouncement = (id) => officialAnnouncementAxios.post('dashboard/official_announcements/bulk_set_finished', id);

/* 店家更新找活公告顯示頻率 */
export const updateOfficialAnnouncementDisplay = (setting) => officialAnnouncementAxios.post('/dashboard/official_announcements/update_settings', setting);

/* 店家設定全部找活公告顯示頻率 */
export const setAllOfficialAnnouncementDisplay = (mode) => officialAnnouncementAxios.post('/dashboard/official_announcements/set_all_display_mode', mode);

/* 搜尋標題 */
export const searchOfficialAnnouncementsByTitle = (params) => officialAnnouncementAxios.get('/dashboard/official_announcements/search', { params });