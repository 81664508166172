import React, { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';

import MenuCouponHeader from './MenuCouponHeader/MenuCouponHeader';
import MenuCouponHeaderFilter from './MenuCouponHeader/MenuCoumponHeaderFilter';
import MenuCouponEmpty from './MenuCouponEmpty';
import MenuCouponList from './MenuCouponList';
import MenuCouponAdd from './MenuCouponAdd/MenuCouponAdd';
import ScanCouponPopup from './MenuCouponPopup/ScanCouponPopup.js'

import { couponInitState, CouponStateContext, CouponReducer } from './MenuCouponContext/MenuCouponContext';
import { couponAddInitState, CouponAddStateContext, CouponAddReducer } from './MenuCouponContext/MenuCouponAddContext';

import MenuCouponApi from './MenuCouponApi';

import '../../sass/menu_coupon.scss';

const menuCouponAPI = new MenuCouponApi();

const MenuCoupon = () => {
	const auth = useSelector((state) => state.auth);
	const { name } = auth.shop;

	const [ CouponState, CouponDispatch ] = useReducer(CouponReducer, couponInitState);
	const { loading, mode, couponLists, showScanPopup } = CouponState;

	const [ CouponAddState, CouponAddDispatch ] = useReducer(CouponAddReducer, couponAddInitState);

	//coupon list
	const getCouponList = useCallback(
		async () => {
			try {
				CouponDispatch({ type: 'setLoading', loading: true });
				const list = await menuCouponAPI.getCoupon(name);
				CouponDispatch({ type: 'getCouponList', list });
			} catch (err) {
				console.log('get coupon list err-----', err);
			}
		},
		[ name ]
	);

	useEffect(() => {
		const $body = document.getElementsByTagName('body')[0];
		$body.classList.add('fixBody');

		return () => {
			$body.classList.remove('fixBody');
		};
	}, []);

	useEffect(
		() => {
			getCouponList();
		},
		[ getCouponList ]
	);

	const renderHeader = () => {
		if (mode === 'list') {
			return (
				<div className="menuCouponHeaderBar">
					<MenuCouponHeader addCoupon={addCoupon} scanCoupon={scanCoupon} />
					<MenuCouponHeaderFilter couponLists={couponLists} />
				</div>
			);
		}
	};

	const renderCouponList = () => {
		if (mode !== 'list') return;

		if (loading)
			return (
				<div style={{ textAlign: 'center', marginTop: 100 }}>
					<LoadingOutlined style={{ fontSize: 30 }} />
					<p>Loading ...</p>
				</div>
			);

		if (couponLists.length === 0) return <MenuCouponEmpty addCoupon={addCoupon} />;

		return <MenuCouponList />;
	};

	const renderAddCoupon = () => {
		if (mode === 'list') return;

		return <MenuCouponAdd closeAddCoupon={closeAddCoupon} />;
	};

	const addCoupon = useCallback(() => {
		CouponDispatch({ type: 'addCoupon' });
	}, []);

	const closeAddCoupon = useCallback(() => {
		CouponDispatch({ type: 'closeAddCoupon' });
	}, []);

	const scanCoupon = useCallback(() => {
		CouponDispatch({ type: 'showScanPopup' });
	}, []);

	return (
		<CouponStateContext.Provider value={{ CouponState, CouponDispatch, getCouponList }}>
			<CouponAddStateContext.Provider value={{ CouponAddState, CouponAddDispatch }}>
				<div className="menuCoupon">
					{renderHeader()}
					{renderCouponList()}
					{renderAddCoupon()}

					{ showScanPopup && (
						<Modal
							className="scanPopup"
							destroyOnClose
							title="QR Code 核銷"
							width={588}
							visible={showScanPopup}
							footer={null}
							onCancel={() => {
								CouponDispatch({ type: 'cancelPopup' });
							}}
							centered
							>
							<ScanCouponPopup
								couponLists={couponLists}
							/>
						</Modal>
					)}

					<div id="scanCoupon_noCamera" className="scanCoupon_notification scanCoupon_noCamera">
						<ExclamationCircleOutlined />
						找不到相機裝置，無法核銷優惠券。
					</div>

					<div id="scanCoupon_exchangedError" className="scanCoupon_notification scanCoupon_error">
						<ExclamationCircleOutlined />
					</div>

					<div id="scanCoupon_exchangedSuccess" className="scanCoupon_notification scanCoupon_success">
						<CheckOutlined />
						優惠券核銷成功
					</div>
				</div>
			</CouponAddStateContext.Provider>
		</CouponStateContext.Provider>
	);
};

export default MenuCoupon;
