import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { browserHistory } from 'react-router';

import BookingSearch from '../../Search';

import DayNames from './DayNames';
import Week from './Week';

import { announcementFetchMonth, updateDayAnnouncements } from '../../../actions/announcementAction';

export default (props) => {
	const selectedRef = useRef(null);
	const [ month, setMonth ] = useState(props.selected.clone());
	const { query: { date } } = browserHistory.getCurrentLocation();
	const dispatch = useDispatch();

	useEffect(
		() => {
			if (selectedRef.current === null) {
				selectedRef.current = props.selected;
			}

			if (selectedRef.current.format('YYYY-MM-DD') !== props.selected.format('YYYY-MM-DD')) {
				setMonth(props.selected);
				selectedRef.current = props.selected;
			}
		},
		[ props.selected ]
	);

	useEffect(() => {
		dispatch(updateDayAnnouncements());
	}, [ dispatch ]);

	const select = (day) => {
		browserHistory.push({
			pathname: '/dashboard/bookings',
			query: { date: day.date.format('YYYY-MM-DD'), mode: 'list' }
		});
		props.renderDisableDate(moment(day.date).format('YYYY-MM-DD'));
		props.changeViewMode('list');
		dispatch(updateDayAnnouncements(day.date.format('YYYY-MM-DD')));
	};

	const renderWeeks = () => {
		let weeks = [],
			done = false,
			date = month.clone().startOf('month').add('w' - 1).day('Sunday'),
			monthIndex = date.month(),
			count = 0;
		let holidayDict = {};
		let disableDatesObj = {};
		props.gov_holidaysData.forEach((item) => {
			holidayDict[item.date] = {
				name: item.name,
				isHoliday: item.isHoliday === '是' ? 'yes' : 'no'
			};
		});

		if (props.disableDates !== undefined) {
			props.disableDates.map((item) => (disableDatesObj[item] = 1));
		}

		while (!done) {
			weeks.push(
				<Week
					gov_holidaysData={holidayDict}
					disableDates={disableDatesObj}
					monthEffectiveCount={props.monthEffectiveCount}
					monthUnconfirmedCount={props.monthUnconfirmedCount}
					monthIneffectiveCount={props.monthIneffectiveCount}
					monthAttendance={props.monthAttendance}
					key={date.toString()}
					date={date.clone()}
					month={month}
					select={select}
					selected={props.selected}
				/>
			);
			date.add(1, 'w');
			done = count++ > 2 && monthIndex !== date.month();
			monthIndex = date.month();
		}

		return weeks;
	};

	return (
		<Fragment>
			<div className="expandWidth" style={{ display: 'flex' }}>
				<div
					className="form-inline"
					style={{
						flex: 'none',
						width: 'auto',
						marginTop: 0,
						marginBottom: 10
					}}
				>
					<span
						style={{
							fontSize: 16,
							marginRight: 0
						}}
					>
						{moment(date, 'YYYY-MM-DD').format('MM')}
						月：{' '}
					</span>
					<span
						style={{
							fontSize: 16,
							marginRight: 0
						}}
					>
						{props.monthTotalEffectiveCount}
						筆成交
					</span>
					<span
						style={{
							fontSize: 16,
							color: '#979797'
						}}
					>
						（{props.monthTotalAttendance}
						人)
					</span>
				</div>

				<div style={{ flex: 1, textAlign: 'right' }}>
					<BookingSearch />
				</div>
			</div>

			<div className="expandWidth" style={{}}>
				<div className="ms-calendar">
					<DayNames />
					{renderWeeks()}
				</div>
			</div>
		</Fragment>
	);
};
