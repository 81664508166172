import React, { Component } from 'react';
import QueueChartToolTip from './QueueChartToolTip'
import QueueListItem from '../QueueListItem';
import classNames from 'classnames'
import QueuePanelAPI from '../api/QueuePanelAPI';
import {getCurrentDateString} from '../QueuePanelHelper';
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;
const Link = require('react-router').Link
// const disabledDate = (current) => {
//     if (current > moment().endOf('day')) {
//         return true
//     } else if (current < moment().subtract(30, 'days')) {
//         return true
//     } else {
//         return false
//     }
// }
    
export default class QueueChart extends Component {


    /*
        ​status:
        ​​waiting: 1, 
        ​​seated: 2, 
        ​​standby: 3, 
        ​​canceled: 4
    */
   
   state = {
        width: window.innerWidth, 
        height: window.innerHeight,
        isEmpty: false,
        currentSelectTagTitle: "全部",
        currentDate: getCurrentDateString(), 
        currentQueryDatePeriod: moment().subtract(8, "days").format('YYYYMMDD') + '~' +  moment().subtract(1, "days").format('YYYYMMDD'),
        showDashboard: false,
        periodTotalCustomerCount: 0,
        periodTotalFinishCount: 0,
        chartData: [],
        waitingRecords: [],
        seatedRecords: [],
        standbyRecords: [],
        canceledRecords: [],
        groupTypes: [],
        totalWaitingCount: 0,
        totalSeatedCount: 0,
        totalStandbyCount: 0,
        totalOverdueCount: 0,
        tabSelected: {
            waitSelected: true,
            seatedSelected: false,
            standbySelected: false
        }
    }

    updateWindowDimensions() {
        this.setState({ 
            width: window.innerWidth, 
            height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
        window.addEventListener('resize', this.updateWindowDimensions);

        this.API = new QueuePanelAPI()
        this.API.getListedQueueGroup().then(data => {
            if (data !== undefined) {
                this.setState({
                    groupTypes: data["queue_groups"].reverse(),
                    currentStartTime: data["start_time"] ? data["start_time"] : ""
                })
                                
            } else {
                this.setState({
                    currentState: "settings"
                })
            }
            this.loadWaitingRecordContent()
        }).catch( error => {
            console.log(error)
            if (error.number === 300) {
                // 沒有 Record
                this.setState({
                    currentState: "pause"
                })
            } else {
                // 沒有 Group
                this.setState({
                    currentState: "settings"
                })
            }
        })


        let formatedDate = moment().subtract(8, "days").format('YYYY-MM-DD') + '~' + moment().subtract(1, "days").format('YYYY-MM-DD')
        this.API.getPeriodQueueRecord(formatedDate).then(data => {
            if (data !== undefined) {
                let dateCountInformation = data["count_by_date"]
                let chartDataArray = []
                Object.keys(dateCountInformation).forEach(element => {
                    // console.log(dateCountInformation[element])
                    chartDataArray.push({
                        name: element,
                        seated: dateCountInformation[element]["seated_count"],
                        unavailable: dateCountInformation[element]["overdue_count"],
                        standby: dateCountInformation[element]["standby_count"],
                        cancel: dateCountInformation[element]["canceled_count"],
                        date: dateCountInformation[element]["date"]
                    })
                })

                this.setState({
                    periodTotalCustomerCount: data["total_count"],
                    periodTotalFinishCount: data["total_finish"],
                    chartData: chartDataArray
                })      
            } else {
                
            }
        }).catch( error => {
            console.log(error)
        })
    }

    getFilterTagContents = () => {

        let tagContents = []

        if (this.state.tabSelected.waitSelected === true) {
            if (this.state.currentSelectTagTitle === "全部") {
                tagContents.push((<div className="queue-dashbaord-tag selected-blue"
                >全部({this.state.waitingRecords.length})</div>))
            } else {
                tagContents.push((<div className="queue-dashbaord-tag" 
                onClick={e => {
                    this.setState({
                        currentSelectTagTitle: "全部"
                    })
                }}>全部({this.state.waitingRecords.length})</div>))
            }

            let tagContentsObject = {}
            this.state.waitingRecords.forEach(record => {
                if (tagContentsObject[record.group_name] === undefined) {
                    tagContentsObject[record.group_name] = 1
                } else {
                    let currentCount = tagContentsObject[record.group_name]
                    currentCount = currentCount + 1
                    tagContentsObject[record.group_name] = currentCount
                }
            })

            this.state.groupTypes.forEach(groupType => {
                if (this.state.currentSelectTagTitle === groupType.title && 
                    tagContentsObject[groupType.title] !== undefined) {
                    tagContents.push((<div className="queue-dashbaord-tag selected-blue">{groupType.title}({tagContentsObject[groupType.title]})</div>))
                } else if (tagContentsObject[groupType.title] !== undefined) {
                    tagContents.push((<div className="queue-dashbaord-tag"
                    onClick={e => {
                        this.setState({
                            currentSelectTagTitle: groupType.title
                        })
                    }}>{groupType.title}({tagContentsObject[groupType.title]})</div>))
                }
            })
        } else if (this.state.tabSelected.standbySelected === true) {
            if (this.state.currentSelectTagTitle === "全部") {
                tagContents.push((<div className="queue-dashbaord-tag selected-yellow"
                >全部({this.state.standbyRecords.length})</div>))
            } else {
                tagContents.push((<div className="queue-dashbaord-tag" 
                onClick={e => {
                    this.setState({
                        currentSelectTagTitle: "全部"
                    })
                }}>全部({this.state.standbyRecords.length})</div>))
            }

            let tagContentsObject = {}
            this.state.standbyRecords.forEach(record => {
                if (tagContentsObject[record.group_name] === undefined) {
                    tagContentsObject[record.group_name] = 1
                } else {
                    let currentCount = tagContentsObject[record.group_name]
                    currentCount = currentCount + 1
                    tagContentsObject[record.group_name] = currentCount
                }
            })


            this.state.groupTypes.forEach(groupType => {
                if (this.state.currentSelectTagTitle === groupType.title && 
                    tagContentsObject[groupType.title] !== undefined) {
                    tagContents.push((<div className="queue-dashbaord-tag selected-yellow">{groupType.title}({tagContentsObject[groupType.title]})</div>))
                }  else if (tagContentsObject[groupType.title] !== undefined) {
                    tagContents.push((<div className="queue-dashbaord-tag"
                    onClick={e => {
                        this.setState({
                            currentSelectTagTitle: groupType.title
                        })
                    }}>{groupType.title}({tagContentsObject[groupType.title]})</div>))
                }
            })
        } else {
            if (this.state.seatedRecords.length > 0) {
                if (this.state.currentSelectTagTitle === "已報到") {
                    tagContents.push((<div className="queue-dashbaord-tag selected-green"
                    >已報到({this.state.seatedRecords.length})</div>))
                } else {
                    tagContents.push((<div className="queue-dashbaord-tag" 
                    onClick={e => {
                        this.loadSeatedRecordContent(getCurrentDateString())
                        this.setState({
                            currentSelectTagTitle: "已報到",
                            currentTag: "2"
                        })
                    }}>已報到({this.state.seatedRecords.length})</div>))
                }
            }

            if (this.state.canceledRecords.length > 0) {
                if (this.state.currentSelectTagTitle === "已取消") {
                    tagContents.push((<div className="queue-dashbaord-tag selected-green"
                    >已取消({this.state.canceledRecords.length})</div>))
                } else {
                    tagContents.push((<div className="queue-dashbaord-tag" 
                    onClick={e => {
                        this.loadCanceledRecordContent(getCurrentDateString())
                        this.setState({
                            currentSelectTagTitle: "已取消",
                            currentTag: "4"
                        })
                    }}>已取消({this.state.canceledRecords.length})</div>))
                }
            }
        }

        return tagContents
    }

    loadWaitingRecordContent = (dateString) => {
        
        this.API.getQueueRecord("overdue", this.state.currentDate).then(data => {

            if (data["queue_records"] === null) {
                this.setState({
                    currentState: 'pause'
                })
            } else {
                if (data["queue_records"] !== undefined) {
                    if (data["queue_records"].length === 0) {
                        this.setState({
                            currentState: 'normal',
                            isEmpty: true,
                            waitingRecords: [],
                            totalStandbyCount: data["standby_count"] ? data["standby_count"] : 0,
                            totalWaitingCount: data["waiting_count"] ? data["waiting_count"] : 0,
                            totalFinishCount: data["finish_count"] ? data["finish_count"] : 0,
                        })
                    } else {
                        this.setState({
                            currentState: this.state.currentState === 'addSuccess' ? 'addSuccess' : 'normal',
                            isEmpty: false,
                            waitingRecords: data["queue_records"],
                            totalStandbyCount: data["standby_count"] ? data["standby_count"] : 0,
                            totalWaitingCount: data["waiting_count"] ? data["waiting_count"] : 0,
                            totalFinishCount: data["finish_count"] ? data["finish_count"] : 0,
                        })
                    }
                }
            }
        }, error => {
            
        })
    }

    loadSeatedRecordContent = (dateString) => {
        
            this.API.getQueueRecord("seated", this.state.currentDate).then(data => {

                if (data["queue_records"] === null) {
                    this.setState({
                        currentState: 'pause'
                    })
                } else {
                    if (data["queue_records"] !== undefined) {
                        if (data["queue_records"].length === 0) {
                            this.setState({
                                currentState: 'normal',
                                isEmpty: true,
                                seatedRecords: [],
                                totalStandbyCount: data["standby_count"] ? data["standby_count"] : 0,
                                totalWaitingCount: data["waiting_count"] ? data["waiting_count"] : 0,
                                totalFinishCount: data["finish_count"] ? data["finish_count"] : 0,
                            })
                        } else {
                            this.setState({
                                currentState: this.state.currentState === 'addSuccess' ? 'addSuccess' : 'normal',
                                isEmpty: false,
                                seatedRecords: data["queue_records"],
                                totalStandbyCount: data["standby_count"] ? data["standby_count"] : 0,
                                totalWaitingCount: data["waiting_count"] ? data["waiting_count"] : 0,
                                totalFinishCount: data["finish_count"] ? data["finish_count"] : 0,
                            })
                        }
                    }
                }
            }, error => {
                
            })
    }

    loadStandbyRecordContent = (dateString) => {
            this.API.getQueueRecord("standby", this.state.currentDate).then(data => {

                if (data["queue_records"] === null) {
                    this.setState({
                        currentState: 'pause'
                    })
                } else {
                    if (data["queue_records"] !== undefined) {
                        if (data["queue_records"].length === 0) {
                            this.setState({
                                currentState: 'normal',
                                isEmpty: true,
                                standbyRecords: [],
                                totalStandbyCount: data["standby_count"] ? data["standby_count"] : 0,
                                totalWaitingCount: data["waiting_count"] ? data["waiting_count"] : 0,
                                totalFinishCount: data["finish_count"] ? data["finish_count"] : 0,
                            })
                        } else {
                            this.setState({
                                currentState: this.state.currentState === 'addSuccess' ? 'addSuccess' : 'normal',
                                isEmpty: false,
                                standbyRecords: data["queue_records"],
                                totalStandbyCount: data["standby_count"] ? data["standby_count"] : 0,
                                totalWaitingCount: data["waiting_count"] ? data["waiting_count"] : 0,
                                totalFinishCount: data["finish_count"] ? data["finish_count"] : 0,
                            })
                        }
                    }
                }
            }, error => {
                
            })
    }

    loadCanceledRecordContent = (dateString) => {
        this.API.getQueueRecord("canceled", this.state.currentDate).then(data => {
            if (data["queue_records"] === null) {
                this.setState({
                    currentState: 'pause'
                })
            } else {
                if (data["queue_records"] !== undefined) {
                    if (data["queue_records"].length === 0) {
                        this.setState({
                            currentState: 'normal',
                            canceledRecords: [],
                            totalStandbyCount: data["standby_count"] ? data["standby_count"] : 0,
                            totalWaitingCount: data["waiting_count"] ? data["waiting_count"] : 0,
                            totalFinishCount: data["finish_count"] ? data["finish_count"] : 0,
                        })
                    } else {
                        this.setState({
                            currentState: this.state.currentState === 'addSuccess' ? 'addSuccess' : 'normal',
                            isEmpty: false,
                            canceledRecords: data["queue_records"],
                            totalStandbyCount: data["standby_count"] ? data["standby_count"] : 0,
                            totalWaitingCount: data["waiting_count"] ? data["waiting_count"] : 0,
                            totalFinishCount: data["finish_count"] ? data["finish_count"] : 0,
                        })
                    }
                }
            }
        }, error => {
            
        })
    }

    scrollToDashboard = () => {
        window.scrollTo({
            top:this.dashboardRef.offsetTop, 
            behavior: "smooth"  // Optional, adds animation
        })
    }

    render() {

        let customerArea;
        let currentListItems = []
        let queueListItemArea = []
        let tagContents = this.getFilterTagContents()

        if (this.state.tabSelected.waitSelected === true) {
            // 如果現在是選擇「等候中」
            if (this.state.currentSelectTagTitle === '全部') {
                currentListItems = this.state.waitingRecords
            } else if (this.state.waitingRecords !== undefined && Array.isArray(this.state.waitingRecords)) {
                currentListItems = this.state.waitingRecords.filter(element => {
                    return element["group_name"] === this.state.currentSelectTagTitle
                })
            }
        } else if (this.state.tabSelected.seatedSelected === true) {
            // 如果現在是選擇「完成」
            if (this.state.currentSelectTagTitle === '已報到') {
                currentListItems = this.state.seatedRecords
            } else if (this.state.currentSelectTagTitle === '已取消') {
                currentListItems = this.state.canceledRecords
            }
        } else if (this.state.tabSelected.standbySelected === true) {
            // 如果現在是選擇「過號」
            if (this.state.currentSelectTagTitle === '全部') {
                currentListItems = this.state.standbyRecords
            } else if (this.state.standbyRecords !== undefined && Array.isArray(this.state.standbyRecords)) {
                currentListItems = this.state.standbyRecords.filter(element => {
                    return element["group_name"] === this.state.currentSelectTagTitle
                })
            }
        }


        currentListItems.forEach(item => {
            queueListItemArea.push((<QueueListItem data={item} hideAction={true} hideSeatedAction={true} hideStandbyAction={true}/>))
        })

        customerArea = (
            <div>
                <div className="queue-dashbaord-tag-container">
                    {tagContents}
                    {/* <div className="queue-dashbaord-tag selected">全部</div> */}
                </div>
                <div className="queue-dashbaord-list-container">
                    {queueListItemArea}
                </div>
            </div>
        )

        return (
			<div className='container container-shadow'>
				<div className='queue-title'>
					<div className='queue-header-name'>
						<Link
							to='/dashboard/queue_system'
							style={{
								color: '#404040',
								textDecoration: 'none'
							}}>
							<img
								src={require('../../../images/queue/img_queue_header_icon.png')}
								alt=''
							/>
						</Link>
						<Link
							to='/dashboard/queue_system'
							style={{
								color: '#404040',
								textDecoration: 'none'
							}}>
							<h2>排隊系統</h2>
						</Link>
					</div>
				</div>
				<div className='queue-header-settings'>
					<div className="queue-header-right-setting">
						<Link
							to='/dashboard/queue_system'
							style={{ color: '#404040' }}>
							<button className='header-action-button'>
								<img
									src={require('../../../images/queue/queue_icon_calendar.png')}
									alt=''
								/>
								<p>返回排隊</p>
							</button>
						</Link>
						<Link
							to={{ pathname: '/dashboard/setting/group', state: { source: 'queue' } }}
							style={{ color: '#404040' }}>
							<button className='header-action-button'>
								<img
									src={require('../../../images/queue/queue_icon_settings.png')}
									alt=''
								/>
								<p>設定</p>
							</button>
						</Link>
					</div>
					{/* {actionsArea} */}
				</div>
				<div className='queue-header'>
					<div className='queue-header-left'></div>
					<div className='queue-header-right'></div>
				</div>
				<div className='container-chart-info'>
					<div className='container-chart-info-item'>
						<p className='container-chart-info-title'>選擇區間</p>
						<a
							className='button-chart-export'
							href={
								window.domain +
								'/dashboard/queue_record/calendar/report.csv?date=' +
								this.state.currentQueryDatePeriod
							}
							download>
							<img
								src={require('../../../images/queue/icon_export.png')}
								alt=''
							/>
						</a>
						<RangePicker
							// disabledDate = {disabledDate}
							defaultValue={[
								moment().subtract(8, 'days'),
								moment().subtract(1, 'days')
							]}
							placeholder={['起始日期', '結束日期']}
							onChange={(dates, dateString) => {
								if (
									dateString[0] !== '' &&
									dateString[1] !== ''
								) {
									let dateQueryString =
										dateString[0] + '~' + dateString[1];
									this.API.getPeriodQueueRecord(
										dateQueryString
									)
										.then(data => {
											if (data !== undefined) {
												let dateCountInformation =
													data['count_by_date'];
												let chartDataArray = [];
												Object.keys(
													dateCountInformation
												).forEach(element => {
													chartDataArray.push({
														name: element,
														seated:
															dateCountInformation[
																element
															]['seated_count'],
														unavailable:
															dateCountInformation[
																element
															]['overdue_count'],
														standby:
															dateCountInformation[
																element
															]['standby_count'],
														cancel:
															dateCountInformation[
																element
															]['canceled_count'],
														date:
															dateCountInformation[
																element
															]['date']
													});
												});
												this.setState({
													showDashboard: false,
													currentQueryDatePeriod: dateQueryString.replace(
														/-/g,
														''
													),
													periodTotalCustomerCount:
														data['total_count'],
													periodTotalFinishCount:
														data['total_finish'],
													chartData: chartDataArray
												});
											} else {
											}
										})
										.catch(error => {
											console.log(error);
										});
								}
							}}
							format={dateFormat}
						/>
					</div>
					<div className='container-chart-info-item'>
						<p className='container-chart-info-title'>總組數</p>
						<p className='container-chart-info-content'>
							{this.state.periodTotalCustomerCount} 組
						</p>
					</div>
					<div className='container-chart-info-item'>
						<p className='container-chart-info-title'>報到</p>
						<p className='container-chart-info-content'>
							{this.state.periodTotalFinishCount} 組
						</p>
					</div>
				</div>
				<div className='container-chart'>
					<AreaChart
						width={this.state.width <= 970 ? 720 : 1100}
						height={this.state.width <= 970 ? 500 : 560}
						data={this.state.chartData}
						margin={{ top: 40, right: 0, left: 0, bottom: 0 }}
						onClick={ev => {
							if (ev) {
								let currData = this.state.chartData.filter(
									entry => entry.name === ev.activeLabel
								)[0];
								this.API.getQueueRecord(
									'overdue',
									currData.date
								).then(
									data => {
										if (data !== undefined) {
											if (
												data['queue_records'].length ===
												0
											) {
												this.setState({
													currentState: 'empty',
													currentDate: currData.date,
													showDashboard: true,
													waitingRecords: [],
													totalSeatedCount: data[
														'finish_count'
													]
														? data['finish_count']
														: 0,
													totalStandbyCount: data[
														'standby_count'
													]
														? data['standby_count']
														: 0,
													totalWaitingCount: data[
														'waiting_count'
													]
														? data['waiting_count']
														: 0,
													totalOverdueCount: data[
														'overdue_count'
													]
														? data['overdue_count']
														: 0
												});
											} else {
												this.setState({
													currentState: 'normal',
													currentDate: currData.date,
													showDashboard: true,
													waitingRecords:
														data['queue_records'],
													totalSeatedCount: data[
														'finish_count'
													]
														? data['finish_count']
														: 0,
													totalStandbyCount: data[
														'standby_count'
													]
														? data['standby_count']
														: 0,
													totalWaitingCount: data[
														'waiting_count'
													]
														? data['waiting_count']
														: 0,
													totalOverdueCount: data[
														'overdue_count'
													]
														? data['overdue_count']
														: 0
												});
											}
											this.scrollToDashboard();
										}

										if (
											this.state.tabSelected
												.waitSelected === true
										) {
											//失效
											this.loadWaitingRecordContent();
										} else if (
											this.state.tabSelected
												.seatedSelected === true
										) {
											//完成
											this.loadSeatedRecordContent();
										} else if (
											this.state.tabSelected
												.standbySelected === true
										) {
											//過號
											this.loadStandbyRecordContent();
										}
									},
									error => {}
								);
							}
						}}>
						<defs>
							<linearGradient
								id='colorSeated'
								x1='0'
								y1='0'
								x2='0'
								y2='1'>
								<stop
									offset='5%'
									stopColor='#3fba87'
									stopOpacity={0.25}
								/>
								<stop
									offset='95%'
									stopColor='#3fba87'
									stopOpacity={0}
								/>
							</linearGradient>
							<linearGradient
								id='colorUnavailable'
								x1='0'
								y1='0'
								x2='0'
								y2='1'>
								<stop
									offset='5%'
									stopColor='#4a78b4'
									stopOpacity={0.25}
								/>
								<stop
									offset='95%'
									stopColor='#4a78b4'
									stopOpacity={0}
								/>
							</linearGradient>
							<linearGradient
								id='colorStandby'
								x1='0'
								y1='0'
								x2='0'
								y2='1'>
								<stop
									offset='5%'
									stopColor='#f6a622'
									stopOpacity={0.25}
								/>
								<stop
									offset='95%'
									stopColor='#f6a622'
									stopOpacity={0}
								/>
							</linearGradient>
							<linearGradient
								id='colorCancel'
								x1='0'
								y1='0'
								x2='0'
								y2='1'>
								<stop
									offset='5%'
									stopColor='#dc8282'
									stopOpacity={0.25}
								/>
								<stop
									offset='95%'
									stopColor='#dc8282'
									stopOpacity={0}
								/>
							</linearGradient>
						</defs>
						<XAxis dataKey='name' />
						<YAxis />
						<CartesianGrid strokeDasharray='3 3' vertical={false} />
						<Tooltip
							content={
								<QueueChartToolTip
									external={this.state.chartData}
								/>
							}
						/>
						<Area
							type='monotone'
							dataKey='seated'
							name='報到'
							stroke='#3fba87'
							fillOpacity={1}
							fill='url(#colorSeated)'
						/>
						<Area
							type='monotone'
							dataKey='unavailable'
							name='失效'
							stroke='#4a78b4'
							fillOpacity={1}
							fill='url(#colorUnavailable)'
						/>
						<Area
							type='monotone'
							dataKey='standby'
							name='過號'
							stroke='#f6a622'
							fillOpacity={1}
							fill='url(#colorStandby)'
						/>
						<Area
							type='monotone'
							dataKey='cancel'
							name='取消'
							stroke='#dc8282'
							fillOpacity={1}
							fill='url(#colorCancel)'
						/>
					</AreaChart>
				</div>

				{this.state.showDashboard ? (
					<div
						className='queue-dashboard'
						ref={ref => (this.dashboardRef = ref)}>
						<div className='queue-dashboard-customers fullsize'>
							<div className='queue-dashboard-customers-container'></div>
							<button className='button-third-bottom'></button>
							<button className='button-second-bottom'></button>
							<button className='button-first-bottom'></button>
							<button
								onClick={e => {
									this.loadStandbyRecordContent(
										getCurrentDateString()
									);
									this.setState({
										currentSelectTagTitle: '全部',
										currentTag: '3',
										tabSelected: {
											waitSelected: false,
											seatedSelected: false,
											standbySelected: true
										}
									});
								}}
								className={classNames('button-third', {
									selected: this.state.tabSelected
										.standbySelected
								})}>
								<p>過號({this.state.totalStandbyCount})</p>
							</button>
							<button
								onClick={e => {
									this.loadSeatedRecordContent(
										getCurrentDateString()
									);
									this.loadCanceledRecordContent(
										getCurrentDateString()
									);
									this.setState({
										currentSelectTagTitle: '已報到',
										currentTag: '2',
										tabSelected: {
											waitSelected: false,
											seatedSelected: true,
											standbySelected: false
										}
									});
								}}
								className={classNames('button-second', {
									selected: this.state.tabSelected
										.seatedSelected
								})}>
								<p>完成({this.state.totalSeatedCount})</p>
							</button>
							<button
								onClick={e => {
									this.loadWaitingRecordContent(
										getCurrentDateString()
									);
									this.setState({
										currentSelectTagTitle: '全部',
										currentTag: '1',
										tabSelected: {
											waitSelected: true,
											seatedSelected: false,
											standbySelected: false
										}
									});
								}}
								className={classNames('button-first', {
									selected: this.state.tabSelected
										.waitSelected
								})}>
								<p>失效({this.state.totalOverdueCount})</p>
							</button>
							<div className='cover-button-area' />
							{customerArea}
						</div>
					</div>
				) : null}
			</div>
		);
    }
}
