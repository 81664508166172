import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
	BSettingAreaFetching,
	switchAreaSetting,
	changeAreaSettingTitle,
	addNewAreaBlock,
	clearBSAreaState,
	setAreaOption,
	checkAreaWarning,
	cancelAreaWarning,
	BSettingAreaSubmit,
	BSareaSettingCheck,
	handleGoogleReservationRequiredModal
} from '../../../actions/bookingSettingAreaAction';
import AreaModal from './BookingSettingAreaModal';
import AreaBlock from './AreaBlock';
import GoogleReservationRequiredCheckModal from '../bookingSettingsPopup/GoogleReservationRequiredCheckModal';

const BookingSettingArea = (props) => {
	const [ isSaved, setIsSaved ] = useState(true);

	const dispatch = useDispatch();
	const BSareaSetting = useSelector((state) => state.BookingSettingAreaReducer);
	const {
		areaSetting,
		areaSettingTitle,
		sending,
		isHiddenModal,
		tables,
		space_groups,
		isHiddenWarning,
		serviceTimeOptionEnabled,
		google_reservation,
		showGoogleReservationRequiredCheckModal
	} = BSareaSetting;

	const routerWillLeave = useCallback(
		() => {
			if (!isSaved) return '您剛剛做的更動尚未儲存，請問您是否要離開?';
		},
		[ isSaved ]
	);

	useEffect(
		() => {
			props.router.setRouteLeaveHook(props.route, routerWillLeave);
		},
		[ props, routerWillLeave ]
	);

	useEffect(
		() => {
			dispatch(BSettingAreaFetching());

			return () => {
				dispatch(clearBSAreaState());
			};
		},
		[ dispatch ]
	);

	const toggleAreaSetting = useCallback(
		() => {
			dispatch(switchAreaSetting());
			setIsSaved(false)
		},
		[ dispatch ]
	);

	const handleAreaSettingTitle = (e) => {
		const txt = e.target.value;
		dispatch(changeAreaSettingTitle(txt));
		setIsSaved(false)
	};

	const addNew = () => {
		dispatch(addNewAreaBlock());
	};

	const showWarning = useCallback(
		(clickType, id) => {
			dispatch(setAreaOption(clickType, id));
			setIsSaved(false)
		},
		[ dispatch ]
	);

	const renderAreaBlock = useCallback(
		() => {
			return space_groups.map((group, index) => {
				let groupName = [];

				group.table_settings.map((groupId) => {
					tables.map((table) => {
						if (groupId === table.id) {
							groupName.push(table);
						}
						return tables;
					});
					return group.table_settings;
				});

				const groupNameGroup = _.unionBy(groupName, 'group');

				return (
					<AreaBlock key={index}>
						<AreaBlock.Header
							group={group}
							groupName={groupNameGroup}
							index={index}
							showWarning={showWarning}
						/>
						<AreaBlock.Body
							groupName={groupNameGroup}
							bookingTimeSetting={group.time_settings}
							payment={group.payment_settings}
							serviceTimeOptionEnabled={serviceTimeOptionEnabled}
						/>
					</AreaBlock>
				);
			});
		},
		[ showWarning, space_groups, tables, serviceTimeOptionEnabled ]
	);

	const handleGoogleReservationCheckModal = (value) => {
		dispatch(handleGoogleReservationRequiredModal(value))
	}

	const handleSubmit = () => {
		dispatch(BSettingAreaSubmit());
		setIsSaved(true);
	};

	const submit = () => {
		dispatch(BSareaSettingCheck());
		setIsSaved(true);
	};

	const setSaved=useCallback(()=>{
		setIsSaved(false)
	}, [])

	return (
		<>
			<div id="area" className="main overflow">
				{sending && (
					<div className="lightBoxLayer">
						<h4>儲存中</h4>
					</div>
				)}
				<div style={{ display: 'flex' }}>
					<div style={{ flexGrow: 1 }}>
						<h3>預約組別設定</h3>
						<h5>
							您可以依據不同的服務類型，設定多種預約組別供顧客選擇。
							每個預約組別可以對應不同的座位組別、開放時段、人數、是否預先收款等設定。
						</h5>
					</div>
				</div>
				<hr />
				<div className="settingBlock">
					<div className="areaSettingItem settingBlock__item">
						<h5>顧客線上預約指定組別</h5>
						<div>
							<label className="msSwitch">
								<input type="checkbox" onChange={() => toggleAreaSetting()} checked={areaSetting} />
								<span className="slider" />
							</label>
						</div>
					</div>
					<div className="areaSettingItem settingBlock__title">
						<h5>預約選項標題</h5>
						<div>
							<input
								type="text"
								disabled={!areaSetting}
								value={areaSettingTitle}
								placeholder="請選擇預約區域"
								onChange={(e) => handleAreaSettingTitle(e)}
								maxLength={10}
							/>
						</div>
					</div>
				</div>
				<div className="areaOption">
					<div className="areaOption__title">
						<h3>
							指定預約選項{!areaSetting && <span>(未啟用)</span>}
						</h3>
						<div className="areaOption__title__button">
							<button onClick={() => addNew()}>+ 新增選項</button>
						</div>
					</div>
					{renderAreaBlock()}
				</div>

				{!isHiddenModal && <AreaModal setSaved={setSaved} serviceTimeOptionEnabled={serviceTimeOptionEnabled}/>}
				{!isHiddenWarning && <Warning />}
			{ showGoogleReservationRequiredCheckModal &&
				<GoogleReservationRequiredCheckModal
					areaSetting={areaSetting}
					setGoogleReservationRequiredCheckModal={() => handleGoogleReservationCheckModal(false)}
					submit={handleSubmit}
				/>
			}
			</div>

			<hr className='main_width' />

			<div className='fix_bottom'>
				<button
					className='btn_submit'
					onClick={submit}
				>
					儲存
				</button>
			</div>
		</>
	);
};


const Warning = () => {
	const dispatch = useDispatch();

	const cancelWarning = () => {
		dispatch(cancelAreaWarning());
	};

	const checkWarning = () => {
		dispatch(checkAreaWarning());
	};

	return (
		<div className="warning_layout">
			<div className="warning modal-content">
				<div className="modal-header">
					<h3>修改/刪除提醒</h3>
				</div>
				<div className="modal-body">編輯或刪除選項內容將會影響已經成立的顧客預約，是否確定繼續編輯？</div>
				<div className="modal-footer">
					<button onClick={cancelWarning}>取消</button>
					<button className="solid-btn" onClick={checkWarning}>
						確定
					</button>
				</div>
			</div>
		</div>
	);
};

export default BookingSettingArea;
