import { createContext } from 'react';

import _ from 'lodash';
import moment from 'moment';

export const couponAddInitState = {
	cropImgType: '',
	cropImg: '',
	showCropper: false,
	showCancelPopup: false,
	checkSetting: false,
	originStatus: '',
	couponSetting: {
		id: null,
		status: 'enable',
		coverImgFile: null,
		coverURL: '',
		thumbnailImgFile: null,
		thumbnailURL: '',
		title: '',
		item: '',
		endDateTime: '',
		countLimited: 1,
		voucherCount: '0',
		sentCount: 0,
		description: '',
		confirmCode: '',
		publishDateTime: ''
	}
};

export const CouponAddStateContext = createContext(couponAddInitState);

export function CouponAddReducer(state, action) {
	const newState = _.cloneDeep(state);
	switch (action.type) {
		case 'setEditorSetting':
			return setEditorSetting(state, action);
		case 'setLandscapeImg':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					coverImgFile: action.coverImgFile,
					coverURL: action.coverURL
				}
			});
		case 'setPortraitImg':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					thumbnailImgFile: action.thumbnailImgFile,
					thumbnailURL: action.thumbnailURL
				}
			});
		case 'setDeleteLandscape':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					coverImgFile: null,
					coverURL: ''
				}
			});
		case 'setDeletePortrait':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					thumbnailImgFile: null,
					thumbnailURL: ''
				}
			});
		case 'setCropImg':
			return cropImg(state, action);
		case 'setCrop':
			return Object.assign({}, newState, {
				cropLandscapeData: action.data
			});
		case 'setShowCropper':
			return Object.assign({}, newState, {
				showCropper: action.showCropper
			});
		case 'setClearState':
			return Object.assign({}, newState, {
				couponSetting: {
					id: null,
					status: '',
					coverImgFile: null,
					coverURL: '',
					thumbnailImgFile: null,
					thumbnailURL: '',
					title: '',
					item: '',
					endDateTime: '',
					countLimited: 1,
					voucherCount: '0',
					sentCount: 0,
					description: '',
					confirmCode: '',
					publishDateTime: ''
				},
				cropImgType: '',
				cropImg: '',
				showCropper: false,
				showCancelPopup: false,
				checkSetting: false,
				originStatus: ''
			});
		case 'setCloseAddCouponView':
			return Object.assign({}, newState, {
				showCancelPopup: true
			});
		case 'setCancelPopup':
			return Object.assign({}, newState, {
				showCancelPopup: false
			});
		case 'setCouponTitle':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					title: action.txt
				}
			});
		case 'setSubCouponTitle':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					item: action.txt
				}
			});
		case 'setCountLimited':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					countLimited: action.countLimited
				}
			});
		case 'setVoucherCount':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					voucherCount: action.count
				}
			});

		case 'setAttentionText':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					description: action.description
				}
			});
		case 'setPassword':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					confirmCode: action.confirmCode
				}
			});
		case 'setPublishDateTime':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					publishDateTime: action.publishDateTime
				}
			});
		case 'setEndDateTime':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					endDateTime: action.endDateTime
				}
			});
		case 'setLaunchType':
			return Object.assign({}, newState, {
				couponSetting: {
					...newState.couponSetting,
					status: action.publishType
				}
			});
		case 'checkSetting':
			return Object.assign({}, newState, {
				checkSetting: true
			});
		default:
			return state;
	}
}

function setEditorSetting(state, action) {
	const newState = _.cloneDeep(state);
	const { couponSettingState } = action;
	const { publishDateTime, endDateTime, coverURL, thumbnailURL } = couponSettingState;

	const newCouponSetting = couponSettingState;

	newCouponSetting['coverImgFile'] = coverURL;
	newCouponSetting['thumbnailImgFile'] = thumbnailURL;
	newCouponSetting['endDateTime'] = (Boolean(endDateTime) && moment(endDateTime)) || '';
	newCouponSetting['publishDateTime'] = (Boolean(publishDateTime) && moment(publishDateTime)) || '';

	newState['couponSetting'] = newCouponSetting;
	newState['originStatus'] = couponSettingState.status;

	return newState;
}

function cropImg(state, action) {
	const newState = _.cloneDeep(state);

	const { imgType } = action;
	const { couponSetting } = newState;
	const { coverURL, thumbnailURL } = couponSetting;

	newState['cropImgType'] = imgType;

	if (imgType === 'landscape') {
		newState['cropImg'] = coverURL;
	}

	if (imgType === 'portrait') {
		newState['cropImg'] = thumbnailURL;
	}

	return newState;
}
