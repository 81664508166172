import React, { useContext, Fragment } from 'react';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import { CouponAddStateContext } from '../MenuCouponContext/MenuCouponAddContext';
import PreviewPopup from '../MenuCouponPopup/Preview';
import { Modal } from 'antd';

import '../../../sass/menu_coupon_header.scss';

const MenuCouponAddHeader = () => {
	const { CouponState, CouponDispatch } = useContext(CouponStateContext);
    const {showPreviewPopup}=CouponState;
	const { CouponAddState } = useContext(CouponAddStateContext);


	const showPreview = () => {
		const {
			coverURL,
			countLimited,
			description,
			thumbnailURL,
			title,
			voucherCount,
			item,
			endDateTime,
			publishDateTime
		} = CouponAddState.couponSetting;

		const previewInfo = {
			coverURL,
			countLimited,
			description,
			thumbnailURL,
            title,
            exchangedCount: 0,
			voucherCount: parseInt(voucherCount, 10),
			item,
			endDateTime,
			publishDateTime,
		};

		CouponDispatch({type: 'setPreview', previewInfo})
	};

	return (
        <Fragment>
		<div className="menuCouponHeaderBar">
			<div className="menuCouponHeader">
				<div className="menuCouponHeader__title">
					<h1>新增優惠券</h1>
				</div>
				<div className="menuCouponHeader__button">
					<button onClick={() => showPreview()}>預覽</button>
				</div>
			</div>
		</div>
        {showPreviewPopup && (
				<Modal
					className="previewPopup"
					destroyOnClose
					title="預覽優惠券"
					visible={showPreviewPopup}
					footer={null}
					onCancel={() => {
						CouponDispatch({ type: 'cancelPopup' });
					}}
				>
					<PreviewPopup />
				</Modal>
			)}
        </Fragment>
	);
};

export default MenuCouponAddHeader;
