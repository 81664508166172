import React, { Component } from 'react';
import { Link } from 'react-router';
import PageNav from './PageNav';
import PageFooter from './PageFooter';
import PageSlogan from './PageSlogan';
import Slider from 'react-slick';
import ScrollReveal from '../../libs/vendor/scrollreveal.min';

import '../../stylesheets/slick.css';
import '../../stylesheets/slick/slick-theme.css';

import '../../stylesheets/queueSystemPage.css';

let slickSetting = {
	autoplay: true,
	dots: true,
	infinite: true,
	speed: 500,
	centerMode: true,
	centerPadding: '10px',
	slidesToShow: 3,
	slidesToScroll: 1,
	adaptiveHeight: true,
	focusOnSelect: true,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				autoplay: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
				centerMode: true,
				centerPadding: '100px',
				adaptiveHeight: true,
				focusOnSelect: true
			}
		},
		{
			breakpoint: 900,
			settings: {
				autoplay: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
				centerMode: true,
				centerPadding: '100px',
				adaptiveHeight: true,
				focusOnSelect: true
			}
		},
		{
			breakpoint: 600,
			settings: {
				autoplay: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
				centerMode: false,
				adaptiveHeight: true,
				focusOnSelect: true
			}
		}
	]
};

export default class QueueSystem extends Component {
	componentDidMount() {
		window.sr = new ScrollReveal();
		window.sr.reveal('.q-block1 h1', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
			delay: 500
		});

		window.sr.reveal('.q-block1 p', {
			distance: '150px',
			origin: 'bottom',
			duration: 1000,
			delay: 500
		});

		window.sr.reveal('.q-block1 .q-btn', {
			distance: '0px',
			duration: 1000,
			delay: 1000
		});

		window.sr.reveal(
			'.cardGroup .cardGroup-card',
			{
				distance: '100px',
				origin: 'bottom',
				duration: 1000,
				delay: 1000
			},
			500
		);

		window.sr.reveal('.q-block4 .q-pad-img', {
			distance: '100px',
			origin: 'right',
			duration: 800,
			delay: 800
		});

		window.sr.reveal(
			'.q-block5 .feature',
			{
				distance: '100px',
				origin: 'bottom',
				duration: 500,
				delay: 500
			},
			300
		);

		window.sr.reveal('.q-block6 .q-pad-img', {
			distance: '100px',
			origin: 'left',
			duration: 800,
			delay: 800
		});

		window.sr.reveal(
			'.padReport .padReport-cell',
			{
				distance: '100px',
				origin: 'top',
				duration: 500,
				delay: 500
			},
			300
		);
	}
	render() {
		return (
			<div
				style={{
					backgroundColor: '#fff'
				}}
			>
				<PageNav />

				<section className="q-block1">
					<div className="block-wrap">
						<div className="q-block1-txt">
							<h1>線上排隊系統</h1>
							<p>省錢、省人力，讓排隊變得輕鬆愉快！</p>

							<Link to="/contact">
								<div className="q-btn">立即試用</div>
							</Link>
						</div>
					</div>

					<div className="dot dot1" />
					<div className="dot dot2" />
					<div className="dot dot3" />
					<div className="dot dot4" />

					<div className="queueShop">
						<div className="shop">
							<img src={require('../../images/component/queue/block1_shop.svg')} alt="" />
						</div>
						<div className="people">
							<img src={require('../../images/component/queue/gogogo.gif')} alt="" />
						</div>
					</div>
				</section>

				<section className="q-block2">
					<div className="block-wrap">
						<div className="q-block2-txt">
							<p>登記資料、發號碼牌、大聲叫號、應付客人詢問焦頭爛額嗎？</p>
							<h2>MENU店+ 將排隊電子化，一次解決、更省錢！</h2>
						</div>
					</div>
				</section>

				<div className="q-block2-2">
					<div className="cardGroup">
						<article className="cardGroup-card">
							<h2>排隊電子化</h2>
							<hr />
							<p>不需要額外載具或App，方便又省錢三秒完成，隨時查看。</p>
						</article>
						<article className="cardGroup-card">
							<h2>設定超彈性</h2>
							<hr />
							<p>完整設定應付各種情況，節省人力又增加顧客滿意度。</p>
						</article>
						<article className="cardGroup-card">
							<h2>視覺化報表</h2>
							<hr />
							<p>簡單易懂的視覺化報表，</p>
							<p>提供再行銷的無限可能。</p>
						</article>
					</div>
				</div>

				<section className="q-block3">
					<div className="block-wrap">
						<div className="compare">
							<div className="compare-cell">
								<div className="compare-cell-background" />
								<div className="compare-cell-content">
									<h3>
										<span className="compare-cell-background" />
										傳統
									</h3>
									<p>發號碼牌、登記資料、現場等候、不耐煩、詢問、確認、估算時間、叫號、帶位...</p>
								</div>
							</div>
							<div className="compare-cell">
								<span>VS</span>
							</div>
							<div className="compare-cell">
								<div className="compare-cell-background" />
								<div className="compare-cell-content">
									<h3>
										<span className="compare-cell-background" />
										現在
									</h3>
									<p>&#12288;&#12288;掃QRcode、看菜單餐點、不必在場、自動通知、回餐廳、入座</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="q-block4">
					<div className="gradientBg q-block4-bg">
						<div className="block-wrap">
							<div className="q-pad">
								<div className="q-pad-txt">
									<h2 className="q-title">
										排隊電子化
										<span />
									</h2>
								</div>

								<div className="q-pad-img">
									<img src={require('../../images/component/queue/pad1.svg')} alt="" />
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="q-block5">
					<div className="block-wrap">
						<div className="feature">
							<div className="feature-left">
								<div className="feature-left-txt">零設備成本</div>
							</div>
							<div className="feature-right">
								<p>不需添購設備，電腦或平板就能操作，不必再買叫號燈、熱感應紙，現省五位數成本！</p>
							</div>
						</div>
						<div className="feature">
							<div className="feature-left">
								<div className="feature-left-txt">不需綁定機台</div>
							</div>
							<div className="feature-right">
								<p>開網頁就能操作，不必綁定iPad或特定機台、不必安裝軟體，節省軟硬體費用。</p>
							</div>
						</div>
						<div className="feature">
							<div className="feature-left">
								<div className="feature-left-txt">簡化現場流程</div>
							</div>
							<div className="feature-right">
								<p>告別人工叫號、手寫資料，讓客人以手機留下資訊，節省人力成本。</p>
							</div>
						</div>
						<div className="feature">
							<div className="feature-left">
								<div className="feature-left-txt">快速完成排隊</div>
							</div>
							<div className="feature-right">
								<p>無需下載App，客人只要以手機掃描QRcode登入網址，30秒內就能完成排隊。</p>
							</div>
						</div>
						<div className="feature">
							<div className="feature-left">
								<div className="feature-left-txt">降低客訴可能</div>
							</div>
							<div className="feature-right">
								<p>客人隨時能以手機查看排隊狀況與等候時間，避免來回詢問增加現場人員負擔與不耐感。</p>
							</div>
						</div>
						<div className="feature">
							<div className="feature-left">
								<div className="feature-left-txt">一鍵通知顧客</div>
							</div>
							<div className="feature-right">
								<p>不必在現場大喊，一鍵就能送出通知，自動發送簡訊或Email提醒即將可入座的顧客。</p>
							</div>
						</div>

						<Link to="/contact">
							<div style={{ textAlign: 'center' }}>
								<div className="q-btn">立即試用</div>
							</div>
						</Link>
					</div>
				</section>

				<section className="q-block6">
					<div className="gradientBg q-block6-bg">
						<div className="block-wrap">
							<div className="q-pad">
								<div className="q-pad-img">
									<img src={require('../../images/component/queue/pad2.svg')} alt="" />
								</div>
								<div className="q-pad-txt">
									<h2 className="q-title">
										設定超彈性
										<span />
									</h2>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="q-block7">
					<div className="featureSlick">
						<Slider {...slickSetting}>
							<div className="featureCell">
								<h3 className="featureCell-title">
									<span className="ficon ficon1" />
									隨時開關系統
								</h3>
								<div className="featureCell-content">
									<p>因應人潮過多或其他現場狀況，可隨時手動開啟、暫停或關閉排隊系統。</p>
								</div>
							</div>

							<div className="featureCell">
								<h3 className="featureCell-title">
									<span className="ficon ficon2" />
									輕鬆自建資料
								</h3>
								<div className="featureCell-content">
									<p>提供現場人員以後台自行建立排隊資料，保有服務顧客的彈性、控管現場人數。</p>
								</div>
							</div>

							<div className="featureCell">
								<h3 className="featureCell-title">
									<span className="ficon ficon3" />
									彈性建立組別
								</h3>
								<div className="featureCell-content">
									<p>可依照人數、座位區等條件自行建立組別，讓客人依據不同條件選擇排隊項目。</p>
								</div>
							</div>

							<div className="featureCell">
								<h3 className="featureCell-title">
									<span className="ficon ficon4" />
									自動登記暫停
								</h3>
								<div className="featureCell-content">
									<p>根據組別設定上限與人數。如吧台區開放3人以下、共2組登記，達上限時自動暫停登記。</p>
								</div>
							</div>

							<div className="featureCell">
								<h3 className="featureCell-title">
									<span className="ficon ficon5" />
									顯示等待時間
								</h3>
								<div className="featureCell-content">
									<p>自動計算顧客的等候時間，並讓客人隨時查看，方便現場人員隨時掌握等候狀況。</p>
								</div>
							</div>

							<div className="featureCell">
								<h3 className="featureCell-title">
									<span className="ficon ficon6" />
									提供額外資訊
								</h3>
								<div className="featureCell-content">
									<p>加入菜單、粉絲專頁等資訊，節省點單時間，並善用客人枯等的時間行銷自己。</p>
								</div>
							</div>
						</Slider>
					</div>
				</section>

				<section className="q-block8">
					<div className="q-block8-bg">
						<div className="whiteLayer" />
						<div className="block-wrap">
							<h2 className="q-title text-center">
								視覺化報表
								<span />
							</h2>
							<div className="pad3 text-center">
								<img src={require('../../images/component/queue/pad3.svg')} alt="" />
							</div>

							<div className="padReport">
								<article className="padReport-cell">
									<div className="padReport-cell-img padReport-icon1" />
									<div className="padReport-cell-content">
										<h3>報表一目瞭然</h3>
										<div>將報表視覺化，清楚顯現等候、過號、取消的人數與波動，比較不同區間的營業狀況。</div>
									</div>
								</article>
								<article className="padReport-cell">
									<div className="padReport-cell-img padReport-icon2" />
									<div className="padReport-cell-content">
										<h3>自選區間匯出</h3>
										<div>可自行選擇日期區間並匯出資料，觀察週期性消長以加強營業狀況、作為行銷參考。</div>
									</div>
								</article>
								<article className="padReport-cell">
									<div className="padReport-cell-img padReport-icon3" />
									<div className="padReport-cell-content">
										<h3>取得行銷資料</h3>
										<div>不同於丟棄式的紙本，電子化的顧客資料可發送E-mail及簡訊，取得珍貴的再行銷資源。</div>
									</div>
								</article>
							</div>
						</div>
					</div>
				</section>

				<PageSlogan />
				<PageFooter />
				
			</div>
		);
	}
}
