import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CouponStateContext } from '../MenuCouponContext/MenuCouponContext';
import moment from 'moment';
import '../../../sass/menu_coupon_popup.scss';

const PreviewPopup = () => {
	const auth = useSelector((state) => state.auth);

	const { CouponState } = useContext(CouponStateContext);
	const { previewInfo } = CouponState;

	const renderPreviewCardImg = () => {
		if (previewInfo.thumbnailURL !== '') {
			return (
				<div
					className="previewCardImg defaulImg"
					style={{ backgroundImage: `url(${previewInfo.thumbnailURL})` }}
				/>
			);
		}

		return <div className="previewCardImg defaulImg" />;
	};

	const renderAppCouponImg = () => {
		if (previewInfo.coverURL !== '') {
			return (
				<div className="previewCardImg defaulImg">
					<img src={previewInfo.coverURL} alt="" />
				</div>
			);
		}

		return <div className="previewCardImg defaulImg" />;
	};

	const renderEndTime = () => {
		if (previewInfo.endDateTime && previewInfo.publishDateTime)
			return <p>{moment(new Date(previewInfo.endDateTime)).format('YYYY-MM-DD HH:mm')}</p>;
	};

	const renderAttention = () => {
		if (previewInfo.description) {
			return <div className="description">{previewInfo.description}</div>;
		}
	};

	const renderApplyType = () => {
		if (previewInfo.countLimited === 1) {
			return (
				<div className="block">
					<div className="applyCount">
						<div className="applyCountRest">
							<p>剩餘份數</p>
							<span>{previewInfo.voucherCount - previewInfo.exchangedCount}</span>
						</div>
						<div className="applyCountTaken">
							<p>已兌換份數</p>
							<span>{previewInfo.exchangedCount}</span>
						</div>
					</div>
					<p className="note">由於數量有限，取得兌換券並非保證您能兌換，請盡快前往店家使用本券。</p>
				</div>
			);
		}
	};

	return (
		<div className="previewPopup">
			<div className="menuCouponPopupCard__body">
				<div>
					<h3>優惠券卡片預覽</h3>
					<div className="previewCard">
						{renderPreviewCardImg()}

						<div className="previewCardContent">
							<h2>{previewInfo.title}</h2>
							<div>
								<p>{previewInfo.item}</p>
								<p>{auth.shop.display_name}</p>
								{renderEndTime()}
							</div>
						</div>
					</div>
				</div>

				<div>
					<h3>優惠券內容預覽</h3>
					<div className="appPreview">
						<div className="appPreviewContent">
							<h3>{previewInfo.title}</h3>

							<div>
								<div className="previewCoupon">
									{renderAppCouponImg()}
									<div>
										<p className="storeName">{auth.shop.display_name}</p>

										<h2>{previewInfo.title}</h2>

										<div className="block">
											<p className="previewCouponTitle">兌換品項優惠內容</p>
											<p>{previewInfo.item}</p>
										</div>

										<div className="block">
											<p className="previewCouponTitle">兌換有效日期</p>
											{renderEndTime()}
										</div>

										{renderApplyType()}

										<div className="attentionWrap">
											<div className="block">
												<p className="previewCouponTitle">官方提醒</p>
												<ol>
													<li>本優惠券兌換具名額限制，請在尚有兌換名額或兌換結束日期前前往店家兌換，逾期不做補發。</li>
													<li>MENU美食誌與活動店家保留活動修改更正的權利。</li>
													<li>使用完畢後也請不吝於在MENU美食誌中發文分享心得，幫助更多人找到好店！</li>
												</ol>
											</div>
											<div className="block">
												<p className="previewCouponTitle">注意事項</p>
												{renderAttention()}
											</div>
										</div>

										<div className="storeInfo">
											<p className="previewCouponTitle">餐廳資訊</p>
											<p className="name">{auth.shop.display_name}</p>
											<p className="address">{auth.shop.address}</p>
											<p className="tel">{auth.shop.phone_number}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviewPopup;
