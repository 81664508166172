export default[

        'Keelung City(基隆市)',
        'Taipei City(台北市)',
        'New Taipei City(新北市)',
        'Yilan County(宜蘭縣)',
        'Hsinchu City(新竹市)',
        'Hsinchu County(新竹縣)',
        'Taoyuan City(桃園市)',
        'Miaoli County(苗栗縣)',
        'Taichung City(台中市)',
        'Changhua County(彰化縣)',
        'Nantou County(南投縣)',
        'Chiayi City(嘉義市)',
        'Chiayi County(嘉義縣)',
        'Yunlin County(雲林縣)',
        'Tainan City(台南市)',
        'Kaohsiung City(高雄市)',
        'Pingtung County(屏東縣)',
        'Taitung County(台東縣)',
        'Hualien County(花蓮縣)',
        'Kinmen County(金門縣)',
        'Lienchiang County(連江縣)',
        'Penghu County(澎湖縣)',
]