/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { handleWebsocketConnection } from '../../libs/handler';
import FlashMessage from '../commons/FlashMessage';
import NotificationBox from './NotificationBox';
import { handleError } from '../../libs/handler';
import { config } from '../../utils/config';
import { switchUser } from '../../actions/auth';

const superAdminId = 'findlifeadmin';

var createReactClass = require('create-react-class');
window.$ = $;
const Navbar = createReactClass({
	getInitialState() {
		this.alert = React.createRef();
		return {
			shop_name: '',
			shop_corp_name: '',
		};
	},
	getUser() {
		$.ajax({
			url: config.domain + this.props.profile_url,
			dataType: 'json',
			xhrFields: { withCredentials: true }
		})
			.done((data) => {
				window.app.current_user = data.user;
				window.app.shop = data.user.shop;
				this.setState(
					{
						shop_name: data.user.shop_name,
						shop_corp_name: data.user.shop_corp_name,
						init: true
					},
					function() {
						//window.app.alert = this.refs.alert;
					}
				);
			})
			.fail(function(xhr) {
				handleError(xhr);
			});
	},
	handleLogout() {
		$.ajax({
			url: config.domain + this.props.url,
			type: 'DELETE',
			xhrFields: { withCredentials: true },
			success: function(data) {
				browserHistory.push(data.redirect_url);
			},
			error: function(xhr) {
				handleError(xhr);
			}
		});
	},
	getDefaultProps() {
		var alertComp = <FlashMessage />;
		return {
			url: '/auth/sign_out.json',
			profile_url: '/dashboard/users/profile.json',
			alert: alertComp
		};
	},

	componentDidMount: function() {
		handleWebsocketConnection();
		this.forceUpdate();
		this.getUser();
		window.addEventListener('scroll', this.handleScroll);
	},
	handleScroll() {
		if (this.refs.menuShopNav) {
			if (window.pageYOffset > 60) {
				this.refs.menuShopNav.classList.add('animateHeader');
			} else {
				this.refs.menuShopNav.classList.remove('animateHeader');
			}
		}
	},
	componentDidUpdate(prevProps, prevState) {
		$('#menuDropdown').on('hide.bs.dropdown', function(e) {
			var target = $(e.target);
			if (target.hasClass('keepopen') || target.parents('.keepopen').length) {
				return false; // returning false should stop the dropdown from hiding.
			} else {
				return true;
			}
		});
		$('.dropdown-menu .keepopen').on('click', function(e) {
			//console.log(e);
			//e.preventDefault;
			//e.stopPropagation;
			return false;
		});
	},
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	handleChangeUser(e) {
		this.props.dispatch(switchUser(e.target.value));
	},
	render() {
		if (this.state.init !== true) return null;
		let switchable_users = this.props.switchable_users;
		let usersOption = (
			<li className="keepopen" style={{ padding: '3px 9px' }}>
				<select className="form-control" value={this.props.user.id} onChange={this.handleChangeUser}>
					{switchable_users.map(function(u) {
						return (
							<option key={u.user_id} value={u.user_id}>
								{' '}
								{u.shop_name}{' '}
							</option>
						);
					})}
				</select>
			</li>
		);

		return (
			<div>
				<header>
					<nav className='menushop-nav navbar-default' ref="menuShopNav">
						<div className="menushopNav__logo">
							{this.props.user.first_time_login_password
								? <div className='menushop-navlogo'><img sizes="140X50" src={require('../../images/newLogo.png')} alt="Menu Shop" />
								</div>
								: <Link className="menushop-navlogo" to="/dashboard">
										<img sizes="140X50" src={require('../../images/newLogo.png')} alt="Menu Shop" />
										{config.env !== 'production' && (
											<span style={{ position: 'absolute', top: 0 }}>測試</span>
										)}
									</Link>
							}
						</div>

						<div className="menushopNav__nav">
							<ul className="nav menushopNav">
								<NotificationBox disabled={this.props.user.first_time_login_password !== null} />
								<li className='dropdown m-dropdown'>
									<a
										className="dropdown-toggle"
										data-toggle="dropdown"
										role="button"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i className="userProfileIcon" />
										<span className="caret" />
									</a>
									<ul className="dropdown-menu dropdown-shop">
										<li>
											{this.props.user.first_time_login_password || this.props.user.user_id === superAdminId
												? <a>編輯個人資訊</a>
												: <Link to="/dashboard/users/profile">編輯個人資訊</Link>
											}
										</li>
										<li role="separator" className="divider" />
										{switchable_users.length > 0 && usersOption}
										<li>
											<a>{this.props.shop.name}</a>
										</li>
										<li>
											<a>{this.props.shop.corp_name}</a>
										</li>
										<li>
											<a onClick={this.handleLogout}>登出</a>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</nav>
				</header>
				<main>
					<FlashMessage forwardRef={this.alert} />
					{React.cloneElement(this.props.children, { alert: this.refs.alert })}
				</main>
			</div>
		);
	}
});

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.auth.user,
		shop: state.auth.shop,
		switchable_users: state.auth.switchable_users,
	};
};

export default connect(mapStateToProps)(Navbar);
