import React, { useState, useEffect } from 'react';
import { browserHistory } from "react-router";
import { Carousel } from 'antd';
import ScrollReveal from '../../libs/vendor/scrollreveal.min';

import PageNav from './PageNav';
import PageFooter from './PageFooter';
import PageSlogan from './PageSlogan';

import '../../sass/pageCase.scss';

const links = [
  {
    name: 'restaurant',
    link: 'findlife11'
  },
  {
    name: 'hotel',
    link: 'findlife22'
  },
  {
    name: 'more',
    link: 'findlife44'
  }
]

const PageCase = () => {
  const [ tab, setTab ] = useState('restaurant');
  const [ link, setLink ] = useState('findlife11');

  useEffect(() => {
    // 在頁面載入時檢查 URL 中的 hash
    const hash = window.location.hash.slice(1);
    const scrollY = getScrollPosition();

    if(hash) {
      let result = links.find(item => item.name === hash);

      setTab(hash);
      setLink(result.link);

      window.scrollTo({
        top: scrollY,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const secondSection = document.getElementsByClassName('shop_case')[0];
      const btnLink = document.getElementsByClassName('link_case')[0];
      const navAndTabHeight = 144 + 350;
      const blockBottom = 250;

      if (secondSection) {
        const rect = secondSection.getBoundingClientRect();
        const isVisible = rect.top <= navAndTabHeight && rect.bottom >= blockBottom;

        if(isVisible) {
          btnLink.classList.add('fadeIn');
          btnLink.classList.remove('fadeOut');
        } else {
          btnLink.classList.remove('fadeIn');
          btnLink.classList.add('fadeOut');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
		window.sr = new ScrollReveal();

    window.sr.reveal('.hero h1', {
			distance: '50px',
			origin: 'top',
			duration: 1000
		});

    window.sr.reveal('.sample_restaurant .case_content:nth-child(odd), .sample_restaurant .case_content img:nth-child(even)', {
      origin: 'right',
      distance: '100px',
      duration: 1000,
      delay: 100,
    });

    window.sr.reveal('.sample_restaurant .case_content:nth-child(even), .sample_restaurant .case_content img:nth-child(odd)', {
      origin: 'left',
      distance: '150px',
      duration: 1000,
      delay: 300,
    });
  }, []);

  const getScrollPosition = () => {
    const sampleBlock = document.getElementsByClassName('sample')[0];
    const elementRect = sampleBlock.getBoundingClientRect();

    const windowWidth = window.innerWidth;
    const navHeight = windowWidth > 767 ? 72 : 50;
    const caseTabHeight = windowWidth > 767 ? 72 : 72;

    const tabHeight = navHeight + caseTabHeight;
    const scrollY = window.scrollY + elementRect.top - tabHeight;

    return scrollY;
  };

  const handleTab = (e) => {
    const name = e.currentTarget.getAttribute('data-name');
    const scrollY = getScrollPosition();
    let result = links.find(item => item.name === name);

    window.scrollTo({
      top: scrollY,
      behavior: "smooth",
    });

    setTab(name);
    setLink(result.link);
    window.history.pushState({ tab: name }, '', `#${name}`);
  };

  return (
    <div className='pageCase'>
      <PageNav />
      <section className='hero'>
        <img
          className='hero_bg'
          src={require("../../images/page_case/hero.svg")}
          alt=""
        />
        <img
          className='logo'
          src={require("../../images/pageIdx/logo2_r.png")}
          alt="menushop+ logo"
        />
        <h1>
          適合各行各業
          <br />
          為滿足商家各種需求所設計
        </h1>
        <div
          data-name={tab}
          onClick={handleTab}
        >
          <span>了解詳情</span>
          <img
            className='arrow_down'
            src={require("../../images/page_case/arrowDown_yellow.svg")} alt=""
          />
        </div>
      </section>

    <div>
      <div className='tab'>
        <ul>
          <li
            data-name='restaurant'
            className={tab === 'restaurant' ? 'active' : ''}
            onClick={handleTab}
          >
            餐飲
          </li>
          <li
            data-name='hotel'
            className={tab === 'hotel' ? 'active' : ''}
            onClick={handleTab}
          >
            飯店設施
          </li>
          <li
            data-name='more'
            className={tab === 'more' ? 'active' : ''}
            onClick={handleTab}
          >
            更多應用
          </li>
        </ul>
      </div>

      <div className='shop_case'>
        { tab === 'restaurant' && <PageSampleRestaurant/> }
        { tab === 'hotel' && <PageSampleHotel/> }
        { tab === 'more' && <PageSampleMore/> }

        <a
          href={`https://booking.menushop.tw/${link}`}
          target="_blank"
          rel="noopener noreferrer"
          className='link_case'
        >
          <button>瞧瞧示範店家</button>
        </a>
      </div>
    </div>
      
      <PageContactBlock />
      <PageSlogan />
      <PageFooter />
    </div>
  )
};

export default PageCase;


const PageContactBlock = () => {
  const toPageContact = () => {
    browserHistory.push("/contact");
  };

  return (
    <div className='pageContactBlock'>
      <h1>想了解更多嗎？<br/>歡迎立即聯絡我們</h1>
      <p>由專業人員為您打造專屬的最佳解決方案！</p>
      <button onClick={toPageContact}>立即試用</button>
    </div>
  )
};

const PageSampleRestaurant = () => {
  const shop_lists_1 = [
    {
      img: 'shop_23ramen.jpeg',
      corpName: '23ramen'
    },
    {
      img: 'shop_ducky.jpeg',
      corpName: 'ducky'
    },
    {
      img: 'shop_oneten7812.jpeg',
      corpName: 'oneten7812'
    },
    {
      img: 'shop_R9Cafe.png',
      corpName: 'R9Cafe'
    },
    {
      img: 'shop_pine.jpeg',
      corpName: 'pine'
    },
    {
      img: 'shop_mogo32.png',
      corpName: 'mogo32'
    },
    {
      img: 'shop_moyl012.png',
      corpName: 'moyl012'
    },
    {
      img: 'shop_chuan79.jpeg',
      corpName: 'chuan79'
    },
    {
      img: 'shop_notwo11295.jpeg',
      corpName: 'notwo11295'
    },
    {
      img: 'shop_Enjoy142.jpeg',
      corpName: 'Enjoy142'
    },
    {
      img: 'shop_friends106.jpeg',
      corpName: 'friends106'
    },
    {
      img: 'shop_aqua2006.jpeg',
      corpName: 'aqua2006'
    },
    {
      img: 'shop_YONGTENG8750.jpeg',
      corpName: 'YONGTENG8750'
    },
  ];

  const shop_lists_2 = [
    {
      img: 'shop_oiagreektaverna0101.jpeg',
      corpName: 'oiagreektaverna0101'
    },
    {
      img: 'shop_mijian.jpeg',
      corpName: 'mijian'
    },
    {
      img: 'shop_rododo51.jpeg',
      corpName: 'rododo51',
      system: 'queue'
    },
    {
      img: 'shop_akafuji84.jpeg',
      corpName: 'akafuji84'
    },
    {
      img: 'shop_katie520.png',
      corpName: 'katie520',
      system: 'queue'
    },
    {
      img: 'shop_moyn02.jpeg',
      corpName: 'moyn02'
    },
    {
      img: 'shop_aquabbq2008.jpeg',
      corpName: 'aquabbq2008'
    },
    {
      img: 'shop_momn01.jpeg',
      corpName: 'momn01'
    },
    {
      img: 'shop_fukurou.jpeg',
      corpName: 'fukurou'
    },
    {
      img: 'shop_dwblackruru.jpeg',
      corpName: 'dwblackruru'
    },
    {
      img: 'shop_mold01.jpeg',
      corpName: 'mold01'
    },
    {
      img: 'shop_catcat1.jpeg',
      corpName: 'catcat8'
    }
  ];

  return (
    <section className='sample sample_restaurant'>
      <h2>整合社群曝光到顧客服務</h2>
      <div className='case_content'>
        <img src={require("../../images/page_case/restaurant_1.png")} alt="" />
        <p>每個店家擁有專屬的預約網址，無痛上架多種社群媒體與預約管道，還可串連90萬美食用戶社群地圖，活動優惠即時推播！</p>
      </div>
      <div className='case_content'>
        <p>即時預約清單整合電子化座位圖，一鍵帶位好懂又不慌亂，掌握客人動態，翻桌率UP。</p>
        <img src={require("../../images/page_case/restaurant_2.svg")} alt="" />
      </div>
      <div className='case_content'>
        <img src={require("../../images/page_case/restaurant_3.svg")} alt="" />
        <p>
          從預約問卷到自訂顧客標記，
          <br />
          提供熟客專屬服務，封鎖奧客放鳥客。
        </p>
      </div>
      <div className='case case_restaurant'>
        <h2>有哪些餐飲店家在使用我們的服務...</h2>
        <Carousel
          className="shop_carousel"
          autoplaySpeed={3000}
          autoplay
        >
          <div className="case_logo carousel">
            {
              shop_lists_1.map((shop, idx) => {
                return (
                  <a
                    key={idx}
                    target="_blank" rel="noopener noreferrer"
                    href={`https://booking.menushop.tw/${shop.corpName}`}
                  >
                    <img src={require(`../../images/page_case/logo_restaurant/${shop.img}`)} alt="" />
                  </a>
                )
              })
            }
          </div>
          <div className="case_logo carousel">
            {
              shop_lists_2.map((shop, idx) => {
                return (
                  <a
                    key={idx}
                    target="_blank" rel="noopener noreferrer"
                    href={`https://${shop.system === 'queue' ? 'line' : 'booking'}.menushop.tw/${shop.corpName}`}
                  >
                    <img src={require(`../../images/page_case/logo_restaurant/${shop.img}`)} alt="" />
                  </a>
                )
              })
            }
        </div>
        </Carousel>
      </div>
    </section>
  )
};

const PageSampleHotel = () => {
  const shop_lists = [
    {
      img: 'shop_Restaurant03.png',
      corpName: 'Restaurant03'
    },
    {
      img: 'shop_SANTOS.jpeg',
      corpName: 'SANTOS'
    },
    {
      img: 'shop_REC5213.jpeg',
      corpName: 'REC5213'
    },
    {
      img: 'shop_fullonhotels01.png',
      corpName: 'fullonhotels01'
    },
    {
      img: 'shop_dwjprs3.jpeg',
      corpName: 'dwjprs3'
    }
  ];

  return (
    <section className='sample sample_hotel'>
      <h2>顧客至上，串連多項貼心服務</h2>
      <div className='case_content'>
        <img src={require("../../images/page_case/hotel_1.svg")} alt="" />
        <ul>
          <li>從機場接送、用餐到SPA服務預約，低門檻、高彈性設定，整合所有貴賓服務。</li>
          <li>手機就能預約，隨時線上操作節省顧客時間；行程自動提醒，貼心又便利。</li>
          <li>客製問卷紀錄顧客需求，提前安排專屬服務，提升顧客體驗。</li>
        </ul>
      </div>
      <div className='case'>
        <h2>有哪些飯店在使用我們的服務...</h2>
        <div className='case_logo'>
          {
            shop_lists.map((shop, idx) => {
              return (
                <a
                  key={idx}
                  target="_blank" rel="noopener noreferrer"
                  href={`https://booking.menushop.tw/${shop.corpName}`}
                >
                  <img src={require(`../../images/page_case/logo_hotel/${shop.img}`)} alt="" />
                </a>
              )
            })
          }
        </div>
      </div>
    </section>
  )
};

const PageSampleMore = () => {
  const shop_lists = [
    {
      img: 'shop_IKEA_XDS01.png',
      corpName: 'IKEA_XDS01',
      system: 'queue'
    },
    {
      img: 'shop_ubertaiwan.png',
      corpName: 'ubertaiwan',
      system: 'queue'
    },
    {
      img: 'shop_deerfun908.jpeg',
      corpName: 'deerfun908'
    },
    {
      img: 'shop_animatecafetw.jpeg',
      corpName: 'animatecafetw'
    },
    {
      img: 'shop_kyotokimonokan.jpeg',
      corpName: 'kyotokimonokan'
    },
    {
      img: 'shop_cmellia86.jpeg',
      corpName: 'cmellia86'
    },
    {
      img: 'shop_bambiland.png',
      corpName: 'bambiland'
    },
    {
      img: 'shop_nwtigerhat.jpeg',
      corpName: 'nwtigerhat'
    },
    {
      img: 'shop_CoffeeMilk453314.jpeg',
      corpName: 'CoffeeMilk453314'
    }
  ];

  return (
    <section className='sample sample_more'>
      <h2>彈性設定適用多元場景，服務數位化，體驗再升級</h2>
      <div className='case_content'>
        <img className='more_1' src={require("../../images/page_case/more_1.svg")} alt="" />
        <ul>
          <li>可自訂活動場次與人數，自動控管人流，維持現場服務品質。</li>
          <li>預收訂金、審核預約和電話認證機制，減少無效預約和 No Show 頻率。</li>
          <li>自動建立顧客資料庫，免人工不怕資料遺漏，服務前後聯絡顧客好方便。</li>
        </ul>
      </div>
      <div className='case_content'>
        <img className='more_2' src={require("../../images/page_case/more_2.svg")} alt="" />
        <ul>
          <li>數位化排隊候位，無紙化方便又環保。</li>
          <li>服務進度線上即時同步，遠端即可候位，不必現場苦等。</li>
          <li>貼心簡訊自動叫號，顧客方便又節省人力。</li>
        </ul>
      </div>
      <div className='case'>
        <h2>有哪些商家在使用我們的服務...</h2>
        <div className='case_logo'>
          {
            shop_lists.map((shop, idx) => {
              return (
                <a
                  key={idx}
                  target="_blank" rel="noopener noreferrer"
                  href={`https://${shop.system === 'queue' ? 'line' : 'booking'}.menushop.tw/${shop.corpName}`}
                >
                  <img src={require(`../../images/page_case/logo_travel_more/${shop.img}`)} alt="" />
                </a>
              )
            })
          }
        </div>
      </div>
    </section>
  )
};
