import React from 'react';

const OrderSeat = ({ children }) => {
	return (
		<li className="dragRow orderSeatsAreaRow">
			{React.Children.map(children, (child) => {
				return child;
			})}
		</li>
	);
};

const SeatHandle = () => {
	return (
		<span className="dragRow__cell">
			<span className="dragHandle">
				<i className="fa fa-ellipsis-v" aria-hidden="true" />
				<i className="fa fa-ellipsis-v" aria-hidden="true" />
			</span>
		</span>
	);
};

const SeatTableNumber = ({ tableNumber }) => {
	return <span className="dragRow__cell">{tableNumber}</span>;
};

const SeatCount = ({ minSeat, maxSeat }) => {
	return (
		<span className="dragRow__cell">
			{minSeat}-{maxSeat}人
		</span>
	);
};

OrderSeat.SeatHandle = SeatHandle;
OrderSeat.SeatTableNumber = SeatTableNumber;
OrderSeat.SeatCount = SeatCount;

export default OrderSeat;
