
//座位圖桌子無預約狀態
export const $tableStatusEmptyColor = 'rgb(255, 255, 255)';

//桌子名稱＆人數
export const $canvasTableNameColor = '#676767';
export const $canvasTableNameColor_white = '#fff';
export const $canvasTableAttendanceColor = '#c8c8c8';
export const $canvasTableAttendanceColor_white = '#fff';

//座位圖桌子Border狀態
export const $tableBorderColor_normal = '#c8c8c8';

//選中桌子
export const $selectedColor = '#faa329';
export const $selectedTableColor = '#676767'; // 換位模式：一對多

//桌子各種icon
export const $canvasAddBookingBtnColor = '#3fba88';
export const $canvasCancelBookingBtnColor = '#c8c8c8';
export const $tableNextBookingColor_normal = '#c8c8c8';
export const $tableNextBookingColor_white = '#fff';
export const $tableNextBookingTxtColor_gray = '#c8c8c8';
export const $tableNextBookingTxtColor_white = '#fff';
export const $progressBarNormalColor = '#fff';
export const $progressBarAlertColor = '#ff8888';
export const $progressBaseBarColor = '#c8c8c8';

//for tableStatus function (桌子預約狀態)
export const $status_confirmed = '#3fba88';
export const $status_unconfirmed = '#bad959';
export const $status_cancel = 'rgb(151, 151, 151)';
export const $status_noShow = 'rgb(103, 103, 103)';
export const $status_show = '#42a0a4';
export const $status_seated = '#27496e';
export const $status_check = 'rgb(67, 70, 73)';

//強制建立預約
export const $forceBookingBackgroundColor= '#dcdcdc';
export const $forceBookingAttendanceTxtColor= '#979797';
export const $forceBookingAttendanceTxtColor_selected= '#ffffff';
