import { createContext } from 'react';
import cloneDeep from 'lodash/cloneDeep';

// userInfo 帳號資訊
// sendVerificationCode 傳送驗證碼

export const userBindState = {
	step: 'search',
  userInfo: {
		menuUserId: '',
		displayName: '',
    thumbnailURL: ''
	},
  sendVerificationCode: false
};

export const UserBindContext = createContext(userBindState);

export function UserBindReducer(state, action) {
  const newState = cloneDeep(state);
  switch (action.type) {
    case 'setInitUserInfo':
			return setInitUserInfo(state, action);
    case 'goUserVerification':
			return Object.assign(
				newState,
				{},
				{
					step: action.step
				}
			);
    case 'closeUserVerification':
			return Object.assign(
				newState,
				{},
				{
					step: action.step
				}
			);
    case 'toggleUserVerificationCode':
			return Object.assign(
				newState,
				{},
				{
					sendVerificationCode: action.sendVerificationCode
				}
			);
    default:
			return state;
  }
}

function setInitUserInfo(state, action) {
	const newState = cloneDeep(state);
	const { menuUserId, displayName, thumbnailURL } = action;

	let userInfo = {
		menuUserId: menuUserId,
		displayName: displayName,
    thumbnailURL: thumbnailURL
	};

	newState['userInfo'] = userInfo;

	return newState;
};