import React from 'react';
import moment from 'moment';

import AnnouncementCard from '../../announcement/AnnouncementCard';

const day_list = ['日', '一', '二', '三', '四', '五', '六'];
const hoursArray = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23'
];
const minArray = [ '00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55' ];

class Datepicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeYear: this.props.tempBookingDate.slice(0, 4),
			activeMonth: this.props.tempBookingDate.slice(5, 7),
			tempBookingDate: this.props.tempBookingDate,
			tempBookingTimeHour: this.props.tempBookingTimeHour,
			tempBookingTimeMin: this.props.tempBookingTimeMin,
			availableTime: this.props.availableTime,
			loadingTime: this.props.loadingTime,
			filterCombinationTables: this.props.filterCombinationTables
		};

		this.createRows = this.createRows.bind(this);
		this.handlePreMonth = this.handlePreMonth.bind(this);
		this.handleNextMonth = this.handleNextMonth.bind(this);
		this.renderBookingTimeDisplay = this.renderBookingTimeDisplay.bind(this);
	}

	componentDidMount() {
		this.props.updateDayAnnouncements(this.props.date);
	};

	componentDidUpdate(prevProps, preState) {
		const {
			tempBookingDate,
			tempBookingTimeHour,
			tempBookingTimeMin,
			availableTime,
			filterCombinationTables
		} = this.props;

		if (
			tempBookingDate !== preState.tempBookingDate ||
			tempBookingTimeHour !== preState.tempBookingTimeHour ||
			tempBookingTimeMin !== preState.tempBookingTimeMin ||
			availableTime !== preState.availableTime
		) {
			this.setState({
				tempBookingDate,
				tempBookingTimeHour,
				tempBookingTimeMin,
				availableTime
			});
		}

		if (filterCombinationTables !== preState.filterCombinationTables) {
			this.setState({
				filterCombinationTables
			});
		}
	}

	componentWillUnmount() {
		this.props.updateDayAnnouncements(this.props.date);
	};

	handlePreMonth() {
		let { activeYear, activeMonth } = this.state;

		activeYear = parseInt(activeYear, 10);
		activeMonth = parseInt(activeMonth, 10);

		if (activeMonth === 1) {
			activeYear -= 1;
			activeMonth = 12;
		} else {
			activeMonth -= 1;
		}

		this.setState({ activeYear: activeYear, activeMonth: activeMonth });
	}

	handleNextMonth() {
		let { activeYear, activeMonth } = this.state;

		activeYear = parseInt(activeYear, 10);
		activeMonth = parseInt(activeMonth, 10);

		if (activeMonth === 12) {
			activeYear += 1;
			activeMonth = 1;
		} else {
			activeMonth += 1;
		}

		this.setState({ activeYear: activeYear, activeMonth: activeMonth });
	}

	createRows(year, month) {
		let createTable = [];

		year = parseInt(year, 10);
		month = parseInt(month, 10);

		const firstday = new Date(year, month - 1, 1),
			dayOfWeek = firstday.getDay(),
			days_per_month = new Date(year, month, 0).getDate();

		const days_per_lastMonth = new Date(year, month - 1, 0).getDate();
		let chosenDateStyle = '';

		const str_nums = Math.ceil((dayOfWeek + days_per_month) / 7);

		const { tempBookingDate } = this.state;

		const handleChooseDate = (e, cell) => {
			const date = moment(cell).format('YYYY-MM-DD')
			this.props.chooseDate(e, cell)
			this.props.updateDayAnnouncements(date);
		};

		for (let i = 0; i < str_nums; i++) {
			let tableTr = [];
			for (let j = 0; j < 7; j++) {
				let dayIndex = 7 * i + j;
				let date = dayIndex - dayOfWeek + 1;
				let cell;
				let notCurrentMonth;
				let notcurrentYear = year,
					notcurrentMonth = month,
					notcurrentDay;

				if (date <= 0) {
					date = date + days_per_lastMonth;
					notCurrentMonth = 'notCurrentMonth';

					if (notcurrentMonth === 1) {
						notcurrentMonth = 12;
						notcurrentYear -= 1;
					} else {
						notcurrentMonth -= 1;
					}
				} else if (date > days_per_month) {
					date = date - days_per_month;
					notCurrentMonth = 'notCurrentMonth';

					if (notcurrentMonth === 12) {
						notcurrentMonth = 1;
						notcurrentYear += 1;
					} else {
						notcurrentMonth += 1;
					}
				} else {
					date = dayIndex - dayOfWeek + 1;
					notCurrentMonth = '';
				}

				if (notcurrentMonth < 10 && notcurrentMonth > 0) {
					notcurrentMonth = '0' + notcurrentMonth;
				}

				notcurrentDay = date;

				if (notcurrentDay < 10 && notcurrentDay > 0) {
					notcurrentDay = '0' + notcurrentDay;
				}

				cell = notcurrentYear + '/' + notcurrentMonth + '/' + notcurrentDay;

				if (tempBookingDate === cell) {
					chosenDateStyle = 'active';
				} else {
					chosenDateStyle = '';
				}

				tableTr.push(
					<div key={cell} className={[ 'tableCell', notCurrentMonth ].join(' ')}>
						<span className={chosenDateStyle} onClick={(e) => handleChooseDate(e, cell)}>
							{date}
						</span>
					</div>
				);
			}

			createTable.push(
				<div key={i} className="tableRow tableCellRow">
					{tableTr}
				</div>
			);
		}

		return createTable;
	}

	renderBookingTimeDisplay() {
		const { availableTime } = this.state;

		if (Object.keys(availableTime).length !== 0) {
			return (
				<p>
					{this.state.tempBookingTimeHour} : {this.state.tempBookingTimeMin}
					{this.renderNoCombinationSeatWarning()}
				</p>
			);
		} else {
			return <p className="text-danger">本日無可預約時段</p>;
		}
	}

	renderNoCombinationSeatWarning() {
		const { filterCombinationTables, tempBookingDate, tempBookingTimeHour, tempBookingTimeMin } = this.state;
		const filterDateTime = `${tempBookingDate} ${tempBookingTimeHour}:${tempBookingTimeMin}`;

		if (
			tempBookingTimeHour !== '' &&
			tempBookingTimeMin !== '' &&
			filterCombinationTables[filterDateTime] === undefined
		) {
			return <span>*此時段店內無符合預約人數的座位</span>;
		}
	}

	renderDateAnnouncement() {
		const dayAnnouncements = this.props.dayAnnouncements;
		const date = moment(new Date(this.props.tempBookingDate)).format(('YYYY-MM-DD'));
		const day = new Date(this.props.tempBookingDate).getDay();

		return (
			<div className='announcementWrapper'>
				<div className='title_date'>{ date + '（' + day_list[day]+ '）' }</div>
				{
          dayAnnouncements.length > 0 && dayAnnouncements.map((item, idx) => {
            return (
              <AnnouncementCard
                data={dayAnnouncements}
                item={item}
                idx={idx}
								key={item.id}
								onlyRead={true}
								date={date}
              />
            )
        })}
				{ dayAnnouncements.length === 0 ? <div className='announcement_empty'>今日暫無公告。</div> : null }
			</div>
		)
	}

	render() {
		const { loadingTime } = this.props;
		const { activeYear, activeMonth, tempBookingTimeHour, tempBookingTimeMin, availableTime } = this.state;

		return (
			<div className="datePicerWrapper">
				<div className="datePicker">
					<div className='datePickerContent'>
						<div className="dateWrapper">
							<div className="dateWrapper-calendar">
								{this.props.loadingTime ? <div className="timePickerLoad" /> : null}
								<Month
									key={activeYear + activeMonth}
									createRows={this.createRows(activeYear, activeMonth)}
									handleNextMonth={this.handleNextMonth}
									handlePreMonth={this.handlePreMonth}
									showYear={parseInt(activeYear, 10)}
									showMonth={parseInt(activeMonth, 10)}
									updateDayAnnouncements={this.props.updateDayAnnouncements}
								/>
							</div>
							<div className="dateWrapper-time">
								<div className="displayTime">{this.renderBookingTimeDisplay()}</div>
								<Time
									availableTime={availableTime}
									tempBookingTimeHour={tempBookingTimeHour}
									tempBookingTimeMin={tempBookingTimeMin}
									handleTimeClick={this.props.handleTimeClick}
								/>
							</div>
						</div>
						{ this.renderDateAnnouncement() }
					</div>
					<div className="wrapperFooter">
						<div
							className={'btn cancelBtn' + (loadingTime ? ' disableBtn' : '')}
							onClick={() => this.props.closeDatepicker()}
						>
							取消
						</div>
						<div
							className={'btn saveBtn' + (loadingTime ? ' disableBtn' : '')}
							onClick={() => this.props.datepickerSubmit()}
						>
							確定
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const Month = ({ showYear, showMonth, createRows, handlePreMonth, handleNextMonth }) => {
	return (
		<div className="calendarTable">
			<div className="tableMonth">
				<span onClick={() => handlePreMonth()}>
					<i className="fa fa-chevron-left" aria-hidden="true" />
				</span>
				{showYear}-{showMonth}
				<span onClick={() => handleNextMonth()}>
					<i className="fa fa-chevron-right" aria-hidden="true" />
				</span>
			</div>

			<div className="tableRow tableRowTitle">
				<div className="tableCell">日</div>
				<div className="tableCell">一</div>
				<div className="tableCell">二</div>
				<div className="tableCell">三</div>
				<div className="tableCell">四</div>
				<div className="tableCell">五</div>
				<div className="tableCell">六</div>
			</div>

			<div className="monthCell">{createRows}</div>
		</div>
	);
};

const Time = ({ tempBookingTimeHour, tempBookingTimeMin, availableTime, handleTimeClick }) => {
	const renderTimeCell = () => {
		//hour 選到的時間
		//availableTime 能選的時間

		return hoursArray.map((item) => {
			if (!availableTime[item]) {
				if(item === tempBookingTimeHour) {
					return (
						<div key={item} className="timeArea-cell active" onClick={() => handleTimeClick('hour', item)}>
							{item}
						</div>
					);
				} else {
					return (
						<div key={item} className="timeArea-cell disable" onClick={() => handleTimeClick('hour', item)}>
							{item}
						</div>
					);
				};
			} else if (item === tempBookingTimeHour) {
				return (
					<div key={item} className="timeArea-cell active" onClick={() => handleTimeClick('hour', item)}>
						{item}
					</div>
				);
			} else if (availableTime[item]) {
				return (
					<div key={item} className="timeArea-cell" onClick={() => handleTimeClick('hour', item)}>
						{item}
					</div>
				);
			}

			return null;
		});
	};

	const renderTimeMin = () => {
		const min = availableTime[tempBookingTimeHour];

		return minArray.map((item) => {
			if (tempBookingTimeHour !== '' && min && min.indexOf(item) === -1) {
				if(item === tempBookingTimeMin) {
					return (
						<div key={item} className="timeArea-cell active" onClick={() => handleTimeClick('min', item)}>
							{item}
						</div>
					);
				} else {
					return (
						<div key={item} className="timeArea-cell disable" onClick={() => handleTimeClick('min', item)}>
							{item}
						</div>
					);
				}
			} else if (tempBookingTimeHour !== '' && !min && item !== tempBookingTimeMin) {
				return (
					<div key={item} className="timeArea-cell disable" onClick={() => handleTimeClick('min', item)}>
						{item}
					</div>
				);
			} else if (tempBookingTimeHour !== '' && item === tempBookingTimeMin) {
				return (
					<div key={item} className="timeArea-cell active" onClick={() => handleTimeClick('min', item)}>
						{item}
					</div>
				);
			} else if (tempBookingTimeHour === '' && !min) {
				return (
					<div key={item} className="timeArea-cell disable" style={{ cursor: 'default' }}>
						{item}
					</div>
				);
			} else {
				return (
					<div key={item} className="timeArea-cell" onClick={() => handleTimeClick('min', item)}>
						{item}
					</div>
				);
			}
		});
	};

	return (
		<div className="timArea">
			<div className="timArea-hour">
				<div className="timeArea-title">
					<span>幾點</span>
				</div>
				{renderTimeCell()}
			</div>
			<div className="timArea-hour timeArea-min">
				<div className="timeArea-title">
					<span>幾分</span>
				</div>
				{renderTimeMin()}
			</div>
		</div>
	);
};

export default Datepicker;
