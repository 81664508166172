import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleAnnouncementListModal } from '../../../actions/announcementAction';

export default (props) => {
	const announcementListReducer = useSelector((state) => state.announcementListReducer);
	const {
		monthAnnouncements
	} = announcementListReducer;
	const dispatch = useDispatch();

	var days = [],
		date = props.date,
		month = props.month;

	const disableDates = props.disableDates; //不可預約日期
	const gov_holiday = props.gov_holidaysData;

	for (let i = 0; i < 7; i++) {
		let day = {
			year: date.format('YYYY'),
			name: date.format('dd').substring(0, 1),
			number: date.date(),
			isCurrentMonth: date.month() === month.month(),
			isToday: date.isSame(new Date(), 'day'),
			date
		};

		let monthNumber = parseInt(date.month() + 1, 10);
		let dayNumber = day.number;

		//day.number 幾號 date.month()+1 月份 day.year 年份
		const holidayCell = day.year + '/' + monthNumber + '/' + dayNumber; //2018/1/1
		const holidayTxt = gov_holiday[holidayCell] ? gov_holiday[holidayCell]['name'] : gov_holiday[holidayCell];
		let nationalHoliday =false;

		if (holidayTxt) {
			nationalHoliday = true;
			if (gov_holiday[holidayCell]['isHoliday'] === 'no') {
				nationalHoliday = false;
			}
		}

		if (dayNumber > 0 && dayNumber < 10) {
			dayNumber = '0' + dayNumber;
		}

		if (monthNumber > 0 && monthNumber < 10) {
			monthNumber = '0' + monthNumber;
		}

		const cell = day.year + '-' + monthNumber + '-' + dayNumber;
		const disableDateStyle = disableDates[cell] === 1 ? true : false; ///不可預約日期style
		const nationalWeek = i === 0 || i === 6 ? true : false; ///六日style
		const renderTodayNumber = day.number === 1 && !day.isToday ? `${date.month() + 1}月${day.number}日` : day.number; ///每月1號＆＆today
		const effectiveCount = props.monthEffectiveCount[date.format('YYYYMMDD')];
		const unconfirmedCount = props.monthUnconfirmedCount[date.format('YYYYMMDD')];
		const attendance = props.monthAttendance[date.format('YYYYMMDD')];

		const effectAnnouncement = monthAnnouncements[date.format('YYYY-MM-DD')];

		const showAnnouncementDayListModal = (e) => {
			e.stopPropagation();
			dispatch(handleAnnouncementListModal('day', new Date(day.date)));
		};

		const renderAnnouncement = () => {
			if(effectAnnouncement && effectAnnouncement.length > 0) {
				return (
					<div className='announcement_have'>
						<div
							className='announcementAction'
							onClick={(e) => showAnnouncementDayListModal(e)}
						>
							<div className="announcementCount">{effectAnnouncement.length}則</div>
							<span className='announcementIcon' />
						</div>
						<span className='announcementIcon announcementView' />
					</div>
				)
			}
		}

		days.push(
			<div
				key={day.date.toString() + '_' + holidayCell}
				className={
					'day-container' +
					(day.isToday ? ' today' : '') +
					(day.isCurrentMonth ? '' : ' different-month') +
					(day.date.isSame(props.selected) ? ' selected-n' : '') +
					(disableDateStyle ? ' disableDateStyle' : '') +
					(nationalWeek ? ' holidayStyle' : '') +
					(nationalHoliday ? ' gov_holidayStyle' : '')
				}
				onClick={props.select.bind(null, day)}
			>
				<span className={'day' + (day.isToday ? ' today' : '')}>
					<span className='day_web'>{renderTodayNumber}</span>
					<span className='day_mobile'>{day.number}</span>
				</span>
				{unconfirmedCount !== undefined && (
					<div className="unconfiremedStyle" style={{ color: '#F5A623', position: 'absolute' }} />
				)}
				{effectiveCount !== undefined && <div className="dayEffectiveCount">{effectiveCount}筆</div>}
				{attendance !== undefined && <div className="dayAttendance">{attendance} 人</div>}

				{ renderAnnouncement() }

				<span
					className="govHolidayStyle"
					style={{
						color: '#2e2e39',
						position: 'absolute',
						top: 11,
						right: 14,
						textAlign: 'right',
						maxWidth: 100
					}}
				>
					{holidayTxt}
				</span>
			</div>
		);
		date = date.clone();
		date.add(1, 'd');
	}
	return (
		<div className="week" key={days[0].toString()}>
			{days}
		</div>
	);
};
