import React from 'react';
import { Link } from 'react-router';

const PageNav = () => {
	return (
		<nav
			className="navbar navbar-default navbar-fixed-top fix-navbar-default fix-nav"
			style={{ border: 0 }}
		>
			<div className="container2">
				<div className="navbar-header">
					{/* <div className="visible-xs-block m_free_block" style={{ float: 'right', height: 50 }}>
						<Link to="/contact">
							<div className="m_free_btn" style={{}}>
								申請試用
							</div>
						</Link>
					</div> */}
					<button
						type="button"
						className="navbar-toggle collapsed"
						// style={{ float: 'left' }}
						data-toggle="collapse"
						data-target="#navbar"
						aria-expanded="false"
						aria-controls="navbar"
					>
						<span className="sr-only">Toggle navigation</span>
						<span className="icon-bar" />
						<span className="icon-bar" />
						<span className="icon-bar" />
					</button>
					<Link className="navbar-brand" to="#">
						<img src={require('../../images/pageIdx/logo.png')} alt="" />
					</Link>
				</div>
				<div id="navbar" className="navbar-collapse collapse">
					<ul className="nav navbar-nav navbar-right">
						<li className="page-nav-dropdown" onClick={(e) => e.preventDefault()}>
							<Link
								className="dropdown-toggle"
								data-toggle="dropdown"
								role="button"
								aria-haspopup="true"
								aria-expanded="false"
							>
								產品特色
								<span className='arrow_icon' />
							</Link>
							<ul className="dropdown-menu">
								<li>
									<Link to="/booking_system">預約系統</Link>
								</li>
								<li>
									<Link to="/queue_system">排隊系統</Link>
								</li>
								<li>
									<Link to="/crm_system">顧客管理系統</Link>
								</li>
								<li>
									<Link to="/menu_coupon">優惠券系統</Link>
								</li>
								<li>
									<Link to="/announcement_system">公告系統</Link>
								</li>
							</ul>
						</li>
						<li>
							<Link to="/case">應用場景</Link>
						</li>
						<li>
							<Link to="/#pricing">計價方案</Link>
						</li>
						<li>
							<Link to="/menutaiwan">美食社群</Link>
						</li>
						<li className='visible-xs-block'>
							<Link to="/contact">申請試用</Link>
						</li>
						<li
							className='hidden-xs nav-btn'
						>
							<Link to="/contact">
								<span className="m_free_btn2 btn_apply">申請試用</span>
							</Link>
						</li>
						<li className='nav-btn'>
							<Link to="/login">
								<span className="m_free_btn2 btn_login">店家登入</span>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default PageNav;
