import React from "react";
import { Link } from "react-router";
var createReactClass = require("create-react-class");
export default createReactClass({
	render() {
		return (
			<div className='main-item'>
				<Link to='/dashboard/calls' role='button'>
					<div className='function-thub'>
						<img
							alt='call'
							src={require("../../images/component/f_phonecall.svg")}
						/>
						<div className='caption function-title'>
							<h3>來電系統</h3>
							<p>管理顧客來電記錄</p>
						</div>
					</div>
				</Link>
			</div>
		);
	}
});
