import React, { useState, useEffect, useCallback } from 'react';
import $ from 'jquery';
import { browserHistory } from 'react-router';
import moment from 'moment';

import useInterval from '../utils/useInterval'
import { time } from '../../new_module/utils/data';

export default ({
	datepickerRef,
	bookingTimeCount,
	setTimerPaused,
	setSelectedBookingTime,
	setCanvasBackgound,
	timerPaused,
	setDate,
	closeSelectTable
}) => {
	let urlDate = browserHistory.getCurrentLocation().query.date;
	let delay = 1000;
	if (urlDate) {
		if (moment().format('YYYY/MM/DD') === moment(new Date(urlDate)).format('YYYY/MM/DD')) {
			delay = 1000;
		} else {
			delay = null;
		}
	} else {
		delay = 1000;
	}

	const [ timestamp, setTimestamp ] = useState(moment());
	const [ clockerTime, setClockerTime ] = useState(0);
	const [ clockerTimerPaused, setClockerTimerPaused ] = useState(timerPaused);
	const [ clockerTimerDelay, setClockerTimerDelay ] = useState(delay);
	const [ selectedTime, setSelectedTime ] = useState(false);
	const [ activeTime, setActiveTime ] = useState(null);
	let paused = 'paused';

	useEffect(
		() => {
			setClockerTimerPaused(timerPaused);
			if (timerPaused) {
				setClockerTimerDelay(null);
				let today = moment().format('YYYY/MM/DD');
				let queryDate = moment(new Date(browserHistory.getCurrentLocation().query.date)).format('YYYY/MM/DD');

				if (today !== queryDate && !selectedTime) {
					setClockerTime(0);
				}
			} else {
				setClockerTimerDelay(1000);
			}
		},
		[ selectedTime, timerPaused ]
	);

	useInterval(() => {
		setTimestamp(moment());

		for (let i = 0; i < time.length; i++) {
			let today = moment().format('YYYY/MM/DD');
			let findTime = moment(new Date(today + ' ' + time[i]));

			if (timestamp <= findTime) {
				setSelectedBookingTime(i - 1);
				setClockerTime(i - 1);
				setActiveTime(null);
				break;
			}
		}
	}, clockerTimerDelay);

	useEffect(
		() => {
			$('.timerSlider').owlCarousel('destroy');

			$('.timerSlider').owlCarousel({
				dots: false,
				autoWidth: true,
				nav: true,
				items: 12,
				navText: [ '', '' ],
				startPosition: clockerTime
			});
		},
		[ clockerTime ]
	);

	const goNow = useCallback(
		() => {
			let today = moment().format('YYYY/MM/DD');
			let now = moment();
			for (let i = 0; i < time.length; i++) {
				let findTime = moment(new Date(today + ' ' + time[i]));

				if (now <= findTime) {
					setSelectedBookingTime(i - 1);
					setClockerTime(i - 1);
					setActiveTime(null);
					break;
				}
			}
			$(datepickerRef.current).datepicker('update', moment().format('YYYY/MM/DD'));
			setClockerTimerDelay(1000);
			setSelectedTime(false);
			setClockerTimerPaused(false);
			setDate(today);

			setTimerPaused(false);
			setCanvasBackgound(null);
			closeSelectTable();
		},
		[ datepickerRef, setCanvasBackgound, setSelectedBookingTime, setDate, setTimerPaused, closeSelectTable ]
	);

	const changeSelectedBookingTime = (index) => {
		setCanvasBackgound('canvasBackgound');
		setClockerTimerDelay(null);
		setSelectedTime(true);
		setActiveTime(index);
		setClockerTimerPaused(true);

		setTimerPaused(true);
		setSelectedBookingTime(index);
		closeSelectTable();
	};

	if (clockerTimerPaused) {
		paused = null;
	}
	return (
		<div className="bookingTableMap__canvas_time">
			<div className="timerSliderWrap">
				<div>
					<div className="timerSlider">
						{time.map((t, index) => {
							let active = '';
							let count = bookingTimeCount[t] ? `${bookingTimeCount[t]}位` : '';

							if (selectedTime && index === activeTime) {
								active = 'timerSlider__cell-active';
							} else {
								active = '';
							}

							return (
								<div
									key={index}
									className={`timerSlider__cell ${active}`}
									onClick={() => changeSelectedBookingTime(index)}
								>
									<span>{t}</span>
									<span>{count}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<button className={`goNow msBtn ${paused}`} onClick={() => goNow()}>
				回現在時間
			</button>
		</div>
	);
};
