import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import BookingSystemApi from '../../booking_settings/BookingSystemApi';
import NextBooking from './CanvasTableBookingInfoNextBooking';
import CurrentBooking from './CanvasTableBookingInfoCurrent';

import { swapConflictTable, seatedConflictTable } from '../utils/conflictTable';
import { Portal } from '../utils/Portal';

import { CommomSettingContext } from '../NewModuleReducer/CommomReuducer';

import { ConflictWarning } from './ConflictWarning';
import { StatusOptions } from './StatusOptions';
import '../../../stylesheets/new_module_tablemap.css';

const BookingAPI = new BookingSystemApi();

// mode 1:booking info, 2: status buttons

const CanvasTableBookingInfo = ({
	bookingListInfoBookings,
	newBookingListInfoBookings,
	startSwapSeat,
	startSwapMoreSeat,
	swapMode = false,
	swapMoreSeatMode,
	swapBookingInfoSeat,
	swapBookingInfo,
	beSwapedBookingInfoSeat,
	closeSwapSeate,
	systemMode = 'tablemap',
	updateBooking,
	target,
	swapped,
	setShowCanvasTableBookingInfo
}) => {
	const { CommomSettingState, setStatus, setUuid } = useContext(CommomSettingContext);
	const { date, isToday } = CommomSettingState;

	const [ mode, setMode ] = useState(1); //1預約資訊 2狀態列表
	const [ bookingList, setBookingList ] = useState(bookingListInfoBookings);
	const [ booking, setBooking ] = useState({});
	const [ showWarning, setShowWarning ] = useState(false);
	const [ swapType, setSwapType ] = useState(null); //seated入座 swap交換
	const [ beSwapedBookingInfo, setBeSwapedBookingInfo ] = useState({});

	useEffect(
		() => {
			setBookingList(bookingListInfoBookings);
			setMode(1);
		},
		[ bookingListInfoBookings ]
	);

	const swithcStatus = (booking = {}, newState) => {
		setStatus(booking, newState);
		setShowCanvasTableBookingInfo(false);
	};

	const switchMode = (modeType, targetBooking) => {
		setMode(modeType);
		setBooking(targetBooking);
	};

	const checkConflict = (type, swapedBookingInfo) => {
		setSwapType(type);
		let targetConflict = false;
		let swappedConflict = false;

		setBeSwapedBookingInfo(swapedBookingInfo);

		if (type === 'seated') {
			//入座
			//1.檢查要入座的位置上下筆預約是否衝突
			//2.檢查要入座的位置該時段是否有預約

			targetConflict = swapConflictTable({
				targetBooking: target.current,
				preBookinging: swapped.seated.pre,
				nextBooking: swapped.seated.next
			});
			swappedConflict = seatedConflictTable({
				targetBooking: target.current,
				seatedBooking: swapped.seated.current
			});
		}

		if (type === 'swap') {
			//交換
			//雙向檢查是否衝突

			targetConflict = swapConflictTable({
				targetBooking: target.current,
				preBookinging: swapped.swap.pre,
				nextBooking: swapped.swap.next
			});
			swappedConflict = swapConflictTable({
				targetBooking: swapped.swap.current,
				preBookinging: target.pre,
				nextBooking: target.next
			});
		}

		if (targetConflict || swappedConflict) {
			//其中一個是true就是有衝突
			setShowWarning(true);
		} else {
			if (type === 'seated') toSeated();
			if (type === 'swap') swap(swapedBookingInfo);
		}
	};

	const checkSwap = () => {
		if (swapType === 'seated') toSeated();
		if (swapType === 'swap') swap(beSwapedBookingInfo);
	};

	const cancelSwap = () => {
		setSwapType(null);
		setShowWarning(false);
	};

	const toSeated = () => {
		//直接入座

		//原本的位置
		const newSeat = _.cloneDeep(swapBookingInfo.seats);
		const findSwapSeatIndex = newSeat.map((seat) => seat.table_number).indexOf(swapBookingInfoSeat.table_number);

		newSeat.splice(findSwapSeatIndex, 1, beSwapedBookingInfoSeat);

		//set uuid
		const eventId = uuidv4();
		setUuid(eventId);

		const dataSubmit = {
			seating_json: JSON.stringify(newSeat),
			booking_datetime: moment(swapBookingInfo.booking_datetime).format('YYYY/MM/DD HH:mm'),
			date: moment(swapBookingInfo.booking_datetime).format('YYYY/MM/DD'),
			event_id: eventId
		};

		swapBooking(swapBookingInfo.id, dataSubmit);
	};

	const swap = (swapedBookingInfo) => {
		//交換位子

		//被換的位子
		//要被換的位子資訊
		const newSwapSeatInfoSeats = _.cloneDeep(swapedBookingInfo.seats);
		const beSwappedSeatIndex = newSwapSeatInfoSeats
			.map((seat) => seat.table_number)
			.indexOf(beSwapedBookingInfoSeat.table_number);
		const beSwappedSeat = newSwapSeatInfoSeats[beSwappedSeatIndex];

		//要換的位子: target
		//swapBookingInfoSeat 要換位子的資訊
		const newSeat = _.cloneDeep(swapBookingInfo.seats);
		const findSwapSeatIndex = newSeat.map((seat) => seat.table_number).indexOf(swapBookingInfoSeat.table_number);
		const seat = newSeat[findSwapSeatIndex];

		newSeat.splice(findSwapSeatIndex, 1, beSwappedSeat);
		newSwapSeatInfoSeats.splice(beSwappedSeatIndex, 1, seat);

		//set uuid
		const eventId = uuidv4();
		setUuid(eventId);

		const data = [
			{
				id: swapedBookingInfo.id,
				seat: _.uniqBy(newSwapSeatInfoSeats, 'table_number'),
				booking_datetime: swapedBookingInfo.booking_datetime
			},
			{
				id: swapBookingInfo.id,
				seat: _.uniqBy(newSeat, 'table_number'),
				booking_datetime: swapBookingInfo.booking_datetime
			}
		];

		data.forEach((booking) => {
			const dataSubmit = {
				seating_json: JSON.stringify(booking.seat),
				booking_datetime: moment(booking.booking_datetime).format('YYYY/MM/DD HH:mm'),
				date: moment(booking.booking_datetime).format('YYYY/MM/DD'),
				event_id: eventId
			};

			swapBooking(booking.id, dataSubmit);
		});
	};

	const swapBooking = (id, dataSubmit) => {
		// console.log('dataSubmit----', dataSubmit);
		// setLoading(true);
		BookingAPI.updateBooking(id, dataSubmit)
			.then(() => {
				window.app.alert.setMessage('修改預約成功', 'done');
				closeSwapSeate();
				updateBooking();
			})
			.catch(() => {
				window.app.alert.setMessage('請重新再試一次', 'error');
				closeSwapSeate();
			});
	};

	const renderBookingInfo = () => {
		const { currentBooking, nextBooking } = bookingList;
		const hasCurrentBooking = currentBooking.length !== 0,
			hasNextBooking = nextBooking.length !== 0;

		if (mode === 1) {
			return (
				<React.Fragment>
					{swapMode &&
					!hasCurrentBooking && (
						<div className="switchSeatInfo">
							<div className="switchSeatInfo__footer">
								{/* <SeatedButton checkConflict={checkConflict} /> */}
								<button className="seatedBtn" onClick={() => checkConflict('seated', {})}>
									入座
								</button>
							</div>
						</div>
					)}

					{hasCurrentBooking &&
						currentBooking.map((booking) => {
							return (
								<CurrentBooking key={booking.id}>
									<CurrentBooking.Header currentBooking={booking} switchMode={switchMode} />
									<CurrentBooking.Body
										currentBooking={booking}
										systemMode={systemMode}
										swapMode={swapMode}
										swapMoreSeatMode={swapMoreSeatMode}
									/>
									<CurrentBooking.Footer
										currentBooking={booking}
										systemMode={systemMode}
										swapMode={swapMode}
										swapMoreSeatMode={swapMoreSeatMode}
										startSwapSeat={startSwapSeat}
										startSwapMoreSeat={startSwapMoreSeat}
										swithcStatus={swithcStatus}
										checkConflict={checkConflict}
									/>
								</CurrentBooking>
							);
						})}

					{hasNextBooking &&
						nextBooking.map((booking) => {
							return (
								<NextBooking key={booking.id}>
									<NextBooking.Header />
									<NextBooking.Body nextBooking={booking} switchMode={switchMode} />
									<NextBooking.Button
										swapMode={swapMode}
										swapMoreSeatMode={swapMoreSeatMode}
										nextBooking={booking}
										currentBooking={currentBooking}
										checkConflict={checkConflict}
										startSwapSeat={startSwapSeat}
										startSwapMoreSeat={startSwapMoreSeat}
									/>
								</NextBooking>
							);
						})}
				</React.Fragment>
			);
		} else {
			return (
				<StatusOptions
					isToday={isToday}
					date={date}
					booking={booking}
					switchMode={switchMode}
					swithcStatus={swithcStatus}
				/>
			);
		}
	};


	const arrowColor = bookingList.currentBooking[0] ? `arrowColor_${bookingList.currentBooking[0].status}` : null;
	return (
		<section className="canvasTableBookingInfo">
			<div className={`canvasTableBookingInfo__arrow ${arrowColor}`}>
				<div>
					{renderBookingInfo()}
				</div>
			</div>

			{showWarning && (
				<Portal>
					<ConflictWarning
						targetTableName={swapBookingInfoSeat.table_number}
						swapTableName={beSwapedBookingInfoSeat.table_number}
						cancelSwap={cancelSwap}
						checkSwap={checkSwap}
					/>
				</Portal>
			)}
		</section>
	);
};


export default CanvasTableBookingInfo;
