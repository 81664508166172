import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import TableCell from './TableCell';
import NotEnoughtWarnging from './NotEnoughWarning';

//tableGroup 區域選項
//tables 所有桌子
//tempSeating 所選擇的桌子
//availableTables 該時段有空位的桌子
//chosenTableGroup 所選擇桌子所屬的區域

const initTableGroup = (tables) => {
	const newTableGroup = { all: [] };

	tables.forEach((tableInfo) => {
		const group = tableInfo.group;
		const allGroup = newTableGroup['all'];
		const groupIndex = allGroup.map((groupName) => groupName.group).indexOf(group);

		if (groupIndex === -1) {
			let groupTable = {
				group,
				tables: []
			};
			groupTable.tables.push(tableInfo);
			allGroup.push(groupTable);
		} else {
			allGroup[groupIndex].tables.push(tableInfo);
		}
	});

	return newTableGroup;
};

const TablePicker = ({
	tablePickerInit,
	defaultTableGroupTxt: propsDefaultTableGroupTxt,
	newSelectedId,
	bookingAttend,
	seating,
	chosenTableGroup: propsChosenTableGroup,
	availableTables: propsAvailableTables,
	tables: propsTables,
	handleTablePicker,
	tablePickerSubmit
}) => {
	const [ init, setInit ] = useState(false);
	const [ tables, setTables ] = useState(propsTables);
	const [ tableGroup, setTableGroup ] = useState(initTableGroup(tables, 'setTableGroup'));
	const [ defaultTableGroupTxt, setDefaultTableGroupTxt ] = useState(propsDefaultTableGroupTxt);
	const [ tempSeating, setTempSeating ] = useState(seating);
	const [ availableTables, setAvailableTables ] = useState(propsAvailableTables);
	const [ chosenTableGroup, setChosenTableGroup ] = useState(propsChosenTableGroup);
	const [ showWarning, setShowWarning ] = useState(false);

	useEffect(() => {
		setInit(true);
	}, []);

	useEffect(
		() => {
			setTables(propsTables);
			setTableGroup(initTableGroup(propsTables));
		},
		[ propsTables ]
	);

	useEffect(
		() => {
			setAvailableTables(propsAvailableTables);
		},
		[ propsAvailableTables ]
	);

	useEffect(
		() => {
			let res;

			//檢查座位是否還在：比較table_setting_id & idx
			for (let i = 0; i < seating.length; i++) {
				res = tables.findIndex((seat) => {
					return (
						seating[i].idx === seat.idx &&
						seating[i].table_setting_id === seat.table_setting_id
					);
				});

				if (res === -1) {
					break;
				}
			}

			if (res === -1 || res === undefined || res === null) {
				setTempSeating([]);
			} else {
				setTempSeating(seating);
			}
		},
		[ seating, tables ]
	);

	useEffect(
		() => {
			setDefaultTableGroupTxt(propsDefaultTableGroupTxt);
		},
		[ propsDefaultTableGroupTxt ]
	);

	useEffect(
		() => {
			setChosenTableGroup(propsChosenTableGroup);
		},
		[ propsChosenTableGroup ]
	);

	const renderChosenTable = () => {
		const maxCount = tempSeating.reduce((prev, cur) => prev + cur.max_seat, 0);

		if (tempSeating.length === 0 || maxCount < parseInt(bookingAttend, 10)) {
			let attendanceCount = parseInt(bookingAttend, 10) - maxCount;
			return (
				<h2 className="text-center">
					<span className="bookingAttendance">
						預約人數<span className="attendanceStyle"> {bookingAttend} </span>人，
					</span>
					已選中 {tempSeating.length} 張桌，尚不足 <span className="notEnoughStyle">{attendanceCount}</span> 人
				</h2>
			);
		} else if (maxCount >= parseInt(bookingAttend, 10)) {
			return (
				<h2 className="text-center full">
					<span className="bookingAttendance">
						預約人數<span className="attendanceStyle"> {bookingAttend} </span>人，
					</span>
					已選中 {tempSeating.length} 張桌，最多容納 <span className="attendanceStyle">{maxCount}</span> 人
				</h2>
			);
		}
	};

	const handleTableGroupChange = (e) => {
		const tableGroupTxt = e.target.value;
		setDefaultTableGroupTxt(tableGroupTxt);
	};

	const handleTableCellClick = (table) => {
		let tableGroup = [];
		const newTempSeating = _.cloneDeep(tempSeating);

		const tableIndex = newTempSeating.findIndex((e) => e.table_number === table.table_number);

		if (tableIndex > -1) {
			// 移除選擇的座位
			newTempSeating.splice(tableIndex, 1);
		} else {
			// 增加選擇的做位
			newTempSeating.push(table);
		}

		newTempSeating.forEach((seat) => tableGroup.push(seat.group));

		setTempSeating(newTempSeating);
		setChosenTableGroup(_.uniq(tableGroup));
	};

	const renderTableCell = () => {
		if (defaultTableGroupTxt === 'all') {
			return tableGroup['all'].map((group) => {
				return (
					<TableCell
						newSelectedId={newSelectedId}
						key={group.group}
						groupTitle={group.group}
						tableInfo={group.tables}
						tempSeating={tempSeating}
						availableTables={availableTables}
						handleTableCellClick={handleTableCellClick}
					/>
				);
			});
		} else {
			const allTableGroups = _.cloneDeep(tableGroup['all']);
			const groupIndex = allTableGroups.map((group) => group.group).indexOf(defaultTableGroupTxt);

			return (
				<TableCell
					newSelectedId={newSelectedId}
					key={defaultTableGroupTxt}
					groupTitle={allTableGroups[groupIndex].group}
					tempSeating={tempSeating}
					availableTables={availableTables}
					tableInfo={allTableGroups[groupIndex].tables}
					handleTableCellClick={handleTableCellClick}
				/>
			);
		}
	};

	const check = () => {
		const maxCount = tempSeating.reduce((prev, cur) => prev + cur.max_seat, 0);

		if (tempSeating.length !== 0 && maxCount < parseInt(bookingAttend, 10)) {
			setShowWarning(true);
		} else {
			tempSeating.map(seat=> delete seat['disable'])

			tablePickerSubmit(tempSeating, chosenTableGroup);
		}
	};

	const cancelWaring = () => {
		setShowWarning(false);
	};

	const checkWarning = () => {
		setShowWarning(false);
		tablePickerSubmit(tempSeating, chosenTableGroup);
	};

	const renderTableNote = () => {

		const tables = tempSeating.map((t) => {
			if (t.disable === 'disable') return t.table_number;
			return null;
		});

		if (tables.filter(Boolean).length !== 0)
			return <div className="text-center tableNote">提醒：選中桌次 {tables.join(', ')} 尚有顧客或即將有預約。</div>;
	};

	if (!init || !tablePickerInit) {
		return (
			<div className="tablePickerWrapper">
				<div className="addModalLoading" />
			</div>
		);
	}

	return (
		<div className="tablePickerWrapper">
			<section className="tableSeleciton">
				<section className="tableSelected">{renderChosenTable()}</section>
				<section className="showTable">
					<select value={defaultTableGroupTxt} onChange={(e) => handleTableGroupChange(e)}>
						<option key="all" value={'all'}>
							全部區域
						</option>
						{tableGroup['all'].map((item) => (
							<option key={item.group} value={item.group}>
								{item.group}
							</option>
						))}
					</select>

					{renderTableNote()}

					<div className="tableRes">{renderTableCell()}</div>
				</section>
				<div className="wrapperFooter">
					<div className="btn cancelBtn" onClick={() => handleTablePicker('close')}>
						取消
					</div>
					<div className="btn saveBtn" onClick={() => check()}>
						確定
					</div>
				</div>
			</section>
			{showWarning && (
				<NotEnoughtWarnging
					bookingAttend={bookingAttend}
					tempSeating={tempSeating}
					cancelWaring={cancelWaring}
					checkWarning={checkWarning}
				/>
			)}
		</div>
	);
};

export default TablePicker;
