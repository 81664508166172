import React from 'react';
import { useDispatch } from 'react-redux'
import _ from 'lodash';
import { Modal } from 'antd';

import { announcementOfficialFetchToday } from '../../../actions/announcementAction';
import { updateOfficialAnnouncementDisplay } from '../api/OfficialAnnouncementApi';

const AnnouncementOfficialModal = ({
  item,
  displayOfficialAnnouncements,
  setDisplayOfficialAnnouncements
}) => {
  const dispatch = useDispatch();

  const closePopup = () => {
    let newOfficialAnnouncement = _.cloneDeep(displayOfficialAnnouncements);
    let idx = displayOfficialAnnouncements.findIndex((d) => d.id = item.id);

    newOfficialAnnouncement.splice(idx, 1);
    setDisplayOfficialAnnouncements(newOfficialAnnouncement);
  };

  const closeAnnouncement = async(type) => {
    let setting = {};

    if(type === 'close') {
      // 設定成關閉且明日還會顯示
      setting = {
        id: item.id,
        display_mode: 'once',
        last_seen: new Date()
      };
    } else if(type === 'finish') {
      // 不再顯示
      setting = {
        id: item.id,
        display_mode: 'never'
      };
    }

    try {
      await updateOfficialAnnouncementDisplay(setting);
      dispatch(announcementOfficialFetchToday());
      closePopup();
    } catch(err) {
      console.log('close announcement err ----------', err);
    }
  };

  return (
    <Modal
      title="找活公告"
      visible
      centered
      className="announcementOfficialModal"
      footer={
        <div>
          <button
            className='btn_cancel'
            onClick={() => closeAnnouncement('finish')}
          >
            不再顯示
          </button>
          <button
            className='btn_save'
            onClick={() => closeAnnouncement('close')}
          >
            知道了
          </button>
        </div>
      }
			transitionName="ant-move-down"
      maskClosable={false}
    >
      <div className='announcement_title'>
        { item.title }
      </div>
      <div className='announcement_content'>
        { item.content }
      </div>
    </Modal>
  );
};

export default AnnouncementOfficialModal;