import React, { useState, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import $ from 'jquery';
import moment from 'moment';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


import NewAddBookingView from '../AddNewBooking/NewAddBooking';
import NewModuleTableMap from './NewModuleTableMap';
import NewModuleTimeline from './NewModuleTimeline';
import Calendar from './Calendar/Calendar';
import List from './NewModuleList';
import { BookingTags } from './Booking/booking';
import NewFeature from './NewFeature/NewFeature';
import DoubleBookingModal from './TableBooking/DoubleBooking';

import NewModuleQueueGroup from './Queue/QueueGroup';
import { QueueTags, QueueGroups } from './Queue/Queue';

import BookingSearch from '../Search';

import BookingSystemApi from '../booking_settings/BookingSystemApi';
import QueuePanelAPI from '../queue_system/api/QueuePanelAPI';

import { handleWebsocketEventRegistry, cancelWebsocketSubscription } from '../../libs/handler';
import { view, systemTabs } from './utils/data';
import { Portal } from './utils/Portal';

import { useSetFakeTimelineStatus } from './hooks/useSetFakeTimelineStatus';

import { BaseSettingContext, BaseSettingReducer, baseSetting } from './NewModuleReducer/BaseSettingReducer';
import { CommomSettingContext, CommomSettingReducer, commomSetting } from './NewModuleReducer/CommomReuducer';
import { ListStateContext, ListStateReducer, listState } from './NewModuleReducer/ListReducer';

import {
	announcementFetchMonth,
	updateDayAnnouncements,
} from '../../actions/announcementAction';

//queue state:end pause (需開始新時段) normal
const API = new BookingSystemApi();
const queueAPI = new QueuePanelAPI();

const NewModule = () => {
	const [ BaseSettingState, BaseSettingDispatch ] = useReducer(BaseSettingReducer, baseSetting);
	const { tempCustomQAns, showCanvas, autoTable, diningTime, customQ, timeChecking, canvasWidth } = BaseSettingState;

	const [ CommomSettingState, CommomSettingDispatch ] = useReducer(CommomSettingReducer, commomSetting);
	const { mode, todayDate, date, isToday, customQAns, activeCanvasTab } = CommomSettingState;

	const [ ListReducerState, ListStateDispatch ] = useReducer(ListStateReducer, listState);
	const {
		activeSystem,
		originBookings,
		activeBookingStatusTab,
		queueGroup,
		queueState,
		activeQueueStatusTab,
		queueList,
		currentTag
	} = ListReducerState;

	const [ scaleRatio, setScaleRatio ] = useState(1);
	const [ modalType, setModalType ] = useState('addNew');
	const [ showAddBookingView, setShowAddBookingView ] = useState(false);
	const [ newSelected, setNewSelected ] = useState({});

	const dispatch = useDispatch();

	// CanvasTableBookingInfo
	const [ bookingListInfoVisible, setBookingListInfoVisible ] = useState(false);

	// 自建預約 response 重疊座位
	const [ overlayTables, setOverlayTables ] = useState([]);

	//queue
	const [ showQueueGroupPanel, setShowQueueGroupPanel ] = useState(false);

	//入座
	const [ loading, setLoading ] = useState(false);

	//timeline
	const [ timelineTables, setTimelineTables ] = useState([]);
	const [ showTimelineSeat, setShowTimelineSeat ] = useState(false);

	//uuid
	const [ uuid, setUuid ] = useState('');

	// 服務時間開放多選
	const [ serviceTimeOptionEnabled, setServiceTimeOptionEnabled ] = useState(false);

	// 點數
	const [ msgPoint, setMsgPoint ] = useState(0);
	const [ smsNotificationSettings, setSmsNotificationSettings ] = useState({});
	const [ emailNotificationSettings, setEmailNotificationSettings ] = useState({});
	const [ spgHashIv, setSpgHashIv ] = useState(null);
	const [ spgHashKey, setSpgHashKey ] = useState(null);
	const [ spgMerchantId, setSpgMerchantId ] = useState(null);
	const [ creditEnabled, setCreditEnabled ] = useState('');

	const setFakeTimelineStatus = useSetFakeTimelineStatus();

	//如果url沒有日期就抓今天日期
	useEffect(() => {
		const urlDate = browserHistory.getCurrentLocation().query.date;
		if (!urlDate) {
			browserHistory.push({
				pathname: '/dashboard/new_module',
				query: {
					date: moment().format('YYYY-MM-DD')
				}
			});

			CommomSettingDispatch({ type: 'setDate', date: moment().format('YYYY/MM/DD') });
		} else {
			CommomSettingDispatch({ type: 'setDate', date: moment(urlDate).format('YYYY/MM/DD') });
		}
	}, []);

	const resizeSreen = useCallback(
		() => {
			let timer;
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				let w_w = window.innerWidth;
				if (w_w >= 500) {
					const $systemListWidth = 290;
					const asideWidth = 60;
					const actual_w_w = window.innerWidth - $systemListWidth - asideWidth;

					const scaleRatio =
						JSON.parse((actual_w_w / canvasWidth).toFixed(2)) > 1
							? 1
							: JSON.parse((actual_w_w / canvasWidth).toFixed(2));

					setScaleRatio(scaleRatio);
				}
			}, 200);
		},
		[ canvasWidth ]
	);

	//booking setting & queue setting
	const newModuleSetting = useCallback(async () => {
		try {
			const { booking_setting, queue_setting, customized_questions } = await API.newModuleSetting();
			const { canvas_enabled } = booking_setting;
			const mode = canvas_enabled ? 'tablemap' : 'timeline';

			CommomSettingDispatch({ type: 'setMode', mode });
			BaseSettingDispatch({ type: 'setBaseSetting', booking_setting, queue_setting, customized_questions });
		} catch (err) {
			// console.log('newModuleSetting-----', err);
		}
	}, []);

	//booking list & canvas & timeline
	const newModuleBooking = useCallback(
		async () => {
			try {
				const urlDate = browserHistory.getCurrentLocation().query.date;
				const urlDateFormat = moment(new Date(urlDate)).format('YYYY/MM/DD');

				if (urlDateFormat !== date) return;

				const { bookings, table_map_list, timeline } = await API.newModuleBooking(date);

				//booking
				ListStateDispatch({ type: 'setInitBookings', bookings });

				//canvas
				CommomSettingDispatch({ type: 'setInitCanvasSetting', tableMapList: table_map_list });

				//timeline
				setTimelineTables(timeline.tables);

				setLoading(false);
				window.app.alert.setMessage('', 'done');
			} catch (err) {
				// console.log('Booking-----', err);
			}
		},
		[ date ]
	);

	//queue group & queue list
	const newModuleQueue = useCallback(
		async () => {
			try {
				const urlDate = browserHistory.getCurrentLocation().query.date;
				const urlDateFormat = moment(new Date(urlDate)).format('YYYY/MM/DD');

				if (urlDateFormat !== date) return;

				const { queue_groups, queue_records } = await API.newModuleQueue(date);

				ListStateDispatch({ type: 'setInitQueueList', queueRecords: queue_records, queueQroups: queue_groups });
			} catch (err) {
				// console.log('newModuleQueue err---', err);
			}
		},
		[ date ]
	);

	//排隊開啟新時段
	const updateQueueSystemState = useCallback(async (state) => {
		let update = false;
		try {
			if (state !== '') {
				const eventId = uuidv4();
				setUuid(eventId);
				update = await queueAPI.updateQueueGroupStatus(state, eventId);
			}
		} catch (error) {
			// console.log('updateQueueSystemState error---', error);
		}
		return update;
	}, []);

	//booking setting 服務時間開放多選 -> 服務時間顯示
	const getBookingSetting = useCallback(async () => {
		try {
			const data = await API.getBookingSetting();
			const {
				service_time_option_enabled,
				sms_notification_settings,
				email_notification_settings,
				shop,
				spg_hash_iv,
				spg_hash_key,
				spg_merchant_id
			} = data;

			setMsgPoint(shop.msg_point)
			setServiceTimeOptionEnabled(service_time_option_enabled);
			setSmsNotificationSettings(sms_notification_settings);
			setEmailNotificationSettings(email_notification_settings);
			setSpgHashIv(spg_hash_iv);
			setSpgHashKey(spg_hash_key);
			setSpgMerchantId(spg_merchant_id);
		} catch (err) {
			// console.log('newModule getBookingSetting err---', err);
		}
	},[]);

	// 付款通知
	const getCreditEnabled = useCallback(
		() => {
			$.ajax({url: window.domain + '/dashboard/booking_settings/spg_data',
				method: 'GET',
				xhrFields: { withCredentials: true }})
			.done(function(data){
				setCreditEnabled(data.credit_enabled)
			}.bind(this))
			.fail(function(xhr){
				console.log('get credit enabled error ----------')
			});
		}, [])

	//websocket
	useEffect(
		() => {
			//修改座位、座位圖
			handleWebsocketEventRegistry('sync_canvas', () => {
				setTimeout(() => {
					// console.log("---修改座位、座位圖----")
					newModuleBooking();
				}, 3000);
			});

			//有新預約、修改預約
			handleWebsocketEventRegistry('refresh_booking_list', () => {
				setTimeout(() => {
					newModuleBooking();
				}, 3000);
			});

			//排隊：新增/修改/改變狀態: update排隊列表
			handleWebsocketEventRegistry('sync_frontend', (data) => {
				setTimeout(() => {
					if (data.event_id !== uuid) {
						newModuleQueue();
					}
				}, 3000);
			});

			//排隊：新增/修改/改變狀態: update排隊組別列表
			handleWebsocketEventRegistry('sync_queue_groups', (data) => {
				setTimeout(() => {
					if (data.event_id !== uuid) {
						newModuleQueue();
					}
				}, 3000);
			});

			// 公告: 建立/更新/過期/刪除 -> update 當月公告、該日公告
			handleWebsocketEventRegistry('sync_announcements', () => {
				const urlDate = browserHistory.getCurrentLocation().query.date;

				setTimeout(() => {
					dispatch(announcementFetchMonth());
					dispatch(updateDayAnnouncements(urlDate));
				}, 3000);
			});

			return () => {
				cancelWebsocketSubscription([ 'sync_canvas' ]);
				// cancelWebsocketSubscription([ 'notify' ]);
				cancelWebsocketSubscription([ 'refresh_booking_list' ]);
				cancelWebsocketSubscription([ 'refresh_booking_list_status_changed' ]);
				cancelWebsocketSubscription([ 'sync_frontend' ]);
				cancelWebsocketSubscription([ 'sync_queue_groups' ]);
				cancelWebsocketSubscription([ 'sync_announcements' ]);
			};
		},
		[
			activeCanvasTab,
			activeSystem,
			activeQueueStatusTab,
			newModuleBooking,
			newModuleQueue,
			uuid,
			dispatch
		]
	);

	useEffect(
		() => {
			window.app.alert.setMessage('讀取中..', 'done');
			newModuleSetting();
		},
		[ newModuleSetting ]
	);

	useEffect(
		() => {
			setLoading(true);
			newModuleBooking();
			newModuleQueue();
		},
		[ newModuleBooking, newModuleQueue ]
	);

	useEffect(() => {
		getBookingSetting();
		getCreditEnabled();
	}, []);

	//init booking list status dropdown
	useEffect(() => {
		$('.bookingStatus-dropdown').popover({
			html: true,
			trigger: 'click',
			container: '.bookingList__card__status'
		});
	}, []);

	useEffect(
		() => {
			const $body = document.getElementsByTagName('body')[0];
			$body.classList.add('fixBody');

			resizeSreen();
			window.addEventListener('resize', resizeSreen);

			return () => {
				window.removeEventListener('resize', resizeSreen);
				$body.classList.remove('fixBody');
			};
		},
		[ resizeSreen ]
	);

	//排隊入座後更新booking&queue
	const updateAfterSeated = useCallback(
		() => {
			newModuleBooking();
			newModuleQueue();
		},
		[ newModuleBooking, newModuleQueue ]
	);

	//新增預約後更新
	const updateBooking = useCallback(
		() => {
			newModuleBooking();
		},
		[ newModuleBooking ]
	);

	const switchSystemTab = useCallback(
		(systemTab) => {
			ListStateDispatch({ type: 'switchSystemTab', isToday, systemTab });
		},
		[ isToday ]
	);

	const switchMode = useCallback(
		(switchMode) => {
			if (switchMode === mode) return;
			CommomSettingDispatch({ type: 'setMode', mode: switchMode });
		},
		[ mode ]
	);

	const setStatus = useCallback(
		(booking, newStatus) => {
			const customerName = booking.last_name,
				customerGender = booking.gender_desc;
			const status_time = moment().format('HH:mm');
			const date = moment(booking.booking_datetime).format('YYYY-MM-DD');

			ListStateDispatch({ type: 'setStatusFake', booking, newStatus }); //列表
			CommomSettingDispatch({ type: 'setStatusFake', booking, newStatus }); //canvas

			const newTimelineTables = setFakeTimelineStatus({ state: timelineTables, booking, newStatus }); //timeline
			setTimelineTables(newTimelineTables);

			if (newStatus === 'confirmed') {
				window.app.alert.setMessage(customerName + customerGender + ' 確認預約', 'done');
			} else if (newStatus === 'no_show') {
				window.app.alert.setMessage(customerName + customerGender + ' 未到店', 'done');
			} else if (newStatus === 'show') {
				window.app.alert.setMessage(customerName + customerGender + ' 已到店', 'done');
			} else if (newStatus === 'seated') {
				window.app.alert.setMessage(customerName + customerGender + ' 已入座', 'done');
			} else if (newStatus === 'finish') {
				window.app.alert.setMessage(customerName + customerGender + ' 完成消費', 'done');
			} else {
				window.app.alert.setMessage('更新狀態成功', 'done');
			}

			//uuid
			const eventId = uuidv4();
			setUuid(eventId);

			API.setBookingStatus(booking.id, newStatus, date, status_time, eventId)
				.then(() => {
					newModuleBooking();
				})
				.catch(() => {
					window.app.alert.setMessage('請重新再試', 'error');
				});
		},
		[ newModuleBooking, setFakeTimelineStatus, timelineTables ]
	);

	const chageBookingStatusTab = (tab) => {
		ListStateDispatch({ type: 'setActiveBookingStatusTab', activeBookingStatusTab: tab });
	};

	const changeQueueStatusTab = useCallback((tab) => {
		ListStateDispatch({ type: 'changeQueueStatusTab', activeQueueStatusTab: tab });
	}, []);

	const filterQueueListByGroup = useCallback((groupName) => {
		ListStateDispatch({ type: 'setActiveGroupTag', activeGroupTag: groupName });
	}, []);

	const updateQueueSystem = useCallback(
		async (status) => {
			try {
				await updateQueueSystemState(status);
				await newModuleQueue();
			} catch (err) {}
		},
		[ newModuleQueue, updateQueueSystemState ]
	);

	const addNewBooking = () => {
		setModalType('addNew');
		setShowAddBookingView(true);
	};

	const editBooking = useCallback((booking) => {
		setNewSelected(booking);
		setModalType('editor');
		setShowAddBookingView(true);
		CommomSettingDispatch({ type: 'setSelectedBookingTableData', selectedBookingTableData: [] });
	}, []);

	const renderSystemTag = useCallback(
		() => {
			if (activeSystem === 'booking') {
				return (
					<BookingTags
						activeBookingStatusTab={activeBookingStatusTab}
						chageBookingStatusTab={chageBookingStatusTab}
						bookings={originBookings}
					/>
				);
			} else {
				const today = moment(new Date(todayDate)),
					queryDate = moment(new Date(date));

				const isPassedDay = queryDate.isBefore(today);
				if (isToday && !isPassedDay && queueState !== 'end' && queueGroup.length !== 0) {
					//當日：結束時段不顯示tag
					return <QueueTags changeQueueStatusTab={changeQueueStatusTab} />;
				} else if (!isToday && isPassedDay) {
					//昨日不限狀態顯示tag
					return <QueueTags changeQueueStatusTab={changeQueueStatusTab} />;
				}
			}
		},
		[
			activeSystem,
			activeBookingStatusTab,
			originBookings,
			todayDate,
			date,
			isToday,
			queueState,
			queueGroup.length,
			changeQueueStatusTab
		]
	);

	const renderQueueGroups = useCallback(
		() => {
			if (activeSystem === 'queue') {
				const today = moment(new Date(todayDate)),
					queryDate = moment(new Date(date));
				const isPassedDay = queryDate.isBefore(today);

				if (queueGroup.length !== 0) {
					if (isToday && !isPassedDay && queueState === 'end') {
						//當日且結束時段不顯示
						return;
					} else if (!isToday && !isPassedDay) {
						//明日且結束時段不顯示
						return;
					}

					let tagContentsObject = {};
					let newQueueList = queueList[activeQueueStatusTab];

					if (isToday) {
						newQueueList = newQueueList.filter((record) => record.tag === currentTag);
					}

					newQueueList.forEach((record) => {
						if (tagContentsObject[record.group_name] === undefined) {
							tagContentsObject[record.group_name] = 1;
						} else {
							const currentCount = tagContentsObject[record.group_name];
							tagContentsObject[record.group_name] = currentCount + 1;
						}
					});

					return (
						<QueueGroups
							queueList={newQueueList}
							tagContentsObject={tagContentsObject}
							filterQueueListByGroup={filterQueueListByGroup}
						/>
					);
				}
			}
		},
		[
			activeSystem,
			todayDate,
			date,
			queueGroup.length,
			isToday,
			queueState,
			queueList,
			activeQueueStatusTab,
			filterQueueListByGroup,
			currentTag
		]
	);

	const hideNewAddBookingModal = () => {
		setShowAddBookingView(false);
		CommomSettingDispatch({ type: 'setCustomQAns', customQAns: JSON.parse(JSON.stringify(tempCustomQAns)) });
	};

	const openQueueGroupPanel = () => {
		setShowQueueGroupPanel(true);
	};

	const closeQueueGroupPanel = () => {
		setShowQueueGroupPanel(false);
	};

	const renderSystemButton = useCallback(
		() => {
			if (activeSystem === 'booking') {
				return <BookingAddButton addNewBooking={addNewBooking} />;
			}

			if (activeSystem === 'queue' && isToday && queueGroup.length !== 0 && queueState !== 'end') {
				//當日有設定排隊組別且狀態非結束才顯示按鈕
				return <QueueAddButton openQueueGroupPanel={openQueueGroupPanel} />;
			}
		},
		[ activeSystem, queueGroup, queueState, isToday ]
	);

	const startQueueTimeline = (queue) => {
		setShowTimelineSeat(true);
		CommomSettingDispatch({ type: 'setQueueSeatedData', queue });
	};

	const closeTimelineSeat = () => {
		setShowTimelineSeat(false);
		CommomSettingDispatch({ type: 'setQueueSeatedData', queue: {} });
	};

	const setActiveSystemToBooking = useCallback(() => {
		ListStateDispatch({ type: 'setActiveSystem', systemTab: 'booking' });
	}, []);

	const renderActiveMode = useCallback(
		() => {
			if (mode === 'tablemap') {
				if (showCanvas) {
					return (
						<NewModuleTableMap
							scaleRatio={scaleRatio}
							activeSystem={activeSystem}
							updateAfterSeated={updateAfterSeated}
							updateBooking={updateBooking}
							setLoading={setLoading}
							setActiveSystemToBooking={setActiveSystemToBooking}
							bookingListInfoVisible={bookingListInfoVisible}
							setBookingListInfoVisible={setBookingListInfoVisible}
							smsNotificationSettings={smsNotificationSettings}
							emailNotificationSettings={emailNotificationSettings}
							spg_hash_iv={spgHashIv}
							spg_hash_key={spgHashKey}
							spg_merchant_id={spgMerchantId}
							credit_enabled={creditEnabled}
						/>
					);
				} else {
					return <CanvasNotEnable />;
				}
			}
			if (mode === 'timeline') {
				return (
					<NewModuleTimeline
						timelineTables={timelineTables}
						showTimelineSeat={showTimelineSeat}
						closeTimelineSeat={closeTimelineSeat}
						updateAfterSeated={updateAfterSeated}
						newModuleBooking={newModuleBooking}
					/>
				);
			}
		},
		[
			activeSystem,
			bookingListInfoVisible,
			mode,
			newModuleBooking,
			scaleRatio,
			setActiveSystemToBooking,
			showCanvas,
			showTimelineSeat,
			timelineTables,
			updateAfterSeated,
			updateBooking
		]
	);

	const systemTabMemo = useCallback(
		() => {
			return Object.keys(systemTabs).map((systemTab) => {
				const active = systemTab === activeSystem ? 'systemList__tools__tabs-active' : '';

				return (
					<li key={systemTab} className={active} onClick={() => switchSystemTab(systemTab)}>
						{systemTabs[systemTab]}
					</li>
				);
			});
		},
		[ activeSystem, switchSystemTab ]
	);

	const BaseSettingStateMemo = useMemo(() => BaseSettingState, [ BaseSettingState ]);

	return (
		<BaseSettingContext.Provider value={{ BaseSettingState: BaseSettingStateMemo }}>
			<CommomSettingContext.Provider
				value={{ CommomSettingState, CommomSettingDispatch, setStatus, editBooking, setUuid }}
			>
				<ListStateContext.Provider value={{ ListReducerState, ListStateDispatch }}>
					<NewFeature />

					<div className="newModule__main__toolBar">
						<div className="switchBtnWrap">
							{view.map((v, index) => {
								const active = v === mode ? 'active' : '';
								const modeTxt = v === 'timeline' ? '時間軸' : '座位圖';

								return (
									<button key={index} className={active} onClick={() => switchMode(v)}>
										{modeTxt}
									</button>
								);
							})}
						</div>

						<Calendar />

						<div className="newModuleBookingSearch">
							<BookingSearch iconSize={14} searchType="newModule" />
						</div>
					</div>
					<div className="newModule__main__display">
						{renderActiveMode()}
						{loading ? <Loading /> : null}

						<div className="newModule__main__display__list systemList">
							<div className="systemList__tools">
								<ul className="systemList__tools__tabs">{systemTabMemo()}</ul>
								{renderSystemTag()}
								{renderQueueGroups()}
							</div>
							<div className="systemList__list">
								<List
									activeSystem={activeSystem}
									showCanvas={showCanvas}
									updateQueueSystem={updateQueueSystem}
									updateQueueRecord={newModuleQueue}
									startQueueTimeline={startQueueTimeline}
									serviceTimeOptionEnabled={serviceTimeOptionEnabled}
								/>
							</div>
						</div>
					</div>
					{renderSystemButton()}

					{/* 自建預約 */}
					{showAddBookingView && (
						<Portal>
							<NewAddBookingView
								autoTable={autoTable}
								hideNewAddBookingModal={hideNewAddBookingModal}
								updateData={() => {
									updateBooking(activeCanvasTab);
									setBookingListInfoVisible(false);
								}}
								modalType={modalType}
								date={date}
								diningTime={diningTime}
								newSelected={newSelected}
								customQAns={customQAns}
								customQ={customQ}
								setUuid={(eventId) => setUuid(eventId)}
								setOverlayTables={setOverlayTables}
								msgPoint={msgPoint}
								smsNotificationSettings={smsNotificationSettings}
								emailNotificationSettings={emailNotificationSettings}
								spg_hash_iv={spgHashIv}
								spg_hash_key={spgHashKey}
								spg_merchant_id={spgMerchantId}
								credit_enabled={creditEnabled}
							/>
						</Portal>
					)}

					{/* 排隊組別 */}
					{showQueueGroupPanel && (
						<Portal>
							<NewModuleQueueGroup
								mode={mode}
								timeChecking={timeChecking}
								closeQueueGroupPanel={closeQueueGroupPanel}
								queueGroup={queueGroup}
								createQueueRecord={newModuleQueue}
								updateQueueSystem={updateQueueSystem}
								setShowQueueGroupPanel={setShowQueueGroupPanel}
							/>
						</Portal>
					)}

					{!overlayTables.length ? null : (
						<DoubleBookingModal conflictTables={overlayTables} setConflictTables={setOverlayTables} />
					)}

					<div id="canvasSeated" />
				</ListStateContext.Provider>
			</CommomSettingContext.Provider>
		</BaseSettingContext.Provider>
	);
};

const BookingAddButton = ({ addNewBooking }) => (
	<button className="bookingSystemAddBookingBtn" onClick={() => addNewBooking()}>
		<i className="fa fa-plus" aria-hidden="true" />
		新增預約
	</button>
);

const QueueAddButton = ({ openQueueGroupPanel }) => (
	<button className="bookingSystemAddBookingBtn" onClick={() => openQueueGroupPanel()}>
		<i className="fa fa-plus" aria-hidden="true" />
		新增排隊
	</button>
);

const CanvasNotEnable = () => (
	<div className="newModule__main__display__chart">
		<div className="CanvasNotEnable">
			<p> 尚未開啟座位圖</p>
			<p>
				前往
				<Link to={{ pathname: '/dashboard/setting/tablemap_setting', state: { source: 'newModule' } }}>設定</Link>
			</p>
		</div>
	</div>
);

const Loading = () => (
	<div className="newModuleLoading">
		<Spin
			indicator={<LoadingOutlined type="loading" style={{ fontSize: 48 }} spin />}
			spinning={true}
			style={{
				color: '#C8C8C8'
			}}
		/>
	</div>
);

export default NewModule;
