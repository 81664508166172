import React, { Fragment } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const GenderOption = ({gender, customerInfo, handleChange, genderTxt}) => {
	return (
		<div key={gender} className="genderRadio">
			<input
				value={gender}
				name="gender"
				id={gender}
				type="radio"
				checked={gender === customerInfo.gender}
				onChange={(e) => handleChange(e, 'gender')}
			/>
			<label htmlFor={gender}>{genderTxt}</label>
		</div>
	);
};

const ServiceTag = ({ ele, handleTagChange, customQAns }) => {
	const options = JSON.parse(ele.content);
	return (
		<div className="bookingContentRow">
			<div className="bookingContentRow-title">
				<p>
					{ele.title}
					<span style={{ marginLeft: '5px' }}>{ele.multiple_selected ? '(複選)' : '(單選)'}</span>
				</p>
			</div>
			<div className="bookingContentRow-value">
				<div className="tagWrapper">
					{Object.keys(options).map((o) => {
						let active = '';
						if (customQAns[o]) {
							active = 'active';
						} else {
							active = '';
						}
						return (
							<span
								key={o}
								className={active}
								onClick={() => handleTagChange(ele.id, ele.multiple_selected, o)}
							>
								{options[o].name}
							</span>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const Questionnaire = ({ ele, customQAns, handleQuestionChange }) => {
	return (
		<div className="bookingContentRow">
			<div className="bookingContentRow-title">
				<p>{ele.title}</p>
			</div>
			<div className="bookingContentRow-value">
				<textarea value={customQAns} onChange={(e) => handleQuestionChange(ele.id, e)} />
			</div>
		</div>
	);
};

const Quantity = ({ ele, options, customQAns, handleQuantityChange }) => {
	const numberOption = JSON.parse(ele.content);
	return (
		<div className="bookingContentRow">
			<div className="bookingContentRow-title">
				<p>
					{ele.title}
					<span style={{ marginLeft: '5px' }}>{ele.multiple_selected ? '(複選)' : '(單選)'}</span>
				</p>
			</div>
			<div className="bookingContentRow-value">
				<div className="selectWrap">
					{Object.keys(numberOption).map((o) => {
						let active = '';
						let selectValue = customQAns[o];
						if (customQAns[o] && customQAns[o] !== 0) {
							active = 'active';
						} else {
							active = '';
							selectValue = 0;
						}
						return (
							<select
								key={o}
								className={active}
								value={selectValue}
								onChange={(e) => handleQuantityChange(ele.id, e, ele.multiple_selected, o)}
							>
								{options[o].map((number) => {
									return (
										<option key={number} value={number}>
											{numberOption[o].name} x {number}
										</option>
									);
								})}
							</select>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const DepositSetting = React.forwardRef(({ depositDetail, handleDepositDetail, depositRef, msgPoint }) => {
	if(depositDetail.depositMode) {
		return (
			<div className='bookingContentRow-value'>
				<input
					ref={depositRef}
					type="text"
					placeholder="請輸入訂金金額"
					value={depositDetail.deposit}
					onChange={(e) => { handleDepositDetail(e, 'deposit') }}
				/>
				<div className='checkBlock'>
					<label className="msgLabel">
						<input
							type="checkbox"
							checked={msgPoint > 0 ? depositDetail.notification.sms : false}
							disabled={msgPoint <= 0}
							onChange={(e) => { handleDepositDetail(e, 'sms_notification') }}
						/>
						<span>傳送簡訊通知</span>
						{ msgPoint <= 0 ?
							<span className='text_point'>簡訊點數餘額不足</span> :
							<span className='text_point'>簡訊點數餘額 { msgPoint } 點</span>
						}
					</label>
					<label className="msgLabel">
						<input
							type="checkbox"
							checked={depositDetail.notification.email}
							onChange={(e) => { handleDepositDetail(e, 'email_notification') }}
						/>
						<span>傳送 E-mail 通知</span>
					</label>
					{ depositDetail.notificationError === 'required' && <div>請選擇傳送通知方式</div> }
				</div>
			</div>
		)
	} else {
		return null
	}
});

const DepositStatus = ({
	depositDetail,
	smsIsSend,
	emailIsSend,
	msgPoint,
	newSelected,
	handleDepositNotification
	}) => {
	const renderSmsNotificationBtn = () => {
		if(msgPoint <= 0) {
			return (
				<button
					className='btn_recharge'
					style={{ cursor: 'default' }}
				>
					點數不足，儲值點數
				</button>
			)
		} else {
			// 完成發送
			if(depositDetail.notificationStatus.sms === 'send') {
				return (
					<div
						className='text_send'
					>
						已傳送通知
					</div>
				)
			} else if(depositDetail.notificationStatus.sms) {
				// 傳送中
				if(smsIsSend) {
					return (
						<button className='btn_resend' style={{ cursor: 'default' }}>
							<LoadingOutlined />
						</button>
					)
				} else {
					// 已發送
					return (
						<button
							className='btn_resend'
							onClick={() => handleDepositNotification('sms')}
						>
							再傳送一次
						</button>
					)
				}
			} else {
				// 傳送中
				if(smsIsSend) {
					return (
						<button className='btn_send' style={{ cursor: 'default' }}>
							<LoadingOutlined />
						</button>
					)
				} else {
					// 尚未發送
					return (
						<button
							className='btn_send'
							onClick={() => handleDepositNotification('sms')}
						>
							傳送通知
						</button>
					)
				}
			}
		}
	}

	const renderEmailNotificationBtn = () => {
		// 完成發送
		if(depositDetail.notificationStatus.email === 'send') {
			return (
				<div
					className='text_send'
				>
					已傳送通知
				</div>
			)
		} else if(depositDetail.notificationStatus.email) {
			// 傳送中
			if(emailIsSend) {
				return (
					<button className='btn_resend' style={{ cursor: 'default' }}>
						<LoadingOutlined />
					</button>
				)
			} else {
				// 已發送
				return (
					<button
						className='btn_resend'
						onClick={() => handleDepositNotification('email')}
					>
						再傳送一次
					</button>
				)
			}
		} else {
			if(emailIsSend) {
				return (
					<button className='btn_send' style={{ cursor: 'default' }}>
						<LoadingOutlined />
					</button>
				)
			} else {
				// 尚未發送
				return (
					<button
						className='btn_send'
						onClick={() => handleDepositNotification('email')}
					>
						傳送通知
					</button>
				)
			}
		}
	}

	if(newSelected.status === 'cancel') {
		return <div style={{ color: '#676767' }}>此筆預約無效</div>
	}

	if(depositDetail.paid) {
		return <div style={{ color: '#676767' }}>已繳納訂金</div>
	} else {
		return (
			<Fragment>
				<div className='bookingDeposit_msg'>
					<div className='title'>簡訊通知</div>
					{ renderSmsNotificationBtn() }
				</div>
				{ msgPoint <= 0 ?
					<div className='text_point'>點數餘額不足</div> :
					<div className='text_point'>點數餘額 { msgPoint } 點</div>
				}
				<div className='bookingDeposit_msg'>
					<div className='title'>E-mail 通知</div>
					{ renderEmailNotificationBtn() }
				</div>
				{ depositDetail.notificationError === 'email' && <div className='text_error'>請輸入 E-mail</div> }
			</Fragment>
		)
	}
};

export { GenderOption, ServiceTag, Questionnaire, Quantity, DepositSetting, DepositStatus };
